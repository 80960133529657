import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './store/configure-store';
import ReactGA from 'react-ga';
import $ from 'jquery';

import Routes from './routes';

import 'antd/dist/antd.css';
import 'rc-steps/assets/index.css';
import 'rc-steps/assets/iconfont.css';
import 'react-day-picker/lib/style.css';
import 'lyef-switch-button/css/main.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-select/dist/react-select.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'react-bootstrap-multiselect/css/bootstrap-multiselect.css';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

const readCookie = require("./cookie").readCookie;
const store = configureStore();

let userData = JSON.parse(readCookie("userData"));

if (userData !== null && userData.email) {
	ReactGA.initialize('UA-71890122-3', {
		gaOptions: {
			userId: userData.email
		},
	})
	ReactGA.set({ 'dimension1': userData.fullName });
	ReactGA.set({ 'dimension2': userData.organisationData.name });
	fetch("https://jsonip.com", {
		method: "GET",
	}).then((data) => data.json())
	.then(data => {
		ReactGA.set({ 'dimension3': data.ip });
	});
} else {
	ReactGA.initialize('UA-71890122-3');
}
ReactGA.pageview(window.location.pathname + window.location.search);

window.inrFormat = function (nStr) {
	nStr += '';
	var x = nStr.split('.');
	var x1 = x[0];
	var x2 = x.length > 1 ? '.' + x[1] : '';
	var rgx = /(\d+)(\d{3})/;
	var z = 0;
	var len = String(x1).length;
	var num = parseInt((len/2)-1);

  while (rgx.test(x1)) {
    if(z > 0) {
      x1 = x1.replace(rgx, '$1' + ',' + '$2');
    } else {
      x1 = x1.replace(rgx, '$1' + ',' + '$2');
      rgx = /(\d+)(\d{2})/;
    }
    z++;
    num--;
    if(num == 0) break;
  }
  return x1 + x2;
}

window.updateWithCommas = function (value, selector) {
  var x = setInterval(function () {
  	if($(selector).html() !== '' && parseInt($(selector).html()) === value) {
	  	$(selector).html(window.inrFormat(value));
	  	clearInterval(x);
	  }
  }, 100);
}

ReactDOM.render(
	<Provider store={store}>
		<Routes />
	</Provider>,
	document.getElementById('root')
);