import React, {Component} from 'react';

export default class CausesBanner extends Component {
	render() {
		return (
			<div className="causes-banner-container">
				<div className="causes-banner-top">
					<div className="causes-banner-title">
						EXPLORE CAUSES
					</div>
				</div>
			</div>
		);
	}
}