import React, {Component} from 'react';
import {Navbar, NavItem, Nav, NavDropdown, MenuItem} from 'react-bootstrap';
import {Link} from 'react-router-dom';

const eraseCookie = require('../../../cookie.js').eraseCookie;
const readCookie = require('../../../cookie.js').readCookie;

export default class Header extends Component {
	logout() {
		eraseCookie('userData');
		eraseCookie('access_token');
		window.location.pathname = '/';
	}

	render() {
		return (
			<Navbar>
				<Navbar.Header>
					<Navbar.Brand>
						<Link to="/">
							<div className="navigation-logo"></div>
						</Link>
					</Navbar.Brand>
					<Navbar.Toggle />
				</Navbar.Header>
				<Navbar.Collapse>
					<Nav>
						<NavItem href="/csr/explore-projects">CSR Projects</NavItem>
						<NavItem href="/csr/explore-volunteer-opportunities">Volunteer Opportunities</NavItem>
						<NavItem href="/csr/contact">Get In Touch</NavItem>
					</Nav>
					<Nav pullRight>
						<NavDropdown title={JSON.parse(readCookie('userData')).organisationData.name} id="basic-nav-dropdown">
							<MenuItem href="/csr">Dashboard</MenuItem>
							<MenuItem href="/csr/profile">Profile</MenuItem>
							<MenuItem onClick={this.logout.bind(this)}>Logout</MenuItem>
						</NavDropdown>
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		);
	}
}