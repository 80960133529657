import React, { Component } from "react";
import { Row } from "react-bootstrap";
import $ from "jquery";
import swal from "sweetalert2";
import PlacesAutocomplete from "react-places-autocomplete";
var CryptoJS = require("crypto-js");
const createCookie = require("../../cookie.js").createCookie;
const google = window.google;

const domainArr = [
  "gmail.com",
  "yahoo.com",
  "yahoo.co.in",
  "rediffmail.com",
  "hotmail.com",
  "outlook.com"
];

export default class SignupSwitch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signInEmail: null,
      signInPassword: null,
      ngoSignUpUsername: null,
      ngoSignUpNgoName: null,
      ngoSignUpDesignation: null,
      ngoSignUpEmail: null,
      ngoSignUpPhone: null,
      ngoSignUpProfUrl: null,
      corpSignUpUsername: "",
      corpSignUpName: "",
      corpSignUpEmail: "",
      corpSignUpDesignation: "",
      corpSignUpPhone: "",
      corpSignUpLocation: "",
      corpSignUpUsernameError: "",
      corpSignUpNameError: "",
      corpSignUpEmailError: "",
      corpSignUpDesignationError: "",
      corpSignUpPhoneError: "",
      corpSignUpLocationError: ""
    };
    this.handleChange = this.handleChange.bind(this);
  }

  setInitialState() {
    if (!$("#invalid-details").hasClass("hidden"))
      $("#invalid-details").addClass("hidden");
    this.setState({
      signInEmail: null,
      signInPassword: null,
      ngoSignUpUsername: null,
      ngoSignUpNgoName: null,
      ngoSignUpDesignation: null,
      ngoSignUpEmail: null,
      ngoSignUpPhone: null,
      ngoSignUpProfUrl: null,
      corpSignUpUsername: "",
      corpSignUpName: "",
      corpSignUpEmail: "",
      corpSignUpDesignation: "",
      corpSignUpPhone: "",
      corpSignUpLocation: "",
      corpSignUpUsernameError: "",
      corpSignUpNameError: "",
      corpSignUpEmailError: "",
      corpSignUpDesignationError: "",
      corpSignUpPhoneError: "",
      corpSignUpLocationError: ""
    });
    this.onCorpSignUpLocationChange = corpSignUpLocation =>
      this.setState({ corpSignUpLocation });
  }

  changeLoginType(loginType) {
    this.setInitialState(this);
    this.props.changeLoginType(loginType);
  }

  cancelCorporateSignin(e) {
    let evt;
    if (!e) evt = window.event;
    evt.cancelBubble = true;
    if (evt.stopPropagation) evt.stopPropagation();
    this.setInitialState(this);
    this.props.cancelCorporateSignin();
  }

  flipNgoSignin(target) {
    this.setInitialState(this);
    this.props.flipNgoSignin(target);
  }

  flipCorporateSignin(target) {
    this.setInitialState(this);
    this.props.flipCorporateSignin(target);
  }

  handleChange(name, value) {
    if (name == "signInPassword") {
      if (value.key === "Enter") {
        if (this.state.signInEmail !== "" && this.state.signInPassword !== "")
          this.login("corporate");
      } else {
        this.setState({ [name]: value, [name + "Error"]: "" });
      }
    } else {
      this.setState({ [name]: value, [name + "Error"]: "" });
    }
  }

  handleSubmit(e) {
    if (e.key === "Enter") {
      if (this.state.signInEmail !== "" && this.state.signInPassword !== "")
        this.login("corporate");
    }
  }

  validateEmail(email) {
    let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  login(type) {
    let signInEmail = CryptoJS.AES.encrypt(this.state.signInEmail, process.env.REACT_APP_ENCRYPTION_KEYPHRASE).toString();
    let signInPassword = CryptoJS.AES.encrypt(this.state.signInPassword,process.env.REACT_APP_ENCRYPTION_KEYPHRASE).toString();

    if (signInEmail !== "" && signInPassword !== "" && signInEmail !== null && signInEmail !== signInPassword) {
      let data = {};
      data["email"] = signInEmail;
      data["password"] = signInPassword;
      data["type"] = type;
			data['enc'] = true;

      fetch(process.env.REACT_APP_API_URL + "/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      }).then(function (response) {
        return response.json();
      }).then(function (data) {
        if (data["status"] === "ok") {
          if (data.data.authorizedFor.hasOwnProperty("mis") || data.data.authorizedFor.hasOwnProperty("marketplace")) {
            createCookie("access_token", JSON.stringify(data.token), 7);
            createCookie("userData", JSON.stringify(data.data), 7);
            this.setInitialState(this);
            if (localStorage.getItem("walkthrough") === null) {
              var walkthroughJSON = {
                completed: false,
                data: [
                  {
                    id: "1",
                    title: "CSR Strategy Set-up",
                    btnTitle: "Set Strategy",
                    body:
                      "Configure your platform with you CSR Strategy or build one with this guided setup to align your social and business goals.",
                    url: "/csr-strategy-setup",
                    done: false
                  }, {
                    id: "2",
                    title: "Fiscal Year Budgeting",
                    btnTitle: "Allot Budget",
                    body:
                      "Allot CSR budget for the current fiscal year and plan the allocation proportion to suit your company’s social goals.",
                    url: "/fiscal-year-budgeting",
                    done: false
                  }, {
                    id: "3",
                    title: "Goal-wise Investment Planning",
                    btnTitle: "Set Goals",
                    body:
                      "Distribute your annual CSR budget across different social goals and agendas and track your investments & impact in each of the selected goals",
                    url: "/portfolio",
                    done: false
                  }, {
                    id: "4",
                    title: "Project Discovery And Pipeline",
                    btnTitle: "Browse Projects",
                    body:
                      "Discover LE assured projects,partner projects & global social innovations and manage the project status with the streamlined project funnel.",
                    url: "/discover/projects",
                    done: false
                  }, {
                    id: "5",
                    title: "Impact Monitoring",
                    btnTitle: "View Impact",
                    body:
                      "Gauge the impact your investments have made and plan further actions with the visual charts, real time project updates and milestones",
                    url: "/investments",
                    done: false
                  }, {
                    id: "6",
                    title: "CSR-Corner",
                    btnTitle: "Read More",
                    body:
                      "Catch up with the latest sector trends and insights from across the country every hour,day and week.",
                    url: "/csr-corner",
                    done: false
                  }
                ],
                completedSteps: []
              };

              localStorage.setItem("walkthrough", JSON.stringify(walkthroughJSON), 7);
            } else if(localStorage.getItem("walkthrough") !== null) {
              let walkthrough = JSON.parse(localStorage.getItem("walkthrough"));
              walkthrough.completed = true;
              localStorage.setItem("walkthrough", JSON.stringify(walkthrough), 7);
            }
            if (data.data.hasCustomPassword) window.location.pathname = "/";
            else window.location.pathname = "/change-password";
          } else {
            $("#invalid-details-corp").removeClass("hidden");
          }
        } else if (data["status"] === "error") {
          $("#invalid-details-corp").removeClass("hidden");
          if (data["code"] === 1) {
            swal(
              "Email Verification Pending!",
              "Kindly check the verification link sent to your official email ID.",
              "warning"
            );
          } else if (data["code"] === 2) {
            swal(
              "Verification Pending!",
              "We are in the process of verifying your email. You will receive a password shortly.",
              "warning"
            );
          } else if (data["code"] === 3) {
            $("#invalid-details-corp").removeClass("hidden");
          } else {
            swal(
              "Error!",
              "We are facing some issue. Kindly try after some time.",
              "warning"
            );
          }
        }
      }.bind(this));
    }
  }

  signup(type) {
    let data = {};
    if (type === "ngo") {
      data["fullName"] = this.state.ngoSignUpUsername;
      data["organisationName"] = this.state.ngoSignUpNgoName;
      data["email"] = this.state.ngoSignUpEmail;
      data["mobileNo"] = this.state.ngoSignUpPhone;
      data["designationAtOrganisation"] = this.state.ngoSignUpDesignation;
      data["le_ngo_id"] = this.state.ngoSignUpProfUrl.split("/").pop();
      data["type"] = type;
      fetch(process.env.REACT_APP_API_URL + "/signup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      }).then(function (response) {
        return response.json();
      }).then(function (data) {
        if (data["status"] === "ok") {
          this.setInitialState(this);
          swal(
            "Thank you for the information.",
            "An email with a verification has been sent to your organization's email ID.",
            "success"
          );
        } else if (data["status"] === "error") {
          swal("Error!", data.message, "warning");
        }
      });
    } else if (type === "corporate") {
      if (this.state.corpSignUpUsername !== "" && this.state.corpSignUpName !== "" && this.state.corpSignUpEmail !== "" && this.state.corpSignUpDesignation !== "" && this.state.corpSignUpPhone !== "" && this.state.corpSignUpLocation !== "") {
        data["fullName"] = this.state.corpSignUpUsername;
        data["organisationName"] = this.state.corpSignUpName;
        data["email"] = this.state.corpSignUpEmail;
        data["designationAtOrganisation"] = this.state.corpSignUpDesignation;
        data["mobileNo"] = this.state.corpSignUpPhone;
        data["organisationLocation"] = this.state.corpSignUpLocation;
        data["type"] = type;
        fetch(process.env.REACT_APP_API_URL + "/signup", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(data)
        }).then(function (response) {
          return response.json();
        }).then(function (data) {
          if (data["status"] === "ok") {
            swal(
              "Thank you for the information.",
              "An email with a verification has been sent to your organization's email ID.",
              "success"
            );
          } else if (data["status"] === "error") {
            swal("Error!", data.message, "warning");
          }
        });
      } else {
        let phoneRegex = new RegExp(/^(?:(?:\+|0{0,2})91(\s*[\ -]\s*)?|[0]?)?[789]\d{9}|(\d[ -]?){10}\d$/gm);
        if (this.state.corpSignUpUsername === "" || this.state.corpSignUpUsername === null) this.setState({ corpSignUpUsernameError: "error" });
        if (this.state.corpSignUpName === "" || this.state.corpSignUpName === null) this.setState({ corpSignUpNameError: "error" });
        if (this.state.corpSignUpEmail === "" || this.state.corpSignUpEmail === null) this.setState({ corpSignUpEmailError: "error" });
        if (this.state.corpSignUpDesignation === "" || this.state.corpSignUpDesignation === null) this.setState({ corpSignUpDesignationError: "error" });
        if (this.state.corpSignUpPhone === "" || this.state.corpSignUpPhone === null || !phoneRegex.test(this.state.corpSignUpPhone)) this.setState({ corpSignUpPhoneError: "error" });
        if (this.state.corpSignUpLocation === "" || this.state.corpSignUpLocation === null) this.setState({ corpSignUpLocationError: "error" });
      }
    }
  }

  componentDidMount() {
    $("#corporateEmail").on("blur", function () {
      let regx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      let email = $(this).val();
      let type = $(this).data("type");

      let data = {
        email: email,
        type: type
      };

      let canSignup = false;

      if (regx.test(email)) {
        let i = email.indexOf("@");
        if (i !== -1) {
          let domain = email.substring(i + 1);

          if (domainArr.indexOf(domain) > -1) {
            $("#signupCorporate").prop("disabled", true);
            $("#already-signed-up").removeClass("hidden");
            $("#already-signed-up").find("span").html("Please enter an official email id");
          } else {
            $("#signupCorporate").prop("disabled", false);
            fetch(process.env.REACT_APP_API_URL + "/check-user-existence", {
              method: "POST",
              headers: {
                "Content-Type": "application/json"
              },
              body: JSON.stringify(data)
            }).then(function (response) {
              return response.json();
            }).then(function(data) {
              if (data["status"] === "ok") {
                $("#signupCorporate").prop("disabled", false);
                $("#already-signed-up").addClass("hidden");
              } else if (data["status"] === "error" && data["message"] === "User already exists") {
                $("#signupCorporate").prop("disabled", true);
                $("#already-signed-up").removeClass("hidden");
                $("#already-signed-up").find("span").html("You have already signed up");
              }
            }.bind(this));
          }
        }
      }
    });

    $(".forgot-link").on("click", function () {
      swal({
        title: "Forgot Password?",
        text: "Please enter your Email",
        input: "email",
        showCancelButton: true,
        confirmButtonText: "Submit",
        showLoaderOnConfirm: true,
        preConfirm: function (email) {
          return new Promise(function (resolve, reject) {
            var data = {
              email,
              type: 'corporate',
              resetUrl: window.location.origin + '/reset-password/'
            };
            fetch(process.env.REACT_APP_API_URL + "/forgot-password", {
              method: "POST",
              headers: {
                "Content-Type": "application/json"
              },
              body: JSON.stringify(data)
            }).then(function (response) {
              return response.json();
            }).then(function(data) {
              if(data["status"] === "ok") {
                $("#signupCorporate").prop("disabled", false);
                $("#already-signed-up").addClass("hidden");
                resolve();
              } else if (data["status"] === "error") {
                $("#signupCorporate").prop("disabled", true);
                $("#already-signed-up").removeClass("hidden");
                $("#already-signed-up").find("span").html("You have already signed up");
                reject(data);
              }
            }.bind(this));
          });
        },
        allowOutsideClick: false
      }).then(function(res) {
        if(!res.dismiss){
          swal({
            type: "success",
            title: "Password Reset Mail sent",
            html: "Please check your email. We have sent you a link for resetting your password."
          });
        }
        // else { null }
      }.bind(this)).catch(function(data) {
        swal({
          type: "error",
          title: "Password Reset Failed",
          html: data.message
        });
      });
    });
  }

  render() {
    const inputProps = {
      value: this.state.corpSignUpLocation,
      onChange: this.onCorpSignUpLocationChange
    };

    const options = {
      types: ["(cities)"]
    };

    const cssClasses = {
      input: "form-control" + this.state.corpSignUpLocationError,
      autocompleteContainer: "my-autocomplete-container"
    };

    return (
      <div className="signup-switcher">
        <div className="forms-container">
          <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
            <h1 style={{ color: "#fff", marginBottom: 10 }}>CSREdge</h1>
            <p style={{ color: "#fff", fontSize: "20px", marginBottom: 10, width: "60%", textAlign: "center" }}>Streamline your CSR initiatives and investments, track financial and impact metrics and maximize social return.</p>
          </div>
          <div className="signup-switch-corporate-forms">
            <div className="flip-container">
              <div className="flipper">
                <div className="front">
                  <Row className="signin-container" style={{ boxShadow: "#eee 5px 3px 50px", padding: "10px 20px", borderRadius: "7px", background: "#fff" }}>
                    <h1 className="signup-header">Login</h1>
                    <form>
                      <div className="form-group">
                        <label className="control-label is-imp">Official Email:</label>
                        <input type="email" autoComplete="off" className="form-control" placeholder="example@domain.com" required onChange={e => { this.handleChange("signInEmail", e.target.value) }} />
                      </div>

                      <div className="form-group">
                        <label className="control-label is-imp">Password:</label>
                        <input type="password" className="form-control" placeholder="password" required onChange={e => { this.handleChange("signInPassword", e.target.value) }} onKeyDown={this.handleSubmit.bind(this)} />
                      </div>
                    </form>

                    <div className="form-group hidden" id="invalid-details-corp">
                      <span>Invalid user details</span>
                    </div>

                    <div className="form-group">
                      <button id="signinCorporate" className="btn btn-center submit-btn" onClick={this.login.bind(this, "corporate")}>
                        Sign In
                      </button>
                    </div>

                    <div className="form-group extra-links">
                      <span className="signup-link" onClick={e => this.flipCorporateSignin(e.target)}>
                        New here? Sign-up
                      </span>
                      <span className="forgot-link">Forgot Password?</span>
                    </div>
                  </Row>
                </div>
                <div className="back" style={{ boxShadow: "#eee 5px 3px 50px", borderRadius: "7px", background: "#fff" }}>
                  <p className="signup-header" style={{ marginBottom: "0" }}>CORPORATE SIGNUP</p>
                  <Row className="signin-container">
                    <div className="form-group">
                      <label className="control-label is-imp">Your Name:</label>
                      <input type="text" className={"form-control " + this.state.corpSignUpUsernameError} placeholder="John Doe" value={this.state.corpSignUpUsername} required
                        onChange={e => { this.handleChange("corpSignUpUsername", e.target.value); }} />
                    </div>

                    <div className="form-group">
                      <label className="control-label is-imp">
                        Company Name:
                      </label>
                      <input
                        type="text"
                        className={
                          "form-control " + this.state.corpSignUpNameError
                        }
                        placeholder="LetsEndorse"
                        value={this.state.corpSignUpName}
                        required
                        onChange={e => {
                          this.handleChange("corpSignUpName", e.target.value);
                        }}
                      />
                    </div>

                    <div className="form-group">
                      <label className="control-label is-imp">
                        Official Email:
                      </label>
                      <input
                        type="email"
                        id="corporateEmail"
                        data-type="corporate"
                        className={
                          "form-control " + this.state.corpSignUpEmailError
                        }
                        value={this.state.corpSignUpEmail}
                        placeholder="example@domain.com"
                        required
                        onChange={e => {
                          this.handleChange("corpSignUpEmail", e.target.value);
                        }}
                      />
                    </div>

                    <div className="form-group">
                      <label className="control-label is-imp">
                        Your Designation:
                      </label>
                      <input
                        type="text"
                        className={
                          "form-control " +
                          this.state.corpSignUpDesignationError
                        }
                        placeholder="Manager"
                        value={this.state.corpSignUpDesignation}
                        required
                        onChange={e => {
                          this.handleChange(
                            "corpSignUpDesignation",
                            e.target.value
                          );
                        }}
                      />
                    </div>

                    <div className="form-group">
                      <label className="control-label is-imp">
                        Phone Number:
                      </label>
                      <input
                        type="number"
                        className={
                          "form-control " + this.state.corpSignUpPhoneError
                        }
                        placeholder="9119229334"
                        value={this.state.corpSignUpPhone}
                        required
                        onChange={e => {
                          this.handleChange("corpSignUpPhone", e.target.value);
                        }}
                      />
                    </div>

                    <div className="form-group mb15">
                      <label className="control-label is-imp">
                        Where is your company located:
                      </label>
                      <PlacesAutocomplete
                        inputProps={inputProps}
                        classNames={cssClasses}
                        options={options}
                      />
                    </div>
                  </Row>
                  <Row>
                    <div className="form-group hidden" id="already-signed-up">
                      <span />
                    </div>
                  </Row>
                  <div className="form-group">
                    <button
                      id="signupCorporate"
                      className="btn btn-center submit-btn"
                      onClick={this.signup.bind(this, "corporate")}
                    >
                      Sign Up
                    </button>
                  </div>

                  <div className="form-group extra-links">
                    <span
                      className="signup-link"
                      onClick={e => this.flipCorporateSignin(e.target)}
                      style={{ marginTop: "10px" }}
                    >
                      Already have an account? Sign-in
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <p style={{ marginBottom: "-14px", marginLeft: "-25px", fontSize: "15px", fontWeight: "500" }}>Powered by</p>
            <img className='signup-logo' src="/img/endorselogo.png" alt="logo" />
          </div>
        </div>
      </div>
    );
  }

  cancelNgoSignin(e) {
    let evt;
    if (!e) evt = window.event;
    evt.cancelBubble = true;
    if (evt.stopPropagation) evt.stopPropagation();
    this.setInitialState(this);
    this.props.cancelNgoSignin();
  }
}
