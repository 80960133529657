import React, { Component } from 'react';
import swal from 'sweetalert2'

const readCookie = require('../../../cookie.js').readCookie;
const createCookie = require('../../../cookie.js').createCookie;


export default class UserProfile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			mobileNo: '',
			fullName: '',
			department: '',
			profilePicture: '',
			designationAtOrganisation: ''
		}
	}

	componentDidMount() {
		fetch(process.env.REACT_APP_API_URL + '/get-user-data', {
			method: 'get',
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token,
			},
		}).then(data => data.json())
			.then(data => {
				this.setState({
					email: data.data.email || '',
					fullName: data.data.fullName || '',
					mobileNo: data.data.mobileNo || '',
					department: data.data.department || '',
					profilePicture: data.data.profilePicture,
					designationAtOrganisation: data.data.designationAtOrganisation || ''
				});
				this.props.userProfile(data.data)
			});
	}

	onSave = () => {
		let data = {};
		data.fullName = this.state.fullName;
		data.mobileNo = this.state.mobileNo;
		// data.department = this.state.department;
		data.profilePicture = this.state.profilePicture;
		data.designationAtOrganisation = this.state.designationAtOrganisation;
		fetch(process.env.REACT_APP_API_URL + '/save-user-data', {
			method: 'POST',
			headers: {
				"Content-Type": "application/json",
				'Auth': JSON.parse(readCookie('access_token')).access_token,
			},
			body: JSON.stringify(data)
		}).then(function (response) {
			return response.json();
		}).then(function (response) {
			let userData = JSON.parse(readCookie("userData"));
			userData.fullName = data.fullName;
			userData.mobileNo = data.mobileNo;
			// userData.department = data.department;
			userData.designationAtOrganisation = data.designationAtOrganisation;
			userData.profilePicture = data.profilePicture;
			createCookie('userData', JSON.stringify(userData), 7);
			if (response.status === 'ok') {
				swal({
					title: 'Your details have been saved!',
					type: 'success'
				}).then(() => {
					window.location.pathname = "/settings"
				});
			} else {
				swal({
					type: 'error',
					title: 'Oops...',
					text: 'Something went wrong!',
				});
			}
		});
	}

	upload = (e) => {
		let data = new FormData();
		data.append("image", e.target.files[0]);

		fetch(process.env.REACT_APP_API_URL + '/uploadImage', {
			method: "POST",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token,
			},
			body: data
		}).then((data) => data.json())
			.then(function (data) {
				this.setState({ profilePicture: data.url });
			}.bind(this))
	}

	onChange = (name, e) => {
		if (name !== "designation") {
			this.setState({ [name]: e.target.value });
		} else {
			this.setState({ designationAtOrganisation: e.target.value });
		}
	}

	render() {
		return (
			<div className='ee-page-container'>
				<div className='profile-modal-bg'>
					<div className='profile-modal'>
						<div className='modal-img-container'>
							<div style={{ width: "150px", height: "150px", borderRadius: '50%', overflow: "hidden", border: 'solid 1px #eee' }}>
								{this.state.profilePicture ? (
									<img className="ee-header-user-profile-img" src={this.state.profilePicture} />
								) : (
										<img className="ee-header-user-profile-img" src='/img/user.png' />
									)}
							</div>
							<div className="image-upload">
								<label htmlFor="file-input" style={{ margin: '0' }}>
									<i className='fa fa-camera' style={{ cursor: "pointer", marginRight: '8px', fontSize: "18px", background: '#fff', padding: '5px', borderRadius: '50%', boxShadow: "0px 1px 7px #888" }}><input onChange={this.upload} className='file-input' id="file-input" type="file" accept="image/*" /></i>
								</label>
								<input className='file-input' id="file-input" type="file" accept="image/*" />
								<p className='change-image'>Change your profile Image</p>
							</div>
						</div>

						<div className='proifle-email-container profile-uneditable-container'>
							<h3 className='profile-email'>Email :</h3>
							<p className='profile-email-input'>{this.state.email}</p>
						</div>

						<div className='proifle-name-container profile-editable-container'>
							<h3 className='profile-name'>Name :</h3>
							<input className='profile-name-input' type="text" onChange={this.onChange.bind(this, 'fullName')} placeholder="Full Name" value={this.state.fullName} />
						</div>

						<div className='proifle-phoneNo-container profile-editable-container'>
							<h3 className='profile-phoneNo'>Phone no. :</h3>
							<input className='profile-phoneNo-input' type="text" onChange={this.onChange.bind(this, 'mobileNo')} placeholder="Mobile No." value={this.state.mobileNo} />
						</div>

						{/* <div className='proifle-department-container profile-editable-container'>
							<h3 className='profile-department'>Department :</h3>
							<input className='profile-department-input' type="text" onChange={this.onChange.bind(this, 'department')} placeholder="Department" value={this.state.department} />
						</div> */}

						<div className='proifle-designation-container profile-editable-container'>
							<h3 className='profile-designation'>Designation :</h3>
							<input value={this.state.designationAtOrganisation} className='profile-designation-input' onChange={this.onChange.bind(this, 'designation')}></input>
						</div>
						<button className='profileSubmit' onClick={this.onSave} style={{ marginTop: '20px' }}>Save</button>
					</div>
				</div>
			</div>
		)
	}
}