import React, { Component } from "react";
import _ from 'lodash';
import io from "socket.io-client";
import UserProfile from "./UserProfile";
import { send } from "react-icons-kit/fa/send";
import SvgIcon from "react-icons-kit";
import { attachment } from "react-icons-kit/icomoon/attachment";
import { notification } from "react-icons-kit/icomoon/notification";
import { ic_arrow_drop_down } from "react-icons-kit/md/ic_arrow_drop_down";
import { search } from "react-icons-kit/icomoon/search";
import { Link } from "react-router-dom";
import $ from 'jquery';
import moment from 'moment';

const readCookie = require('../../../cookie.js').readCookie;

export default class ChatMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notFoundProject: null,
      showStartChat: false,
      selectedProjectId: null,
      projectList: [],
      _companyId: this.props.userData.organisationData.id,
      nameToDisplay: null,
      projectId: null,
      _projectInterest: '',
      _conversationId: '',
      discussion: '',
      start_date: '',
      conversation_status: '',
      message: "",
      chat: [],
      message: "",
      dummyMessage: "Some message",
      receiverMessage: "Yes! tell",
      selectedNgo: '',
      user_pik: "http://bpic.588ku.com/element_origin_min_pic/17/09/19/28e7312d32356cabb08cb36d5d232492.jpg",
      showthreadsInfoCol: false,
      socket: null,
      user: null,
      recent_chat: [],
      chatInfoColVisibility: true,
      showThreadsCol: true,
      screenWidth:""
    };
    this.startChat = this.startChat.bind(this);
   }


  componentWillMount() {
    this.setState({screenWidth: window.innerWidth})
    this.initSocket();
    if (this.props.match.params.hasOwnProperty('chatId') && this.props.match.params.chatId) {
      this.setState({ selectedProjectId: this.props.match.params.chatId });
    }
    fetch(process.env.REACT_APP_API_URL + "/chat/csr/" + this.state._companyId, {
      method: 'GET',
      headers: {
        'Auth': JSON.parse(readCookie('access_token')).access_token
      }
    }).then(res => (res.json()))
    .then(res => {
      let selected_proj = _.find(res.message_sent, (el, i) => (el._projectId === this.state.selectedProjectId));
      if (selected_proj !== undefined) {
        this.startChat(selected_proj);
      } else {
        this.setState({ notFoundProject: 'You can start a discussion after you move a project to interested stage' });
      }
      this.setState({ chat: res.message_sent }, function() {
        if(!this.props.match.params.hasOwnProperty('chatId') || !this.props.match.params.chatId) {
          this.startChat(res.message_sent[0]);
        }
      }.bind(this));

      fetch(process.env.REACT_APP_API_URL + '/company/' + this.state._companyId + '/mis/suggest-projects', {
        method: "GET",
        headers: {
          'Auth': JSON.parse(readCookie('access_token')).access_token
        }
      }).then((data) => data.json())
      .then(res => {
        this.setState({ projectList: res.data });
      }).catch(err => {
        // console.log("err", err)
      });
    }).catch(err => {
      // console.log("err", err);
    });
  }
initSocket = () => {
    const socket = io(process.env.REACT_APP_API_URL)
    socket.on('connect', () => {
      socket.emit('authentication', { token: JSON.parse(readCookie('access_token')).access_token });
      socket.on('authenticated', function () {
        this.setState({ socket });

        let csr_namespace = "CSR_" + this.state._companyId;
        socket.on(csr_namespace, (data) => {
          if(data._conversationId === this.state._conversationId) {
            let new_chat = this.state.recent_chat;
            new_chat.push(data);
            let uniq = _.uniq(new_chat, '_id');
            this.setState({ recent_chat: uniq, message: '' });
          }
          if(this.checkIfMessageReceived(data)) this.showNewMessageToast();
          else {
            var elem = $('.mis-content-container.chat-messages-content-container > .ChatMessageContainer > .chat > .chat-scroll')[0];
            elem.scrollTop = elem.scrollHeight;
          }
        });
      }.bind(this));
    });
  }
   
 

checkIfMessageReceived(data) {
    if (data.length === undefined && data.hasOwnProperty('receiver') && data.receiver === JSON.parse(readCookie('userData'))._organisationId) {
      return true;
    } else if(data.length) {
      var foundMessage = false;
      for(var i = 0; i < data.length; i++) {
        if(data[i].receiver === JSON.parse(readCookie('userData'))._organisationId) foundMessage = true;
      }
      return foundMessage;
    } else return false;
  }

  showNewMessageToast() {
    $('.mis-content-container.chat-messages-content-container > .ChatMessageContainer > .chat').append(`
      <div class="new-message-below-toast">
        New Message Below
        <i class="fa fa-angle-double-down"></i>
      </div>`);

    $('.new-message-below-toast').click(function() {
      var elem = $('.mis-content-container.chat-messages-content-container > .ChatMessageContainer > .chat > .chat-scroll');
      $(elem).animate({ scrollTop: elem[0].scrollHeight }, 500);
      $(this).remove();
    });

    $('.mis-content-container.chat-messages-content-container > .ChatMessageContainer > .chat > .chat-scroll').scroll(function() {
      if(($(this)[0].scrollTop + $('.mis-content-container.chat-messages-content-container > .ChatMessageContainer > .chat').height()) === $(this)[0].scrollHeight) $('.new-message-below-toast').remove();
    });
  }

  typeYourMessage = e => {
    this.setState({ message: e.target.value });
  }

  toggleChatInfoColVisibility = e => {
    this.setState({ chatInfoColVisibility: !this.state.chatInfoColVisibility });
  }

  sendMessageByEnterKey(event) {
    if (event.key === 'Enter' && this.state.message !== '') {
      const { socket } = this.state;
      let data = {
        "case": "saveLastChat",
        "_ngoId": this.state._ngoId,
        "_conversationId": this.state._conversationId,
        "_companyId": this.state._companyId,
        "discussion": this.state.message,
        "sender_name": this.props.userData.fullName,
        "sender": "CSR"
      }
      socket.emit("CHAT_HANDLERS", data);
      this.setState({ message: '' });
    }
  }

  sendMessage(event) {
    if(this.state.message !== '') {
      const { socket } = this.state;
      let data = {
        "case": "saveLastChat",
        "_ngoId": this.state._ngoId,
        "_conversationId": this.state._conversationId,
        "_companyId": this.state._companyId,
        "discussion": this.state.message,
        "sender_name": this.props.userData.fullName,
        "sender": "CSR"
      }
     socket.emit("CHAT_HANDLERS", data);
      this.setState({ message: '' });
    }
  }

  startChat(event, data) {
    this.setState({
      notFoundProject: null,
      showStartChat: true,
      showthreadsInfoCol: true,
      chatInfoColVisibility: true,
      _conversationId: event._conversationId,
      start_date: event.date,
      _projectInterest: event._projectId,
      _ngoId: event._ngoId,
      conversation_status: event.status
    });

    fetch(process.env.REACT_APP_API_URL + "/chat/csr/read/" + this.state._companyId, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Auth': JSON.parse(readCookie('access_token')).access_token
      },
      body: JSON.stringify({ "_conversationId": event._conversationId })
    }).then(res => (res.json()))
    .then(res => {
      let conversationId = event._conversationId;
      let nameToDisplay = _.filter(this.state.chat, (el, i) => (el._conversationId === conversationId));
      this.setState({
        recent_chat: res.data,
        nameToDisplay: nameToDisplay[0].name,
        projectId: nameToDisplay[0]._projectId
      }, function() {
        $(document).ready(function() {
          var elem = $('.mis-content-container.chat-messages-content-container > .ChatMessageContainer > .chat > .chat-scroll')[0];
          elem.scrollTop = elem.scrollHeight;

          $('.mis-content-container.chat-messages-content-container > .ChatMessageContainer > .chat > .chat-scroll').scroll(function() {
            if(($(this)[0].scrollTop + $('.mis-content-container.chat-messages-content-container > .ChatMessageContainer > .chat').height()) === $(this)[0].scrollHeight) $('.new-message-below-toast').remove();
          });
        });
      });

      fetch(process.env.REACT_APP_API_URL + "/chat/getNgo/" + event._ngoId, {
        method: 'GET',
        headers: {
          'Auth': JSON.parse(readCookie('access_token')).access_token
        }
      }).then(res => (res.json()))
      .then(res => {
        this.setState({ selectedNgo: res.message[0].name })
      }).catch(err => {
        console.log("err", err)
      });
    }).catch(err => {
      console.log("err", err);
    });
  }

  goToProject(event) {
    let data = _.filter(this.state.projectList, (el, i) => (el.id === this.state.projectId));
    if (data[0].customUrl) {
      let url = "/csr/csr-projects/" + data[0].customUrl;
      window.open(url, '_blank');
    } else {
      return;
    }
  }
  getChat_time(timestamp) {
    if(moment().format('DD/MM/YYYY') === moment.unix(timestamp / 1000).format('DD/MM/YYYY')) return moment.unix(timestamp / 1000).format('HH:mm');
    else if(moment().format('YYYY') !== moment.unix(timestamp / 1000).format('YYYY')) return moment.unix(timestamp / 1000).format('DD/MM/YYYY HH:mm');
    else return moment.unix(timestamp / 1000).format('DD/MM HH:mm')
  }
  getChatTime(timestamp) {
    if(moment().format('DD/MM/YYYY') === moment.unix(timestamp / 1000).format('DD/MM/YYYY')) return moment.unix(timestamp / 1000).format('HH:mm a');
    else if(moment().format('YYYY') !== moment.unix(timestamp / 1000).format('YYYY')) return moment.unix(timestamp / 1000).format('DD/MM/YYYY HH:mm a');
    else return moment.unix(timestamp / 1000).format('DD/MM HH:mm a')
  }

  render() {
    return (
      <div className="mis-content-container chat-messages-content-container">
        {this.state.chat.length ? (
          this.state.showThreadsCol || this.state.screenWidth > 500 ? (
            <div className="threadsCol">
              <div className="projectTitleDiv">Projects</div>
              <div className="col-md-12 threadDiv p0">
                <div className="col-md-12 NgoNameCol">
                  {_.map(this.state.chat, (chat, index) => (
                    <div className={this.state._projectInterest === chat._projectId ? "projectTitleContainer selected" : "projectTitleContainer"} style={{ cursor: "pointer" }} key={index + "chat"} onClick={this.startChat.bind(this, chat)}>
                      <div className="projectTitle">
                        <h4 className={this.state._projectInterest === chat._projectId ? "NgoName-2" : "NgoName-1"}>
                          {chat.name}
                        </h4>
                      </div>
                      <p className="NgoMessage">{chat.message}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (null)
        ) : (null)}
        <div className={this.state.chatInfoColVisibility ? "ChatMessageContainer" : "ChatMessageContainer chat-info-col-hidden"}>
          {this.state.screenWidth < 500 ? <i className={this.state.showThreadsCol === false ? "fa fa-angle-double-right showThreadsCol-button" : "fa fa-angle-double-left showThreadsCol-button"} onClick={() => {this.setState({showThreadsCol: !this.state.showThreadsCol})}}></i> : null }
          {this.state.chat.length ? (
            <div className="projectTitleDiv">
              <h3 className="projectTitle">
                {this.state.nameToDisplay}
              </h3>
            </div>
          ) : (null)}
          <div className={this.state.showStartChat ? "chat" : "chat no-chat"}>
            <div className="chat-scroll">
              {_.map(this.state.recent_chat, (chat, index) => {
              if (chat.sender === JSON.parse(readCookie('userData'))._organisationId && chat.discussion) {
                  return (
                    <div className="row user-message-reply" key={index + "reply"}>
                      <div>
                        {this.state.recent_chat[index - 1] === undefined ? (
                          <p className="mb5" style={{ fontSize: 12 }}>{(chat.sender_name !== null || chat.sender_name !== undefined) ? chat.sender_name : "Anonymous"}</p>
                        ) : (
                          this.state.recent_chat[index - 1].sender !== chat.sender ? (
                            <p className="mb5" style={{ fontSize: 12 }}>{(chat.sender_name !== null || chat.sender_name !== undefined) ? chat.sender_name : "Anonymous"}</p>
                          ) : (null)
                        )}
                          <p className="reply-message">{chat.discussion}<span>{this.getChatTime(chat.time)}</span></p>
                      </div>
                    </div>
                  )
                } else if (chat.discussion) {
                  return (
                    <div className="row user-message-incoming" key={index + "incoming"}>
                      <div>
                        {this.state.recent_chat[index - 1] === undefined ? (
                          <p className="mb5" style={{ fontSize: 12 }}>{(chat.sender_name !== null || chat.sender_name !== undefined) ? chat.sender_name : "Anonymous"}</p>
                        ) : (
                          this.state.recent_chat[index - 1].sender !== chat.sender ? (
                            <p className="mb5" style={{ fontSize: 12 }}>{(chat.sender_name !== null || chat.sender_name !== undefined) ? chat.sender_name : "Anonymous"}</p>
                          ) : (null)
                        )}
                        <p className="incoming-message">{chat.discussion}<span>{this.getChatTime(chat.time)}</span></p>
                      </div>
                    </div>
                  )
                } else return null;
              })}
              {this.state.selectedProjectId !== null && this.state.notFoundProject !== null ? (
                <div className="row user-message-incoming">
                  <p className="incoming-message">{this.state.notFoundProject}</p>
                </div>
              ) : (
                this.state.showStartChat ? (null) : (
                  this.state.chat.length ? (
                    <div className="row user-message-incoming text-center">
                      <p className="incoming-message">Please select the project from the left to begin your chat</p>
                    </div>
                  ) : (
                    <div className="row user-message-incoming text-center">
                      <p style={{fontSize: 16}}>No chats yet.<br/>Show interest on projects to start chatting with the NGO Partners</p>
                    </div>
                  )
                )
              )}
            </div>
          </div>

          {this.state.showStartChat ? (
            <div className="row chatForm">
              <div className="chat-input-col">
                <input value={this.state.message} className="chat-input" type="text" placeholder="Enter your text...." ref="message" onChange={this.typeYourMessage} onKeyPress={this.sendMessageByEnterKey.bind(this)} />
              </div>
              <div className="chat-button-col">
                <button className="chat-send" type="button" onClick={this.sendMessage.bind(this)}>
                  <SvgIcon className="sendIcon" size={25} icon={send} />
                </button>
              </div>
            </div>
          ) : (null)}
        </div>
        {this.state.showthreadsInfoCol ? (
          <div className={!this.state.chatInfoColVisibility ? "threadsInfoCol hide-info" :"threadsInfoCol" }>
            <div className="chat-info-col-toggler-container" onClick={this.toggleChatInfoColVisibility.bind(this)} title={this.state.chatInfoColVisibility ? (null) : "Show NGO Details"}>
              <i className={this.state.chatInfoColVisibility ? "fa fa-chevron-right" : "fa fa-chevron-right inverted"}></i>
            </div>
            <h3 className="projectTitleinfo">
              {this.state.selectedNgo}
            </h3>
            <hr className="infoDevider" />
            <div className="projectInfoContainer">
              {this.state.projectId !== null ? (
                <div className="projectInfo">
                  <button className="btn btn-danger" onClick={() => { window.location.pathname = "/mis-projects/" + this.state.projectId }}>Go to project</button>
                </div>
              ) : (null)}
            </div>
          </div>
        ) : null}
      </div>
    )
  }
}