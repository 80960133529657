import React, { Component } from 'react';
import $ from 'jquery';
import { Col, Row, Tab, Tabs, Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import SvgIcon from "react-icons-kit";
import { ic_list } from 'react-icons-kit/md/ic_list';
import {info} from 'react-icons-kit/entypo/info';
import Loader from '../Loader';
import Multiselect from 'react-bootstrap-multiselect';

const checkValue = require('../../../checkValue.js').checkValue;
const readCookie = require('../../../cookie.js').readCookie;

class partnerPage extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			ngoId:"",
			projects: [],
			activeTab : 1,
			partnerData : {},
			solicitations:[],
			isLoading: false,
			showModal: false,
			isChecked: false,
			solicitMessage:"",
			showLoader: true,
			loaderHiding: '',
		 	solicitSubject:"",
			certificateName: "",
			showSolicitForm: false,
			shareKpi: false,
			dontSharekpi: false,
			clusters: [],
			indicatorRows: [],
			selectedcols:[],
			kpiDropDownList:[],
			frequencyReport:"",
			clusterName:"",
			assessment:""
		}
	}

	componentWillMount(){
		fetch(process.env.REACT_APP_API_URL + `/ngo/${this.props.match.params.partnerId}/mis`,{
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
		.then((data) => {
			if(data.status === "ok"){
				this.setState({partnerData: data.data, isChecked: data.data.projectWithoutSolicitation, loaderHiding: 'hiding' }, function () {
					setTimeout(function () {
						this.setState({ showLoader: false });
					}.bind(this), 1500);
				}.bind(this));
			}
		})

		fetch(process.env.REACT_APP_API_URL + `/ngo/${this.props.match.params.partnerId}/mis/submitted-projects`,{
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
		.then((data) => {
			if(data.status === 'ok'){
				let projects = data.data;
				this.setState({projects})
			}
		})

		fetch (process.env.REACT_APP_API_URL + "/kpi-category",{
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
		.then((data) => {
			let clusters = [];
			clusters = (data.data);
			this.setState({clusters});
		});

		let indicatorRows = [], kpiDropDownList = [];
		fetch (process.env.REACT_APP_API_URL + "/kpi-indicator",{
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
		.then((data) => {
			for (let i = 0; i < data.data.length; i++) {
				indicatorRows.push({
					id:data.data[i]["indicatorId"],
					outputOutcome:data.data[i]["type"],
					unit: data.data[i]["unit"],
					indicator:data.data[i]["name"],
					format: data.data[i]["format"],
					kpiId: data.data[i]["id"],
					mode: "view",
					editOutputOutcome: data.data[i]["type"],
					editUnit: data.data[i]["unit"],
					editIndicator:data.data[i]["name"],
					editFormat:data.data[i]["format"],
					formula: data.data[i]["formula"],
					editFormulaInput: data.data[i]["formula"],
					durationalCumulative: data.data[i]["computationType"],
					editDurationalCumulative: data.data[i]["computationType"]
				})
				kpiDropDownList.push({
					label: data.data[i]["name"],
					value: data.data[i]["id"]
				})
			}
			this.setState({indicatorRows, kpiDropDownList});
		});
	}

	notifySuccess = (text) => toast.success(text);

  notifyError = (text) => toast.error(text);

	keyChange = (key) => {
		if(key !== this.state.activeTab) {
			this.setState({
				activeTab: key,
			});
		}
		if(key === 2 && !this.state.projects.length){
			fetch(process.env.REACT_APP_API_URL + `/ngo/${this.props.match.params.partnerId}/mis/submitted-projects`,{
				method: "GET",
				headers: {
					'Auth': JSON.parse(readCookie('access_token')).access_token
				}
			}).then((data) => data.json())
			.then((data) => {
				if(data.status === 'ok'){
					let projects = data.data;
					this.setState({projects})
				}
			})
		}
		if(key === 3 && !this.state.solicitations.length){
			fetch(process.env.REACT_APP_API_URL + `/ngo/${this.props.match.params.partnerId}/mis/solicitations`,{
				method: "GET",
				headers: {
					'Auth': JSON.parse(readCookie('access_token')).access_token
				}
			}).then((data) => data.json())
			.then((data) => {
				if(data.status === 'ok'){
					let solicitations = data.data;
					for(let i = 0; i < solicitations.length; i++){
						solicitations[i]['expand'] = false;
					}
					this.setState({solicitations})
				}
			})
		}
	}

	handleSubmit = () => {
		let data = {};
		if(this.state.solicitSubject !== "" && this.state.solicitMessage !== "" && this.state.ngoId !== ""){
			data["subject"] = this.state.solicitSubject;
			data["message"] = this.state.solicitMessage;;
			data["ngoId"] = this.state.ngoId;
			if (this.state.sharekpi === true && (this.state.clusterName !== "" || this.state.selectedcols.length > 0)  && this.state.frequencyReport !== "") {
				data["clusterId"] = this.state.clusterName;
				data["indicators"] = this.state.selectedcols;
				data["reportingFrequency"] = this.state.frequencyReport;
			} else if (this.state.dontSharekpi === true && this.state.frequencyReport !== "" && this.state.assessment !== "") {
				data["reportingFrequency"] = this.state.frequencyReport;
				data["computationType"] = this.state.assessment;
			} else {
				this.notifyError('Please provide mandatory Values');
				return
			}
			let companyId = JSON.parse(readCookie('userData')).organisationData.id;
			this.setState({isLoading: true})
			fetch(process.env.REACT_APP_API_URL + "/company/:" + companyId + "/mis/solicit-project",{
				method: "POST",
				headers:{
					'Content-type': 'application/json',
	        'Auth': JSON.parse(readCookie('access_token')).access_token
				},
				body: JSON.stringify(data)
			}).then((data) => data.json())
			.then((data) => {
				if (data.status === "ok") {
					this.notifySuccess('Success, request has been sent.');
					this.setState({isLoading: false, showSolicitForm: false, solicitSubject:"", solicitMessage:"", clusterName:"", selectedcols:[], frequencyReport:"", assessment:"", sharekpi: "", dontSharekpi: false});
				} else {
					this.notifyError(data.message);
					this.setState({isLoading: false,});
				}
			})
		} else {
			this.notifyError('Please provide mandatory Values');
		}
	}

	expand = (i) => {
		let solicitations = this.state.solicitations;
		solicitations[i].expand = !solicitations[i].expand;
		this.setState({ solicitations });
	}

	hideModal = () => {
		this.setState({showModal: false})
	}

	showModal = (name) => {
		this.setState({showModal: true, certificateName: name})
	}

	solicitProject = (id) => {
		this.setState({showSolicitForm: true, ngoId:id});
	}
	hideSolicitProject = (i) => {
		this.setState({isLoading: false, showSolicitForm: false, solicitSubject:"", solicitMessage:"", ngoId:"", clusterName:"", selectedcols:[], frequencyReport:"", assessment:"", sharekpi: false, dontSharekpi: false});
	}

	onChange = (e) => {
		this.setState({[e.target.name] : e.target.value});
	}

	projectWithoutSolicitation = () => {
		let data = {};
		data['allowed'] = !this.state.isChecked;
		fetch(process.env.REACT_APP_API_URL + `/ngo/${this.props.match.params.partnerId}/mis/project-solicitations`,{
			method: "PUT",
			headers:{
					'Content-type': 'application/json',
          'Auth': JSON.parse(readCookie('access_token')).access_token
				},
				body: JSON.stringify(data)
			}).then((data) => data.json())
			.then((data) => {
				if(data.status === "ok"){
					this.setState({isChecked: !this.state.isChecked});
					this.state.isChecked ? this.notifySuccess('You, Allowed NGO to submit projects without solicitation.') : this.notifySuccess('You, Denied NGO to submit projects without solicitation.');
				} else {
					this.notifyError('Failed');
				}
			})
	}

	sharekpi = (e) => {
		if (e.target.name === "sharekpi") {
			this.setState({sharekpi: true, dontSharekpi: false, assessment:"", frequencyReport:""});
		} else {
			this.setState({dontSharekpi: true, sharekpi: false, clusterName:"", selectedcols:[], frequencyReport:""});
		}
	}

	handleColChange = (e) => {
		this.setState({ selectedcols: $('select#colsTo').val()})
  }

  frequencyReport = (e) => {
  	this.setState({[e.target.name]: e.target.value});
  }

	render() {
		return (
			<div className="mis-content-container">
				{this.state.showLoader ? (
					<Loader loaderHiding={this.state.loaderHiding} />
				) : ('')}
				<ToastContainer position="bottom-center" autoClose={3000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={true} />
				<Modal className="explore-ngo-modal" show={this.state.showModal}
  				onHide={this.hideModal} bsSize="large">
  				<Modal.Body>
	 					{this.state.certificateName === "Certificate_12_A" ? <embed style={{width:"100%", height:"1000px"}} src={this.state.partnerData.Certificate_12_A} alt="pdf" pluginspage="http://www.adobe.com/products/acrobat/readstep2.html" /> : null}
	 					{this.state.certificateName !== "Certificate_12_A" ? <img className='certificate-img' style={{width:"870px"}} src={this.state.certificateName === "registrationCertificate" ? this.state.partnerData.registrationCertificate : this.state.certificateName === "Certificate_FCRA" ? this.state.partnerData.Certificate_FCRA : this.state.certificateName === "Certificate_80_G" ? this.state.partnerData.Certificate_80_G : null} alt=""/> : null}
 					</Modal.Body>
  			</Modal>
  			 <Modal className="mis-content-container invitations-modal" style={{width:"70%", margin: "0 auto"}} show={this.state.showSolicitForm} onHide={this.hideSolicitProject} bsSize="large">
 		 				<Modal.Header>
   						<Modal.Title className="modal-header-custom">
   							Solicit Project from Partner NGO
							</Modal.Title>
						</Modal.Header>
						<Modal.Body className='invitations-modal-main-container'>
							<Row>
                <Col md={5} className="invitations-modal-select-section form-group m0">
                  <label className="invitations-modal-ngosug-heading control-label is-imp">Subject</label>
                  <input type="text" name='solicitSubject' placeholder="Enter Subject" style={{ width: 'calc(100% - 40px)' }} value={this.state.solicitSubject} className="invitations-modal-poc-name form-control" onChange={this.onChange} />
                </Col>
							</Row>
							<Row>
								<Col md={12} className="invitations-modal-note-section form-group">
                  <label className="invitations-modal-note-heading control-label is-imp">Message</label>
                  <textarea type="text" placeholder="Mention the specific project you want to solicit from the partner" value={this.state.solicitMessage} name='solicitMessage' className="invitations-modal-note-text form-control" onChange={this.onChange}></textarea>
                  {/*this.state.error.indexOf('note') > -1 ? (
                    <div className="invitations-modal-note-error" style={{color: 'red'}}>This field cannot be empty</div>
                  ) : (null)*/}
                </Col>
							</Row>
							<Row>
                <Col md={12} className="invitations-modal-select-section form-group m0">
	     						<label style={{marginBottom:"0px"}} htmlFor="">Do you want to share KPIs with your partner?</label>
	     						<div style={{display: "flex", alignItems:"center"}}>
	     							<div style={{display:"flex", alignItems:"center"}}>
		     							<label style={{marginBottom:"0px"}} htmlFor="">Yes</label>
	                		<input type="radio" style={{margin: "0px 0px 0px 5px"}} checked={this.state.sharekpi === true ? true : false} name='sharekpi' onChange={this.sharekpi}/>
		     						</div>
		     						<div style={{display:"flex", alignItems:"center", marginLeft:"10px"}}>
		     							<label style={{marginBottom:"0px"}} htmlFor="">No</label>
	                		<input type="radio" style={{margin: "0px 0px 0px 5px"}} checked={this.state.dontSharekpi === true ? true : false} name='dontShareKpi' onChange={this.sharekpi}/>
		     						</div>
	     						</div>
	     						<div style={ this.state.sharekpi === true ? {display:"flex", alignItems:"center", justifyContent: "space-between"} : {display:"flex", alignItems:"center", justifyContent: "flex-start"}}>
	     							{this.state.sharekpi === true && this.state.indicatorRows.length > 0 ? (
			     						<div style={{marginTop:"10px", display: "flex", flexDirection:"column"}}>
			     							<label style={{marginRight:"10px"}} htmlFor="">Select Cluster:</label>
				     						<select style={{borderRadius:"4px"}} name='clusterName' onChange={this.frequencyReport}>
				     							<option selected disabled value="">Select Clusters</option>
				     							{this.state.clusters.map((cluster,i) => {
				     								if (cluster.totalIndicators > 0) {
				     									return (
				     										<option key={i} value={cluster.id}>{cluster.name}</option>
				     									)
				     								}
			     								})}
				     						</select>
			     						</div>
		     						) : (null)}
		     						{this.state.sharekpi === true && this.state.indicatorRows.length > 0 ? (
			     						<div style={{marginTop:"10px", marginLeft:"10px", display: "flex", flexDirection:"column"}}>
			     							<label style={{marginRight:"10px"}} htmlFor="">Select Kpi:</label>
				     						<Multiselect id="colsTo" onChange={this.handleColChange} data={this.state.kpiDropDownList} buttonWidth={'300px'} multiple enableClickableOptGroups enableCollapsibleOptGroups numberDisplayed={3} includeSelectAllOption onSelectAll={this.handleColChange} onDeselectAll={this.handleColChange} />
			     						</div>
		     						) : (null)}
		     						{this.state.sharekpi === true && this.state.indicatorRows.length > 0 ? (
			     						<div style={{marginTop:"10px", marginLeft:"10px", display: "flex", flexDirection: "column"}}>
			     							<label style={{marginRight:"10px"}} htmlFor="">Select Frequency of reporting:</label>
				     						<select style={{borderRadius:"4px"}} name="frequencyReport" onChange={this.frequencyReport}>
				     							<option selected disabled value="">Select Frequency of reporting</option>
				     							<option value="weekly">Weekly</option>
				     							<option value="monthly">Monthly</option>
				     							<option value="yearly">Yearly</option>
				     							<option value="flexible">Flexible</option>
				     						</select>
			     						</div>
		     						) : (null)}
		     						{this.state.dontSharekpi === true ? (
			     						<div style={ this.state.sharekpi === true ? {marginTop:"10px", marginLeft:"10px", display: "flex", flexDirection: "column"} : {marginTop:"10px", display: "flex", flexDirection: "column"}}>
			     							<label style={{marginRight:"10px"}} htmlFor="">Select Frequency of reporting:</label>
				     						<select style={{borderRadius:"4px"}} name="frequencyReport" onChange={this.frequencyReport}>
				     							<option selected disabled value="">Select Frequency of reporting</option>
				     							<option value="weekly">Weekly</option>
				     							<option value="monthly">Monthly</option>
				     							<option value="yearly">Yearly</option>
				     							<option value="flexible">Flexible</option>
				     						</select>
			     						</div>
		     						) : (null)}
		     						{this.state.dontSharekpi === true ? (
			     						<div style={{marginTop:"10px", marginLeft:"40px", display: "flex", flexDirection: "column"}}>
			     							<label style={{marginRight:"10px"}} htmlFor="">Select Assessment:</label>
				     						<select style={{borderRadius:"4px"}} name="assessment" onChange={this.frequencyReport}>
				     							<option selected disabled value="">Select Assessment</option>
				     							<option value="durational">Durational</option>
				     							<option value="cumulative">Cumulative</option>
				     						</select>
			     						</div>
		     						) : (null)}
	     						</div>
     							{this.state.sharekpi === true && this.state.indicatorRows.length < 0 ? (
	     							<div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent:"center"}}>
	     								<p>You must add KPIs before sending to your partners</p>
	     								<button className='btn solicit-modal-btn' style={{backgroundColor:"#ef5a20", color:"#fff"}} onClick={() => {window.location.pathname='/indicators'}}>Add KPIs</button>
	     							</div>
     							) : (null)}
                </Col>
							</Row>
						</Modal.Body>
						<Modal.Footer>
							<Row>
								<Col md={12} className="solicit-buttons-container">
									<button className={this.state.isLoading === true ? "btn solicit-modal-btn-loading" : "btn solicit-modal-btn"} onClick={this.handleSubmit}>{this.state.isLoading === true ? <img src="/img/Spinner-white.svg" style={{width: "27px"}} alt="loading"/> : "Submit"}</button>
									<button className="btn solicit-cancel-button" onClick={this.hideSolicitProject}>Cancel</button>
								</Col>
							</Row>
						</Modal.Footer>
					</Modal>
				<div style={{marginBottom:"20px"}}>
					<button style={{border:"none", borderRadius:"50px", padding:"3px 10px"}} onClick={()=>{window.location.pathname="/discover/partner-projects"}}><i className="fa fa-arrow-left"></i> Back</button>
				</div>
				<div className='ngo-profile'>
					<div style={{display:"flex", alignItems:"center"}}>
						<div className='ngo-profile-img'>
							<img className='ngo-image' src={this.state.partnerData.logo} alt=""/>
						</div>
						<div className='ngo-profile-info'>
							<h3 style={{marginBottom: "5px"}}>{this.state.partnerData.name}</h3>
							<h4 style={{marginBottom: "5px"}}>City: <span>{this.state.partnerData.ngoCity}</span></h4>
							<h4 style={{marginBottom: "5px"}}>State: <span>{this.state.partnerData.ngoState}</span></h4>
							{(this.state.partnerData.ngoFacebookUrl) && (this.state.partnerData.ngoInstagramUrl) && (this.state.partnerData.ngoLinkedinUrl) ? (
								<div style={{display:"flex", alignItems: "center"}}>
									<a href={this.state.partnerData.ngoFacebookUrl} className="fa fa-facebook-f partner-social-handle"></a>
									<a href ={this.state.partnerData.ngoInstagramUrl} style={{marginLeft: "15px"}} className="fa fa-instagram partner-social-handle"></a>
									<a href ={this.state.partnerData.ngoLinkedinUrl} style={{marginLeft: "15px"}} className="fa fa-linkedin partner-social-handle"></a>
								</div>
							) : null}
						</div>
					</div>
					<div className='ngo-profile-button-container'>
						<button className='ngo-profile-button' onClick={this.solicitProject.bind(this, this.props.match.params.partnerId)}>Solicit Projects</button>
						<div style={{display:"flex", marginTop: "10px"}}>
							<input type="checkbox" checked={this.state.isChecked ? true : false} onChange={this.projectWithoutSolicitation}/><span style={{marginLeft: "5px"}}>Allow NGO to submit projects without solicitation</span>
						</div>
					</div>
				</div>

				<div className='ngo-projects'>
					<div className='project-amount'>Total Amount Allocated <span>(INR) {this.state.partnerData.allocatedAmount ? window.inrFormat(this.state.partnerData.allocatedAmount) : "0"}</span></div>
					<div className='project-amount'>Total Amount Invested <span>(INR) {this.state.partnerData.investedAmount ? window.inrFormat(this.state.partnerData.investedAmount) : "0"}</span></div>
					<div className='project-amount'>Total Amount Utilised <span>(INR) {this.state.partnerData.utilizedAmount ? window.inrFormat(this.state.partnerData.utilizedAmount) : "0"}</span></div>
					<div className='project-amount'>Total Projects <span>{this.state.partnerData.projectCount ? this.state.partnerData.projectCount : '0'}</span></div>
				</div>

				<Tabs activeKey={this.state.activeTab} onSelect={this.keyChange.bind(this)} id="partnerPage-tabs-container">
				  <Tab eventKey={1} title="Profile">
				  	<div className='profile-details-container' style={{display:"flex", justifyContent:"space-between"}}>
				  		<div style={{width:"90%", marginRight:"40px"}}>
						  	<div className='partner-contact-details-container'>
						  		<h3 style={{marginBottom: "12px", fontSize: "20px", fontWeight: "bold"}}>About The NGO</h3>
				  				<h4 style={{fontSize:"14px", lineHeight:"1.3", letterSpacing:"1px", wordBreak: "break-all"}}>{this.state.partnerData.aboutNgo ? this.state.partnerData.aboutNgo : null}</h4>
						  	</div>
						  	<div className='partner-contact-details-container'>
						  		<h3 style={{marginBottom: "12px", fontSize: "20px", fontWeight: "bold"}}>NGO Mission</h3>
					  			<h4 style={{fontSize:"14px", lineHeight:"1.3", letterSpacing:"1px", wordBreak: "break-all"}}>{this.state.partnerData.ngoMission ? this.state.partnerData.ngoMission : null}</h4>
						  	</div>
						  	<div className='partner-contact-details-container'>
						  		<h3 style={{marginBottom: "12px", fontSize: "20px", fontWeight: "bold"}}>NGO Vision</h3>
					  			<h4 style={{fontSize:"14px", lineHeight:"1.3", letterSpacing:"1px", wordBreak: "break-all"}}>{this.state.partnerData.ngoVision ? this.state.partnerData.ngoVision : null}</h4>
						  	</div>
						  	<div className='partner-contact-details-container'>
						  		<h3 style={{marginBottom: "12px", fontSize: "20px", fontWeight: "bold"}}>NGO Approach</h3>
				  				<h4 style={{fontSize:"14px", lineHeight:"1.3", letterSpacing:"1px", wordBreak: "break-all"}}>{this.state.partnerData.ngoApproach ? this.state.partnerData.ngoApproach : null}</h4>
						  	</div>
				  		</div>
				  		<div style={{width: '26%'}}>
					  		<div className='partner-organisation-details-container' style={{background: "#f8f8f8", padding: "12px", borderRadius:"4px"}}>
						  		<h3 style={{marginBottom: "8px", fontSize: "20px", fontWeight: "bold", borderBottom:"solid 1px #ef5a20", padding:"0px 0px 8px 0px"}}>Organisation Details</h3>
							  	<div className='partner-organisation-details'>
							  		{/*<h4 className='partner-detail-heading'>Darpan ID: <span className='partner-detail'>{this.state.partnerData.darpanId}</span></h4>
										<h4 className='partner-detail-heading'>Registered with: <span className='partner-detail'>{this.state.partnerData.registeredWith}</span></h4>*/}
										<h4 className='partner-detail-heading'>Type of NGO: <span className='partner-detail'>{this.state.partnerData.legalIdentity}</span></h4>
										<h4 className='partner-detail-heading'>Registration No: <span className='partner-detail'>{this.state.partnerData.ngoRegNo}</span></h4>
										<h4 className='partner-detail-heading'>City of Registration: <span className='partner-detail'>{this.state.partnerData.ngoCity}</span></h4>
										<h4 className='partner-detail-heading'>State of Registration: <span className='partner-detail'>{this.state.partnerData.ngoState}</span></h4>
										<h4 className='partner-detail-heading'>Date of Registration: <span className='partner-detail'>{this.state.partnerData.ngoRegYear}</span></h4>
										{/*<h4 className='partner-detail-heading'>Operational Area: <span className='partner-detail'>{this.state.partnerData.operationalArea}</span></h4>*/}
							  	</div>
						  	</div>
						  	<div className='project-key-issues-container' style={{marginBottom: "20px", background: "#f8f8f8", padding: "12px", borderRadius:"4px"}}>
									<h3 className='partner-key-issue-heading' style={{marginBottom: "8px", fontSize: "20px", fontWeight: "bold", borderBottom:"solid 1px #ef5a20", padding:"0px 0px 8px 0px"}}>Key Issues:</h3>
									<div className='project-key-issues'>
										{this.state.partnerData.goals && this.state.partnerData.goals.length  ? this.state.partnerData.goals.map((issue,i)=>{
											return (<h4 key={i} className='key-issue' style={{display: "block", fontSize: "15px", marginBottom:"6px"}}><i className="fa fa-check" style={{color:'#ef5a20', marginRight:"6px"}}></i> {issue}</h4>)
										}) : <h4>No Key Issues to Display</h4>}
									</div>
								</div>
						  	<div className='partner-contact-details-container' style={{marginBottom: "20px", background: "#f8f8f8", padding: "12px", borderRadius:"4px"}}>
						  		<h3 style={{marginBottom: "8px", fontSize: "20px", fontWeight: "bold", borderBottom:"solid 1px #ef5a20", padding:"0px 0px 8px 0px"}}>Contact Details</h3>
						  		<div className='partner-contact-details'>
						  			<h4 className='partner-detail-heading'>Address: <span className='partner-detail'>{this.state.partnerData.ngoAddressLine1}</span></h4>
							  		<h4 className='partner-detail-heading'>Mobile No: <span className='partner-detail'>{this.state.partnerData.ngoSTD} {this.state.partnerData.ngoPhone}</span></h4>
							  		<h4 className='partner-detail-heading'>Website: <span className='partner-detail'>{this.state.partnerData.ngoWebsiteUrl}</span></h4>
							  		<h4 className='partner-detail-heading'>Email: <span className='partner-detail'>{this.state.partnerData.ngoEmail}</span></h4>
						  		</div>
						  	</div>
				  		</div>
				  	</div>
				  </Tab>
				  <Tab eventKey={2} title="Projects">
				  	{this.state.projects.length ? (
				  		this.state.projects.map((data,i) => {
					  		return (
							  	<Row key={i} className="single-project csr-project ngo-projects-container">
										<Col className="csr-project-image-container" style={{width:"30%", position: "relative"}}>
											<img className="csr-project-image" src={data.coverImage ? data.coverImage : "https://assets.letsendorse.com/sahaj/project-placeholder.jpg"} />
											<div className="project-type-solicit-section">
												<span className="current-state-outer-solicit-section">
													<span className="outer-arrow-solicit-section"></span>
													<span className="current-state-text-solicit-section">{data.status}</span>
												</span>
											</div>
										</Col>
										{/*<h4 className='ngo-project-status'>{data.status}</h4>*/}
										<Col md={6}>
											<Col md={12} className="project-title">
												<Link to={"/mis-projects/" + data.customUrl} target="_self"><span>{data.title}</span></Link>
											</Col>
											<Col md={12} className="project-description">
												<p>{data.description}</p>
											</Col>
											{checkValue(data.targetBeneficiaries) !== true ? (
												<Col md={12} className="features mt5" hidden={checkValue(data.targetBeneficiaries)}>
													<div className="heading">Target beneficiaries: </div>
													<div className="content">
														{data.targetBeneficiaries.map(function (targetBeneficiary, i) {
															if (data.targetBeneficiaries.length - i === 1) return targetBeneficiary.name
															else return targetBeneficiary.name + ', '
														}.bind(this))}
													</div>
												</Col>
											) : (null)}
											{checkValue(data.goalData) !== true ? (
												<Col md={12} className="features mt5" hidden={checkValue(data.goalData.name)}>
													<div className="heading">Cause/Developmental Goal: </div>
													<div className="content">{data.goalData.name}</div>
												</Col>
											) : (null)}
											<Col md={12} className="features mt5">
												<Link to={"/mis-projects/" + data.customUrl} target="_self">
													<button className='ngo-project-button'>Read More</button>
												</Link>
											</Col>
										</Col>
										<Col md={3}>
											<Col md={12} className="project-duration-container">
												<span className="current-state-outer">
													<span className="outer-arrow"></span>
													{data.LEAssured ? (
														<span className="current-state-text"><SvgIcon size={16} style={{color:"#ef5a20"}} icon={info} /> <b>Type:</b> LE Assured</span>
													) : (
														<span className="current-state-text"><SvgIcon size={16} style={{color:"#ef5a20"}} icon={info} /> <b>Type:</b> Partner Project</span>
													)}
												</span>
											</Col>
											{data.thematicCategory && data.thematicCategory.name ? (
												<Col md={12} className="project-thematic-category">
													<div>
														<SvgIcon size={16} style={{color:"#ef5a20"}} icon={ic_list} /><b>{' Schedule 7 Category: '}</b>
														<span>{data.thematicCategory.name}</span>
													</div>
												</Col>
											) : (null)}
											<Col md={12} className="project-location">
												{data.location && data.location.length ? (
													<div>
														<i className="fa fa-map-marker"></i><b>{' Location: '}</b>
														<span hidden={checkValue(data.location[0].address)}>{data.location.length > 1 ? (' Multiple Locations') : (' ' + data.location[0].address)}</span>
													</div>
												) : (null)}
												<span className="project-address">
													<span hidden={checkValue(data.city)}>{data.city}</span>
													<span hidden={checkValue(data.state)}>{', ' + data.state}</span>
													<span hidden={checkValue(data.country)}>{', ' + data.country}</span>
													<span hidden={checkValue(data.pincode)}>{', ' + data.pincode}</span>
												</span>
											</Col>
											<Col md={12} className="project-budget-con" hidden={checkValue(data.proposedBudget)}>
												<i className="fa fa-money"></i>
												<span className="project-budget">
													{data.hasOwnProperty('currencySymbol') ? (
														<span><b>{' Budget: '}</b>{data.currencySymbol + ' ' + window.inrFormat(data.proposedBudget)}</span>
													) : (
															<span><b>{' Budget: '}</b>{'INR ' + window.inrFormat(data.proposedBudget)}</span>
														)}
												</span>
											</Col>
											<Col md={12} className="project-duration-container" hidden={checkValue(data.expectedDurationObject)}>
												<i className="fa fa-calendar"></i>
												<span className="project-duration"><b>{' Duration: '}</b>{data.expectedDurationObject.duration} {data.expectedDurationObject.type}</span>
											</Col>
										</Col>
									</Row>
					  		)
					  	})
				  	) : <div className='projects-error-message-container' style={{display:"flex", alignItems:"center"}}>
				  		<img src='/img/spinner.gif' style={{width:"62px"}} className='projects-error-message' />
				  		<p style={{margin:'0px'}}>Loading Projects...</p>
				  	</div>}
				  </Tab>
				  <Tab eventKey={3} title="Solicitations">
				  	{this.state.solicitations.length ? (
				  		<div>
				  			<button className='new-solicitation-button' onClick={this.solicitProject.bind(this, this.props.match.params.partnerId)}>New Solicitation</button>
				  			<h3>Projects yet to be submitted</h3>
				  			{this.state.solicitations.map((data,i) => {
						  		return (
							  		Object.keys(data.project).length ? ( null ) : (
							  			<div key={i} className='solicitations-container' style={{border: "solid 1px #c1c1c1", marginBottom: "15px", padding:"10px", borderRadius:"4px", display:"flex", borderLeft: "solid 5px orange"}}>
									  		<div className='solicit-project-details-container'>
									  			{Object.keys(data.project).length ? null : null }
									  			<h3 className='solicit-subject'><span style={{fontWeight:"bold", fontSize:"16px"}}> {data.subject}</span></h3>
										  		{/*<h4 className={Object.keys(data.project).length ? 'solicit-status submitted' : 'solicit-status not-submitted'}>{Object.keys(data.project).length ? (null) : (<i className="fa fa-info-circle" style={{marginRight:"5px", fontWeight:"bold"}}> Project yet to be submitted</i>)}</h4>*/}
										  		<div style={{ display: "flex", alignItems: "center" }}>
										  			<button className='ngo-project-button' style={{margin: "10px 0px 0px 0px"}} onClick={this.expand.bind(this, i)}> {data.expand === true ? "View Less" : "View More" }</button>
													</div>
													{data.expand === true ?
														(<div className='solicit-content-container'>
															<h5 className='solicit-label' style={{fontWeight:"bold"}}>Solicited By : <span className='solicit-info' style={{display:"block", fontWeight:"normal", marginTop:"3px"}}>{data.invitor.fullName}</span></h5>
															<h5 className='solicit-message' style={{fontWeight:"bold"}}>Message: <span style={{display:"block", fontWeight:"normal", marginTop:"3px"}}>{data.message}</span></h5>
														</div>) : (null)
													}
									  		</div>
									  	</div>
						  			)
						  		)
						  	})}
						  	<h3>Submitted Projects</h3>
				  			{this.state.solicitations.map((data,i) => {
						  		return (
						  			Object.keys(data.project).length ? (
							  			<div key={i} className='solicitations-container' style={{border: "solid 1px #c1c1c1", marginBottom: "15px", padding:"10px", borderRadius:"4px", display:"flex", flexDirection:"column", borderLeft: "solid 5px green", justifyContent:"space-between"}}>
							  				<div style={{display: "flex", justifyContent: "space-between"}}>
										  		{Object.keys(data.project).length ? (
										  			<div className='solicit-project-img-container' style={{order: "2", margin:"0px"}}>
										  				<img className='solicit-img' src={Object.keys(data.project).length && data.project.coverImage  ? data.project.coverImage : "https://assets.letsendorse.com/sahaj/project-placeholder.jpg"} alt=""/>
											  		</div>
										  		) : null}
										  		<div className='solicit-project-details-container'>
										  			<h3 className='solicit-subject'><span style={{fontWeight:"bold", fontSize:"16px"}}> {data.subject}</span></h3>
										  			{Object.keys(data.project).length ? <h3 className='solicit-title' style={{cursor: "pointer", color:"#ef5a20"}} onClick={() => {window.location.pathname='/mis-projects/' + data.project.customUrl}}>{data.project.title}</h3> : null }
											  		{/*<h4 className={Object.keys(data.project).length ? 'solicit-status submitted' : 'solicit-status not-submitted'}>{Object.keys(data.project).length ? (<i className="fa fa-check-circle" style={{marginRight:"5px", fontWeight:"bold"}}> Project Submitted</i>) : (null)}</h4>*/}
											  		<div style={{ display: "flex", alignItems: "center" }}>
											  			<button className='ngo-project-button' style={{margin: "10px 0px 0px 0px"}} onClick={this.expand.bind(this, i)}> {data.expand === true ? "View Less" : "View More" }</button>
														</div>
										  		</div>
							  				</div>
												{data.expand === true ?
													(<div className='solicit-content-container'>
														<h5 className='solicit-label' style={{fontWeight:"bold"}}>Solicited By : <span className='solicit-info' style={{display:"block", fontWeight:"normal", marginTop:"3px"}}>{data.invitor.fullName}</span></h5>
														<h5 className='solicit-message' style={{fontWeight:"bold"}}>Message: <span style={{display:"block", fontWeight:"normal", marginTop:"3px"}}>{data.message}</span></h5>
													</div>) : (null)
												}
									  	</div>
					  			 	) : ( null )
						  		)
						  	})}
				  		</div>
				  	) : <div className='projects-error-message-container' style={{display:"flex", alignItems:"center"}}>
				  		<img src='/img/spinner.gif' style={{width:"62px"}} className='projects-error-message' />
				  		<p style={{margin:'0px'}}>Loading...</p>
				  	</div>}
				  </Tab>
				  <Tab eventKey={4} title="Documents">
				  	<div className='certificates-container'>
				  		{this.state.partnerData.Certificate_12_A ? <div className='certificate' name='Certificate_12_A'><p style={{margin:"0px"}}><i className="fa fa-file"></i> Certificate 12 A</p><button style={{marginLeft:"12px"}} className='ngo-project-button' onClick={this.showModal.bind(this, "Certificate_12_A")}>View</button></div> : null }
					  	{this.state.partnerData.Certificate_80_G ? <div className='certificate' name="Certificate_80_G"><p style={{margin:"0px"}}><i className="fa fa-file"></i> Certificate 80 G</p><button style={{marginLeft:"12px"}} className='ngo-project-button' onClick={this.showModal.bind(this, "Certificate_80_G")}>View</button></div> : null }
					  	{this.state.partnerData.Certificate_FCRA ? <div className='certificate' name='Certificate_FCRA'><p style={{margin:"0px"}}><i className="fa fa-file"></i> Certificate FCRA</p><button style={{marginLeft:"12px"}} className='ngo-project-button' onClick={this.showModal.bind(this, "Certificate_FCRA")}>View</button></div> : null }
					  	{this.state.partnerData.registrationCertificate ? <div className='certificate' name='registrationCertificate'><p style={{margin:"0px"}}><i className="fa fa-file"></i> Registration Certificate</p><button style={{marginLeft:"12px"}} className='ngo-project-button' onClick={this.showModal.bind(this, "registrationCertificate")}>View</button></div> : null }
				  	</div>
				  </Tab>
			 	</Tabs>
			</div>
		)
	}
}

export default partnerPage;