import React, { Component } from 'react';
import { OverlayTrigger, Tooltip, Col, Row } from 'react-bootstrap';
import moment from 'moment';
import $ from 'jquery';
import { BootstrapTable, TableHeaderColumn, ButtonGroup } from 'react-bootstrap-table';
import Multiselect from 'react-bootstrap-multiselect';
import Icon from 'react-icons-kit';
import { Select } from 'antd';
import swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';

import SinglePortfolioGoalForm from './SinglePortfolioGoalForm';
import Investments from './Investments';

const Option = Select.Option;

const readCookie = require('../../../cookie.js').readCookie;

export default class SinglePortfolioGoal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			singlePortfolio: null,
			singlePortfolioId: null,
			singlePortfolioGoal: null,
			singlePortfolioAgendas: null,
			singlePortfolioAmount: null,
			selectedAgenda: {
				id: "",
				name: ""
			},
			allNGOs: null,
			selectedNgo: [],
			allProjects: [],
			selectedProject: [],
			selectedProjectDescription: "",
			multiselectData: [
				{ value: "1", label: "Vishal" },
				{ value: "2", label: "Lionel" },
				{ value: "3", label: "Harkirat" },
				{ value: "4", label: "Subham" }
			],
			allKPIs: [],
			showForm: false,
			otAttributes: null,
			hasProjectLocation: {
				preferenceLocation: '',
				preferenceLocationError: '',
				latLng: {}
			},
			projectExpectedImpact: '',
			projectStartDate: null,
			allocatedAmount: 0,
			allocatedUnits: 0,
			amountBreakupTableVisibility: true,
			amountBreakup: [],
			reportingFrequency: '',
			selectedKPIs: [],
			selectedLocations: [],
			selectedBeneficiaryTypes: [],
			selectedProjectDuration: {
				duration: '',
				type: ''
			},
			selectedStartDate: null,
			signlePortfolioPage: true
		};
		this.changeSelectedNgo = this.changeSelectedNgo.bind(this);
		this.changeSelectedAgenda = this.changeSelectedAgenda.bind(this);
		this.changeSelectedProject = this.changeSelectedProject.bind(this);
		this.changeSelectedProjectDescription = this.changeSelectedProjectDescription.bind(this);
		this.handleSelectChange = this.handleSelectChange.bind(this);
		this.saveProject = this.saveProject.bind(this);
		this.cancelProject = this.cancelProject.bind(this);
		this.changeProjectDuration = this.changeProjectDuration.bind(this);
		this.changeProjectDurationType = this.changeProjectDurationType.bind(this);
	}

	componentDidMount() {
		let singlePortfolioId = this.props.match.params.id;
		this.setState({ singlePortfolioId });
		let companyId = JSON.parse(readCookie('userData')).organisationData.id;
		fetch(process.env.REACT_APP_API_URL + '/company/' + companyId + '/mis/individual-portfolio/' + singlePortfolioId, {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
			.then(function (data) {
				let singlePortfolio = data.data;
				let singlePortfolioGoal = data.data.goal;
				let singlePortfolioAgendas = data.data.agendas;
				let singlePortfolioAmount = data.data.amount;

				fetch(process.env.REACT_APP_API_URL + '/kpi/' + singlePortfolioGoal.id, {
					method: "GET",
					headers: {
						'Auth': JSON.parse(readCookie('access_token')).access_token
					}
				}).then((data) => data.json())
					.then(function (data) {
						let allKPIs = [];
						for (var i = 0; i < data.data.length; i++) {
							let kpi = {};
							kpi.value = data.data[i]._id;
							kpi.label = data.data[i].name;
							allKPIs.push(kpi);
						}
						this.setState({ allKPIs });
					}.bind(this));

				fetch(process.env.REACT_APP_API_URL + '/getOtAttributes', {
					method: "GET",
					headers: {
						'Auth': JSON.parse(readCookie('access_token')).access_token
					}
				}).then((data) => data.json())
					.then(function (data) {
						let beneficiaryTypes = [];
						let projectDurations = [];
						let reportingFrequency = [];

						for (let i = 0; i < data.otAttributes.length; i++) {
							if (data.otAttributes[i].type === "beneficiary") {
								let beneficiaryType = {};
								beneficiaryType['label'] = data.otAttributes[i].name;
								beneficiaryType['value'] = data.otAttributes[i]._id;
								beneficiaryTypes.push(beneficiaryType);
							} else if (data.otAttributes[i].type === "projectDuration") {
								let projectDuration = {};
								projectDuration['label'] = data.otAttributes[i].name;
								projectDuration['value'] = data.otAttributes[i]._id;
								projectDurations.push(projectDuration);
							} else if (data.otAttributes[i].type === "reportingFrequency") {
								let frequency = {};
								frequency['label'] = data.otAttributes[i].name;
								frequency['value'] = data.otAttributes[i]._id;
								reportingFrequency.push(frequency);
							}
						}
						let otAttributes = {};
						otAttributes['beneficiaryTypes'] = beneficiaryTypes;
						otAttributes['projectDurations'] = projectDurations;
						otAttributes['reportingFrequency'] = reportingFrequency;

						this.setState({ otAttributes });
					}.bind(this));

				let goalId = singlePortfolioGoal.id;

				fetch(process.env.REACT_APP_API_URL + '/company/' + companyId + '/mis/suggest-projects?goalId=' + goalId, {
					method: "GET",
					headers: {
						'Auth': JSON.parse(readCookie('access_token')).access_token
					}
				}).then((data) => data.json())
					.then(function (data) {
						let allProjects = data.data;
						this.setState({ allProjects });
					}.bind(this));

				fetch(process.env.REACT_APP_API_URL + '/company/' + companyId + '/mis/suggest-ngos', {
					method: "GET",
					headers: {
						'Auth': JSON.parse(readCookie('access_token')).access_token
					}
				}).then((data) => data.json())
					.then(function (data) {
						let allNGOs = data.data;
						this.setState({ singlePortfolio }, function () {
							this.setState({ singlePortfolioGoal, singlePortfolioAgendas, singlePortfolioAmount, allNGOs });
						}.bind(this))
					}.bind(this));
			}.bind(this));
	}

	changeSelectedAgenda(value) {
		let selectedAgenda = null;
		for (var i = 0; i < this.state.singlePortfolioAgendas.length; i++) {
			if (value === this.state.singlePortfolioAgendas[i].id) selectedAgenda = this.state.singlePortfolioAgendas[i];
		}
		this.setState({ selectedAgenda });
	}

	changeSelectedNgo(value) {
		let newSelectedNgo = [];
		if (value.length) {
			newSelectedNgo.push(value[0]);
			this.setState({ selectedNgo: newSelectedNgo }, function () {
				let foundNgo = false;
				let foundNgoIndex = -1;
				for (var i = 0; i < this.state.allNGOs.length; i++) {
					if (this.state.allNGOs[i].id === this.state.selectedNgo[0]) {
						foundNgo = true;
						foundNgoIndex = i;
						break;
					}
				}
				if (!foundNgo) {
					let name = this.state.selectedNgo[0];
					let fullNameOfPOC = '';
					let emailOfPOC = '';
					let contactNoOfPOC = '';
					let checkEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
					swal({
						title: 'Kindly fill in the following details.',
						html: `<div class="addNGOSwalContainer">
										<div>
											<p class="mt5 mb20 changePasswordSwal"><b>NGO Name: </b>` + name + `</p>
										</div>
										<p class="mt5 mb10 poc-heading">Enter details of Person Of Contact</p>
										<div>
											<span class="mt5 mb15 changePasswordSwal is-imp">Full Name: </span>
											<input type="text" id="swal-input1" class="mt5 mb15 changePasswordSwal swal2-input" value="">
										</div>
										<div>
											<span class="mt5 mb15 changePasswordSwal is-imp">Email: </span>
											<input type="email" id="swal-input2" class="mt5 mb15 changePasswordSwal swal2-input" value="">
										</div>
										<div>
											<span class="mt5 mb15 changePasswordSwal is-imp">Mobile Number: </span>
											<input type="tel" id="swal-input3" class="mt5 mb15 changePasswordSwal swal2-input" value="">
										</div>
									</div>`,
						showCancelButton: false,
						closeOnCancel: false,
						allowEscapeKey: false,
						confirmButtonText: 'Submit',
						showLoaderOnConfirm: true,
						allowOutsideClick: false,
						preConfirm: function () {
							fullNameOfPOC = $('.addNGOSwalContainer #swal-input1').val();
							emailOfPOC = $('.addNGOSwalContainer #swal-input2').val();
							contactNoOfPOC = $('.addNGOSwalContainer #swal-input3').val();
							return new Promise(function (resolve, reject) {
								setTimeout(function () {
									if (name === '') {
										reject('Please enter a proper NGO Name.')
									} else if (fullNameOfPOC === '' || emailOfPOC === '' || contactNoOfPOC === '' || !checkEmail.test(emailOfPOC)) {
										reject('Incorrect details provided.')
									} else {
										resolve()
									}
								}, 1500)
							})
						},
						allowOutsideClick: false
					}).then(function (result) {
						fullNameOfPOC = $('.addNGOSwalContainer #swal-input1').val();
						emailOfPOC = $('.addNGOSwalContainer #swal-input2').val();
						contactNoOfPOC = $('.addNGOSwalContainer #swal-input3').val();

						let data = {};
						data['name'] = name;
						data['emailOfPOC'] = emailOfPOC;
						data['fullNameOfPOC'] = fullNameOfPOC;
						data['contactNoOfPOC'] = contactNoOfPOC;

						let companyId = JSON.parse(readCookie('userData')).organisationData.id;
						fetch(process.env.REACT_APP_API_URL + '/company/' + companyId + '/mis/ngo/invite', {
							method: "POST",
							headers: {
								'Content-Type': 'application/json',
								'Auth': JSON.parse(readCookie('access_token')).access_token
							},
							body: JSON.stringify(data)
						}).then(function (response) {
							return response.json();
						}).then(function (data) {
							if (data.status === 'ok') {
								swal({
									type: 'success',
									title: 'Your NGO will be added soon after verification!',
									showCancelButton: false,
									closeOnCancel: false,
									allowEscapeKey: false,
									allowOutsideClick: false
								}).then(function () {
									window.location.reload();
								});
							} else {
								swal({
									type: 'warning',
									title: data.message,
									showCancelButton: false,
									closeOnCancel: false,
									allowEscapeKey: false,
									allowOutsideClick: false
								});
							}
						});
					}.bind(this));
				}
			}.bind(this));
		} else this.setState({ selectedNgo: [] });
	}

	changeSelectedProject(value) {
		let newSelectedProject = [];
		if (value.length) newSelectedProject.push(value[0]);
		this.setState({ selectedProject: newSelectedProject }, function () {
			let foundProject = false;
			let foundProjectIndex = -1;
			for (var i = 0; i < this.state.allProjects.length; i++) {
				if (this.state.allProjects[i].id === this.state.selectedProject[0]) {
					foundProject = true;
					foundProjectIndex = i;

					var selectedNgo = [];
					var selectedAgenda = null;
					for (var j = 0; j < this.state.singlePortfolioAgendas.length; j++) {
						if (this.state.allProjects[i].agenda.id === this.state.singlePortfolioAgendas[j].id) selectedAgenda = this.state.singlePortfolioAgendas[j];
					}
					if (selectedAgenda !== null) this.setState({ selectedAgenda });
					selectedNgo.push(this.state.allProjects[i].ngoData.id);
					this.setState({ selectedNgo });
					break;
				}
			}
			if (foundProject) {
				let locations = [];
				for (var i = 0; i < this.state.allProjects[foundProjectIndex].location.length; i++) {
					let loc = {};
					loc['value'] = this.state.allProjects[foundProjectIndex].location[i].address;
					loc['label'] = this.state.allProjects[foundProjectIndex].location[i].address;
					locations.push(loc);
				}
				this.setState({ multiselectData: locations });
			}
		}.bind(this));
	}

	changeSelectedProjectDescription(e) {
		this.setState({ selectedProjectDescription: e.target.value });
	}

	handleSelectChange(name, value) {
		this.setState({ [name]: $(value).parents('select').val() });
	}

	saveProject() {
		if (this.state.selectedAgenda.id !== '' && this.state.selectedProject.length && this.state.selectedNgo.length && this.state.selectedKPIs.length && this.state.reportingFrequency !== '' && (this.state.allocatedAmount !== 0 || this.state.allocatedUnits !== 0)) {
			if (this.state.selectedProject[0] !== '') {
				let data = {};
				data.goal = this.state.singlePortfolioGoal.id;
				data.agenda = this.state.selectedAgenda.id;
				if (this.state.selectedProject.length === 1) {
					let foundProject = false;
					let foundProjectIndex = -1;
					for (var i = 0; i < this.state.allProjects.length; i++) {
						if (this.state.allProjects[i].id === this.state.selectedProject[0]) {
							foundProject = true;
							foundProjectIndex = i;
							break;
						}
					}
					if (foundProject) data.projectId = this.state.selectedProject[0];
					else {
						if (this.state.selectedProjectDescription !== '' && this.state.hasProjectLocation.preferenceLocation !== '' && this.state.selectedBeneficiaryTypes.length && this.state.projectExpectedImpact !== '' && this.state.selectedProjectDuration.duration !== '' && this.state.selectedProjectDuration.type !== '') {
							let project = {};
							project.title = this.state.selectedProject[0];
							project.description = this.state.selectedProjectDescription;
							let location = [];
							let hasProjectLocation = {};
							hasProjectLocation.latitude = this.state.hasProjectLocation.latLng.lat;
							hasProjectLocation.longitude = this.state.hasProjectLocation.latLng.lng;
							hasProjectLocation.address = this.state.hasProjectLocation.preferenceLocation;
							location.push(hasProjectLocation);
							project.location = location;
							project.targetBeneficiaries = this.state.selectedBeneficiaryTypes;
							project.expectedImpact = this.state.projectExpectedImpact;
							project.expectedDuration = this.state.selectedProjectDuration.duration + ' ' + this.state.selectedProjectDuration.type;
							let expectedDurationObject = {};
							expectedDurationObject.duration = this.state.selectedProjectDuration.duration;
							expectedDurationObject.type = this.state.selectedProjectDuration.type;
							project.expectedDurationObject = expectedDurationObject;
							project.expectedStartDate = this.state.selectedStartDate;
							data.project = project;
						} else return;
					}
				}

				data.ngoId = this.state.selectedNgo[0];
				if (this.state.allocatedAmount === 0 && this.state.allocatedUnits !== 0) data.allocatedUnits = this.state.allocatedUnits;
				else if (this.state.allocatedAmount !== 0 && this.state.allocatedUnits === 0) data.allocatedAmount = this.state.allocatedAmount;
				data.KPIs = this.state.selectedKPIs;
				data.reportingFrequency = this.state.reportingFrequency;
				let amountBreakup = [];
				for (var i = 0; i < this.state.amountBreakup.length; i++) {
					let breakup = {};
					breakup.description = this.state.amountBreakup[i].name;
					breakup.costHead = this.state.amountBreakup[i].costHead;
					breakup.amount = this.state.amountBreakup[i].amount;
					amountBreakup.push(breakup);
				}
				data.amountBreakup = amountBreakup;

				let companyId = JSON.parse(readCookie('userData')).organisationData.id;
				fetch(process.env.REACT_APP_API_URL + '/company/' + companyId + '/mis/individual-portfolio/' + this.state.singlePortfolioId + '/investment', {
					method: "POST",
					headers: {
						'Content-Type': 'application/json',
						'Auth': JSON.parse(readCookie('access_token')).access_token
					},
					body: JSON.stringify(data)
				}).then(function (response) {
					return response.json();
				}).then(function (data) {
					if (data.status === 'ok') {
						this.notifySuccess('Successfully added your project.');
						this.cancelProject();
						let singlePortfolioId = this.state.singlePortfolioId;
						this.setState({ singlePortfolioId: null }, function () {
							this.setState({ singlePortfolioId });
						}.bind(this));
					}
				}.bind(this));
			}
		}
	}

	notifySuccess = (text) => toast.success(text);

	cancelProject() {
		this.setState({
			selectedAgenda: {
				id: "",
				name: ""
			},
			selectedNgo: [],
			selectedProject: [],
			selectedProjectDescription: "",
			showForm: false
		});
	}

	changeProjectDuration(e) {
		let selectedProjectDuration = this.state.selectedProjectDuration;
		selectedProjectDuration.duration = e.target.value;
		this.setState({ selectedProjectDuration });
	}

	changeProjectDurationType(value) {
		let selectedProjectDuration = this.state.selectedProjectDuration;
		selectedProjectDuration.type = value;
		this.setState({ selectedProjectDuration });
	}

	openForm() {
		this.setState({ showForm: true });
	}

	changeLatLng(latLng) {
		let hasProjectLocation = this.state.hasProjectLocation;
		hasProjectLocation.latLng = latLng;
		this.setState({ hasProjectLocation });
	}

	changeLocation(value) {
		let hasProjectLocation = this.state.hasProjectLocation;
		hasProjectLocation.preferenceLocation = value;
		this.setState({ hasProjectLocation });
	}

	changeProjectExpectedImpact(e) {
		this.setState({ projectExpectedImpact: e.target.value });
	}

	onStartDateChange(date, dateString) {
		this.setState({ selectedStartDate: moment(dateString).format('DD/MM/YYYY') });
	}

	updateAllocatedAmount(e) {
		this.setState({ allocatedAmount: e.target.value });
	}

	updateAllocatedUnits(e) {
		this.setState({ allocatedUnits: e.target.value });
	}

	showAmountBreakupTable() {
		if (this.state.allocatedAmount !== 0) this.setState({ amountBreakupTableVisibility: true });
	}

	changeSelectedReportingFreq(value) {
		this.setState({ reportingFrequency: value });
	}

	addNewBreakup() {
		let amountBreakup = this.state.amountBreakup;
		let newBreakup = {};
		newBreakup.name = '';
		newBreakup.costHead = '';
		newBreakup.amount = 0;
		amountBreakup.push(newBreakup);
		this.setState({ amountBreakup });
	}

	updateAmountBreakupName(index, e) {
		let amountBreakup = this.state.amountBreakup;
		amountBreakup[index].name = e.target.value;
		this.setState({ amountBreakup });
	}

	updateAmountBreakupCosthead(index, value) {
		let amountBreakup = this.state.amountBreakup;
		amountBreakup[index].costHead = value;
		this.setState({ amountBreakup });
	}

	updateAmountBreakupAmount(index, e) {
		let amountBreakup = this.state.amountBreakup;
		amountBreakup[index].amount = e.target.value;
		this.setState({ amountBreakup });
	}

	removeAmountBreakup(index) {
		let amountBreakup = this.state.amountBreakup;
		amountBreakup.splice(index, 1);
		this.setState({ amountBreakup: null }, function () {
			this.setState({ amountBreakup });
		}.bind(this));
	}

	render() {
		if (this.state.singlePortfolio !== null && this.state.singlePortfolioGoal !== null && this.state.singlePortfolioAgendas !== null && this.state.otAttributes !== null) {
			return (
				<div className="mis-content-container single-portfolio-goal">
					<ToastContainer position="bottom-center" autoClose={4000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={true} />
					<button className="back-to-investments-button" onClick={() => window.location.pathname = "/portfolio"}>
						<i className="fa fa-arrow-left"></i>
						Back to Goal-Wise Investments
					</button>
					<h2 className="mb30 text-center goal-container">
						<span className="goal-icon" style={{backgroundImage: 'url("' + this.state.singlePortfolioGoal.icon + '")', backgroundColor: this.state.singlePortfolioGoal.backgroundColor}}></span>
						{this.state.singlePortfolioGoal.name}
					</h2>
					<Col md={12} className="add-single-portfolio-project-buttons mt20" style={{ padding: '0' }}>
						<span className="single-portfolio-allocated-amount">Total Amount Allocated: Rs. {window.inrFormat(this.state.singlePortfolioAmount)}</span>
						{/* <button className={this.state.showForm ? "btn btn-default pull-right formShowed" : "btn btn-default pull-right"} onClick={this.openForm.bind(this)}>Add Project</button> */}
						{JSON.parse(readCookie('userData')).authorizedFor.mis !== 'view' ? (
							<button className={this.state.showForm ? "btn btn-default pull-right formShowed" : "btn btn-default pull-right"} onClick={() => { window.location.pathname = "/discover/projects" }}>Add Project</button>
						) : (null)}
					</Col>
					<SinglePortfolioGoalForm multiselectData={this.state.multiselectData} allKPIs={this.state.allKPIs}
						changeSelectedProject={this.changeSelectedProject} selectedNgo={this.state.selectedNgo} selectedKPIs={this.state.selectedKPIs}
						singlePortfolioAgendas={this.state.singlePortfolioAgendas} allNGOs={this.state.allNGOs} selectedLocations={this.state.selectedLocations}
						selectedProject={this.state.selectedProject} selectedAgenda={this.state.selectedAgenda}
						changeSelectedAgenda={this.changeSelectedAgenda} changeSelectedNgo={this.changeSelectedNgo}
						handleSelectChange={this.handleSelectChange} selectedBeneficiaryTypes={this.state.selectedBeneficiaryTypes}
						changeSelectedProjectDescription={this.changeSelectedProjectDescription} allProjects={this.state.allProjects}
						changeLocation={this.changeLocation.bind(this)} otAttributes={this.state.otAttributes} selectedProjectDuration={this.state.selectedProjectDuration}
						hasProjectLocation={this.state.hasProjectLocation} changeLatLng={this.changeLatLng.bind(this)} changeProjectDuration={this.changeProjectDuration}
						saveProject={this.saveProject} cancelProject={this.cancelProject} showForm={this.state.showForm} changeProjectDurationType={this.changeProjectDurationType}
						changeProjectExpectedImpact={this.changeProjectExpectedImpact.bind(this)} allocatedAmount={this.state.allocatedAmount} allocatedUnits={this.state.allocatedUnits}
						onStartDateChange={this.onStartDateChange.bind(this)} updateAllocatedAmount={this.updateAllocatedAmount.bind(this)} updateAllocatedUnits={this.updateAllocatedUnits.bind(this)}
						showAmountBreakupTable={this.showAmountBreakupTable.bind(this)} amountBreakupTableVisibility={this.state.amountBreakupTableVisibility}
						addNewBreakup={this.addNewBreakup.bind(this)} updateAmountBreakupName={this.updateAmountBreakupName.bind(this)}
						updateAmountBreakupCosthead={this.updateAmountBreakupCosthead.bind(this)} removeAmountBreakup={this.removeAmountBreakup.bind(this)}
						updateAmountBreakupAmount={this.updateAmountBreakupAmount.bind(this)} amountBreakup={this.state.amountBreakup}
						changeSelectedReportingFreq={this.changeSelectedReportingFreq.bind(this)} reportingFrequency={this.state.reportingFrequency} />
					{this.state.singlePortfolioId !== null ? (
						<Investments portfolioId={this.state.singlePortfolioId} openForm={this.openForm.bind(this)} signlePortfolioPage={this.state.singlePortfolio} />
					) : (null)}
				</div>
			)
		} else {
			return null;
		}
	}
}