import React, {Component} from 'react';

import CauseContainer from './CauseContainer';

export default class Causes extends Component {
	render() {
		return (
			<div className="causes">
				{this.props.causes !== null ? (
						this.props.causes.map(function(cause, i) {
							return <CauseContainer key={i} cause={cause}
								updateSelectedCause={this.props.updateSelectedCause} />
						}.bind(this))
					) : (
						<div>

						</div>
					)
				}
			</div>
		);
	}
}