import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Row, Col} from 'react-bootstrap';

const checkValue = require('../../../checkValue.js').checkValue;

export default class ProjectContainer extends Component {
	componentDidMount() {
		checkValue(this.props.project.address1)
	}
	render() {
		return (
			<Row className="single-project" data-id={this.props.project.id}>
				<Col md={12} className="project-title">
					<Link to={'/csr/volunteer-opportunity/' + this.props.project.customUrl}><span>{this.props.project.title}</span></Link>
				</Col>
				<Col md={12} className="project-description">
					<p>{this.props.project.description}</p>
				</Col>
				<Col md={12} className="project-location">
					<i className="fa fa-map-marker"></i>
					<span className="project-address">
						<span hidden={checkValue(this.props.project.address1)}>{' ' + this.props.project.address1}</span><span hidden={checkValue(this.props.project.address2)}>{', ' + this.props.project.address2}</span><span hidden={checkValue(this.props.project.city)}>{', ' + this.props.project.city}</span><span hidden={checkValue(this.props.project.state)}>{', ' + this.props.project.state}</span><span hidden={checkValue(this.props.project.country)}>{', ' + this.props.project.country}</span><span hidden={checkValue(this.props.project.pincode)}>{', ' + this.props.project.pincode}</span>
					</span>
				</Col>
				<Col md={6} className="project-vols">
					<i className="fa fa-users"></i>
					<span className="project-volunteers">
						{' Volunteers ' + this.props.project.minVolunteers + ' - ' + this.props.project.maxVolunteers}
					</span>
				</Col>
				<Col md={6} className="project-budget-con">
					<i className="fa fa-money"></i>
					<span className="project-budget">
						{this.props.project.costType === 'perVolunteer' ? (
							this.props.project.hasOwnProperty('currencySymbol') ? (
								' Budget ' + this.props.project.currencySymbol + ' ' + this.props.project.cost + ' per volunteer '
							) : (
								' Budget INR' + this.props.project.cost + ' per volunteer '
							)
						) : (
							this.props.project.hasOwnProperty('currencySymbol') ? (
								' Budget ' + this.props.project.currencySymbol + ' ' + this.props.project.cost
									) : (
								' Budget INR' + this.props.project.cost
							)
						)}
					</span>
				</Col>
				<Col md={6} className="project-end">
					<i className="fa fa-calendar"></i>
					<span className="project-deadline">
						{' ' + this.props.project.startTime + ' - ' + this.props.project.endTime}
					</span>
				</Col>
			</Row>
		);
	}
}