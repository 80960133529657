import React, {Component} from 'react';
import $ from 'jquery';

import DiscoverProjectsFilter from './DiscoverProjectsFilter';
import Projects from './Projects';

export default class ProjectsContainer extends Component {
	constructor(props) {
    super(props);
    this.state = {
    	hideProjectsFilter: true
    };
  }

  componentDidUpdate() {
	  $('[data-id="'+ this.props.topProject +'"]').animate({ scrollTop: 0 }, 600);
  }

  toggleProjectFilters() {
	  this.setState({ hideProjectsFilter: !this.state.hideProjectsFilter });
  }

	render() {
		return (
			<div className="projects-container">
				{this.props.projects !== null ? (
						<div>							
							<Projects projects={this.props.projects} />
						</div>
					) : ('')
				}
			</div>
		);
	}
}