import React, {Component} from 'react';
import {Col, Row} from 'react-bootstrap';
import {createFilter} from 'react-search-input';
import ReactPaginate from 'react-paginate';
import Multiselect from 'react-bootstrap-multiselect';
import $ from 'jquery';
import { Select } from 'antd';

import Loader from '../Loader';

const KEYS_TO_FILTERS = ['title'];
const Option = Select.Option;

let filteredInnovativeModels = [];

const readCookie = require('../../../cookie.js').readCookie;

export default class MISInnovativeModels extends Component {
	constructor(props) {
		super(props);
		this.state = {
			offset: 0,
			goal: null,
			agenda: [],
			perPage: 8,
			pageCount: 1,
			goalValue: "",
			searchTerm: '',
			agendaValue: "",
			loaderHiding: '',
			showLoader: true,
			allAgendas: null,
			paginatedData: null,
			innovativeModels: null,
			showModelsLoader: false,
			filteredInnovativeModels: null,
			modelsLoaderHiding: 'hiding',
		};
	}

	componentDidMount() {
		fetch(process.env.REACT_APP_API_URL + '/innovation', {
			method: 'GET',
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then(function(response) {
				if(response.ok) return response.json();
		}).then(function(data) {
			let innovativeModels = data.data;
			this.setState({ innovativeModels, filteredInnovativeModels: innovativeModels, pageCount: Math.ceil(innovativeModels.length / this.state.perPage) }, function() {
				let filteredInnovativeModels = this.state.filteredInnovativeModels.filter(createFilter(this.state.searchTerm, KEYS_TO_FILTERS));
				this.setState({ filteredInnovativeModels }, function() {
					this.getPaginatedData();
				}.bind(this));
			}.bind(this));
		}.bind(this));

		fetch(process.env.REACT_APP_API_URL + '/getOtAttributes', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
		.then(function(data) {
			let goal = [];
			let allAgendas = [];

			for(let i = 0; i < data.otAttributes.length; i++) {
				if(data.otAttributes[i].type === 'goal') {
					let goalObj = {};
					goalObj['goalId'] = data.otAttributes[i]._id;
					goalObj['value'] = data.otAttributes[i].id;
					goalObj['label'] = data.otAttributes[i].name;
					goal.push(goalObj);
				} else if (data.otAttributes[i].type === 'agenda') {
					let agendaObj = {};
					agendaObj['value'] = data.otAttributes[i].leAgendaId;
					agendaObj['label'] = data.otAttributes[i].name;
					agendaObj['goalId'] = data.otAttributes[i]._goalId;
					allAgendas.push(agendaObj);
				}
			}
			this.setState({ goal, allAgendas });
		}.bind(this));
	}

	updateSearchTerm(e) {
		let value = e.target.value;
		this.setState({ searchTerm: value }, function() {
			if(value === '') {
				let innovativeModels = this.state.innovativeModels;
				this.setState({ offset: 0, filteredInnovativeModels: innovativeModels, pageCount: Math.ceil(innovativeModels.length / this.state.perPage) }, function() {
					this.getPaginatedData();
				});
			} else {
				let filteredInnovativeModels = this.state.innovativeModels.filter(createFilter(this.state.searchTerm, KEYS_TO_FILTERS));
				this.setState({ offset: 0, filteredInnovativeModels, pageCount: Math.ceil(filteredInnovativeModels.length / this.state.perPage) }, function() {
					this.getPaginatedData();
				});
			}
		}.bind(this));
	}

	getPaginatedData() {
		let filteredInnovativeModels = this.state.filteredInnovativeModels;
		let paginatedData = [];
		for (var i = this.state.offset; i < this.state.offset + this.state.perPage; i++) {
			paginatedData.push(filteredInnovativeModels[i]);
		}
		this.setState({ paginatedData, loaderHiding: 'hiding', modelsLoaderHiding: 'hiding' }, function() {
			var that = this;
			setTimeout(function() {
				that.setState({ showLoader: false, showModelsLoader: false });
			}.bind(this), 1500);
		}.bind(this));
	}

	handlePageClick(data) {
		let selected = data.selected;
		let offset = Math.ceil(selected * this.state.perPage);

		this.setState({ offset }, function() {
			this.getPaginatedData();
		}.bind(this));
	}

	showButtonText(options, select) {
		if(this.state.goalValue === '') {
			return 'Select Goal first';
		} else if (options.length === 0) {
			return 'No option selected ...';
		} else if (options.length > 2) {
			return options.length + ' options selected!';
		} else {
			let labels = [];
			options.each(function() {
				if ($(this).attr('label') !== undefined) {
					labels.push($(this).attr('label'));
				} else {
					labels.push($(this).html());
				}
			});
			return labels.join(', ') + '';
		}
	}

	handleChangeAgenda(value) {
		this.setState({ agendaValue: $(value).parent().val() });
	}

	handleChangeGoal(value) {
		this.setState({ goalValue: value });
		let agenda = [];
		for(var i = 0; i < this.state.allAgendas.length; i++) {
			if(value === this.state.allAgendas[i].goalId) {
				let agendaObj = {};
				agendaObj['value'] = this.state.allAgendas[i].value;
				agendaObj['label'] = this.state.allAgendas[i].label;
				agenda.push(agendaObj);
			}
		}
		this.setState({ agenda: null, agendaValue: "" }, function() {
			this.setState({ agenda });
		}.bind(this));
	}

	resetFilters() {
		this.setState({ modelsLoaderHiding: '', showModelsLoader: true });
		let goalValue = '';
		let agenda = [];
		let agendaValue = '';

		this.setState({ goalValue: null, agenda: null, agendaValue: null }, function() {
			this.setState({ goalValue, agenda, agendaValue });
		});

		fetch(process.env.REACT_APP_API_URL + '/innovation', {
			method: 'GET',
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then(function(response) {
				if(response.ok) return response.json();
		}).then(function(data) {
			let innovativeModels = data.data;
			this.setState({ innovativeModels, filteredInnovativeModels: innovativeModels, pageCount: Math.ceil(innovativeModels.length / this.state.perPage), searchTerm: '', offset: 0 }, function() {
				let filteredInnovativeModels = this.state.filteredInnovativeModels.filter(createFilter(this.state.searchTerm, KEYS_TO_FILTERS));
				this.setState({ filteredInnovativeModels }, function() {
					this.getPaginatedData();
				}.bind(this));
			}.bind(this));
		}.bind(this));
	}

	applyFilters() {
		this.setState({ modelsLoaderHiding: '', showModelsLoader: true });
		let params = {};
		if(this.state.goalValue !== '' && this.state.goalValue !== null) {
			let goalValue = this.state.goalValue;
			let goalId = '';
			for(var i = 0; i < this.state.goal.length; i++) {
				if(this.state.goal[i].goalId === goalValue) goalId = this.state.goal[i].value;
			}
			params['goal'] = goalId;
		}
		if(this.state.agendaValue !== '') {
			if(Array.isArray(this.state.agendaValue)) {
				if(this.state.agendaValue.length) {
					params['agenda'] = [];
					this.state.agendaValue.map(function(val) {
						params['agenda'].push(val);
					})
				}
			}
		}
		let esc = encodeURIComponent
		let query = Object.keys(params).map(function(k) {
			if(Array.isArray(params[k])) {
				let x = '';
				for(let i = 0; i < params[k].length; i++) {
					if(i === 0) x += esc(k) + '=' + esc(params[k][i]);
					else x += '&' + esc(k) + '=' + esc(params[k][i])
				}
				return x;
			} else return esc(k) + '=' + esc(params[k])
		}).join('&')
		if(query !== '') {
			let url = process.env.REACT_APP_API_URL + '/innovation' + '?' + query;
			fetch(url, {
				method: 'GET',
				headers: {
					'Auth': JSON.parse(readCookie('access_token')).access_token
				}
			}).then(function(response) {
				if(response.ok) return response.json();
			}).then(function(data) {
				let innovativeModels = data.data;
				this.setState({ innovativeModels, filteredInnovativeModels: innovativeModels, pageCount: Math.ceil(innovativeModels.length / this.state.perPage) }, function() {
					let filteredInnovativeModels = this.state.filteredInnovativeModels.filter(createFilter(this.state.searchTerm, KEYS_TO_FILTERS));
					this.setState({ filteredInnovativeModels }, function() {
						this.getPaginatedData();
					}.bind(this));
				}.bind(this));
			}.bind(this));
		} else {
			this.setState({ modelsLoaderHiding: 'hiding', showModelsLoader: false });
		}
	}

	showProjects() {
		window.location.pathname = '/discover/projects';
	}

	render() {
		if(this.state.innovativeModels !== null && this.state.filteredInnovativeModels !== null && this.state.paginatedData !== null) {
			return (
				<div className="mis-content-container mis-innovative-models">
					<Row className="csr-projects-filters">
						<div className="mb10 csr-projects-filters-container">
							<Col md={4} className="search-container">
								<div className="csr-projects-search-filter-container">
									<input className="csr-projects-search-filter-input" type="search" placeholder="Search by Name" defaultValue={this.state.searchTerm} onChange={this.updateSearchTerm.bind(this)} />
									<button className="btn csr-projects-search-filter-icon"><i className="fa fa-search"></i></button>
								</div>
							</Col>
							<Col md={3}>
								<span>Goals</span>
								{this.state.goalValue !== null ? (
									<Select id="csr-project-goals" defaultValue={this.state.goalValue} size="large" onChange={this.handleChangeGoal.bind(this)}>
										<Option disabled value="">None Selected</Option>
										{this.state.goal.map(function(goal, index) {
											return <Option key={index} value={goal.goalId}>{goal.label}</Option>
										})}
									</Select>
								) : (null)}
							</Col>
							<Col md={3}>
								<span>Agendas</span>
								{this.state.agendaValue !== null && this.state.agenda !== null ? (
									<Multiselect id="csr-project-goals" defaultValue={this.state.agendaValue} onChange={this.handleChangeAgenda.bind(this)} buttonWidth={'100%'} disableIfEmpty buttonText={this.showButtonText.bind(this)} data={this.state.agenda} multiple />
								) : (null)}
							</Col>
							<Col md={1} className="btn filter-reset-btn" onClick={this.resetFilters.bind(this)}>Reset</Col>
							<Col md={1} className="btn filter-apply-btn" onClick={this.applyFilters.bind(this)}>Apply</Col>
						</div>
					</Row>
					<div className={!this.state.showModelsLoader ? "innovative-models" : "innovative-models loading"}>
						{this.state.showModelsLoader ? (
							<Loader loaderHiding={this.state.modelsLoaderHiding} />
						) : (null)}
						<div className="innovative-model-row">
							<span className="dot"></span>
							<span className="innovative-model-span">Innovative Models</span>
							<span className="line"></span>
						</div>
						{this.state.paginatedData.map(function(innovativeModel, index) {
							if(innovativeModel !== undefined) {
								return (
									<div className="col-lg-3 col-md-4 col-sm-6 innovative-model">
										<div className="row">
											<div className="col-md-12 ideaResult">
												<a href={"/innovation/" + innovativeModel.customUrl} target="_self">
													<div className="resultCover col-md-12" style={{ backgroundImage: "url('https://le-uploaded-image-bucket.s3.amazonaws.com/" + innovativeModel.coverImage + "')" }}>
														{innovativeModel.hasOwnProperty('isInterested') && innovativeModel.isInterested === true ? (
															<div style={{
																height: '30px',
																width: '30px',
																borderRadius: '50%',
																marginTop: '10px'
															}}>
																<i className="fa fa-heart" style={{
																	fontSize: '20px',
																	color: '#ef5a20'
																}}></i>
															</div>
														):(null)}
													</div>
												</a>
												<h2 className="resultName">
													<a className="title-ellipsis" href={"/innovation/" + innovativeModel.customUrl} target="_self">
														{innovativeModel.title}
													</a>
												</h2>
												<div className="col-md-12 resultDescription">
													<div>
														<div className="desc-ellipsis">{innovativeModel.description}</div>
														<div>
															<a className="continue-reading" href={"/innovation/" + innovativeModel.customUrl} target="_self">
																Read More
															</a>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								)
							} else return null;
						}.bind(this))}
						<div className="fixed-bottom-container">
							<div>
								<h4>View LetsEndorse Assured Solution Centric Projects.</h4>
								<button className="btn show-innovations-btn" onClick={this.showProjects.bind(this)}>View more!</button>
							</div>
						</div>
						{this.state.pageCount > 1 ? (
							<Col md={12} className="pagination-container text-center">
								<ReactPaginate previousLabel={"Prev"} nextLabel={"Next"} breakLabel={<a href="">...</a>}
									breakClassName={"break-me"} pageCount={this.state.pageCount} marginPagesDisplayed={2}
									pageRangeDisplayed={5} onPageChange={this.handlePageClick.bind(this)} containerClassName={"pagination"}
									subContainerClassName={"pages pagination"} activeClassName={"active"} />
							</Col>
						) : (null)}
					</div>
				</div>
			)
		} else if(this.state.showLoader) {
			return <Loader loaderHiding={this.state.loaderHiding} />
		} else {
			return null;
		}
	}
}