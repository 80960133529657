import React, {Component} from 'react';

import ProjectContainer from './ProjectContainer';

export default class Projects extends Component {
	render() {
		return (
			<div className="projects">
				{this.props.projects !== null ? (
					this.props.projects.map(function(project, i) {
						return <ProjectContainer key={i} project={project} />
					})
				) : (
					<div></div>
				)}
			</div>
		);
	}
}