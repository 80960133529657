export const updateProjects = (projects) => {
	return {
		projects,
		type: 'UPDATE_PROJECTS'
	}
};

export const updateSelectedProject = (selectedProject) => {
	return {
		selectedProject,
		type: 'UPDATE_SELECTED_PROJECT'
	}
};

export const toggleProjectFilter = (hideProjectsAdvancedFilter) => {
	return {
		hideProjectsAdvancedFilter,
		type: 'TOGGLE_PROJECT_FILTER'
	}
};

export const updateSearchTerm = (searchTerm) => {
	return {
		searchTerm,
		type: 'UPDATE_SEARCH_TERM'
	}
};

export const updateFilteredProjects = (filteredProjects) => {
	return {
		filteredProjects,
		type: 'UPDATE_FILTERED_PROJECTS'
	}
};

export const toggleDiscoverCSRProjectsView = () => {
	return {
		type: 'TOGGLE_DISCOVER_CSR_PROJECTS_VIEW'
	}
};

export const toggleInterested = () => {
	return {
		type: 'TOGGLE_INTERESTED'
	}
};

export const updateShortlistedProjects = (shortlistedProjects) => {
	return {
		shortlistedProjects,
		type: 'UPDATE_SHORTLISTED_PROJECTS'
	}
};