import React, { Component } from 'react';
import { Menu, Icon, Dropdown } from "antd";
import $ from 'jquery';
import moment from 'moment';
import TimeAgo from 'react-timeago';
import InfiniteScroll from 'react-infinite-scroll-component';

const readCookie = require("../../../cookie.js").readCookie;

export default class Notifications extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userData: null,
			notifications: [],
			totalNotifications: 0,
			unseenNotifications: 0,
			perPage: 7,
			page: 1,
			hasMoreItems: false,
			notification: false
		};
	}

	componentDidMount() {
		if (this.state.notification === false) {
			let notifications = [];
			fetch(process.env.REACT_APP_API_URL + '/notifications' + "?perPage=" + this.state.perPage, {
				method: "GET",
				headers: {
					'Auth': JSON.parse(readCookie('access_token')).access_token
				}
			}).then(res => res.json())
				.then(res => {
					if (res.status === "ok") {
						notifications = res.data.notifications;
						let hasMoreItems = this.state.hasMoreItems;
						if (notifications.length < res.data.total) {
							hasMoreItems = true;
						}
						sessionStorage.setItem('notificationCount', 0);

						this.setState({ notifications, notification: true, hasMoreItems, showBadge: false, totalNotifications: res.data.total });
					}
				})
		} else {
			this.setState({ notification: false });
		}
	}


	loadMoreNotifications = () => {
		console.log('hello');
		let notifications = [];
		let page = this.state.page;
		page += 1;
		if (this.state.notifications.length >= this.state.totalNotifications) {
			this.setState({ hasMoreItems: false });
			return;
		}
		fetch(process.env.REACT_APP_API_URL + '/notifications' + "?page=" + this.state.page + "&perPage=" + this.state.perPage, {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then(res => res.json())
			.then(res => {
				if (res.status === "ok") {
					notifications = this.state.notifications.concat(res.data.notifications);
					this.setState({ notifications, totalNotifications: res.data.total, page });
				}
			})
	}

	formatter(x, y, z) {
		if (y === 'second') return 'few seconds ago';
		else if (x === 1) return x + ' ' + y + ' ' + z;
		else return x + ' ' + y + 's ' + z;
	}

	render() {
		var items = [];
		const loader = <div className="loader" style={{ display: "flex", justifyContent: "center" }}><img src="/img/spinner.gif" style={{ width: "60px", height: "60px" }} /></div>;
		this.state.notifications.map((notification, index) => {
			items.push(
				<div key={index} className="notification-dropdown-menu" style={{ display: "flex", height: "auto", padding: "10px", cursor: "pointer", border: "solid 1px #eee", borderRadius: "7px", marginTop:"10px" }} onClick={() => { window.location.pathname = notification.redirectUrl }}>
					{notification.leftImage ? (
						<img className="left-image" src={notification.leftImage} style={{ width: "65px", height: "70px" }} />
					) : (null)}
					<div className='notificationInfo' style={{ display: "flex", flexDirection: "column", marginLeft: "8px", marginRight: "8px", width: "100%" }} >
						<h4 className="message" style={{ margin: "0px 0px 2px 0px" }}>{notification.msg}</h4>
						<p style={{ color: "#a9a9a9", margin: "0px 0px 5px 0px" }}><TimeAgo style={{ fontSize: "14px" }} date={moment(notification.createdAt).toDate()} formatter={this.formatter} /></p>
						<button className='viewNotification-button' onClick={() => { window.location.pathname = notification.redirectUrl }} style={{ width: "100px" }}>View</button>
					</div>
					{notification.rightImage ? (
						<img className="right-image" src={notification.rightImage} style={{ width: "125px", height: "100%" }} />
					) : (null)}
				</div>
			);
		});
		return (
			<div className="mis-content-container">
				<div id="notification" className="notification" style={{ margin: "0 auto" }}>
					<h2 className="notificationHeader">
						Notifications
					</h2>
					<div id='tracks' style={{ height: "84vh", overflowY: "scroll", paddingTop: "12px" }} className="tip">
						{this.state.notifications.length ? (
							<InfiniteScroll
								dataLength={this.state.notifications.length}
								pageStart={0}
								next={this.loadMoreNotifications}
								hasMore={this.state.hasMoreItems}
								loader={loader}
								scrollableTarget={"tracks"}
								endMessage={
									<p style={{ textAlign: "center", color: "#a9a9a9", marginTop: "12px", fontSize: "16px" }}>
										You don't have new notifications any more.
									</p>
								}
								style={{ height: "auto" }}
							>
								{items}
								{this.state.hasMoreItems === true ? <p style={{ textAlign: "center", color: "#a9a9a9", marginTop: "12px" }}>
									Scroll down to load more notifications.
									</p> : null}
							</InfiniteScroll>
						) : (<div className="messageDivEmpty" style={{ height: "100%" }}>
							<div className='notifyInfo' style={{ display: "flex", flexDirection: "column", marginLeft: "8px", marginRight: "8px", width: "auto", justifyContent: "center", height: "100%" }}>
								<h3 style={{ color: "#a9a9a9", fontSize: "16px", marginTop: "0px", marginBottom: "0px", textAlign: "center" }} className="my-2">You don't have any new Notifications.</h3>
							</div>
						</div>)}
					</div>
				</div>
			</div>
		);
	}
}