import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import SingleProjectMap from './SingleProjectMap';
import Calendar from './Calendar';
import ExpressInterestModal from './ExpressInterestModal';

import * as action from '../../../../actions/DiscoverProjectsAction';

const checkValue = require('../../../../checkValue.js').checkValue;
const readCookie = require('../../../../cookie.js').readCookie;

class SingleProject extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showExpressInterestModal: false,
		}
		this.onExpressInterestModalClose = this.onExpressInterestModalClose.bind(this);
	}

	componentDidMount() {
		if(!this.props.selectedProject.hasOwnProperty('customUrl')) {
			let path = window.location.pathname;
			path = path.split('/').pop();
			fetch(process.env.REACT_APP_API_URL + '/volunteer-opportunity/' + path, {
				method: "GET",
				headers: {
					'Auth': JSON.parse(readCookie('access_token')).access_token
				}
			}).then((data) => data.json())
			.then(function(data) {
				if(data.hasOwnProperty('data')) {
					this.props.actions.updateSelectedProject(data['data']);
				} else {
					window.location = "/404";
				}
			}.bind(this));
		}
	}

	clearSelectedProject() {
		let projectJSON = {};
		this.props.actions.updateSelectedProject(projectJSON);
	}

	onExpressInterestModalClose() {
		this.setState({ showExpressInterestModal: false });
	}

	onExpressInterestModalOpen() {
		this.setState({ showExpressInterestModal: true });
	}

	render() {
		if(this.props.selectedProject.hasOwnProperty('title')) {
			return (
				<div className="single-project-container">
					<Row className="back-container">
						<Link to={"/csr/explore-volunteer-opportunities"} className="back" onClick={this.clearSelectedProject.bind(this)}>
							<i className="fa fa-chevron-left"></i>
							{' GO TO ALL PROJECTS'}
						</Link>
					</Row>
					<Row>
						<Col md={9} className="project-details">
							<span className="project-name">{this.props.selectedProject.title}</span>
							<Row>
								<Col md={12} className="features">
									<div className="content">{this.props.selectedProject.description}</div>
								</Col>
								<Col md={12} className="features" hidden={checkValue(this.props.selectedProject.expectedImpact)}>
									<div className="heading">Expected Impact</div>
									<div className="content">{this.props.selectedProject.expectedImpact}</div>
								</Col>
								<Col md={3} className="features" hidden={checkValue(this.props.selectedProject.city)}>
									<div className="heading">Project Location</div>
									<div className="content">{this.props.selectedProject.city}</div>
								</Col>
								<Col md={3} className="features" hidden={checkValue(this.props.selectedProject)}>
									<div className="heading">Cause Area</div>
									<div className="content">{'Cause'}</div>
								</Col>
								<Col md={3} className="features" hidden={checkValue(this.props.selectedProject.projectSetting)}>
									<div className="heading">Location Coverage</div>
									<div className="content">
										{this.props.selectedProject.projectSetting.map(function(projectSetting, i) {
											if(this.props.selectedProject.projectSetting.length - i === 1) return projectSetting.name
											else return projectSetting.name + ', '
										}.bind(this))}
									</div>
								</Col>
								<Col md={3} className="features" hidden={checkValue(this.props.selectedProject.beneficiaryTypes)}>
									<div className="heading">Target beneficiaries</div>
									<div className="content">
										{this.props.selectedProject.beneficiaryTypes.map(function(beneficiaryType, i) {
											if(this.props.selectedProject.beneficiaryTypes.length - i === 1) return this.props.selectedProject.beneficiaryTypes[i].name
											else return this.props.selectedProject.beneficiaryTypes[i].name + ', '
										}.bind(this))}
									</div>
								</Col>
								<Col md={3} className="features">
									<div className="heading">Preferrable Timing Between</div>
									<div className="content">{this.props.selectedProject.startTime + ' - ' + this.props.selectedProject.endTime}</div>
								</Col>
								<Col md={3} className="features" hidden={checkValue(this.props.selectedProject.activities)}>
									<div className="heading">Primary Activities</div>
									<div className="content">
										{this.props.selectedProject.activities.map(function(activity, i) {
											return (
												<div key={i}>
													<i className="fa fa-dot-circle-o"></i>
													{' ' + activity.name}
												</div>
											);
										})}
									</div>
								</Col>
								<Col md={3} className="features">
									<div className="heading">Can happen over weekends: </div>
									<div className="content">{this.props.selectedProject.weekends ? ('Yes') : ('No')}</div>
								</Col>
								<Col md={3} className="features">
									<div className="heading">Requires travelling to distant places: </div>
									<div className="content">{this.props.selectedProject.travelling ? ('Yes') : ('No')}</div>
								</Col>
								<Col md={3} className="features" hidden={checkValue(this.props.selectedProject.pastProjectCount)}>
									<div className="heading">Number of such projects conducted by us in the past: </div>
									<div className="content">{this.props.selectedProject.pastProjectCount}</div>
								</Col>
								<Col md={3} className="features" hidden={checkValue(this.props.selectedProject.date)}>
									<div className="heading">Available Dates</div>
									{this.props.selectedProject.hasOwnProperty('dateType') ? (
										this.props.selectedProject.dateType === 'available' ? (
											<Calendar availableDates={this.props.selectedProject.date} />
										) : (
											<Calendar unavailableDates={this.props.selectedProject.date} dateType={this.props.selectedProject.dateType} />
										)
									) : (
										<Calendar availableDates={this.props.selectedProject.date} />
									)
									}
								</Col>
							</Row>
						</Col>
						<Col md={3} className="other-details" hidden={checkValue(this.props.selectedProject.beneficiaryCount)}>
							<div className="other-features">
								<i className="fa fa-user-circle"></i>
								<div className="details">
									<div className="heading">No. of Beneficiaries</div>
									<div className="content">{this.props.selectedProject.beneficiaryCount}</div>
								</div>
							</div>
							<div className="other-features" hidden={checkValue(this.props.selectedProject.duration)}>
								<i className="fa fa-calendar"></i>
								<div className="details">
									<div className="heading">Project Duration</div>
									{this.props.selectedProject.duration > 1 ? (<div className="content">{this.props.selectedProject.duration + ' hrs'}</div>):(<div className="content">{this.props.selectedProject.duration + ' hr'}</div>)}
								</div>
							</div>
							<div className="other-features" hidden={checkValue(this.props.selectedProject.cost)}>
								<div className="money"></div>
								<div className="details">
									{this.props.selectedProject.costType === 'perVolunteer' ? (<div className="heading">{'Budget per Volunteer'}</div>) : (<div className="heading">{'Total Budget'}</div>)}
									{this.props.selectedProject.hasOwnProperty('currency_symbol') ? (<div className="content">{this.props.selectedProject.currency_symbol + ' ' + this.props.selectedProject.cost}</div>) : (<div className="content">{'INR ' + this.props.selectedProject.cost}</div>)}
								</div>
							</div>
							<div className="other-features" hidden={checkValue(this.props.selectedProject.minVolunteers)}>
								<i className="fa fa-users"></i>
								<div className="details">
									<div className="heading">Volunteers</div>
									{!this.props.selectedProject.hasOwnProperty('minVolunteers') && !this.props.selectedProject.hasOwnProperty('maxVolunteers') ? (
										''
									) : (
										this.props.selectedProject.hasOwnProperty('minVolunteers') && this.props.selectedProject.hasOwnProperty('maxVolunteers') ? (
											this.props.selectedProject.minVolunteers + ' - ' + this.props.selectedProject.maxVolunteers
										) : (
											this.props.selectedProject.hasOwnProperty('minVolunteers') ? (
												'Min : ' + this.props.selectedProject.minVolunteers
											) : (
												this.props.selectedProject.hasOwnProperty('maxVolunteers') ? (
													'Max : ' + this.props.selectedProject.maxVolunteers
												) : (
													''
												)
											)
										)
									)
									}
								</div>
							</div>
						</Col>
						<Col mdOffset={9} md={3} className="express-interest-container">
							<button className="btn express-interest" onClick={this.onExpressInterestModalOpen.bind(this)}>Express Interest</button>
							<SingleProjectMap selectedProject={this.props.selectedProject} />
						</Col>
					</Row>
					<Row>
						<Col md={9} className="details">
							<Row>

							</Row>
						</Col>

					</Row>
					{this.props.selectedProject.hasOwnProperty('dateType') ? (
						this.props.selectedProject.dateType === 'available' ? (
							<ExpressInterestModal volunteerOpportunityId={this.props.selectedProject.id}
							                      availableDates={this.props.selectedProject.date}
							                      showExpressInterestModal={this.state.showExpressInterestModal}
							                      onExpressInterestModalClose={this.onExpressInterestModalClose}
							                      onExpressInterestModalOpen={this.onExpressInterestModalOpen} />
						) : (
							<ExpressInterestModal volunteerOpportunityId={this.props.selectedProject.id}
							                      unavailableDates={this.props.selectedProject.date} dateType={this.props.selectedProject.dateType}
							                      showExpressInterestModal={this.state.showExpressInterestModal}
							                      onExpressInterestModalClose={this.onExpressInterestModalClose}
							                      onExpressInterestModalOpen={this.onExpressInterestModalOpen} />
						)
					) : (
						<ExpressInterestModal volunteerOpportunityId={this.props.selectedProject.id}
						                      availableDates={this.props.selectedProject.date}
						                      showExpressInterestModal={this.state.showExpressInterestModal}
						                      onExpressInterestModalClose={this.onExpressInterestModalClose}
						                      onExpressInterestModalOpen={this.onExpressInterestModalOpen} />
					)}
				</div>
			);
		} else {
			return null;
		}
	}
}

SingleProject.propTypes = {
	selectedProject: PropTypes.object,
};

function mapStateToProps(state) {
	return {
		selectedProject: state.DiscoverProjects.selectedProject,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(action, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SingleProject);