import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';

import SvgIcon from "react-icons-kit";
import { ic_list } from 'react-icons-kit/md/ic_list';

import Loader from '../Loader';
import MISProjectsFilter from './MISProjectsFilter';

const checkValue = require('../../../checkValue.js').checkValue;
const readCookie = require('../../../cookie.js').readCookie;

let max = 0;
let marks = {
	0: 'INR 0'
}

export default class MISProjects extends Component {
	constructor(props) {
		super(props);
		this.state = {
			projects: null,
			searchTerm: '',
			discoverCSRProjectsListView: true,
			filteredProjects: null,
			shortlistedProjects: null,
			selectedProject: {},
			priceRangeMax: 0,
			priceRangeMin: 0,
			initialPosition: {
				lat: 20.5937,
				lng: 78.9629
			},
			showShortlisted: false,
			showLoader: true,
			loaderHiding: '',
			totalProjectsCount: 0,
			interestedProjectsCount: 0,
			underReviewProjectsCount: 0,
			investedProjectsCount: 0,
			skippedProjectsCount: 0,
			selectedProjectStatus: '',
			corporateInvestment: {},
			goalValue:'',
		    min :0,
			max :0
		}
		this.getCurrentYear = this.getCurrentYear.bind(this);
	}

	componentDidMount() {
		let params = {};
		if(this.state.goalValue !== '' ) {
			if(Array.isArray(this.state.goalValue)) {
				if(this.state.goalValue.length) {
					params['goalId'] = this.state.goalValue;
					// this.state.goalValue.map(function (val) {
					// 	params['goalId'].push(val);
					// })
				}
			}
		}
		params['year'] = this.getCurrentYear();
		params['minBudget'] = this.state.min;
		params['maxBudget'] = this.state.max;
		params['corporateInterestStatus'] = 'all';
		// params['projectCategory'] = this.state.projectType;
		params['query'] = '';
		let esc = encodeURIComponent
		let query = Object.keys(params).map(function (k) {
			if (Array.isArray(params[k])) {
				let x = '';
				for (let i = 0; i < params[k].length; i++) {
					if (i === 0) x += esc(k) + '=' + esc(params[k][i]);
					else x += '&' + esc(k) + '=' + esc(params[k][i])
				}
				return x;
			} else return esc(k) + '=' + esc(params[k])
		}).join('&')

		let companyId = JSON.parse(readCookie('userData')).organisationData.id;
		let url = process.env.REACT_APP_API_URL + '/project/recommendations/' + companyId + '?' + query
		// let companyId = JSON.parse(readCookie('userData')).organisationData.id;
			fetch(url, {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
			.then(function (data) {
				if (data.hasOwnProperty('projectInterestStatus') && Object.keys(data.projectInterestStatus).length > 0) {
					let totalProjectsCount = data.projectInterestStatus['all'];
					let interestedProjectsCount = data.projectInterestStatus['interested'];
					let underReviewProjectsCount = data.projectInterestStatus['under-review'];
					let investedProjectsCount = data.projectInterestStatus['invested'];
					let skippedProjectsCount = data.projectInterestStatus['skipped'];
					this.setState({ totalProjectsCount, interestedProjectsCount, underReviewProjectsCount, investedProjectsCount, skippedProjectsCount });
				}

				let dataObj = data.data;
				for (var i = 0; i < dataObj.length; i++) {
					let corporateInvestment = {};
					if (dataObj[i].hasOwnProperty('corporateInvestment')) {
						corporateInvestment['companyId'] = dataObj[i].corporateInvestment.companyId;
						corporateInvestment['investmentId'] = dataObj[i].corporateInvestment.investmentId;
						corporateInvestment['portfolioId'] = dataObj[i].corporateInvestment.portfolioId;
					}
					this.setState({ corporateInvestment });
				}
				let projects = data.data.filter(x => x.fiscalYear === this.getCurrentYear())
				

				this.setState({ projects: projects, filteredProjects:projects, loaderHiding: 'hiding' }, function () {
					setTimeout(function () {
						this.setState({ showLoader: false });
					}.bind(this), 1500);
				}.bind(this));
			}.bind(this));
	}

	updateProjects(projects) {
		let priceRangeMax = 0;
		projects.map(function (project) {
			if (project.max_budget > priceRangeMax) priceRangeMax = project.max_budget;
		});
		this.setState({
			projects,
			priceRangeMax
		});
	}

	updateSearchTerm(searchTerm) {
		this.setState({ searchTerm });
	}
	getCurrentYear(){
		var today = new Date();
		var curMonth = today.getMonth();
		var fiscalYr = "";
		if (curMonth > 3) {
		  return today.getFullYear().toString() 
		} else {
		  return (today.getFullYear() - 1).toString() 		
		}
	  }

	updateFilteredProjects(filteredProjects) {
		let priceRangeMax = 0;
		filteredProjects.map(function (filteredProject) {
			if (filteredProject.max_budget > priceRangeMax) priceRangeMax = filteredProject.max_budget;
		});
		this.setState({
			filteredProjects,
			priceRangeMax
		});
	}

	toggleShortListed() {
		this.setState({ showShortlisted: this.state.showShortlisted });
	}

	updateShortlistedProjects(shortlistedProjects) {
		let priceRangeMax = 0;
		let newShortlistedProjects = [];
		shortlistedProjects.map(function (shortlistedProject) {
			for (var i = 0; i < this.state.projects.length; i++) {
				if (this.state.projects[i].id === shortlistedProject.project.id) {
					newShortlistedProjects.push(this.state.projects[i]);
					if (this.state.projects[i].max_budget > priceRangeMax) priceRangeMax = this.state.projects[i].max_budget;
				}
			}
		});
		this.setState({
			shortlistedProjects: newShortlistedProjects,
			filteredProjects: newShortlistedProjects,
			priceRangeMax
		});
	}

	/*sendProjectStatus(status, projectId) {
		let data = {};
		data.status = status;
		fetch(process.env.REACT_APP_API_URL + '/project/' + projectId + '/interests', {
			method: 'POST',
			headers: {
				'Content-type': 'application/json',
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
			body: JSON.stringify(data)
		}).then(res => res.json())
		.then(res => {
			if (res.status === 'ok') {
				let selectedProjectStatus = res.data.status;
				this.setState({ selectedProjectStatus })
			}
		});
	}*/

	showInnovativeModels() {
		window.location.pathname = '/discover/innovative-models';
	}

	updateProjectTypeBreadcrumb(name, val) {
		this.setState({ [name]: val });
	}

	updatePreferencesForMoreProjects() {
		ReactGA.event({
		  category: 'Discover Projects',
		  action: 'click',
		  label: 'Update Preferences'
		});
		window.location.pathname = "/csr-strategy-setup";
	}

	render() {
		return (
			<div className="mis-content-container">
				{this.state.showLoader ? (
					<Loader loaderHiding={this.state.loaderHiding} />
				) : ('')}
				<Col md={12} className="projects-list">
					{this.state.projects !== null && this.state.filteredProjects !== null && this.state.showShortlisted !== null ? (
						<div className="projects">
							<MISProjectsFilter projects={this.state.projects} searchTerm={this.state.searchTerm} updateProjectTypeBreadcrumb={this.updateProjectTypeBreadcrumb.bind(this)}
								totalProjectsCount={this.state.totalProjectsCount} interestedProjectsCount={this.state.interestedProjectsCount}
								underReviewProjectsCount={this.state.underReviewProjectsCount} investedProjectsCount={this.state.investedProjectsCount}
								skippedProjectsCount={this.state.skippedProjectsCount} max={max} marks={marks} showShortlisted={this.state.showShortlisted}
								updateProjects={this.updateProjects.bind(this)} updateShortlistedProjects={this.updateShortlistedProjects.bind(this)}
								updateSearchTerm={this.updateSearchTerm.bind(this)} updateFilteredProjects={this.updateFilteredProjects.bind(this)}
								toggleShortListed={this.toggleShortListed.bind(this)} />
							<div className="projects-count-row">
								<span className="dot"></span>
								<span className="project-count">{this.state.filteredProjects.length + ' Projects Found'}</span>
								<span className="line"></span>
							</div>
							{this.state.filteredProjects.map(function (project, i) {
								return (
									<Row key={i} className="single-project csr-project">
										<Col md={3} className="csr-project-image-container">
											<img className="csr-project-image" src={project.coverImage ? project.coverImage : "https://assets.letsendorse.com/sahaj/project-placeholder.jpg"} />
											<div className="project-type">
												<span className="current-state-outer">
													<span className="outer-arrow"></span>
													{project.LEAssured ? (
														<span className="current-state-text">LE Assured</span>
													) : (
														<span className="current-state-text">Partner Project</span>
													)}
												</span>
											</div>
										</Col>
										<Col md={6}>
											<Col md={12} className="project-title">
												<Link to={"/mis-projects/" + project.customUrl} target="_self"><span>{project.title}</span></Link>
												{project.ngoData.name ? (
													<div className="ngo-name-container">
														{"Submitted by- "}<b>{project.ngoData.name}</b>
													</div>
												) : (null)}
											</Col>
											<Col md={12} className="project-description">
												<p>{project.description}</p>
											</Col>
											{checkValue(project.targetBeneficiaries) !== true ? (
												<Col md={12} className="features mt5" hidden={checkValue(project.targetBeneficiaries)}>
													<div className="heading">Target beneficiaries: </div>
													<div className="content">
														{project.targetBeneficiaries.map(function (targetBeneficiary, i) {
															if (project.targetBeneficiaries.length - i === 1) return targetBeneficiary.name
															else return targetBeneficiary.name + ', '
														}.bind(this))}
													</div>
												</Col>
											) : (null)}
											{checkValue(project.goalData) !== true ? (
												<Col md={12} className="features mt5" hidden={checkValue(project.goalData.name)}>
													<div className="heading">Cause/Developmental Goal: </div>
													<div className="content">{project.goalData.name}</div>
												</Col>
											) : (null)}
											{checkValue(project.fiscalYear) !== true ? (
												<Col md={12} className="features mt5" hidden={checkValue(project.fiscalYear)}>
													<div className="heading">Investment Year: </div>
													<div className="content">{project.fiscalYear+'-'+(parseInt(project.fiscalYear)+1)}</div>
												</Col>
											) : (null)}
											<Col md={12} className="features mt5">
												{/*!--{project.corporateInterestStatus !== false ? (
													this.state.corporateInvestment.investmentId !== '' && this.state.corporateInvestment.portfolioId !== '' ?
														(
															<Link to={"/mis-projects/" + project.customUrl} target="_blank">
																<button className="btn btn-interested" onClick={this.sendProjectStatus.bind(this, 'interested', project.id)}>{project.corporateInterestStatus}</button>
															</Link>
														) : (
															<Link to={"/mis-projects/" + project.customUrl} target="_blank">
																<button className="btn btn-interested" onClick={this.sendProjectStatus.bind(this, 'interested', project.id)}>{project.corporateInterestStatus}</button>
															</Link>
														)
												) : (
														this.state.corporateInvestment.investmentId !== '' && this.state.corporateInvestment.portfolioId !== '' ? (
															<Link to={"/mis-projects/" + project.customUrl} target="_blank">
																<button className="btn btn-interested" onClick={this.sendProjectStatus.bind(this, 'interested', project.id)}>Show Interest</button>
															</Link>
														) : (
																<Link to={"/mis-projects/" + project.customUrl} target="_blank">
																	<button className="btn btn-interested" onClick={this.sendProjectStatus.bind(this, 'interested', project.id)}>Show Interest</button>
																</Link>
															)
													)}*/}
												<Link to={"/mis-projects/" + project.customUrl} target="_blank">
													<button className="btn btn-interested">Read More</button>
												</Link>
											</Col>
										</Col>
										<Col md={3}>
											{project.thematicCategory && project.thematicCategory.name ? (
												<Col md={12} className="project-thematic-category">
													<div>
														<SvgIcon size={16} style={{color:"#ef5a20"}} icon={ic_list} /><b>{' Schedule 7 Category: '}</b>
														<span>{project.thematicCategory.name}</span>
													</div>
												</Col>
											) : (null)}
											<Col md={12} className="project-location">
												{project.location && project.location.length ? (
													<div>
														<i className="fa fa-map-marker"></i><b>{' Location: '}</b>
														<span hidden={checkValue(project.location[0].address)}>{project.location.length > 1 ? (' Multiple Locations') : (' ' + project.location[0].address)}</span>
													</div>
												) : (null)}
												<span className="project-address">
													<span hidden={checkValue(project.city)}>{project.city}</span>
													<span hidden={checkValue(project.state)}>{', ' + project.state}</span>
													<span hidden={checkValue(project.country)}>{', ' + project.country}</span>
													<span hidden={checkValue(project.pincode)}>{', ' + project.pincode}</span>
												</span>
											</Col>
											<Col md={12} className="project-budget-con" hidden={checkValue(project.proposedBudget)}>
												<i className="fa fa-money"></i>
												<span className="project-budget">
													{project.hasOwnProperty('currencySymbol') ? (
														<span><b>{' Budget: '}</b>{project.currencySymbol + ' ' + window.inrFormat(project.proposedBudget)}</span>
													) : (
															<span><b>{' Budget: '}</b>{'INR ' + window.inrFormat(project.proposedBudget)}</span>
														)}
												</span>
											</Col>
											<Col md={12} className="project-duration-container" hidden={checkValue(project.expectedDurationObject)}>
												<i className="fa fa-calendar"></i>
												<span className="project-duration"><b>{' Duration: '}</b>{project.expectedDurationObject.duration} {project.expectedDurationObject.type}</span>
											</Col>
											<Col md={12} className="project-status-container" hidden={checkValue(project.corporateInterestStatus)}>
												<i className="fa fa-filter"></i>
												{project.corporateInterestStatus === false ? (
													<span className="project-status"><b>{' Status: '}</b>Open</span>
												) : (
													<span className="project-status"><b>{' Status: '}</b>{project.corporateInterestStatus}</span>
												)}
											</Col>
											{/*<Col md={12} className="project-status-container" hidden={checkValue(project.corporateInterestStatus)}>
												<i className="fa fa-calendar"></i>
													<span className="project-status"><b>{' Year: '}</b></span>
											</Col>*/}
											<Col md={12} className="project-status-container" hidden={checkValue(project.projectInterestCode)}>
												<i className="fa fa-key"></i>
												<span className="project-status"><b>{' Email Code: '}</b><b style={{color: "#ef5a20"}}>{project.projectInterestCode}</b></span>
											</Col>
										</Col>
									</Row>
								)
							}.bind(this))}
							{JSON.parse(readCookie('userData')).authorizedFor.mis !== 'view' ? (
								<div className="invite-partner-link" onClick={() => { window.location.pathname = '/discover/partner-projects' }}>
									<div className="slider-icon-container">
										<i className="fa fa-handshake-o"></i>
									</div>
									<div className="slider-message-show">Invite Partners</div>
								</div>
							) : (null)}
							<div className="fixed-bottom-container">
								<div>
									<h4>Explore Innovative and Impactful Solutions Across SDGs.</h4>
									<button className="btn show-innovations-btn" onClick={this.showInnovativeModels.bind(this)}>Show me!</button>
								</div>
							</div>
						</div>
					) : (null)}
				</Col>
				{/* {this.state.projects !== null && this.state.filteredProjects !== null ? (
					this.state.projects.length === 0 && this.state.filteredProjects.length === 0 ? (
						<div className="create-annual-budget-container">
							<div className="create-annual-budget-button" onClick={this.updatePreferencesForMoreProjects}>Update Your Preferences To Get More Projects</div>
						</div>
					) : (null)
				) : (null)} */}
			</div>
		)
	}
}
