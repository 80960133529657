export const updateProjects = (projects) => {
	return {
		projects,
		type: 'UPDATE_PROJECTS'
	}
};

export const updateSelectedProject = (selectedProject) => {
	return {
		selectedProject,
		type: 'UPDATE_SELECTED_PROJECT'
	}
};

export const updateTopProject = (topProject) => {
	return {
		topProject,
		type: 'UPDATE_TOP_PROJECT'
	}
};