import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import SwitchButton from 'lyef-switch-button';

import * as action from '../../../actions/DiscoverCSRProjectsAction';

import CSRProjectsContainer from './CSRProjectsContainer';
import DiscoverCSRProjectsMap from './DiscoverCSRProjectsMap';
// import DiscoverCSRProjectsList from './DiscoverCSRProjectsList';
import DiscoverCSRProjectsFilter from './DiscoverCSRProjectsFilter';

const readCookie = require('../../../cookie.js').readCookie;

let max = 0;
let marks = {
	0: 'INR 0'
}

class DiscoverCSRProjects extends Component {

  toggleProjectsAdvancedFilters() {
  	this.props.actions.toggleProjectFilter(!this.props.hideProjectsAdvancedFilter);
	}

	componentDidMount() {
		fetch(process.env.REACT_APP_API_URL + '/projects/discover', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
		.then(function(data) {
			this.props.actions.updateProjects(data.data);
			max = 0;
			for(var i = 0; i < data.data.length; i++) {
				if(data.data[i]['proposedBudget'] > max) max = data.data[i]['proposedBudget'];
			}
			marks[max] = 'INR ' + max;
			this.props.actions.updateFilteredProjects(data.data);
		}.bind(this));
	}

	render() {
		return (
			<div className="container">
				<Row>
					<Col md={12} className="projects-filters-container">
						{this.props.projects !== null && this.props.filteredProjects !== null && this.props.showInterested !== null ? (
							<div>
								<Col md={12}>
									<DiscoverCSRProjectsFilter projects={this.props.projects} advancedHidden={this.props.hideProjectsAdvancedFilter} searchTerm={this.props.searchTerm}
										toggleProjectAdvancedFilters={this.toggleProjectsAdvancedFilters.bind(this)} updateProjects={this.props.actions.updateProjects}
										updateSearchTerm={this.props.actions.updateSearchTerm} updateFilteredProjects={this.props.actions.updateFilteredProjects}
										max={max} marks={marks} showInterested={this.props.showInterested} toggleInterested={this.props.actions.toggleInterested}
										updateShortlistedProjects={this.props.actions.updateShortlistedProjects} />
								</Col>
								<Col md={12} className="csr-projects-found-container">
									<span id="projects-count">{this.props.filteredProjects.length + ' PROJECTS FOUND'}</span>
									<SwitchButton id="list-map-switch" labelRight="List" labelLeft="Map" isChecked={this.props.discoverCSRProjectsListView}
										action={this.props.actions.toggleDiscoverCSRProjectsView} />
								</Col>
								{/*this.state.hideProjectsFilter ? (
										<span id="projects-filters" onClick={this.toggleProjectFilters.bind(this)}>{'Advanced Filter '}<i className="fa fa-caret-down"></i></span>
									) : (
										<span id="projects-filters" onClick={this.toggleProjectFilters.bind(this)}>{'Advanced Filter '}<i className="fa fa-caret-up"></i></span>
									)
								*/}


							</div>
						) : (null)}
					</Col>

					{this.props.discoverCSRProjectsListView ? (
						<Col md={12} className="projects-list">
							<CSRProjectsContainer projects={this.props.filteredProjects}
								priceRangeMin={this.props.priceRangeMin}
								priceRangeMax={this.props.priceRangeMax}
								updateProjects={this.props.actions.updateProjects}
								discoverCSRProjectsListView={this.props.discoverCSRProjectsListView} />
						</Col>
					) : (
						<div>
							<Col md={7} className="projects-list">
								<CSRProjectsContainer projects={this.props.filteredProjects}
									priceRangeMin={this.props.priceRangeMin}
									priceRangeMax={this.props.priceRangeMax}
									updateProjects={this.props.actions.updateProjects}
									discoverCSRProjectsListView={this.props.discoverCSRProjectsListView} />
							</Col>
							<Col md={5} className="projects-map">
								<DiscoverCSRProjectsMap projects={this.props.filteredProjects}
									updateProjects={this.props.actions.updateProjects}
									initialPosition={this.props.initialPosition} />
							</Col>
						</div>
					)}
				</Row>
			</div>
		);
	}
}

DiscoverCSRProjects.propTypes = {
	discoverCSRProjectsListView: PropTypes.bool,
	searchTerm: PropTypes.string,
	projects: PropTypes.array,
	filteredProjects: PropTypes.array,
	priceRangeMin: PropTypes.number,
	priceRangeMax: PropTypes.number,
	initialPosition: PropTypes.object,
	showInterested: PropTypes.bool,
	shortlistedProjects: PropTypes.array
};

function mapStateToProps(state) {
	return {
		discoverCSRProjectsListView: state.DiscoverCSRProjects.discoverCSRProjectsListView,
		searchTerm: state.DiscoverCSRProjects.searchTerm,
		projects: state.DiscoverCSRProjects.projects,
		filteredProjects: state.DiscoverCSRProjects.filteredProjects,
		priceRangeMin: state.DiscoverCSRProjects.priceRangeMin,
		priceRangeMax: state.DiscoverCSRProjects.priceRangeMax,
		initialPosition: state.DiscoverCSRProjects.initialPosition,
		showInterested: state.DiscoverCSRProjects.showInterested,
		shortlistedProjects: state.DiscoverCSRProjects.shortlistedProjects
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(action, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DiscoverCSRProjects);