import React, { Component } from 'react';
import DashboardMap from './DashboardMap';
import { OverlayTrigger, Col, Row, Modal } from 'react-bootstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip, RadialBarChart, RadialBar, Legend, BarChart, Bar, XAxis, YAxis, CartesianGrid } from 'recharts';
import $ from 'jquery';
import { LocaleProvider } from 'antd';
import enUS from 'antd/lib/locale-provider/en_US';
import { BootstrapTable, TableHeaderColumn, ButtonGroup } from 'react-bootstrap-table';
import Icon from 'react-icons-kit';
import { ic_add_circle_outline } from 'react-icons-kit/md/ic_add_circle_outline';
import { ic_delete } from 'react-icons-kit/md/ic_delete';
import { Menu } from 'antd';
import Scroll from 'react-scroll';

import ImageModal from '../Discover/SingleMISProject/ImageModal';

const readCookie = require('../../../cookie.js').readCookie;

let year = moment().year();
const years = [];
for (var i = -3; i < 4; i++) {
  var yearStr = (year - i) + '-' + (year - i + 1);
  years.push(yearStr);
}

export default class MISDashboardCSR extends Component {
  constructor(props) {
    super(props);
    this.state = {
      csrInvestmentsData: null,
      fiscalYear: this.getCurrentFiscalYear(),  //moment().year() + '-' + moment().add(1, 'years').year(),
      markers: null,
      amountComparisonData: [],
      createCSRPortfolio: false,
      investmentByCauses: [],
      activeTab: 'impactFootprint',
      projectUpdates: [],
      galleryIsOpen: false,
      selectedImages: [],
    }
    this.changeInvestmentDataWithYear = this.changeInvestmentDataWithYear.bind(this);
    this.modifyInvestmentByCausesData = this.modifyInvestmentByCausesData.bind(this);
  }

  getCurrentFiscalYear() {
    var today = new Date();
    var curMonth = today.getMonth();
    var fiscalYr = "";
    if (curMonth > 3) {
      return today.getFullYear().toString() + '-' + (today.getFullYear() + 1).toString();
    } else {
      return (today.getFullYear() - 1).toString() + '-' + today.getFullYear().toString();
    }
  }

  componentWillMount() {
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAwG-tGR0wmlo3qyG6IpRO9XO3sCaAEBCs&libraries=places';

    document.getElementsByTagName('head')[0].appendChild(script);
  }

  componentDidMount() {
    let companyId = JSON.parse(readCookie('userData')).organisationData.id;
    fetch(process.env.REACT_APP_API_URL + '/company/' + companyId + '/mis/investment-footprints', {
      method: "GET",
      headers: {
        'Auth': JSON.parse(readCookie('access_token')).access_token
      }
    }).then((data) => data.json())
    .then(function (data) {
      var markers = [];
      for (var i = 0; i < data.data.length; i++) {
        var marker = {};
        var latlng = {};
        latlng['lat'] = parseFloat(data.data[i].latitude);
        latlng['lng'] = parseFloat(data.data[i].longitude);
        marker['address'] = data.data[i].address;
        marker['latlng'] = latlng;
        marker['showInfo'] = false;
        marker['icon'] = '/img/marker.png';
        marker['project'] = data.data[i].project;
        markers.push(marker);
      }
      this.setState({ markers });
    }.bind(this));

    fetch(process.env.REACT_APP_API_URL + '/company/' + companyId + '/mis/dashboard', {
      method: "GET",
      headers: {
        'Auth': JSON.parse(readCookie('access_token')).access_token
      }
    }).then((data) => data.json())
    .then(function (data) {
      let csrInvestmentsData = {};
      csrInvestmentsData.totalAllocated = data.data.allocatedAmount;
      csrInvestmentsData.totalInvested = data.data.investmentAmount;
      csrInvestmentsData.totalUtilized = data.data.utilizedAmount;
      csrInvestmentsData.totalLiveProjects = data.data.partnersCount;
      csrInvestmentsData.totalInvestmentsCount = data.data.investmentCount;

      if (data.data.allocatedAmount !== 0) {
        var investmentByCauses = this.modifyInvestmentByCausesData(data.data.investmentByCauses);

        var amountComparisonData = [];
        let allocated = {
          name: 'Allocated (100%)',
          value: 100,
          fill: '#ef5a20'
        };
        let invested = {
          name: 'Invested (' + ((data.data.investmentAmount / data.data.allocatedAmount) * 100).toFixed(2) + '%)',
          value: ((data.data.investmentAmount / data.data.allocatedAmount) * 100).toFixed(2),
          fill: '#8884d8'
        };
        let utilized = {
          name: 'Utilized (' + ((data.data.utilizedAmount / data.data.allocatedAmount) * 100).toFixed(2) + '%)',
          value: ((data.data.utilizedAmount / data.data.allocatedAmount) * 100).toFixed(2),
          fill: '#82ca9d'
        };

        amountComparisonData.push(allocated);
        amountComparisonData.push(invested);
        amountComparisonData.push(utilized);

        this.setState({ csrInvestmentsData, amountComparisonData, createCSRPortfolio: false, investmentByCauses });
      } else {
        this.setState({ createCSRPortfolio: true });
      }
    }.bind(this));

    fetch(process.env.REACT_APP_API_URL + '/company/' + companyId + '/mis/investment-updates', {
      method: "GET",
      headers: {
        'Auth': JSON.parse(readCookie('access_token')).access_token
      }
    }).then((data) => data.json())
    .then(function (data) {
      if(data.status === 'ok') {
        this.setState({ projectUpdates: data.data });
      }
    }.bind(this));
  }

  changeInvestmentDataWithYear() {
    var year = this.state.fiscalYear.split('-')[0];
    let companyId = JSON.parse(readCookie('userData')).organisationData.id;

    fetch(process.env.REACT_APP_API_URL + '/company/' + companyId + '/mis/investment-footprints/' + year, {
      method: "GET",
      headers: {
        'Auth': JSON.parse(readCookie('access_token')).access_token
      }
    }).then((data) => data.json())
    .then(function (data) {
      var markers = [];
      var x = [];
      for (var i = 0; i < x.length; i++) {
        var marker = {};
        var latlng = {};
        latlng['lat'] = parseFloat(data.data[i].latitude);
        latlng['lng'] = parseFloat(data.data[i].longitude);
        marker['latlng'] = latlng;
        marker['address'] = data.data[i].address;
        marker['showInfo'] = false;
        marker['icon'] = '/img/marker.png';
        marker['project'] = data.data[i].project;
        markers.push(marker);
      }
      this.setState({ markers });
    }.bind(this));

    fetch(process.env.REACT_APP_API_URL + '/company/' + companyId + '/mis/dashboard/' + year, {
      method: "GET",
      headers: {
        'Auth': JSON.parse(readCookie('access_token')).access_token
      }
    }).then((data) => data.json())
    .then(function (data) {
      let csrInvestmentsData = {};
      csrInvestmentsData.totalAllocated = data.data.allocatedAmount;
      csrInvestmentsData.totalInvested = data.data.investmentAmount;
      csrInvestmentsData.totalUtilized = data.data.utilizedAmount;
      csrInvestmentsData.totalLiveProjects = data.data.partnersCount;
      csrInvestmentsData.totalInvestmentsCount = data.data.investmentCount;

      if (data.data.allocatedAmount !== 0) {
        var investmentByCauses = this.modifyInvestmentByCausesData(data.data.investmentByCauses);

        var amountComparisonData = [];
        let allocated = {
          name: 'Allocated (100%)',
          value: 100,
          fill: '#ef5a20'
        };
        let invested = {
          name: 'Invested (' + ((data.data.investmentAmount / data.data.allocatedAmount) * 100).toFixed(2) + '%)',
          value: ((data.data.investmentAmount / data.data.allocatedAmount) * 100).toFixed(2),
          fill: '#8884d8'
        };
        let utilized = {
          name: 'Utilized (' + ((data.data.utilizedAmount / data.data.allocatedAmount) * 100).toFixed(2) + '%)',
          value: ((data.data.utilizedAmount / data.data.allocatedAmount) * 100).toFixed(2),
          fill: '#82ca9d'
        };

        amountComparisonData.push(allocated);
        amountComparisonData.push(invested);
        amountComparisonData.push(utilized);

        this.setState({ csrInvestmentsData, amountComparisonData, createCSRPortfolio: false, investmentByCauses });
      } else {
        this.setState({ createCSRPortfolio: true });
      }
    }.bind(this));
  }

  ddClick() {
    $('#dd').toggleClass('active');
  }

  selectYear(event) {
    var opt = $(event.target).text();
    $('#dd > span').html(opt);
    this.selectFiscalYear(opt);
  }

  selectFiscalYear(year) {
    if (year === 'default') {
      this.setState({ fiscalYear: this.getCurrentFiscalYear() }, function () {
        this.changeInvestmentDataWithYear();
      }.bind(this));
    } else {
      this.setState({ fiscalYear: year }, function () {
        this.changeInvestmentDataWithYear();
      }.bind(this));
    }
  }

  setAnnualBudget() {
    sessionStorage.setItem("selectedFiscalYear", this.state.fiscalYear);
    window.location.href = '/fiscal-year-budgeting';
  }

  modifyInvestmentByCausesData(data) {
    let newData = [];
    for (var i = 0; i < data.length; i++) {
      let x = {};
      x['id'] = data[i].id;
      x['name'] = data[i].name;
      x['Allocated'] = data[i].amount;
      x['Invested'] = data[i].investedAmount;
      x['Utilized'] = data[i].utilizedAmount;
      newData.push(x);
    }
    return newData;
  }

  changeMarkerShowInfo(index) {
    let markers = this.state.markers;
    let newShowInfo = !markers[index]['showInfo'];
    for (var i = 0; i < markers.length; i++) {
      markers[i]['showInfo'] = false;
    }
    markers[index]['showInfo'] = newShowInfo;
    this.setState({ markers });
  }

  viewImages(update) {
    var images = update.images;
    this.setState({ selectedImages: images, galleryIsOpen: true });
  }

  onCloseImageModal() {
    this.setState({ selectedImages: [], galleryIsOpen: false });
  }

  render() {
    if (this.state.csrInvestmentsData !== null) {
      return (
        <div className="mis-content-container mis-dashboard-csr">
          <div className="fiscal-year-selector fixed">
            <span>
              A snapshot of our community investment in Fiscal Year:
              <div id="dd" className="wrapper-dropdown-3" tabIndex="1" onClick={this.ddClick.bind(this)}>
                <span>{this.getCurrentFiscalYear()}</span>
                <ul className="dropdown">
                  {years.map(function (year, index) {
                    return (
                      <li key={index} onClick={this.selectYear.bind(this)}><a href="#">{year}</a></li>
                    )
                  }.bind(this))}
                </ul>
              </div>
            </span>
          </div>
          {this.state.createCSRPortfolio ? (null) : (
            <Menu selectedKeys={[this.state.activeTab]} mode="horizontal" className="dashboard-tabs">
              <Menu.Item key="overview">
                <Link to="/dashboard" spy={true} smooth={true} offset={-100} duration={500}>Overview</Link>
              </Menu.Item>
              <Menu.Item key="impactFootprint">
                <span spy={true} smooth={true} offset={-50} duration={800}>Impact Footprint</span>
              </Menu.Item>
            </Menu>
          )}
          {this.state.createCSRPortfolio ? (
            <div className="create-annual-budget-container">
              <div className="create-annual-budget-button" onClick={this.setAnnualBudget.bind(this)}>Create Your Annual Budget for {this.state.fiscalYear}</div>
            </div>
          ) : (
            <div className="row">
              <div className="very-big">
                <div className="content map">
                  <h4 className="mb10 impact-footprint">Impact Footprint</h4>
                  {this.state.markers !== null ? (
                    <DashboardMap markers={this.state.markers} changeMarkerShowInfo={this.changeMarkerShowInfo.bind(this)} />
                  ) : (
                    'Loading Map...'
                  )}
                </div>
              </div>
              {this.state.projectUpdates.length ? (
                <div className="very-big project-updates">
                  <div className="content map">
                    <h4 className="mb10">Project Updates</h4>
                    <div className="updates-container">
                      {this.state.projectUpdates.map(function(update, index) {
                        return (
                          <div className="update-container" key={index}>
                            <div className="update-container-top">
                              <div className="info">
                                <div className="title">
                                  {update.hasOwnProperty('projectCustomUrl') ? (
                                    <a className="" href={"/mis-projects/" + update.projectCustomUrl} target="_self">{update.projectTitle}</a>
                                  ) : (
                                    <span>{update.projectTitle}</span>
                                  )}
                                </div>
                                <div className="description">{update.description}</div>
                                {update.images && update.images.length ? (
                                  <button className="update-images" onClick={this.viewImages.bind(this, update)}>View Images</button>
                                ) : (null)}
                              </div>
                              <div className="image">
                                <img src={update.projectImage || 'https://assets.letsendorse.com/sahaj/project-placeholder.jpg'} />
                              </div>
                            </div>
                            <div className="update-container-bottom">
                              <div className="user">{update.updatedBy + ' - '}</div>
                              <div className="date">{' ' + moment(update.createdOn).format('DD MMM YYYY')}</div>
                            </div>
                          </div>
                        )
                      }.bind(this))}
                    </div>
                  </div>
                </div>
              ) : (null)}
            </div>
          )}

          {this.state.galleryIsOpen && this.state.selectedImages.length ? (
            <Modal show={this.state.galleryIsOpen} onHide={this.onCloseImageModal.bind(this)}>
              <ImageModal imgArray={this.state.selectedImages} hasDate={false}
                openModal={this.state.galleryIsOpen} imageIndex={0} />
            </Modal>
          ) : (null)}
        </div>
      );
    } else {
      return (
        <div className="mis-content-container mis-dashboard-csr">
          <div className="fiscal-year-selector fixed">
            <span>
              A snapshot of our community investment in Fiscal Year:
              <div id="dd" className="wrapper-dropdown-3" tabIndex="1" onClick={this.ddClick.bind(this)}>
                <span>{this.getCurrentFiscalYear()}</span>
                <ul className="dropdown">
                  {years.map(function (year, index) {
                    return (
                      <li key={index} onClick={this.selectYear.bind(this)}><a href="#">{year}</a></li>
                    )
                  }.bind(this))}
                </ul>
              </div>
            </span>
          </div>
          {this.state.createCSRPortfolio ? (null) : (
            <Menu selectedKeys={[this.state.activeTab]} mode="horizontal" className="dashboard-tabs">
              <Menu.Item key="overview">
                <Link to="/dashboard" spy={true} smooth={true} offset={-100} duration={500}>Overview</Link>
              </Menu.Item>
              <Menu.Item key="impactFootprint">
                <span spy={true} smooth={true} offset={-50} duration={800}>Impact Footprint</span>
              </Menu.Item>
            </Menu>
          )}
          {this.state.createCSRPortfolio ? (
            <div className="create-annual-budget-container">
              <div className="create-annual-budget-button" onClick={this.setAnnualBudget.bind(this)}>Create Your Annual Budget for {this.state.fiscalYear}</div>
            </div>
          ) : (
            <div className="row">
              <div className="very-big">
                <div className="content map">
                  <h4 className="mb10 impact-footprint">Impact Footprint</h4>
                  {this.state.markers !== null ? (
                    <DashboardMap markers={this.state.markers} changeMarkerShowInfo={this.changeMarkerShowInfo.bind(this)} />
                  ) : (
                    'Loading Map...'
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      )
    }
  }
}