import React, { Component } from "react";
import { Checkbox } from "antd";
import { Button, Modal, Row, Col } from "react-bootstrap";

export default class NotificationModal extends Component {

  render() {
    return (
      <div>
        <Modal id="show-notification-modal"
          show={this.props.ShownotificationModal}
          onHide={this.props.onShownotification}
          bsSize="large">
       		 <Modal.Body className="show-notification-modal-body">
            <Row style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
            {this.props.years ? (
              <div style={{display: "flex", alignItems: "center", marginBottom: "10px"}}>
                <h4 style={{margin: "0px 10px 0px 0px"}}>Select Year Of Investment</h4>
                {this.props.QueryYear !== null ? (
                  /*<select id="year" value={this.props.QueryYear} style={{marginBottom:"10px"}}>*/
                    <select id="year" value={this.props.QueryYear}>
                      {this.props.years.map(function (year, index) {
                        return (
                          <option key={index} value={year}>{year}</option>
                        )
                      })}
                    </select>
                ) : (
                  /*<select id="year" value={this.props.csrBudgetYear} style={{marginBottom:"10px"}}>*/
                  <select id="year" value={this.props.csrBudgetYear} style={{marginBottom:"10px"}}>
                    {this.props.years.map(function (year, index) {
                      return (
                        <option key={index} value={year}>{year}</option>
                      )
                    })}
                  </select>
                )}
              </div>
             ) : (null)}
              <Col className="show-notification-modal-para" md={12}>
              	<div className="modal-dot"></div>
                <p>{this.props.notificationContent.text}</p>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn le-button-light agree"
              onClick={this.props.handleConfirm.bind(this, 'Confirm', this.props.notificationContent.status)}>
              Confirm
            </Button>
            <Button className="btn le-button-light"
              onClick={this.props.onClosenotification}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
