import React, { Component } from "react";
import SelectTheCause from "./strategies/SelectTheCause";
import YourInitiatives from "./strategies/YourInitiatives";
import InvestmentPreferences from "./strategies/InvestmentPreferences";
import PartnerPreferences from "./strategies/PartnerPreferences";
import AboutYourOrganisation from "./strategies/AboutYourOrganisation";
import CsrHistory from "./strategies/CsrHistory";

class Strategy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      selectedStepData: null,
      show: true,
      currentStep: 0,
      completed: false,
      strategyData: [
        {
          title: "SELECT THE CAUSES",
          done: false
        }, {
          title: "YOUR INITIATIVES",
          done: false
        }, {
          title: "INVESTMENT PREFERENCES",
          done: false
        }, {
          title: "PARTNER PREFERENCES",
          done: false
        }, {
          title: "ABOUT YOUR ORGNIZATION",
          done: false
        }, {
          title: "CSR HISTORY",
          done: false
        }
      ],
      completedSteps: []
    };
  }

  componentDidMount() {
    let lastStep = 0;
    if (this.state.completedSteps.length)
      lastStep = this.state.completedSteps[this.state.completedSteps.length - 1];
    this.setState({
      data: this.state.strategyData,
      selectedStepData: this.state.strategyData[lastStep],
      currentStep: lastStep
    });
  }

  changeStep = index => {
    let selectedStepData = this.state.strategyData[index];
    this.setState({ selectedStepData, currentStep: index });
  };

  nextStep = () => {
    let currentStep = this.state.currentStep;
    currentStep += 1;
    let selectedStepData = this.state.data[currentStep];
    this.setState({ selectedStepData, currentStep });
  };

  prevStep = () => {
    let currentStep = this.state.currentStep;
    currentStep -= 1;
    let selectedStepData = this.state.data[currentStep];
    this.setState({ selectedStepData, currentStep });
  };

  render() {
    if (this.state.data !== null && this.state.selectedStepData !== null) {
      return (
        <div className="mis-content-container-strategy">
          <div className="icon-anime">
            {this.state.data.map(
              function (data, index) {
                return (
                  <div className="icon-container">
                    <div
                      className={data.done ? "numbers done" : "numbers"}
                      onClick={this.changeStep.bind(this, index)}
                    >
                      {data.done ? (
                        <span>
                          <i
                            className="fa fa-check"
                            style={{ color: "#fff" }}
                          />
                        </span>
                      ) : (
                          <span className="numberValue">{index + 1}</span>
                        )}
                    </div>
                    {index < this.state.data.length - 1 ? (
                      <div
                        className={
                          this.state.currentStep > index
                            ? "progress-bars done"
                            : "progress-bars"
                        }
                      />
                    ) : null}
                  </div>
                );
              }.bind(this)
            )}
          </div>
          <div style={{ width: '80%', display: 'flex', justifyContent: 'center' }}>
            {this.state.currentStep == 0 ? (
              <SelectTheCause
                changeStep={this.changeStep}
                next={this.nextStep}
                previous={this.prevStep}
              />
            ) : null}
            {this.state.currentStep == 1 ? (
              <YourInitiatives
                changeStep={this.changeStep}
                next={this.nextStep}
                previous={this.prevStep}
              />
            ) : null}
            {this.state.currentStep == 2 ? (
              <InvestmentPreferences
                changeStep={this.changeStep}
                next={this.nextStep}
                previous={this.prevStep}
              />
            ) : null}
            {this.state.currentStep == 3 ? (
              <PartnerPreferences
                changeStep={this.changeStep}
                next={this.nextStep}
                previous={this.prevStep}
              />
            ) : null}
            {this.state.currentStep == 4 ? (
              <AboutYourOrganisation
                changeStep={this.changeStep}
                next={this.nextStep}
                previous={this.prevStep}
              />
            ) : null}
            {this.state.currentStep == 5 ? (
              <CsrHistory
                changeStep={this.changeStep}
                next={this.nextStep}
                previous={this.prevStep}
              />
            ) : null}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default Strategy;
