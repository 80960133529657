import React, {Component} from 'react';
import {Col} from 'react-bootstrap';
import InnovationModal from'./InnovationModal';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import ReactGA from 'react-ga';

const readCookie = require('../../../../cookie.js').readCookie;

const MyMapComponent = withGoogleMap((props) =>
	<GoogleMap defaultZoom={6}
		defaultCenter={{ lat: 12.972442, lng: 77.580643 }} >
		{props.deployedLocation.map(function(location, index) {
			return (
				<Marker position={{ lat: parseFloat(location.latitude), lng: parseFloat(location.longitude) }} options={{ icon: { url: 'http://icons.iconarchive.com/icons/icons-land/vista-map-markers/256/Map-Marker-Marker-Outside-Azure-icon.png', scaledSize: { width: 32, height: 32 } } }} />
			)
		})}
		{props.developedLocation.map(function(location, index) {
			return (
				<Marker position={{ lat: parseFloat(location.latitude), lng: parseFloat(location.longitude) }} options={{ icon: { url: 'https://cdn1.iconfinder.com/data/icons/Map-Markers-Icons-Demo-PNG/256/Map-Marker-Marker-Outside-Pink.png', scaledSize: { width: 32, height: 32 } } }} />
			)
		})}
	</GoogleMap>
)

export default class Innovation extends Component {
	constructor(props){
		super(props);
		this.state = {
			innovation: null,
			ngolist: [],
			show: false,
		}
		this.handleShow = this.handleShow.bind(this);
		this.handleClose = this.handleClose.bind(this);
	}

	componentWillMount() {
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAwG-tGR0wmlo3qyG6IpRO9XO3sCaAEBCs&libraries=places';

    document.getElementsByTagName('head')[0].appendChild(script);
  }

	componentDidMount() {
		fetch(process.env.REACT_APP_API_URL + '/innovation/' + this.props.match.params.innovationId, {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
		.then(function(data) {
			let innovation = data.data;
			let innovationId = innovation.id;
			fetch(process.env.REACT_APP_API_URL + '/innovation/' + innovationId +'/interested/ngo', {
				method: "GET",
				headers: {
					'Auth': JSON.parse(readCookie('access_token')).access_token
				}
			}).then((data) => data.json())
			.then(function(data) {
				let ngolist = data.data;
				this.setState({ ngolist });
			}.bind(this));

			this.setState({ innovation });
		}.bind(this));
	}

	handleClose() {
		this.setState({ show: false });
	}

	handleShow() {
		ReactGA.event({
		  category: 'Innovation',
		  action: 'click',
		  label: 'Interested'
		});
		this.setState({ show: true });
	}

	render() {
		return (
			<div className="mis-content-container">
				{this.state.innovation !== null ? (
					<Col md={12} className="innovation-main-container">
						<a className="back-arrow" href="/discover/innovative-models" target="_self">
							<i className="fa fa-angle-left"></i>
							<span>Back</span>
						</a>
						<Col md={12} className="innovation-headerandlist">
						{this.state.ngolist.length ? (
							<div className="innovation-intro-section">
								<Col md={8} className="innovation-intro" style={{ backgroundImage: 'url("https://assets.letsendorse.com/' + this.state.innovation.image + '")' }}>
									<div className="innovation-intro-title-container">
										<div className="innovation-goal-intro">
											<div style={{ backgroundColor: this.state.innovation.goalBackgroundColor, padding: 4, borderRadius: '50%' }}>
												<img className="innovation-goal-logo" src={'https://assets.letsendorse.com/' + this.state.innovation.goalIcon} />
											</div>
											<div className="innovation-goal-title">{this.state.innovation.goalTitle}</div>
										</div>
										<div className="innovation-title"><h3>{this.state.innovation.title}</h3></div>
									</div>
								</Col>
								<Col md={4} className="innovation-ngolist">
									<div className="innovation-ngolist-title"><h5>NGO's interested in the INNOVATION</h5></div>
									<div className="innovation-ngolist-container">
									{this.state.ngolist.map((ngoData, ngoIndex)=>{
										return(
											<div className="innovation-ngolist-list" key={ngoIndex}>
												<div className="innovation-ngo-logo"></div>
												<div className="innovation-ngo-namelocation">
													<span>{ngoData.name}</span>
													<div className="innovation-ngo-location">
														<i className="fa fa-map-marker"></i><span>{ngoData.location}</span>
													</div>
												</div>
											</div>
										)
									})}
									</div>
								</Col>
							</div>
							) : (
								<div className="innovation-intro-section">
								<Col md={12} className="innovation-intro" style={{ backgroundImage: 'url("https://assets.letsendorse.com/' + this.state.innovation.image + '")' }}>
									<div className="innovation-intro-title-container">
										<div className="innovation-goal-intro">
											<div style={{ backgroundColor: this.state.innovation.goalBackgroundColor, padding: 4, borderRadius: '50%' }}>
												<img className="innovation-goal-logo" src={'https://assets.letsendorse.com/' + this.state.innovation.goalIcon} />
											</div>
											<div className="innovation-goal-title">{this.state.innovation.goalTitle}</div>
										</div>
										<div className="innovation-title"><h3>{this.state.innovation.title}</h3></div>
									</div>
								</Col>
							</div>
							)}
						</Col>
						<Col md={12} className="innovation-body">
							<Col md={8} className="innovation-topic-para-container">
								{this.state.innovation.description !== '' ? (
									<Col md={12} className="innovation-about-short">
										<p>{this.state.innovation.description}</p>
									</Col>
								) : (null)}
								{this.state.innovation.longdescription !== '' ? (
									<Col md={12} className="innovation-about-long">
										<p>{this.state.innovation.longdescription}</p>
									</Col>
								) : (null)}
								<Col md={12} className="innovation-impact-section">
									{this.state.innovation.environmentImpact !== '' ? (
										<Col md={12} className="innovation-envimpact">
											<div className="innovation-envheader">
												<span className="icon">
													<img src="/img/innovation-section-heading-icon.png"/>
												</span>
												<div className="innovation-envheader-title">ENVIRONMENTAL IMPACT</div>
											</div>
											<div className="innovation-envcontent">
												<p>{this.state.innovation.environmentImpact}</p>
											</div>
										</Col>
									) : (null)}
									{this.state.innovation.socialImpact !== '' ? (
										<Col md={12} className="innovation-socialimpact">
											<div className="innovation-socialheader">
												<span className="icon">
													<img src="/img/innovation-section-heading-icon2.png"/>
												</span>
												<div className="innovation-socialheader-title">SOCIAL IMPACT</div>
											</div>
											<div className="innovation-socialcontent">
												<p>{this.state.innovation.socialImpact}</p>
											</div>
										</Col>
									) : (null)}
									{this.state.innovation.economicImpact !== '' ? (
										<Col md={12} className="innovation-ecoimpact">
											<div className="innovation-ecoheader">
												<span className="icon">
													<img src="/img/innovation-section-heading-icon2.png"/>
												</span>
												<div className="innovation-ecoheader-title">ECONOMIC IMPACT</div>
											</div>
											<div className="innovation-socialcontent">
												<p>{this.state.innovation.economicImpact}</p>
											</div>
										</Col>
									) : (null)}
								</Col>
							</Col>
							<Col md={4} className="innovation-side-content-container">
								<InnovationModal show={this.state.show} innovationId={this.state.innovation.id} handleClose={this.handleClose} />
								<Col md={12} className="innovation-interested-btn-section">
									<button onClick={this.handleShow}>Interested in the innovation</button>
									<div className="innovation-interested-para">You shall get a call back and the best possible recommendation about the solution</div>
								</Col>
								{this.state.innovation.representative ? (
									<Col md={12} className="innovation-founder-container">
									{this.state.innovation.representative.profilePicture ? (
										<div className="founder-prof-pic">
											<img className="founder-prof-img" src={this.state.innovation.representative.profilePicture} />
										</div>
									) : (
											<div className="founder-prof-pic">
												<img className="founder-prof-img" src="http://bpic.588ku.com/element_origin_min_pic/17/09/19/28e7312d32356cabb08cb36d5d232492.jpg" />
											</div>
											)}
										<div className="founder-details">
											<div className="founder-name">{this.state.innovation.representative.firstName +` `+ this.state.innovation.representative.lastName}</div>
											<div className="founder-designition">{this.state.innovation.representative.designation}</div>
										</div>
									</Col>
								) : (null)}
								<Col md={12} className="innovation-location">
									<MyMapComponent isMarkerShown
										loadingElement={<div style={{ height: `100%` }} />}
										containerElement={<div style={{ height: `300px` }} />}
										mapElement={<div style={{ height: `100%` }} />}
										developedLocation={this.state.innovation.developedLocation}
										deployedLocation={this.state.innovation.deployedLocation}
									/>
									<div className="map-legend-container">
										<h4>Legend</h4>
										<div className="legends">
											<div className="legend-container">
												<img src="https://cdn1.iconfinder.com/data/icons/Map-Markers-Icons-Demo-PNG/256/Map-Marker-Marker-Outside-Pink.png" width="20" height="20" />
												<span>Developed Location</span>
											</div>
											<div className="legend-container">
												<img src="http://icons.iconarchive.com/icons/icons-land/vista-map-markers/256/Map-Marker-Marker-Outside-Azure-icon.png" width="20" height="20" />
												<span>Deployed Location</span>
											</div>
										</div>
									</div>
								</Col>
								{this.state.innovation.tags.length ? (
									<Col md={12} className="innovation-tags">
										<div className="innovation-tag-heading">TAGS</div>
										<div className="innovation-tag-container">
										{this.state.innovation.tags.map((tag, index)=>{
											return (
												<div className="innovation-tag-body" key={index}>
													<span className="innovation-tag-name">{tag.name}</span>
												</div>
											)
										})}
										</div>
									</Col>
								) : (null)}
							</Col>
						</Col>
					</Col>
				) : (null)}
			</div>
		)
	}
}