import React, {Component} from 'react';
import {Row, Col, Modal} from 'react-bootstrap';

const readCookie = require('../../../../cookie.js').readCookie;

export default class BudgetEditorModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedColumnValue: this.props.selectedColumnValue
		}
	}

	render() {
		return (
			<Modal className="budget-editor-modal" show={this.props.budgetEditorModalVisibility}
				onHide={this.props.hideBudgetEditorModal.bind(this)} bsSize="large">
				<Modal.Header>
					<Modal.Title className="modal-header-custom">
						{this.props.selectedColumnName}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row>
						<label className="control-label">{this.props.selectedColumnName + ':'}</label>
						{(() => {
							if(this.props.selectedColumn === 'costHead') {
								return (
									<input className="form-control" value={this.state.selectedColumnValue} disabled />
								)
							} else if(this.props.selectedColumn === 'expenseItem') {
								return (
									<input className="form-control" type="text" value={this.state.selectedColumnValue} disabled />
								)
							} else if(this.props.selectedColumn === 'comments') {
								return (
									<textarea className="form-control" value={this.state.selectedColumnValue} disabled></textarea>
								)
							}
						})()}

						<Col md={12} className="budget-editor-buttons-container mt20 mb10">
							<button className="btn budget-editor-cancel-button" onClick={this.props.hideBudgetEditorModal}>Cancel</button>
						</Col>
					</Row>
				</Modal.Body>
			</Modal>
		)
	}
}