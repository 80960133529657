export const updateDashboardCount = (ngosCount, companiesCount, projectsCount, volunteerOpportunitiesCount) => {
	return {
		ngosCount,
		companiesCount,
		projectsCount,
		volunteerOpportunitiesCount,
		type: 'UPDATE_DASHBOARD_COUNT'
	}
};

export const updateFeatured = (featured) => {
	return {
		featured,
		type: 'UPDATE_FEATURED'
	}
};