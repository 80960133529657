import React, { Component } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from "recharts";
import { BootstrapTable, TableHeaderColumn, ButtonGroup, ExportCSVButton } from 'react-bootstrap-table';
import moment from 'moment';
import _ from 'lodash';

export default class GraphModal extends Component {

    constructor(props){
        super(props);
        this.state = {
            dataArray: [],
            kpi_name: null,

        }
    }

    componentDidMount(){
        let arr = [];
        if(this.props.dataArray.graph_data.length>0 || this.props.dataArray.graph_data.length !==  undefined){
            _.map(this.props.dataArray.graph_data, (el, i) => {
                arr.push({count: Number(el.value[0].unit), date: el.date})
            })
            this.setState({dataArray: arr, kpi_name: this.props.dataArray.graph_data[0].value[0].kpi_name});
        }

    }

    render(){
        return(
            <div style={{display: 'inline !important', height: '500px'}} >
                {
                    (this.state.dataArray.length>0)
                        ?
                    <div style={{display: 'inline !important', height: '500px'}}>

                        <ResponsiveContainer style={{display: 'inline !important', height:'500px', width:'500px'}}>

                            <LineChart width={900} data={this.state.dataArray}
                                margin={{ top: 5, right: 30, left: 30, bottom: 5 }}>
                                <XAxis dataKey="date" label="date" />
                                <YAxis label="Count" dataKey="count" />
                                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="count"
                                stroke="#82ca9d" strokeWidth={3} />
                                {/* <Line type="monotone" dataKey="date" stroke="#82ca9d" strokeWidth={3} /> */}
                            </LineChart>

                        </ResponsiveContainer>
                    </div>
                        :
                    <div>No data to display</div>
                }

           </div>
            )

    }
}