import React, {Component} from 'react'
import {Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';

export default class LandingSingleGoal extends Component {
	render() {
		return (
			<Col md={3}>
				<Link to={'/csr/csr-projects/' + this.props.featured.url}>
					{this.props.featured.image ? (
						<div className="landing-goal-wrapper" style={{backgroundImage: `url(${this.props.featured.image})` }}>
							<span>{this.props.featured.title}</span>
						</div>
					) : (
						<div className="landing-goal-wrapper" style={{backgroundImage: `url('https://assets.letsendorse.com/sahaj/project-placeholder.jpg')` }}>
							<span>{this.props.featured.title}</span>
						</div>
					)
					}
				</Link>
			</Col>
		);
	}
}