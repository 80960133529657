import React,{ Component } from 'react';
import {OverlayTrigger, Tooltip, Col, Row} from 'react-bootstrap';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import moment from 'moment';
import { LocaleProvider } from 'antd';
import enUS from 'antd/lib/locale-provider/en_US';
import $ from 'jquery';
import {BootstrapTable, TableHeaderColumn, ButtonGroup} from 'react-bootstrap-table';
import Multiselect from 'react-bootstrap-multiselect';
import Icon from 'react-icons-kit';
import { ic_done } from 'react-icons-kit/md/ic_done';
import { DatePicker, Select } from 'antd';

import AmountBreakupTable from './AmountBreakupTable';

const Option = Select.Option;

const readCookie = require('../../../cookie.js').readCookie;

export default class SinglePortfolioGoalForm extends Component {
	constructor(props) {
    super(props);
    this.state = {
    	costHeads: []
  	}
  }

	componentDidMount() {
		fetch(process.env.REACT_APP_API_URL + '/cost-attributes', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
		.then(function(data) {
			let costHeads = [];
			for(var i = 0; i < data.data.length; i++) {
				for(var j = 0; j < data.data[i].costHeads.length; j++) {
					let costHead = {};
					costHead.value = data.data[i].costHeads[j].key;
					costHead.label = data.data[i].costHeads[j].name;
					costHeads.push(costHead);
				}
			}
			this.setState({ costHeads });
		}.bind(this));
	}

  showButtonText(options, select) {
	  if (options.length === 0) {
      return 'None Selected';
	  } else if (options.length > 1) {
      return options.length + ' options selected!';
	  } else {
    	let labels = [];
      options.each(function() {
	     	if ($(this).attr('label') !== undefined) {
	      	labels.push($(this).attr('label'));
	     	} else {
	      	labels.push($(this).html());
	    	}
 			});
			return labels.join(', ') + '';
    }
  }

  onPreferenceLocationChange(preferenceLocation) {
  	this.props.changeLocation(preferenceLocation);
  	geocodeByAddress(preferenceLocation)
    .then(results => getLatLng(results[0]))
    .then(function(latLng) {
    	this.props.changeLatLng(latLng);
    }.bind(this)).catch(error => console.error('Error', error));
  }

  render() {
		return (
			<Col md={12} className={this.props.showForm ? "add-single-portfolio-project-form formShowed mb20" : "add-single-portfolio-project-form mb20"}>
				<Col md={6} className="add-single-portfolio-project-form-item mt10 mb10">
					<label className="col-md-12 is-imp">Select the Project / Add new Project:</label>
					<Col md={12}>
						<LocaleProvider locale={enUS}>
							<Select id="project-selector" value={this.props.selectedProject} size="large" placeholder="None Selected" mode="tags" onChange={this.props.changeSelectedProject}>
								{this.props.allProjects.map(function(project, index) {
									return <Option key={index} value={project.id}>{project.title}</Option>
								})}
							</Select>
						</LocaleProvider>
					</Col>
				</Col>
				<Col md={6} className="add-single-portfolio-project-form-item mt10 mb10">
					<label className="col-md-12 is-imp">Select the NGO deploying the funds:</label>
					<Col md={12}>
						<LocaleProvider locale={enUS}>
							<Select id="ngo-selector" value={this.props.selectedNgo} size="large" placeholder="None Selected" mode="tags" onChange={this.props.changeSelectedNgo}>
								{this.props.allNGOs.map(function(ngo, index) {
									return (
										<Option key={index} value={ngo.id} title={ngo.name}>
											<span className="ngo-selector-option-container">
												<span className="ngo-selector-option-name">{ngo.name}</span>
												<span className="ngo-selector-option-sections">
													{' ( '}
													{ngo.csrHistory ? ('Has done CSR') : ('')}
													{ngo.section.map(function(sec, index) {
														if(index === 0) {
															if(ngo.csrHistory) return <span>{' / ' + sec.name}</span>
															else return <span>{sec.name}</span>
														} else {
															return <span>{' / ' + sec.name}</span>
														}
													})}
													{' )'}
												</span>
											</span>
										</Option>
									)
								})}
							</Select>
						</LocaleProvider>
					</Col>
				</Col>
				<Col md={6} className="add-single-portfolio-project-form-item mt10 mb10">
					<label className="col-md-12 is-imp">Select Agenda:</label>
					<Col md={12}>
						<Select id="agenda-selector" value={this.props.selectedAgenda.id} size="large" onChange={this.props.changeSelectedAgenda}>
							<Option disabled value="">None Selected</Option>
							{this.props.singlePortfolioAgendas.map(function(agenda, index) {
								return <Option key={index} value={agenda.id}>{agenda.name}</Option>
							})}
						</Select>
					</Col>
				</Col>
				<div className="break"></div>
				{(() => {
					if(this.props.selectedProject.length === 1) {
						let foundProject = false;
						let foundProjectIndex = -1;
						for(var i = 0; i < this.props.allProjects.length; i++) {
							if(this.props.allProjects[i].id === this.props.selectedProject[0]) {
								foundProject = true;
								foundProjectIndex = i;
								break;
							}
						}
						if(foundProject) {
							return <Col md={12} className="add-single-portfolio-project-form-item mt10 mb10">
								<label className="col-md-12">Project Description:</label>
								<Col md={12}>
									<textarea id="project-description-read" readOnly value="Found Project Description"></textarea>
								</Col>
							</Col>
						} else {
							return <Col md={12} className="add-single-portfolio-project-form-item mt10 mb10">
								<label className="col-md-12 is-imp">Project Description:</label>
								<Col md={12}>
									<textarea id="project-description" defaultValue="" onChange={this.props.changeSelectedProjectDescription}></textarea>
								</Col>
							</Col>
						}
					}
				})()}

				{(() => {
					if(this.props.selectedProject.length === 1) {
						let foundProject = false;
						let foundProjectIndex = -1;
						for(var i = 0; i < this.props.allProjects.length; i++) {
							if(this.props.allProjects[i].id === this.props.selectedProject[0]) {
								foundProject = true;
								foundProjectIndex = i;
								break;
							}
						}

						if(!foundProject || !this.props.allProjects[foundProjectIndex].unitEconomics) {
							return <Col md={4} className="add-single-portfolio-project-form-item mt10 mb10">
								<label className="col-md-12 is-imp">Enter allocated amount:</label>
								<Col md={12}>
									<span className="allocated-amount-span">
										<input type="number" className="form-control" defaultValue={this.props.allocatedAmount} onChange={this.props.updateAllocatedAmount} />
										<Icon icon={ic_done} onClick={this.props.showAmountBreakupTable} title="Click to Show " />
									</span>
								</Col>
							</Col>
						} else {
							return <Col md={4} className="add-single-portfolio-project-form-item mt10 mb10">
								<label className="col-md-12 is-imp">
									{'How many ' + this.props.allProjects[foundProjectIndex].unitName + ' would you like to fund?'}
									{this.props.allProjects[foundProjectIndex].hasOwnProperty('maxUnits') ? (
										' (Max Available: ' + this.props.allProjects[foundProjectIndex].maxUnits + ' ' + this.props.allProjects[foundProjectIndex].unitName + ')'
									) : (null)}
								</label>
								<Col md={12}>
									<span className="allocated-amount-span">
										<input type="number" className="form-control" defaultValue={this.props.allocatedUnits} onChange={this.props.updateAllocatedUnits} />
									</span>
								</Col>
							</Col>
						}
					} else {
						return <Col md={4} className="add-single-portfolio-project-form-item mt10 mb10">
							<label className="col-md-12 is-imp">Enter allocated amount:</label>
							<Col md={12}>
								<span className="allocated-amount-span">
									<input type="number" className="form-control" defaultValue={this.props.allocatedAmount} onChange={this.props.updateAllocatedAmount} />
									<Icon icon={ic_done} onClick={this.props.showAmountBreakupTable} title="Click to Show " />
								</span>
							</Col>
						</Col>
					}
				})()}

				{(() => {
					if(this.props.selectedProject.length === 1) {
						let foundProject = false;
						let foundProjectIndex = -1;
						for(var i = 0; i < this.props.allProjects.length; i++) {
							if(this.props.allProjects[i].id === this.props.selectedProject[0]) {
								foundProject = true;
								foundProjectIndex = i;
								break;
							}
						}
						if(foundProject) {
							return <Col md={4} className="add-single-portfolio-project-form-item mt10 mb10">
								<label className="col-md-12 is-imp">Select Location(s) of deployment:</label>
								<Col md={12}>
									<Multiselect id="singlePortfolioGoal" buttonClass="singlePortfolioGoal btn btn-default"
				        		onChange={this.props.handleSelectChange.bind(this, 'selectedLocations')}
				        		buttonText={this.showButtonText} multiple
				        		buttonWidth={'100%'} data={this.props.multiselectData} />
								</Col>
							</Col>
						} else {
							let inputProps = {
					      value: this.props.hasProjectLocation.preferenceLocation,
					      onChange: this.onPreferenceLocationChange.bind(this),
					    };

					    const options = {
					      types: ['(regions)'],
					    };

					    let cssClasses = {
					      input: 'location form-control ' + this.props.hasProjectLocation.preferenceLocationError,
					      autocompleteContainer: 'my-autocomplete-container'
					    };

							return <Col md={4} className="add-single-portfolio-project-form-item mt10 mb10">
								<label className="col-md-12 is-imp">Select Location(s) of deployment:</label>
								<Col md={12}>
									<PlacesAutocomplete inputProps={inputProps} classNames={cssClasses} options={options} />
								</Col>
							</Col>
						}
					}
				})()}

				{(() => {
					if(this.props.selectedProject.length === 1) {
						let foundProject = false;
						let foundProjectIndex = -1;
						for(var i = 0; i < this.props.allProjects.length; i++) {
							if(this.props.allProjects[i].id === this.props.selectedProject[0]) {
								foundProject = true;
								foundProjectIndex = i;
								break;
							}
						}
						if(!foundProject) {
							return <Col md={4} className="add-single-portfolio-project-form-item mt10 mb10">
								<label className="col-md-12 is-imp">Beneficiary Types:</label>
								<Col md={12}>
									<Multiselect id="singlePortfolioGoal" buttonClass="singlePortfolioGoal btn btn-default"
				        		onChange={this.props.handleSelectChange.bind(this, 'selectedBeneficiaryTypes')}
				        		buttonText={this.showButtonText} multiple
				        		buttonWidth={'100%'} data={this.props.otAttributes.beneficiaryTypes} />
								</Col>
							</Col>
						}
					}
				})()}

				{(() => {
					if(this.props.selectedProject.length === 1) {
						let foundProject = false;
						let foundProjectIndex = -1;
						for(var i = 0; i < this.props.allProjects.length; i++) {
							if(this.props.allProjects[i].id === this.props.selectedProject[0]) {
								foundProject = true;
								foundProjectIndex = i;
								break;
							}
						}
						if(!foundProject) {
							return <Col md={4} className="add-single-portfolio-project-form-item mt10 mb10">
								<label className="col-md-12">Duration of Project:</label>
								<Col md={12} className="project-duration-selector">
									<input type="number" className="form-control" defaultValue={this.props.selectedProjectDuration.duration} onChange={this.props.changeProjectDuration} />
									<Select id="project-duration-type-selector" defaultValue={this.props.selectedProjectDuration.type} size="large" onChange={this.props.changeProjectDurationType}>
										<Option disabled value="">None Selected</Option>
										<Option value="days">Days</Option>
										<Option value="weeks">Weeks</Option>
										<Option value="months">Months</Option>
										<Option value="years">Years</Option>
									</Select>
								</Col>
							</Col>
						}
					}
				})()}

				{(() => {
					if(this.props.selectedProject.length === 1) {
						let foundProject = false;
						let foundProjectIndex = -1;
						for(var i = 0; i < this.props.allProjects.length; i++) {
							if(this.props.allProjects[i].id === this.props.selectedProject[0]) {
								foundProject = true;
								foundProjectIndex = i;
								break;
							}
						}
						if(!foundProject) {
							return <Col md={12} className="add-single-portfolio-project-form-item mt10 mb10">
								<label className="col-md-12 is-imp">Expected Impact:</label>
								<Col md={12}>
									<textarea id="project-expected-impact" defaultValue="" onChange={this.props.changeProjectExpectedImpact}></textarea>
								</Col>
							</Col>
						}
					}
				})()}

				<Col md={12} className="add-single-portfolio-project-form-item mt10 mb10">
					<label className="col-md-12 is-imp">Enter KPIs or Copy:</label>
					<Col md={12}>
						<Multiselect id="singlePortfolioGoal" buttonClass="singlePortfolioGoal btn btn-default"
	        		onChange={this.props.handleSelectChange.bind(this, 'selectedKPIs')}
	        		buttonText={this.showButtonText} multiple
	        		buttonWidth={'100%'} data={this.props.allKPIs} />
					</Col>
				</Col>

				{(() => {
					if(this.props.selectedProject.length === 1) {
						let foundProject = false;
						let foundProjectIndex = -1;
						for(var i = 0; i < this.props.allProjects.length; i++) {
							if(this.props.allProjects[i].id === this.props.selectedProject[0]) {
								foundProject = true;
								foundProjectIndex = i;
								break;
							}
						}
						if(!foundProject) {
							return <Col md={4} className="add-single-portfolio-project-form-item mt10 mb10">
								<label className="col-md-12">Expected Start Date:</label>
								<Col md={12}>
									<LocaleProvider locale={enUS}>
										<DatePicker locale={enUS} format={'DD/MM/YYYY'} onChange={this.props.onStartDateChange} />
									</LocaleProvider>
								</Col>
							</Col>
						}
					}
				})()}

				<Col md={4} className="add-single-portfolio-project-form-item mt10 mb10">
					<label className="col-md-12 is-imp">Define reporting frequency:</label>
					<Col md={12}>
						<Select id="reporting-freq-selector" defaultValue={this.props.reportingFrequency} size="large" onChange={this.props.changeSelectedReportingFreq}>
							<Option disabled value="">None Selected</Option>
							{this.props.otAttributes.reportingFrequency.map(function(freq, index) {
								return <Option key={index} value={freq.value}>{freq.label}</Option>
							})}
						</Select>
					</Col>
				</Col>


				{this.props.amountBreakupTableVisibility && this.props.amountBreakup !== null ? (
					<Col md={12}>
						{(() => {
							if(this.props.selectedProject.length === 1) {
								let foundProject = false;
								let foundProjectIndex = -1;
								for(var i = 0; i < this.props.allProjects.length; i++) {
									if(this.props.allProjects[i].id === this.props.selectedProject[0]) {
										foundProject = true;
										foundProjectIndex = i;
										break;
									}
								}

								if(!foundProject) {
									return <div>
										<span className="amount-breakup-table-note mb10"># Note: Please fill the table to assign amounts for different cost heads resulting to a total of the enter allocated amount.</span>
										<AmountBreakupTable addNewBreakup={this.props.addNewBreakup} updateAmountBreakupName={this.props.updateAmountBreakupName}
											updateAmountBreakupCosthead={this.props.updateAmountBreakupCosthead} amountBreakup={this.props.amountBreakup}
											updateAmountBreakupAmount={this.props.updateAmountBreakupAmount} removeAmountBreakup={this.props.removeAmountBreakup}
											costHeads={this.state.costHeads} />
										{/*<CostTable allocatedAmount={this.props.allocatedAmount} costAllocation={this.props.costAllocation}
											addNewInvestment={this.props.addNewInvestment} updateInvestmentAmount={this.props.updateInvestmentAmount}
											updateInvestmentDate={this.props.updateInvestmentDate} updateInvestmentComment={this.props.updateInvestmentComment}
											updateInvestmentMode={this.props.updateInvestmentMode} removeInvestment={this.props.removeInvestment} />*/}
									</div>
								}
							}
						})()}
					</Col>
				) : (null)}
				<Col md={12} className="mt10 mb10">
					<button className="btn btn-default btn-danger pull-right" onClick={this.props.cancelProject}>Cancel</button>
					<button className="btn btn-default pull-right" onClick={this.props.saveProject}>Save</button>
				</Col>
			</Col>
		)
  }
}