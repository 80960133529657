import React, { Component } from "react";

export default class UserProfile extends Component {
  constructor(props) {
    super(props);
    //needs props for:-
    //[scaleComponent]component scale -> 1,2,3 etc. so that the size of image can be fixed accordding to the name of the componnets
    //[backgroundImage]background image -> name of the image
    this.state = {
      style: null
    };
  }

  // border: 0.1em solid #FA4600
  // width: 5em
  // height: 5em
  // border-radius: 50%
  // background-size: cover
  // background-repeat: no-repeat
  // background-image: url('http://bpic.588ku.com/element_origin_min_pic/17/09/19/28e7312d32356cabb08cb36d5d232492.jpg')

  componentDidMount() {
    switch (this.props.boxType) {
      case "CIRCLE":
        switch (this.props.scaleComponent) {
          case 0:
            this.setState({
              style: {
                width: "4em",
                height: "4em",
                borderRadius: "50%",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundImage: "url(" + this.props.backgroundImage + ")"
              }
            });
            break;

          case 1:
            this.setState({
              style: {
                border: "0.1em solid #FA4600",
                width: "3em",
                height: "3em",
                borderRadius: "50%",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundImage: "url(" + this.props.backgroundImage + ")"
              }
            });
            break;

          case 2:
            this.setState({
              style: {
                border: "0.1em solid #FA4600",
                width: "3.5em",
                height: "3.5em",
                borderRadius: "50%",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                 backgroundImage: "url(" + this.props.backgroundImage + ")"
              }
            });
            break;

          case 3:
            this.setState({
              style: {
                border: "0.1em solid #FA4600",
                width: "5em",
                height: "5em",
                borderRadius: "50%",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundImage: "url(" + this.props.backgroundImage + ")"
              }
            });
            break;

          case 4:
            this.setState({
              style: {
                border: "0.1em solid #FA4600",
                width: "7em",
                height: "7em",
                borderRadius: "50%",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundImage: "url(" + this.props.backgroundImage + ")"
              }
            });
            break;

          case 5:
            this.setState({
              style: {
                border: "0.1em solid #FA4600",
                width: "2em",
                height: "2em",
                borderRadius: "50%",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat"
              }
            });
            break;

          case 6:
            this.setState({
              style: {
                border: "0.1em solid #242223",
                width: "2em",
                height: "2em",
                borderRadius: "50%",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat"
              }
            });
            break;
        }
        break;

      case "RECTANGLE":
        switch (this.props.scaleComponent) {
          case 1:
            this.setState({
              style: {
                width: "2em",
                height: "2em",
                borderRadius: "1px",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundImage: "url(" + this.props.backgroundImage + ")"
              }
            });
            break;
        }
        break;
    }
  }

  render() {
    return <div style={this.state.style} />;
  }
}
