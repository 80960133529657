import React, {Component} from 'react';
import {Row, Col, Tab, Tabs} from 'react-bootstrap';
// import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import swal from 'sweetalert2';
import $ from 'jquery';
import _ from 'lodash';
import moment from 'moment';
import { Select } from 'antd';
import { Menu } from 'antd';
import Scroll from 'react-scroll';

import * as action from '../../../../actions/DiscoverCSRProjectsAction';

import SingleCSRProjectMap from "./SingleCSRProjectMap";
import AmountBreakupTable from './AmountBreakupTable';
import ShowInterestModal from './ShowInterestModal';
import NotificationModal from '../../../MIS/Discover/SingleMISProject/NotificationModal';
import GraphModal from "./GraphModal";
import AddBudget from './AddBudget';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    Legend
  } from "recharts";
import { BootstrapTable, TableHeaderColumn, ButtonGroup, ExportCSVButton } from 'react-bootstrap-table';
import io from "socket.io-client";

const socket = io(process.env.REACT_APP_API_URL);

const checkValue = require('../../../../checkValue.js').checkValue;
const readCookie = require('../../../../cookie.js').readCookie;
const Option = Select.Option;

let Link = Scroll.Link;

class SingleCSRProject extends Component {
	constructor(props) {
		super(props);
		this.state = {
			graphPlot: null,
			projectUpdates: false,
			milestones: false,
			keyIndicator: false,
			assessmentData: false,
			assessmentdata: false,
			dateArray: null,
			kpiData: null,
			sortByDate: null,
			keyIndicator: null,
			readMore: false,
			readMoreSocialImpact: false,
			readMoreEconomicImpact: false,
			canShowInterest: true,
			amountBreakup: null,
			showInterestModal: false,
			showInterestMessage: '',
			chatContainerOpen: false,
			chats: null,
			chatReason: null,
			projectStatus: 'noAction',
			evenMoreDetailsKey: 1,
			ngoId: null,
			companyId: this.props.userData.organisationData.id,
			projectId: null,
			discussion: '',
			conversationId: null,
			corporateInterestStatus: 'noAction',
			corporateInvestment: {},
			confirmInvestment: false,
			exitFlow: false,
			current: 'overview'
		}
		this.chatReasonRef = null;

		this.setChatReasonRef = element => {
			this.chatReasonRef = element;
		};

		this.focusChatReason = () => {
			// Focus the text input using the raw DOM API
			if (this.chatReasonRef) this.chatReasonRef.focus();
		};
	}

	componentWillMount() {
		this.initSocket();
	}

	initSocket = () => {
		socket.on('connect', () => {
			socket.emit('authentication', { token: JSON.parse(readCookie('access_token')).access_token });
			socket.on('authenticated', function () {
			  this.setState({ socket });

			  let csr_namespace = "CSR_" + this.state._companyId;
			  socket.on(csr_namespace, (data) => {
			    if(data.length === undefined) {
    				let new_chat = this.state.chats;
    				new_chat.push(data);
    				let uniq = _.uniq(new_chat, '_id');
    				this.setState({ chats: uniq, discussion: '' });
    			} else {
    				let new_chat = [];
    				let uniq_msg = [];
    				new_chat.push(this.state.chats[0]);
    				uniq_msg = new_chat.concat(data);
    				this.setState({ chats: uniq_msg });
    			}
			  });
			}.bind(this));
		});
	}

	componentDidMount() {
		this.focusChatReason();

		if(!this.props.selectedProject.hasOwnProperty('customUrl')) {
			let path = window.location.pathname;
			path = path.split('/').pop();
			fetch(process.env.REACT_APP_API_URL + '/get-project/' + path, {
				method: "GET",
				headers: {
					'Auth': JSON.parse(readCookie('access_token')).access_token
				}
			}).then((data) => data.json())
			.then(function(data) {
				if(data.status === 'ok') {
					this.setState({ngoId: data.data.ngoData.id, projectId: data.data.id});

					let uri_updates = process.env.REACT_APP_API_URL+"/project/allUpdates/"+this.state.projectId;

					fetch(uri_updates,{
						method: 'GET',
						headers: {
							'Auth': JSON.parse(readCookie('access_token')).access_token
						}
					}).then(res => (res.json()))
					.then(res => {
						let projectUpdates = res.message.reverse();
						this.setState({ projectUpdates: projectUpdates });

						fetch(process.env.REACT_APP_API_URL + '/project/' + this.state.projectId + '/milestone', {
							method: "GET",
							headers: {
								"Content-Type": "application/json",
								'Auth': JSON.parse(readCookie('access_token')).access_token
							}
						}).then(res => (res.json()))
						.then(res => {
							this.setState({ milestones: res.data });

							const uri = process.env.REACT_APP_API_URL + "/project/key-indicators/" + this.state.projectId;

	        		let url = process.env.REACT_APP_API_URL + "/project/" + this.state.projectId + "/kpi";

			        fetch(url, {
		            method: 'GET',
		            headers: {
									'Auth': JSON.parse(readCookie('access_token')).access_token
								}
        			}).then(res => (res.json()))
        			.then(res => {
		            let activeKpis = _.filter(res.data, (el, i) => (el.isDeleted === false));
		            this.setState({ kpiData: activeKpis });
        			}).catch(er => {
		            console.log("err", er)
			        });

			        fetch(uri, {
		            method:'GET',
		            headers: {
									'Auth': JSON.parse(readCookie('access_token')).access_token
								}
        			}).then(res => (res.json()))
        			.then(res => {
		            this.setState({keyIndicator: res.data});
		           	this.setState({assessmentdata: res.data})
		            let sortedAssessmentDate = [];
		            let sortByDate = [];
		            let dateArray = [];
		            sortedAssessmentDate = _.groupBy(res.data, 'date');
		            _.map(sortedAssessmentDate, (el, index) => {
	                let array = [];
	                dateArray.push({date: index})
	                sortByDate.push({date: index, value: el})
		          	});
		            this.setState({dateArray: dateArray, sortByDate: sortByDate});
        			}).catch(err => {
		            console.log("err", err);
			        });
						}).catch(err => {
						});
					}).catch(err => {
						console.log("error", err);
					});

					this.props.actions.updateSelectedProject(data.data);
					let companyId = JSON.parse(readCookie('userData')).organisationData.id;
					let projectId = data.data.id;
					let amountBreakup = data.data.amountBreakup;
					if (amountBreakup.length) {
						this.setState({ amountBreakup });
					} else {
						this.setState({ evenMoreDetailsKey: 2 });
					}
					let corporateInterestStatus = data.data.corporateInterestStatus;
					if (corporateInterestStatus !== false) {
						this.setState({ corporateInterestStatus });
					} else {
						this.setState({ corporateInterestStatus: 'noAction' });
					}
					fetch(process.env.REACT_APP_API_URL + '/project/interests/company/' + companyId, {
						method: "GET",
						headers: {
							'Auth': JSON.parse(readCookie('access_token')).access_token
						}
					}).then((data) => data.json())
					.then(function (data) {
						if (data.status === 'ok') {
							let canShowInterest = true;
							for (var i = 0; i < data.data.length; i++) {
								if (data.data[i].project.id === projectId) {
									canShowInterest = false;
									this.setState({ canShowInterest: false });
								}
							}
							if (canShowInterest) {
								this.setState({ canShowInterest: true });
							}
						}
					}.bind(this));
				} else {
					this.setState({ evenMoreDetailsKey: 2 });
				}
			}.bind(this));
		} else {
			window.location = "/404";
		}

		$(window).scroll(function() {
			if($(window).scrollTop() > 50) $('#single-csr-project-fixed-top').addClass('show');
			else $('#single-csr-project-fixed-top').removeClass('show');
		});
	}

	showInterest(value) {
		if(this.state.showInterestMessage !== '') {
			let data = {};
			data['companyId'] = JSON.parse(readCookie('userData')).organisationData.id;
			data['message'] = this.state.showInterestMessage;
			data["ngoId"] = this.state.ngoId;
			fetch(process.env.REACT_APP_API_URL + '/project/' + this.props.selectedProject.id + '/interests', {
				method: "POST",
				headers: {
					'Content-Type': 'application/json',
					'Auth': JSON.parse(readCookie('access_token')).access_token
				},
				body: JSON.stringify(data)
			}).then(function(response) {
				return response.json();
			}).then(function(data) {
				if(data.status === 'ok') {
					swal(
						'Awesome!',
						'Your interest in this project has been taken into account.',
						'success'
					)
					this.setState({ showInterestModal: false, showInterestMessage: '', canShowInterest: false });
				}
			}.bind(this));
		}
		this.projectStatusChange(value);
	}

	onShowInterestClose(data) {
		this.setState({ showInterestModal: false, showInterestMessage: '' });
	}

	onShowInterestOpen() {
		this.setState({ showInterestModal: true });
	}

	updateMessage(e) {
		this.setState({ showInterestMessage: e.target.value });
	}

	clearSelectedProject() {
		let projectJSON = {};
		this.props.actions.updateSelectedProject(projectJSON);
	}

	toggleChatContainerOpen() {
		this.setState({ chatContainerOpen: !this.state.chatContainerOpen });
		let uri = process.env.REACT_APP_API_URL+"/chat/csr-ngo/"+this.state.companyId;
		let chat_info = {
			projectId: this.state.projectId
		}
		fetch(uri,{
			method:'POST',
			headers: {
				'Content-Type': 'application/json',
			// 'Auth': JSON.parse(readCookie('mis_data')).access_token
			},
			body:  JSON.stringify(chat_info)
		})
		.then(res => (res.json()))
		.then(res => {
		console.log("chat/id", res.data);
			//let arr = recent_chat.concat(res.data);
		// console.log("chat:id", arr)
			this.setState({chats: res.data, conversationId: res.data[0]._conversationId });
			console.log("chats", this.state.chats);
		})
		.catch(err => {
			console.log("err", err);
		});

	}

	startChat() {
		// if(this.state.chatReason !== null && this.state.chatReason !== '') {
		// 	let data = {};
		// 	data['reason'] = this.state.chatReason;
		// 	data['companyId'] = JSON.parse(readCookie('userData')).organisationData.id;
		// 	data['projectId'] = this.props.selectedProject.id;
		// 	this.setState({ chats: [] });
		// }
		if(this.state.chatReason !== null && this.state.chatReason !== '') {
			let data = {};
			data['companyId'] = JSON.parse(readCookie('userData')).organisationData.id;
			data['message'] = this.state.chatReason;
			data["ngoId"] = this.state.ngoId;
			fetch(process.env.REACT_APP_API_URL + '/project/' + this.props.selectedProject.id + '/interests', {
				method: "POST",
				headers: {
					'Content-Type': 'application/json',
					'Auth': JSON.parse(readCookie('access_token')).access_token
				},
				body: JSON.stringify(data)
			}).then(function(response) {
				return response.json();
			}).then(function(data) {
				if(data.status === 'ok') {
					swal(
						'Awesome!',
						'Your interest in this project has been taken into account.',
						'success'
					)
					this.setState({ chats: [] });
					//this.setState({ showInterestModal: false, showInterestMessage: '', canShowInterest: false });
				}
			}.bind(this));
		}
		else {
			this.focusChatReason();
		}
	}

	changeChatReason(e) {
		this.setState({ chatReason: e.target.value });
	}

	projectStatusChange(value) {
		if(value === 'Invested'){
			this.onShowInterestOpen();
			if(this.state.confirmInvestment === true){
				this.setState({ corporateInterestStatus: value });
			} else {
				this.setState({ corporateInterestStatus: 'Under Review' });
			}
		} else {
		this.setState({ corporateInterestStatus: value });
		}
		fetch(process.env.REACT_APP_API_URL+ '/project/'+ this.state.projectId + '/interests', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
			body: JSON.stringify(value)
		}).then(data => data.json())
		.then(data => console.log(data))
		console.log(this.state.projectId, '---', value)
	}

	changeEvenMoreDetailsKey(key) {
		this.setState({ evenMoreDetailsKey: key });
	}

	sendMessage(event){
		// console.log("event", event.key);
		if(event.key === 'Enter'){
			let { socket } = this.state;
			let data = {
				"case": "saveLastChat",
				"_ngoId": this.state.ngoId,
				"_conversationId": this.state.conversationId,
				"_companyId": this.state.companyId,
				"discussion": this.state.discussion,
				"sender": "CSR"

				}
				socket.emit("CHAT_HANDLERS", data);
				this.setState({discussion: ''})

		}
	}

	getSocketDone(event) {
		let {socket} = this.state;
		//console.log("getsocket done");
		if(this.state.companyId===null || this.state.ngoId=== null || this.state.conversationId === null){
			return;
		}
		let data = {
			"case": "getLastChat",
			"_ngoId":  this.state.ngoId,
			"_conversationId": this.state.conversationId,
			"_companyId": this.state.companyId,
			"discussion": this.state.discussion,
			"sender": "CSR"
		}
		socket.emit("CHAT_HANDLERS", data);

		// this.setState({message: ''})
		// this.sendMessage(event);
	}

	exitFlow() {
		let exitFlow = true;
		this.setState({ exitFlow });
	}

	showFlow() {
		let exitFlow = false;
		this.setState({ exitFlow });
	}

	handleAgree(value) {
		if(value === 'Confirm'){
			this.setState({ confirmInvestment: true });
		} else {
			this.setState({ confirmInvestment: false });
		}
		this.onShowInterestClose();
	}

	showAssessmentData(data, event){
        console.log("showAssessmentData",data, event, this.state.assessmentdata);
        let sortById = _.filter(this.state.assessmentdata, (el, i) => (el.value[0].kpi === event));
        let finalSort = _.filter(sortById, (el, i) => (
            data.date === el.date
        ))
        console.log("sortedByid", data, event, sortById, finalSort)
        if(finalSort.length>0){
            return  finalSort[0].value[0].unit
        }
        else{
            return "0";
        }
	}

	showGraphTable(row){
		//this.setState({graphPlot: null})
        let graph_data = _.filter(this.state.keyIndicator, (el , i) => (row === el.value[0].kpi));
        if(graph_data.length>0){
			let data = {
				"graph_data" : graph_data
			}
			this.setState({graphPlot: data});
			console.log("graph_data::", graph_data, this.state.graphPlot)
            //this.props.graphModal(graph_data);Graph
        }
        else{
			return 0;
           // Alert.info("There is no Accessment Data to show. Please Add one");
        }

    }

	addPlotGraphButton = (row) => {
        return(
            <button className="btn btn-info" onClick={this.showGraphTable.bind(this, row)}>Plot Graph</button>
        )
    }
	handleClick(key) {
		console.log('hello');
		this.setState({ current: key });
	}

	handlePathChange = () => {
		window.location.pathname = "/portfolio/" + this.state.corporateInvestment.portfolioId + "/project/" + this.state.corporateInvestment.investmentId;
	}

	render() {
		if(this.props.selectedProject.hasOwnProperty('title')) {
			return (
				<div className="single-csr-project-container">
					<div className="fixed-top-container" id="single-csr-project-fixed-top">
						<div>
							<Menu selectedKeys={[this.state.current]} mode="horizontal">
								<Menu.Item key="overview">
									<Link to="overview" spy={true} smooth={true} offset={-100} duration={500} onClick={this.handleClick.bind(this, 'overview')}>Overview</Link>
								</Menu.Item>
								<Menu.Item key="description">
									<Link to="description" spy={true} smooth={true} offset={-50} duration={800} onClick={this.handleClick.bind(this, 'description')}>Description</Link>
								</Menu.Item>
								<Menu.Item key="finances">
									<Link to="finances" spy={true} smooth={true} offset={-50} duration={1500} onClick={this.handleClick.bind(this, 'finances')}>Finances</Link>
								</Menu.Item>
								<Menu.Item key="milestones">
									<Link to="milestones" spy={true} smooth={true} offset={-50} duration={1500} onClick={this.handleClick.bind(this, 'milestones')}>Milestones</Link>
								</Menu.Item>
								<Menu.Item key="impact-monitoring">
									<Link to="impact-monitoring" spy={true} smooth={true} offset={-50} duration={1500} onClick={this.handleClick.bind(this, 'impact-monitoring')}>Impact Monitoring</Link>
								</Menu.Item>
							</Menu>

							{this.state.confirmInvestment === true ? (
								<div>
									<span className="status-container">Status: <span className="status">Invested</span></span>
									<button className="express-btn">
										Invest Funds
									</button>
								</div>
							) : (
								<div>
									{(() => {
										if(this.state.corporateInterestStatus === 'noAction') {
											return (
												<div className="status-holder">
													<span className="status-container">Status: <span className="status">Show Interest</span></span>
													<button className="express-btn" onClick={this.projectStatusChange.bind(this, 'Interested')}>
														Show Interest
													</button>
													<button className="btn skip-btn" onClick={this.exitFlow.bind(this)}>
														Skip
													</button>
												</div>
											)
										} else if(this.state.corporateInterestStatus === 'Interested') {
											return (
												<div className="status-holder">
													<span className="status-container">Status: <span className="status">Put Under Review</span></span>
													<button className="express-btn" onClick={this.projectStatusChange.bind(this, 'Under Review')}>
													 Put Under Review
													</button>
													<button className="btn skip-btn" onClick={this.exitFlow.bind(this)}>
														Skip
													</button>
												</div>
											)
										} else if(this.state.corporateInterestStatus === 'Under Review') {
											return (
												<div className="status-holder">
													<span className="status-container">Status: <span className="status">Start Investment</span></span>
													<button className="express-btn" onClick={this.onShowInterestOpen.bind(this, 'Invested')}>
														Start Investment
													</button>
													<button className="btn skip-btn" onClick={this.exitFlow.bind(this)}>
														Skip
													</button>
												</div>
											)
										}
									})()}
								</div>
							)}
						</div>
					</div>

					<Row className="back-container" id="overview">
						<a href={"/csr/explore-projects"} className="back" onClick={this.clearSelectedProject.bind(this)}>
							<i className="fa fa-chevron-left"></i>
						</a>
						<span className="project-title">{' ' + this.props.selectedProject.title}</span>
					</Row>

					<Row>
						<Col md={6} className="project-details">
							{this.props.selectedProject.photo ? (
								<div className="project-image" style={{backgroundImage: "url('" + this.props.selectedProject.photo + "')"}}>
									<div className="project-cause-icon" title={this.props.selectedProject.goalData.name}
										style={{backgroundImage: "url('https://le-uploaded-image-bucket.s3.amazonaws.com/" + this.props.selectedProject.goalData.iconUrl + "')"}}>
									</div>
									<span className="project-cause-name">{this.props.selectedProject.goalData.name}</span>
								</div>
							) : (
								<div className="project-image" style={{backgroundImage: "url('https://assets.letsendorse.com/sahaj/project-placeholder.jpg')"}}>
									<div className="project-cause-icon" title={this.props.selectedProject.goalData.name}
										style={{backgroundImage: "url('https://le-uploaded-image-bucket.s3.amazonaws.com/" + this.props.selectedProject.goalData.iconUrl + "')"}}>
									</div>
									<span className="project-cause-name">{this.props.selectedProject.goalData.name}</span>
								</div>
							)}
						</Col>
						<Col md={6} className="project-details">
							<div className="project-key-details">
								<div className="project-key-detail">
									<div className="heading">Status: </div>
									<div className="content status-content">
										{this.state.exitFlow ? (null) : (
											<div className="status-content-container">
												{this.state.corporateInterestStatus === 'Invested' && this.state.confirmInvestment ? (
													<div className="status-content-disabled">Invested</div>
												) : (
													<Select value={this.state.corporateInterestStatus} onChange={this.projectStatusChange.bind(this)}>
														<Option value="noAction">No Action</Option>
														<Option value="Interested">Interested</Option>
														<Option value="Under Review">Under Review</Option>
														<Option value="Invested">Invested</Option>
														<Option value="Skipped">Skipped</Option>
													</Select>
												)}
											</div>
										)}
										<span className="project-flow-span">view project flow</span>
									</div>
								</div>
								{!checkValue(this.props.selectedProject.location) ? (
									<div className="project-key-detail">
										<div className="heading">Location/Region: </div>
										<div className="content">
											{' ' + this.props.selectedProject.location[0].address}
											{this.props.selectedProject.location.map(function(location, index) {
												if(index !== 0) {
													return (
														<span key={index}>{'; ' + location.address}</span>
													)
												}
											}.bind(this))}
										</div>
									</div>
								) : (null)}
								{this.props.selectedProject.targetBeneficiaries.length ? (
									<div className="project-key-detail">
										<div className="heading">Target beneficiaries ({this.props.selectedProject.targetBeneficiaries.length}): </div>
										<div className="content">
											{this.props.selectedProject.targetBeneficiaries.map(function(targetBeneficiary, i) {
												if(this.props.selectedProject.targetBeneficiaries.length - i === 1) return targetBeneficiary.name
												else return targetBeneficiary.name + ', '
											}.bind(this))}
										</div>
									</div>
								) : (null)}
								{(() => {
									if(this.props.selectedProject.hasOwnProperty('agenda')) {
										if(this.props.selectedProject.agenda.hasOwnProperty('id')) {
											return (
												<div className="project-key-detail">
													<div className="heading">Agenda: </div>
													<div className="content">{this.props.selectedProject.agenda.name}</div>
												</div>
											)
										} else return null;
									} else return null;
								})()}
								<div className="project-key-detail" hidden={checkValue(this.props.selectedProject.expectedDuration)}>
									<div className="heading">Expected duration: </div>
									<div className="content">{this.props.selectedProject.expectedDuration}</div>
								</div>
								{this.props.selectedProject.status ? (
									<div className="project-key-detail">
										<div className="heading">Project status: </div>
										<div className="content">{this.props.selectedProject.status}</div>
									</div>
								) : (null)}
								{!checkValue(this.props.selectedProject.proposedBudget) ? (
								<div className="project-key-detail">
									<div className="heading">Proposed Budget: </div>
									<div className="content">
										{this.props.selectedProject.hasOwnProperty('currency_symbol') ? (<div className="content">{this.props.selectedProject.currency_symbol + this.props.selectedProject.proposedBudget}</div>) : (<div className="content">{'INR ' + window.inrFormat(this.props.selectedProject.proposedBudget)}</div>)}
									</div>
								</div>): (null)}
								{this.props.selectedProject.unitEconomics ? (
									<div className="project-key-detail">
										<div className="heading">Proposed Requirement: </div>
										<div className="content">
											{this.props.selectedProject.hasOwnProperty('maxUnits') ? (<span className="mr5">{this.props.selectedProject.maxUnits + ' '}</span>) : (null)}
											{this.props.selectedProject.unitName + ' at '}
											{this.props.selectedProject.hasOwnProperty('currency_symbol') ? (
												<span className="ml5">{this.props.selectedProject.currency_symbol + ' ' + window.inrFormat(this.props.selectedProject.unitAmount) + ' / Unit'}</span>
											) : (
												<span className="ml5">{'INR ' + window.inrFormat(this.props.selectedProject.unitAmount) + ' / Unit'}</span>
											)}
										</div>
									</div>
								) : (null)}
								<div className="project-key-detail">
									<div className="heading">About the NGO</div>
									<div className="content">
										{this.props.selectedProject.ngoData.csrHistory ? (
											<div className="about-ngo-tag">Has done CSR</div>
										) : (null)}
										{this.props.selectedProject.ngoData.hasOwnProperty('section') ? (
											this.props.selectedProject.ngoData.section.map(function(sec, index) {
												return <div className="about-ngo-tag" key={index+"cc"}>{sec.name}</div>
											})
										) : (null)}
										<div className="about-ngo-tag">{this.props.selectedProject.ngoData.older} years old</div>
									</div>
								</div>
							</div>
							{this.state.exitFlow ? (
								<div className="project-status-btn-container">
									<button className="express-btn" onClick={this.showFlow.bind(this)}>
										Show Interest
									</button>
								</div>
							) : (
								<div className="project-status-btn-container">
									{this.state.canShowInterest !== null ? (
										this.state.canShowInterest === false ? (
											<button className="btn project-buy" disabled={true}>
												{' Already Interest Shown'}
											</button>
										) : (
											this.state.confirmInvestment === true ? (
												<button className="express-btn">
													Invest Funds
												</button>
											) : (
												<div className="btn-group">
													{this.state.corporateInterestStatus === 'noAction' ? (
														<button className="btn interested-btn" onClick={this.projectStatusChange.bind(this, 'Interested')}>
															Show Interest
														</button>
													) : (null)}
													{this.state.corporateInterestStatus === 'Interested' ? (
														<button className="btn interested-btn" onClick={this.projectStatusChange.bind(this, 'Under Review')}>
															Put Under Review
														</button>
													) : (null)}
													{this.state.corporateInterestStatus === 'Under Review' ? (
														<button className="btn interested-btn" onClick={this.projectStatusChange.bind(this, 'Invested')}>
															Start Investment
														</button>
													) : (null)}
													{this.state.corporateInterestStatus !== 'Invested' ? (
														<button className="btn skip-btn" onClick={this.exitFlow.bind(this)}>
															Skip
														</button>
													) : (null)}
												</div>
											)
										)
									) : (null)}
								</div>
							)}
						</Col>
						{/*this.props.selectedProject.hasOwnProperty('latitude') && this.props.selectedProject.hasOwnProperty('longitude') ? (
							<Col md={12}>
								<SingleCSRProjectMap selectedProject={this.props.selectedProject} />
							</Col>
						) : (null)*/}
						{this.props.selectedProject.hasOwnProperty('innovationData') && Object.keys(this.props.selectedProject.innovationData).length ? (
							<Col md={12}>
								<Row className="innovation-card">
									<Col md={8} className="innovation-card-text-container">
										<span className="is-inspired-by">This Project is Inspired by -</span>
										<a href={this.props.selectedProject.innovationData.url} target="_blank">
											<span className="innovation-card-title">{this.props.selectedProject.innovationData.title}</span>
										</a>
										<div className="innovation-card-description">
											<span className={this.state.readMore ? ('desc') : ('desc ellipsis')}>
												{this.props.selectedProject.innovationData.description}
											</span>
											<div className="read-more" onClick={() => {this.setState({ readMore: !this.state.readMore })}}>
												{this.state.readMore ? ('Read Less') : ('Read More')}
											</div>
										</div>
										<div className="innovation-card-impact-container">
											<Col md={6} className="social-impact-container">
												<span className="title"><div className="dot"></div>{'Social Impact :'}</span>
												<span className={this.state.readMoreSocialImpact ? ('desc') : ('desc ellipsis')}>
													{this.props.selectedProject.innovationData.socialImpact}
												</span>
												<div className="read-more" onClick={() => {this.setState({ readMoreSocialImpact: !this.state.readMoreSocialImpact })}}>
													{this.state.readMoreSocialImpact ? ('Read Less') : ('Read More')}
												</div>
											</Col>
											<Col md={6} className="economic-impact-container">
												<span className="title"><div className="dot"></div>{'Economic Impact :'}</span>
												<span className={this.state.readMoreEconomicImpact ? ('desc') : ('desc ellipsis')}>
													{this.props.selectedProject.innovationData.economicImpact}
												</span>
												<div className="read-more" onClick={() => {this.setState({ readMoreEconomicImpact: !this.state.readMoreEconomicImpact })}}>
													{this.state.readMoreEconomicImpact ? ('Read Less') : ('Read More')}
												</div>
											</Col>
										</div>
									</Col>
									<Col md={4} className="innovation-card-image-container">
										<a href={this.props.selectedProject.innovationData.url} target="_blank" className="innovation-card-image-link">
											<div className="innovation-card-cause-icon" style={{backgroundColor: this.props.selectedProject.innovationData.goalBackgroundColor, backgroundImage: "url('" + this.props.selectedProject.innovationData.goalIcon + "')"}}></div>
											<img className="innovation-card-image" src={this.props.selectedProject.innovationData.image} />
										</a>
										<span className="interested-in-innovation">Interested in the Innovation?</span>
										<button className="btn view-more-btn" onClick={()=> window.location.pathname = "/csr-investment-planning/innovation/" + this.props.selectedProject.innovationData.customUrl}>View More</button>
									</Col>
								</Row>
							</Col>
						) : (null)}
						<Col id="description" md={12} className="project-details more-details padt20">
							<Row>
								<Col md={12} className="features mt10 mb10">
									<div className="heading"><div className="dot"></div>{'About The Project :'}</div>
									<div className="content">{this.props.selectedProject.description}</div>
								</Col>
								<Col md={12} className="features mt10 mb10" hidden={checkValue(this.props.selectedProject.expectedImpact)}>
									<div className="heading"><div className="dot"></div>{'Expected Impact :'}</div>
									<div className="content">{this.props.selectedProject.expectedImpact}</div>
								</Col>

								<Col md={12} className="features mt10 mb10" style={{ padding: '0' }} hidden={checkValue(this.props.selectedProject.milestones)}>
									{this.props.selectedProject.milestones ? (
										<Col md={12} className="even-more-details-milestones-container">
											<div className="heading"><div className="dot"></div>{'Planned Milestones :'}</div>
											<div className="milestones" style={{ padding: '0 10px' }}>
												{this.props.selectedProject.milestones.map(function (milestone, index) {
													return (
														<div className="milestone" key={index}>
															<div className="dot"></div>
															{milestone}
														</div>
													)
												})}
											</div>
										</Col>
									) : (null)}
								</Col>

								<Col md={12} className="features mb10">
									<div id="even-more-details-container">
										<Row className="even-more-details-about-container">
											<Col md={12} className="features mb10" hidden={checkValue(this.props.selectedProject.need)}>
												<div className="heading"><div className="dot"></div>{'Need :'}</div>
												<div className="content">{this.props.selectedProject.need}</div>
											</Col>
											<Col md={12} className="features mt10 mb10" hidden={checkValue(this.props.selectedProject.impactMonitoring)}>
												<div className="heading"><div className="dot"></div>{'Systems in place to monitor the impact of the project :'}</div>
												<div className="content">{this.props.selectedProject.impactMonitoring}</div>
											</Col>
											<Col md={12} className="features mt10 mb10" hidden={checkValue(this.props.selectedProject.pastProjectHistory)}>
												<div className="heading"><div className="dot"></div>{'History of executing similar projects in the past :'}</div>
												<div className="content">{this.props.selectedProject.pastProjectHistory}</div>
											</Col>
											<Col md={12} className="features mt10 mb10" hidden={checkValue(this.props.selectedProject.sustainabilityPlan)}>
												<div className="heading"><div className="dot"></div>{'The plans to sustain this project in a long-term and the funding source :'}</div>
												<div className="content">{this.props.selectedProject.sustainabilityPlan}</div>
											</Col>
											{this.props.selectedProject.eeOpportunities ? (
												<Col md={12} className="features mt10 mb10" hidden={checkValue(this.props.selectedProject.eeOpportunities)}>
													<div className="heading"><div className="dot"></div>{'Various Opportunities for Employee Engagement, as part of this project :'}</div>
													<div className="content">
														{this.props.selectedProject.eeOpportunities.map(function(eeOpportunity, eeOpportunityIndex) {
															if(eeOpportunityIndex > 0) {
																return (
																	<span key={eeOpportunityIndex}>{', ' + eeOpportunity}</span>
																)
															} else {
																return (
																	<span key={eeOpportunityIndex}>{eeOpportunity}</span>
																)
															}
														})}
													</div>
												</Col>
											) : (null)}
										</Row>

										{this.state.amountBreakup !== null ? (
											<Col id="finances" md={12} className="even-more-details-budget-container mt10">
												<div className="heading"><div className="dot"></div>{'Project Budget :'}</div>
												<AddBudget proposedBudget={this.props.selectedProject.proposedBudget} amountBreakup={this.state.amountBreakup} />
											</Col>
										) : (null)}
									</div>
								</Col>

								<Col md={12} className="features mt10 mb10" hidden={checkValue(this.state.milestones)}>
									<div className="heading"><div className="dot"></div>{'Milestones :'}</div>
									<div className="content">
										<BootstrapTable data={this.state.milestones} striped={false} ref='table' hover={true}>
											<TableHeaderColumn dataField="id" isKey={true} hidden={true} export={false} searchable={false}>Milestone Id</TableHeaderColumn>
											<TableHeaderColumn dataField="date"   width='140px' headerAlign='center' dataAlign='center' dataSort={false}   hidden={false}  export={true} searchable={true}>Date</TableHeaderColumn>
											<TableHeaderColumn dataField="title" width='180px' headerAlign='center' dataAlign='center' dataSort={false}   hidden={false}   export={true} searchable={true}> Title</TableHeaderColumn>
											<TableHeaderColumn dataField="description"    width='70px' headerAlign='center' dataAlign='center' dataSort={false}   hidden={false}   export={true} searchable={true} dataFormat={this.typeFormatter}>Description</TableHeaderColumn>
										</BootstrapTable>
									</div>
								</Col>

								<Col md={12} className="features mt10 mb10" hidden={checkValue(this.state.assessmentdata)}>
										<div className="heading"><div className="dot"></div>{'Assessment :'}</div>
										<div className="content">

											<div style={{overflowX: 'scroll', overflowY: 'scroll'}}>
												<BootstrapTable data={this.state.kpiData} striped={false} ref='table' hover={true}>
													<TableHeaderColumn dataField="_id" isKey={true} hidden={true} export={false} searchable={false}>Id</TableHeaderColumn>
													<TableHeaderColumn dataField="name"   width='140px' headerAlign='center' dataAlign='center' dataSort={false}   hidden={false}  export={true} searchable={true}>KEY INDICATORS</TableHeaderColumn>
													{/* <TableHeaderColumn dataField="_id"   width='140px' headerAlign='center' dataAlign='center' dataSort={false}   hidden={false}  export={true} searchable={true} dataFormat={this.addNewButton}>ADD NEW ASSESSMENT</TableHeaderColumn>
													*/}
													{
														_.map(this.state.sortByDate, (el,i) => (

															<TableHeaderColumn dataField="_id"  key={i+"date"} width='140px' headerAlign='center' dataAlign='center' dataSort={false}   hidden={false}  export={true} searchable={true} dataFormat={this.showAssessmentData.bind(this, el)}>{el.date}</TableHeaderColumn>

														))
													}

													<TableHeaderColumn dataField="_id"    width='140px' headerAlign='center' dataAlign='center' dataSort={false}   hidden={false}   export={true} searchable={true} dataFormat={this.addPlotGraphButton}>PLOT GRAPH</TableHeaderColumn>

												</BootstrapTable>
											</div>


											</div>
									</Col>


									{
										(this.state.graphPlot !== null)
											?
											// <Col md={12}  style={{display: 'inline !important', height: '250px'}}>

												<GraphModal
													dataArray={this.state.graphPlot}
												/>
											// </Col>

											:
											null
									}

									<Col md={12} className="features mt10 mb10" hidden={checkValue(this.state.projectUpdates)}>
										<div className="heading"><div className="dot"></div>{'Project Updates :'}</div>
										<div className="content">
											{
												_.map(this.state.projectUpdates, (update, index) => (
													<div key={index+"updates"} style={{border: '1px solid #CCCCCC',
														padding: '10px',
														borderRadius: '4px',
														margin: '10px 0px 10px 0px'}}
													>
														{update._updatedBy[0].comments[0].text[0].description}
													</div>
												))
											}
										</div>
									</Col>

							</Row>
						</Col>
					</Row>
					{/* <MISImpact project_id={this.state.projectId} /> */}
					<div className={this.state.chatContainerOpen ? "chat-container open" : "chat-container"}>
						<div className="chat-header" onClick={this.toggleChatContainerOpen.bind(this)}>
							<span className="chat-span">Chat with NGO</span>
							<span className="chat-icon-container">
								{this.state.chatContainerOpen ? (
									<i className="fa fa-times chat-icon"></i>
								) : (
									<i className="fa fa-chevron-up chat-icon"></i>
								)}
							</span>
						</div>
						{this.state.chatContainerOpen && this.state.chats !== null ? (
							<div className="chat-messages-container" onMouseMove={this.getSocketDone.bind(this)}>
								{_.map(this.state.chats, (chat, index) => {
									if(chat.sender === this.state.companyId){
										return(
											<div key={index+"sndr"} style={{
												minHeight: '4em',
												alignItems: 'center',
												margin: '30px auto',
												alignSelf: 'flex-end',
												justifyContent: 'flex-end',
												display: 'flex',
												maxWidth: '100%'
											}}>
												<div style={{
													maxWidth: '65%',
													margin: '0 15px 0 0',
													borderRadius: '4px',
													padding: '5px 8px',
													backgroundColor: '#ededfa',
													color: '#6C6C6F',
													wordWrap: 'break-word'
												}}>{chat.discussion} <p style={{fontSize: '10px'}}>{moment(chat.time).format('YYYY-MM-DD HH:mm')}</p>
												</div>
											</div>
										)
									}
									else{

										return(
											<div key={index+"rcvr"} style={{
												minHeight: '4em',
												alignItems: 'center',
												margin: '30px auto',
												display: 'flex',
												maxWidth: '100%'
											}}>
												<div style={{
													maxWidth: '65%',
													margin: '0 0 0 15px',
													color: '#fff',
													borderRadius: '4px',
													padding: '5px 8px',
													backgroundColor: '#ef5a20',

													wordWrap: 'break-word'
												}}>{chat.discussion}<p style={{fontSize: '10px'}}>{moment(chat.time).format('YYYY-MM-DD HH:mm')}</p></div>
											</div>
										)
									}
								})}
							</div>
						) : (null)}
						{this.state.chatContainerOpen && this.state.chats !== null ? (
							<div className="chat-text-container">
								<textarea className="chat-text" ref={input => input && input.focus()} autoFocus onChange={(e) => this.setState({discussion: e.target.value})} placeholder="Type here and press enter.." onKeyPress={this.sendMessage.bind(this)}></textarea>
								<span className="chat-text-icons">
									<i className="fa fa-paperclip"></i>
								</span>
							</div>
						) : (null)}
						{this.state.chatContainerOpen && this.state.chats === null ? (
							<div className="chat-start-form">
								<span className="form-title">Please fill out the form below to start chatting.</span>
								<div className="form-group">
									<label className="control-label m0 is-imp">Reason:</label>
									<textarea className="reason" ref={this.setChatReasonRef} onChange={this.changeChatReason.bind(this)} autoFocus placeholder="Reason.." required></textarea>
								</div>
								<div className="start-chat-btn" onClick={this.startChat.bind(this)}>Start Chat</div>
							</div>
						) : (null)}
					</div>
					{this.state.canShowInterest ? (
						<ShowInterestModal showInterestMessage={this.state.showInterestMessage}
							showInterestModal={this.state.showInterestModal}
							updateMessage={this.updateMessage.bind(this)}
							showInterest={this.showInterest.bind(this)}
							onShowInterestClose={this.onShowInterestClose.bind(this)}
							onShowInterestOpen={this.onShowInterestOpen.bind(this)}
							handleAgree = {this.handleAgree.bind(this)}/>
					) : (null)}
					{/*<NotificationModal/> */}
				</div>
			);
		} else {
			return null;
		}
	}
}

SingleCSRProject.propTypes = {
	selectedProject: PropTypes.object,
};

function mapStateToProps(state) {
	return {
		selectedProject: state.DiscoverCSRProjects.selectedProject,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(action, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SingleCSRProject);
