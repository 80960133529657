import React,{ Component } from 'react';
import {Col, Row} from 'react-bootstrap';
import moment from 'moment';
import $ from 'jquery';
import Slider, { createSliderWithTooltip } from 'rc-slider';
import { VictoryPie } from 'victory';

const SliderWithTooltip = createSliderWithTooltip(Slider);

let year = moment().year();
const years = [];
for(var i = -3; i < 10; i++) {
	var yearStr = (year - i) + '-' + (year - i + 1);
	years.push(yearStr);
}

const tipProps = {
	placement: 'bottom',
	prefixCls: 'preferences-slider-tooltip rc-slider-tooltip',
}

export default class BasicInformation extends Component {
	constructor(props) {
		super(props);
		this.state = {
		}
		this.handleCountryChange = this.handleCountryChange.bind(this);
	}

	handleCountryChange(state, city) {
    var $this = $('.basic-information').find('select#country');
    var $selectedCountry = $($this).find(':selected');
    var countryId = $selectedCountry.attr('countryId');
    this.props.selectCountry($selectedCountry.val());
    fetch(process.env.REACT_APP_LOCATION_API+'?type=1&countryId=' + countryId)
    .then(function(response) {
      return response.json();
    }).then(function(data) {
      $('.basic-information').find('select#state').html('<option value="" disabled selected>Choose your state</option>');
      $('.basic-information').find('select#city').html('<option value="" disabled selected>Choose your city</option>');
      for(var i = 0; i < data.length; i++) {
        $('.basic-information').find('select#state').append('<option value="' + data[i]['name'] + '" stateId="' + data[i]['id'] + '">' + data[i]['name'] + '</option>');
      }
      if(state !== '') {
        $('.basic-information').find('select#state').find('option[value=' + state + ']').prop('selected', true);
        var $this2 = $('.basic-information').find('select#state');
        var $selectedState = $($this2).find(':selected');
        var stateId = $selectedState.attr('stateId');
        this.props.selectState($selectedState.val());
        fetch(process.env.REACT_APP_LOCATION_API+'?type=2&stateId=' + stateId)
        .then(function(response) {
          return response.json();
        }).then(function(data) {
          $('.basic-information').find('select#city').html('<option value="" disabled selected>Choose your city</option>');
          for(var i = 0; i < data.length; i++) {
            $('.basic-information').find('select#city').append('<option value="' + data[i]['name'] + '" cityId="' + data[i]['id'] + '">' + data[i]['name'] + '</option>');
          }
          if(city !== '') {
            $('.basic-information').find('select#city').find('option[value=' + city + ']').prop('selected', true);
          }
          this.props.basicInformationReady();
        }.bind(this));
      } else {
      	this.props.basicInformationReady();
      }
    }.bind(this));
  }

  handleStateChange() {
    var $this = $('.basic-information').find('select#state');
    var $selectedState = $($this).find(':selected');
    var stateId = $selectedState.attr('stateId');
    this.props.selectState($selectedState.val());
    fetch(process.env.REACT_APP_LOCATION_API+'?type=2&stateId=' + stateId)
    .then(function(response) {
      return response.json();
    }).then(function(data) {
      $('.basic-information').find('select#city').html('<option value="" disabled>Choose your city</option>');
      for(var i = 0; i < data.length; i++) {
        $('.basic-information').find('select#city').append('<option value="' + data[i]['name'] + '" cityId="' + data[i]['id'] + '">' + data[i]['name'] + '</option>');
      }
    });
  }

  handleCityChange() {
    var $this = $('.basic-information').find('select#city');
    var $selectedCity = $($this).find(':selected');
    this.props.selectCity($selectedCity.val());
  }

  componentDidMount() {
    fetch(process.env.REACT_APP_LOCATION_API)
    .then(function(response) {
      return response.json();
    }).then(function(data) {
      for(var i = 0; i < data.length; i++) {
        $('.basic-information').find('select#country').append('<option value="' + data[i]['name'] + '" countryId="' + data[i]['id'] + '">' + data[i]['name'] + '</option>');
      }
      if(this.props.country !== '') {
      	$('.basic-information').find('select#country').val(this.props.country);
      	this.handleCountryChange(this.props.state, this.props.city);
      } else {
      	this.props.basicInformationReady();
      }
    }.bind(this));
  }

  render() {
	  return (
	    <div>
	    	<Row className="basic-information">
	        <Col md={12}>
	          <Row className="mb20 mt20">
	          	<Col md={12}>
	          		<label className="headquarters">Headquarters Location</label>
	          	</Col>
	            <Col className="form-group" md={4}>
	              <label className="control-label mt0">Country:</label>
	              <select id="country" onChange={this.handleCountryChange.bind('', '')} defaultValue={this.props.country}>
	                <option value="" disabled>Choose your country</option>
	              </select>
	            </Col>

	            <Col className="form-group" md={4}>
	              <label className="control-label mt0">State:</label>
	              <select id="state" onChange={this.handleStateChange.bind(this)} defaultValue={this.props.state}>
	                <option value="" disabled>Choose your state</option>
	              </select>
	            </Col>

	            <Col className="form-group" md={4}>
	              <label className="control-label mt0">City:</label>
	              <select id="city" onChange={this.handleCityChange.bind(this)} defaultValue={this.props.city}>
	                <option value="" disabled>Choose your city</option>
	              </select>
	            </Col>
	          </Row>
	          <Row className="mb20 mt20">
	          	<Col className="m0 form-group" md={12}>
	          		<label>Annual CSR Budget: (Want to know how to calculate your CSR budget)</label>
	          	</Col>
	          	<div>
	          		{(() => {
	          			const chartData = [
	          				{x: 'Rs. ' + Math.round(this.props.csrBudget.communityInvestmentPercentage / 100 * this.props.csrBudget.amount), y: this.props.csrBudget.communityInvestmentPercentage},
	          				{x: 'Rs. ' + Math.round(this.props.csrBudget.volunteerEngagementPercentage / 100 * this.props.csrBudget.amount), y: this.props.csrBudget.volunteerEngagementPercentage},
	          				{x: 'Rs. ' + Math.round(this.props.csrBudget.trainingAndDevelopmentPercentage / 100 * this.props.csrBudget.amount), y: this.props.csrBudget.trainingAndDevelopmentPercentage},
	          				{x: 'Rs. ' + Math.round(this.props.csrBudget.othersPercentage / 100 * this.props.csrBudget.amount), y: this.props.csrBudget.othersPercentage}
	          			];
	          			const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
		          		return (
		          			<Row>
			          			<Col className="csr-budget-inputs" md={8}>
						          	<Col className="form-group" mdOffset={2} md={4}>
						          		<label className="control-label">Year:</label>
						              <select id="year" value={this.props.csrBudget.year} onChange={this.props.changeYear}>
						              	{years.map(function(year, index) {
						              		return (
						              			<option key={index} value={year}>{year}</option>
						              		)
						              	})}
						              </select>
						          	</Col>
						          	<Col className="form-group amount" md={4}>
						          		<label className="control-label">Amount:</label>
						              <input type="number" onChange={this.props.changeAmount} value={this.props.csrBudget.amount} />
						          	</Col>
						          	<Col md={12}>
							          	<Col className="form-group" md={6}>
							          		<label className="control-label"><span className="csr-budget-label csr-budget-comm-inv-label"></span>Percentage allocated to Community Investment:</label>
							          		<SliderWithTooltip value={this.props.csrBudget.communityInvestmentPercentage} tipProps={tipProps}
							          			max={100} onChange={this.props.communityInvestmentPercentageChange} />
							          	</Col>
							          	<Col className="form-group" md={6}>
							          		<label className="control-label"><span className="csr-budget-label csr-budget-vol-eng-label"></span>Percentage allocated to Volunteer Engagement:</label>
							          		<SliderWithTooltip value={this.props.csrBudget.volunteerEngagementPercentage} tipProps={tipProps}
							          			max={100} onChange={this.props.volunteerEngagementPercentageChange} />
							          	</Col>
							          	<Col className="form-group" md={6}>
							          		<label className="control-label"><span className="csr-budget-label csr-budget-training-dev-label"></span>Percentage allocated to Training And Development:</label>
							          		<SliderWithTooltip value={this.props.csrBudget.trainingAndDevelopmentPercentage} tipProps={tipProps}
							          			max={100} onChange={this.props.trainingAndDevelopmentPercentageChange} />
							          	</Col>
							          	<Col className="form-group" md={6}>
							          		<label className="control-label"><span className="csr-budget-label csr-budget-others-label"></span>Other:</label>
							          		<SliderWithTooltip value={this.props.csrBudget.othersPercentage} max={100} />
							          	</Col>
							          </Col>
			          			</Col>
			          			<Col className="csr-budget-chart" md={4}>
			          				<VictoryPie colorScale={['#0088FE', '#00C49F', '#FFBB28', '#FF8042']}
			          					data={chartData} labelRadius={70} style={{ labels: { fill: "#333", fontSize: 16, fontFamily: '"Lato", sans-serif' } }}/>
			          			</Col>
			          		</Row>
		          		)
		          	})()}
	          	</div>
	          </Row>
	        </Col>
	      </Row>
	    </div>
	  );
	}
}