import React, {Component} from 'react';

import CSRProjects from './CSRProjects';

export default class CSRProjectsContainer extends Component {
	constructor(props) {
    super(props);
    this.state = {
    	hideProjectsFilter: true
    };
  }

  toggleProjectFilters() {
  	this.setState({ hideProjectsFilter: !this.state.hideProjectsFilter });
	}

	render() {
		return (
			<div className="projects-container">
				{this.props.projects !== null ? (
						<div>							
							<CSRProjects projects={this.props.projects}
								discoverCSRProjectsListView={this.props.discoverCSRProjectsListView} />
						</div>
					) : ('')
				}
			</div>
		);
	}
}