import React from 'react';
import './NotFound.css';

export default class NotFound extends React.Component {
	render() {
		const { className} = this.props;
		return (
			<div className="NotFound">
				<div className="c">
					<div className="_404">404</div>
					<hr/>
					<div className="_1">THE PAGE</div>
					<div className="_2">WAS NOT FOUND</div>
					<a className="btn-nf" href='/'>BACK TO MARS</a>
				</div>
			</div>
		);
	}
}