import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import LandingSingleGoal from './LandingSingleGoal'

export default class LandingGoalsSection extends Component {
	render() {
		return (
			<div className="landing-goals-container light-bg">
				<div className="container">
					<Row>
						<Col md={6}>
							<div className="landing-goal-wrapper landing-goal-wrapper-large">
								pick an area and start making a difference
							</div>
						</Col>
						{this.props.featured !== undefined && this.props.featured !== null ? (
							this.props.featured.map(function(featured, i) {
								return <LandingSingleGoal
									key={i}
									featured={featured}
								/>
							}.bind(this))
						) : (null)}
					</Row>
				</div>
			</div>
		);
	}
}