import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import Map from './Map'

import * as action from '../../../actions/ContactAction';

class Contact extends Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}

	render() {
		return (
			<div className="about-parent">
				<div className="about-container">
					<div className="about-left">
						<Map>

						</Map>
					</div>
					<div className="about-right">
						<div className="about-contact-header">
							<span>contact us</span>
						</div>
						<div className="about-contact-form">
							<textarea placeholder="Let us know your queries...">

							</textarea>
							<button className="about-submit">Submit <i className="fa fa-long-arrow-right"></i></button>
						</div>
						<div className="about-contact-details">
							<div className="address-section">
								<span className="head-title">Address: </span>
								<span className="head-subtitle">208, Hosur Rd, Sector 6, HSR Layout, Bengaluru, Karnataka 560102</span>
							</div>
							<div className="contact-section">
								<span className="head-title">Phone: </span>
								<span className="head-subtitle">072040 24529</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

Contact.propTypes = {
	name: PropTypes.string,
	email: PropTypes.string,
	message: PropTypes.string
};

function mapStateToProps(state) {
	return {
		name: state.Contact.name,
		email: state.Contact.email,
		message: state.Contact.message,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(action, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Contact);