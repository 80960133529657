import React from 'react';
import './Verify.css';
import $ from 'jquery';

export default class Verify extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			status: null
		}
	}

	componentWillMount() {
		$('.loader-wrapper').show();
		let code = window.location.pathname;
		code = code.split('/').pop();
		let data = {};
		data.code = code;
		fetch(process.env.REACT_APP_API_URL + '/verify-email', {
			method: "POST",
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(data)
		}).then(function(response) {
			return response.json();
		}).then(function(data) {
			setTimeout(function () {
				$('.loader-wrapper').hide();
			}, 2000);
			if(data['status'] === 'ok') {
				this.setState({
					status: true
				})
			} else {
				this.setState({
					status: false
				})
			}
		}.bind(this));
	}

	render() {
		const { className} = this.props;
		return (
			<div className="verify">
				<div className="loader-wrapper">
					<svg className="hourglass" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 206" preserveAspectRatio="none">
						<path className="middle" d="M120 0H0v206h120V0zM77.1 133.2C87.5 140.9 92 145 92 152.6V178H28v-25.4c0-7.6 4.5-11.7 14.9-19.4 6-4.5 13-9.6 17.1-17 4.1 7.4 11.1 12.6 17.1 17zM60 89.7c-4.1-7.3-11.1-12.5-17.1-17C32.5 65.1 28 61 28 53.4V28h64v25.4c0 7.6-4.5 11.7-14.9 19.4-6 4.4-13 9.6-17.1 16.9z"/>
						<path className="outer" d="M93.7 95.3c10.5-7.7 26.3-19.4 26.3-41.9V0H0v53.4c0 22.5 15.8 34.2 26.3 41.9 3 2.2 7.9 5.8 9 7.7-1.1 1.9-6 5.5-9 7.7C15.8 118.4 0 130.1 0 152.6V206h120v-53.4c0-22.5-15.8-34.2-26.3-41.9-3-2.2-7.9-5.8-9-7.7 1.1-2 6-5.5 9-7.7zM70.6 103c0 18 35.4 21.8 35.4 49.6V192H14v-39.4c0-27.9 35.4-31.6 35.4-49.6S14 81.2 14 53.4V14h92v39.4C106 81.2 70.6 85 70.6 103z"/>
					</svg>
				</div>
				{this.state.status ? (
					<div className='verify-container'>
						<div className='verify-1'>YOUR EMAIL HAS BEEN SUCCESSFULLY VERIFIED!</div>
						<div className='verify-2'>The request to activate your account has been received. Our team shall get back to you shortly.</div>
					</div>
				) : (
					<div className='verify-container'>
						<div className='verify-2'>This link has expired :(</div>
					</div>
				)}

			</div>
		);
	}
}
