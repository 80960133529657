import React, { Component } from "react";
import { Checkbox } from "antd";
import { ToastContainer, toast } from "react-toastify";
import ReactGA from 'react-ga';
import $ from 'jquery';

const readCookie = require("../../../../../cookie.js").readCookie;

const focalGoals = ["Investors", "Management team", "CSR Committee", "Employees", "Customers", "Vendors and Value Chain", "Corporate Headquarter (Global Mandate)", "Local communities"];

class SelectTheCause extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allGoals: null,
      allAgendas: null,
      selectedGoals: [],
      selectedAgendas: [],
      noPreference: false,
      focalGoals: [],
      noFocals: false,
      csrVision: "",
      errorInGoal: []
    };
  }

  componentDidMount() {
    fetch(process.env.REACT_APP_API_URL + "/getOtAttributes", {
      method: "GET",
      headers: {
        Auth: JSON.parse(readCookie("access_token")).access_token
      }
    }).then(data => data.json())
    .then(data => {
      let allGoals = [];
      let allAgendas = [];
      let goalWithAgendas = {};
      for (let i = 0; i < data.otAttributes.length; i++) {
        if (data.otAttributes[i].type === "goal") {
          data.otAttributes[i].selected = false;
          allGoals.push(data.otAttributes[i]);
        } else if (data.otAttributes[i].type === "agenda") {
          allAgendas.push(data.otAttributes[i]);
          let agendaGoalId = data.otAttributes[i]._goalId;
          if(goalWithAgendas.hasOwnProperty(agendaGoalId)) goalWithAgendas[agendaGoalId].push(data.otAttributes[i]);
          else {
            let firstAgenda = {
              _id: -1,
              name: 'Select/Unselect All',
              selected: false
            };
            goalWithAgendas[agendaGoalId] = [];
            goalWithAgendas[agendaGoalId].push(firstAgenda, data.otAttributes[i]);
          }
        }
      }

      for(var i = 0; i < allGoals.length; i++) {
        allGoals[i].agendas = goalWithAgendas[allGoals[i]._id];
        allGoals[i].selectedAgendas = [];
      }
      allGoals = allGoals.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

      let noPreferenceGoal = {
        _id: -1,
        name: 'No Preference',
        selected: false
      }
      allGoals = [noPreferenceGoal, ...allGoals];

      let companyId = JSON.parse(readCookie("userData")).organisationData.id;
      fetch(process.env.REACT_APP_API_URL + "/company/" + companyId + "/mis/preferences", {
        method: "GET",
        headers: {
          Auth: JSON.parse(readCookie("access_token")).access_token
        }
      }).then(data => data.json())
      .then(data => {
        let goals = data.data.goals, agendas = data.data.agendas;

        if(goals === -1) {
          allGoals[0].selected = true;
        } else {
          for(var i = 0; i < goals.length; i++) {
            for(var j = 0; j < allGoals.length; j++) {
              if(goals[i].id === allGoals[j]._id) {
                allGoals[j].selected = true;
                for(var k = 0; k < agendas.length; k++) {
                  if(agendas[k].goalId === allGoals[j]._id) {
                    allGoals[j].selectedAgendas.push(agendas[k].id);
                  }
                }
                if(allGoals[j].hasOwnProperty('selectedAgendas') && allGoals[j].hasOwnProperty('agendas')) {
                  if(allGoals[j]._id !== -1 && allGoals[j].selectedAgendas.length === allGoals[j].agendas.length - 1) {
                    allGoals[j].agendas[0].selected = true;
                  }
                }
              }
            }
          }
        }

        let focalGoals = data.data.focalGoals || [];
        let csrVision = data.data.csrVision || "";

        this.setState({
          allGoals: allGoals,
          allAgendas: allAgendas,
          focalGoals: focalGoals,
          csrVision: csrVision
        });
      });
    });
  }

  noPreference = e => {
    let allGoals = this.state.allGoals;
    for (var i = 0; i < allGoals.length; i++) {
      allGoals[i].selected = false;
    }
    if (e.target.checked === true) {
      this.setState({
        selectedGoals: -1
      });
    } else if (e.target.checked === false) {
      this.setState({
        selectedGoals: []
      });
    }
    this.setState({
      allGoals,
      noPreference: !this.state.noPreference
    });
  };

  changeSelect = (index, e) => {
    let allGoals = this.state.allGoals;
    allGoals[index].selected = !allGoals[index].selected;
    if(e.target.value === -1 && allGoals[index].selected) {
      for(var i = 1; i < allGoals.length; i++) {
        allGoals[i].selected = false;
        allGoals[i].selectedAgendas = [];
      }
    } else if(e.target.value !== -1 && !allGoals[index].selected) {
      allGoals[index].agendas[0].selected = false;
      allGoals[index].selectedAgendas = [];
    }
    this.setState({ allGoals });
  };

  agendaSelect = (index, e) => {
    let allGoals = this.state.allGoals;
    if (e.target.value === -1) {
      allGoals[index].agendas[0].selected = !allGoals[index].agendas[0].selected;
      if(allGoals[index].agendas[0].selected) {
        let selectedAgendas = [];
        for(var i = 0; i < allGoals[index].agendas.length; i++) {
          selectedAgendas.push(allGoals[index].agendas[i]._id);
        }
        allGoals[index].selectedAgendas = selectedAgendas;
      } else {
        allGoals[index].selectedAgendas = [];
      }
    } else {
      if(allGoals[index].selectedAgendas.indexOf(e.target.value) > -1) {
        allGoals[index].selectedAgendas.splice(allGoals[index].selectedAgendas.indexOf(e.target.value), 1);
        allGoals[index].agendas[0].selected = false;
      } else {
        allGoals[index].selectedAgendas.push(e.target.value);
        if(allGoals[index].selectedAgendas.length === allGoals[index].agendas.length - 1) allGoals[index].agendas[0].selected = true;
      }
    }
    this.setState({ allGoals });
  };

  focalGoals = e => {
    let focalGoals = this.state.focalGoals;
    if (focalGoals.indexOf(e.target.value) === -1) {
      focalGoals.push(e.target.value);
    } else {
      focalGoals.splice(focalGoals.indexOf(e.target.value), 1);
    }
    this.setState({
      focalGoals
    });
  };

  textarea = e => {
    this.setState({
      csrVision: e.target.value
    });
  };

  saveData = () => {
    let allGoals = this.state.allGoals;
    let agendasErrorInGoal = false;
    let selectedGoals = [], selectedAgendas = [];
    if(allGoals[0].selected) {
      selectedGoals = -1;
      selectedAgendas = [];
    } else {
      for(var i = 1; i < allGoals.length; i++) {
        if(allGoals[i].selected) {
          selectedGoals.push(allGoals[i]._id);
          if(allGoals[i].selectedAgendas.length === 0) agendasErrorInGoal = true;
          selectedAgendas = [...selectedAgendas, ...allGoals[i].selectedAgendas];
        }
      }
    }
    if ((selectedGoals.length || selectedGoals === -1) && (selectedAgendas.length || selectedGoals === -1) && (this.state.focalGoals.length || this.state.focalGoals === -1) && !this.state.errorInGoal.length && !agendasErrorInGoal) {
      let data = {};
      if (this.state.focalGoals.length) {
        data.focalGoals = this.state.focalGoals;
      } else if (this.state.focalGoals === -1) {
        data.focalGoals = -1
      }
      data.goals = selectedGoals;
      data.agendas = selectedAgendas;
      data.csrVision = this.state.csrVision;
      let companyId = JSON.parse(readCookie("userData")).organisationData.id;
      fetch(process.env.REACT_APP_API_URL + "/company/" + companyId + "/mis/preferences", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Auth: JSON.parse(readCookie("access_token")).access_token
        },
        body: JSON.stringify(data)
      }).then(function (response) {
        return response.json();
      }).then(function (data) {
        if (data.status === "ok") {
          this.notify("Your Preferences has been saved!");
          ReactGA.event({
            category: 'Csr Strategy',
            action: 'edit',
            label: 'Saved',
            value: 1
          });
        }
      }.bind(this));
    } else {
      this.notifyError("Mandatory values are missing!");
    }
  };

  notify = text =>
    toast.success(text, {
      onClose: this.next(this)
    });

  notifyError = text => toast.error(text);

  next = () => {
    var that = this;
    ReactGA.event({
      category: 'Csr Strategy',
      action: 'edit',
      label: 'Next',
      value: 1
    });
    setTimeout(function () {
      that.props.next();
    }, 1000);
  };

  render() {
    if (this.state.allGoals !== null && this.state.allAgendas !== null && this.state.selectedGoals !== null && this.state.selectedAgendas !== null && this.state.focalGoals !== null) {
      return (
        <div className="mis-content-container-s1">
          <div className="s1-header-container">
            <h1 className="s1-header">
              Select the causes and goals which align with your organization
            </h1>
          </div>
          <div className="s1-info-container">
            <ToastContainer
              position="bottom-center"
              type="success"
              autoClose={4000}
              hideProgressBar={true}
              closeOnClick={false}
              newestOnTop={false}
              pauseOnHover={false}
            />
            <div className="s1-info-1">
              <div className="s1-info-1-header">
                <div className="s1-info-1-point" />
                <h4 className="s1-info-1-heading">
                  Select Preferred Goals and Agendas <span className="astris">*</span>
                </h4>
              </div>
              <div className="s1-info-1-container">
                <h4 className="goals-heading">Goals :</h4>
                <div className="goal-container">
                  {this.state.allGoals.map((goal, index) => {
                    return (
                      <div className="goal_agenda" key={goal._id} id={goal._id}>
                        <div className="individual-goal-container">
                          {goal._id !== -1 ? (
                            <div className="goal-icon"
                              style={{
                                backgroundColor: goal.backgroundColor,
                                backgroundImage: "url('https://le-uploaded-image-bucket.s3.amazonaws.com/" + goal.icon + "')"
                              }}
                            />
                          ) : (null)}

                          {goal._id === -1 ? (
                            <Checkbox className="goalCheckbox" value={goal._id} onChange={this.changeSelect.bind(this, index)} checked={goal.selected} disabled={JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'} />
                          ) : (
                            <Checkbox className="goalCheckbox" value={goal._id} onChange={this.changeSelect.bind(this, index)} disabled={this.state.allGoals[0].selected || JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'} checked={goal.selected} />
                          )}

                          <h3 className="goalName">{goal.name}</h3>
                          {goal._id !== -1 && goal.selected && !goal.selectedAgendas.length ? (
                            <div style={{ color: 'red', marginLeft: 10 }}>{'( Select atleast one agenda )'}</div>
                          ) : (null)}
                        </div>
                        {goal._id !== -1 && goal.selected ? (
                          <div className="agendas">
                            <h4 className="agenda-heading">Agendas :</h4>
                            <div className="agenda-container">
                              {goal.agendas.map((agenda, agendaIndex) => {
                                return (
                                  <div className="individual-agenda-container">
                                    {agenda._id === -1 ? (
                                      <Checkbox className="agendaCheckbox" type="checkbox" checked={agenda.selected} value={agenda._id} onChange={this.agendaSelect.bind(this, index)} disabled={JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'} />
                                    ) : (
                                      <Checkbox className="agendaCheckbox" type="checkbox" checked={goal.selectedAgendas.indexOf(agenda._id) > -1 ? true : false} value={agenda._id} onChange={this.agendaSelect.bind(this, index)} disabled={JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'} />
                                    )}
                                    <h4 className="agendaName">
                                      {agenda.name}
                                    </h4>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        ) : (null)}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="s1-info-2">
              <div className="s1-info-2-header">
                <div className="s1-info-2-point" />
                <h4 className="s1-info-2-heading">
                  Select decision-makers and stakeholders involved in deciding Goals
                  <br />
                  (Select all that apply) <span className="astris">*</span>
                </h4>
              </div>
              <div className="s1-info-2-container">
                {this.state.focalGoals === -1 ? (
                  <ul className="s1-info-2-ul">
                    {focalGoals.map((focalGoal, index) => {
                      return (
                        <li className="s1-info-2-li">
                          <Checkbox checked={false} disabled={this.state.noFocals} onChange={this.focalGoals} className="s1-info-2-li-input" value={focalGoal} disabled={JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'} />
                          <h3 className="s1-info-2-li-lable">{focalGoal}</h3>
                        </li>
                      )
                    })}
                  </ul>
                ) : (
                  <ul className="s1-info-2-ul">
                    {focalGoals.map((focalGoal, index) => {
                      return (
                        <li className="s1-info-2-li" key={index}>
                          <Checkbox checked={this.state.focalGoals.indexOf(focalGoal) > -1 ? true : false} onChange={this.focalGoals} className="s1-info-2-li-input" value={focalGoal} disabled={JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'} />
                          <h3 className="s1-info-2-li-lable">{focalGoal}</h3>
                        </li>
                      )
                    })}
                  </ul>
                )}
                <li className="s1-info-2-li" style={{ listStyle: 'none', marginLeft: '15px', display: 'flex' }}>
                  <Checkbox onChange={this.focalGoals} className="s1-info-2-li-input" type="checkbox" value="others"
                    checked={this.state.focalGoals.indexOf("others") > -1 ? true : false} disabled={JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'} />
                  <h3 className="s1-info-2-li-lable" style={{ fontSize: '16px', marginLeft: '8px', paddingTop: '2px', fontWeight: 'normal' }}>Others</h3>
                </li>
              </div>
            </div>
            <div className="s1-info-3">
              <div className="s1-info-3-header">
                <div className="s1-info-3-point" />
                <h4 className="s1-info-3-heading">
                  Articulate your CSR vision
                </h4>
              </div>
              <div className="s1-info-3-container">
                <textarea value={this.state.csrVision} onChange={this.textarea} className="textarea"
                  disabled={JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'}
                  rows="4" cols="50" placeholder="Enter your CSR Vision statement here" />
              </div>
            </div>
            <div className="s1-buttons">
              <div className="left-btn">
                <button onClick={this.next} className="s1-next">
                  {JSON.parse(readCookie('userData')).authorizedFor.mis !== 'view' ? ('Continue without saving') : ('Next')}
                </button>
              </div>
              {JSON.parse(readCookie('userData')).authorizedFor.mis !== 'view' ? (
                <div className="right-btn">
                  <button className="s1-save_next" onClick={this.saveData}>
                    Save & Next
                  </button>
                </div>
              ) : (null)}
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default SelectTheCause;
