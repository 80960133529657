import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';

export default class LandingTestimonial extends Component {

	render() {
		return (
			<div className="landing-testimonial-container white-bg">
				<div className="container">
					<div className="flex-container">
						<div className="flexslider">
							<ul className="slides">
								<li>
									<Row className="testimonial-parent">
										<Col md={5} sm={12} className="landing-testimonial-user">
											<img src={process.env.PUBLIC_URL + '/img/user.svg'} alt=""/>
										</Col>
										<Col md={5} sm={12} className="landing-testimonial-text">
											<div className="testimonial-text">
												<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ullamcorper augue neque consequat aptent ad: Sagittis sagittis facilisis odio eget semper cursus: Adipiscing platea cursus orci non justo? Pulvinar nisl nostra accumsan blandit magna felis tortor? Curabitur aenean est... Primis habitasse blandit dis feugiat?</p>
											</div>
											<div className="testimonial-writer">
												<div className="testimonial-writer-name">Subham Chatterjee</div>
												<div className="testimonial-writer-job">Frontend Developer, LetsEndorse</div>
											</div>
										</Col>
									</Row>
								</li>
								<li>
									<Row className="testimonial-parent">
										<Col md={5} sm={12} className="landing-testimonial-user">
											<img src={process.env.PUBLIC_URL + '/img/user3.svg'} alt=""/>
										</Col>
										<Col md={5} sm={12} className="landing-testimonial-text">
											<div className="testimonial-text">
												<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ullamcorper augue neque consequat aptent ad: Sagittis sagittis facilisis odio eget semper cursus: Adipiscing platea cursus orci non justo? Pulvinar nisl nostra accumsan blandit magna felis tortor? Curabitur aenean est... Primis habitasse blandit dis feugiat?</p>
											</div>
											<div className="testimonial-writer">
												<div className="testimonial-writer-name">Monika Shukla</div>
												<div className="testimonial-writer-job">Co-Founder, LetsEndorse</div>
											</div>
										</Col>
									</Row>
								</li>
								<li>
									<Row className="testimonial-parent">
										<Col md={5} sm={12} className="landing-testimonial-user">
											<img src={process.env.PUBLIC_URL + '/img/user2.svg'} alt=""/>
										</Col>
										<Col md={5} sm={12} className="landing-testimonial-text">
											<div className="testimonial-text">
												<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ullamcorper augue neque consequat aptent ad: Sagittis sagittis facilisis odio eget semper cursus: Adipiscing platea cursus orci non justo? Pulvinar nisl nostra accumsan blandit magna felis tortor? Curabitur aenean est... Primis habitasse blandit dis feugiat?</p>
											</div>
											<div className="testimonial-writer">
												<div className="testimonial-writer-name">Varun Kashyap</div>
												<div className="testimonial-writer-job">Co-Founder, LetsEndorse</div>
											</div>
										</Col>
									</Row>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		);
	}
}