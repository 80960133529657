import React,{ Component } from 'react';
import {OverlayTrigger, Tooltip, Col, Row} from 'react-bootstrap';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import moment from 'moment';
import $ from 'jquery';
import { LocaleProvider } from 'antd';
import enUS from 'antd/lib/locale-provider/en_US';
import {BootstrapTable, TableHeaderColumn, ButtonGroup} from 'react-bootstrap-table';
import Multiselect from 'react-bootstrap-multiselect';
import Icon from 'react-icons-kit';
import { ic_done } from 'react-icons-kit/md/ic_done';
import { ic_delete } from 'react-icons-kit/md/ic_delete';
import { DatePicker, Select } from 'antd';

const Option = Select.Option;

const readCookie = require('../../../cookie.js').readCookie;

export default class CostTable extends Component {
	render() {
		return (
			<Row className="cost-table mt20">
				<Col md={3} className="text-center header">Amount Invested (INR)</Col>
				<Col md={3} className="text-center header">Date of Investment</Col>
				<Col md={3} className="text-center header">Choose Amount Breakup</Col>
				<Col md={2} className="text-center header">Comment</Col>
				<Col md={1} className="text-center header">Mode</Col>
				<Row>
					{this.props.costAllocation.map(function(cost, index) {
						return <Col key={index} md={12} className="cost-allocated">
							<Col md={3} className="text-center">
								<span>{cost.amount}</span>
							</Col>
							<Col md={3} className="text-center">
								<span>{cost.date}</span>
							</Col>
							<Col md={3} className="text-center">
								{cost.amountBreakups.map(function(amountBreakup, index2) {
									if(index2 === cost.amountBreakups.length - 1) return <span>{amountBreakup.value}</span>;
									else return <span>{amountBreakup.value}</span>
								})}
							</Col>
							<Col md={2} className="text-center">
								<span>{cost.comment}</span>
							</Col>
							<Col md={1} className="text-center">
								<span>{cost.mode}</span>
							</Col>
						</Col>
					}.bind(this))}
				</Row>
			</Row>
		);
	}
}