import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import $ from 'jquery';
import moment from 'moment';

import ExpressInterest from './ExpressInterest';

export default class ExpressInterestModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			unavailableDates: [],
			datesReady: false
		}
	}

	componentDidMount() {
		if(this.props.dateType !== 'unavailable') {
			var availableDates = this.props.availableDates;
			var unavailableDates = [];

			for(var i = 0; i < 60; i++) {
				var day = moment().add(i, 'days').format('DD/MM/YYYY');
				unavailableDates.push(day);
				for(var j = 0; j < availableDates.length; j++) {
					if(availableDates[j] === day) {
						unavailableDates.splice(unavailableDates.length - 1, 1);
						availableDates.splice(j, 1);
						break;
					}
				}
			}

			this.setState({ unavailableDates }, function() {
				this.updateDatesToMDY(this);
			});
		} else {
			this.setState({ unavailableDates: this.props.unavailableDates }, function() {
				this.updateDatesToMDY(this);
			});
		}
	}

	updateDatesToMDY() {
		let unavailableDates = this.state.unavailableDates;
		for(var i = 0; i < unavailableDates.length; i++) {
			var x = unavailableDates[i];
			var y = x.split('/');
			y[0] = y.splice(1, 1, y[0])[0];
			x = y.join('/')
			unavailableDates[i] = new Date(x);
		}

		var maxMinObj = {};
		maxMinObj['before'] = new Date();
		maxMinObj['after'] = new Date(new Date().setDate(new Date().getDate() + 59));
		unavailableDates.push(maxMinObj);

		this.setState({ unavailableDates, datesReady: true })
	}

	render() {
		if(this.state.datesReady) {
			return (
				<div>
					<ExpressInterest volunteerOpportunityId={this.props.volunteerOpportunityId}
					                 unavailableDates={this.state.unavailableDates}
					                 showExpressInterestModal={this.props.showExpressInterestModal}
					                 onExpressInterestModalClose={this.props.onExpressInterestModalClose} />
				</div>
			);
		} else {
			return null;
		}
	}
}