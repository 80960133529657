import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';
import Slider, { createSliderWithTooltip } from 'rc-slider';
import { ToastContainer, toast } from "react-toastify";
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip, Legend, Label, Sector } from 'recharts';
import ReactGA from 'react-ga';

import { readCookie } from "../../../../cookie";

const SliderWithTooltip = createSliderWithTooltip(Slider);

let year = moment().year();
const years = [];
for (var i = -3; i < 10; i++) {
	var yearStr = (year - i) + '-' + (year - i + 1);
	years.push(yearStr);
}

const tipProps = {
	placement: 'bottom',
	prefixCls: 'preferences-slider-tooltip rc-slider-tooltip',
}

const invalidChars = ["-", "+", "e"];

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
    fill, payload, percent, value, totalAmount } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 8;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <Sector cx={cx} cy={cy} innerRadius={innerRadius} outerRadius={outerRadius} startAngle={startAngle} endAngle={endAngle} fill={fill} />
      <Sector cx={cx} cy={cy} fill={fill} startAngle={startAngle} endAngle={endAngle} innerRadius={outerRadius + 6} outerRadius={outerRadius + 10} />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none"/>
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none"/>
      <text scaleToFit x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#444">
      	{'INR ' + window.inrFormat(Math.round(value / 100 * totalAmount))}
      </text>
      <text scaleToFit x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#444">
        {`${value}% of total`}
      </text>
    </g>
  );
};

export default class FiscalYearBudgeting extends Component {
	constructor(props) {
		super(props);
		this.state = {
			csrBudgets: [],
			csrBudget: null,
			selectedCSRBudgetIndex: null,
			QueryYear: null,
			activeIndex: 0
		}
	}

	componentDidMount() {
		let companyId = JSON.parse(readCookie('userData')).organisationData.id;
		fetch(process.env.REACT_APP_API_URL + '/company/' + companyId + '/mis/preferences', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
		.then((data) => {
			let csrBudgets = [];
			let selectedCSRBudgetIndex = 0;
			let preferences = data.data;

			if (preferences.hasOwnProperty('csrBudget')) {
				for (var i = 0; i < preferences.csrBudget.length; i++) {
					let currentYearBudget = {};
					if (parseInt(preferences.csrBudget[i].year) === parseInt(this.getCurrentFiscalYear().split('-')[0])) selectedCSRBudgetIndex = i;
					currentYearBudget['year'] = preferences.csrBudget[i].year + '-' + (preferences.csrBudget[i].year + 1);
					currentYearBudget['amount'] = preferences.csrBudget[i].amount;
					currentYearBudget['communityInvestmentPercentage'] = preferences.csrBudget[i].communityInvestment;
					// currentYearBudget['volunteerEngagementPercentage'] = preferences.csrBudget[i].volunteerEngagement;
					// currentYearBudget['trainingAndDevelopmentPercentage'] = preferences.csrBudget[i].trainingAndDevelopment;
					currentYearBudget['capacityBuildingAndOtherExpenses'] = preferences.csrBudget[i].capacityBuildingAndOtherExpenses;
					csrBudgets.push(currentYearBudget);
				}
				if (preferences.csrBudget.length === 0) {
					let currentYearBudget = {};
					currentYearBudget['year'] = this.getCurrentFiscalYear();
					currentYearBudget['amount'] = 0;
					currentYearBudget['communityInvestmentPercentage'] = 0;
					// currentYearBudget['volunteerEngagementPercentage'] = 0;
					// currentYearBudget['trainingAndDevelopmentPercentage'] = 0;
					currentYearBudget['capacityBuildingAndOtherExpenses'] = 100;
					csrBudgets.push(currentYearBudget);
				}
			} else {
				let currentYearBudget = {};
				currentYearBudget['year'] = this.getCurrentFiscalYear();
				currentYearBudget['amount'] = 0;
				currentYearBudget['communityInvestmentPercentage'] = 0;
				// currentYearBudget['volunteerEngagementPercentage'] = 0;
				// currentYearBudget['trainingAndDevelopmentPercentage'] = 0;
				currentYearBudget['capacityBuildingAndOtherExpenses'] = 100;
				csrBudgets.push(currentYearBudget);
			}
			let QueryYear = sessionStorage.getItem("selectedFiscalYear")
			sessionStorage.removeItem("selectedFiscalYear");
			if (QueryYear === null) {
				this.setState({ QueryYear: this.getCurrentFiscalYear() });
			} else {
				this.setState({ QueryYear	});
				let found = false;
				let foundIndex = -1;
				for (var i = 0; i < csrBudgets.length; i++) {
					if(csrBudgets[i].year === QueryYear) {
						found = true;
						foundIndex = i;
						break;
					}
				}
				if(!found) {
					let csrBudget = {};
					csrBudget['year'] = QueryYear;
					csrBudget['amount'] = 0;
					csrBudget['communityInvestmentPercentage'] = 0;
					// csrBudget['volunteerEngagementPercentage'] = 0;
					// csrBudget['trainingAndDevelopmentPercentage'] = 0;
					csrBudget['capacityBuildingAndOtherExpenses'] = 100;
					csrBudgets.push(csrBudget);
					foundIndex = csrBudgets.length - 1;
					selectedCSRBudgetIndex = csrBudgets.length - 1
				}
			}

			this.setState({ csrBudgets, csrBudget: csrBudgets[selectedCSRBudgetIndex], selectedCSRBudgetIndex });
		});
	}

	changeYear = (year) => {
		let csrBudgets = this.state.csrBudgets;
		let found = false;
		let foundIndex = -1;
		for (var i = 0; i < csrBudgets.length; i++) {
			if(csrBudgets[i].year === year.target.value) {
				found = true;
				foundIndex = i;
				break;
			}
		}
		if(!found) {
			let csrBudget = {};
			csrBudget['year'] = year.target.value;
			csrBudget['amount'] = 0;
			csrBudget['communityInvestmentPercentage'] = 0;
			// csrBudget['volunteerEngagementPercentage'] = 0;
			// csrBudget['trainingAndDevelopmentPercentage'] = 0;
			csrBudget['capacityBuildingAndOtherExpenses'] = 100;
			csrBudgets.push(csrBudget);
			foundIndex = csrBudgets.length - 1;
			this.setState({ csrBudgets, csrBudget, selectedCSRBudgetIndex: csrBudgets.length });
		} else {
			if(csrBudgets[foundIndex].communityInvestmentPercentage === undefined){
				csrBudgets[foundIndex].communityInvestmentPercentage = 0;
			}
			// if(csrBudgets[foundIndex].volunteerEngagementPercentage === undefined){
			// 	csrBudgets[foundIndex].volunteerEngagementPercentage = 0;
			// }
			// if(csrBudgets[foundIndex].trainingAndDevelopmentPercentage === undefined){
			// 	csrBudgets[foundIndex].trainingAndDevelopmentPercentage = 0;
			// }
			if(csrBudgets[foundIndex].capacityBuildingAndOtherExpenses === undefined){
				csrBudgets[foundIndex].capacityBuildingAndOtherExpenses = 100;
			}
		}
		this.setState({ selectedCSRBudgetIndex: foundIndex, csrBudget: csrBudgets[foundIndex], QueryYear: year.target.value });
	}

	getCurrentFiscalYear() {
    var today = new Date();
    var curMonth = today.getMonth();
    var fiscalYr = "";
    if (curMonth > 3) {
      return today.getFullYear().toString() + '-' + (today.getFullYear() + 1).toString();
    } else {
      return (today.getFullYear() - 1).toString() + '-' + today.getFullYear().toString();
    }
  }

	changeAmount = (amount) => {
		if (invalidChars.includes(amount.key)) {
    	amount.preventDefault();
  	}
		if(amount.target.value !== '' && amount.target.value !== 0 && amount.target.value !== '0'){
			let csrBudgets = this.state.csrBudgets;
			let selectedCSRBudgetIndex = this.state.selectedCSRBudgetIndex;
			if(selectedCSRBudgetIndex !== null) {
				csrBudgets[selectedCSRBudgetIndex].amount = amount.target.value;
				if(csrBudgets[selectedCSRBudgetIndex].capacityBuildingAndOtherExpenses === undefined) {
					if(csrBudgets[selectedCSRBudgetIndex].communityInvestmentPercentage === undefined 
						// && csrBudgets[selectedCSRBudgetIndex].trainingAndDevelopmentPercentage === undefined
						//  && csrBudgets[selectedCSRBudgetIndex].volunteerEngagementPercentage === undefined
						 ) 
						 csrBudgets[selectedCSRBudgetIndex].capacityBuildingAndOtherExpenses = 100;
					else csrBudgets[selectedCSRBudgetIndex].capacityBuildingAndOtherExpenses = 0;
				}
				if(csrBudgets[selectedCSRBudgetIndex].communityInvestmentPercentage === undefined) csrBudgets[selectedCSRBudgetIndex].communityInvestmentPercentage = 0;
				// if(csrBudgets[selectedCSRBudgetIndex].trainingAndDevelopmentPercentage === undefined) csrBudgets[selectedCSRBudgetIndex].trainingAndDevelopmentPercentage = 0;
				// if(csrBudgets[selectedCSRBudgetIndex].volunteerEngagementPercentage === undefined) csrBudgets[selectedCSRBudgetIndex].volunteerEngagementPercentage = 0;
				this.setState({ csrBudgets, csrBudget: csrBudgets[selectedCSRBudgetIndex] });
			}
		} else if(amount.target.value === '' || amount.target.value === 0 || amount.target.value === '0') {
			let csrBudgets = this.state.csrBudgets;
			let selectedCSRBudgetIndex = this.state.selectedCSRBudgetIndex;
			if(selectedCSRBudgetIndex !== null) {
				csrBudgets[selectedCSRBudgetIndex].amount = amount.target.value;
				csrBudgets[selectedCSRBudgetIndex].communityInvestmentPercentage = 0;
				// csrBudgets[selectedCSRBudgetIndex].volunteerEngagementPercentage = 0;
				// csrBudgets[selectedCSRBudgetIndex].trainingAndDevelopmentPercentage = 0;
				csrBudgets[selectedCSRBudgetIndex].capacityBuildingAndOtherExpenses= 100;
				this.setState({ csrBudgets, csrBudget: csrBudgets[selectedCSRBudgetIndex] });
			}
		}
	}

	communityInvestmentPercentageChange = (value) => {
		if(this.state.csrBudget.amount > 0) {
			let csrBudgets = this.state.csrBudgets;
			let selectedCSRBudgetIndex = this.state.selectedCSRBudgetIndex;
			if (selectedCSRBudgetIndex !== null) {
				// if(csrBudgets[selectedCSRBudgetIndex].trainingAndDevelopmentPercentage === undefined) csrBudgets[selectedCSRBudgetIndex].trainingAndDevelopmentPercentage = 0;
				// if(csrBudgets[selectedCSRBudgetIndex].volunteerEngagementPercentage === undefined) csrBudgets[selectedCSRBudgetIndex].volunteerEngagementPercentage = 0;
				let totalPercentageAllocated = 
				// csrBudgets[selectedCSRBudgetIndex].volunteerEngagementPercentage 
				// + csrBudgets[selectedCSRBudgetIndex].trainingAndDevelopmentPercentage + 
				value;
				if(totalPercentageAllocated <= 100) {
					csrBudgets[selectedCSRBudgetIndex].communityInvestmentPercentage = value;
					csrBudgets[selectedCSRBudgetIndex].capacityBuildingAndOtherExpenses = 100 - totalPercentageAllocated;
					this.setState({ csrBudgets, csrBudget: csrBudgets[selectedCSRBudgetIndex] });
				}
			}
		} else {
			// this.notifyError("Please, Set the budget amount");
		}
	}

	volunteerEngagementPercentageChange = (value) => {
		if(this.state.csrBudget.amount > 0) {
			let csrBudgets = this.state.csrBudgets;
			let selectedCSRBudgetIndex = this.state.selectedCSRBudgetIndex;
			if(selectedCSRBudgetIndex !== null) {
				if(csrBudgets[selectedCSRBudgetIndex].communityInvestmentPercentage === undefined) csrBudgets[selectedCSRBudgetIndex].communityInvestmentPercentage = 0;
				// if(csrBudgets[selectedCSRBudgetIndex].trainingAndDevelopmentPercentage === undefined) csrBudgets[selectedCSRBudgetIndex].trainingAndDevelopmentPercentage = 0;
				// let totalPercentageAllocated = csrBudgets[selectedCSRBudgetIndex].communityInvestmentPercentage + csrBudgets[selectedCSRBudgetIndex].trainingAndDevelopmentPercentage + value;
				if (totalPercentageAllocated <= 100) {
					csrBudgets[selectedCSRBudgetIndex].volunteerEngagementPercentage = value;
					csrBudgets[selectedCSRBudgetIndex].capacityBuildingAndOtherExpenses = 100 - totalPercentageAllocated;
					this.setState({ csrBudgets, csrBudget: csrBudgets[selectedCSRBudgetIndex] });
				}
			}
		} else {
			// this.notifyError("Please, Set the budget amount");
		}
	}

	trainingAndDevelopmentPercentageChange = (value) => {
		if (this.state.csrBudget.amount > 0) {
			let csrBudgets = this.state.csrBudgets;
			let selectedCSRBudgetIndex = this.state.selectedCSRBudgetIndex;
			if (selectedCSRBudgetIndex !== null) {
				if(csrBudgets[selectedCSRBudgetIndex].communityInvestmentPercentage === undefined) csrBudgets[selectedCSRBudgetIndex].communityInvestmentPercentage = 0;
				// if(csrBudgets[selectedCSRBudgetIndex].volunteerEngagementPercentage === undefined) csrBudgets[selectedCSRBudgetIndex].volunteerEngagementPercentage = 0;
				let totalPercentageAllocated = csrBudgets[selectedCSRBudgetIndex].communityInvestmentPercentage 
				// + csrBudgets[selectedCSRBudgetIndex].volunteerEngagementPercentage 
				+ value;
				if(totalPercentageAllocated <= 100) {
					// csrBudgets[selectedCSRBudgetIndex].trainingAndDevelopmentPercentage = value;
					csrBudgets[selectedCSRBudgetIndex].capacityBuildingAndOtherExpenses = 100 - totalPercentageAllocated;
					this.setState({ csrBudgets, csrBudget: csrBudgets[selectedCSRBudgetIndex] });
				}
			}
		} else {
			// this.notifyError("Please, Set the budget amount");
		}
	}

	saveData = () => {
		if(this.state.csrBudgets.length !== 0) {
			let selectedCSRBudgetIndex = this.state.selectedCSRBudgetIndex;
			if (parseInt(this.state.csrBudgets[selectedCSRBudgetIndex].amount) > 0) {
				ReactGA.event({
				  category: 'CSR Budget',
				  action: 'edit',
				  label: 'Save (' + this.state.csrBudgets[selectedCSRBudgetIndex].year + ')'
				});

				let csrBudget = {}, data = {};
				csrBudget.year = parseInt(this.state.csrBudgets[selectedCSRBudgetIndex].year.split('-')[0]);
				csrBudget.amount = parseInt(this.state.csrBudgets[selectedCSRBudgetIndex].amount);
				csrBudget.communityInvestment = this.state.csrBudgets[selectedCSRBudgetIndex].communityInvestmentPercentage;
				// csrBudget.volunteerEngagement = this.state.csrBudgets[selectedCSRBudgetIndex].volunteerEngagementPercentage;
				// csrBudget.trainingAndDevelopment = this.state.csrBudgets[selectedCSRBudgetIndex].trainingAndDevelopmentPercentage;
				csrBudget.capacityBuildingAndOtherExpenses= this.state.csrBudgets[selectedCSRBudgetIndex].capacityBuildingAndOtherExpenses;
				data.csrBudget = csrBudget;
				let companyId = JSON.parse(readCookie('userData')).organisationData.id;
				fetch(process.env.REACT_APP_API_URL + '/company/' + companyId + '/mis/preferences', {
					method: "POST",
					headers: {
						'Content-Type': 'application/json',
						'Auth': JSON.parse(readCookie('access_token')).access_token
					},
					body: JSON.stringify(data)
				}).then(function (response) {
					return response.json();
				}).then(function (data) {
					if (data.status === 'ok') {
						this.notify("Your Preferences has been saved!");
					} else {
						this.notifyError("You've entered wrong details, Please try again!");
					}

				}.bind(this));
			}
		}
	}

	notifyError = text => toast.error(text);

	notify = text => toast.success(text);


	gotoHandler = () => {
		// window.location.pathname = '/portfolio';
		window.location.pathname = '/investments';
	}

	onPieEnter(data, index) {
    this.setState({
      activeIndex: index,
    });
  }

	render() {
		if (this.state.csrBudgets.length && this.state.csrBudget !== null) {
			return (
				<div className="mis-content-container">
					<Col md={12} className="fiscalYear-main-container p0">
						<ToastContainer
							position="bottom-center"
							type="success"
							autoClose={4000}
							hideProgressBar={true}
							closeOnClick={false}
							newestOnTop={false}
							pauseOnHover={false}
						/>
						<Col className="mb20 mt20 fiscalYear-chart-container">
							<Col className="m0 form-group fiscalYear-header" md={12}>
								<h2>Annual CSR Budget</h2>
							</Col>
							<div className="fiscal-chart-details">
								{(() => {
									const chartData = [
										{name: 'Community Investment Allocation', value: this.state.csrBudget.communityInvestmentPercentage, fill: "#0088FE", totalAmount: this.state.csrBudget.amount},
									//   {name: 'Volunteer Engagement Allocation', value: this.state.csrBudget.volunteerEngagementPercentage, fill: "#00C49F", totalAmount: this.state.csrBudget.amount},
									//   {name: 'Training And Development Allocation', value: this.state.csrBudget.trainingAndDevelopmentPercentage, fill: "#FFBB28", totalAmount: this.state.csrBudget.amount},
									  {name: 'Capacity Building And Other Expenses', value: this.state.csrBudget.capacityBuildingAndOtherExpenses, fill: "#FF8042", totalAmount: this.state.csrBudget.amount}
									];
									return (
										<Col className="fiscal-chartdetail-container">
											<Col className="fiscal-budget-inputs p0" md={12}>
												<Row className="fiscal-year-amount">
													<Col className="form-group fiscalyear-select" mdOffset={2} md={6}>
														<Col className="fiscal-selectyear-header">
															<span className="s4-dot" />
															<label className="control-label-fiscaltitle">Select fiscal year </label>
															<span className="astris">*</span>
														</Col>
														{this.state.QueryYear !== null ? (
															<select id="year" 
															value={this.state.QueryYear} 
															onChange={this.changeYear.bind(this)} 
															>
																{years.map(function (year, index) {
																	return (
																		<option key={index} value={year}>{year}</option>
																	)
																})}
															</select>
														) : (
															<select id="year" 
															value={this.state.QueryYear} onChange={this.changeYear.bind(this)} >
																{years.map(function (year, index) {
																	return (
																		<option key={index} value={year}>{year}</option>
																	)
																})}
															</select>
														)}
													</Col>
													<Col className="form-group fiscalamount-select" md={6}>
														<Col className="fiscal-selectamount-header">
															<span className="s4-dot" />
															<label className="control-label-fiscaltitle">Set budget amount </label>
															<span className="astris">*</span>
														</Col>
														{JSON.parse(readCookie('userData')).authorizedFor.mis !== 'view' ? (
															<input type="number" className="fiscal-amount" min={0} onChange={this.changeAmount.bind(this)} value={this.state.csrBudget.amount} />
														) : (
															<input type="number" className="fiscal-amount" min={0} value={this.state.csrBudget.amount} disabled={true} />
														)}
													</Col>
												</Row>
												<Col md={12} className="fiscal-tooltip-container p0">
													<Col className="fiscal-plan-header">
														<span className="s4-dot" />
														<label className="control-label-fiscaltitle">Set your plan of allocation </label>
														<span className="astris">*</span>
													</Col>
													<Col md={6}>
														<Col className="form-group fiscal-tooltip" >
															<div style={{ display: 'flex', alignItems: 'center' }} onClick={this.slider}>
																<div className='label-color-blue' style={{ width: '15px', height: '15px', backgroundColor: '#0088FE' }}></div>
																<label className="control-label chart-control-label"><span className="csr-budget-label csr-budget-comm-inv-label"></span>Percentage allocated to Community Investment:</label>
															</div>
															{JSON.parse(readCookie('userData')).authorizedFor.mis !== 'view' ? (
																<SliderWithTooltip value={this.state.csrBudget.communityInvestmentPercentage} tipProps={tipProps} max={100} onChange={this.communityInvestmentPercentageChange.bind(this)} />
															) : (
																<SliderWithTooltip value={this.state.csrBudget.communityInvestmentPercentage} tipProps={tipProps} max={100} />
															)}
														</Col>
														{/* <Col className="form-group fiscal-tooltip">
															<div style={{ display: 'flex', alignItems: 'center' }}>
																<div className='label-color-yellow' style={{ width: '15px', height: '15px', backgroundColor: '#00C49F' }}></div>
																<label className="control-label chart-control-label"><span className="csr-budget-label csr-budget-vol-eng-label"></span>Percentage allocated to Volunteer Engagement:</label>
															</div>
															{JSON.parse(readCookie('userData')).authorizedFor.mis !== 'view' ? (
																<SliderWithTooltip value={this.state.csrBudget.volunteerEngagementPercentage} tipProps={tipProps} max={100} onChange={this.volunteerEngagementPercentageChange.bind(this)} />
															) : (
																<SliderWithTooltip value={this.state.csrBudget.volunteerEngagementPercentage} tipProps={tipProps} max={100} />
															)}
														</Col> */}
														{/* <Col className="form-group fiscal-tooltip">
															<div style={{ display: 'flex', alignItems: 'center' }}>
																<div className='label-color-green' style={{ width: '15px', height: '15px', backgroundColor: '#FFBB28' }}></div>
																<label className="control-label chart-control-label"><span className="csr-budget-label csr-budget-training-dev-label"></span>Percentage allocated to Training And Development:</label>
															</div>
															{JSON.parse(readCookie('userData')).authorizedFor.mis !== 'view' ? (
																<SliderWithTooltip value={this.state.csrBudget.trainingAndDevelopmentPercentage} tipProps={tipProps} max={100} onChange={this.trainingAndDevelopmentPercentageChange.bind(this)} />
															) : (
																<SliderWithTooltip value={this.state.csrBudget.trainingAndDevelopmentPercentage} tipProps={tipProps} max={100} />
															)}
														</Col> */}
														<Col className="form-group fiscal-tooltip">
															<div style={{ display: 'flex', alignItems: 'center' }}>
																<div className='label-color-orange' style={{ width: '15px', height: '15px', backgroundColor: '#FF8042' }}></div>
																<label className="control-label chart-control-label"><span className="csr-budget-label csr-budget-others-label"></span>Capacity building and other expenses{"(<=5%)"}:</label>
															</div>
															<SliderWithTooltip value={this.state.csrBudget.capacityBuildingAndOtherExpenses} max={100} />
														</Col>
													</Col>
													<Col className="fiscal-budget-chart" md={6}>
														<ResponsiveContainer height={500}>
														  <PieChart width={500} height={500}>
														    <Pie dataKey='value' cx={220} cy={180} data={chartData} outerRadius={60} activeIndex={this.state.activeIndex} activeShape={renderActiveShape} onMouseEnter={this.onPieEnter.bind(this)}>
														      {chartData.map(function(item, index) {
														        return (
														          <Cell fill={item.fill} key={index} />
														        )
														      })}
														    </Pie>
														  </PieChart>
														</ResponsiveContainer>
													</Col>
												</Col>
											</Col>
										</Col>
									)
								})()}
							</div>
						</Col>
						<Col className="buttons-section-fiscal" md={12}>
							<div className="nxt-btn-container-fiscal">
								{JSON.parse(readCookie('userData')).authorizedFor.mis !== 'view' ? (
									<button className="btn-save-next-fiscal" onClick={this.saveData.bind(this)}>Save</button>
								) : (null)}
								{/* <button className="btn-nxt-fiscal" onClick={this.next}>Next</button> */}
								{/*<button className="btn-go-to" onClick={this.gotoHandler}> Go to Goal-wise Investment </button>*/}
								<button className="btn-go-to" onClick={this.gotoHandler}> Go to Investments </button>
							</div>
						</Col>
					</Col>
				</div>
			)
		} else {
			return null;
		}
	}
}
