import React, { Component } from "react";
import { Link } from "react-router-dom";

import SvgIcon from "react-icons-kit";
import { ic_email } from 'react-icons-kit/md/ic_email';
import { basic_mail_multiple } from 'react-icons-kit/linea/basic_mail_multiple';
import { ic_dashboard } from "react-icons-kit/md/ic_dashboard";
import { cubes } from "react-icons-kit/fa/cubes";
import { ic_event_note } from "react-icons-kit/md/ic_event_note";
import { ic_aspect_ratio } from "react-icons-kit/md/ic_aspect_ratio";
import { ic_business } from "react-icons-kit/md/ic_business";
import { ic_business_center } from "react-icons-kit/md/ic_business_center";
import { ic_format_list_bulleted } from "react-icons-kit/md/ic_format_list_bulleted";
import { ic_exit_to_app } from "react-icons-kit/md/ic_exit_to_app";
import { ic_settings } from "react-icons-kit/md/ic_settings";
import { ic_question_answer } from "react-icons-kit/md/ic_question_answer";
import { ic_list } from 'react-icons-kit/md/ic_list';
import { ic_people_outline } from 'react-icons-kit/md/ic_people_outline';
import { ic_beenhere } from 'react-icons-kit/md/ic_beenhere';
import { ic_layers } from 'react-icons-kit/md/ic_layers';
import { ic_trending_up } from 'react-icons-kit/md/ic_trending_up';
import { random } from 'react-icons-kit/iconic/random';
import { office } from 'react-icons-kit/icomoon/office';
import {fileO} from 'react-icons-kit/fa/fileO';
import { ic_assessment } from 'react-icons-kit/md/ic_assessment';
import { ic_directions_bus } from 'react-icons-kit/md/ic_directions_bus';
import { line_graph } from 'react-icons-kit/ikons/line_graph';
import { flag } from 'react-icons-kit/fa/flag';
import { Menu, Icon, Dropdown } from "antd";
import swal from "sweetalert2";
import $ from 'jquery';
import ReactGA from 'react-ga';

import socketIOClient from "socket.io-client";

import Notifications from './Notifications';

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const eraseCookie = require("../../../cookie.js").eraseCookie;
const readCookie = require("../../../cookie.js").readCookie;

const socket = socketIOClient(process.env.REACT_APP_API_URL);

const Icon20 = props => <SvgIcon size={props.size || 20} icon={props.icon} />;


export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: null,
      notifications: [],
      totalNotifications: 0,
      unseenNotifications: 0,
      perPage: 4,
      page: 1,
      screenWidth:"",
      showMenu: false
    };
  }

  componentWillMount() {
    this.setState({screenWidth: window.innerWidth})
    let userData = JSON.parse(readCookie("userData"));
    this.setState({ userData });
    $(document).ready(function () {
      var userDivHeight = $('.mis-header-container div.mis-header-user').height();
      $('.mis-header-container ul#mis-navbar').css('max-height', 'calc(100% - ' + (userDivHeight + 185) + 'px)')
    });
    let that = this;
    if (!sessionStorage.length || !sessionStorage.getItem('notificationFetched')) {
      // Ask other tabs for session storage
      localStorage.setItem('getSessionStorage', Date.now());
    };
    window.addEventListener('storage', function (event) {
      try {
        if (event && event.key && (event.key === 'getSessionStorage')) {
          // Some tab asked for the sessionStorage -> send it
          localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
          localStorage.removeItem('sessionStorage');
        } else if (event && event.key && (event.key === 'sessionStorage') && !sessionStorage.length) {
          // sessionStorage is empty -> fill it
          var data = JSON.parse(event.newValue), value;
          for (event.key in data) {
            sessionStorage.setItem("key", data[event.key]);
          }
          showSessionStorage();
        }
      }
      catch(e) {
        console.log(e)
      }
    });
    function showSessionStorage() {
      if (!sessionStorage.length || !sessionStorage.getItem('notificationFetched')) {
        fetch(process.env.REACT_APP_API_URL + '/notifications' + "?perPage=" + that.state.perPage, {
          method: "GET",
          headers: {
            'Auth': JSON.parse(readCookie('access_token')).access_token
          }
        }).then(res => res.json())
          .then(res => {
            console.log(res.data);
            if (res.status === "ok") {
              sessionStorage.setItem('notificationFetched', true);
              sessionStorage.setItem('notificationCount', res.data.unseen);
              if (sessionStorage.getItem('notificationCount') > 0) {
                that.setState({ unseenNotifications: sessionStorage.getItem('notificationCount') });
              }
            }
          })
      } else {
        if (sessionStorage.getItem('notificationCount') > 0) {
          that.setState({ unseenNotifications: sessionStorage.getItem('notificationCount') });
        }
      }
    }
    window.addEventListener('load', function () {
      showSessionStorage();
    })
    let token = JSON.parse(readCookie('access_token')).access_token;
    socket.on('connect', function () {
      socket.emit('authentication', { token: token });
      socket.on('authenticated', function () {
        socket.on('notificationsCount', (count) => {
          that.setState({ unseenNotifications: count.count });
          sessionStorage.setItem('notificationCount', count.count);
        });
      });
    });
  }

  logout() {
    eraseCookie("userData");
    eraseCookie("access_token");
    localStorage.removeItem("investmentsWarning");
    localStorage.removeItem("goalPortfolioWarning");
    localStorage.removeItem("individualGoalPortfolioWarning");
    window.location.pathname = "/";
  }

  gotoPage(key, page) {
    ReactGA.event({
      category: 'Sidebar',
      action: 'click',
      label: key
    });
    window.location.pathname = page;
  }

  handleClick(e) {
    if (e.key === 'projects') this.gotoPage(e.key, '/discover/projects');
    else if (e.key === 'invite-ngo') this.gotoPage(e.key, '/discover/partner-projects');
    else if (e.key === 'partnerProjects') this.gotoPage(e.key, '/all-projects');
    else if (e.key === 'fiscal-year-budgeting') this.gotoPage(e.key, '/fiscal-year-budgeting');
    else if (e.key === 'goal-plan') this.gotoPage(e.key, '/investment-goal-plan');
    else if (e.key === 'innovative-models') this.gotoPage(e.key, '/discover/innovative-models');
    else if (e.key === 'dashboard') this.gotoPage(e.key, '/dashboard');
    else if (e.key === 'csr-portfolio') this.gotoPage(e.key, '/portfolio');
    else if (e.key === 'investments') this.gotoPage(e.key, '/investments');
    else if (e.key === 'discussion') this.gotoPage(e.key, '/discussion');
    else if (e.key === 'emails-repo') this.gotoPage(e.key, '/organizer');
    else if (e.key === 'indicators') this.gotoPage(e.key, '/indicators');
    else if (e.key === 'trends') this.gotoPage(e.key, '/trends');
    else if (e.key === 'walkthrough') this.gotoPage(e.key, '/walkthrough');
    else if (e.key === 'strategy') this.gotoPage(e.key, '/csr-strategy-setup');
    else if (e.key === "csr-corner") this.gotoPage(e.key, "/csr-corner");
    else if (e.key === "director-report") this.gotoPage(e.key, "/directors-report");
    else if (e.key === 'messages') this.gotoPage(e.key, '/discussion/ngo_id/messages');
    else if (e.key === 'preferences') this.gotoPage(e.key, '/preferences');
  }
  click(key) {
    fetch(process.env.REACT_APP_API_URL + "/notifications/mark-seen", {
      method: "GET",
      headers: {
        'Auth': JSON.parse(readCookie('access_token')).access_token
      }
    }).then(data => data.json()).then(data => {
    })
    sessionStorage.setItem('notificationCount', 0);
    if (key === 'settings') this.gotoPage(key, '/settings');
    if (key === 'notifications') this.gotoPage(key, '/notifications');
    else if (key === 'logout') {
      ReactGA.event({
        category: 'Sidebar',
        action: 'click',
        label: 'logout'
      });
      swal({
        title: "Are you sure you want to logout?",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Logout"
      }).then(function (result) {
        if (result.hasOwnProperty('value') && result.value) this.logout();
      }.bind(this));
    }
  }

  userProfile(e) {
    ReactGA.event({
      category: 'Sidebar',
      action: 'click',
      label: 'user-profile'
    });
    window.location.pathname = "/settings";
  }

  render() {

    let defaultOpenKeys = [];

    if (this.props.selected === 'innovative-models' || this.props.selected === 'projects' || this.props.selected === 'invite-ngo') defaultOpenKeys[0] = 'investment-plan';

    if (this.state.userData !== null) {
      return (
        <div className="mis-header-container">
          {this.state.screenWidth < 500 ? (
            <div className='mis-mobile-header-menu'>
              <i className="fa fa-bars" style={{fontSize:"18px"}} onClick={() => this.setState({showMenu: !this.state.showMenu})}></i>
            </div>
          ) : (null)}
          {this.state.showMenu === true || this.state.screenWidth > 500 ? (
            <div className="mis-header">
              <div className="mis-header-user">
                <div style={{ position: "relative" }}>
                  {(() => {
                    if (this.state.userData.hasOwnProperty("profilePicture")) {
                      if (this.state.userData.profilePicture) {
                        return (
                          <img src={this.state.userData.profilePicture} alt={this.state.userData.fullName} />
                        );
                      } else
                        return (
                          <img src="/img/user.png" alt={this.state.userData.fullName} />
                        );
                    } else
                      return (
                        <img src="/img/user.png" alt={this.state.userData.fullName} />
                      );
                  })()}
                  {/*<i className='fa fa-pencil' onClick={this.userProfile.bind(this)}></i>*/}
                </div>
                <div className="mr20">
                  <div>{this.state.userData.fullName}</div>
                  <div>
                    {this.state.userData.designationAtOrganisation ? this.state.userData.designationAtOrganisation : ""}
                  </div>
                </div>
              </div>

              <Menu id="mis-navbar" onClick={this.handleClick.bind(this)} style={{ width: 240 }}
                defaultSelectedKeys={[this.props.selected]} defaultOpenKeys={defaultOpenKeys} mode="inline">
                <Menu.Item className="notification-settings-logout-container">
                  {/* <Dropdown overlay={menu} trigger={['click']}> */}
                  <a className={this.props.selected === "notifications" ? "mis-header-dropdown notification-icon selected-menu-option" : "mis-header-dropdown notification-icon"} onClick={this.click.bind(this, 'notifications')} title="Notifications">
                    <i className="fa fa-bell"></i>
                    {this.state.unseenNotifications > 0 ? (
                      <span className="unseen-notifications-count">{this.state.unseenNotifications}</span>
                    ) : (null)}
                  </a>
                  {/* </Dropdown> */}

                  <a className={this.props.selected === "settings" ? "mis-header-dropdown selected-menu-option" : "mis-header-dropdown"} onClick={this.click.bind(this, 'settings')} title="Settings">
                    <i className="fa fa-cog" style={{fontSize:"16px"}}></i>
                  </a>

                  <a className="mis-header-dropdown" onClick={this.click.bind(this, 'logout')} title="Logout">
                    <i className="fa fa-power-off" style={{ fontSize: "18px" }}></i>
                  </a>
                </Menu.Item>

                <Menu.Item key="dashboard" className="menu-separator">
                  <SvgIcon size={14} icon={ic_dashboard} />
                  <span className="mis-header-menu-text">Dashboard</span>
                </Menu.Item>
                <Menu.Item key="investments" className="menu-separator investment-portfolio-1">
                  <SvgIcon size={14} icon={line_graph} />
                  <span className="mis-header-menu-text">Investment Portfolio</span>
                </Menu.Item>
                <Menu.Item key="projects" className="menu-separator">
                  <SvgIcon size={14} icon={ic_beenhere} />
                  <span className="mis-header-menu-text">Project Pipeline</span>
                </Menu.Item>
                {this.state.userData.authorizedFor.mis !== 'view' ? (
                  <SubMenu key="investment-plan" className="menu-separator"
                    title={
                      <div>
                        <SvgIcon size={14} icon={ic_list} />
                        <span className="mis-header-menu-text">Source Projects</span>
                      </div>
                    }>
                    <Menu.Item key="invite-ngo">
                      <SvgIcon size={14} icon={ic_layers} />
                      <span className="mis-header-menu-text">Solicit from NGO Partners</span>
                    </Menu.Item>
                    <Menu.Item key="innovative-models">
                      <SvgIcon size={14} icon={ic_layers} />
                      <span className="mis-header-menu-text">
                        Adopt Innovations
                        </span>
                    </Menu.Item>
                  </SubMenu>
                ) : (null)}
                {/* <Menu.Item key="investments" className="investment-portfolio-2">
                    <SvgIcon size={14} icon={ic_trending_up} />
                    <span className="mis-header-menu-text">All Investments</span>
                  </Menu.Item>
                </SubMenu> */}
                <Menu.Item key="discussion" className="menu-separator">
                  <SvgIcon size={14} icon={ic_question_answer} />
                  <span className="mis-header-menu-text">Chats</span>
                  {/*<span className="notifyBadge">
                    <p className="badgeText">9+</p>
                  </span>*/}
                </Menu.Item>
                <Menu.Item key="emails-repo" className="menu-separator">
                  <SvgIcon size={14} icon={ic_email} />
                  <span className="mis-header-menu-text">Organizer</span>
                </Menu.Item>
                {/* {this.state.userData.authorizedFor.mis !== 'view'?(<Menu.Item key="indicators" className="menu-separator">
                  <SvgIcon size={14} icon={ic_layers} />
                  <span className="mis-header-menu-text">Indicators</span>
                </Menu.Item>):(null) } */}
                
                <Menu.Item key="fiscal-year-budgeting" className="menu-separator">
                  <SvgIcon size={14} icon={ic_assessment} />
                  <span className="mis-header-menu-text">Annual Budget</span>
                </Menu.Item>
                <Menu.Item key="strategy" className="menu-separator">
                  <SvgIcon size={14} icon={random} />
                  <span className="mis-header-menu-text">CSR Strategy Setup</span>
                </Menu.Item>
                <Menu.Item key="csr-corner" className="menu-separator">
                  <SvgIcon size={14} icon={office} />
                  <span className="mis-header-menu-text">CSR Corner</span>
                </Menu.Item>
                {/* <Menu.Item key="trends" className="menu-separator">
                  <SvgIcon size={14} icon={ic_layers} />
                  <span className="mis-header-menu-text">Trends <i style={{color:"#fff", marginLeft:"6px"}} className="fa fa-lock"></i></span>
                </Menu.Item> */}
                <Menu.Item key="director-report" style={this.props.selected === "directors-report" ? {background: "#ef5a20"} : null}>
                  <SvgIcon size={14} icon={fileO} />
                  <span className="mis-header-menu-text">Directors Report
                  {/* <i style={{color:"#fff", marginLeft:"6px"}} className="fa fa-lock"></i> */}
                  </span>
                </Menu.Item>
                <Menu.Item className='take-tour-btn' key="walkthrough">
                  <SvgIcon size={14} icon={ic_directions_bus} style={{ marginLeft: -5 }} />
                  <span className="mis-header-menu-text">Take A Tour</span>
                </Menu.Item>
                {/*<Menu.Item key="csr-disclosure">
                  <SvgIcon size={14} icon={ic_event_note} />
                  <span className="mis-header-menu-text">CSR Disclosure</span>
                </Menu.Item>*/}
                {/*<Menu.Item key="walkthrough">
                  <SvgIcon size={14} icon={ic_question_answer} />
                  <span className="mis-header-menu-text">Walkthrough</span>
                </Menu.Item>

                <Menu.Item key="strategy">
                  <SvgIcon size={14} icon={ic_question_answer} />
                  <span className="mis-header-menu-text">CSR Strategy Setup</span>
                </Menu.Item>

                <SubMenu key="investment-plan"
                  title={
                    <div>
                      <SvgIcon size={14} icon={ic_question_answer} />
                      <span className="mis-header-menu-text">My Investments</span>
                    </div>
                  }>
                  <Menu.Item key="csr-portfolio">
                    <SvgIcon size={14} icon={ic_business_center} />
                    <span className="mis-header-menu-text">Investment Portfolio</span>
                  </Menu.Item>
                  <Menu.Item key="investments">
                    <SvgIcon size={14} icon={ic_business_center} />
                    <span className="mis-header-menu-text">All Investments</span>
                  </Menu.Item>
                </SubMenu>
                <SubMenu key="old" title={
                    <div>
                      <SvgIcon size={14} icon={ic_business_center} />
                      <span className="mis-header-menu-text">Old</span>
                    </div>
                  }>
                  <SubMenu key="discover" title={
                      <div>
                        <SvgIcon size={14} icon={cubes} />
                        <span className="mis-header-menu-text">Discover</span>
                      </div>
                    }>
                  </SubMenu>
                    <SubMenu key="portfolio" title={
                        <div>
                          <SvgIcon size={14} icon={ic_business_center} />
                          <span className="mis-header-menu-text">Portfolio</span>
                        </div>
                      }>
                        <Menu.Item key="csr-portfolio">
                          <SvgIcon size={14} icon={ic_business_center} />
                          <span className="mis-header-menu-text">CSR Portfolio</span>
                        </Menu.Item>
                        <Menu.Item key="investments">
                          <SvgIcon size={14} icon={ic_business_center} />
                          <span className="mis-header-menu-text">All Investments</span>
                        </Menu.Item>
                        {/*<Menu.Item key="field-partners">
                          <SvgIcon size={14} icon={ic_business_center} />
                          <span className="mis-header-menu-text">Field Partners</span>
                        </Menu.Item>
                    </SubMenu>
                    <Menu.Item key="preferences">
                      <SvgIcon size={14} icon={ic_business} />
                      <span className="mis-header-menu-text">Preferences</span>
                    </Menu.Item>
                </SubMenu>
                <Menu.Item key="settings">
                  <SvgIcon size={14} icon={ic_settings} />
                  <span className="mis-header-menu-text">Settings</span>
                </Menu.Item>
                <Menu.Item key="logout">
                  <SvgIcon size={14} icon={ic_exit_to_app} />
                  <span className="mis-header-menu-text">Logout</span>
                </Menu.Item>*/}
              </Menu>
              <div id="powered-by-container">
                <a href="https://www.letsendorse.com/" target="_blank">
                  Powered By
                  <img
                    src="/img/powered_by_le.png"
                    alt="Powered By LetsEndorse"
                  />
                </a>
              </div>
            </div>
          ):(null)}
        </div>
      );
    } else {
      return null;
    }
  }
}
