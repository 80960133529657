import React, { Component } from "react";
import { Modal, Col, Row } from 'react-bootstrap';
import { Checkbox, Dropdown, Button, Input, AutoComplete } from 'antd';
import swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';

const readCookie = require('../../../cookie.js').readCookie;

const Option = AutoComplete.Option;

export default class InnovationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
      // actionSolicited: [],
      suggestedNgos: [],
      error: [],
      note: '',
      disabledButton:true,
      ngoName :"",
      selectedNgoName:"",
      email:"",
     emailPattern : /^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/
    }
  }

  handleNote = (e) => {
    let error = this.state.error;
    let note = e.target.value;
    if(note !== '') {
     if(error.indexOf('note') !== -1) {
        error.splice(error.indexOf('note'), 1);
      }
    } else {
      if(error.indexOf('note') === -1) {
        error.push('note');
      }
    }
    this.setState({ note, error });
  }

  handleSearch = (index, value, x) => {
     this.setState({ngoName:value})
    if(value.length > 2) {
      let name = value;
      fetch(process.env.REACT_APP_API_URL + '/ngo/search?q=' + value, {
        method: "GET",
        headers: {
          'Auth': JSON.parse(readCookie('access_token')).access_token
        }
      }).then((data) => data.json())
      .then(function(data) {
        console.log("datataaa",data)
        let dataSource = data.data;
        let suggestedNgos = this.state.suggestedNgos;
        if(dataSource.length === 0) {
          suggestedNgos[index]['name'] = value;
          if(suggestedNgos[index].hasOwnProperty('ngoId')) delete suggestedNgos[index]['ngoId'];
          suggestedNgos[index]['email'] = '';
          suggestedNgos[index]['ngoFound'] = false;
          this.setState({ suggestedNgos });
        } else {
          suggestedNgos[index]['name'] = value;
          suggestedNgos[index]['ngoFound'] = true;
          this.setState({ suggestedNgos });
        }
        this.setState({ dataSource });
      }.bind(this));
    }
  }

  onSelect = (index, value, elem) => {

    
    let suggestedNgos = this.state.suggestedNgos;
    let suggestedNgoIds = [];
    for(var i = 0; i < suggestedNgos.length; i++) {
      if(suggestedNgos[i].hasOwnProperty('ngoId')) suggestedNgoIds.push(suggestedNgos[i].ngoId);
    }
    if(!suggestedNgoIds.length || suggestedNgoIds.indexOf(value) === -1) {
      this.setState({selectedNgoName :elem.props.children, ngoName :elem.props.children})
      suggestedNgos[index]['name'] = elem.props.children;
      suggestedNgos[index]['ngoId'] = value;
      suggestedNgos[index]['ngoFound'] = true;
    } else {
      this.notifyError('Please select another NGO partner.');
    }
    this.setState({ suggestedNgos });
  }

  handleUpdate = (name, e) => {
    let value = e.target.value;
    let suggestedNgos = this.state.suggestedNgos;
    this.setState({email:value});
    suggestedNgos[0][name] = value;
    this.setState({ suggestedNgos });
  }

  handleSubmit = () => {
    let suggestedNgos = this.state.suggestedNgos;
    let note = this.state.note;
   

    let data = {};

 if(this.state.ngoName === this.state.selectedNgoName){
  if(note !== '' && suggestedNgos[0].name !== '' && 
  ((suggestedNgos[0].hasOwnProperty('ngoId') && suggestedNgos[0]['ngoId'] !== '') 
  || (suggestedNgos[0].hasOwnProperty('email') && suggestedNgos[0]['email'] !== '' 
  && suggestedNgos[0]['email'].match(this.state.emailPattern) && suggestedNgos[0]['pocName'] !== '' 
  && suggestedNgos[0]['pocContact'] !== ''))) {
    let name = suggestedNgos[0]['name'].split("(");
  suggestedNgos[0]['name'] = name[0]
  data.message = note;
  if(suggestedNgos[0].hasOwnProperty('email')) {
      suggestedNgos[0]['emailOfPOC'] = suggestedNgos[0].email;
      delete suggestedNgos[0]['email'];
    }
    data.suggestedNgos = suggestedNgos;
  let companyId = JSON.parse(readCookie('userData')).organisationData.id;
    fetch(process.env.REACT_APP_API_URL + '/company/' + companyId + '/mis/ngo/invite' , {
      method: "POST",
      headers: {
        'Content-type': 'application/json',
        'Auth': JSON.parse(readCookie('access_token')).access_token
      },
      body: JSON.stringify(data)
    }).then(function(data) {
      return data.json();
    }).then(function(data) {
      if(data.status === 'ok') {
        this.setState({disabledButton:false})
        swal({
          title: 'Saved Successfully.',
          html: data.data,
          type: 'success'
        }).then(function() {
          this.props.updateInvitations();
          window.location.pathname="/discover/partner-projects";
        }.bind(this));
      } else if (data.status === 'error'){
        this.setState({disabledButton:true})
        swal({
          title: "Something Went Wrong",
          html: 'Please retry!',
          type: 'error'
        });
      }
      this.props.handleClose();
    }.bind(this));
  } else {
    this.setState({disabledButton:true})
    this.notifyError('Please enter correct details of the NGO partners.');
  }
 }else {
  this.notifyError('Please choose NGO from the options.');
 }
    
  }

  componentDidMount() {
    this.setState({ suggestedNgos: [ { name: '', ngoFound: null } ] });
    fetch(process.env.REACT_APP_API_URL + '/ngo/search', {
      method: "GET",
      headers: {
        'Auth': JSON.parse(readCookie('access_token')).access_token
      }
    }).then((data) => data.json())
    .then(function(data) {
      let dataSource = data.data;
      this.setState({ dataSource });
    }.bind(this));
  }

  addAnotherPartner() {
    let suggestedNgos = this.state.suggestedNgos;
    var EmailPattern = /^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/;
    if(suggestedNgos[suggestedNgos.length - 1]['name'] !== '' && ((suggestedNgos[suggestedNgos.length - 1].hasOwnProperty('ngoId') && suggestedNgos[suggestedNgos.length - 1]['ngoId'] !== '') || (suggestedNgos[suggestedNgos.length - 1].hasOwnProperty('email') && suggestedNgos[suggestedNgos.length - 1]['email'] !== '' && suggestedNgos[suggestedNgos.length - 1]['email'].match(EmailPattern)))) {
      let newNGO = {
        name: '',
        ngoFound: null
      };
      suggestedNgos.push(newNGO);
      this.setState({ suggestedNgos });
    } else {
      this.notifyError('Please enter the complete details of the previous NGO partner.');
    }
  }

  notifySuccess = (text) => toast.success(text);

  notifyError = (text) => toast.error(text);

  render() {
    const options = this.state.dataSource.map(function(option, index) {
      return (
        <Option key={index} value={option.id}>
          {option.location !== "" ? `${option.name}`+" ("+ `${option.location}` +")" : option.name}
        </Option>
      )


    });

    return (
      <div>
        <ToastContainer position="bottom-center" autoClose={4000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={true} />
        <Modal className="mis-content-container invitations-modal" show={this.props.show} onHide={this.props.handleClose}>
          <div className="invitations-modal-contents">
            <div className="invitations-modal-header">
              <div className="invitations-modal-title-section">
                <div className="invitations-modal-title-text">Invite Partner NGOs</div>
                <div className="invitations-modal-header-close" onClick={this.props.handleClose}>X</div>
              </div>
            </div>
            <Modal.Body className="invitations-modal-main-container ml10">
              <Row>
    <Col md={12} className="invitations-status" style={{color:"orange"}}>
      Note: Only selection from the list is allowed. Please start typing the name of the NGO. In case NGO is not found, Please reach out to your account manager.
    </Col>

              </Row>
              <Row className="ml10">
                <Col md={12} className="invitations-modal-ngosug-section">
                  {this.state.suggestedNgos.map(function(suggestedNgo, index) {
                    return (
                        <Row key={index} style={{display: 'flex', alignItems: 'flex-end'}}>
                         <Col md={3} className="invitations-modal-select-section form-group m0">
                          <label className="invitations-modal-ngosug-heading control-label is-imp">NGO Partner Name</label>
                          <AutoComplete dataSource={options} style={{ width: 'calc(100%)' }} onSelect={this.onSelect.bind(this, index)} onSearch={this.handleSearch.bind(this, index)} filterOption={(inputValue, option) => option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1} placeholder="Enter the NGO name here"/>
                        </Col>
                        <Col md={3} className="invitations-modal-select-section form-group m0">
                          <label className="invitations-modal-ngosug-heading control-label is-imp">NGO Partner Email</label>
                          <Input type="email" placeholder="Please Enter Email" style={{ width: 'calc(100% - 40px)' }} className="invitations-modal-email form-control" onChange={this.handleUpdate.bind(this, 'email')} />
                        </Col>
                        <Col md={3} className="invitations-modal-select-section form-group m0">
                          <label className="invitations-modal-ngosug-heading control-label is-imp">Name of POC</label>
                          <Input type="text" placeholder="Name of POC" style={{ width: 'calc(100% - 40px)' }} className="invitations-modal-poc-name form-control" onChange={this.handleUpdate.bind(this, 'pocName')} />
                        </Col>
                        <Col md={3} className="invitations-modal-select-section form-group m0">
                          <label className="invitations-modal-ngosug-heading control-label is-imp">Contact No. of POC</label>
                          <Input type="number" placeholder="Contact No. of POC" style={{ width: 'calc(100% - 40px)' }} className="invitations-modal-poc-contact form-control" onChange={this.handleUpdate.bind(this, 'pocContact')} />
                        </Col>
                      </Row>
                    )
                  }.bind(this))}
                </Col>
                {/*<div className="innovation-modal-ask">
                  <div className="innovation-modal-ask-text">What should we ask your partners to do ?</div>
                  <div className="innovation-modal-ask-options">
                  <Checkbox onChange={this.actionHandler} value={'Onboard'}
                    checked={ this.state.actionSolicited === 'Onboard' ? true : false }>Invite my NGO partners to join this platform</Checkbox>
                  <Checkbox onChange={this.actionHandler} value={'Add Projects to CSREdge'}
                    checked={ this.state.actionSolicited === 'Add Projects to CSREdge' ? true : false }>Ask my NGO Partners to add projects for me</Checkbox>
                  </div>
                </div>*/}
                <Col md={12} className="invitations-modal-note-section form-group">
                  <label className="invitations-modal-note-heading control-label is-imp">Send a note to your NGO partner</label>
                  <textarea type="text" placeholder="Enter Your Text Here" className="invitations-modal-note-text form-control" style={{ width: 'calc(100% - 40px)' }} onChange={this.handleNote}></textarea>
                  {this.state.error.indexOf('note') > -1 ? (
                    <div className="invitations-modal-note-error" style={{color: 'red'}}>This field cannot be empty</div>
                  ) : (null)}
                </Col>
              </Row>
            </Modal.Body>
            <div className="invitations-modal-footer-section">
              {(this.state.note !== '' && this.state.suggestedNgos[0].name !== '' && 
              ((this.state.suggestedNgos[0].hasOwnProperty('ngoId') && 
              this.state.suggestedNgos[0]['ngoId'] !== '') &&
              (this.state.suggestedNgos[0].hasOwnProperty('email') && 
              this.state.suggestedNgos[0]['email'] !== '' &&
               this.state.suggestedNgos[0]['email'].match(this.state.emailPattern) 
               && this.state.suggestedNgos[0]['pocName'] !== '' && 
               this.state.suggestedNgos[0]['pocContact'] !== '' )) 
               && (this.state.ngoName === this.state.selectedNgoName)) ? 
               <button className="invitations-modal-btn" onClick={this.handleSubmit} 
              >Submit</button> :<button 
              className="invitations-modal-btn-2" 
              style={{background:"grey" }} 
              >Submit</button>}
              
            </div>
          </div>
        </Modal>
      </div>
      );
  }
}
