import React, { Component } from 'react';
//import Modal from 'react-responsive-modal';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    Legend
  } from "recharts";
import {Row, Col, Tab, Tabs} from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn, ButtonGroup, ExportCSVButton } from 'react-bootstrap-table';
import moment from 'moment';
import _ from 'lodash';

let array = [];
export default class GraphModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataArray: [],
            kpi_name: null
        }
    }

    componentDidMount() {
        let arr = [];
        if(this.props.dataArray.graph_data !== null) {
            _.map(this.props.dataArray.graph_data, (el, i) => {
                console.log("234567", el);
                arr.push({count: Number(el.value[0].unit), date: el.date})
            });
            array = arr;
            this.setState({dataArray: arr, kpi_name: this.props.dataArray.graph_data[0].value[0].kpi_name});
        }
    }

    render() {
        let dummy = [
            {"date": "01/02/2018", "count": 5},
            {"date": "04/02/2018", "count": 10}
        ];
        return (
            <Col style={{display: 'inline !important', height: '230px'}} md={12}>
                {this.state.dataArray !== null ? (
                    <ResponsiveContainer>
                        <LineChart width={900} 
                            data={array}
                            margin={{ top: 5, right: 30, left: 30, bottom: 5 }}>
                            <XAxis dataKey="date" label="date" />
                            <YAxis label="Count" dataKey="count" />
                            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                            <Tooltip />
                            <Legend /> 
                            <Line type="monotone" dataKey="count"
                            stroke="#82ca9d" strokeWidth={3} /> 
                            {/* <Line type="monotone" dataKey="date" stroke="#82ca9d" strokeWidth={3} /> */}
                        </LineChart>
                    </ResponsiveContainer>
                ) : (null)}
            </Col>
        )
    }
}