import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Row, Col, Button} from 'react-bootstrap';
import $ from 'jquery';
import swal from "sweetalert2";

import * as action from '../../../actions/ProfileAction';

const readCookie = require('../../../cookie.js').readCookie;

class Profile extends Component {
	constructor(props) {
		super(props);
		this.updateUserData = this.updateUserData.bind(this);
		this.onChangePasswordClick = this.onChangePasswordClick.bind(this);
	}

	componentDidMount() {
		let userData = JSON.parse(readCookie('userData'));
		this.updateUserData(userData);
	}

	updateUserData(userData) {
		this.props.actions.updateUserData(userData);
	}

	onChangePasswordClick() {
    swal({
      title: 'Change Password',
      html: '<span>Current Password</span><input type="password" id="swal-input1" class="swal2-input" placeholder="Current Password">' +
        '<span>New Password</span><input type="password" id="swal-input2" class="swal2-input" placeholder="New Password">' +
        '<span>Repeat New Password</span><input type="password" id="swal-input3" class="swal2-input" placeholder="Repeat New Password">'
    }).then(function (result) {
      let val1 = $('#swal-input1').val();
      let val2 = $('#swal-input2').val();
      let val3 = $('#swal-input3').val();
      if (val2 !== val3 || val2 === '') {
        swal('Oops...',
  	      "Your passwords didn't match!",
	        'error');
      } else {
        if(result) {
          swal({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Change!',
            cancelButtonText: 'No, cancel!',
            confirmButtonClass: 'btn',
            cancelButtonClass: 'btn',
          }).then(function(result) {
            if(result) {
            	var userDataCookie = readCookie('userData');
	  					var userDataJSON = JSON.parse(userDataCookie);
              fetch(process.env.REACT_APP_API_URL + '/updatePassword', {
                method: 'PUT',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
                },
                body: JSON.stringify({
                	userId: userDataJSON['id'],
                  newPassword: val2,
                  oldPassword: val1
                })
           		}).then((response) => response.json())
            	.then(function(data) {
                if(data['status'] !== 'ok') {
                  swal('Oops...',
          	        'Something went wrong!',
        	          'error');
                } else if(data['status'] === 'ok') {
                	swal({
										type: 'success',
										title: 'Your password has been changed successfully!',
										timer: 1000
									});
                }
              });
            }
          });
        }
      }
    }).catch(swal.noop);
  }

	render() {
		if(this.props.userData !== null) {
			return (
				<div className="container" id="profile">
					<div className="single-donor-tab-header">
						<i className="fa fa-user"></i>
						<span> Profile</span>
					</div>
					<Col md={12}>
	          <Row id="profile-details" className="mt20">
	            <Row className="flex-center">
	              <Col md={8} className="mb10">
	                <Row>
	                  <Col md={12} className="mb10">
	                    <span className="settings-title">Full Name:</span>
	                    <span className="detail-data">
	                      {this.props.userData['fullName']}
	                    </span>
	                  </Col>
	                  <Col md={12} className="mb10">
	                    <span className="settings-title">Email:</span>
	                    <span className="detail-data">
	                      {this.props.userData['email']}
	                    </span>
	                  </Col>
	                  <Col md={12} className="mb10">
	                    <Button className="btn-success change-password" onClick={this.onChangePasswordClick}>Change Password</Button>
	                  </Col>
	                </Row>
	              </Col>
	            </Row>
	          </Row>
	        </Col>
				</div>
			);
		} else {
			return null;
		}
	}
}

Profile.propTypes = {
	userData: PropTypes.object
};

function mapStateToProps(state) {
	return {
		userData: state.Profile.userData
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(action, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);