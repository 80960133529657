import React, { Component } from "react";
import { Col, Modal, Button } from "react-bootstrap";
import { Input, Select } from "antd";
import { toast } from "react-toastify";
import swal from "sweetalert2";
const Option = Select.Option;

export default class UserEditModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: props.memberData.fullName,
      role: props.memberData.authorizedFor.mis,
      // props.authorizedFor.mis,
    };
  }

  notifySuccess = (text) => toast.success(text);
  notifyError = (text) => toast.error(text);
  handleChange(name, value) {
    let val = "";
    if (name === "name") {
      val = value.target.value;
      this.setState({ name: val });
    }
    this.setState({ [name]: val });
  }
  handleClose() {
    let data = {};
    let role;
    if (this.state.name !== "") {
      swal({
        title: "Are you sure you want to update this user?",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Update",
      }).then(
        function (result) {
          if (result) {
            // let memberData =this.state.members.filter((x)=> x._id === memberId) ,role,email;
            // let member =memberData[0];
            if (this.state.role !== "view") {
              role = "view";
              this.props.memberData.authorizedFor.mis = "view";
            } else {
              this.props.memberData.authorizedFor.mis = "admin";
              role = "admin";
            }
            data["role"] = role;
            data["email"] = this.props.memberData.email;
            data["name"] = this.state.name;

            let companyId = JSON.parse(readCookie("userData")).organisationData
              .id;
            fetch(
              process.env.REACT_APP_API_URL +
                "/company/" +
                companyId +
                "/mis/update-member/" +
                this.props.memberData._id,
              {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                  Auth: JSON.parse(readCookie("access_token")).access_token,
                },
                body: JSON.stringify(data),
              }
            )
              .then(function (response) {
                return response.json();
              })
              .then(
                function (data) {
                  if (data.status === "ok") {
                    this.notifySuccess("Successfully updated user!");
                    window.location.reload();
                  }
                }.bind(this)
              ).catch(err => console.log("Err",err))
          }
        }.bind(this)
      );
    } else {
      this.notifyError("Please enter a valid name");
    }
  }

  render() {
    return (
      <>
        <Modal show={this.props.show} onHide={this.props.onHide}>
          <Modal.Body>
            <Col md={12} className="mt20 mb20">
              <Col md={6} style={{ paddingLeft: "0px" }}>
                <label className="col-md-12 " style={{ paddingLeft: "0px" ,fontFamily:"'Proxima Nova',sans-serif" }}>
                  Change Name:
                </label>
                <Input
                  id="corporateName"
                  type="text"
                  placeholder="Full Name"
                  data-type="corporate"
                  size="large"
                  defaultValue={this.state.name}
                  onChange={this.handleChange.bind(this, "name")}
                />
              </Col>
              <Col md={6} className="role-selector-container">
                <label className="col-md-12 " style={{ paddingLeft: "0px",fontFamily:"'Proxima Nova',sans-serif" }}>
                  Change Access Rights:
                </label>
                <Select
                  id="role-selector"
                  defaultValue={this.state.role}
                  size="large"
                  onChange={this.handleChange.bind(this, "role")}
                >
                  {/* <Option disabled value="">None Selected</Option> */}
                  <Option style={{ paddingLeft: "0px",fontFamily:"'Proxima Nova',sans-serif" }} value="admin" >Admin</Option>
                  <Option style={{ paddingLeft: "0px",fontFamily:"'Proxima Nova',sans-serif" }} value="view">Member</Option>
                </Select>
              </Col>
            </Col>
          </Modal.Body>
          <Modal.Footer>
            <Button 
            variant="secondary" 
            onClick={this.props.onHide}
            style={{fontFamily:"'Proxima Nova',sans-serif" }}
            >
              Close
            </Button>

            <Button
              className="add-role-button-container add-role-button btn btn-default le-button"
              style={{fontFamily:"'Proxima Nova',sans-serif" }}
              onClick={this.handleClose.bind(this)}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
