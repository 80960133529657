import React,{ Component } from 'react';
import {Col, Row} from 'react-bootstrap';

import $ from 'jquery';

export default class OtherInformation extends Component {
  render() {
	  return (
	  	<div>
	    	<Row className="other-information">
	        <Col md={12}>
	          <Row className="mb20 mt20">
	          	<div className="form-group goals-and-agendas">
	            	<div><label>Select your focus developmental goals and agendas:</label></div>
	            	{this.props.allGoals.map(function(goal, index) {
          				var index = this.props.preferedGoals.indexOf(goal._id);
	            		if(index > -1) {
	            			return (
	            				<div className="goal-container">
            						<span>
            							<div className="goal-icon" style={{backgroundColor: goal.backgroundColor, backgroundImage: "url('https://le-uploaded-image-bucket.s3.amazonaws.com/" + goal.icon + "')"}}></div>
            							<input type="checkbox" value={goal._id} checked onChange={this.props.changePreferedGoals.bind(this, false, goal._id)} /> {goal.name}
            						</span>
            						<div className="goal-agendas">
            							<div className="mb5"><label>Agendas:</label></div>
            							<div className="agenda-container mb5">
		            						<span style={{fontWeight: 'bold'}}>
		            							<input type="checkbox" value='all' onChange={this.props.changeAllPreferedAgendas.bind(this, goal._id)} />
		            							{' Select / Unselect All'}
		            						</span>
		            					</div>
            							{this.props.preferedAgendas !== null && this.props.allAgendas.map(function(agenda, index) {
            								if(agenda._goalId === goal._id) {
	            								var index = this.props.preferedAgendas.indexOf(agenda._id);
							            		if(index > -1) {
							            			return (
							            				<div className="agenda-container">
						            						<span>
						            							<input type="checkbox" value={agenda._id} checked onChange={this.props.changePreferedAgendas.bind(this, false, agenda._id)} /> {agenda.name}
						            						</span>
						            					</div>
						            				)
							            		} else {
							            			return <div className="agenda-container">
						            						<span>
						            							<input type="checkbox" value={agenda._id} onChange={this.props.changePreferedAgendas.bind(this, true, agenda._id)} /> {agenda.name}
						            						</span>
						            					</div>
							            		}
						            		}
            							}.bind(this))}
            						</div>
            					</div>
            				)
	            		} else {
	            			return <div className="goal-container">
            						<span>
            							<div className="goal-icon" style={{backgroundColor: goal.backgroundColor, backgroundImage: "url('https://le-uploaded-image-bucket.s3.amazonaws.com/" + goal.icon + "')"}}></div>
            							<input type="checkbox" value={goal._id} onChange={this.props.changePreferedGoals.bind(this, true, goal._id)} /> {goal.name}
            						</span>
            					</div>
	            		}
	            	}.bind(this))}
	              <div className="goal-container">
	              	<span><input type="checkbox" value="" onChange={this.props.changePreferedGoals.bind(this, true, '')} /> No Preference</span>
	              </div>
	            </div>
	          </Row>

		        <Row className="mb20 mt20">
	          	<Col md={6} className="form-group projects-size">
	            	<div><label>Select your prefered size of projects:</label></div>
	            	<div className="projects-size-container">
      						<span><input type="radio" name="projects-size" value="small" checked={this.props.projectsSizeType === 'small' ? ('checked'):(false)} onChange={this.props.changePreferedProjectSize.bind(this)} />{' Multiple smaller projects'}</span>
      					</div>
            		<div className="projects-size-container">
      						<span><input type="radio" name="projects-size" value="large" checked={this.props.projectsSizeType === 'large' ? ('checked'):(false)} onChange={this.props.changePreferedProjectSize.bind(this)} />{' Few large projects'}</span>
      					</div>
	            	<div className="projects-size-container">
	            		<span><input type="radio" name="projects-size" value="" checked={this.props.projectsSizeType === '' ? ('checked'):(false)} onChange={this.props.changePreferedProjectSize.bind(this)} />{' No Preference'}</span>
	              </div>
	            </Col>

	            <Col md={6} className="form-group projects-amount">
	            	<div><label>Select your prefered average ticket size of projects:</label></div>
	            	<div className="projects-size-container">
      						<span><input type="number" className="project-amount" value={this.props.projectsSizeAmount} onChange={this.props.changePreferedProjectAmount.bind(this)} /></span>
      					</div>
	            </Col>
	          </Row>

	          <Row className="mb20 mt20">
	          	<Col md={12} className="form-group ngo-specifications">
	            	<div><label>Select your prefered NGO specifications:</label></div>
	            	<div className="ngo-specifications-container">
		            	{this.props.allNgoSpecs.map(function(ngoSpecs, index) {
	          				var index = this.props.preferedNgoSpecs.indexOf(ngoSpecs._id);
		            		if(index > -1) {
		            			return (
		            				<div className="ngo-specification-container">
	            						<span><input type="checkbox" value={ngoSpecs._id} checked onChange={this.props.changePreferedNgoSpecifications.bind(this, false, ngoSpecs._id)} /> {ngoSpecs.name}</span>
	            					</div>
	            				)
		            		} else {
		            			return (
		            				<div className="ngo-specification-container">
	            						<span><input type="checkbox" value={ngoSpecs._id} onChange={this.props.changePreferedNgoSpecifications.bind(this, true, ngoSpecs._id)} /> {ngoSpecs.name}</span>
	            					</div>
	            				)
		            		}
		            	}.bind(this))}
		            	<div className="ngo-specification-container">
		              	<span><input type="checkbox" checked={this.props.ngoIsOlder} onChange={this.props.changePreferedNgoOlder} /> Must be >3 years old</span>
		              </div>
		              <div className="ngo-specification-container">
		              	<span><input type="checkbox" checked={this.props.hasCSRHistory} onChange={this.props.changePreferedNgoCSRHistory} /> Must have a history of CSR investments</span>
		              </div>
		            	<div className="ngo-specification-container">
		              	<span><input type="checkbox" value="" onChange={this.props.changePreferedNgoSpecifications.bind(this, true, '')} /> No Preference</span>
		              </div>
		            </div>
	            </Col>
	          </Row>

	          <Row className="mb20 mt20">
	          	<Col md={12} className="form-group project-durations">
	            	<div><label>Select your prefered project duration:</label></div>
	            	<div className="project-durations-container">
		            	{this.props.allProjectDuration.map(function(projectDuration, index) {
	          				var index = this.props.preferedProjectDuration.indexOf(projectDuration._id);
		            		if(index > -1) {
		            			return (
		            				<div className="project-duration-container">
	            						<span><input type="checkbox" value={projectDuration._id} checked onChange={this.props.changePreferedProjectDuration.bind(this, false, projectDuration._id)} /> {projectDuration.name}</span>
	            					</div>
	            				)
		            		} else {
		            			return (
		            				<div className="project-duration-container">
	            						<span><input type="checkbox" value={projectDuration._id} onChange={this.props.changePreferedProjectDuration.bind(this, true, projectDuration._id)} /> {projectDuration.name}</span>
	            					</div>
	            				)
		            		}
		            	}.bind(this))}
		            	<div className="project-duration-container">
		              	<span><input type="checkbox" value="" onChange={this.props.changePreferedProjectDuration.bind(this, true, '')} /> No Preference</span>
		              </div>
		            </div>
	            </Col>
	          </Row>
	        </Col>
	      </Row>
	    </div>
	  );
	}
}