import React, { Component } from "react";
import { Modal } from 'react-bootstrap';
import { Checkbox, Dropdown, Button, Menu, Radio, Input, AutoComplete, Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import swal from 'sweetalert2';
import ReactGA from 'react-ga';

const readCookie = require('../../../../cookie.js').readCookie;

const Option = Select.Option;

export default class InnovationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ngolist: [],
      error: [],
      selectPartner: false,
      dataSource: [],
      visible1: false,
      visible2: false,
      fundProject: false,
      fetching: false,
      name: '',
      email: '',
      note: '',
      value: [],
      suggestedNgos: [],
      selectedNgo: [],
      ngoFound: false
    }
  }

  componentDidMount(){
    fetch(process.env.REACT_APP_API_URL + '/innovation/' + this.props.innovationId + '/interested/ngo', {
      method: "GET",
      headers: {
        'Auth': JSON.parse(readCookie('access_token')).access_token
      }
    }).then((data) => data.json())
    .then(function(data) {
      let ngolist = data.data;
      this.setState({ ngolist });
    }.bind(this));
  }

  partnerHandler = (e) =>{
    let selectPartner = e.target.value;
    this.setState({ selectPartner });
  }

  handleVisibleChanged1 = (flag) => {
    this.setState({ visible1: flag });
  }

  handleVisibleChanged2 = (flag) => {
    this.setState({ visible2: flag });
  }

  fundProjectHandler = (e) =>{
    let fundProject = e.target.value;
    this.setState({ fundProject });
  }

  interestedNgoSelectedList = (e) =>{
    let selectedNgo = this.state.selectedNgo;
    let interestedNgoSelected = e.target.value;
    if( selectedNgo.indexOf(interestedNgoSelected) === -1){
      selectedNgo.push(interestedNgoSelected);
    } else {
      selectedNgo.splice(selectedNgo.indexOf(interestedNgoSelected), 1);
    }
    this.setState({ selectedNgo });
  }

  handleChange = (value, obj) => {
    if(value.length) {
      var lastVal = value[value.length - 1];
      var lastObj = obj[obj.length - 1];
      if(lastObj.props !== undefined && (lastObj.props.hasOwnProperty('value') && lastObj.props.hasOwnProperty('children')) && lastObj.key === lastObj.props.value && lastObj.key === lastObj.props.children) {
        this.setState({
          value: lastVal,
          name: lastObj.key,
          ngoFound: false
        });
      } else {
        this.setState({
          value: lastVal,
          name: lastObj.props.children,
          ngoFound: true
        });
      }
    } else {
      this.setState({
        value: [],
        name: '',
        ngoFound: false
      });
    }
  }

  handleSearch = (value) => {
    if(value.length > 2) {
      this.setState({ fetching: true });
      fetch(process.env.REACT_APP_API_URL + '/ngo/search?q=' + value, {
        method: "GET",
        headers: {
          'Auth': JSON.parse(readCookie('access_token')).access_token
        }
      }).then((data) => data.json())
      .then(function(data) {
        let dataSource = data.data;
        this.setState({ dataSource, ngoFound: false, fetching: false });
      }.bind(this));
    }
  }

  ngoNameInputBlur(value) {
    if(value === undefined && this.state.name !== '') {
      let suggestedNgos = this.state.suggestedNgos;
      let suggestedNgosList = {};
      suggestedNgosList['id'] = '';
      suggestedNgosList['name'] = this.state.name;
      suggestedNgosList['email'] = '';
      suggestedNgos.push(suggestedNgosList)
      let value = this.state.value;
      value.push({
        label: this.state.name,
        key: this.state.name
      });
      this.setState({ value, name: '' });
    }
  }

  handleEmailUpdate = (e) => {
    let error = this.state.error;
    let email = e.target.value;
    var EmailPattern = /^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/;
    if(!email.match(EmailPattern)) {
      if(error.indexOf('email') === -1) {
        error.push('email');
      }
    } else {
      if(error.indexOf('email') !== -1) {
        error.splice(error.indexOf('email'), 1);
      }
    }
    if(email === '') {
        error.splice(error.indexOf('email'), 1);
    }
    this.setState({ email, error });
  }

  handleNote = (e) => {
    let error = this.state.error;
    let note = e.target.value;
    if(note !== ''){
     if(error.indexOf('note') !== -1) {
        error.splice(error.indexOf('note'), 1);
      }
    } else {
      if(error.indexOf('note') === -1) {
        error.push('note');
      }
    }
    this.setState({ note, error });
  }

  handleSubmit = () => {
    let selectedNgo = this.state.selectedNgo;
    let note = this.state.note;
    let email = this.state.email;
    let name = this.state.name;

    let data = {};
    if(note !== '' && ((this.state.fundProject && selectedNgo.length) || !this.state.fundProject) && (this.state.selectPartner && name !== '' && email !== '') || !this.state.selectPartner) {
      data.message = note;
      if(this.state.fundProject) {
        data.ngoIds = selectedNgo;
      }
      if(this.state.selectPartner && name !== '' && email !== '') {
        let ngoObj = {};
        ngoObj.name = name;
        ngoObj.email = email;
        if(this.state.ngoFound) {
          ngoObj.id = this.state.value;
        }
        data.ngo = ngoObj;
      }

      ReactGA.event({
        category: 'Innovation',
        action: 'click',
        label: 'Interested',
        value: 1
      });

      fetch(process.env.REACT_APP_API_URL + '/innovation/' + this.props.innovationId + '/express-interest' , {
        method: "POST",
        headers: {
          'Content-type': 'application/json',
          'Auth': JSON.parse(readCookie('access_token')).access_token
        },
        body: JSON.stringify(data)
      }).then(function(data) {
        return data.json();
      }).then(function(data) {
        if(data.status === 'ok') {
          swal({
            title: 'Saved Successfully.',
            html: 'Our team will get back to you shortly!',
            type: 'success'
          });
        } else {
          swal({
            title: 'Save Unsuccessfully.',
            html: 'Please retry!',
            type: 'error'
          });
        }
        this.props.handleClose();
      }.bind(this));
    } else {
      //Error
      let error = [];
      if(note === '') error.push('note');
      if(name === '') error.push('name');
      if(email === '') error.push('email');
      this.setState({ error });
    }
  }

  render() {
    const menu = (
      <Menu>
        {this.state.ngolist.map((ngoData, index)=>{
          return (
            <Menu.Item>
              <Checkbox onChange={this.interestedNgoSelectedList} value={ngoData.id}>{ngoData.name}</Checkbox>
            </Menu.Item>
          )
        })}
      </Menu>
    );

    return(
      <div>
        <Modal className="mis-content-container" show={this.props.show} onHide={this.props.handleClose}>
          <div className="innovation-modal-contents">
            <div className="innovation-modal-header">
              <div className="innovation-modal-title-section">
                <div className="innovation-modal-lelogo">
                  <img src="/img/logo.jpg" />
                </div>
                <div className="innovation-modal-title-text">Show Interest</div>
                <div className="innovation-modal-header-close" onClick={this.props.handleClose}>X</div>
              </div>
            </div>
            <Modal.Body className="innovation-modal-main-container">
            {this.state.ngolist.length ? (
              <div className="innovation-modal-ngoint-section">
                <div className="innovation-modal-ask">
                  <div className="innovation-modal-ask-text">Would you like to fund projects for the following interested NGOs ?</div>
                    <div className="innovation-modal-ask-options">
                      <Radio onChange={this.fundProjectHandler} checked={
                        this.state.fundProject === true ? true : false }  value={true} name="fundProject" >
                        <span className="innovation-modal-ask-options-txt">YES</span>
                      </Radio>
                      <Radio onChange={this.fundProjectHandler} checked={
                        this.state.fundProject === false ? true : false }  value={false} name="fundProject" >
                        <span className="innovation-modal-ask-options-txt">NO</span>
                      </Radio>
                    </div>
                  </div>
                {this.state.fundProject ? (
                  <div className="innovation-modal-fund-ngo">
                    <div className="innovation-modal-ngoint-heading mb5">Please select the NGOs you would like to partner with.</div>
                    <div className="innovation-modal-select-ngo">
                      <Dropdown overlay={menu} trigger={['click']} onVisibleChange={this.handleVisibleChanged1} visible={this.state.visible1}>
                        <Button>NGOs Interested</Button>
                      </Dropdown>
                    </div>
                    {this.state.selectedNgo.length === 0 ? (
                      <div style={{color: "red"}}> Please select NGO from the list</div>
                    ) : (null)}
                  </div>
                ) : (null)}
              </div>
            ) : (null)}
              <div className="innovation-modal-ask">
                <div className="innovation-modal-ask-text">Do you have any NGO partner suggestions who could adopt this solution ?</div>
                <div className="innovation-modal-ask-options">
                  <Radio onChange={this.partnerHandler}
                    checked={ this.state.selectPartner === true ? true : false } value={true} name="selectPartner" >
                    <span className="innovation-modal-ask-options-txt">YES</span>
                  </Radio>
                  <Radio onChange={this.partnerHandler}
                    checked={ this.state.selectPartner === false ? true : false } value={false} name="selectPartner" >
                    <span className="innovation-modal-ask-options-txt">NO</span>
                  </Radio>
                </div>
              </div>
              {this.state.selectPartner ? (
                <div className="innovation-modal-ngosug-section">
                  <div className="innovation-modal-select-section mb20">
                    <div className="innovation-modal-ngosug-heading mb5 is-imp">Please select an NGO</div>
                    <div className="innovation-modal-select-ngo">
                      <Select mode="tags" value={this.state.value} notFoundContent={this.state.fetching ? <Spin size="small" /> : null} placeholder="Enter the NGO name here" filterOption={false} onSearch={this.handleSearch} onChange={this.handleChange} style={{ width: '50%' }} maxTagCount={1}>
                        {this.state.dataSource.map(function(option, index) {
                          return (
                            <Option key={index} value={option.id}>
                              {option.name}
                            </Option>
                          )
                        })}
                      </Select>
                    </div>
                    {this.state.error.indexOf('name') > -1 ? (
                      <div className="innovation-modal-name-error" style={{color: 'red'}}>This field cannot be empty</div>
                    ) : (null)}
                  </div>
                  <div>
                    <div className="innovation-modal-ngosug-subheading mb5">
                      <div className="is-imp">Please Enter the NGO Mail ID</div>
                    </div>
                    <div className="innovation-modal-ngosug-mail">
                      <Input type="email" placeholder="Please Enter Email" className="innovation-modal-email" onChange={this.handleEmailUpdate}/>
                    </div>
                    {this.state.error.indexOf('email') > -1 ? (
                      <div className="innovation-email-error" style={{color: 'red'}}>Invalid Email</div>
                    ) : (null)}
                  </div>
                </div>
              ): null}
              <div className="innovation-modal-note-section">
                  <div className="innovation-modal-note-heading mb5">Write a note for us to get back with better recommendations
                    <span className="innovation-modal-imp-bullet control-label is-imp"></span>
                  </div>
                <Input type="text" placeholder="Enter Your Text Here" className="innovation-modal-note-text" onChange={this.handleNote}/>
              {this.state.error.indexOf('note') > -1 ? (
                <div className="innovation-modal-note-error" style={{color: 'red'}}>This field cannot be empty</div>
              ) : (null)}
              </div>
            </Modal.Body>
            <div className="innovation-modal-footer-section">
              <button className="innovation-modal-btn" onClick={this.handleSubmit}>Submit</button>
            </div>
          </div>
        </Modal>
      </div>
      );
	}
}
