import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Col} from 'react-bootstrap';

import * as action from '../../../actions/LandingAction';

import LandingBanner from './LandingBanner';
import LandingGoalsSection from './LandingGoalsSection';
import LandingTestimonial from './LandingTestimonial';
import FirstLogin from './FirstLogin';

const readCookie = require('../../../cookie.js').readCookie;

class Landing extends Component {
	constructor(props) {
		super(props);

		this.state = {
			firstLogin: false
		}
	}

	componentDidMount() {
		fetch(process.env.REACT_APP_API_URL + '/getDashboardCounts', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
		.then(function(data) {
			this.props.actions.updateDashboardCount(data.ngosCount, data.companiesCount, data.projectsCount, data.volunteerOpportunitiesCount);
		}.bind(this));

		fetch(process.env.REACT_APP_API_URL + '/getFeaturedProjects', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
		.then(function(data) {
			this.props.actions.updateFeatured(data.data);
		}.bind(this));
	}

	render() {
		if(this.state.firstLogin) {
			return (
				<FirstLogin />
			);
		} else {
			return (
				<div>
					<LandingBanner
						ngosCount={this.props.ngosCount}
						companiesCount={this.props.companiesCount}
						projectsCount={this.props.projectsCount}
						volunteerOpportunitiesCount={this.props.volunteerOpportunitiesCount}
					/>
					<LandingGoalsSection
						featured={this.props.featured}
						updateFeatured={this.props.actions.updateFeatured}
					/>
					<Col md={12} className="footer">
						<p className="footer-copyright mb5 mt5">
							Powered By
							<a href="https://www.letsendorse.com/" target="_blank">
								<img className="logo" height="50" src="https://www.letsendorse.com/images/0.png" alt="Logo 85b82f324543c85814c3497bf0430305ae9d473c2cd47cf496fb3c7de05bba1a" />
							</a>
						</p>
					</Col>
					{/*<LandingTestimonial />*/}
				</div>
			);
		}
	}

}

Landing.propTypes = {
	ngosCount: PropTypes.number,
	projectsCount: PropTypes.number,
	companiesCount: PropTypes.number,
	volunteerOpportunitiesCount: PropTypes.number,
	featured: PropTypes.array
};

function mapStateToProps(state) {
	return {
		ngosCount: state.Landing.ngosCount,
		companiesCount: state.Landing.companiesCount,
		projectsCount: state.Landing.projectsCount,
		volunteerOpportunitiesCount: state.Landing.volunteerOpportunitiesCount,
		featured: state.Landing.featured,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(action, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Landing);