import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import $ from 'jquery';
import {createFilter} from 'react-search-input';
import Multiselect from 'react-bootstrap-multiselect';

const KEYS_TO_FILTERS = ['title']

let filteredCauses = [];
const readCookie = require('../../../cookie.js').readCookie;

export default class CausesFilter extends Component {
	constructor(props) {
		super(props);
		this.state = {
			searchTerm: this.props.searchTerm,
			location: null,
    	engagement: null,
    	goal: null,
    	frequency: null,
    	locationValue: "",
    	engagementValue: "",
    	goalValue: "",
    	frequencyValue: ""
		};
	}

	updateSearchTerm(e) {
		let value = e.target.value;
		this.props.updateSearchTerm(value);
		this.setState({ searchTerm: value }, function() {
			if(value === '') this.searchUpdated();
		}.bind(this));
	}

	searchUpdated() {
		this.props.updateSearchTerm(this.state.searchTerm);
		this.props.updateFilteredCauses(filteredCauses);
	}

	componentDidMount() {
		$(document).ready(function() {
			$('.causes-filter .causes-search-filter-input').keyup(function(e){
		    if(e.keyCode === 13) $(this).siblings('button.causes-search-filter-icon').click();
		  });
		});

		fetch(process.env.REACT_APP_API_URL + '/getOtAttributes', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
		.then(function(data) {
			this.setState({ filters: data.otAttributes });
			let location = [], engagement = [], goal = [], frequency = [];

			for(let i = 0; i < data.otAttributes.length; i++) {
				if(data.otAttributes[i].type === 'location') {
					let locationObj = {};
					locationObj['value'] = data.otAttributes[i]._id;
					locationObj['label'] = data.otAttributes[i].name;
					location.push(locationObj);
				} else if(data.otAttributes[i].type === 'engagement') {
					let engagementObj = {};
					engagementObj['value'] = data.otAttributes[i]._id;
					engagementObj['label'] = data.otAttributes[i].name;
					engagement.push(engagementObj);
				} else if(data.otAttributes[i].type === 'goal') {
					let goalObj = {};
					goalObj['value'] = data.otAttributes[i]._id;
					goalObj['label'] = data.otAttributes[i].name;
					goal.push(goalObj);
				} else if(data.otAttributes[i].type === 'frequency') {
					let frequencyObj = {};
					frequencyObj['value'] = data.otAttributes[i]._id;
					frequencyObj['label'] = data.otAttributes[i].name;
					frequency.push(frequencyObj);
				}
			}
			this.setState({ location, engagement, goal, frequency });
		}.bind(this));
	}

	handleChange(name, value) {
		this.setState({ [name]: $(value).parent().val() });
	}

	resetFilters() {
		let locationValue = [];
		let engagementValue = [];
		let goalValue = [];
		let frequencyValue = [];

		this.setState({ locationValue: null, engagementValue: null, goalValue: null, frequencyValue: null }, function() {
			this.setState({ locationValue, engagementValue, goalValue, frequencyValue });
		});

		fetch(process.env.REACT_APP_API_URL + '/opportunityType/discover', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
		.then(function(data) {
			this.props.updateFilteredCauses(data.data);
		}.bind(this));
	}

	applyFilters() {
		let params = {
			'opportunityType': []
		};
		if(this.state.locationValue !== '') {
			if(Array.isArray(this.state.locationValue)) {
				if(this.state.locationValue.length) {
					this.state.locationValue.map(function(val) {
						params['opportunityType'].push(val);
					})
				}
			}
		}
		if(this.state.engagementValue !== '') {
			if(Array.isArray(this.state.engagementValue)) {
				if(this.state.engagementValue.length) {
					this.state.engagementValue.map(function(val) {
						params['opportunityType'].push(val);
					})
				}
			}
		}
		if(this.state.frequencyValue !== '') {
			if(Array.isArray(this.state.frequencyValue)) {
				if(this.state.frequencyValue.length) {
					this.state.frequencyValue.map(function(val) {
						params['opportunityType'].push(val);
					})
				}
			}
		}
		if(this.state.goalValue !== '') {
			if(Array.isArray(this.state.goalValue)) {
				if(this.state.goalValue.length) {
					params['goalId'] = [];
					this.state.goalValue.map(function(val) {
						params['goalId'].push(val);
					})
				}
			}
		}

		let esc = encodeURIComponent
		let query = Object.keys(params).map(function(k) {
			if(Array.isArray(params[k])) {
				let x = '';
				for(var i = 0; i < params[k].length; i++) {
					if(i === 0) x += esc(k) + '=' + esc(params[k][i]);
					else x += '&' + esc(k) + '=' + esc(params[k][i])
				}
				return x;
			} else return esc(k) + '=' + esc(params[k])
		}).join('&')

		let url = process.env.REACT_APP_API_URL + '/opportunityType/discover?' + query
		fetch(url, {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
		.then(function(data) {
			this.props.updateFilteredCauses(data.data);
		}.bind(this));
	}

	showButtonText(options, select) {
	  if (options.length === 0) {
      return 'None selected ...';
	  } else if (options.length > 2) {
      return options.length + ' options selected!';
	  } else {
    	let labels = [];
      options.each(function() {
	     	if ($(this).attr('label') !== undefined) {
	      	labels.push($(this).attr('label'));
	     	} else {
	      	labels.push($(this).html());
	    	}
 			});
			return labels.join(', ') + '';
    }
  }

	render() {
		filteredCauses = this.props.causes.filter(createFilter(this.props.searchTerm, KEYS_TO_FILTERS))

		return (
			<div className="causes-filter">
				<div className="row">
					<div className="col-md-3 heading">SEARCH</div>
					<div className="col-md-9">
						<div className="causes-search-filter-container">
							<input className="causes-search-filter-input" type="search" placeholder="Search by Name" onChange={this.updateSearchTerm.bind(this)} />
							<button className="btn causes-search-filter-icon" onClick={this.searchUpdated.bind(this)}><i className="fa fa-search"></i></button>
						</div>
					</div>
				</div>
				<Row className="filters">
					<Col md={2}>
						<span>Engagement</span>
						{this.state.engagementValue !== null ? (
								<Multiselect id="causeEngagement" defaultValue={this.state.engagementValue} onChange={this.handleChange.bind(this, 'engagementValue')} buttonWidth={'100%'} buttonText={this.showButtonText.bind(this)} data={this.state.engagement} multiple />
							) : ('')
						}
					</Col>
					<Col md={2}>
						<span>Location</span>
						{this.state.locationValue !== null ? (
								<Multiselect id="causeLocation" defaultValue={this.state.locationValue} onChange={this.handleChange.bind(this, 'locationValue')} buttonWidth={'100%'} buttonText={this.showButtonText.bind(this)} data={this.state.location} multiple />
							) : ('')
						}
					</Col>
					<Col md={2}>
						<span>Frequency</span>
						{this.state.frequencyValue !== null ? (
								<Multiselect id="causeFrequency" defaultValue={this.state.frequencyValue} onChange={this.handleChange.bind(this, 'frequencyValue')} buttonWidth={'100%'} buttonText={this.showButtonText.bind(this)} data={this.state.frequency} multiple />
							) : ('')
						}
					</Col>
					<Col md={3}>
						<span>Goals</span>
						{this.state.goalValue !== null ? (
								<Multiselect id="causeGoal" defaultValue={this.state.goalValue} onChange={this.handleChange.bind(this, 'goalValue')} buttonWidth={'100%'} buttonText={this.showButtonText.bind(this)} data={this.state.goal} multiple />
							) : ('')
						}
					</Col>
					<Col md={1} className="btn filter-reset-btn" onClick={this.resetFilters.bind(this)}>Reset</Col>
					<Col md={1} className="btn filter-apply-btn" onClick={this.applyFilters.bind(this)}>Apply</Col>
				</Row>
			</div>
		);
	}
}