import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import $ from 'jquery';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import * as action from '../../../actions/DiscoverProjectsAction';

import ProjectsContainer from './ProjectsContainer';
import DiscoverProjectsMap from './DiscoverProjectsMap';
import DiscoverProjectsFilter from './DiscoverProjectsFilter';

const readCookie = require('../../../cookie.js').readCookie;

class DiscoverProjects extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hideProjectsFilter: true
		};
	}

	toggleProjectFilters() {
		this.setState({ hideProjectsFilter: !this.state.hideProjectsFilter });
	}

	componentDidMount() {
		fetch(process.env.REACT_APP_API_URL + '/volunteer-opportunity/discover', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
		.then(function(data) {
			this.props.actions.updateProjects(data.data);
		}.bind(this));

		let fixmeTop = $('.project-discover-map').offset().top;
		$(window).scroll(function() {
			let currentScroll = $(window).scrollTop();
			if (currentScroll >= (fixmeTop + 100)) {
				$('.project-discover-map').css({
					position: 'fixed',
					right: '30px',
					top: '20px'
				});
			} else {
				$('.project-discover-map').css({
					position: 'relative',
					right: 'auto',
					top: 'auto'
				});
			}
		});
	}

	render() {
		return (
			<div className="container">
				<Row>
					<Col md={12} className="projects-filters-container">
						{this.props.projects !== null ? (
							<div>
								<span id="projects-count">{this.props.projects.length + ' PROJECTS FOUND'}</span>
								{/*this.state.hideProjectsFilter ? (
										<span id="projects-filters" onClick={this.toggleProjectFilters.bind(this)}>{'Advanced Filter '}<i className="fa fa-caret-down"></i></span>
									) : (
										<span id="projects-filters" onClick={this.toggleProjectFilters.bind(this)}>{'Advanced Filter '}<i className="fa fa-caret-up"></i></span>
									)
								*/}

								<DiscoverProjectsFilter hidden={this.state.hideProjectsFilter} updateProjects={this.props.actions.updateProjects}/>

							</div>
						) : ('')
						}
					</Col>

					<Col md={7} sm={12} className="projects-list">
						<ProjectsContainer projects={this.props.projects}
						                   topProject={this.props.topProject}
						                   priceRangeMin={this.props.priceRangeMin}
						                   priceRangeMax={this.props.priceRangeMax}
						                   updateProjects={this.props.actions.updateProjects}/>
					</Col>
					<Col md={5} className="projects-map hidden-sm hidden-xs">
						<DiscoverProjectsMap projects={this.props.projects}
						                     topProject={this.props.topProject}
						                     updateProjects={this.props.actions.updateProjects}
						                     updateTopProject={this.props.actions.updateTopProject}
						                     initialPosition={this.props.initialPosition} />
					</Col>
				</Row>
			</div>
		);
	}
}

DiscoverProjects.propTypes = {
	projects: PropTypes.array,
	priceRangeMin: PropTypes.number,
	priceRangeMax: PropTypes.number,
	initialPosition: PropTypes.object,
	topProject: PropTypes.string
};

function mapStateToProps(state) {
	return {
		projects: state.DiscoverProjects.projects,
		topProject: state.DiscoverProjects.topProject,
		priceRangeMin: state.DiscoverProjects.priceRangeMin,
		priceRangeMax: state.DiscoverProjects.priceRangeMax,
		initialPosition: state.DiscoverProjects.initialPosition
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(action, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DiscoverProjects);