import React,{ Component } from 'react';
import {Col, Row} from 'react-bootstrap';
import Steps, { Step } from 'rc-steps';
import moment from 'moment';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';

import Loader from '../Loader';
import BasicInformation from './BasicInformation';
import InvestmentInformation from './InvestmentInformation';
import OtherInformation from './OtherInformation';

const readCookie = require('../../../cookie.js').readCookie;

export default class Preferences extends Component {
	constructor(props) {
		super(props);
		this.state = {
			preferences: null,
			currentStep: 0,
			csrBudgets: [],
			selectedCSRBudgetIndex: null,
			country: '',
			state: '',
			city: '',
			allGeographiesSetting: null,
			allGoals: null,
			allAgendas: null,
			allProjectDuration: null,
			allNgoSpecs: null,
			preferedGeographiesSetting: null,
			preferedGeographiesLocation: [],
			preferedGoals: [],
			preferedAgendas: [],
			preferedProjectDuration: [],
			preferedNgoSpecs: [],
			projectsSizeType: null,
			projectsSizeAmount: 0,
			ngoIsOlder: false,
			hasCSRHistory: false,
      		showLoader: true,
      		loaderHiding: ''
		};
		this.selectCountry = this.selectCountry.bind(this);
		this.selectState = this.selectState.bind(this);
		this.selectCity = this.selectCity.bind(this);
		this.changeYear = this.changeYear.bind(this);
		this.changeAmount = this.changeAmount.bind(this);
		this.communityInvestmentPercentageChange = this.communityInvestmentPercentageChange.bind(this);
		this.volunteerEngagementPercentageChange = this.volunteerEngagementPercentageChange.bind(this);
		this.trainingAndDevelopmentPercentageChange = this.trainingAndDevelopmentPercentageChange.bind(this);
		this.changeRange = this.changeRange.bind(this);
		this.changeLocation = this.changeLocation.bind(this);
		this.changeLatLng = this.changeLatLng.bind(this);
		this.changePreferedGeographiesSetting = this.changePreferedGeographiesSetting.bind(this);
		this.addPreferedGeographiesLocation = this.addPreferedGeographiesLocation.bind(this);
		this.changePreferedGoals = this.changePreferedGoals.bind(this);
		this.changePreferedAgendas = this.changePreferedAgendas.bind(this);
		this.changePreferedProjectSize = this.changePreferedProjectSize.bind(this);
		this.changePreferedProjectAmount = this.changePreferedProjectAmount.bind(this);
		this.changePreferedNgoSpecifications = this.changePreferedNgoSpecifications.bind(this);
		this.changePreferedProjectDuration = this.changePreferedProjectDuration.bind(this);
		this.changePreferedNgoCSRHistory = this.changePreferedNgoCSRHistory.bind(this);
		this.changePreferedNgoOlder = this.changePreferedNgoOlder.bind(this);
		this.changeAllPreferedAgendas = this.changeAllPreferedAgendas.bind(this);
		this.removeGeographiesLocation = this.removeGeographiesLocation.bind(this);
	}

	nextStep(doSave) {
		let currentStep = this.state.currentStep;
		if(doSave) this.save(currentStep);
		currentStep += 1;
		this.setState({ currentStep });
	}

	prevStep() {
		let currentStep = this.state.currentStep;
		currentStep -= 1;
		this.setState({ currentStep });
	}

	save(currentStep) {
		if(currentStep === 0) {
			if(this.state.country !== '' && this.state.state !== '' && this.state.city !== '' && this.state.csrBudgets.length !== 0) {
				let selectedCSRBudgetIndex = this.state.selectedCSRBudgetIndex;
				if(parseInt(this.state.csrBudgets[selectedCSRBudgetIndex].amount) > 0) {
					let data = {};
					data.country = this.state.country;
					data.state = this.state.state;
					data.city = this.state.city;
					let csrBudget = {};
					csrBudget.year = parseInt(this.state.csrBudgets[selectedCSRBudgetIndex].year.split('-')[0]);
					csrBudget.amount = parseInt(this.state.csrBudgets[selectedCSRBudgetIndex].amount);
					csrBudget.communityInvestment = this.state.csrBudgets[selectedCSRBudgetIndex].communityInvestmentPercentage;
					// csrBudget.volunteerEngagement = this.state.csrBudgets[selectedCSRBudgetIndex].volunteerEngagementPercentage;
					// csrBudget.trainingAndDevelopment = this.state.csrBudgets[selectedCSRBudgetIndex].trainingAndDevelopmentPercentage;
					csrBudget.capacityBuildingAndOtherExpenses = this.state.csrBudgets[selectedCSRBudgetIndex].capacityBuildingAndOtherExpenses;
					data.csrBudget = csrBudget;
					let companyId = JSON.parse(readCookie('userData')).organisationData.id;
					fetch(process.env.REACT_APP_API_URL + '/company/' + companyId + '/mis/preferences', {
						method: "POST",
						headers: {
							'Content-Type': 'application/json',
							'Auth': JSON.parse(readCookie('access_token')).access_token
						},
						body: JSON.stringify(data)
					}).then(function(response) {
						return response.json();
					}).then(function(data) {
					});
				}
			}
		} else if(currentStep === 1) {
			if(this.state.preferedGeographiesSetting.length !== 0 && this.state.preferedGeographiesLocation.length !== 0) {
				let data = {};
				data.investmentSetting = this.state.preferedGeographiesSetting;
				let geographies = [];
				for(var i = 0; i < this.state.preferedGeographiesLocation.length; i++) {
					let geography = {};
					geography.latitude = this.state.preferedGeographiesLocation[i].latLng.lat;
					geography.longitude = this.state.preferedGeographiesLocation[i].latLng.lng;
					geography.radius = parseInt(this.state.preferedGeographiesLocation[i].range);
					geography.location = this.state.preferedGeographiesLocation[i].preferenceLocation;
					geographies.push(geography);
				}
				data.geographies = geographies;
				let companyId = JSON.parse(readCookie('userData')).organisationData.id;
				fetch(process.env.REACT_APP_API_URL + '/company/' + companyId + '/mis/preferences', {
					method: "POST",
					headers: {
						'Content-Type': 'application/json',
						'Auth': JSON.parse(readCookie('access_token')).access_token
					},
					body: JSON.stringify(data)
				}).then(function(response) {
					return response.json();
				}).then(function(data) {
				});
			}
		} else if(currentStep === 2) {
			if(this.state.preferedGoals.length !== 0 && this.state.preferedProjectDuration.length !== 0 && this.state.preferedNgoSpecs.length !== 0 && this.state.projectsSizeType !== null && this.state.projectsSizeAmount !== 0) {
				let data = {};
				data.goals = this.state.preferedGoals;
				data.agendas = this.state.preferedAgendas;

				let ngoSpecifications = {};
				if(this.state.ngoIsOlder) ngoSpecifications.older = 3;
				ngoSpecifications.section = this.state.preferedNgoSpecs;
				ngoSpecifications.csrHistory = this.state.hasCSRHistory;
				data.ngoSpecifications = ngoSpecifications;

				let projectSpecifications = {};
				projectSpecifications.duration = this.state.preferedProjectDuration;

				let size = {};
				size.type = this.state.projectsSizeType;
				size.amount = this.state.projectsSizeAmount;

				projectSpecifications.size = size;

				data.projectSpecifications = projectSpecifications;

				let companyId = JSON.parse(readCookie('userData')).organisationData.id;
				fetch(process.env.REACT_APP_API_URL + '/company/' + companyId + '/mis/preferences', {
					method: "POST",
					headers: {
						'Content-Type': 'application/json',
						'Auth': JSON.parse(readCookie('access_token')).access_token
					},
					body: JSON.stringify(data)
				}).then(function(response) {
					return response.json();
				}).then(function(data) {
					this.notify('Your Preferences has been saved!');
				}.bind(this));
			}
		}
	}

	selectCountry(country) {
		this.setState({ country });
	}

	selectState(state) {
		this.setState({ state });
	}

	selectCity(city) {
		this.setState({ city })
	}

  changeYear(year) {
  	let csrBudgets = this.state.csrBudgets;

  	let found = false;
  	let foundIndex = -1;
  	for(var i = 0; i < csrBudgets.length; i++) {
  		if(csrBudgets[i].year === year.target.value) {
  			found = true;
  			foundIndex = i;
  			break;
  		}
  	}
  	if(!found) {
  		let csrBudget = {};

  		csrBudget['year'] = year.target.value;
			csrBudget['amount'] = 0;
			csrBudget['communityInvestmentPercentage'] = 0;
			// csrBudget['volunteerEngagementPercentage'] = 0;
			// csrBudget['trainingAndDevelopmentPercentage'] = 0;
			csrBudget['capacityBuildingAndOtherExpenses'] = 100;
			csrBudgets.push(csrBudget);
			foundIndex = csrBudgets.length - 1;
  		this.setState({ csrBudgets });
  	}
  	this.setState({ selectedCSRBudgetIndex: foundIndex });
  }

  changeAmount(amount) {
  	let csrBudgets = this.state.csrBudgets;
  	let selectedCSRBudgetIndex = this.state.selectedCSRBudgetIndex;

  	if(selectedCSRBudgetIndex !== null) {
  		csrBudgets[selectedCSRBudgetIndex].amount = amount.target.value;
  		this.setState({ csrBudgets });
  	}
  }

  communityInvestmentPercentageChange(value) {
  	let csrBudgets = this.state.csrBudgets;
  	let selectedCSRBudgetIndex = this.state.selectedCSRBudgetIndex;

  	if(selectedCSRBudgetIndex !== null) {
	  	let totalPercentageAllocated = csrBudgets[selectedCSRBudgetIndex].volunteerEngagementPercentage + csrBudgets[selectedCSRBudgetIndex].trainingAndDevelopmentPercentage + value;
	  	if(totalPercentageAllocated <= 100) {
	  		csrBudgets[selectedCSRBudgetIndex].communityInvestmentPercentage = value;
		  	csrBudgets[selectedCSRBudgetIndex].othersPercentage = 100 - totalPercentageAllocated;

		  	this.setState({ csrBudgets });
		  }
		}
  }

  volunteerEngagementPercentageChange(value) {
  	let csrBudgets = this.state.csrBudgets;
  	let selectedCSRBudgetIndex = this.state.selectedCSRBudgetIndex;

  	if(selectedCSRBudgetIndex !== null) {
	  	let totalPercentageAllocated = csrBudgets[selectedCSRBudgetIndex].communityInvestmentPercentage + csrBudgets[selectedCSRBudgetIndex].trainingAndDevelopmentPercentage + value;
	  	if(totalPercentageAllocated <= 100) {
	  		csrBudgets[selectedCSRBudgetIndex].volunteerEngagementPercentage = value;
		  	csrBudgets[selectedCSRBudgetIndex].othersPercentage = 100 - totalPercentageAllocated;

		  	this.setState({ csrBudgets });
		  }
		}
  }

  trainingAndDevelopmentPercentageChange(value) {
  	let csrBudgets = this.state.csrBudgets;
  	let selectedCSRBudgetIndex = this.state.selectedCSRBudgetIndex;

  	if(selectedCSRBudgetIndex !== null) {
	  	let totalPercentageAllocated = csrBudgets[selectedCSRBudgetIndex].communityInvestmentPercentage + csrBudgets[selectedCSRBudgetIndex].volunteerEngagementPercentage + value;
	  	if(totalPercentageAllocated <= 100) {
	  		csrBudgets[selectedCSRBudgetIndex].trainingAndDevelopmentPercentage = value;
		  	csrBudgets[selectedCSRBudgetIndex].othersPercentage = 100 - totalPercentageAllocated;

		  	this.setState({ csrBudgets });
		  }
		}
  }

  changePreferedGeographiesSetting(doAdd, id) {
  	let preferedGeographiesSetting = this.state.preferedGeographiesSetting;
  	if(doAdd) {
  		preferedGeographiesSetting.push(id);
  	} else {
  		var index = preferedGeographiesSetting.indexOf(id);

	    if (index > -1) {
	      preferedGeographiesSetting.splice(index, 1);
	    }
  	}
  	this.setState({ preferedGeographiesSetting });
  }

  changeRange(index, e) {
  	let preferedGeographiesLocation = this.state.preferedGeographiesLocation;
  	preferedGeographiesLocation[index].range = e.target.value;
  	this.setState({ preferedGeographiesLocation });
  }

  changeLocation(index, value) {
  	let preferedGeographiesLocation = this.state.preferedGeographiesLocation;
  	preferedGeographiesLocation[index].preferenceLocation = value;
  	this.setState({ preferedGeographiesLocation });
  }

  changeLatLng(index, value) {
  	let preferedGeographiesLocation = this.state.preferedGeographiesLocation;
  	preferedGeographiesLocation[index].latLng = value;
  	this.setState({ preferedGeographiesLocation });
  }

  addPreferedGeographiesLocation() {
  	let preferedGeographiesLocation = this.state.preferedGeographiesLocation;
  	let preferedGeographiesLocationJSON = {};
		preferedGeographiesLocationJSON['preferenceLocation'] = '';
		preferedGeographiesLocationJSON['preferenceLocationError'] = '';
		preferedGeographiesLocationJSON['latLng'] = {};
		preferedGeographiesLocationJSON['range'] = '';
		preferedGeographiesLocation.push(preferedGeographiesLocationJSON);
		this.setState({ preferedGeographiesLocation });
  }

  removeGeographiesLocation(index) {
  	let preferedGeographiesLocation = this.state.preferedGeographiesLocation;
  	preferedGeographiesLocation.splice(index, 1);
  	this.setState({ preferedGeographiesLocation });
  }

  changePreferedGoals(doAdd, id) {
  	let preferedGoals = this.state.preferedGoals;
  	if(doAdd) {
  		preferedGoals.push(id);
  	} else {
  		var index = preferedGoals.indexOf(id);

	    if (index > -1) {
	       preferedGoals.splice(index, 1);
	    }
  	}
  	this.setState({ preferedGoals });
  }

  changePreferedAgendas(doAdd, id) {
  	let preferedAgendas = this.state.preferedAgendas;
  	if(doAdd) {
  		preferedAgendas.push(id);
  	} else {
  		var index = preferedAgendas.indexOf(id);

	    if (index > -1) {
	       preferedAgendas.splice(index, 1);
	    }
  	}
  	this.setState({ preferedAgendas });
  }

  changeAllPreferedAgendas(goalId) {
  	let preferedAgendas = this.state.preferedAgendas.slice();
  	for(var i = 0; i < this.state.allAgendas.length; i++) {
  		if(this.state.allAgendas[i]._goalId === goalId) {
  			if(preferedAgendas.indexOf(this.state.allAgendas[i]._id) === -1) preferedAgendas.push(this.state.allAgendas[i]._id);
  			else preferedAgendas.splice(preferedAgendas.indexOf(this.state.allAgendas[i]._id), 1);
  		}
  	}
  	this.setState({ preferedAgendas: null }, function() {
  		this.setState({ preferedAgendas });
  	}.bind(this));
  }

  changePreferedProjectSize(e) {
  	this.setState({ projectsSizeType: e.target.value });
  }

  changePreferedProjectAmount(e) {
  	this.setState({ projectsSizeAmount: e.target.value });
  }

  changePreferedNgoSpecifications(doAdd, id) {
  	let preferedNgoSpecs = this.state.preferedNgoSpecs;
  	if(doAdd) {
  		preferedNgoSpecs.push(id);
  	} else {
  		var index = preferedNgoSpecs.indexOf(id);

	    if (index > -1) {
	       preferedNgoSpecs.splice(index, 1);
	    }
  	}
  	this.setState({ preferedNgoSpecs });
  }

  changePreferedProjectDuration(doAdd, id) {
  	let preferedProjectDuration = this.state.preferedProjectDuration;
  	if(doAdd) {
  		preferedProjectDuration.push(id);
  	} else {
  		var index = preferedProjectDuration.indexOf(id);

	    if (index > -1) {
	       preferedProjectDuration.splice(index, 1);
	    }
  	}
  	this.setState({ preferedProjectDuration });
  }

  changePreferedNgoCSRHistory() {
  	this.setState({ hasCSRHistory: !this.state.hasCSRHistory });
  }

  changePreferedNgoOlder() {
  	this.setState({ ngoIsOlder: !this.state.ngoIsOlder });
  }

	componentDidMount() {
		fetch(process.env.REACT_APP_API_URL + '/getOtAttributes', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
		.then(function(data) {
			let allGeographiesSetting = [];
			let allGoals = [];
			let allAgendas = [];
			let allProjectDuration = [];
			let allNgoSpecs = [];
			for(var i = 0; i < data.otAttributes.length; i++) {
				if(data.otAttributes[i].type === 'setting') allGeographiesSetting.push(data.otAttributes[i]);
				else if(data.otAttributes[i].type === 'goal') allGoals.push(data.otAttributes[i]);
				else if(data.otAttributes[i].type === 'agenda') allAgendas.push(data.otAttributes[i]);
				else if(data.otAttributes[i].type === 'projectDuration') allProjectDuration.push(data.otAttributes[i]);
				else if(data.otAttributes[i].type === 'ngoSection') allNgoSpecs.push(data.otAttributes[i]);
			}

			this.setState({ allGeographiesSetting, allGoals, allAgendas, allProjectDuration, allNgoSpecs, preferedGeographiesSetting: [] });

			let companyId = JSON.parse(readCookie('userData')).organisationData.id;
			fetch(process.env.REACT_APP_API_URL + '/company/' + companyId + '/mis/preferences', {
				method: "GET",
				headers: {
					'Auth': JSON.parse(readCookie('access_token')).access_token
				}
			}).then((data) => data.json())
			.then(function(data) {
				if(data.status === 'ok') {
					let preferences = data.data;

					let city = preferences.city;
					let state = preferences.state;
					let country = preferences.country;
					let preferedGoals = [];
					let preferedAgendas = [];
					let preferedProjectDuration = [];
					let projectsSizeType = null;
					let projectsSizeAmount = 0;
					let hasCSRHistory = false;
					let ngoIsOlder = false;
					let preferedNgoSpecs = [];
					let csrBudgets = [];
					let preferedGeographiesLocation = [];
					let preferedGeographiesSetting = [];
					let selectedCSRBudgetIndex = 0;

					if(preferences.hasOwnProperty('csrBudget')) {
						for(var i = 0; i < preferences.csrBudget.length; i++) {
							let currentYearBudget = {};

							if(parseInt(preferences.csrBudget[i].year) === parseInt(moment().year())) selectedCSRBudgetIndex = i;
							currentYearBudget['year'] = preferences.csrBudget[i].year + '-' + (preferences.csrBudget[i].year + 1);
							currentYearBudget['amount'] = preferences.csrBudget[i].amount;
							currentYearBudget['communityInvestmentPercentage'] = preferences.csrBudget[i].communityInvestment;
							currentYearBudget['volunteerEngagementPercentage'] = preferences.csrBudget[i].volunteerEngagement;
							currentYearBudget['trainingAndDevelopmentPercentage'] = preferences.csrBudget[i].trainingAndDevelopment;
							currentYearBudget['othersPercentage'] = preferences.csrBudget[i].others;
							csrBudgets.push(currentYearBudget);
						}
						if(preferences.csrBudget.length === 0) {
							let currentYearBudget = {};
							currentYearBudget['year'] = moment().year() + '-' + moment().add(1, 'years').year();
							currentYearBudget['amount'] = 0;
							currentYearBudget['communityInvestmentPercentage'] = 0;
							currentYearBudget['volunteerEngagementPercentage'] = 0;
							currentYearBudget['trainingAndDevelopmentPercentage'] = 0;
							currentYearBudget['othersPercentage'] = 100;
							csrBudgets.push(currentYearBudget);
						}
					} else {
						let currentYearBudget = {};
						currentYearBudget['year'] = moment().year() + '-' + moment().add(1, 'years').year();
						currentYearBudget['amount'] = 0;
						currentYearBudget['communityInvestmentPercentage'] = 0;
						currentYearBudget['volunteerEngagementPercentage'] = 0;
						currentYearBudget['trainingAndDevelopmentPercentage'] = 0;
						currentYearBudget['othersPercentage'] = 100;
						csrBudgets.push(currentYearBudget);
					}

					if(preferences.hasOwnProperty('geographies')) {
						for(var i = 0; i < preferences.geographies.length; i++) {
					  	let preferedGeographiesLocationJSON = {};
							preferedGeographiesLocationJSON['preferenceLocation'] = preferences.geographies[i].location;
							preferedGeographiesLocationJSON['preferenceLocationError'] = '';
							let latLng = {};
							latLng.lat = preferences.geographies[i].latitude;
							latLng.lng = preferences.geographies[i].longitude;

							preferedGeographiesLocationJSON['latLng'] = latLng;
							preferedGeographiesLocationJSON['range'] = preferences.geographies[i].radius;
							preferedGeographiesLocation.push(preferedGeographiesLocationJSON);
						}
						if(preferences.geographies.length === 0) this.addPreferedGeographiesLocation();
					} else this.addPreferedGeographiesLocation();

					if(preferences.hasOwnProperty('investmentSetting')) {
						for(var i = 0; i < preferences.investmentSetting.length; i++) {
							preferedGeographiesSetting.push(preferences.investmentSetting[i].id);
						}
					}

					if(preferences.hasOwnProperty('goals')) {
						for(var i = 0; i < preferences.goals.length; i++) {
							preferedGoals.push(preferences.goals[i].id);
						}
					}

					if(preferences.hasOwnProperty('agendas')) {
						for(var i = 0; i < preferences.agendas.length; i++) {
							preferedAgendas.push(preferences.agendas[i].id);
						}
					}

					if(preferences.hasOwnProperty('projectSpecifications')) {
						if(preferences.projectSpecifications.hasOwnProperty('duration')) {
							for(var i = 0; i < preferences.projectSpecifications.duration.length; i++) {
								preferedProjectDuration.push(preferences.projectSpecifications.duration[i].id);
							}
						}
						if(preferences.projectSpecifications.hasOwnProperty('size')) {
							projectsSizeType = preferences.projectSpecifications.size.type;
							projectsSizeAmount = preferences.projectSpecifications.size.amount;
						}
					}

					if(preferences.hasOwnProperty('ngoSpecifications')) {
						hasCSRHistory = preferences.ngoSpecifications.csrHistory;
						if(preferences.ngoSpecifications.hasOwnProperty('older')) {
							ngoIsOlder = preferences.ngoSpecifications.older;
						}
						if(preferences.ngoSpecifications.hasOwnProperty('section')) {
							for(var i = 0; i < preferences.ngoSpecifications.section.length; i++) {
								preferedNgoSpecs.push(preferences.ngoSpecifications.section[i].id);
							}
						}
					}

					this.setState({
						preferences, csrBudgets, selectedCSRBudgetIndex, city,
						state, country, preferedGoals, preferedAgendas, preferedNgoSpecs,
						preferedProjectDuration, preferedGeographiesLocation,
						preferedGeographiesSetting, projectsSizeType, projectsSizeAmount,
						hasCSRHistory, ngoIsOlder }, function() {
							if(this.props.match.params.fiscalYear !== undefined) {
								let year = {};
								let target = {};
								target['value'] = this.props.match.params.fiscalYear;
								year['target'] = target;
								this.changeYear(year);
							}
					}.bind(this));
				}
			}.bind(this));

		}.bind(this));
	}

	basicInformationReady() {
		this.setState({ loaderHiding: 'hiding' }, function() {
			var that = this;
			setTimeout(function() {
        that.setState({ showLoader: false });
      }.bind(this), 100);
    }.bind(this))
	}

	notify = (text) => toast.success(text , {
		onClose: (childrenProps) => window.location.pathname = "/dashboard"
	});

	render() {
		return (
			<div className="mis-content-container">
				{this.state.showLoader ? (
		      <Loader loaderHiding={this.state.loaderHiding} />
		    ) : (null)}
				<div className='step-progress'>
	        <Steps current={this.state.currentStep}>
			      <Step title="Basic Information" />
			      <Step title="Investment Information" />
			      <Step title="Other Information" />
			    </Steps>
	    	</div>
	    	<div className="step-content">
	    		<ToastContainer position="bottom-center" type="success" autoClose={4000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={false} />
	    		{(() => {
	    			if(this.state.currentStep === 0 && this.state.csrBudgets.length) {
	    				return (
	    					<BasicInformation country={this.state.country} state={this.state.state} city={this.state.city}
	    						selectCountry={this.selectCountry} changeYear={this.changeYear} changeAmount={this.changeAmount}
	    						selectState={this.selectState} selectCity={this.selectCity} csrBudget={this.state.csrBudgets[this.state.selectedCSRBudgetIndex]}
	    						communityInvestmentPercentageChange={this.communityInvestmentPercentageChange} basicInformationReady={this.basicInformationReady.bind(this)}
	    						volunteerEngagementPercentageChange={this.volunteerEngagementPercentageChange}
	    						trainingAndDevelopmentPercentageChange={this.trainingAndDevelopmentPercentageChange} />
	    				);
	    			} else if(this.state.currentStep === 1 && this.state.allGeographiesSetting !== null) {
	    				return (
	    					<InvestmentInformation allGeographiesSetting={this.state.allGeographiesSetting}
	    						preferedGeographiesSetting={this.state.preferedGeographiesSetting}
	    						preferedGeographiesLocation={this.state.preferedGeographiesLocation}
	    						changePreferedGeographiesSetting={this.changePreferedGeographiesSetting}
	    						changeRange={this.changeRange} changeLocation={this.changeLocation} changeLatLng={this.changeLatLng}
	    						addPreferedGeographiesLocation={this.addPreferedGeographiesLocation}
	    						removeGeographiesLocation={this.removeGeographiesLocation} />
	    				);
	    			} else if(this.state.currentStep === 2 && this.state.allGoals !== null && this.state.allAgendas !== null && this.state.allProjectDuration !== null && this.state.allNgoSpecs !== null) {
	    				return (
	    					<OtherInformation allGoals={this.state.allGoals} allAgendas={this.state.allAgendas}
	    						allProjectDuration={this.state.allProjectDuration} allNgoSpecs={this.state.allNgoSpecs}
	    						preferedGoals={this.state.preferedGoals} preferedAgendas={this.state.preferedAgendas}
	    						preferedProjectDuration={this.state.preferedProjectDuration} preferedNgoSpecs={this.state.preferedNgoSpecs}
	    						projectsSizeType={this.state.projectsSizeType} projectsSizeAmount={this.state.projectsSizeAmount}
	    						changePreferedGoals={this.changePreferedGoals} changePreferedAgendas={this.changePreferedAgendas}
	    						changePreferedProjectSize={this.changePreferedProjectSize} hasCSRHistory={this.state.hasCSRHistory}
	    						changePreferedProjectAmount={this.changePreferedProjectAmount} ngoIsOlder={this.state.ngoIsOlder}
	    						changePreferedNgoSpecifications={this.changePreferedNgoSpecifications}
	    						changePreferedProjectDuration={this.changePreferedProjectDuration}
	    						changePreferedNgoCSRHistory={this.changePreferedNgoCSRHistory}
	    						changeAllPreferedAgendas={this.changeAllPreferedAgendas}
	    						changePreferedNgoOlder={this.changePreferedNgoOlder} />
	    				);
	    			}
	    		})()}
	    	</div>
	    	<div className="mb20">
	    		{this.state.currentStep !== 0 ? (
	    			<button className="btn btn-primary" type="button" onClick={this.prevStep.bind(this)}>Prev</button>
	    		) : (null)}
	    		{this.state.currentStep !== 2 ? (
	    			<div>
		    			<button className="btn btn-primary pull-right" type="button" onClick={this.nextStep.bind(this, false)}>Next</button>
		    			<button className="btn btn-primary pull-right" type="button" onClick={this.nextStep.bind(this, true)}>Save & Next</button>
		    		</div>
	    		): (null)}
	    		{this.state.currentStep === 2 ? (
	    			<button className="btn btn-primary pull-right" type="button" onClick={this.save.bind(this, 2)}>Save</button>
	    		): (null)}
	    	</div>
			</div>
		);
	}
}