export const updateCauses = (causes) => {
	return {
		causes,
		type: 'UPDATE_CAUSES'
	}
};

export const updateFilteredCauses = (filteredCauses) => {
	return {
		filteredCauses,
		type: 'UPDATE_FILTERED_CAUSES'
	}
};

export const updateSearchTerm = (searchTerm) => {
	return {
		searchTerm,
		type: 'UPDATE_SEARCH_TERM'
	}
};

export const updateSelectedCause = (selectedCause) => {
	return {
		selectedCause,
		type: 'UPDATE_SELECTED_CAUSE'
	}
};

export const updateProjects = (projects) => {
	return {
		projects,
		type: 'UPDATE_PROJECTS'
	}
};