import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Row, Col} from 'react-bootstrap';

import $ from 'jquery';

export default class CauseContainer extends Component {
	componentDidMount() {
		$(document).ready(function() {
			$('.cause-container .rating-responses-div > div.col-md-3 i.fa').hover(function() {
				if ($(this).hasClass('fa-star')) $(this).addClass('fa-star-o').removeClass('fa-star');
				else if ($(this).hasClass('fa-star-o')) $(this).addClass('fa-star').removeClass('fa-star-o');
			}, function() {
				if ($(this).hasClass('fa-star')) $(this).addClass('fa-star-o').removeClass('fa-star');
				else if ($(this).hasClass('fa-star-o')) $(this).addClass('fa-star').removeClass('fa-star-o');
			});
		});
	}

	render() {
		return (
			<div className="cause-container">
				<div className="cause-image col-md-3" style={{backgroundImage: `url(${this.props.cause.representativeMedia})`}}></div>
				<div className="cause-details col-md-9">
					<Link className="cause-name"
					      to={"/csr/cause/" + this.props.cause.customUrl}
					      onClick={this.props.updateSelectedCause.bind(this, this.props.cause)}>
						{this.props.cause.title}
					</Link>
					<p className="cause-desc">{this.props.cause.description}</p>
					<div className="row">
						<Row className="m0">
							<div className="features col-md-6">
								<div className="heading">Target Beneficiaries</div>
								<div className="content">
									{this.props.cause.beneficiaryTypes.map(function(beneficiaryType, i) {
										if(this.props.cause.beneficiaryTypes.length - i === 1) return beneficiaryType.name;
										else return beneficiaryType.name + ', '
									}.bind(this))}
								</div>
							</div>
							<div className="features col-md-6">
								<div className="heading">Causes</div>
								<div className="content">
									{this.props.cause.causes.map(function(cause, i) {
										if(this.props.cause.causes.length - i === 1) return cause.name;
										else return cause.name + ', '
									}.bind(this))}
								</div>
							</div>
						</Row>
						<div className="features col-md-6">
							<Link to={"/csr/cause/" + this.props.cause.customUrl} onClick={this.props.updateSelectedCause.bind(this, this.props.cause)}>
								<button className="btn btn-know-more">Know More</button>
							</Link>
						</div>
					</div>
				</div>
			</div>
		);
	}
}