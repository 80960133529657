import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import CorporateDashboard from './CorporateDashboard';

import * as action from '../../../actions/CorporateAction';

const readCookie = require('../../../cookie.js').readCookie;

class Corporate extends Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		fetch(process.env.REACT_APP_API_URL + '/volunteer-opportunity/interests/company/59aed7bedc7b656cfe96c4fe', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
		.then(function(data) {
			this.props.actions.updateInterests(data.data);
		}.bind(this));
	}

	render() {
		return (
			<div className="container">
				<CorporateDashboard interests={this.props.interests}/>
			</div>
		);
	}
}

Corporate.propTypes = {
	interests: PropTypes.array
};

function mapStateToProps(state) {
	return {
		interests: state.Corporate.interests
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(action, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Corporate);