export const changeLoginStatus = (loginStatus) => {
	return {
		loginStatus,
		type: 'CHANGE_LOGIN_STATUS'
	}
};


export const changeSelectedMenuItem = (selectedMenuItem) => {
	return {
		selectedMenuItem,
		type: 'CHANGE_SELECTED_MENU_ITEM'
	}
};

export const updateCorporateUsers = (corporateUsers) => {
	return {
		corporateUsers,
		type: 'UPDATE_CORPORATE_USERS'
	}
};

export const updateNgoUsers = (ngoUsers) => {
	return {
		ngoUsers,
		type: 'UPDATE_NGO_USERS'
	}
};