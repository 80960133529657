import React, { Component } from 'react';
import moment from 'moment';
import ReactPlayer from 'react-player';


export default class ImageModal extends Component {
	constructor(props){
		super(props);
		this.state = {
			imgArray: this.props.imgArray,
			imgIndex: (this.props.imageIndex !== undefined ? this.props.imageIndex : 0),
			typeOf: (this.props.typeOf !== undefined ? this.props.typeOf : 'image')
		}
	}

	componentDidMount() {
		if(!this.props.openModal) {
			this.setState({ imgIndex: 0 })
		}
	}

	showNext(e) {
		let currIndex = this.state.imgIndex;
		currIndex = currIndex + 1;
		if(currIndex > (this.props.imgArray.length - 1)) {
			this.setState({ imgIndex: 0 });
		} else {
			this.setState({ imgIndex: currIndex });
		}
	}

	showPrev() {
		let currIndex = this.state.imgIndex;
		currIndex = currIndex - 1;
		if(currIndex < 0) {
			this.setState({ imgIndex: this.props.imgArray.length - 1 });
		} else {
			this.setState({ imgIndex: currIndex });
		}
	}

	forceDownload(blob, filename) {
	 var a = document.createElement('a');
	 a.download = filename;
	 a.href = blob;
	 a.click();
	}

	// Current blob size limit is around 500MB for browsers
	 downloadResource(url, filename) {
	 	if(filename === ""){
	 		filename = "download"
	 	}
	 if (!filename) filename = url.split('\\').pop().split('/').pop();
	 fetch(url, {
	 		headers:{
       	'Origin': window.location.origin
	     },
	     mode: 'cors',
	   })
	   .then(response => response.blob())
	   .then(blob => {
	     let blobUrl = window.URL.createObjectURL(blob);
	     this.forceDownload(blobUrl, filename);
	   })
	   .catch(e => console.error(e));
	}

	render() {
		return (
			<div className="im-updates-images-modal-container">
				{this.state.imgArray[this.state.imgIndex] !== undefined ? (
					<div className="im-updates-images">
						<div className={this.props.hasDate !== false ? "modal-left" : "modal-left full"}>
							<div className={this.state.imgArray[this.state.imgIndex - 1] === undefined ? "prev disabled" : "prev"} onClick={this.showPrev.bind(this)}>
								<i className="fa fa-chevron-left"></i>
							</div>

							<div className="image-container">
								{this.state.imgArray[this.state.imgIndex].type === "image" ? (
									typeof this.state.imgArray[this.state.imgIndex] === 'string' && !this.state.imgArray[this.state.imgIndex].hasOwnProperty('url') ? (
										<div className="image" style={{ backgroundImage: 'url(' + this.state.imgArray[this.state.imgIndex] + ')' }}></div>
									) : (
										<div className="image" style={{ backgroundImage: 'url(' + this.state.imgArray[this.state.imgIndex].url + ')' }} 
										// onClick={this.downloadResource.bind(this, this.state.imgArray[this.state.imgIndex].url, this.state.imgArray[this.state.imgIndex].title)}
										></div>
									)
								) : (
									typeof this.state.imgArray[this.state.imgIndex] === 'string' && !this.state.imgArray[this.state.imgIndex].hasOwnProperty('url') ? (
										<ReactPlayer className="image" url={this.state.imgArray[this.state.imgIndex]} width="auto" height="500px"	/>
									) : (
										<ReactPlayer className="image" url={this.state.imgArray[this.state.imgIndex].url} width="auto" height="500px"	/>
									)
								)}
							</div>

							<div className={this.state.imgArray[this.state.imgIndex + 1] === undefined ? "next disabled" : "next"} onClick={this.showNext.bind(this)}>
								<i className="fa fa-chevron-right"></i>
							</div>

							<div className="image-count">{(this.state.imgIndex + 1) + ' / ' + this.state.imgArray.length}</div>
						</div>
						{this.props.hasDate !== false ? (
							<div className="modal-right">
								<div className="modal-right-content">
									<div style={{ width: "4em", height: "4em", borderRadius: "50%", backgroundSize: "cover", backgroundRepeat: "no-repeat", marginRight: 8, backgroundImage: "url(/img/user.png)" }}></div>
									<div className="modal-user-name">
										{this.state.imgArray[this.state.imgIndex].title !== undefined ? (
											this.state.imgArray[this.state.imgIndex].title
										) : (null)}
										<span className="image-details">{moment(this.state.imgArray[this.state.imgIndex].date).format('MMM Do YY, h:mm a')}</span>
									</div>
								</div>
								{this.state.imgArray[this.state.imgIndex].description !== undefined ? (
									<div className="modal-image-description">
										{this.state.imgArray[this.state.imgIndex].description}
										{this.state.imgArray[this.state.imgIndex].type === "image" ? (<button className='image-download-button' onClick={this.downloadResource.bind(this, this.state.imgArray[this.state.imgIndex].url, this.state.imgArray[this.state.imgIndex].title)}>Download Image</button> ): null}
									</div>
								) : (null)}
							</div>
						) : (null)}
					</div>
				) : (
					<div>No images found!</div>
				)}
			</div>
		)
	}
}