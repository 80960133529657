import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Row, Col} from 'react-bootstrap';
import swal from 'sweetalert2';

import ShowInterestModal from './SingleCSRProject/ShowInterestModal';

const checkValue = require('../../../checkValue.js').checkValue;
const readCookie = require('../../../cookie.js').readCookie;

export default class CSRProjectContainer extends Component {
	constructor(props) {
    super(props);
    this.state = {
      canShowInterest: null,
	    showInterestModal: false,
	    showInterestMessage: ''
    }
  }

	// shortlistProject() {
	// 	let companyId = JSON.parse(readCookie('userData')).organisationData.id;
	// 	let entityType = 'project';
	// 	let entityId = this.props.project.id;
	// 	let data = { companyId, entityId, entityType };

	// 	fetch(process.env.REACT_APP_API_URL + '/shortlist', {
	// 		method: "POST",
	// 		headers: {
	// 			'Content-Type': 'application/json',
	// 			'Auth': JSON.parse(readCookie('access_token')).access_token
	// 		},
	// 		body: JSON.stringify(data)
	// 	}).then(function(response) {
	// 		return response.json();
	// 	}).then(function(data) {
	// 		if(data.status === 'ok') {
	// 			swal(
	// 				'Awesome!',
	// 				'This project has been added to your shortlist.',
	// 				'success'
	// 			)
	// 		}
	// 	}.bind(this))
	// }

	showInterest() {
		if(this.state.showInterestMessage !== '') {
			let data = {};
			data['companyId'] = JSON.parse(readCookie('userData')).organisationData.id;
			data['message'] = this.state.showInterestMessage;

			fetch(process.env.REACT_APP_API_URL + '/project/' + this.props.project.id + '/interests', {
				method: "POST",
				headers: {
					'Content-Type': 'application/json',
					'Auth': JSON.parse(readCookie('access_token')).access_token
				},
				body: JSON.stringify(data)
			}).then(function(response) {
				return response.json();
			}).then(function(data) {
				if(data.status === 'ok') {
					swal(
						'Awesome!',
						'Your interest in this project has been taken into account.',
						'success'
					)
					this.setState({ showInterestModal: false, showInterestMessage: '', canShowInterest: false });
				}
			}.bind(this));
		}
	}

	componentDidMount() {
		let companyId = JSON.parse(readCookie('userData')).organisationData.id;
		let projectId = this.props.project.id;
		fetch(process.env.REACT_APP_API_URL + '/project/interests/company/' + companyId, {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
		.then(function(data) {
			if(data.status === 'ok') {
				let canShowInterest = true;
				for(var i = 0; i < data.data.length; i++) {
					if(data.data[i].project.id === projectId) {
						canShowInterest = false;
						this.setState({ canShowInterest: false });
					}
				}
				if(canShowInterest) {
					this.setState({canShowInterest: true});
				}
			}
		}.bind(this));
	}

	onShowInterestClose(data) {
    this.setState({ showInterestModal: false, showInterestMessage: '' });
  }

  onShowInterestOpen() {
    this.setState({ showInterestModal: true });
  }

  updateMessage(e) {
  	this.setState({ showInterestMessage: e.target.value });
  }

	render() {
		if(this.props.discoverCSRProjectsListView) {
			return (
				<Row className="single-project csr-project">
					<Col md={3}>
						<Link to={"/csr/csr-projects/" + this.props.project.customUrl}>
							<img className="csr-project-image" src={this.props.project.photo ? this.props.project.photo : "https://assets.letsendorse.com/sahaj/project-placeholder.jpg"} />
							{!this.props.project.isAvailable ? (
	            	<div className="single-csr-project-unavailable">
	                <span className="current-state-outer submitted">
	                  <span className="outer-arrow"> </span>
	                  <span className="current-state-text">not available</span>
	         		    </span>
	            	</div>
	            ) : (null)}
	          </Link>
					</Col>
					<Col md={6}>
						<Col md={12} className="project-title">
							<Link to={"/csr/csr-projects/" + this.props.project.customUrl}><span>{this.props.project.title}</span></Link>
						</Col>
						<Col md={12} className="project-description">
							<p>{this.props.project.description}</p>
						</Col>
						{checkValue(this.props.project.targetBeneficiaries) !== true ? (
							<Col md={12} className="features" hidden={checkValue(this.props.project.targetBeneficiaries)}>
								<div className="heading">Target beneficiaries: </div>
								<div className="content">
									{this.props.project.targetBeneficiaries.map(function(targetBeneficiary, i) {
										if(this.props.project.targetBeneficiaries.length - i === 1) return targetBeneficiary.name
										else return targetBeneficiary.name + ', '
									}.bind(this))}
								</div>
							</Col>
						) : (null)}
						{checkValue(this.props.project.goalData) !== true ? (
							<Col md={12} className="features" hidden={checkValue(this.props.project.goalData.name)}>
								<div className="heading">Cause/Developmental Goal: </div>
								<div className="content">{this.props.project.goalData.name}</div>
							</Col>
						) : (null)}
						<Col md={12} className="features">
							{this.state.canShowInterest !== null ? (
								this.state.canShowInterest === false ? (
									<button className="btn btn-know-more" disabled={true}>Already Shown Interest</button>
								) : (
									<button className="btn btn-know-more" onClick={this.onShowInterestOpen.bind(this)}>Show Interest</button>
								)
							) : (null)}
							<Link to={"/csr/csr-projects/" + this.props.project.customUrl}>
								<button className="btn btn-know-more">Know More</button>
							</Link>
						</Col>
					</Col>
					<Col md={3}>
						{this.props.project.location.length ? (
							<Col md={12} className="project-location">
								<div>
									<i className="fa fa-map-marker"></i><b>{' Location: '}</b>
									<span>{' ' + this.props.project.location[0].address}</span>
								</div>
								<span className="project-address">
									{this.props.project.location.map(function(location, index) {
										if(index !== 0) {
											return (
												<div key={index}>{location.address}</div>
											)
										}
									}.bind(this))}
								</span>
							</Col>
						) : (null)}
						<Col md={12} className="project-budget-con" hidden={checkValue(this.props.project.proposedBudget)}>
							<i className="fa fa-money"></i>
							<span className="project-budget">
								{this.props.project.hasOwnProperty('currencySymbol') ? (
									<span><b>{' Budget: '}</b>{this.props.project.currencySymbol + ' ' + this.props.project.proposedBudget}</span>
								) : (
									<span><b>{' Budget: '}</b>{'INR ' + this.props.project.proposedBudget}</span>
								)}
							</span>
						</Col>
						<Col md={12} className="project-duration-container" hidden={checkValue(this.props.project.expectedDuration)}>
							<i className="fa fa-calendar"></i>
							<span className="project-duration"><b>{' Duration: '}</b>{this.props.project.expectedDuration}</span>
						</Col>
					</Col>
					{this.state.canShowInterest ? (
						<ShowInterestModal showInterestMessage={this.state.showInterestMessage}
              showInterestModal={this.state.showInterestModal}
              updateMessage={this.updateMessage.bind(this)}
              showInterest={this.showInterest.bind(this)}
              onShowInterestClose={this.onShowInterestClose.bind(this)}
              onShowInterestOpen={this.onShowInterestOpen.bind(this)} />
					) : (null)}
				</Row>
			);
		} else {
			return (
				<Row className="single-project csr-project">
					<Col md={4}>
						<Link to={"/csr/csr-projects/" + this.props.project.customUrl}>
							<img className="csr-project-image" src={this.props.project.photo ? this.props.project.photo : "https://assets.letsendorse.com/sahaj/project-placeholder.jpg"} />
							{!this.props.project.isAvailable ? (
	            	<div className="single-csr-project-unavailable">
	                <span className="current-state-outer submitted">
	                  <span className="outer-arrow"> </span>
	                  <span className="current-state-text">not available</span>
	         		    </span>
	            	</div>
	            ) : (null)}
	          </Link>
					</Col>
					<Col md={8}>
						<Col md={12} className="project-title">
							<Link to={"/csr/csr-projects/" + this.props.project.customUrl}><span>{this.props.project.title}</span></Link>
						</Col>
						<Col md={12} className="project-description">
							<p>{this.props.project.description}</p>
						</Col>
						{checkValue(this.props.project.targetBeneficiaries) !== true ? (
							<Col md={12} className="features" hidden={checkValue(this.props.project.targetBeneficiaries)}>
								<div className="heading">Target beneficiaries: </div>
								<div className="content">
									{this.props.project.targetBeneficiaries.map(function(targetBeneficiary, i) {
										if(this.props.project.targetBeneficiaries.length - i === 1) return targetBeneficiary.name
										else return targetBeneficiary.name + ', '
									}.bind(this))}
								</div>
							</Col>
						) : (null)}
						{checkValue(this.props.project.goalData) !== true ? (
							<Col md={12} className="features" hidden={checkValue(this.props.project.goalData.name)}>
								<div className="heading">Cause/Developmental Goal: </div>
								<div className="content">{this.props.project.goalData.name}</div>
							</Col>
						) : (null)}
						<Col md={12} className="features">
							{this.state.canShowInterest !== null ? (
								this.state.canShowInterest === false ? (
									<button className="btn btn-know-more" disabled={true}>Already Shown Interest</button>
								) : (
									<button className="btn btn-know-more" onClick={this.onShowInterestOpen.bind(this)}>Show Interest</button>
								)
							) : (null)}
							<Link to={"/csr/csr-projects/" + this.props.project.customUrl}>
								<button className="btn btn-know-more">Know More</button>
							</Link>
						</Col>
					</Col>
					{this.state.canShowInterest ? (
						<ShowInterestModal showInterestMessage={this.state.showInterestMessage}
              showInterestModal={this.state.showInterestModal}
              updateMessage={this.updateMessage.bind(this)}
              showInterest={this.showInterest.bind(this)}
              onShowInterestClose={this.onShowInterestClose.bind(this)}
              onShowInterestOpen={this.onShowInterestOpen.bind(this)} />
					) : (null)}
				</Row>
			);
		}
	}
}