import React, {Component} from 'react';
import {Col} from 'react-bootstrap';
import DocumentTitle from 'react-document-title';

const readCookie = require('../../cookie.js').readCookie;

export default class ClassificationModule extends Component {
	constructor(props) {
		super(props);
		this.state = {
			mis_access: false,
			csr_access: false
		}
	}

	gotoCSRLanding() {
		window.location.pathname = '/csr';
	}

	gotoMISLanding() {
		window.location.pathname = '/';
	}

	componentDidMount() {
		var mis_access = JSON.parse(readCookie('userData')).authorizedFor.hasOwnProperty('mis');
		var csr_access = JSON.parse(readCookie('userData')).authorizedFor.hasOwnProperty('marketplace');
		this.setState({ mis_access, csr_access });
	}

	render() {
		return (
			<DocumentTitle title="CSR | MIS">
				<div className="classification-modules">
					{this.state.csr_access ? (
						<div className="classification-module csr" onClick={this.gotoCSRLanding.bind(this)}>
							<div className="csr"></div>
							Corporate Social Responsibility
						</div>
					) : (null)}
					{this.state.mis_access ? (
						<div className="classification-module mis" onClick={this.gotoMISLanding.bind(this)}>
							<div className="mis"></div>
							Management Information System
						</div>
					) : (null)}
				</div>
			</DocumentTitle>
		);
	}
}