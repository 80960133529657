const initialState = {
	ngosCount: 0,
	companiesCount: 0,
	projectsCount: 0,
	volunteerOpportunitiesCount: 0,
	featured: null,
};

const LandingReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'UPDATE_DASHBOARD_COUNT':
			return Object.assign({}, state, {
				ngosCount: action.ngosCount,
				companiesCount: action.companiesCount,
				projectsCount: action.projectsCount,
				volunteerOpportunitiesCount: action.volunteerOpportunitiesCount,
			});
		case 'UPDATE_FEATURED':
			return Object.assign({}, state, {
				featured: action.featured
			});
		default:
			return state
	}
}

export default LandingReducer;