import React, { Component } from 'react';
import { Row, Col, Tab, Tabs, Modal } from 'react-bootstrap';
import swal from 'sweetalert2';
import $ from 'jquery';
import _ from 'lodash';
import moment from 'moment';
import { Select } from 'antd';
import { Menu } from 'antd';
import Scroll from 'react-scroll';
import Icon from 'react-icons-kit';
import { ToastContainer, toast } from "react-toastify";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, BarChart, Bar } from "recharts";
import { BootstrapTable, TableHeaderColumn, ButtonGroup, ExportCSVButton } from 'react-bootstrap-table';
import io from "socket.io-client";
import Loader from '../../Loader';
import ReactGA from 'react-ga';

import ReactPlayer from 'react-player';

import * as action from '../../../../actions/DiscoverCSRProjectsAction';

import SingleCSRProjectMap from "../../../CSR/6-DiscoverCSRProjects/SingleCSRProject/SingleCSRProjectMap";
import ShowInterestModal from '../../../CSR/6-DiscoverCSRProjects/SingleCSRProject/ShowInterestModal';
import ReleaseAmountTable from './ReleaseAmountTable';
import NotificationModal from './NotificationModal';
import ReportExpenseModal from './ReportExpenseModal';
import ImageModal from './ImageModal';
import GraphModal from './GraphModal';
import AddDocModal from './AddDocModal';

import { ic_add_box } from 'react-icons-kit/md/ic_add_box';
import { ic_edit } from 'react-icons-kit/md/ic_edit';
import { ic_delete } from 'react-icons-kit/md/ic_delete';

const checkValue = require('../../../../checkValue.js').checkValue;
const readCookie = require('../../../../cookie.js').readCookie;
const Option = Select.Option;

let Link = Scroll.Link;
let plottedGraph = null;

let year = moment().year();
const years = [];
for (var i = -3; i < 10; i++) {
	var yearStr = (year - i) + '-' + (year - i + 1);
	years.push(yearStr);
}

export default class SingleMISProject extends Component {
	constructor(props) {
		super(props);
		this.state = {
			graphPlot: null,
			showGraph: false,
			openGraphModal: false,
			projectUpdates: [],
			milestones: [],
			keyIndicator: false,
			dateArray: null,
			kpiData: null,
			beneficiaries: null,
			sortByDate: null,
			keyIndicator: null,
			readMore: false,
			readMoreSocialImpact: false,
			readMoreEconomicImpact: false,
			canShowInterest: true,
			amountBreakup: null,
			ShowInterestModal: false,
			ShownotificationModal: false,
			notificationContent: {},
			showInterestMessage: '',
			chatContainerOpen: false,
			chats: null,
			chatReason: null,
			projectStatus: 'noAction',
			ngoId: null,
			companyId: this.props.userData.organisationData.id,
			projectId: null,
			discussion: '',
			conversationId: null,
			corporateInterestStatus: 'noAction',
			corporateInvestment: {},
			confirmInvestment: false,
			current: 'resources',
			releasedAmountBreakup: [],
			reportExpenseModalVisibility: false,
			reportType: '',
			expenseData: null,
			editExpense: false,
			imgArray: [],
			openModal: false,
			showLoader: false,
			loaderHiding: '',
			showProjectBudgetDetails: false,
			activeTab: 1,
			albums: [],
			onlyOneAlbum: false,
			other_album: null,
			doc_resources: [],
			showAlbumsView: true,
			albumImages: [],
			loadingAlbumImages: false,
			selectedImageIndex: 0,
			selectedAlbum: null,
			addDocModalVisibility: false,
			downloadAlbum: false,
			csrBudget: null,
			selectedCSRBudgetIndex: null,
			QueryYear: null,
		}
		this.chatReasonRef = null;

		this.setChatReasonRef = element => {
			this.chatReasonRef = element;
		};

		this.focusChatReason = () => {
			// Focus the text input using the raw DOM API
			if (this.chatReasonRef) this.chatReasonRef.focus();
		};
	}

	componentWillMount() {
		this.initSocket();
		let companyId = JSON.parse(readCookie('userData')).organisationData.id;
		fetch(process.env.REACT_APP_API_URL + '/company/' + companyId + '/mis/preferences', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
		.then((data) => {
			let csrBudgets = [];
			let selectedCSRBudgetIndex = 0;
			let preferences = data.data;

			if (preferences.hasOwnProperty('csrBudget')) {
				for (var i = 0; i < preferences.csrBudget.length; i++) {
					let currentYearBudget = {};
					if (parseInt(preferences.csrBudget[i].year) === parseInt(this.getCurrentFiscalYear().split('-')[0])) selectedCSRBudgetIndex = i;
					currentYearBudget['year'] = preferences.csrBudget[i].year + '-' + (preferences.csrBudget[i].year + 1);
					currentYearBudget['amount'] = preferences.csrBudget[i].amount;
					currentYearBudget['communityInvestmentPercentage'] = preferences.csrBudget[i].communityInvestment;
					currentYearBudget['volunteerEngagementPercentage'] = preferences.csrBudget[i].volunteerEngagement;
					currentYearBudget['trainingAndDevelopmentPercentage'] = preferences.csrBudget[i].trainingAndDevelopment;
					currentYearBudget['othersPercentage'] = preferences.csrBudget[i].others;
					csrBudgets.push(currentYearBudget);
				}
				if (preferences.csrBudget.length === 0) {
					let currentYearBudget = {};
					currentYearBudget['year'] = this.getCurrentFiscalYear();
					currentYearBudget['amount'] = 0;
					currentYearBudget['communityInvestmentPercentage'] = 0;
					currentYearBudget['volunteerEngagementPercentage'] = 0;
					currentYearBudget['trainingAndDevelopmentPercentage'] = 0;
					currentYearBudget['othersPercentage'] = 100;
					csrBudgets.push(currentYearBudget);
				}
			} else {
				let currentYearBudget = {};
				currentYearBudget['year'] = this.getCurrentFiscalYear();
				currentYearBudget['amount'] = 0;
				currentYearBudget['communityInvestmentPercentage'] = 0;
				currentYearBudget['volunteerEngagementPercentage'] = 0;
				currentYearBudget['trainingAndDevelopmentPercentage'] = 0;
				currentYearBudget['othersPercentage'] = 100;
				csrBudgets.push(currentYearBudget);
			}
			let QueryYear = sessionStorage.getItem("selectedFiscalYear")
			sessionStorage.removeItem("selectedFiscalYear");
			if (QueryYear === null) {
				this.setState({ QueryYear: this.getCurrentFiscalYear() });
			} else {
				this.setState({ QueryYear	});
				let found = false;
				let foundIndex = -1;
				for (var i = 0; i < csrBudgets.length; i++) {
					if(csrBudgets[i].year === QueryYear) {
						found = true;
						foundIndex = i;
						break;
					}
				}
				if(!found) {
					let csrBudget = {};
					csrBudget['year'] = QueryYear;
					csrBudget['amount'] = 0;
					csrBudget['communityInvestmentPercentage'] = 0;
					csrBudget['volunteerEngagementPercentage'] = 0;
					csrBudget['trainingAndDevelopmentPercentage'] = 0;
					csrBudget['othersPercentage'] = 100;
					csrBudgets.push(csrBudget);
					foundIndex = csrBudgets.length - 1;
					selectedCSRBudgetIndex = csrBudgets.length - 1
				}
			}

			this.setState({ csrBudgets, csrBudget: csrBudgets[selectedCSRBudgetIndex], selectedCSRBudgetIndex });
		});
	}

	getCurrentFiscalYear() {
    var today = new Date();
    var curMonth = today.getMonth();
    var fiscalYr = "";
    if (curMonth > 3) {
      return today.getFullYear().toString() + '-' + (today.getFullYear() + 1).toString();
    } else {
      return (today.getFullYear() - 1).toString() + '-' + today.getFullYear().toString();
    }
  }

	initSocket = () => {
		const socket = io(process.env.REACT_APP_API_URL)
		socket.on('connect', () => {
			socket.emit('authentication', { token: JSON.parse(readCookie('access_token')).access_token });
      socket.on('authenticated', function () {
				this.setState({ socket });

				let csr_namespace = "CSR_" + this.state.companyId;

				socket.on(csr_namespace, (data) => {
					if (data.length === undefined) {
						let new_chat = this.state.chats;
						new_chat.push(data);
						let uniq = _.uniq(new_chat, '_id');
						this.setState({ chats: uniq, discussion: '' });
					} else {
						let new_chat = [];
						let uniq_msg = [];
						new_chat.push(this.state.chats[0]);
						uniq_msg = new_chat.concat(data);
						this.setState({ chats: uniq_msg });
					}
				});
			}.bind(this));
		});
	}

	componentDidMount() {
		this.focusChatReason();

		if(this.props.match.params.hasOwnProperty('albumId') && this.props.match.params.albumId && this.props.match.params.albumId !== "0") this.setState({ showAlbumsView: false, loadingAlbumImages: true, activeTab: 2 })

		if (this.props.match.params.name) {
			let path = this.props.match.params.name;
			fetch(process.env.REACT_APP_API_URL + '/get-project/' + path, {
				method: "GET",
				headers: {
					'Auth': JSON.parse(readCookie('access_token')).access_token
				}
			}).then((data) => data.json())
			.then(function (data) {
				if (data.status === 'ok') {
					if (data.data.hasOwnProperty('corporateInvestment')) {
						let corporateInvestment = {};
						if (typeof data.data.corporateInvestment.companyId !== 'undefined' && typeof data.data.corporateInvestment.investmentId !== 'undefined' && typeof data.data.corporateInvestment.portfolioId !== 'undefined') {
							corporateInvestment['companyId'] = data.data.corporateInvestment.companyId;
							corporateInvestment['investmentId'] = data.data.corporateInvestment.investmentId;
							corporateInvestment['portfolioId'] = data.data.corporateInvestment.portfolioId;
							this.setState({ confirmInvestment: true });
						}
						this.setState({ corporateInvestment });
					}

					let corporateInterestStatus = data.data.corporateInterestStatus;
					if (corporateInterestStatus !== false) {
						this.setState({ corporateInterestStatus });
					} else {
						this.setState({ corporateInterestStatus: 'noAction' });
					}

					let releasedAmountBreakup = [];
					if (data.data.hasOwnProperty('releasedAmountBreakup') && data.data.releasedAmountBreakup.length > 0) {
						releasedAmountBreakup = data.data.releasedAmountBreakup;
					}
					this.setState({ releasedAmountBreakup });

					this.setState({ ngoId: data.data.ngoData.id, projectId: data.data.id, selectedProject: data.data });

					let projectId = data.data.id;
					let companyId = JSON.parse(readCookie('userData')).organisationData.id;
					fetch(process.env.REACT_APP_API_URL + '/project/' + projectId + '/reported-expenses', {
						method: "GET",
						headers: {
							'Auth': JSON.parse(readCookie('access_token')).access_token
						}
					}).then((data) => data.json())
					.then(function (data) {
						if (data.status === 'ok') {
							let amountBreakup = data.expenseItems;
							this.setState({ amountBreakup });
						}
					}.bind(this));

					fetch(process.env.REACT_APP_API_URL + '/project/interests/company/' + companyId, {
						method: "GET",
						headers: {
							'Auth': JSON.parse(readCookie('access_token')).access_token
						}
					}).then((data) => data.json())
					.then(function (data) {
						if (data.status === 'ok') {
							let canShowInterest = true;
							for (var i = 0; i < data.data.length; i++) {
								if (data.data[i].project.id === projectId) {
									canShowInterest = false;
									this.setState({ canShowInterest: true });
								}
							}
							if (canShowInterest) {
								this.setState({ canShowInterest: true });
							}
						}
					}.bind(this));

					fetch(process.env.REACT_APP_API_URL + '/project/' + projectId + '/milestone', {
						method: "GET",
						headers: {
							"Content-Type": "application/json",
							'Auth': JSON.parse(readCookie('access_token')).access_token
						}
					}).then(res => (res.json()))
					.then(data => {
						this.setState({ milestones: data.data });
					}).catch(er => {
					});

					fetch(process.env.REACT_APP_API_URL + '/project/' + projectId + '/beneficiaries', {
						method: 'GET',
						headers: {
							'Auth': JSON.parse(readCookie('access_token')).access_token
						}
					}).then(res => (res.json()))
					.then(data => {
						if(data.status === 'ok') {
							this.setState({ beneficiaries: data.beneficiaries });
						}
					}).catch(er => {
					});

					fetch(process.env.REACT_APP_API_URL + '/project/key-assesment/' + projectId, {
						method: 'GET',
						headers: {
							'Auth': JSON.parse(readCookie('access_token')).access_token
						}
					}).then(res => (res.json()))
					.then(data => {
						if(data.status === 'ok') {
							this.setState({ kpiData: data.data });
						}
					}).catch(er => {
					});

					fetch(process.env.REACT_APP_API_URL + '/project/' + projectId + '/resources?type=doc', {
						method: 'GET',
						headers: {
							'Auth': JSON.parse(readCookie('access_token')).access_token
						}
					}).then(res => (res.json()))
					.then(data => {
						if(data.status === 'ok') {
							this.setState({ doc_resources: data.data });
						}
					}).catch(er => {
					});

					let otherAlbumPromise = fetch(process.env.REACT_APP_API_URL + '/project/' + projectId + '/resources?type=image', {
						method: 'GET',
						headers: {
							'Auth': JSON.parse(readCookie('access_token')).access_token
						}
					}).then(res => (res.json()))
					.then(data => {
						if(data.status === 'ok') {
							this.setState({ other_album: data.data });
						}
					}).catch(er => {
					});

					fetch(process.env.REACT_APP_API_URL + '/project/' + projectId + '/resources?type=album', {
						method: 'GET',
						headers: {
							'Auth': JSON.parse(readCookie('access_token')).access_token
						}
					}).then(res => (res.json()))
					.then(data => {
						if(data.status === 'ok') {
							let albums = data.data;
							let otherAlbum = {
								id: "0",
								title: "Other Single Images",
								description: "Other Images",
								date: "",
								url: "/img/others_album.png"
							};

							if(albums.length) {
								if(this.props.match.params.hasOwnProperty('albumId') && this.props.match.params.albumId) {
									let selectedAlbum = null;
									for(var i = 0; i < albums.length; i++) {
										if(albums[i].id === this.props.match.params.albumId) selectedAlbum = albums[i];
									}
									if(selectedAlbum === null) window.location.pathname = "/mis-projects/" + this.props.match.params.name + "/resources";
									else {
										this.setState({ loadingAlbumImages: true, selectedAlbum, onlyOneAlbum: false }, function() {
											fetch(process.env.REACT_APP_API_URL + '/project/' + this.state.projectId + '/resources?albumId=' + selectedAlbum.id, {
												method: 'GET',
												headers: {
													'Auth': JSON.parse(readCookie('access_token')).access_token
												}
											}).then(res => (res.json()))
											.then(data => {
												// console.l0g(data.data);
												if(data.status === 'ok') {
													this.setState({ showAlbumsView: false, albumImages: data.data, activeTab: 2 }, function() {
														var that = this;
														setTimeout(function() {
															that.setState({loadingAlbumImages: false});
														}, 500);
													}.bind(this));
												}
											}).catch(er => {
											});
										}.bind(this));
									}
								} else {
									albums.push(otherAlbum);
									this.setState({ albums, onlyOneAlbum: false });
								}
							} else {
								if(this.props.match.params.hasOwnProperty('albumId')) window.location.pathname = "/mis-projects/" + this.props.match.params.name + "/resources";
								else {
									var that = this;
									Promise.all([otherAlbumPromise]).then(function() {
										that.setState({ showAlbumsView: false, albumImages: that.state.other_album, selectedAlbum: otherAlbum, onlyOneAlbum: true });
									});
								}
							}
						}
					}).catch(er => {
					});
				}
			}.bind(this));
		} else {
			window.location = "/404";
		}
	}

	updateReleaseAmountBreakup() {
		let path = window.location.pathname;
		path = path.split('/').pop();
		fetch(process.env.REACT_APP_API_URL + '/get-project/' + path, {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
		.then(function (data) {
			if (data.status === 'ok') {
				let releasedAmountBreakup = [];
				if (data.data.hasOwnProperty('releasedAmountBreakup') && data.data.releasedAmountBreakup.length > 0) {
					releasedAmountBreakup = data.data.releasedAmountBreakup;
				}
				this.setState({ releasedAmountBreakup });
			}
		}.bind(this));
	}

	showInterest(value) {
		if (this.state.showInterestMessage !== '') {
			let data = {};
			data['companyId'] = JSON.parse(readCookie('userData')).organisationData.id;
			data['message'] = this.state.showInterestMessage;
			data["ngoId"] = this.state.ngoId;
			fetch(process.env.REACT_APP_API_URL + '/project/' + this.state.selectedProject.id + '/interests', {
				method: "POST",
				headers: {
					'Content-Type': 'application/json',
					'Auth': JSON.parse(readCookie('access_token')).access_token
				},
				body: JSON.stringify(data)
			}).then(function (response) {
				return response.json();
			}).then(function (data) {
				if (data.status === 'ok') {
					swal(
						'Awesome!',
						'Your interest in this project has been taken into account.',
						'success'
					)
					this.setState({ InvestedModal: false, showInterestMessage: '', canShowInterest: true });
				}
			}.bind(this));
		}
		this.projectStatusChange(value);
	}

	onShowInterestClose(data) {
		this.setState({ ShowInterestModal: false, showInterestMessage: '' });
	}

	onShowInterestOpen(data) {
		this.setState({ ShowInterestModal: true, showInterestMessage: '' });
	}

	onShownotification(data) {
		this.setState({ ShownotificationModal: true });
	}

	onClosenotification(data) {
		this.setState({ ShownotificationModal: false });
	}

	updateMessage(e) {
		this.setState({ showInterestMessage: e.target.value });
	}

	clearSelectedProject() {
		let projectJSON = {};
		this.props.actions.updateSelectedProject(projectJSON);
	}

	toggleChatContainerOpen() {
		this.setState({ chatContainerOpen: !this.state.chatContainerOpen });
		let uri = process.env.REACT_APP_API_URL + "/chat/csr-ngo/" + this.state.companyId;
		let chat_info = {
			projectId: this.state.projectId
		}
		fetch(uri, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				// 'Auth': JSON.parse(readCookie('mis_data')).access_token
			},
			body: JSON.stringify(chat_info)
		}).then(res => (res.json()))
		.then(res => {
			//let arr = recent_chat.concat(res.data);
			this.setState({ chats: res.data, conversationId: res.data[0]._conversationId });
		}).catch(err => {
			console.log("err", err);
		});
	}

	startChat() {
		if (this.state.chatReason !== null && this.state.chatReason !== '') {
			let data = {};
			data['companyId'] = JSON.parse(readCookie('userData')).organisationData.id;
			data['message'] = this.state.chatReason;
			data["ngoId"] = this.state.ngoId;
			fetch(process.env.REACT_APP_API_URL + '/project/' + this.state.selectedProject.id + '/interests', {
				method: "POST",
				headers: {
					'Content-Type': 'application/json',
					'Auth': JSON.parse(readCookie('access_token')).access_token
				},
				body: JSON.stringify(data)
			}).then(function (response) {
				return response.json();
			}).then(function (data) {
				if (data.status === 'ok') {
					swal(
						'Awesome!',
						'Your interest in this project has been taken into account.',
						'success'
					)
					this.setState({ chats: [] });
				}
			}.bind(this));
		}
		else {
			this.focusChatReason();
		}
	}

	changeChatReason(e) {
		this.setState({ chatReason: e.target.value });
	}

	projectStatusChange(value) {
		var reactGAObj = {
		  category: 'Project',
		  action: 'click',
		  label: value
		};
		if (value === 'Invested') {
			reactGAObj['value'] = 1;
		}
		ReactGA.event(reactGAObj);

		let data = {};
		let notificationContent = {};
		if (value === 'Interested') {
			notificationContent.text = 'This project shall move to “Interested” state. You can start a chat with the NGO for further discussions now.';
			notificationContent.status = 'interested';
			this.setState({ notificationContent }, () => {
				this.onShownotification();
			});
		} else if (value === 'Under Review') {
			notificationContent.text = 'This project shall move to “Under review” state. You could initiate the due diligence process and sign the MoU in this stage.';
			notificationContent.status = 'under-review';
			this.setState({ notificationContent }, () => {
				this.onShownotification();
			});
		} else if (value === 'Skipped') {
			notificationContent.text = 'This action would move the project out of your projects funnel. You can add this project back later by showing interest, if needed.';
			notificationContent.status = 'skipped';
			this.setState({ notificationContent }, () => {
				this.onShownotification();
			});
		} else if (value === 'Invested') {
			this.onShowInterestOpen();
		}
	}

	handleConfirm(value, status) {
		if (value === 'Confirm') {
			let data = {
				status: status,
				user_id: this.props.userData.id,
				fullName: this.props.userData.fullName
			}
			fetch(process.env.REACT_APP_API_URL + '/project/' + this.state.projectId + '/interests', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Auth': JSON.parse(readCookie('access_token')).access_token
				},
				body: JSON.stringify(data)
			}).then(data => data.json())
			.then(data => {
				if (data.status === 'ok') {
					let corporateInvestment = {};
					let corporateInterestStatus = data.data.status
					corporateInvestment['portfolioId'] = data.data.portfolioId;
					corporateInvestment['investmentId'] = data.data.investmentId;
					this.setState({ corporateInterestStatus, confirmInvestment: true, corporateInvestment });
				} else {
					this.setState({ confirmInvestment: false });
				}
			});
		} else {
			this.setState({ confirmInvestment: false });
		}
		this.onClosenotification();
	}

	sendMessage(event) {
		if (event.key === 'Enter') {
			const { socket } = this.state;
			let data = {
				"case": "saveLastChat",
				"_ngoId": this.state.ngoId,
				"_conversationId": this.state.conversationId,
				"_companyId": this.state.companyId,
				"discussion": this.state.discussion,
				"sender": "CSR"
			}
			socket.emit("CHAT_HANDLERS", data);
			this.setState({ discussion: '' })
		}
	}

	getSocketDone(event) {
		const { socket } = this.state;
		if (this.state.companyId === null || this.state.ngoId === null || this.state.conversationId === null) {
			return;
		}
		let data = {
			"case": "getLastChat",
			"_ngoId": this.state.ngoId,
			"_conversationId": this.state.conversationId,
			"_companyId": this.state.companyId,
			"discussion": this.state.discussion,
			"sender": "CSR"
		}
		socket.emit("CHAT_HANDLERS", data);
	}

	handleAgree(value) {
		if (value === 'Confirm') {
			let data = {
				status: 'invested'
			}
			fetch(process.env.REACT_APP_API_URL + '/project/' + this.state.projectId + '/interests', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Auth': JSON.parse(readCookie('access_token')).access_token
				},
				body: JSON.stringify(data)
			}).then(data => data.json())
				.then(data => {
					if (data.status === 'ok') {
						let corporateInvestment = {};
						let corporateInterestStatus = data.data.status
						corporateInvestment['portfolioId'] = data.data.portfolioId;
						corporateInvestment['investmentId'] = data.data.investmentId;
						this.setState({ corporateInterestStatus, confirmInvestment: true, corporateInvestment });
					} else {
						this.setState({ confirmInvestment: false });
					}
				})
		} else {
			this.setState({ confirmInvestment: false });
		}
		this.onShowInterestClose();
	}

	showAssessmentData(data, event) {
		let sortById = _.filter(this.state.assessmentdata, (el, i) => (el.value[0].kpi === event));
		let finalSort = _.filter(sortById, (el, i) => (
			data.date === el.date
		))
		if (finalSort.length > 0) {
			return finalSort[0].value[0].unit
		} else {
			return "0";
		}
	}

	showGraphTable(row) {
		this.setState({ openGraphModal: true })
		let graph_data = _.filter(this.state.keyIndicator, (el, i) => (row === el.value[0].kpi));
		if (graph_data.length > 0) {
			let data = {
				"graph_data": graph_data
			}
			plottedGraph = data;
		} else {
			return 0;
		}
	}

	addPlotGraphButton = (row) => {
		return (
			<button className="btn btn-info" onClick={this.showGraphTable.bind(this, row)}>Plot Graph</button>
		)
	}

	handlePathChange = () => {
		if (this.state.corporateInvestment.portfolioId !== undefined && this.state.corporateInvestment.investmentId !== undefined) {
			ReactGA.event({
			  category: 'Project',
			  action: 'click',
			  label: 'Invest Funds'
			});
			window.location.pathname = "/edit-investment/" + this.state.corporateInvestment.portfolioId + "/" + this.state.corporateInvestment.investmentId + "/invest";
		} else {
			this.notifyError("Please try again!");
		}
	}

	exportPdf() {
		ReactGA.event({
		  category: 'View Project',
		  action: 'view',
		  label: 'Export Project'
		});
		$(`<div class="modal-backdrop downloadAlbumModal"><p class='downloadAlbum-loader-text'><img class='downloadAlbum-loader' src="/img/Spinner-white.svg"/> Getting your files ready....</p></div>`).appendTo(document.body);
		fetch(process.env.REACT_APP_API_URL + '/project/' + this.state.projectId + '/export-project', {
			method: 'GET',
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then(data => data.json())
		.then(data => {
			$(".modal-backdrop").remove();
			if (data.status === 'ok') {
				swal({
    			html: '<img src="/img/report_image.png" style="height: 120px; margin: 20px 0;" />' +
						'<div style="font-size: 26px; letter-spacing: 0.5px; margin: 0 0 20px;">The exported project is ready to be downloaded!</div>' +
						'<a class="btn" style="background: #3085d6; color: #fff; font-size: 22px !important; letter-spacing: 0.5px; padding: 10px 20px; width: 240px; margin: 0 0 10px;" href="' + data.pdfUrl + '" target="_blank">Download</a>',
    			showCancelButton: false,
    			showConfirmButton: false
    		});
			}
		});
	}

	downloadAlbum = (e) => {
		let data = {}
		data['albumId'] = e
		$(`<div class="modal-backdrop downloadAlbumModal"><p class='downloadAlbum-loader-text'><img class='downloadAlbum-loader' src="/img/Spinner-white.svg"/> Getting your files ready....</p></div>`).appendTo(document.body);
		fetch(process.env.REACT_APP_API_URL + '/project/' + this.state.projectId + '/export-album', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
			body: JSON.stringify(data)
		}).then(data => data.json())
		.then(data => {
			if (data.status === 'ok') {
				$(".modal-backdrop").remove();
				swal({
    			html: '<img src="/img/album-image.png" style="height: 120px; margin: 20px 0;" />' +
						'<div style="font-size: 26px; letter-spacing: 0.5px; margin: 0 0 20px;">The Album is ready to be downloaded!</div>',
    			showCancelButton: false,
    			showConfirmButton: true,
    			confirmButtonText: 'Download'
    		}).then(isConfirm =>{
    			window.open(data.url);
    		})
			} else {
				$(".modal-backdrop").remove();
				this.notifyError("Failed to download, " + data.message)
			}
		});
	}

	notifyError = text => toast.error(text);

	notifySuccess = text => toast.success(text);

	costHeadFormatter(cell, row) {
		return cell.name;
	}

	typeFormatter(cell, row) {
		if (cell) return cell.type;
		else return null;
	}

	budgetedFormatter(cell, row) {
		if (cell) return 'Yes';
		else if (row.isTotal) return '';
		else return 'No';
	}

	proposedAmountFormatter(cell, row) {
		if (cell) return window.inrFormat(cell);
		else return null;
	}

	reportedAmountFormatter(cell, row) {
		if (cell) return window.inrFormat(cell);
		else return null;
	}

	expenseStatusFormatter(cell, row) {
		if (cell !== '' && cell !== 'N/A') return cell + ' Payment';
		else if (cell === 'N/A') return cell;
		else return '';
	}

	percentageUtilizedFormatter(cell, row) {
		if (row.budgeted || row.isTotal) {
			if(cell) return cell + '%';
			else return '0%';
		} else return 'N/A';
	}

	unitEconomicsFormatter(cell, row) {
		if (row.unitEconomics && cell) return cell;
		else return '';
	}

	billsFormatter(cell, row) {
		if (row.isTotal) return null;
		else if (cell === undefined) return '';
		else if (cell.length) return (
			<button className="btn table-btn" onClick={this.editExpense.bind(this, 'view', row)}>
				<i className="fa fa-file-text-o"></i>
				Show Bills
			</button>
		);
		else return row.billStatus;
	}

	editExpense(reportType, expenseData) {
		this.setState({ expenseData, editExpense: true }, function () {
			this.showReportExpenseModal(reportType);
		}.bind(this));
	}

	showReportExpenseModal(reportType) {
		this.setState({ reportExpenseModalVisibility: true, reportType });
	}

	hideReportExpenseModal(type) {
		this.setState({ reportExpenseModalVisibility: false, reportType: '', editExpense: false });
	}

	onCloseModal() {
		this.setState({ openModal: false })
	}
	onOpenModal() {
		this.setState({ openModal: true });
	}
	viewImages(event, data) {
		this.setState({ imgArray: event, openModal: true })
	}
	onCloseGraphModal() {
		this.setState({ openGraphModal: false })
	}
	chatWithNgo() {
		if(this.state.corporateInterestStatus !== false && this.state.corporateInterestStatus !== 'noAction') {
			ReactGA.event({
			  category: 'View Project',
			  action: 'view',
			  label: 'Chat'
			});
			window.location.pathname = "/discussion/" + this.state.projectId;
		}
	}

	handleKPIGraphTypeChange(index, value) {
		let kpiData = this.state.kpiData;
		kpiData[index]['graphType'] = value;
		this.setState({ kpiData });
	}

	renderCusomizedLegend = (name, unit, props) => {
		const { payload } = props;
    return (
      <div className="customized-legend">
      	<div className="legend-box" style={{backgroundColor: payload[0].color}}></div>
      	{unit && unit !== '' && unit !== 'None' ? (
      		<div className="legend-text">{name.charAt(0).toUpperCase() + name.slice(1) + ' (' + unit + ')'}</div>
      	) : (
      		<div className="legend-text">{name.charAt(0).toUpperCase() + name.slice(1)}</div>
      	)}
      </div>
    )
	}

	toggleDetailsView(name) {
		if(this.state[name]) $('.' + name).hide('slow');
		else $('.' + name).show('slow');
		this.setState({ [name]: !this.state[name] });
	}

	handleClick(key) {
		ReactGA.event({
		  category: 'View Project',
		  action: 'view',
		  label: key.charAt(0).toUpperCase() + key.slice(1)
		});
		if(this.state.selectedProject.hasOwnProperty('customUrl') && this.state.selectedProject.customUrl) window.location.href = window.location.origin + "/mis-projects/" + this.state.selectedProject.customUrl + "#" + key;
		else window.location.href = window.location.origin + "/mis-projects/" + this.state.projectId + "#" + key;
	}

	dateFormatter(cell, row) {
		if(cell) return moment(cell).format('DD/MM/YYYY HH:mm A');
		else return 'N/A';
	}

	urlFormatter(cell, row) {
		if(cell) {
			return (
				<a href={cell} target="_blank" className="btn view-doc-btn">View Document</a>
			)
		} else {
			return 'N/A';
		}
	}

	showAlbumImages(album) {
		if(album.id === "0") {
			this.setState({ selectedAlbum: album, showAlbumsView: false, albumImages: this.state.other_album });
		} else {
			this.setState({ loadingAlbumImages: true, selectedAlbum: album }, function() {
				fetch(process.env.REACT_APP_API_URL + '/project/' + this.state.projectId + '/resources?albumId=' + album.id, {
					method: 'GET',
					headers: {
						'Auth': JSON.parse(readCookie('access_token')).access_token
					}
				}).then(res => (res.json()))
				.then(data => {
					if(data.status === 'ok') {
						this.setState({ showAlbumsView: false, albumImages: data.data, loadingAlbumImages: false });
					}
				}).catch(er => {
				});
			}.bind(this));
		}
	}

	showImages(imageIndex) {
		let imgArray = [];
		for(var i = 0; i < this.state.albumImages.length; i++) {
			imgArray.push(this.state.albumImages[i]);
		}
		this.setState({ imgArray, openModal: true, selectedImageIndex: imageIndex });
	}

	backToAlbums() {
		this.setState({ selectedAlbum: null, showAlbumsView: true, albumImages: [] });
	}

	keyChange(key) {
		if(key !== this.state.activeTab) {
			if(this.state.onlyOneAlbum) {
				this.setState({
					activeTab: key,
					showAlbumsView: false,
					selectedImageIndex: 0,
					loadingAlbumImages: false
				});
			} else {
				this.setState({
					activeTab: key,
					albumImages: [],
					selectedAlbum: null,
					showAlbumsView: true,
					selectedImageIndex: 0,
					loadingAlbumImages: false
				});
			}
		}
	}

	uploadedByFormatter(cell, row) {
		if(cell && cell.fullName) {
			if(cell.orgName) {
				return (
					<span className="name" title={cell.fullName + ' ( ' + cell.orgName + ' )'}>{cell.fullName + ' '}<span className="org">{'( ' + cell.orgName + ' )'}</span></span>
				)
			} else {
				return (
					<span className="name" title={cell.fullName}>{cell.fullName}</span>
				)
			}
		} else return '';
	}

	showAddDocModal() {
		this.setState({ addDocModalVisibility: true });
	}

	hideAddDocModal() {
		this.setState({ addDocModalVisibility: false });
	}

	submitForm(file, title, description, visibility) {
		var data = new FormData();
		data.append('file', file);
		data.append('type', 'doc');
		data.append('title', title);
		data.append('description', description);
		data.append('visibility', visibility);
		fetch(process.env.REACT_APP_API_URL + "/project/" + this.state.selectedProject.id + "/resources", {
			method: 'POST',
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
			body: data
		}).then((response) => response.json())
		.then(data => {
			if(data.status === "ok"){
				this.notifySuccess('Document Added successfully');
				this.hideAddDocModal(this);
				fetch(process.env.REACT_APP_API_URL + '/project/' + this.state.selectedProject.id + '/resources?type=doc', {
					method: 'GET',
					headers: {
						'Auth': JSON.parse(readCookie('access_token')).access_token
					}
				}).then(res => (res.json()))
				.then(data => {
					if(data.status === 'ok') {
						this.setState({ doc_resources: data.data });
					}
				}).catch(er => {
				});
			} else this.notifyError('Document upload failed');
		}).catch(err => {
			this.notifyError('Document upload failed');
		});
	}

	gotoOrganizer() {
		ReactGA.event({
		  category: 'View Project',
		  action: 'view',
		  label: 'Organizer'
		});
		if(this.state.selectedProject.hasOwnProperty('customUrl') && this.state.selectedProject.customUrl) window.location.pathname = "/mis-projects/" + this.state.selectedProject.customUrl + "/organizer";
		else window.location.pathname = "/mis-projects/" + this.state.projectId + "/organizer";
	}

	render() {
		if(this.state.selectedProject && this.state.selectedProject.hasOwnProperty('title')) {
			return (
				<div className="mis-content-container single-csr-project-container">
					<div className="mis-fixed-top-container" id="single-csr-project-fixed-top">
						<div>
							<Modal show={this.state.openModal} onHide={this.onCloseModal.bind(this)} className="resources-image-modal">
								<ImageModal imgArray={this.state.imgArray} hasDate={true} openModal={this.state.openModal} imageIndex={this.state.selectedImageIndex} />
							</Modal>
							<Modal show={this.state.openGraphModal} style={{ height: '500px' }} onHide={this.onCloseGraphModal.bind(this)} center>
								<GraphModal dataArray={plottedGraph} />
							</Modal>
							<AddDocModal submitForm={this.submitForm.bind(this)} addDocModalVisibility={this.state.addDocModalVisibility} hideAddDocModal={this.hideAddDocModal.bind(this)} />

							<Menu selectedKeys={[this.state.current]} mode="horizontal">
								<Menu.Item key="overview">
									<Link onClick={this.handleClick.bind(this, 'overview')}>Overview</Link>
								</Menu.Item>
								<Menu.Item key="description">
									<Link onClick={this.handleClick.bind(this, 'description')}>Description</Link>
								</Menu.Item>
								{(this.state.selectedProject.status === 'Completed' || this.state.selectedProject.status === 'Funded') || this.state.amountBreakup !== null || this.state.releasedAmountBreakup.length ? (
									<Menu.Item key="finances">
										<Link onClick={this.handleClick.bind(this, 'finances')}>Finances</Link>
									</Menu.Item>
								) : (null)}
								{!checkValue(this.state.kpiData) || !checkValue(this.state.beneficiaries) ? (
									<Menu.Item key="impact-monitoring">
										<Link onClick={this.handleClick.bind(this, 'impact-monitoring')}>Impact Monitoring</Link>
									</Menu.Item>
								) : (null)}
								{!checkValue(this.state.milestones) ? (
									<Menu.Item key="milestones">
										<Link onClick={this.handleClick.bind(this, 'milestones')}>Milestones</Link>
									</Menu.Item>
								) : (null)}
								<Menu.Item key='resources'>
									<Link><span>Resources</span></Link>
								</Menu.Item>
								<Menu.Item key='export'>
									<Link onClick={this.exportPdf.bind(this)}><span>Export Project</span></Link>
								</Menu.Item>
								<Menu.Item key='chat' title={this.state.corporateInterestStatus === false || this.state.corporateInterestStatus === 'noAction' ? "Show Interest on Project to start chatting" : null}>
									<Link onClick={this.chatWithNgo.bind(this)}><span>Chat{this.state.corporateInterestStatus === false || this.state.corporateInterestStatus === 'noAction' ? (<i className="fa fa-lock ml5"></i>) : (null)}</span></Link>
								</Menu.Item>
								{this.state.selectedProject.projectInterestCode ? (
									<Menu.Item key='organizer'>
										<Link onClick={this.gotoOrganizer.bind(this)}>
											<span>Organizer</span>
										</Link>
									</Menu.Item>
								) : (null)}
							</Menu>
							{this.state.confirmInvestment === true && this.state.corporateInterestStatus === 'Invested' && JSON.parse(readCookie('userData')).authorizedFor.mis !== 'view' ? (
								<div>
									<button className="header-express-btn" onClick={this.handlePathChange}>
										Invest Funds
									</button>
								</div>
							) : (
								JSON.parse(readCookie('userData')).authorizedFor.mis !== 'view' ? (
									<div>
										{(() => {
											if (this.state.corporateInterestStatus === 'noAction') {
												return (
													<div className="status-holder">
														<button className="header-express-btn" onClick={this.projectStatusChange.bind(this, 'Interested')}>
															Show Interest
														</button>
														<button className="btn skip-btn" onClick={this.projectStatusChange.bind(this, 'Skipped')}>
															Decline
														</button>
													</div>
												)
											} else if (this.state.corporateInterestStatus === 'Interested') {
												return (
													<div className="status-holder">
														<button className="header-express-btn" onClick={this.projectStatusChange.bind(this, 'Under Review')}>
															Put Under Review
														</button>
														<button className="btn skip-btn" onClick={this.projectStatusChange.bind(this, 'Skipped')}>
															Decline
														</button>
													</div>
												)
											} else if (this.state.corporateInterestStatus === 'Under Review') {
												return (
													<div className="status-holder">
														<button className="header-express-btn" onClick={this.projectStatusChange.bind(this, 'Invested')}>
															Start Investing
														</button>
														<button className="btn skip-btn" onClick={this.projectStatusChange.bind(this, 'Skipped')}>
															Decline
														</button>
													</div>
												)
											} else if (this.state.corporateInterestStatus === 'Skipped') {
												return (
													<div className="status-holder">
														<button className="header-express-btn" onClick={this.projectStatusChange.bind(this, 'Interested')}>
															Show Interest
														</button>
													</div>
												)
											}
										})()}
									</div>
								) : (null)
							)}
						</div>
					</div>

					<Row className="back-container" id="overview">
						<a href={"/discover/projects"} className="back" onClick={this.clearSelectedProject.bind(this)}>
							<i className="fa fa-chevron-left"></i>
						</a>
						<span className="project-title">{' ' + this.state.selectedProject.title}</span>
					</Row>

					<Row>
						<Col id="resources" md={12} className="project-details more-details">
							<Row>
								<Col md={12} className="features mt10 mb10">
									<div className="heading"><div className="dot"></div>{'Resources :'}</div>
									<div className="content">
										<Tabs activeKey={this.state.activeTab} onSelect={this.keyChange.bind(this)} id="resources-tabs-container">
										  <Tab eventKey={1} title="Documents">
		    								<BootstrapTable data={this.state.doc_resources} striped={false} ref='table' hover={true} columnTitle={true}>
		    									<TableHeaderColumn dataField="id" isKey={true} hidden={true} export={false} searchable={false}>Id</TableHeaderColumn>
		    									<TableHeaderColumn dataField="title" width='180px' headerAlign='center' dataAlign='center' dataSort={false}   hidden={false}  export={true} searchable={true} columnTitle={true}>Title</TableHeaderColumn>
										    	<TableHeaderColumn dataField="url" width='150px' headerAlign='center' dataAlign='center' dataSort={false}   hidden={false}  export={true} searchable={true} dataFormat={this.urlFormatter.bind(this)}>Resource Url</TableHeaderColumn>
		    									<TableHeaderColumn dataField="description" headerAlign='center' dataAlign='center' dataSort={false}   hidden={false}   export={true} searchable={true}>Description</TableHeaderColumn>
	    										<TableHeaderColumn dataField="date" width='160px' headerAlign='center' dataAlign='center' dataSort={false}   hidden={false}   export={true} searchable={true} dataFormat={this.dateFormatter.bind(this)}>Uploaded On</TableHeaderColumn>
	    										<TableHeaderColumn dataField="createdBy" headerAlign='center' dataAlign='center' dataSort={false}   hidden={false}   export={true} searchable={true} dataFormat={this.uploadedByFormatter.bind(this)}>Uploaded By</TableHeaderColumn>
                				</BootstrapTable>
                				{this.state.companyId === JSON.parse(readCookie('userData'))._organisationId && this.state.selectedProject.status === 'Funded' && JSON.parse(readCookie('userData')).authorizedFor.mis !== 'view'? (
	                				<button className="btn btn-default add-new-doc-btn" onClick={this.showAddDocModal.bind(this)}>
	                					<i className="fa fa-plus"></i>
	                					Add New Document
	                				</button>
                				) : (null)}
										  </Tab>
										  <Tab eventKey={2} title="Images">
										  	{this.state.showAlbumsView ? (
										  		<div className="all-albums">
										  			<h4>{'Albums (' + this.state.albums.length + ')'}</h4>
										  			<div className="album-covers">
												  		{this.state.albums.map(function(album, index) {
												  			// console.log(album)
												  			if(album.id === "0") {
												  				if(this.state.other_album !== null) {
												  					return (
												  						<div key={index} style={{position:"relative"}}>
															  				<div className="album-cover" style={{backgroundImage: 'url(' + album.url + ')'}} onClick={this.showAlbumImages.bind(this, album)}>
															  					<div className="title">{album.title}</div>
															  				</div>
															  				<button className='albumDownload' onClick={this.downloadAlbum.bind(this, album.id)}>Download Album</button>
													  					</div>
												  					)
												  				} else return null;
												  			} else {
													  			return (
													  				<div key={index} style={{position:"relative"}}>
														  				<div className="album-cover" style={{backgroundImage: 'url(' + album.url + ')'}} onClick={this.showAlbumImages.bind(this, album)}>
														  					<div className="title">{album.title}</div>
														  				</div>
														  				<button className='albumDownload' onClick={this.downloadAlbum.bind(this, album.id)}>Download Album</button>
													  				</div>
													  			)
													  		}
												  		}.bind(this))}
												  	</div>
											  		{this.state.albums.length === 0 ? (
											  			<h4 className="no-albums">There are no albums in this project</h4>
											  		) : (null)}
											  	</div>
										  	) : (
										  		<div className="all-images">
										  			{this.state.onlyOneAlbum ? (null) : (
										  				this.state.selectedAlbum ? (
											  				<h4>
												  				<span onClick={this.backToAlbums.bind(this)}>
												  					<i className="fa fa-chevron-left"></i>
												  				</span>
												  				<span className="title">{' ' + this.state.selectedAlbum.title}</span>
												  			</h4>
												  		) : (null)
										  			)}
										  			{this.state.selectedAlbum && this.state.selectedAlbum.id !== "0" ? (
										  				<p>{this.state.selectedAlbum.description}</p>
										  			) : (null)}
											  		{this.state.loadingAlbumImages ? (
											  			<div className="loading-images">
											  				<i className="fa fa-spin fa-spinner fa-3x"></i>
											  			</div>
											  		) : (
											  			<div className="album-images">
												  			{this.state.albumImages.map(function(image, imageIndex) {
												  				return (
													  				<div key={imageIndex}>
													  					{image.type === "image" ? (
														  					<div className="album-image" style={{backgroundImage: 'url(' + image.url + ')'}} onClick={this.showImages.bind(this, imageIndex)}>
														  						<div className="title">{image.title}</div>
														  					</div>
												  						) : (
														  					<div>
														  						<ReactPlayer className="album-video" url={image.url} width="150px" height="115px"/>
														  						<button style={{marginLeft:"10px", width:"150px", border:"none", background:"#ef5a20", color:"#fff", borderRadius:"4px"}} onClick={this.showImages.bind(this, imageIndex)}><i className="fa fa-play"></i> Play</button>
														  					</div>
												  						)}
													  				</div>
													  			)
												  			}.bind(this))}
												  			{this.state.albumImages.length === 0 ? (
													  			<h4>There are no images in this album</h4>
													  		) : (null)}
													  	</div>
											  		)}
											  	</div>
										  	)}
										  </Tab>
										</Tabs>
									</div>
								</Col>
							</Row>
						</Col>
					</Row>

					{this.state.canShowInterest ? (
						<ShowInterestModal showInterestMessage={this.state.showInterestMessage}
							ShowInterestModal={this.state.ShowInterestModal}
							updateMessage={this.updateMessage.bind(this)}
							showInterest={this.showInterest.bind(this)}
							onShowInterestClose={this.onShowInterestClose.bind(this)}
							onShowInterestOpen={this.onShowInterestOpen.bind(this)}
							handleAgree={this.handleAgree.bind(this)} />
					) : (null)}
					<NotificationModal ShownotificationModal={this.state.ShownotificationModal}
						notificationContent={this.state.notificationContent}
						onClosenotification={this.onClosenotification.bind(this)}
						onShownotification={this.onShownotification.bind(this)}
						handleConfirm={this.handleConfirm.bind(this)}
						QueryYear={this.state.QueryYear}
						csrBudgetYear={this.state.csrBudget.year}
						years={years}/>
					<ToastContainer
						position="bottom-center"
						autoClose={4000}
						hideProgressBar={true}
						closeOnClick={false}
						newestOnTop={false}
						pauseOnHover={false}
					/>
					{this.state.reportExpenseModalVisibility && this.state.reportType !== '' ? (
						<ReportExpenseModal hideReportExpenseModal={this.hideReportExpenseModal.bind(this)} type={this.state.reportType}
							project_id={this.state.selectedProject.id} editExpense={this.state.editExpense} expenseData={this.state.expenseData} />
					) : (null)}
				</div>
			);
		} else {
			return null;
		}
	}
}