import React,{ Component } from 'react';
import {Col, Row} from 'react-bootstrap';

const readCookie = require('../../../../cookie.js').readCookie;

export default class AmountBreakupTable extends Component {
	render() {
		return (
			<Row className="amount-breakup-table">
				{(() => {
					if(this.props.amountBreakup.length) {
						if(this.props.amountBreakup[0].hasOwnProperty('readOnly')) {
							return (
								<Col md={12} className="header-container">
									<Col md={4} className="text-center header">Investment Name</Col>
									<Col md={4} className="text-center header">Cost Head</Col>
									<Col md={4} className="text-center header">Amount Breakup</Col>
								</Col>
							)
						}
					}
				})()}
				{this.props.amountBreakup.map(function(breakup, index) {
					if(breakup.hasOwnProperty('readOnly')) {
						return (
							<Col className={index % 2 === 0 ? "amount-breakup odd" : "amount-breakup even"} key={index} md={12}>
								<Col md={4}>
									<span className="amount-breakup-description">{breakup.description}</span>
								</Col>
								<Col md={4}>
									<span>{breakup.costHead}</span>
								</Col>
								<Col md={4}>
									<span>{breakup.amount}</span>
								</Col>
							</Col>
						);
					}
				}.bind(this))}
			</Row>
		);
	}
}