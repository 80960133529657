import React, {Component} from 'react';
import { BootstrapTable, TableHeaderColumn, ButtonGroup, ExportCSVButton } from 'react-bootstrap-table';
const readCookie = require('../../../../cookie.js').readCookie;
import swal from "sweetalert2";

export default class ReleaseAmountTable extends Component {
	constructor(props){
		super(props);
		this.state = {
			releasedAmountBreakup: this.props.releasedAmountBreakup
		}
	}

	amountFormat = (cell, row) =>{
		for(let i=0; i < cell.length; i++){
			return (
				<div className="amount-breakup-container">
					<div className="amount-breakup-bullet"></div>
					<div className="amount-breakup-info">{cell[i].value}</div>
				</div>
			)
		}
	}

	docsFormat = (cell, row) =>{
		for(let i = 0; i < cell.length; i++) {
			return (
				<div style={{fontWeight: 600, textDecoration: 'underline'}}>
					<a onClick={()=>{window.open(cell[i])}} >View</a>
				</div>
			);
		}
	}

	commentEdittor = (value)=> {
		const response = { isValid: true, notification: { type: 'success', msg: '', title: '' } };
		if (!value) {
			response.isValid = false;
			response.notification.type = 'error';
			response.notification.msg = 'Value must be inserted';
			response.notification.title = 'Requested Value';
		} else if (value.length < 2) {
			response.isValid = false;
			response.notification.type = 'error';
			response.notification.msg = 'Value must have 5+ characters';
			response.notification.title = 'Invalid Value';
		}
		return response;
	}

	chequeFormatter(cell, row) {
		if(row.hasOwnProperty('transactionRefNo')){
			return cell;
		} else {
			return 'N/A'
		}
	}

	updateComment = (rowIndex, e) =>{
		let releasedAmountBreakup = this.state.releasedAmountBreakup;
		releasedAmountBreakup[rowIndex]['comment'] = e.target.value;
		this.setState({ releasedAmountBreakup });
	}

	editComment(rowIndex) {
		let releasedAmountBreakup = this.state.releasedAmountBreakup;
		releasedAmountBreakup[rowIndex]['commentBeingEditted'] = true;
		this.setState({ releasedAmountBreakup });
	}

	saveComment(index) {
		let data = {};
	  data.index = index;
	  data.comment = this.state.releasedAmountBreakup[index].comment;

	  fetch(process.env.REACT_APP_API_URL + '/company/' + this.props.companyId + '/mis/investment/'+ this.props.investmentId + '/released-amount', {
	    method: 'PUT',
    	headers: {
				'Content-Type': 'application/json',
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
  		body: JSON.stringify(data)
    }).then(data => data.json())
    .then(data => {
    	if(data.status === 'ok') {
			  let releasedAmountBreakup = this.state.releasedAmountBreakup;
				releasedAmountBreakup[index]['commentBeingEditted'] = false;
				this.setState({ releasedAmountBreakup });

    		this.props.updateReleaseAmountBreakup();
    	}
    });
	}

	commentFormatter(cell, row){
		let rowIndex;
		for(var i = 0; i < this.state.releasedAmountBreakup.length; i++) {
			if(this.state.releasedAmountBreakup[i].id === row.id) rowIndex = i;
		}
		if(row.hasOwnProperty('commentBeingEditted') && row.commentBeingEditted) {
			return (
				<div className="comment-style-update">
					<input type="text" defaultValue={cell} onChange={this.updateComment.bind(this, rowIndex)} />
					<button className="btn comment-update-btn" onClick={this.saveComment.bind(this, rowIndex)}>
						<i className="fa fa-check"></i>
					</button>
				</div>
			)
		} else {
			return (
				<div className="comment-style-edit">
					<span>{cell}</span>
					{JSON.parse(readCookie('userData')).authorizedFor.mis !== 'view' ? (
						<button className="btn comment-edit-btn" onClick={this.editComment.bind(this, rowIndex)}>
							<i className="fa fa-pencil"></i>
						</button>
					) : (null)}
				</div>
			)
		}
	}

	tranchFormatter(cell, row) {
		if(!isNaN(cell)) {
			return 'Tranch ' + cell;
		} else {
			return 'N/A';
		}
	}

	amountInvestedFormatter(cell, row) {
		if(cell) return window.inrFormat(cell);
		else return 'N/A';
	}

deleteParticularInvestment(cell,rowId){
	
	swal.fire({
		title: 'Are you sure?',
		text: "You won't be able to revert this!",
		type: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: 'Remove'
	  }).then((result) => {
		if (result.dismiss !== 'cancel') {
			let path = window.location.pathname;
	path = path.split('/').pop();
	fetch(process.env.REACT_APP_API_URL +'/company/' + this.props.companyId + '/mis/investment/'+ this.props.investmentId + '/released-amount/'+ rowId, {
		method: "DELETE",
		headers: {
			'Auth': JSON.parse(readCookie('access_token')).access_token
		}
	}).then((data) => data.json())
	.then((data)=>{
		if(data.status === 'ok'){
		this.setState({releasedAmountBreakup :this.state.releasedAmountBreakup.filter(x => x.id !== rowId)})
		swal.fire(
			'Deleted!',
			'success'
		  )
		  window.location.reload();	
	}	
	})
		  
		}
	  }).catch(()=>{})
	
}

	deleteInvestment(cell,row){
		
		return  (<button className='btn comment-edit-btn' style={{backgroundColor:"#EF5A20",color :'white'}} onClick={this.deleteParticularInvestment.bind(this,cell,row.id)}>
			<i className="fa fa-trash"></i></button>)
	}

	render() {
		return (
			<div className="released-amount-table">
				<BootstrapTable data={this.state.releasedAmountBreakup} ref='table' hover={true} scrollTop={ 'Top' }>
					<TableHeaderColumn dataField="id" isKey={true} hidden={true}>Id</TableHeaderColumn>
					<TableHeaderColumn dataField='amount' width={'150px'} headerAlign='center' dataAlign='center' export={true} searchable={true} dataFormat={this.amountInvestedFormatter}>Amount Invested</TableHeaderColumn>
					<TableHeaderColumn dataField='date' width={'100px'} headerAlign='center' dataAlign='center' export={true} searchable={true} >Date</TableHeaderColumn>
					<TableHeaderColumn dataField='amountBreakups' headerAlign='center' dataAlign='center' export={true} searchable={true} dataFormat={this.amountFormat}  >Amount Breakup</TableHeaderColumn>
					<TableHeaderColumn dataField='mode' width={'120px'} headerAlign='center' dataAlign='center' export={true} searchable={true} >Payment Mode </TableHeaderColumn>
					<TableHeaderColumn dataField='transactionRefNo' headerAlign='center' dataAlign='center' export={true} searchable={true}  dataFormat={this.chequeFormatter}>Cheque/UTR No</TableHeaderColumn>
					<TableHeaderColumn dataField='docs' headerAlign='center' dataAlign='center' export={true} searchable={true} dataFormat={this.docsFormat} >Scanned copy</TableHeaderColumn>
					{this.props.disbursementType === 'Tranches' ? (
						<TableHeaderColumn dataField='tranchId' headerAlign='center' dataAlign='center' export={true} searchable={true} dataFormat={this.tranchFormatter}>Tranch</TableHeaderColumn>
					) : (null)}
					<TableHeaderColumn dataField='comment' headerAlign='center' width={'220px'}dataAlign='center' export={true} searchable={true}  dataFormat={this.commentFormatter.bind(this)}>Comment</TableHeaderColumn>
				     {JSON.parse(readCookie('userData')).authorizedFor.mis !== 'view'?(<TableHeaderColumn   headerAlign='center' dataAlign='center' width={'120px'} dataFormat={this.deleteInvestment.bind(this)} >Remove</TableHeaderColumn>):null }
				</BootstrapTable>
			</div>
		)
	}
}
