import React, { Component } from 'react';
import {Row, Col, Modal} from 'react-bootstrap';
import { ToastContainer, toast } from "react-toastify";

export default class AddDocModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			title: '',
			description: '',
			visibility: '',
			file: null
		}
	}

	onChange(name, e) {
		if(name === 'file') {
			this.setState({ file: e.target.files[0] });
		} else this.setState({ [name]: e.target.value});
	}

	submitForm() {
		if(this.state.file && this.state.file !== null && this.state.title && this.state.description && this.state.visibility) {
			this.props.submitForm(this.state.file, this.state.title, this.state.description, this.state.visibility);
		} else {
			this.notifyError('Please fill in the required fields!');
		}
	}

	notifyError = text => toast.error(text);

	render() {
		return (
			<Modal className="add-doc-modal" show={this.props.addDocModalVisibility} onHide={this.props.hideAddDocModal}>
				<div className="add-doc-modal-container">
					<ToastContainer position="bottom-center" autoClose={4000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={true} />
					<div className="padding0">
						<h2 className="mb5 mt5 ml15 mr15">Add New Document</h2>
						<Row>
							<Col md={12} style={{ margin:'5px 5px 25px 5px !important', padding: '10px 10px 30px 10px !important' }}>
								<Col className="form-group" md={6}>
									<label className="control-label is-imp">Title:</label>
									<input className="form-control" type="text" placeholder="Enter Title" onChange={this.onChange.bind(this, 'title')} />
								</Col>

								<Col className="form-group" md={6}>
									<label className="control-label is-imp">Description:</label>
									<input className="form-control" type="text" placeholder="Enter Description" onChange={this.onChange.bind(this, 'description')} />
								</Col>

								<Col className="form-group" md={6}>
									<label className="control-label is-imp">Select Visibility:</label>
									<select className="form-control" required onChange={this.onChange.bind(this, 'visibility')}>
										<option value=''>Select Visibility</option>
										<option value='public'>Public</option>
										<option value='private'>Private</option>
									</select>
								</Col>

								<Col className="form-group" md={6}>
									<label className="control-label is-imp">Upload File</label>
									<input type="file" className="btn btn-info" accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" onChange={this.onChange.bind(this, 'file')} />
								</Col>

								<Col className="mt10 mb10 form-buttons text-center" md={12}>
									<button type="button" className="btn btn-default btn-danger ml5 mr5" onClick={this.props.hideAddDocModal}>Cancel</button>
									<button type="button" className="btn btn-default ml5 mr5" onClick={this.submitForm.bind(this)}>Submit</button>
								</Col>
							</Col>
						</Row>
					</div>
				</div>
			</Modal>
		);
	}
}