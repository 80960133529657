import React, {Component} from 'react';

import CSRProjectContainer from './CSRProjectContainer';

export default class CSRProjects extends Component {
	render() {
		return (
			<div className="projects">
				{this.props.projects !== null ? (
					this.props.projects.map(function(project, i) {
						return <CSRProjectContainer key={i} project={project}
										discoverCSRProjectsListView={this.props.discoverCSRProjectsListView} />
					}.bind(this))
				) : (
					null
				)}
			</div>
		);
	}
}