import React, { Component } from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";
// import { readCookie } from "../../../../cookie";

class Walkthrough extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      selectedStepData: null,
      show: true,
      currentStep: 0,
      selectedUrl: null
    }
  }

  componentDidMount() {
    let walkthrough;
    let lastStep = 0;
    if(localStorage.getItem('walkthrough') !== null) {
      walkthrough = JSON.parse(localStorage.getItem('walkthrough'));
      // if (walkthrough.completedSteps.length) walkthrough.completedSteps[walkthrough.completedSteps.length - 1]
    } else {
      walkthrough = {
        completed: false,
        data: [
          {
            id: "1",
            title: "CSR Strategy Set-up",
            btnTitle: "Set Strategy",
            body:
              "Configure your platform with you CSR Strategy or build one with this guided setup to align your social and business goals.",
            url: "/csr-strategy-setup",
            done: false
          }, {
            id: "2",
            title: "Fiscal Year Budgeting",
            btnTitle: "Allot Budget",
            body:
              "Allot CSR budget for the current fiscal year and plan the allocation proportion to suit your company’s social goals.",
            url: "/fiscal-year-budgeting",
            done: false
          }, {
            id: "3",
            title: "Goal-wise Investment Planning",
            btnTitle: "Set Goals",
            body:
              "Distribute your annual CSR budget across different social goals and agendas and track your investments & impact in each of the selected goals",
            url: "/portfolio",
            done: false
          }, {
            id: "4",
            title: "Project Discovery And Pipeline",
            btnTitle: "Browse Projects",
            body:
              "Discover LE assured projects,partner projects & global social innovations and manage the project status with the streamlined project funnel.",
            url: "/discover/projects",
            done: false
          }, {
            id: "5",
            title: "Impact Monitoring",
            btnTitle: "View Impact",
            body:
              "Gauge the impact your investments have made and plan further actions with the visual charts, real time project updates and milestones",
            url: "/investments",
            done: false
          }, {
            id: "6",
            title: "CSR-Corner",
            btnTitle: "Read More",
            body:
              "Catch up with the latest sector trends and insights from across the country every hour,day and week.",
            url: "/csr-corner",
            done: false
          }
        ],
        completedSteps: []
      };
      localStorage.setItem('walkthrough', JSON.stringify(walkthrough), 7);
    }
    this.setState({
      data: walkthrough.data,
      selectedStepData: walkthrough.data[lastStep],
      currentStep: lastStep,
      selectedUrl: walkthrough.data[lastStep].url
    });
  }

  changeStep = index => {
    let selectedStepData = this.state.data[index];
    this.setState({ selectedStepData, currentStep: index });
  }

  nextStep = () => {
    let currentStep = this.state.currentStep;
    currentStep += 1;
    let selectedStepData = this.state.data[currentStep];
    let selectedUrl = this.state.data[currentStep].url;
    this.setState({ selectedStepData, currentStep });
  }

  prevStep = () => {
    let currentStep = this.state.currentStep;
    currentStep -= 1;
    let selectedStepData = this.state.data[currentStep];
    let selectedUrl = this.state.data[currentStep].url;
    this.setState({ selectedStepData, currentStep });
  }

  visitStrategy = () => {
    window.location.pathname = this.state.selectedStepData.url;
  }

  completedStrategy = (type) => {
    let walkthrough;
    if(localStorage.getItem('walkthrough') !== null) {
      walkthrough = JSON.parse(localStorage.getItem('walkthrough'));
      walkthrough.completed = true;
      if(type === 'skipped') walkthrough.skipped = true;
      localStorage.setItem('walkthrough', JSON.stringify(walkthrough), 7);
    } else {
      walkthrough = {
        completed: true,
        data: [
          {
            id: "1",
            title: "CSR Strategy Set-up",
            btnTitle: "Set Strategy",
            body:
              "Configure your platform with you CSR Strategy or build one with this guided setup to align your social and business goals.",
            url: "/csr-strategy-setup",
            done: false
          }, {
            id: "2",
            title: "Fiscal Year Budgeting",
            btnTitle: "Allot Budget",
            body:
              "Allot CSR budget for the current fiscal year and plan the allocation proportion to suit your company’s social goals.",
            url: "/fiscal-year-budgeting",
            done: false
          }, {
            id: "3",
            title: "Goal-wise Investment Planning",
            btnTitle: "Set Goals",
            body:
              "Distribute your annual CSR budget across different social goals and agendas and track your investments & impact in each of the selected goals",
            url: "/portfolio",
            done: false
          }, {
            id: "4",
            title: "Project Discovery And Pipeline",
            btnTitle: "Browse Projects",
            body:
              "Discover LE assured projects,partner projects & global social innovations and manage the project status with the streamlined project funnel.",
            url: "/discover/projects",
            done: false
          }, {
            id: "5",
            title: "Impact Monitoring",
            btnTitle: "View Impact",
            body:
              "Gauge the impact your investments have made and plan further actions with the visual charts, real time project updates and milestones",
            url: "/investments",
            done: false
          }, {
            id: "6",
            title: "CSR-Corner",
            btnTitle: "Read More",
            body:
              "Catch up with the latest sector trends and insights from across the country every hour,day and week.",
            url: "/csr-corner",
            done: false
          }
        ],
        completedSteps: []
      };
      if(type === 'skipped') walkthrough.skipped = true;
      localStorage.setItem('walkthrough', JSON.stringify(walkthrough), 7);
    }
    window.location.pathname = '/dashboard';
  }

  render() {
    if (this.state.data !== null && this.state.selectedStepData !== null) {
      return (
        <div className="mis-content-container">
          <div className="main-container">
            <button className="walkthrough-skip-btn" onClick={this.completedStrategy.bind(this, 'skipped')}>
              Skip Tour
              <i className="fa fa-angle-double-right" aria-hidden="true" />
            </button>
            <div className="greet-box">Welcome to CSREdge</div>
            <div className="greet-box-paragraph">Let's get you started with a quick tour of CSREdge.</div>
            <div className="icon-anime">
              {this.state.data.map(
                function (data, index) {
                  return (
                    <div className="icon-container">
                      <div className={data.done ? "numbers done" : "numbers"}
                        onClick={this.changeStep.bind(this, index)} >
                        {data.done ? (<span><i className="fa fa-check" style={{ color: "#fff" }} /></span>
                        ) : (<span className="numberValue">{index + 1}</span>)}
                      </div>
                      {index < this.state.data.length - 1 ? (
                        <div className={this.state.currentStep > index ? "progress-bars done" : "progress-bars"} >
                        </div>
                      ) : (null)}
                    </div>
                  )
                }.bind(this)
              )}
            </div>
            <div className="info-container">
              <div className="info-header">
                {this.state.selectedStepData.title}
              </div>
              <div className="info-para">
                <p className='info-para-text'>{this.state.selectedStepData.body}</p>
                <button className="btn-visit" onClick={this.visitStrategy}>
                  {this.state.selectedStepData.btnTitle}
                  <i className="fa fa-long-arrow-right right-arrow"></i>
                </button>
              </div>
            </div>
            <div className="buttons">
              {this.state.currentStep > 0 ? (
                <button onClick={this.prevStep} className="prev-btn">
                  <i className="fa fa-angle-double-left" aria-hidden="true" />
                  Prev
                </button>
              ) : null}

              {this.state.currentStep < 5 ? (
                <button onClick={this.nextStep} className="nxt-btn">
                  Next
                  <i className="fa fa-angle-double-right" aria-hidden="true" />
                </button>
              ) : null}
              {this.state.currentStep == 5 ? (
                <button onClick={this.completedStrategy.bind(this, 'finished')} className="finish-btn">
                  Finish
                  <i className="fa fa-angle-double-right" aria-hidden="true" />
                </button>
              ) : null}
            </div>
          </div>
        </div>
      )
    } else {
      return null;
    }
  }
}

export default Walkthrough;