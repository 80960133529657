import React, {Component} from 'react';
import { Input, Select } from 'antd';
import {BootstrapTable, TableHeaderColumn, ButtonGroup} from 'react-bootstrap-table';
import Icon from 'react-icons-kit';
import { iosEmailOutline } from 'react-icons-kit/ionicons/iosEmailOutline';
import { ic_delete } from 'react-icons-kit/md/ic_delete';
import {edit} from 'react-icons-kit/fa/edit';
import $ from 'jquery';
import swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import UserProfile from './UserProfile';
import PlacesAutocomplete from 'react-places-autocomplete';

import { Col, Row, Tab, Tabs,Modal } from 'react-bootstrap';
import UserEditModal from './UserEditModal';

const Option = Select.Option;

const readCookie = require('../../../cookie.js').readCookie;
const createCookie = require('../../../cookie.js').createCookie;

const domainArr = [
	'gmail.com',
	'yahoo.com',
	'yahoo.co.in',
	'rediffmail.com',
	'hotmail.com',
	'outlook.com'
];

const cellEditProp = {
	mode: 'click',
	blurToSave : true
  };
  const selectRowProp = {
	mode: 'checkbox'
  };

export default class Settings extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			role: '',
			members: [],
			fullName: '',
			activetab: 1,
			currency:'',
			newCurrency:'',
			budgetCycle:'',
			newBudgetCycle:'',
			hasCustomCategory: false,
			customCategory: [],
			editName: false,
			orgName:"",
			newOrgName:"",
			isUploading: false,
			userData : null, 
			organisationEmail:JSON.parse(readCookie('userData')).email ,
			emailCorrect :true,
			memberData :null,
			emailPattern : /^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/,
			location:{
				city:"",
				state:"",
				country:""
			},
			isSave: "",
			deletedKeys: [],
			isManage: false,
			newRole:[
				{
					role: "",
					modules:{
						csrStrategySetup:{
							none: false,
							canRead: false,
							canModify: false
						},
						fiscalYearBudget:{
							none: false,
							canRead: false,
							canModify: false
						},
						chats:{
							none: false,
							canRead: false,
							canModify: false
						},
						goalWiseInvestmentPortfolio:{
							none: false,
							canRead: false,
							canModify: false
						},
						inviteNgoPartners:{
							none: false,
							canRead: false,
							canModify: false
						},
						recommendedInnovations:{
							none: false,
							canRead: false,
							canModify: false
						},
						projectsNone:{
							none: false,
							canRead: false,
							canModify: false
						}
					}
				}
			],
			options: {
				afterInsertRow: this.onAfterInsertRow,  
				afterDeleteRow: this.onAfterDeleteRow
			  },
			orgLogo: '',
			logo:''
		}
	}

	handleChange(name, value) {
		let val = '';
		if(name === 'name'){
			val = value.target.value;
			this.setState({fullName: val});
		}
		if(name === 'email') {
			val = value.target.value;
			let regx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			let email = val
			if(regx.test(email)) {
				let i = email.indexOf("@");
				if (i !== -1) {
					let domain = email.substring(i + 1);

					if(domainArr.indexOf(domain) > -1) {
						$('#signupCorporate').prop('disabled', true);
						this.notifyError('Please enter an official email id.');
					} else $('#signupCorporate').prop('disabled', false);
				} else $('#signupCorporate').prop('disabled', true);
			} else $('#signupCorporate').prop('disabled', true);
		} else if (name === 'role') {
			val = value;
		}
		
		this.setState({ [name]: val });
	}
  
  handleEmailChange(name,value){
	let val = '';
	
		if(name === 'email') {
			
			val = value.target.value;
			let regx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			let email = val;

			  if(val.match(regx)){
				if(regx.test(email)) {
					let i = email.indexOf("@");
					if (i !== -1) {
						let domain = email.substring(i + 1);
	
						if(domainArr.indexOf(domain) > -1) {
							$('#signupCorporate').prop('disabled', true);
							this.notifyError('Please enter an official email id.');
						} else{
							$('#signupCorporate').prop('disabled', false);
							//  console.log("emailCorrect1 ====else")
							// this.setState({emailCorrect:false})
						} 
					} else {
						if(this.state.emailPattern.match(val)){
							this.setState({emailCorrect:true})
						}
						else{
							$('#signupCorporate').prop('disabled', true);
							this.setState({emailCorrect:false})
						}
						}
				} else {
					$('#signupCorporate').prop('disabled', true);
					}
			  }
	    	
					this.setState({organisationEmail: val });
		} 
		
	}
	onCloseModal(){
		this.setState({openModal:false})
	}

	addMember() {
		let data = {};
		data['email'] = this.state.email;
		data['role'] = this.state.role;
		data['fullName'] = this.state.fullName;
		data['loginUrl'] = window.location.origin;
		let companyId = JSON.parse(readCookie('userData')).organisationData.id;
		fetch(process.env.REACT_APP_API_URL + '/company/' + companyId + '/mis/member', {
			method: "POST",
			headers: {
				'Content-Type': 'application/json',
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
			body: JSON.stringify(data)
		}).then(function(response) {
			return response.json();
		}).then(function(data) {
			if(data.status === 'ok') {
				this.setState({
					email: '',
					role: ''
				}, function(){
					fetch(process.env.REACT_APP_API_URL + '/company/' + companyId + '/mis/member', {
						method: "GET",
						headers: {
							'Auth': JSON.parse(readCookie('access_token')).access_token
						}
					}).then(function(response) {
						return response.json();
					}).then(function(data) {
						this.setState({ members: data.data });
						swal({
							title: 'Added successfully',
							text:"They must have received an email with login details.",
							type: 'success'
						})
					}.bind(this));
				}.bind(this));
			} else {
				swal({
					title: 'Already a member in your organisation',
					type: 'error'
				})
			}
		}.bind(this));
	}

	componentDidMount() {
		if (this.props.match.params.hasOwnProperty('settings_type') && this.props.match.params.settings_type === 'organisation') {
			this.setState({activeTab: 2})
		} else if (this.props.match.params.hasOwnProperty('settings_type') && this.props.match.params.settings_type === 'members') {
			this.setState({activeTab: 3});
		} else {
			this.setState({activeTab: 1})
		}
		$('#signupCorporate').prop('disabled', true);
		let companyId = JSON.parse(readCookie('userData')).organisationData.id;
		fetch(process.env.REACT_APP_API_URL + '/company/' + companyId + '/mis/member', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then(function(response) {
			return response.json();
		}).then(function(data) {
			this.setState({ members: data.data });
		}.bind(this));
		fetch(process.env.REACT_APP_API_URL + "/company", {
      method: "GET",
      headers: {
        Auth: JSON.parse(readCookie("access_token")).access_token
      }
    }).then(data => data.json())
    .then(data => {
		let location = this.state.location;
    	if(data.data.city)location['city'] = data.data.city || "";
    	if(data.data.state)location['state'] = data.data.state || "";
    	if(data.data.country)location['country'] = data.data.country || "";
		(data.data.hasCustomProjectCategories) ? this.setState({hasCustomCategory:true}) : this.setState({hasCustomCategory:false})
		this.setState({location, currency: data.data.currency, budgetCycle: data.data.fiscalBudgetCycle, orgName: data.data.name, newOrgName: data.data.name, newCurrency:data.data.currency, newBudgetCycle:data.data.fiscalBudgetCycle, orgLogo: data.data.logo, logo: data.data.logo });
		if(data.data.customCategories)
			this.setState({customCategory: data.data.customCategories});
	})
  }


	notifySuccess = (text) => toast.success(text);

	notifyWarn = (text) => toast.warning(text);

	notifyError = (text) => toast.error(text);

	createCustomButtonGroup (props, onClick) {
		return (
	  	<div className="col-xs-offset-0 col-sm-offset-0 col-md-offset-0 col-lg-offset-2">
	      <ButtonGroup className='my-custom-class' sizeClass='btn-group-md'></ButtonGroup>
      </div>
    );
  }

  access(value, row, index) {
  	if(value.mis) {
  		if(value.mis === 'view') return (<div>Member</div>);
	  	else return (<div>{value.mis.charAt(0).toUpperCase() + value.mis.slice(1)}</div>);
  	} else return 'N/A';
  }

  changeAccess(value, row, index) {
  	return (
  		<div>{value.mis.charAt(0).toUpperCase() + value.mis.slice(1)}</div>
  	)
  }

  remove(value, row, enumObject, rowIndex) {
  	return (
  		<div className="text-center" ><Icon icon={ic_delete}  style={{"cursor":"pointer"}} onClick={this.removeMember.bind(this, rowIndex, value)} /></div>
  	)
  }

  removeMember(index, memberId) {
  	swal({
      title: 'Are you sure you want to remove this user?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Remove'
    }).then(function(result) {
      if(result) {
		  	let members = this.state.members;
		  	members.splice(index, 1);
		  	this.setState({ members });
		  	let companyId = JSON.parse(readCookie('userData')).organisationData.id;
				fetch(process.env.REACT_APP_API_URL + '/company/' + companyId + '/mis/member/' + memberId, {
					method: "DELETE",
					headers: {
						'Auth': JSON.parse(readCookie('access_token')).access_token
					}
				}).then(function(response) {
					return response.json();
				}).then(function(data) {
					if(data.status === 'ok') this.notifySuccess('Successfully removed user!');
				}.bind(this));
			}
		}.bind(this));
  }
  update(value, row, enumObject, rowIndex){
	return (
		<div className="text-center" ><Icon icon={edit} style={{"cursor":"pointer"}} onClick={this.updateMember.bind(this, rowIndex, value)} /></div>
	)
  }
  updateMember(index, memberId){
       let data =this.state.members.filter((x)=> x._id === memberId) ,role,email;
		let member = data[0];
			this.setState({openModal:true,memberData:member})
  }

  keyChange = (key) => {
		if(key !== this.state.activeTab) {
			if (key === 1) {
				window.location.pathname= "/settings";
			}
			if (key === 2) {
				window.location.pathname= "/settings/organisation";
			}
			if (key === 3) {
				window.location.pathname= "/settings/members";
			}
		}
	}

	selectHandle = (e) => {
		this.setState({[e.target.name]: e.target.value});
	}
	cancelSelectHandler = () => {
		document.getElementById("file-input-orgLogo").value = "";
		this.setState({newBudgetCycle:this.state.budgetCycle, orgLogo:this.state.logo, logo: this.state.logo, orgName:this.state.orgName, newOrgName:this.state.orgName, newCurrency:this.state.currency, budgetCycle:this.state.budgetCycle, currency:this.state.currency});
	}

	// editHandler = (name) => {
	// 	this.setState({[name]: true});
	// }

	// cancelEditHandler = (e) => {
	// 	this.setState({[e.target.name]: false, orgName:this.state.orgName, newOrgName:this.state.orgName});
	// }

	saveOrgData = (e) => {
		
		let data = {};
		let email={};
		let name = e.target.name;
		let mail = this.state.organisationEmail;
		let regx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if ( this.state.newOrgName !== "" && (this.state.organisationEmail !== "" && mail.match(regx) )) {
			data['logo'] = this.state.orgLogo;
			data['name'] = this.state.newOrgName;
			data['currency'] = this.state.newCurrency;
			data['fiscalBudgetCycle'] = this.state.newBudgetCycle;
			data['hasCustomProjectCategory'] = this.state.hasCustomCategory;
			data['deletedKeys'] = this.state.deletedKeys;
			email['organisationEmail'] = this.state.organisationEmail
			if(this.state.hasCustomCategory){
				data['customCategory']=this.state.customCategory ;
			}
				
			this.setState({isSave:  e.target.name});
			if(this.state.emailCorrect){
				fetch(process.env.REACT_APP_API_URL + '/save-user-email-data',{
					method:"PUT",
					headers :{
					'Content-Type':"application/json",
					"Auth":JSON.parse(readCookie("access_token")).access_token,
						},
						body :JSON.stringify(email)
						})
						.then(x => x.json())
						.then (x =>{
							if(x.status ==="ok"){
								const newCookie = JSON.parse(readCookie("userData"));
								eraseCookie("userData");
								newCookie.email = this.state.organisationEmail;
								createCookie("userData", JSON.stringify(newCookie), 7);
									fetch(process.env.REACT_APP_API_URL + "/company", {
									method: "PUT",
									headers: {
										"Content-Type": "application/json",
										   Auth: JSON.parse(readCookie("access_token")).access_token
									},
									body: JSON.stringify(data)
								  }).then(x => x.json())
								  .then(x => {
									  if(x.status === "ok"){
											this.notifySuccess("Successfully Updated.");
											  this.setState({isSave: "", deletedKeys: [], budgetCycle: x.data.fiscalBudgetCycle,  orgName: x.data.name, orgLogo: x.data.logo,  currency: x.data.currency});
										  }
										  });
										}})
							.catch(err => console.log('err',err));
			}
			else { 
					if(this.state.newOrgName === "" && this.state.organisationEmail ===""){
					this.notifyError("Please enter a valid name and Email ID")
					}
					else if(this.state.newOrgName === "")
						{
						this.notifyError("Please enter a valid name.");
						this.setState({isSave :""})		
						}
						else {
						this.notifyError("Please enter a valid email ID.");
						this.setState({isSave :""})		
					}
				}
			} else {

				if(this.state.newOrgName === "" && this.state.organisationEmail === ""){
					this.notifyError("Please enter a valid name and Email ID")
					}
				else if(this.state.newOrgName === "")
						{
						this.notifyError("Please enter a valid name.");
						this.setState({isSave :""})		
						}
					else {
						this.notifyError("Please enter a valid email ID.");
						this.setState({isSave :""})		
					}
						// this.notifyError("Please enter a valid email ID.");
		}
	}

	manage = (e) => {
		this.setState({isManage: e.target.name === "manage" ? true : false});
	}

	addNewRole = () => {
		let newRole = this.state.newRole;
		newRole.push({
			role: "",
			modules:{
				csrStrategySetup:{
					none: false,
					canRead: false,
					canModify: false
				},
				fiscalYearBudget:{
					none: false,
					canRead: false,
					canModify: false
				},
				chats:{
					none: false,
					canRead: false,
					canModify: false
				},
				goalWiseInvestmentPortfolio:{
					none: false,
					canRead: false,
					canModify: false
				},
				inviteNgoPartners:{
					none: false,
					canRead: false,
					canModify: false
				},
				recommendedInnovations:{
					none: false,
					canRead: false,
					canModify: false
				},
				projectsNone:{
					none: false,
					canRead: false,
					canModify: false
				}
			}
		});
		this.setState({newRole});
	}

	uploadOrgLogo = (e) => {
		if(e.target.files[0]){
			this.setState({isUploading: true})
			let data = new FormData();
			data.append("image", e.target.files[0]);
			fetch(process.env.REACT_APP_API_URL + '/uploadImage', {
				method: "POST",
				headers: {
					'Auth': JSON.parse(readCookie('access_token')).access_token,
				},
				body: data
			}).then((data) => data.json())
			.then((data) => {
				if(data.status === "ok"){
					this.setState({ orgLogo: data.url, isUploading: false });
				}
			})
		}
	}

	handleChangeCustomCategory =() =>{
		// let data={};
		// data['hasCustomProjectCategory'] = !this.state.hasCustomCategory;
		// console.log("this.state.hasCustomCategory",this.state.hasCustomCategory)
		// fetch(process.env.REACT_APP_API_URL + "/company", {
		// 	method: "PUT",
		// 	headers: {
		// 		"Content-Type": "application/json",
		// 	  Auth: JSON.parse(readCookie("access_token")).access_token
		// 	},
		// 	body: JSON.stringify(data)
		// }).then(data => data.json())
		// .then(data => {
		// 	if(data.status === "ok"){
		// 		this.setState({hasCustomCategory: !this.state.hasCustomCategory});
		// 	}
		// });
		this.setState({hasCustomCategory: !this.state.hasCustomCategory});
	}

	onAfterInsertRow = (row) => {
		let customCategory = [...this.state.customCategory];
		if(row.id!== customCategory.length+1)
			row.id=customCategory.length+1;
			customCategory.push(row);
	  this.setState({customCategory})
	}

	onAfterDeleteRow = (rowKeys) => {
		let deleted = this.state.deletedKeys;
		for(var i=0;i<rowKeys.length;i++)
			deleted.push(rowKeys[i]);
		this.setState({deletedKeys: deleted});
		let customCategory = this.state.customCategory;
		for (let i = 0; i < customCategory.length; i++) {
			for (let j = 0; j < rowKeys.length; j++) {
				if (customCategory[i] && rowKeys[j] === customCategory[i].name) 
					customCategory.splice(i,1);
			}
		}
		this.setState({customCategory});
	}
	CategoryName = (value, row) => {
		let customCategory = this.state.customCategory;
		for (let i = 0; i < customCategory.length; i++) {
			if (row.id=== customCategory[i].id) {
				customCategory[i].name = value;
			}
		}
		this.setState({customCategory});
		return true;
	}

	CategoryDescription = (value, row) => {
		let customCategory = this.state.customCategory;
		for (let i = 0; i < customCategory.length; i++) {
			if (row.id=== customCategory[i].id) {
				customCategory[i].description = value;
			}
		}
		this.setState({customCategory});
		return true;
	}
gettingUserData=(data)=>{
		this.setState({userData:data})
}

	render() {
		const options = {
      btnGroup: this.createCustomButtonGroup.bind(this),
      noDataText: ''
  	}


		return (
			<div className="mis-content-container mis-settings">
				<Tabs activeKey={this.state.activeTab} onSelect={this.keyChange.bind(this)} id="settings-tabs-container">
					<ToastContainer position="bottom-center" autoClose={4000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={false} />
				  <Tab eventKey={1} title="Profile">
						<UserProfile userProfile={(data)=> this.setState({userData:data})}/>
				  </Tab>
				  {JSON.parse(readCookie('userData')).authorizedFor.mis !== 'view' ? (
					  <Tab eventKey={2} title="Organisation">
					  	<div className='organisation-info-container'>
					  		{this.state.isUploading === true ? (<img src="/img/Spinner-white.svg" alt="loading" style={{width:"100px",position:"absolute", marginLeft:"10px", zIndex:"99"}}/>) : (null)}
					  		<img className='organisation-image' style={{position: "relative", borderRadius:"50%", border: "solid 1px #eee"}} src={this.state.orgLogo ? this.state.orgLogo : "/img/user.png"} alt=""/>
					  		<label htmlFor="file-input-orgLogo" style={{ margin: '0' }}>
					  			<i className='fa fa-pencil' style={{ cursor: "pointer", marginRight: '8px', fontSize: "18px", background: '#fff', padding: '5px 6px', position:"absolute",marginTop:"30px", left:"130px", borderRadius: '50%', boxShadow: "0px 1px 7px #888" }}><input onChange={this.uploadOrgLogo} className='file-input' id="file-input-orgLogo" type="file" accept="image/*" /></i>
					  		</label>
					  		<input className='file-input' id="file-input" type="file" accept="image/*" />
					  		<div className='organisation-info'>
					  			<div className="organisation-name">
			  						<input className='editName' placeholder='Enter Name' type="text" value={this.state.newOrgName} name='newOrgName' onChange={this.selectHandle}/>
					  			</div>
					  			{(this.state.location.city && this.state.location.state && this.state.location.country) !== "" ? (
						  			<div className="organisation-location">
					  					<i style={{fontSize:"15px"}} className="fa fa-map-marker"></i>
					  					<p>{this.state.location.city}, {this.state.location.state}, {this.state.location.country}</p>
					  				</div>
					  			):(null)}
					  		</div>
					  	</div>
					  	<div className='organisation-details-container'>
					  		<div className='organisation-currency'>
					  			<h4>Default Currency</h4>
					  			<select className="form-control" name="newCurrency" value={this.state.newCurrency} onChange={this.selectHandle}>
					  				<option selected value="INR">INR</option>
					  				{/*<option value="ZAR">ZAR</option>
					  				<option value="SGD">SGD</option>
					  				<option value="USD">USD</option>
					  				<option value="EUR">EUR</option>
					  				<option value="HKD">HKD</option>
					  				<option value="RUB">RUB</option>
					  				<option value="NZD">NZD</option>
					  				<option value="MYR">MYR</option>
					  				<option value="CAD">CAD</option>
					  				<option value="CHF">CHF</option>
					  				<option value="BRL">BRL</option>
					  				<option value="AED">AED</option>
					  				<option value="AUD">AUD</option>
					  				<option value="CNY">CNY</option>
					  				<option value="JPY">JPY</option>
					  				<option value="GBP">GBP</option>*/}
					  			</select>
					  		</div>
					  		<div className='organisation-cycle'>
					  			<h4>Default Budget Cycle</h4>
					  			<select className="form-control" name="newBudgetCycle" value={this.state.newBudgetCycle} onChange={this.selectHandle}>
					  				<option value="april-march">April to March</option>
					  				<option value="jan-dec">January to December</option>
					  			</select>
					  		</div>
					  	</div>
						<div className="organisation-category">
						<div> 
						<h4>Email ID of recipients of reminders/notification</h4> 
						<span>
						<Input id="corporateEmail"  
						type="email" data-type="corporate" size="large"
						placeholder="email@domain.com" 
						defaultValue={this.state.organisationEmail} 
						onChange={this.handleEmailChange.bind(this, 'email')} /></span></div>
						</div>
						<div className="organisation-category">
								<input type="checkbox" id="customCategory" checked={this.state.hasCustomCategory} onChange={this.handleChangeCustomCategory}/>
							    <h4>Define custom project categories for my organization</h4>
						</div>
						{this.state.hasCustomCategory ? (
							<div className='composition-table-div-scrollable' style={{marginLeft: "15px"}}>
							<BootstrapTable data={this.state.customCategory} id="composition-table" className='composition-table' striped={false} ref='table' insertRow={JSON.parse(readCookie('userData')).authorizedFor.mis !== 'view'} cellEdit={cellEditProp} options={this.state.options} deleteRow={JSON.parse(readCookie('userData')).authorizedFor.mis !== 'view'} selectRow={selectRowProp}>
								<TableHeaderColumn dataField='name' editable={{ type: 'input'}} isKey={true}>Name</TableHeaderColumn>
								<TableHeaderColumn dataField="description"  editable={{ type: 'input', validator: this.committeeName }}  headerAlign='center' dataAlign='center' >DESCRIPTION</TableHeaderColumn>
								 <TableHeaderColumn dataField="total_tagged_projects" editable={false} headerAlign='center' dataAlign='center'>TOTAL TAGGED PROJECTS (Auto)</TableHeaderColumn>
							</BootstrapTable>
							</div>
						):(null)}
				  		<div style={{display:"flex", marginTop: "30px", justifyContent:"center"}}>
							   <button className={this.state.isSave === "currency&BudgetCycle" ? "saveLoading" : 'saveButton'} name='currency&BudgetCycle' onClick={this.saveOrgData}>{this.state.isSave === "currency&BudgetCycle" ? (<img src="/img/Spinner-white.svg" alt="loading" style={{width:"27px"}}/>) : ("Save")}</button>
				  			<button className='cancelButton' onClick={this.cancelSelectHandler}>Cancel</button>
				  		</div>
						</Tab>
					) : (null)}
					{JSON.parse(readCookie('userData')).authorizedFor.mis !== 'view' ? (
						<Tab eventKey={3} title="Members">
							{this.state.isManage ? (
								<Col md={12} className="mt20">
									<button className='ghostButton' name='goBack' onClick={this.manage}><i className="fa fa-arrow-left"></i> Go Back</button>
									<button className='solidButton' name='addNewRole' style={{marginLeft: "10px"}} onClick={this.addNewRole}><i className="fa fa-plus"></i> Add New Role</button>
									<div className='manage-table-contaier'>
										<div className='manage-table-header-container'>
											<div className='role-name'>Role Name</div>
											<div className='manage-table-headers'>
												<div className='manage-table-header-module'>CSR Strategy Setup</div>
												<div className='manage-table-header-module-options'>
													<div>None</div>
													<div>Can Read</div>
													<div>Can Modify</div>
												</div>
											</div>
											<div className='manage-table-headers'>
												<div className='manage-table-header-module'>Fiscal Year Budget</div>
												<div className='manage-table-header-module-options'>
													<div>None</div>
													<div>Can Read</div>
													<div>Can Modify</div>
												</div>
											</div>
											<div className='manage-table-headers'>
												<div className='manage-table-header-module'>Chats</div>
												<div className='manage-table-header-module-options'>
													<div>None</div>
													<div>Can Read</div>
													<div>Can Modify</div>
												</div>
											</div>
											<div className='manage-table-headers'>
												<div className='manage-table-header-module'>Goal Wise Investment portfolio</div>
												<div className='manage-table-header-module-options'>
													<div>None</div>
													<div>Can Read</div>
													<div>Can Modify</div>
												</div>
											</div>
											<div className='manage-table-headers'>
												<div className='manage-table-header-module'>Invite NGO partners</div>
												<div className='manage-table-header-module-options'>
													<div>None</div>
													<div>Can Read</div>
													<div>Can Modify</div>
												</div>
											</div>
											<div className='manage-table-headers'>
												<div className='manage-table-header-module'>Recommended Innovations</div>
												<div className='manage-table-header-module-options'>
													<div>None</div>
													<div>Can Read</div>
													<div>Can Modify</div>
												</div>
											</div>
											<div className='manage-table-headers'>
												<div className='manage-table-header-module'>Projects-None</div>
												<div className='manage-table-header-module-options'>
													<div>None</div>
													<div>Can Read and Export Project</div>
													<div>Can Modify</div>
												</div>
											</div>
										</div>
										{this.state.newRole.map((role,i) => {
											return (
												<div key={i} className='manage-table-header-container'>
													<div className='role-name' style={{padding:"0px"}}><input type="text" className='form-control' placeholder="Enter Role Name" style={{width: "90%"}} value={role.role}/></div>
													{Object.keys(role.modules).map((module,j) => {
														return (
															<div key={j} className='manage-table-headers'>
																<div className='manage-table-header-module-options'>
																	<div className='module-input-div' style={{cursor:"pointer"}}><i style={{color:"#ddd"}} name='none' className="fa fa-check"></i></div>
																	<div className='module-input-div' style={{cursor:"pointer"}}><i style={{color:"#ddd"}} name='read' className="fa fa-check"></i></div>
																	<div className='module-input-div' style={{cursor:"pointer"}}><i style={{color:"#ddd"}} name='modify' className="fa fa-check"></i></div>
																</div>
															</div>
														)
													})}
												</div>
											)
										})}
									</div>
								</Col>
							) : (
								<div>
									<Col md={12} className="mt20 mb20">
										<Col md={3} style={{paddingLeft: "0px"}}>
											<label className="col-md-12 is-imp" style={{paddingLeft: "0px"}}>Enter Name:</label>
											<Input id="corporateName" type="text" placeholder="Full Name" data-type="corporate" size="large" defaultValue={this.state.name} onChange={this.handleChange.bind(this, 'name')} required />
										</Col>
										<Col md={3}>
											<label className="col-md-12 is-imp" style={{paddingLeft: "0px"}}>Enter Email Id:</label>
											<Input id="corporateEmail" prefix={<Icon icon={iosEmailOutline} />} type="email" data-type="corporate" size="large"
												placeholder="email@domain.com" defaultValue={this.state.email} onChange={this.handleChange.bind(this, 'email')} required />
										</Col>
										<Col md={3} className="role-selector-container">
											<label className="col-md-12 is-imp" style={{paddingLeft: "0px"}}>Select Member Role:</label>
											<Select id="role-selector" defaultValue={this.state.role} size="large" onChange={this.handleChange.bind(this, 'role')}>
												<Option disabled value="">None Selected</Option>
												<Option value="admin">Admin</Option>
												<Option value="view">Member</Option>
											</Select>
										</Col>
										<Col md={3} className="add-role-button-container">
											<button id="signupCorporate" className="add-role-button btn btn-default le-button" onClick={this.addMember.bind(this)}>Add</button>
											{/*<button id="signupCorporate" name='manage' onClick={this.manage} className="add-role-button btn btn-default le-button manage-button">Manage</button>*/}
										</Col>
									</Col>
									<Col md={12} className="mt20">
										<BootstrapTable data={this.state.members} options={options} striped={true} hover={true} ref='table'>
											<TableHeaderColumn dataField="fullName" hidden={false}>Full Name</TableHeaderColumn>
											<TableHeaderColumn dataField="email" isKey={true}>Email Id</TableHeaderColumn>
											<TableHeaderColumn dataField="authorizedFor" dataFormat={this.access.bind(this)}>Access Rights</TableHeaderColumn>
											{JSON.parse(readCookie('userData')).authorizedFor.mis !== 'view'? (
											<TableHeaderColumn dataField="_id" width='100' headerAlign='center' dataFormat={this.update.bind(this)}>Edit</TableHeaderColumn> 
											):null}
											{JSON.parse(readCookie('userData')).authorizedFor.mis !== 'view' ? ( 
   											<TableHeaderColumn dataField="_id" width='100' headerAlign='center' dataFormat={this.remove.bind(this)}>Remove</TableHeaderColumn>
   											):null} 
											</BootstrapTable>
									</Col>
								</div>
								
							)}
						</Tab>
					) : (null)}
			 	</Tabs>
				 {this.state.openModal ?(
				//  <Modal 
				//  show={this.state.openModal} 
				//  onHide={this.onCloseModal.bind(this)}
				//   className="resources-image-modal">
				 <UserEditModal  
				 show={this.state.openModal} 
				 onHide={this.onCloseModal.bind(this)} 
				 memberData={this.state.memberData} />			
				//  </Modal> 
				):(null)}
			</div>
			
		)
	}
}