import React, {Component} from 'react';
import Map from './Map';

export default class DiscoverCSRProjectsMap extends Component {
	render () {
		return (
			<Map initialPosition={this.props.initialPosition}
				projects={this.props.projects} />
		);
	}
}