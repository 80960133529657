import React, { Component } from 'react';
import $ from 'jquery';
import moment from 'moment';
import TimeAgo from 'react-timeago';
import copy from 'copy-to-clipboard';
import SwitchButton from 'lyef-switch-button';
import { ToastContainer, toast } from "react-toastify";
import { Col, Row, Tab, Tabs, Modal } from 'react-bootstrap';

const readCookie = require('../../../cookie.js').readCookie;

export default class EmailsRepo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			sizeOfPage: 0,
			loading: true,
			projects: [],
			conversations: [],
			selectedProjectEmails: [],
			conversationSkip: 0,
			totalConversations: 0,
			allConversationsOpen: true,
			conversationProjectView: false,
			showConversationType: 'all',
			showProjectsViewType: 'projects',
			selectedConversation: null,
			selectedProject: null,
			selectedProjectConversation: null,
			showIndexModal: false,
			showHelpModal: false,
			projectInterestCodes: [],
			searchString: ''
		};
		this.handleSearch = this.handleSearch.bind(this);
		this.handleSearchChange = this.handleSearchChange.bind(this);
	}

	componentDidMount() {
		fetch(process.env.REACT_APP_API_URL + '/fetch-emails', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
		.then((data) => {
			if(data.status === 'ok') {
				let conversations = [];
				for(var i = 0; i < data.emails.length; i++) {
					data.emails[i].selected = false;
					conversations.push(data.emails[i]);
				}
				this.setState({ conversations, totalConversations: conversations.length, sizeOfPage: conversations.length, loading: false });
			}
		});

		fetch(process.env.REACT_APP_API_URL + '/fetch-email-projects', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
		.then((data) => {
			if(data.status === 'ok') {
				let projects = [];
				for(var i = 0; i < data.projects.length; i++) {
					data.projects[i].selected = false;
					projects.push(data.projects[i]);
				}
				this.setState({ projects });
			}
		});

		fetch(process.env.REACT_APP_API_URL + '/fetch-projects-interests', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
		.then((data) => {
			if(data.status === 'ok') {
				this.setState({ projectInterestCodes: data.data });
			}
		});
	}

	toggleConversationProjectView(x) {
		this.setState({
			searchString: '',
			selectedProject: null,
			selectedConversation: null,
			showConversationType: 'all',
			showProjectsViewType: 'projects',
			conversationProjectView: !this.state.conversationProjectView,
		});
	}

	toggleConversationProperties(stateName, name, index) {
		let conversations = this.state[stateName],
			updateObj = {};

		updateObj[name] = !conversations[index][name];

		if(name === 'starred' || name === 'important') {
			fetch(process.env.REACT_APP_API_URL + '/fetch-email/' + conversations[index].id, {
				method: "POST",
				headers: {
					'Content-Type': 'application/json',
					'Auth': JSON.parse(readCookie('access_token')).access_token
				},
				body: JSON.stringify(updateObj)
			}).then((data) => data.json())
			.then((data) => {
				if(data.status === 'ok') {
					conversations[index][name] = !conversations[index][name];
					this.setState({ [stateName]: conversations });
				} else {
					this.notifyError("Failed to update conversation!")
				}
			});
		} else {
			conversations[index][name] = !conversations[index][name];
			this.setState({ [stateName]: conversations });
		}
	}

	notifyError = text => toast.error(text);
	notifyInfo = text => toast.info(text);

	openConversation(index) {
		let selectedConversation = this.state.conversations[index];
		fetch(process.env.REACT_APP_API_URL + '/fetch-email/' + selectedConversation.id, {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
		.then((data) => {
			if(data.status === 'ok') {
				this.setState({ searchString: '', selectedConversation: data.email, showConversationType: 'single' });
			}
		});
	}

	openProject(index) {
		let selectedProject = this.state.projects[index];
		fetch(process.env.REACT_APP_API_URL + '/fetch-project-emails/' + selectedProject.id, {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
		.then((data) => {
			if(data.status === 'ok') {
				this.setState({ selectedProject, selectedProjectEmails: data.emails, showProjectsViewType: 'single-project' });
			}
		});
	}

	openProjectConversation(index) {
		let selectedProjectConversation = this.state.selectedProjectEmails[index];
		fetch(process.env.REACT_APP_API_URL + '/fetch-email/' + selectedProjectConversation.id, {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
		.then((data) => {
			if(data.status === 'ok') {
				this.setState({ selectedProjectConversation: data.email, showProjectsViewType: 'single-project-conversation' });
			}
		});
	}

	formatter(x, y, z) {
		if (y === 'second') return 'few seconds ago';
		else if (x === 1) return x + ' ' + y + ' ' + z;
		else return x + ' ' + y + 's ' + z;
	}

	toggleAllConversationsOpen() {
		this.setState({ allConversationsOpen: !this.state.allConversationsOpen });
	}

	backToAllConversations() {
		this.setState({ selectedConversation: null, showConversationType: 'all' });
	}

	backToAllProjects() {
		this.setState({ selectedProject: null, showProjectsViewType: 'projects' });
	}

	backToProjectConversations() {
		this.setState({ selectedProjectConversation: null, showProjectsViewType: 'single-project' });
	}

	showIndexModal() {
		this.setState({ showIndexModal: true });
	}

	showHelpModal() {
		this.setState({ showHelpModal: true });
	}

	organizerHelpImageClick(e) {
		if(e.target) {
			$(e.target).toggleClass('large');
		}
	}

	hideIndexModal() {
		this.setState({ showIndexModal: false });
	}

	hideHelpModal() {
		this.setState({ showHelpModal: false });
	}

	copyToClipboard(str) {
		copy(str);
		this.notifyInfo("Tag Copied to clipboard");
	}

	handleSearchChange(value) {
		this.setState({ searchString: value });
	}

	handleSearch(type) {
		if(type === "Enter" || type === "click") {
			if(this.state.searchString) {
				this.setState({ loading: true });
				var searchData = {
					string: this.state.searchString
				};
				fetch(process.env.REACT_APP_API_URL + '/search-emails', {
					method: "POST",
					headers: {
						'Content-Type': 'application/json',
						'Auth': JSON.parse(readCookie('access_token')).access_token
					},
					body: JSON.stringify(searchData)
				}).then((data) => data.json())
				.then((data) => {
					if(data.status === 'ok') {
						let conversations = [];
						for(var i = 0; i < data.emails.length; i++) {
							data.emails[i].selected = false;
							conversations.push(data.emails[i]);
						}
						this.setState({ conversations, totalConversations: conversations.length, sizeOfPage: conversations.length, loading: false });
					}
				});
			} else {
				this.clearSearch(this);
			}
    }
	}

	clearSearch() {
		this.setState({ loading: true, searchString: '' });
		fetch(process.env.REACT_APP_API_URL + '/fetch-emails', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
		.then((data) => {
			if(data.status === 'ok') {
				let conversations = [];
				for(var i = 0; i < data.emails.length; i++) {
					data.emails[i].selected = false;
					conversations.push(data.emails[i]);
				}
				this.setState({ conversations, totalConversations: conversations.length, sizeOfPage: conversations.length, loading: false });
			}
		});
	}

	render() {
		return (
			<div className="mis-content-container mis-emails-repo-page">
				<ToastContainer position="bottom-center" autoClose={4000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={false} />
				<div className="emails-repo-header-container">
					<div className="header-buttons-container">
						<button className="btn btn-default" onClick={this.showIndexModal.bind(this)}>
							<img src="/img/organizer-index.png" alt="Organizer Index" />
							Index
						</button>
						<button className="btn btn-default" onClick={this.showHelpModal.bind(this)}>
							<img src="/img/organizer-help.png" alt="Organizer Help" />
							Help
						</button>
					</div>
					{this.state.showConversationType === 'all' && !this.state.conversationProjectView ? (
						<div className="search-container">
							<i className="fa fa-search"></i>
							<input className="search-input" type="text" placeholder="Search conversations" value={this.state.searchString} onChange={e => { this.handleSearchChange(e.target.value) }} onKeyDown={e => this.handleSearch(e.key)} />
							<i className="fa fa-caret-right" onClick={() => this.handleSearch("click")}></i>
						</div>
					) : (null)}

					{this.state.showConversationType === 'all' || this.state.showProjectsViewType === 'projects' ? (
						<div className="conversation-project-toggle-container">
							<SwitchButton id="list-map-switch" labelRight="Project View" labelLeft="Conversation View" isChecked={this.state.conversationProjectView}
								action={this.toggleConversationProjectView.bind(this)} />
						</div>
					) : (null)}
				</div>

				{this.state.showIndexModal ? (
					<Modal className="organizer-index-modal" show={this.state.showIndexModal} onHide={this.hideIndexModal.bind(this)} bsSize="large">
						<Modal.Body>
							<div className="organizer-index-table">
								<div className="organizer-index-table-header">
									<div className="project-header">Project</div>
									<div className="tag-header">Unique Tag</div>
								</div>
								<div className="organizer-index-table-body">
									{this.state.projectInterestCodes.map(function(projectInterest, index) {
										return (
											<div className="project-interest-row" key={index}>
												<div className="project-title"><a href={"/mis-projects/" + projectInterest.projectId} target="_blank">{projectInterest.title}</a></div>
												<div className="project-tag" onClick={this.copyToClipboard.bind(this, '[' + projectInterest.projectInterestCode + ']')}>{'[' + projectInterest.projectInterestCode + ']'}</div>
											</div>
										)
									}.bind(this))}
								</div>
							</div>
							<div className="organizer-index-notice">
								<li>- To use ORGANIZER, use the project-specific unique tag in the beginning of the subject line of your email and CC csredge@letsendorse.com. The conversation shall automatically get archived in the ORGANIZER.</li>
								<li>- If you wish the conversation thread to be agnostic of projects, just CC csredge@letsendorse.com and your email shall be archived in the ORGANIZER.</li>
							</div>
						</Modal.Body>
					</Modal>
				) : (null)}

				{this.state.showHelpModal ? (
					<Modal className="organizer-help-modal" show={this.state.showHelpModal} onHide={this.hideHelpModal.bind(this)} bsSize="large">
						<Modal.Body>
							<div className="organizer-help-header">
								Help
							</div>
							<div className="organizer-help-body">
								<li>- To use ORGANIZER, use the project-specific unique tag in the beginning of the subject line of your email and CC csredge@letsendorse.com. The conversation shall automatically get archived in the ORGANIZER.</li>
								<img src="/img/organizer-sample-email-1.png" onClick={(e) => this.organizerHelpImageClick(e)} />
								<li>- If you wish the conversation thread to be agnostic of projects, just CC csredge@letsendorse.com and your email shall be archived in the ORGANIZER.</li>
								<img src="/img/organizer-sample-email-2.png" onClick={(e) => this.organizerHelpImageClick(e)} />
							</div>
						</Modal.Body>
					</Modal>
				) : (null)}

				{this.state.showConversationType === 'all' && !this.state.conversationProjectView ? (
					<div className="all-conversations-container">
						<div className="all-conversations-container-header">
							{/*<i className={this.state.allConversationsOpen ? "fa fa-chevron-up all-conversations-arrow" : "fa fa-chevron-down all-conversations-arrow"}
								onClick={this.toggleAllConversationsOpen.bind(this)}></i>*/}
							<span className="all-conversations-title">
								All conversations
								{!this.state.allConversationsOpen ? (
									<span>{`(${this.state.conversations.length})`}</span>
								) : (null)}
							</span>
							<span className="all-conversations-count">
								{this.state.totalConversations === 0 ? (
									'0 of 0'
								) : (
									<span>{this.state.conversationSkip + 1} - {this.state.sizeOfPage + this.state.conversationSkip} of {this.state.totalConversations.toLocaleString()}</span>
								)}
							</span>
							{/*<i className="fa fa-ellipsis-v"></i>*/}
						</div>
						<div className={this.state.allConversationsOpen ? "all-conversations" : "all-conversations hidden"}>
							{this.state.conversations.length ? (
								this.state.conversations.map(function(conversation, index) {
									return (
										<div className="conversation-container" key={index}>
											<div className="conversation-selector-container">
												<input id={"conversation-selector-" + index} className="conversation-selector" type="checkbox"
													checked={conversation.selected} onChange={this.toggleConversationProperties.bind(this, 'conversations', 'selected', index)} />
												<label htmlFor={"conversation-selector-" + index}></label>
											</div>
											<i className={conversation.starred ? "fa fa-star conversation-star" : "fa fa-star-o conversation-star"}
												onClick={this.toggleConversationProperties.bind(this, 'conversations', 'starred', index)}></i>
											<div className={conversation.important ? "conversation-important selected" : "conversation-important"}
												onClick={this.toggleConversationProperties.bind(this, 'conversations', 'important', index)}></div>
											<div className="conversation-title" onClick={this.openConversation.bind(this, index)}>
												<span className="conversation-title-span">
													<span className="conversation-project-title">{`[${conversation.project.name}] `}</span>
													<span className="conversation-subject">{conversation.subject}</span>
													<span className="conversation-messages-count">{' - (Contains ' + conversation.messagesCount + ' messages)'}</span>
												</span>
											</div>
											{conversation.hasAttachment ? (
												<i className="fa fa-paperclip"></i>
											) : (null)}
											{moment(conversation.updatedAt.split(' ')[0] + '00:00', 'DD/MM/YYYY HH:mm').diff(moment(), 'days') < 0 ? (
												<div className="conversation-last-updated">{moment(conversation.updatedAt, 'DD/MM/YYYY HH:mm').format('MMM DD')}</div>
											) : (
												<div className="conversation-last-updated">{moment(conversation.updatedAt, 'DD/MM/YYYY HH:mm').format('hh:mm A')}</div>
											)}
										</div>
									)
								}.bind(this))
							) : (
								this.state.search === undefined || this.state.search === null ? (
									<div className="no-conversations">
										{this.state.loading ? (
											'Loading...'
										) : (
											'No emails were found.'
										)}
									</div>
								) : (
									<div className="no-conversations">
										<i className="fa fa-search"></i>
										No emails matched your search. Try using different search criterias.
									</div>
								)
							)}
						</div>
					</div>
				) : (null)}

				{this.state.showConversationType === 'single' && this.state.selectedConversation !== null && !this.state.conversationProjectView ? (
					<div className="single-conversation-container">
						<div className="single-conversation-container-header">
							<i className="fa fa-arrow-left back-to-all-conversations-arrow" onClick={this.backToAllConversations.bind(this)}></i>
							<span className="single-conversation-title">
								<span className="conversation-project-title">{`[${this.state.selectedConversation.project.name}] `}</span>
								<span className="conversation-subject">{this.state.selectedConversation.subject}</span>
							</span>
							{/*<i className="fa fa-ellipsis-v"></i>*/}
						</div>
						<div className="single-conversation-messages-container">
							{this.state.selectedConversation.messages.length ? (
								this.state.selectedConversation.messages.map(function(message, index) {
									return (
										<div className="conversation-container" key={index}>
											<div className="sender-container">
												{message.hasOwnProperty('sender') && message.sender ? (
													<i className={message.sender.type + " fa fa-user"}></i>
												) : (
													<i className="fa fa-user"></i>
												)}
											</div>
											<div className="message-container">
												<div className="message-top-container">
													{message.from.address === JSON.parse(readCookie('userData')).email ? (
														<span className="sender-name-email">{'me'}</span>
													) : (
														message.from.name ? (
															<span className="sender-name-email">{message.from.name}</span>
														) : (
															<span className="sender-name-email">{message.from.address}</span>
														)
													)}
													<span className="received-at">
														{moment(message.receivedDate + '00:00', 'DD/MM/YYYY HH:mm').diff(moment(), 'days') < 0 ? (
															moment(message.receivedDate, 'DD/MM/YYYY HH:mm').format('MMM DD') + " ("
														) : (
															moment(message.receivedDate, 'DD/MM/YYYY HH:mm').format('hh:mm A') + " ("
														)}
														<TimeAgo style={{ fontSize: 12 }} date={moment(message.receivedDate, 'DD/MM/YYYY HH:mm').toDate()} formatter={this.formatter} />
														{")"}
													</span>
												</div>
												{message.to && message.to.length ? (
													<div className="message-to-container">
														{'to'}
														{message.to.map(function(user, index) {
															if(user.address === JSON.parse(readCookie('userData')).email) {
																if(index === message.to.length - 1) return ' me';
																else return ' me,';
															}	else if(user.name) {
																if(index === message.to.length - 1) return ` ${user.name}`;
																else return ` ${user.name},`;
															} else if(user.address) {
																if(index === message.to.length - 1) return ` ${user.address}`;
																else return ` ${user.address},`;
															} else return null;
														})}
													</div>
												) : (null)}
												{message.html ? (
													<div className="message-bottom-container" dangerouslySetInnerHTML={{__html: message.html}}></div>
												) : (
													<div className="message-bottom-container">
														{message.text}
													</div>
												)}
												{this.state.selectedConversation.hasAttachment && message.attachments && message.attachments.length ? (
													<div className="message-attachments">
														<span className="heading">Attachments :</span>
														{message.attachments.map(function(attachment, index) {
															return (
																<a className="message-attachment" href={attachment.url} target="_blank" key={index}>{attachment.name}</a>
															)
														})}
													</div>
												) : (null)}
											</div>
										</div>
									)
								}.bind(this))
							) : (
								<div className="no-conversations">
									No messages were found.
								</div>
							)}
						</div>
					</div>
				) : (null)}

				{this.state.conversationProjectView && this.state.showProjectsViewType === 'projects' ? (
					<div className="all-projects-container">
						<div className="all-projects-container-header">
							<span className="all-projects-title">
								All projects
							</span>
						</div>
						<div className="all-projects">
							{this.state.projects.length ? (
								this.state.projects.map(function(project, index) {
									return (
										<div className="project-container" key={index}>
											<div className="project-title" onClick={this.openProject.bind(this, index)}>
												<span className="project-title-span">
													<span className="project-title">{project.name}</span>
													<span className="project-messages-count">{' - (Contains ' + project.conversationsCount + ' conversations)'}</span>
												</span>
											</div>
										</div>
									)
								}.bind(this))
							) : (
								this.state.search === undefined || this.state.search === null ? (
									<div className="no-projects">
										{this.state.loading ? (
											'Loading...'
										) : (
											'No projects were found.'
										)}
									</div>
								) : (null)
							)}
						</div>
					</div>
				) : (null)}

				{this.state.conversationProjectView && this.state.showProjectsViewType === 'single-project' ? (
					<div className="all-conversations-container">
						<div className="all-conversations-container-header">
							<i className="fa fa-arrow-left back-to-all-projects-arrow" onClick={this.backToAllProjects.bind(this)}></i>
							<span className="all-conversations-title">
								{`[ ${this.state.selectedProject.name} ] - `}
								All conversations
							</span>
							<span className="all-conversations-count">
								{this.state.selectedProjectEmails.length === 0 ? (
									'0 of 0'
								) : (
									<span>{'1 - ' + this.state.selectedProjectEmails.length} of {this.state.selectedProjectEmails.length.toLocaleString()}</span>
								)}
							</span>
						</div>
						<div className="all-conversations">
							{this.state.selectedProjectEmails.length ? (
								this.state.selectedProjectEmails.map(function(conversation, index) {
									return (
										<div className="conversation-container" key={index}>
											<div className="conversation-selector-container">
												<input id={"conversation-selector-" + index} className="conversation-selector" type="checkbox"
													checked={conversation.selected} onChange={this.toggleConversationProperties.bind(this, 'selectedProjectEmails', 'selected', index)} />
												<label htmlFor={"conversation-selector-" + index}></label>
											</div>
											<i className={conversation.starred ? "fa fa-star conversation-star" : "fa fa-star-o conversation-star"}
												onClick={this.toggleConversationProperties.bind(this, 'selectedProjectEmails', 'starred', index)}></i>
											<div className={conversation.important ? "conversation-important selected" : "conversation-important"}
												onClick={this.toggleConversationProperties.bind(this, 'selectedProjectEmails', 'important', index)}></div>
											<div className="conversation-title" onClick={this.openProjectConversation.bind(this, index)}>
												<span className="conversation-title-span">
													<span className="conversation-subject">{conversation.subject}</span>
													<span className="conversation-messages-count">{' - (Contains ' + conversation.messagesCount + ' messages)'}</span>
												</span>
											</div>
											{conversation.hasAttachment ? (
												<i className="fa fa-paperclip"></i>
											) : (null)}
											{moment(conversation.updatedAt.split(' ')[0] + '00:00', 'DD/MM/YYYY HH:mm').diff(moment(), 'days') < 0 ? (
												<div className="conversation-last-updated">{moment(conversation.updatedAt, 'DD/MM/YYYY HH:mm').format('MMM DD')}</div>
											) : (
												<div className="conversation-last-updated">{moment(conversation.updatedAt, 'DD/MM/YYYY HH:mm').format('hh:mm A')}</div>
											)}
										</div>
									)
								}.bind(this))
							) : (
								this.state.search === undefined || this.state.search === null ? (
									<div className="no-conversations">
										{this.state.loading ? (
											'Loading...'
										) : (
											'No emails were found.'
										)}
									</div>
								) : (null)
							)}
						</div>
					</div>
				) : (null)}

				{this.state.conversationProjectView && this.state.showProjectsViewType === 'single-project-conversation' && this.state.selectedProjectConversation !== null ? (
					<div className="single-conversation-container">
						<div className="single-conversation-container-header">
							<i className="fa fa-arrow-left back-to-all-conversations-arrow" onClick={this.backToProjectConversations.bind(this)}></i>
							<span className="single-conversation-title">
								<span className="conversation-project-title">{`[${this.state.selectedProjectConversation.project.name}] `}</span>
								<span className="conversation-subject">{this.state.selectedProjectConversation.subject}</span>
							</span>
						</div>
						<div className="single-conversation-messages-container">
							{this.state.selectedProjectConversation.messages.length ? (
								this.state.selectedProjectConversation.messages.map(function(message, index) {
									return (
										<div className="conversation-container" key={index}>
											<div className="sender-container">
												{message.hasOwnProperty('sender') && message.sender ? (
													<i className={message.sender.type + " fa fa-user"}></i>
												) : (
													<i className="fa fa-user"></i>
												)}
											</div>
											<div className="message-container">
												<div className="message-top-container">
													{message.from.address === JSON.parse(readCookie('userData')).email ? (
														<span className="sender-name-email">{'me'}</span>
													) : (
														message.from.name ? (
															<span className="sender-name-email">{message.from.name}</span>
														) : (
															<span className="sender-name-email">{message.from.address}</span>
														)
													)}
													<span className="received-at">
														{moment(message.receivedDate + '00:00', 'DD/MM/YYYY HH:mm').diff(moment(), 'days') < 0 ? (
															moment(message.receivedDate, 'DD/MM/YYYY HH:mm').format('MMM DD') + " ("
														) : (
															moment(message.receivedDate, 'DD/MM/YYYY HH:mm').format('hh:mm A') + " ("
														)}
														<TimeAgo style={{ fontSize: 12 }} date={moment(message.receivedDate, 'DD/MM/YYYY HH:mm').toDate()} formatter={this.formatter} />
														{")"}
													</span>
												</div>
												{message.to && message.to.length ? (
													<div className="message-to-container">
														{'to'}
														{message.to.map(function(user, index) {
															if(user.address === JSON.parse(readCookie('userData')).email) {
																if(index === message.to.length - 1) return ' me';
																else return ' me,';
															}	else if(user.name) {
																if(index === message.to.length - 1) return ` ${user.name}`;
																else return ` ${user.name},`;
															} else if(user.address) {
																if(index === message.to.length - 1) return ` ${user.address}`;
																else return ` ${user.address},`;
															} else return null;
														})}
													</div>
												) : (null)}
												{message.html ? (
													<div className="message-bottom-container" dangerouslySetInnerHTML={{__html: message.html}}></div>
												) : (
													<div className="message-bottom-container">
														{message.text}
													</div>
												)}
												{this.state.selectedProjectConversation.hasAttachment && message.attachments && message.attachments.length ? (
													<div className="message-attachments">
														<span className="heading">Attachments :</span>
														{message.attachments.map(function(attachment, index) {
															return (
																<a className="message-attachment" href={attachment.url} target="_blank" key={index}>{attachment.name}</a>
															)
														})}
													</div>
												) : (null)}
											</div>
										</div>
									)
								}.bind(this))
							) : (
								<div className="no-conversations">
									No messages were found.
								</div>
							)}
						</div>
					</div>
				) : (null)}
			</div>
		);
	}
}
