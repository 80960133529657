import React, { Component } from 'react';
import {Col, Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import Slider from 'rc-slider';
import $ from 'jquery';
import Multiselect from 'react-bootstrap-multiselect';

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

const checkValue = require('../../../checkValue.js').checkValue;
const readCookie = require('../../../cookie.js').readCookie;

let max = 0;
let marks = {
	0: 'INR 0'
}

export default class AllProjects extends Component {
	constructor(props) {
		super(props);
		this.state = {
			totalProjectsCount: 0,
			interestedProjectsCount: 0,
			underReviewProjectsCount: 0,
			investedProjectsCount: 0,
			skippedProjectsCount: 0,
			filters: {
				searchTerm: '',
				status: 'all',
				goal: '',
				priceRangeMax: 0,
				priceRangeMin: 0,
			},
			goals: [],
			projects: []
		}
	}

	updateSearchTerm(e) {
		let value = e.target.value;
		let filters = this.state.filters;
		filters.searchTerm = value;
		this.setState({ filters }, function() {
			// if(value === '' && value.length > 3) this.filterProjects();
		}.bind(this));
	}

	filterProjects() {
		let filters = this.state.filters;
		let query = '';

		if(filters.searchTerm.length > 3) {
			if(query === '') query = '?query=' + filters.searchTerm;
			else query += '&query=' + filters.searchTerm;
		}

		if(filters.goal !== '') {
			if(Array.isArray(filters.goal)) {
				if(filters.goal.length) {
					for(var i = 0; i < filters.goal.length; i++) {
						if(query === '') query = '?goalId=' + filters.goal[i];
						else query += '&goalId=' + filters.goal[i];
					}
				}
			}
		}

		if(typeof filters.priceRangeMin === 'number') {
			if(query === '') query = '?minBudget=' + filters.priceRangeMin;
			else query += '&minBudget=' + filters.priceRangeMin;
		}

		if(typeof filters.priceRangeMax === 'number') {
			if(query === '') query = '?maxBudget=' + filters.priceRangeMax;
			else query += '&maxBudget=' + filters.priceRangeMax;
		}

		if(filters.status !== '') {
			if(query === '') query = '?status=' + filters.status;
			else query += '&status=' + filters.status;
		}
	}

	priceFormatter(v) {
		return `INR ${v}`;
	}

	log(val) {
		this.updateMinMax('minmax', val);
	}

	updateMinMax(name, val) {
		let filters = this.state.filters;
		if(name === 'minmax') {
			filters['priceRangeMin'] = val[0];
			filters['priceRangeMax'] = val[1];
		} else if(name === 'priceRangeMin' || name === 'priceRangeMax') {
			let value;
			if(val.target.value === '') value = 0;
			else value = val.target.value;
			filters[name] = parseInt(value);
		}
		this.setState({ filters });
	}

	showButtonText(options, select) {
		if (options.length === 0) {
			return 'No option selected ...';
		} else if (options.length > 2) {
			return options.length + ' options selected!';
		} else {
			let labels = [];
			options.each(function() {
				if ($(this).attr('label') !== undefined) {
					labels.push($(this).attr('label'));
				} else {
					labels.push($(this).html());
				}
			});
			return labels.join(', ') + '';
		}
	}

	handleFiltersChange(name, value) {
		let filters = this.state.filters;
		filters[name] = $(value).parents('select').val();
		this.setState({ filters });
	}

	componentDidMount() {
		fetch(process.env.REACT_APP_API_URL + '/getOtAttributes', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
		.then(function(data) {
			let goals = data.otAttributes;
			let goalData = [];
			let preferedGoals = {
				label: 'Prefered Goals'
			}
			let companyId = JSON.parse(readCookie('userData')).organisationData.id;
			fetch(process.env.REACT_APP_API_URL + '/company/' + companyId + '/mis/preferences', {
				method: "GET",
				headers: {
					'Auth': JSON.parse(readCookie('access_token')).access_token
				}
			}).then((data) => data.json())
			.then(function(data) {
				let children = [];
				let preferedGoalIds = [];
				for(var i = 0; i < data.data.goals.length; i++) {
					let child = {};
					child['value'] = data.data.goals[i].id;
					child['label'] = data.data.goals[i].name;
					children.push(child);
					preferedGoalIds.push(data.data.goals[i].id);
				}
				preferedGoals['children'] = children;
				preferedGoals['label'] = preferedGoals['label'] + ' (' + children.length + ')';

				let otherGoals = {
					label: 'Other Goals'
				}
				let otherChildren = [];
				for(let i = 0; i < goals.length; i++) {
					if(goals[i].type === 'goal') {
						if(preferedGoalIds.indexOf(goals[i]._id) === -1) {
							let goalObj = {};
							goalObj['value'] = goals[i]._id;
							goalObj['label'] = goals[i].name;
							otherChildren.push(goalObj);
						}
					}
				}
				otherGoals['children'] = otherChildren;
				otherGoals['label'] = otherGoals['label'] + ' (' + otherChildren.length + ')';
				goalData.push(preferedGoals);
				goalData.push(otherGoals);
				this.setState({ goals: goalData });
			}.bind(this));

			fetch(process.env.REACT_APP_API_URL + '/company/' + companyId + '/mis/suggest-projects', {
				method: "GET",
				headers: {
					'Auth': JSON.parse(readCookie('access_token')).access_token
				}
			}).then((data) => data.json())
			.then(function(data) {
				max = 0;
				for(var i = 0; i < data.data.length; i++) {
					if(data.data[i]['proposedBudget'] > max) max = data.data[i]['proposedBudget'];
				}
				marks[max] = 'INR ' + max;
				let filters = this.state.filters;
				filters['priceRangeMax'] = max;
				this.setState({ projects: data.data, filters });
			}.bind(this));
		}.bind(this));
	}

	resetFilters() {
		let filters = {
			searchTerm: '',
			status: 'all',
			goal: '',
			priceRangeMax: 0,
			priceRangeMin: 0,
		}

    this.setState({ filters }, function() {
			this.filterProjects();
		}.bind(this));
	}

	updateStatus(value) {
		let filters = this.state.filters;
		filters['status'] = value;
		this.setState({ filters }, function() {
			this.filterProjects();
		}.bind(this));
	}

	showInnovativeModels() {
		window.location.pathname = '/discover/innovative-models';
	}

	render() {
		return (
			<div className="partner-projects-tab-container">
				<div className="first-filter-row">
					<div className="search-container">
						<div className="csr-projects-search-filter-container">
							<input className="csr-projects-search-filter-input" type="search" placeholder="Search by Name" onChange={this.updateSearchTerm.bind(this)} />
							<button className="btn csr-projects-search-filter-icon" onClick={this.updateSearchTerm.bind(this)}><i className="fa fa-search"></i></button>
						</div>
					</div>
				</div>
				<div className="second-filter-row">
					<Col md={12}>
						<div className="status-buttons-container">
							<ol className="project-type-breadcrumb">
								<li><a className={this.state.status === "all" ? "selected" : ""} onClick={this.updateStatus.bind(this, 'all')}>{'All (' + this.state.totalProjectsCount + ')'}</a></li>
								<li><a className={this.state.status === "interested" ? "selected" : ""} onClick={this.updateStatus.bind(this, 'interested')}>{'Interested (' + this.state.interestedProjectsCount + ')'}</a></li>
								<li><a className={this.state.status === "under-review" ? "selected" : ""} onClick={this.updateStatus.bind(this, 'under-review')}>{'Under review (' + this.state.underReviewProjectsCount + ')'}</a></li>
								<li><a className={this.state.status === "invested" ? "selected" : ""} onClick={this.updateStatus.bind(this, 'invested')}>{'Invested (' + this.state.investedProjectsCount + ')'}</a></li>
							</ol>
							<button className={this.state.status === "skipped" ? "status-btn selected" : "status-btn"} onClick={this.updateStatus.bind(this, 'skipped')}>{'Skipped (' + this.state.skippedProjectsCount + ')'}</button>
							{/*<button className={this.state.filters.status === "all" ? "btn status-btn selected" : "btn status-btn"} onClick={this.updateStatus.bind(this, 'all')}>{'All (' + this.state.totalProjectsCount + ')'}</button>
							<button className={this.state.filters.status === "interested" ? "btn status-btn selected" : "btn status-btn"} onClick={this.updateStatus.bind(this, 'interested')}>{'Interested (' + this.state.interestedProjectsCount + ')'}</button>
							<button className={this.state.filters.status === "underReview" ? "btn status-btn selected" : "btn status-btn"} onClick={this.updateStatus.bind(this, 'underReview')}>{'Under review (' + this.state.underReviewProjectsCount + ')'}</button>
							<button className={this.state.filters.status === "invested" ? "btn status-btn selected" : "btn status-btn"} onClick={this.updateStatus.bind(this, 'invested')}>{'Invested (' + this.state.investedProjectsCount + ')'}</button>
							<button className={this.state.filters.status === "skipped" ? "btn status-btn selected" : "btn status-btn"} onClick={this.updateStatus.bind(this, 'skipped')}>{'Skipped (' + this.state.skippedProjectsCount + ')'}</button>*/}
						</div>
					</Col>
					<Col md={3}>
						<span>Goals</span>
						{this.state.filters.goal !== null ? (
							<Multiselect id="csr-project-goals" defaultValue={this.state.filters.goal} onChange={this.handleFiltersChange.bind(this, 'goal')} buttonWidth={'100%'} buttonText={this.showButtonText.bind(this)} data={this.state.goals} multiple />
						) : (null)}
					</Col>
					<Col md={5}>
						<span>Budget Range</span>
						{this.state.filters.priceRangeMin !== null && this.state.filters.priceRangeMax !== null ? (
							<div>
								<Range step={5000} min={0} max={max} value={[this.state.filters.priceRangeMin, this.state.filters.priceRangeMax]} marks={marks} onChange={this.log.bind(this)} tipFormatter={this.priceFormatter} />
								<div className="csr-projects-minmax-budget-container">
									<b>{'Min : '}</b><input className="csr-projects-minmax-budget" type="number" placeholder="Min" onChange={this.updateMinMax.bind(this, 'priceRangeMin')} value={this.state.filters.priceRangeMin} />
									<b>{'Max : '}</b><input className="csr-projects-minmax-budget" type="number" placeholder="Max" onChange={this.updateMinMax.bind(this, 'priceRangeMax')} value={this.state.filters.priceRangeMax} />
								</div>
							</div>
						) : (null)}
					</Col>
					<Col md={1} className="btn filter-reset-btn" onClick={this.resetFilters.bind(this)}>Reset</Col>
					<Col md={1} className="btn filter-apply-btn" onClick={this.filterProjects.bind(this)}>Apply</Col>
				</div>
				<div className="projects-count-row">
					<span className="dot"></span>
					<span className="project-count">{this.state.projects.length + ' Projects Found'}</span>
					<span className="line"></span>
				</div>
				<div className="projects-container">
					{this.state.projects.map(function(project, i) {
						return (
							<Row className="single-project csr-project" key={i}>
								<Col md={3}>
									<img className="csr-project-image" src={project.photo ? project.photo : "https://assets.letsendorse.com/sahaj/project-placeholder.jpg"} />
								</Col>
								<Col md={6}>
									<Col md={12} className="project-title">
										<Link to={"/csr/csr-projects/" + project.customUrl} target="_blank"><span>{project.title}</span></Link>
									</Col>
									<Col md={12} className="project-description">
										<p>{project.description}</p>
									</Col>
									{checkValue(project.targetBeneficiaries) !== true ? (
										<Col md={12} className="features mt5" hidden={checkValue(project.targetBeneficiaries)}>
											<div className="heading">Target beneficiaries: </div>
											<div className="content">
												{project.targetBeneficiaries.map(function(targetBeneficiary, i) {
													if(project.targetBeneficiaries.length - i === 1) return targetBeneficiary.name
													else return targetBeneficiary.name + ', '
												}.bind(this))}
											</div>
										</Col>
									) : (null)}
									{checkValue(project.goalData) !== true ? (
										<Col md={12} className="features mt5" hidden={checkValue(project.goalData.name)}>
											<div className="heading">Cause/Developmental Goal: </div>
											<div className="content">{project.goalData.name}</div>
										</Col>
									) : (null)}
									<Col md={12} className="features mt5">
										<Link to={"/csr/csr-projects/" + project.customUrl} target="_blank">
											<button className="btn btn-interested">Interested</button>
										</Link>
									</Col>
								</Col>
								<Col md={3}>
									<Col md={12} className="project-location" hidden={checkValue(project.address) && checkValue(project.city) && checkValue(project.state) && checkValue(project.country) && checkValue(project.pincode)}>
										<div>
											<i className="fa fa-map-marker"></i><b>{' Location: '}</b>
											<span hidden={checkValue(project.address)}>{' ' + project.address + ','}</span>
										</div>
										<span className="project-address">
											<span hidden={checkValue(project.city)}>{project.city}</span>
											<span hidden={checkValue(project.state)}>{', ' + project.state}</span>
											<span hidden={checkValue(project.country)}>{', ' + project.country}</span>
											<span hidden={checkValue(project.pincode)}>{', ' + project.pincode}</span>
										</span>
									</Col>
									<Col md={12} className="project-budget-con" hidden={checkValue(project.proposedBudget)}>
										<i className="fa fa-money"></i>
										<span className="project-budget">
											{project.hasOwnProperty('currencySymbol') ? (
												<span><b>{' Budget: '}</b>{project.currencySymbol + ' ' + project.proposedBudget}</span>
											) : (
												<span><b>{' Budget: '}</b>{'INR ' + project.proposedBudget}</span>
											)}
										</span>
									</Col>
									<Col md={12} className="project-duration-container" hidden={checkValue(project.expectedDuration)}>
										<i className="fa fa-calendar"></i>
										<span className="project-duration"><b>{' Duration: '}</b>{project.expectedDuration}</span>
									</Col>
								</Col>
							</Row>
						)
					}.bind(this))}
				</div>
				<div className="fixed-bottom-container">
					<div>
						<h4>Explore Innovative and Impactful Solutions Across SDGs.</h4>
						<button className="btn show-innovations-btn" onClick={this.showInnovativeModels.bind(this)}>Show me!</button>
					</div>
				</div>
			</div>
		);
	}
}
