import React, { Component } from 'react';
import DashboardMap from './DashboardMap';
import { OverlayTrigger, Col, Row } from 'react-bootstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip, RadialBarChart, RadialBar, Legend, Label, BarChart, Bar, XAxis, YAxis, CartesianGrid } from 'recharts';
import $ from 'jquery';
import { LocaleProvider } from 'antd';
import enUS from 'antd/lib/locale-provider/en_US';
import { BootstrapTable, TableHeaderColumn, ButtonGroup } from 'react-bootstrap-table';
import Icon from 'react-icons-kit';
import { ic_add_circle_outline } from 'react-icons-kit/md/ic_add_circle_outline';
import { ic_delete } from 'react-icons-kit/md/ic_delete';
import { Menu } from 'antd';
import Scroll from 'react-scroll';
import ReactGA from 'react-ga';
import createClass from 'create-react-class';

const readCookie = require('../../../cookie.js').readCookie;

const {PropTypes} = React;

let year = moment().year();
const years = [];
for (var i = -3; i < 4; i++) {
  var yearStr = (year - i) + '-' + (year - i + 1);
  years.push(yearStr);
}

const CustomTooltip  = createClass({
  render() {
    const { active } = this.props;

    if (active) {
      const { payload, label } = this.props;
      return (
        <div className="goal-wise-projects-tooltip">
          <p className="label">{`${payload[0].name} : ${payload[0].value}`}</p>
        </div>
      );
    } return null;
  }
});

export default class MISDashboardCSR extends Component {
  constructor(props) {
    super(props);
    this.state = {
      csrInvestmentsData: null,
      fiscalYear: this.getCurrentFiscalYear(),  //moment().year() + '-' + moment().add(1, 'years').year(),
      markers: null,
      amountComparisonData: [],
      createCSRPortfolio: false,
      investmentByCauses: [],
      investmentByThematicCategory: [],
      hasCustomProjectCategories: false,
      investmentByCustomProjectCategory: [],
      investmentByGoals: [],
      activeTab: 'overview',
      projectInterestStatus: null,
      targetBeneficiaries: {
        potential: 0,
        actual: 0
      }
    }
    this.changeInvestmentDataWithYear = this.changeInvestmentDataWithYear.bind(this);
    this.modifyInvestmentByCausesData = this.modifyInvestmentByCausesData.bind(this);
    this.modifyInvestmentByThematicCategoryData = this.modifyInvestmentByThematicCategoryData.bind(this);
    this.modifyInvestmentByCustomProjectCategoryData = this.modifyInvestmentByCustomProjectCategoryData.bind(this);
    this.navigateInvestments=this.navigateInvestments.bind(this);
  }

  getCurrentFiscalYear() {
    var today = new Date();
    var curMonth = today.getMonth();
    var fiscalYr = "";
    if (curMonth > 3) {
      return today.getFullYear().toString() + '-' + (today.getFullYear() + 1).toString();
    } else {
      return (today.getFullYear() - 1).toString() + '-' + today.getFullYear().toString();
    }
  }

  componentDidMount() {
    let companyId = JSON.parse(readCookie('userData')).organisationData.id;
    fetch(process.env.REACT_APP_API_URL + '/company/' + companyId + '/mis/dashboard', {
      method: "GET",
      headers: {
        'Auth': JSON.parse(readCookie('access_token')).access_token
      }
    }).then((data) => data.json())
    .then(function (data) {
      let csrInvestmentsData = {};
      csrInvestmentsData.totalAllocated = data.data.allocatedAmount;
      csrInvestmentsData.totalInvested = data.data.investmentAmount;
      csrInvestmentsData.totalUtilized = data.data.utilizedAmount;
      csrInvestmentsData.totalLiveProjects = data.data.partnersCount;
      csrInvestmentsData.totalInvestmentsCount = data.data.investmentCount;

      var that = this;
      $.getScript('https://cdnjs.cloudflare.com/ajax/libs/jquery-countto/1.2.0/jquery.countTo.min.js').then(function() {
        that.setTickerToValue(data.data.allocatedAmount, 'totalAllocated');
        that.setTickerToValue(data.data.investmentAmount, 'totalInvested');
        that.setTickerToValue(data.data.utilizedAmount, 'totalUtilized');
        that.setTickerToValue(data.data.partnersCount, 'totalLiveProjects');
        that.setTickerToValue(data.data.investmentCount, 'totalInvestmentsCount');
        that.setTickerToValue(data.data.targetBeneficiaries.actual, 'targetBeneficiaries');

        if(data.data.targetBeneficiaries.potential !== 0) {
          var beneficiaryPercentage =  Math.round(data.data.targetBeneficiaries.actual / data.data.targetBeneficiaries.potential * 100);
          if(beneficiaryPercentage > 100) that.setTickerToValue(100, 'beneficiaryPercentage');
          else that.setTickerToValue(beneficiaryPercentage, 'beneficiaryPercentage');
        }
      });

      if(data.data.hasOwnProperty('hasCustomProjectCategories'))
        this.setState({hasCustomProjectCategories: data.data.hasCustomProjectCategories});

      if (data.data.allocatedAmount !== 0) {
        var investmentByCauses = this.modifyInvestmentByCausesData(data.data.investmentByCauses);
        var investmentByThematicCategory = this.modifyInvestmentByThematicCategoryData(data.data.investmentByThematicCategory);
        var investmentByCustomProjectCategory =this.modifyInvestmentByCustomProjectCategoryData(data.data.investmentByCustomProjectCategory)
        var investmentByGoals = this.modifyInvestmentByGoalsData(data.data.investmentByCauses);
        var amountComparisonData = this.getAmountComparisonData(data.data.allocatedAmount, data.data.investmentAmount, data.data.utilizedAmount);

        this.setState({ csrInvestmentsData, amountComparisonData, createCSRPortfolio: false, investmentByCauses, investmentByThematicCategory, investmentByCustomProjectCategory, investmentByGoals, targetBeneficiaries: data.data.targetBeneficiaries });
      } else {
        this.setState({ createCSRPortfolio: true });
      }
    }.bind(this));

    fetch(process.env.REACT_APP_API_URL + '/project/recommendations/' + companyId, {
      method: "GET",
      headers: {
        'Auth': JSON.parse(readCookie('access_token')).access_token
      }
    }).then((data) => data.json())
    .then(function (data) {
      if(data.status === 'ok') {
        this.setState({ projectInterestStatus: data.projectInterestStatus });
      }
    }.bind(this));
  }

  changeInvestmentDataWithYear() {
    var year = this.state.fiscalYear.split('-')[0];
    let companyId = JSON.parse(readCookie('userData')).organisationData.id;

    fetch(process.env.REACT_APP_API_URL + '/company/' + companyId + '/mis/dashboard/' + year, {
      method: "GET",
      headers: {
        'Auth': JSON.parse(readCookie('access_token')).access_token
      }
    }).then((data) => data.json())
    .then(function (data) {
      if (data.data.allocatedAmount !== 0) {
        this.setState({ createCSRPortfolio: false, csrInvestmentsData: { 
          totalAllocated: 0, 
          totalInvested: 0, 
          totalUtilized: 0, 
          totalLiveProjects: 0, 
          totalInvestmentsCount: 0 } }, function() {
          let csrInvestmentsData = {};
          csrInvestmentsData.totalAllocated = data.data.allocatedAmount;
          csrInvestmentsData.totalInvested = data.data.investmentAmount;
          csrInvestmentsData.totalUtilized = data.data.utilizedAmount;
          csrInvestmentsData.totalLiveProjects = data.data.partnersCount;
          csrInvestmentsData.totalInvestmentsCount = data.data.investmentCount;

          this.setTickerToValue(data.data.allocatedAmount, 'totalAllocated');
          this.setTickerToValue(data.data.investmentAmount, 'totalInvested');
          this.setTickerToValue(data.data.utilizedAmount, 'totalUtilized');
          this.setTickerToValue(data.data.partnersCount, 'totalLiveProjects');
          this.setTickerToValue(data.data.investmentCount, 'totalInvestmentsCount');
          this.setTickerToValue(data.data.targetBeneficiaries.actual, 'targetBeneficiaries');

          if(data.data.targetBeneficiaries.potential !== 0) {
            var beneficiaryPercentage =  Math.round(data.data.targetBeneficiaries.actual / data.data.targetBeneficiaries.potential * 100);
            if(beneficiaryPercentage > 100) this.setTickerToValue(100, 'beneficiaryPercentage');
            else this.setTickerToValue(beneficiaryPercentage, 'beneficiaryPercentage');
          }

          var investmentByCauses = this.modifyInvestmentByCausesData(data.data.investmentByCauses);
          var investmentByThematicCategory = this.modifyInvestmentByThematicCategoryData(data.data.investmentByThematicCategory);
          if(data.data.investmentByCustomProjectCategory)
          var investmentByCustomProjectCategory = this.modifyInvestmentByCustomProjectCategoryData(data.data.investmentByCustomProjectCategory);
          var investmentByGoals = this.modifyInvestmentByGoalsData(data.data.investmentByCauses);
          var amountComparisonData = this.getAmountComparisonData(data.data.allocatedAmount, data.data.investmentAmount, data.data.utilizedAmount);

          this.setState({ csrInvestmentsData, amountComparisonData, investmentByCauses, investmentByThematicCategory, investmentByCustomProjectCategory, investmentByGoals, targetBeneficiaries: data.data.targetBeneficiaries });
        }.bind(this));
      } else {
        this.setState({ createCSRPortfolio: true });
      }
    }.bind(this));

    fetch(process.env.REACT_APP_API_URL + '/project/recommendations/' + companyId + '?year=' + year, {
      method: "GET",
      headers: {
        'Auth': JSON.parse(readCookie('access_token')).access_token
      }
    }).then((data) => data.json())
    .then(function (data) {
      if(data.status === 'ok') {
        this.setState({ projectInterestStatus: data.projectInterestStatus });
      }
    }.bind(this));
  }

  getAmountComparisonData(allocatedAmount, investmentAmount, utilizedAmount) {
    var amountComparisonData = [];
    let allocated = {
      name: 'Allocated (100%)',
      value: 100,
      fill: '#ef5a20'
    };
    let invested = {
      name: 'Invested (' + ((investmentAmount / allocatedAmount) * 100).toFixed(2) + '%)',
      value: ((investmentAmount / allocatedAmount) * 100).toFixed(2),
      fill: '#8884d8'
    };
    let utilized = {
      name: 'Utilized (' + ((utilizedAmount / allocatedAmount) * 100).toFixed(2) + '%)',
      value: ((utilizedAmount / allocatedAmount) * 100).toFixed(2),
      fill: '#82ca9d'
    };
    if(isNaN(invested.value)) {
      invested.name = 'Invested (0%)';
      invested.value = 0;
    }
    if(isNaN(utilized.value)) {
      utilized.name = 'Utilized (0%)';
      utilized.value = 0;
    }

    amountComparisonData.push(allocated);
    amountComparisonData.push(invested);
    amountComparisonData.push(utilized);

    // var max = Math.max(allocatedAmount, investmentAmount, utilizedAmount);
    // var aVal, iVal, uVal;

    // if(max === allocatedAmount) {
    //   aVal = 100;
    //   iVal = ((investmentAmount / allocatedAmount) * 100).toFixed(2);
    //   uVal = ((utilizedAmount / allocatedAmount) * 100).toFixed(2);
    // } else if(max === investmentAmount) {
    //   aVal = ((allocatedAmount / investmentAmount) * 100).toFixed(2);
    //   iVal = 100;
    //   uVal = ((utilizedAmount / investmentAmount) * 100).toFixed(2);
    // } else {
    //   aVal = ((allocatedAmount / utilizedAmount) * 100).toFixed(2);
    //   iVal = ((investmentAmount / utilizedAmount) * 100).toFixed(2);
    //   uVal = 100;
    // }

    // if(isNaN(aVal)) aVal = 0;
    // if(isNaN(iVal)) iVal = 0;
    // if(isNaN(uVal)) uVal = 0;

    // let allocated = {
    //   name: 'Allocated (' + aVal + '%)',
    //   value: aVal,
    //   fill: '#ef5a20'
    // };
    // let invested = {
    //   name: 'Invested (' + iVal + '%)',
    //   value: iVal,
    //   fill: '#8884d8'
    // };
    // let utilized = {
    //   name: 'Utilized (' + uVal + '%)',
    //   value: uVal,
    //   fill: '#82ca9d'
    // };

    // if(max === allocatedAmount) {
    //   if(Math.max(investmentAmount, utilizedAmount) === investmentAmount) amountComparisonData.push(utilized, invested, allocated);
    //   else amountComparisonData.push(invested, utilized, allocated);
    // } else if(max === investmentAmount) {
    //   if(Math.max(allocatedAmount, utilizedAmount) === allocatedAmount) amountComparisonData.push(utilized, allocated, invested);
    //   else amountComparisonData.push(allocated, utilized, invested);
    // } else {
    //   if(Math.max(investmentAmount, allocatedAmount) === investmentAmount) amountComparisonData.push(allocated, invested, utilized);
    //   else amountComparisonData.push(invested, allocated, utilized);
    // }
    return amountComparisonData;
  }

  ddClick() {
    $('#dd').toggleClass('active');
  }

  selectYear(event) {
    var opt = $(event.target).text();
    $('#dd > span').html(opt);
    this.selectFiscalYear(opt);
  }

  selectFiscalYear(year) {
    if (year === 'default') {
      this.setState({ fiscalYear: this.getCurrentFiscalYear() }, function () {
        this.changeInvestmentDataWithYear();
      }.bind(this));
    } else {
      this.setState({ fiscalYear: year }, function () {
        this.changeInvestmentDataWithYear();
      }.bind(this));
    }
  }

  setAnnualBudget() {
    sessionStorage.setItem("selectedFiscalYear", this.state.fiscalYear)
    window.location.href = '/fiscal-year-budgeting'
  }

  modifyInvestmentByCausesData(data) {
    let newData = [];
    for (var i = 0; i < data.length; i++) {
      let x = {};
      x['id'] = data[i].id;
      x['name'] = data[i].name;
      x['Allocated'] = data[i].amount;
      x['Invested'] = data[i].investedAmount;
      x['Utilized'] = data[i].utilizedAmount;
      newData.push(x);
    }
    return newData;
  }

  modifyInvestmentByThematicCategoryData(data) {
    let newData = [];
    for (var i = 0; i < data.length; i++) {
      let x = {};
      x['id'] = data[i].id;
      x['name'] = data[i].name;
      x['Allocated'] = data[i].amount;
      x['Invested'] = data[i].investedAmount;
      x['Utilized'] = data[i].utilizedAmount;
      newData.push(x);
    }
    return newData;
  }

  modifyInvestmentByCustomProjectCategoryData(data) {
    let newData = [];
    for (var i = 0; i < data.length; i++) {
      let x = {};
      x['id'] = data[i].id;
      x['name'] = data[i].name;
      x['Allocated'] = data[i].amount;
      x['Invested'] = data[i].investedAmount;
      x['Utilized'] = data[i].utilizedAmount;
      newData.push(x);
    }
    return newData;
  }

  modifyInvestmentByGoalsData(data) {
    let newData = [];
    for (var i = 0; i < data.length; i++) {
      let x = {};
      x['id'] = data[i].id;
      x['name'] = data[i].name;
      x['value'] = data[i].projectCount;
      x['backgroundColor'] = data[i].backgroundColor;
      newData.push(x);
    }
    return newData;
  }

  setTickerToValue = (value, stateName) => {
    var that = this;
    var h = 0;
    var increment = 1;
    var stepTime = Math.abs(Math.floor(1000 / value));

    if(stepTime == 0) {
      var pow = Math.ceil(Math.log10(value / 1000));
      increment = Math.pow((value / 1000), pow);
      stepTime = 1000 / value;
    }
    var selector = '';
    if(stateName === 'beneficiaryPercentage') {
      selector = '.percent-count.count > span.value';
    } else if(stateName === 'targetBeneficiaries') {
      selector = '.beneficiary-actual';
    } else {
      if(stateName === 'totalAllocated' || stateName === 'totalInvested' || stateName === 'totalUtilized') {
        selector = '.amount.' + stateName + ' > span.value';
      } else {
        selector = '.amount.' + stateName;
      }
    }
    var options = {
      from: 0,
      to: value,
      speed: 1000,
      onComplete: window.updateWithCommas(value, selector)
    };
    var x = ``;
    if(stateName === 'beneficiaryPercentage') {
      x = `<script>$('.percent-count.count > span.value').countTo(` + JSON.stringify(options) + `)</script>`;
    } else if(stateName === 'targetBeneficiaries') {
      x = `<script>$('.beneficiary-actual').countTo(` + JSON.stringify(options) + `)</script>`;
    } else {
      if(stateName === 'totalAllocated' || stateName === 'totalInvested' || stateName === 'totalUtilized') {
        x = `<script>$('.amount.` + stateName + ` > span.value').countTo(` + JSON.stringify(options) + `)</script>`;
      } else {
        x = `<script>$('.amount.` + stateName + `').countTo(` + JSON.stringify(options) + `)</script>`;
      }
    }
  
    $('body').append(x);

    if(stateName === 'beneficiaryPercentage') {
      var x = setInterval(function () {
        h += increment;
        if( h >= value) {
          clearInterval(x);
          if(document.getElementsByClassName("percent-bar")[0]) document.getElementsByClassName("percent-bar")[0].style.width = parseInt(value) + "%";
        } else {
          if(document.getElementsByClassName("percent-bar")[0]) document.getElementsByClassName("percent-bar")[0].style.width = parseInt(h) + "%";
        }
      }, stepTime);
    }
  }

  viewAllInvestments() {
    window.location.pathname = '/investments';
  }

  gotoPath(type, path) {
    ReactGA.event({
      category: 'Dashboard',
      action: 'click',
      label: type
    });
    window.location.pathname = path;
  }

  gotoImpactFootprint() {
    ReactGA.event({
      category: 'Dashboard',
      action: 'click',
      label: 'Impact Footprint'
    });
  }

  customInvestmentTooltipFormatter(value, name, props) {
    return 'INR ' + window.inrFormat(value);
  }

  customInvestmentTickFormatter(value) {
    return 'INR ' + window.inrFormat(value);
  }
  navigateInvestments(){
    this.props.history.push({pathname: '/investments', state:{fiscalYear:this.state.fiscalYear}});
  }

  render() {
    if (this.state.csrInvestmentsData !== null) {
      return (
        <div className="mis-content-container mis-dashboard-csr">
          <div className="fiscal-year-selector fixed">
            <span>
              A snapshot of our community investment in Fiscal Year:
              <div id="dd" className="wrapper-dropdown-3" tabIndex="1" onClick={this.ddClick.bind(this)}>
                <span>{this.getCurrentFiscalYear()}</span>
                <ul className="dropdown">
                  {years.map(function (year, index) {
                    return (
                      <li key={index} onClick={this.selectYear.bind(this)}><a href="#">{year}</a></li>
                    )
                  }.bind(this))}
                </ul>
              </div>
            </span>
          </div>
          {this.state.createCSRPortfolio ? (null) : (
            <Menu selectedKeys={[this.state.activeTab]} mode="horizontal" className="dashboard-tabs">
              <Menu.Item key="overview">
                <span to="/dashboard" offset={-100}>Overview</span>
              </Menu.Item>
              <Menu.Item key="impactFootprint">
                <Link to="/dashboard/impact-footprint" offset={-50} onClick={this.gotoImpactFootprint.bind(this)}>Impact Footprint</Link>
              </Menu.Item>
            </Menu>
          )}
          {this.state.createCSRPortfolio ? (
            <div className="create-annual-budget-container">
              <div className="create-annual-budget-button" onClick={this.setAnnualBudget.bind(this)}>Create Your Annual Budget for {this.state.fiscalYear}</div>
            </div>
          ) : (
            <div className="row">
              <div className="square-box">
                <div className="content">
                  <div className="heading">Total Allocated Amount</div>
                  <div className="sub-heading"></div>
                  <div className="amount totalAllocated"><span>(INR)</span><span className="value">0</span></div>
                  <span className="link" onClick={this.gotoPath.bind(this, "Change Fiscal Year Budget", "/fiscal-year-budgeting")}>Change This</span>
                </div>
              </div>
              <div className="square-box">
                <div className="content">
                  <div className="heading">Total Invested Amount</div>
                  <div className="sub-heading"></div>
                  <div className="amount totalInvested"><span>(INR)</span><span className="value">0</span></div>
                  <span className="link" onClick={this.gotoPath.bind(this, "Change Portfolio Amount", "/investments")}>Invest More</span>
                </div>
              </div>
              <div className="square-box">
                <div className="content">
                  <div className="heading">Total Utilized Amount</div>
                  <div className="sub-heading"></div>
                    <div className="amount totalUtilized"><span>(INR)</span><span className="value">{window.inrFormat(0)}</span></div>
                  {/*<span className="link" onClick={() => {window.location.pathname = "/portfolio" }}>Show Project Utilization</span>*/}
                </div>
              </div>
              <div className="square-box">
                <div className="content">
                  <div className="heading">Total Live Projects</div>
                  <div className="sub-heading"></div>
                  <div className="amount totalInvestmentsCount">0</div>
                  <span className="link" onClick={this.navigateInvestments}>Show all Investments</span>
                </div>
              </div>
              <div className="square-box">
                <div className="content">
                  <div className="heading">Total Investment Partners</div>
                  <div className="sub-heading"></div>
                  <div className="amount totalLiveProjects">0</div>
                  <span className="link" onClick={this.gotoPath.bind(this, "Show all Partners", "/discover/partner-projects")}>Show all Partners</span>
                </div>
              </div>
              <div className="big allocated-invested-utilized">
                <div className="content">
                  <h4 className="mb10 heading">Allocated VS Invested VS Utilized</h4>
                  <ResponsiveContainer height={200}>
                    <RadialBarChart width={500} height={150} cx={150} cy={100} innerRadius={20} outerRadius={100} barSize={10} data={this.state.amountComparisonData} startAngle={180} endAngle={0}>
                      <RadialBar minAngle={15} label={{ display: 'none' }} background clockWise={true} dataKey='value' />
                      <Legend />
                    </RadialBarChart>
                  </ResponsiveContainer>
                </div>
              </div>
              <div className="big goal-wise-projects">
                <div className="content">
                  <h4 className="mb10 heading">Goal Wise Projects</h4>
                  {this.state.investmentByGoals.length === 0 || this.state.investmentByGoals[0].value === 0 ? (
                    <h4 className="no-projects">No Projects done yet</h4>
                  ) : (
                    <ResponsiveContainer height={200}>
                      <PieChart width={400} height={400}>
                        <Pie dataKey='value' label={true} data={this.state.investmentByGoals} innerRadius={65} outerRadius={85}>
                          {this.state.investmentByGoals.map(function(cause, index) {
                            return (
                              <Cell fill={cause.backgroundColor} key={cause.name} />
                            )
                          })}
                          <Label value={"Total Projects - " + this.state.csrInvestmentsData.totalInvestmentsCount} position="center" />
                        </Pie>
                        <Tooltip content={<CustomTooltip/>} />
                      </PieChart>
                    </ResponsiveContainer>
                  )}
                </div>
              </div>
              <div className="big goal-wise-projects beneficiaries">
                <div className="content">
                  <h4 className="mb10 heading">Total Beneficiaries</h4>
                  <div className="beneficiary-actual" title={this.state.targetBeneficiaries.actual}></div>
                  {this.state.targetBeneficiaries.potential ? (
                    <div className="beneficiary-potential-container">of potential<span>{' ' + window.inrFormat(this.state.targetBeneficiaries.potential)}</span></div>
                  ) : (null)}
                  {this.state.targetBeneficiaries.potential ? (
                    <div className="percent-container">
                      <div className="percent-bar" style={{ width: 0 }}></div>
                    </div>
                  ) : (null)}
                  {this.state.targetBeneficiaries.potential ? (
                    <div className="percent-count count"><span className="value"></span><span>%</span></div>
                  ) : (null)}
                </div>
              </div>
              {this.state.hasCustomProjectCategories ? (
              <div className="big rectangle">
                <div className="content">
                  <h4 className="mb10 heading">CUSTOM PROJECT CATEGORY</h4>
                  <button className="btn view-all-investments-btn" onClick={this.viewAllInvestments.bind(this)}>View all Investments</button>
                  {this.state.investmentByCustomProjectCategory.length ? (
                    <ResponsiveContainer height="95%">
                      <BarChart width={500} height={300} data={this.state.investmentByCustomProjectCategory}
                        margin={{ top: 15, right: 30, left: 20, bottom: 30 }} barGap={0}>
                        <XAxis dataKey="name" hide={true} />
                        <YAxis tickFormatter={this.customInvestmentTickFormatter} />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Tooltip formatter={this.customInvestmentTooltipFormatter} />
                        <Legend />
                        <Bar dataKey="Allocated" fill="#ef5a20" />
                        <Bar dataKey="Invested" fill="#8884d8" />
                        <Bar dataKey="Utilized" fill="#82ca9d" />
                      </BarChart>
                    </ResponsiveContainer>): (
                    <h4 className="text-center no-investments-yet">No Investments yet</h4>)}
                </div>
              </div>):(null)}
              <div className="big rectangle">
                <div className="content">
                  <h4 className="mb10 heading">Schedule 7 Category Wise Investment</h4>
                  <button className="btn view-all-investments-btn" onClick={this.viewAllInvestments.bind(this)}>View all Investments</button>
                  {this.state.investmentByThematicCategory.length ? (
                    <ResponsiveContainer height="95%">
                      <BarChart width={500} height={300} data={this.state.investmentByThematicCategory}
                        margin={{ top: 15, right: 30, left: 20, bottom: 30 }} barGap={0}>
                        <XAxis dataKey="name" hide={true} />
                        <YAxis tickFormatter={this.customInvestmentTickFormatter} />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Tooltip formatter={this.customInvestmentTooltipFormatter} />
                        <Legend />
                        <Bar dataKey="Allocated" fill="#ef5a20" />
                        <Bar dataKey="Invested" fill="#8884d8" />
                        <Bar dataKey="Utilized" fill="#82ca9d" />
                      </BarChart>
                    </ResponsiveContainer>
                  ) : (
                    <h4 className="text-center no-investments-yet">No Investments yet</h4>
                  )}
                </div>
              </div>
              <div className="big rectangle">
                <div className="content">
                  <h4 className="mb10 heading">Goal Wise Investment</h4>
                  <button className="btn view-all-investments-btn" onClick={this.viewAllInvestments.bind(this)}>View all Investments</button>
                  {this.state.investmentByCauses.length ? (
                    <ResponsiveContainer height="95%">
                      <BarChart width={500} height={300} data={this.state.investmentByCauses}
                        margin={{ top: 15, right: 30, left: 20, bottom: 30 }} barGap={0}>
                        <XAxis dataKey="name" hide={true} />
                        <YAxis tickFormatter={this.customInvestmentTickFormatter} />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Tooltip formatter={this.customInvestmentTooltipFormatter} />
                        <Legend />
                        <Bar dataKey="Allocated" fill="#ef5a20" />
                        <Bar dataKey="Invested" fill="#8884d8" />
                        <Bar dataKey="Utilized" fill="#82ca9d" />
                      </BarChart>
                    </ResponsiveContainer>
                  ) : (
                    <h4 className="text-center no-investments-yet">No Investments yet</h4>
                  )}
                </div>
              </div>
              {this.state.projectInterestStatus !== null ? (
                <div className="project-pipeline-container">
                  <div className="content">
                    <h4 className="mb20 heading text-center">Project Pipeline</h4>
                    <div className="pipeline">
                      <div className="status">
                        <div className="icon-container"><img src="/img/shopping-list.png" /></div>
                        <div className="count"><div className="open">{this.state.projectInterestStatus['all']}</div>Open Projects</div>
                      </div>
                      <div className="status">
                        <div className="icon-container"><img src="/img/thumbs-up.png" /></div>
                        <div className="count"><div className="interested">{this.state.projectInterestStatus['interested']}</div>Interested</div>
                      </div>
                      <div className="status">
                        <div className="icon-container"><img src="/img/file.png" /></div>
                        <div className="count"><div className="under-review">{this.state.projectInterestStatus['under-review']}</div>Under Review</div>
                      </div>
                      <div className="status">
                        <div className="icon-container"><img src="/img/check.png" /></div>
                        <div className="count"><div className="invested">{this.state.projectInterestStatus['invested']}</div>Invested</div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (null)}
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div className="mis-content-container mis-dashboard-csr">
          <div className="fiscal-year-selector fixed">
            <span>
              A snapshot of our community investment in Fiscal Year:
              <div id="dd" className="wrapper-dropdown-3" tabIndex="1" onClick={this.ddClick.bind(this)}>
                <span>{this.getCurrentFiscalYear()}</span>
                <ul className="dropdown">
                  {years.map(function (year, index) {
                    return (
                      <li key={index} onClick={this.selectYear.bind(this)}><a href="#">{year}</a></li>
                    )
                  }.bind(this))}
                </ul>
              </div>
            </span>
          </div>
          {this.state.createCSRPortfolio ? (null) : (
            <Menu selectedKeys={[this.state.activeTab]} mode="horizontal" className="dashboard-tabs">
              <Menu.Item key="overview">
                <span to="/dashboard" offset={-100}>Overview</span>
              </Menu.Item>
              <Menu.Item key="impactFootprint">
                <Link to="/dashboard/impact-footprint" offset={-50}>Impact Footprint</Link>
              </Menu.Item>
            </Menu>
          )}
          {this.state.createCSRPortfolio ? (
            <div className="create-annual-budget-container">
              <div className="create-annual-budget-button" onClick={this.setAnnualBudget.bind(this)}>Create Your Annual Budget for {this.state.fiscalYear}</div>
            </div>
          ) : (
            <div className="row">
              <div className="square-box">
                <div className="content">
                  <div className="heading">Total Allocated Amount</div>
                  <div className="sub-heading"></div>
                  <div className="amount totalAllocated"><span>(INR)</span>0</div>
                  <span className="link" onClick={() => { window.location.pathname = "/fiscal-year-budgeting" }}>Change This</span>
                </div>
              </div>
              <div className="square-box">
                <div className="content">
                  <div className="heading">Total Invested Amount</div>
                  <div className="sub-heading"></div>
                  <div className="amount totalInvested"><span>(INR)</span>0</div>
                  <span className="link" onClick={() => { window.location.pathname = "/investments" }}>Invest More</span>
                </div>
              </div>
              <div className="square-box">
                <div className="content">
                  <div className="heading">Total Utilized Amount</div>
                  <div className="sub-heading"></div>
                  <div className="amount totalUtilized"><span>(INR)</span>0</div>
                  {/*<span className="link" onClick={() => {window.location.pathname = "/portfolio"}}>Show Project Utilization</span>*/}
                </div>
              </div>
              <div className="square-box">
                <div className="content">
                  <div className="heading">Total Live Projects</div>
                  <div className="sub-heading"></div>
                  <div className="amount totalInvestmentsCount">0</div>
                  <span className="link" onClick={this.navigateInvestments}>Show all Investments</span>
                </div>
              </div>
              <div className="square-box">
                <div className="content">
                  <div className="heading">Total Investment Partners</div>
                  <div className="sub-heading"></div>
                  <div className="amount totalLiveProjects">0</div>
                  <span className="link" onClick={() => { window.location.pathname = "/discover/partner-projects" }}>Show all Partners</span>
                </div>
              </div>
              <div className="big allocated-invested-utilized">
                <div className="content">
                  <h4 className="mb10 heading">Allocated VS Invested VS Utilized</h4>
                  <ResponsiveContainer height={200}>
                    <RadialBarChart width={500} height={150} cx={150} cy={100} innerRadius={20} outerRadius={100} barSize={10} data={this.state.amountComparisonData} startAngle={180} endAngle={0}>
                      <RadialBar minAngle={15} label={{ display: 'none' }} background clockWise={true} dataKey='value' />
                      <Legend />
                    </RadialBarChart>
                  </ResponsiveContainer>
                </div>
              </div>
              <div className="big goal-wise-projects">
                <div className="content">
                  <h4 className="mb10 heading">Goal Wise Projects</h4>
                  <ResponsiveContainer height={200}>
                    <PieChart width={400} height={400}>
                      <Pie dataKey='value' data={this.state.investmentByGoals} innerRadius={65} outerRadius={85}>
                        {this.state.investmentByGoals.map(function(cause, index) {
                          return (
                            <Cell fill={cause.backgroundColor} key={cause.name} />
                          )
                        })}
                        <Label value={"Total Projects - 0"} position="center" />
                      </Pie>
                      <Tooltip content={<CustomTooltip/>} />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              </div>
              <div className="big goal-wise-projects">
                <div className="content">
                  <h4 className="mb10 heading">Total Beneficiary</h4>
                  <div className="beneficiary-actual">0</div>
                  {this.state.targetBeneficiaries.potential ? (
                    <div className="beneficiary-potential-container">of potential<span>{' 0'}</span></div>
                  ) : (null)}
                </div>
              </div>
              {this.state.hasCustomProjectCategories ? (
              <div className="big rectangle">
                <div className="content">
                  <h4 className="mb10 heading">CUSTOM PROJECT CATEGORY</h4>
                  <button className="btn view-all-investments-btn" onClick={this.viewAllInvestments.bind(this)}>View all Investments</button>
                  <ResponsiveContainer height="95%">
                    <BarChart width={500} height={300} data={this.state.investmentByCustomProjectCategory}
                      margin={{ top: 15, right: 30, left: 20, bottom: 30 }} barGap={0}>
                      <XAxis dataKey="name" hide={true} />
                      <YAxis tickFormatter={this.customInvestmentTickFormatter} />
                      <CartesianGrid strokeDasharray="3 3" />
                      <Tooltip formatter={this.customInvestmentTooltipFormatter} />
                      <Legend />
                      <Bar dataKey="Allocated" fill="#ef5a20" />
                      <Bar dataKey="Invested" fill="#8884d8" />
                      <Bar dataKey="Utilized" fill="#82ca9d" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>):(null)}
              <div className="big rectangle">
                <div className="content">
                  <h4 className="mb10 heading">Schedule 7 Category Wise Investment</h4>
                  <button className="btn view-all-investments-btn" onClick={this.viewAllInvestments.bind(this)}>View all Investments</button>
                  <ResponsiveContainer height="95%">
                    <BarChart width={500} height={300} data={this.state.investmentByThematicCategory}
                      margin={{ top: 15, right: 30, left: 20, bottom: 30 }} barGap={0}>
                      <XAxis dataKey="name" hide={true} />
                      <YAxis tickFormatter={this.customInvestmentTickFormatter} />
                      <CartesianGrid strokeDasharray="3 3" />
                      <Tooltip formatter={this.customInvestmentTooltipFormatter} />
                      <Legend />
                      <Bar dataKey="Allocated" fill="#ef5a20" />
                      <Bar dataKey="Invested" fill="#8884d8" />
                      <Bar dataKey="Utilized" fill="#82ca9d" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
              <div className="big rectangle">
                <div className="content">
                  <h4 className="mb10 heading">Goal Wise Investment</h4>
                  <button className="btn view-all-investments-btn" onClick={this.viewAllInvestments.bind(this)}>View all Investments</button>
                  <ResponsiveContainer height="95%">
                    <BarChart width={500} height={300} data={this.state.investmentByCauses}
                      margin={{ top: 15, right: 30, left: 20, bottom: 30 }} barGap={0}>
                      <XAxis dataKey="name" hide={true} />
                      <YAxis tickFormatter={this.customInvestmentTickFormatter} />
                      <CartesianGrid strokeDasharray="3 3" />
                      <Tooltip formatter={this.customInvestmentTooltipFormatter} />
                      <Legend />
                      <Bar dataKey="Allocated" fill="#ef5a20" />
                      <Bar dataKey="Invested" fill="#8884d8" />
                      <Bar dataKey="Utilized" fill="#82ca9d" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
              <div className="project-pipeline-container">
                <div className="content">
                  <h4 className="mb20 heading text-center">Project Pipeline</h4>
                  <div className="pipeline">
                    <div className="status">
                      <div className="icon-container"></div>
                      <div className="count">{'0 Open Projects'}</div>
                    </div>
                    <div className="status">
                      <div className="icon-container"></div>
                      <div className="count">{'0 Interested'}</div>
                    </div>
                    <div className="status">
                      <div className="icon-container"></div>
                      <div className="count">{'0 Under Review'}</div>
                    </div>
                    <div className="status">
                      <div className="icon-container"></div>
                      <div className="count">{'0 Invested'}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )
    }
  }
}