import React, {Component} from 'react';

import SingleInterest from './SingleInterest';

export default class CorporateDashboard extends Component {
	render () {
		return (
			<div>
				{this.props.interests !== null ? (
					this.props.interests.map(function(interest, i) {
						return <SingleInterest key={i} interest={interest}/>
					})
				) : (
					<div>

					</div>
				)}
			</div>
		);
	}
}