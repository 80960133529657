import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

export default class LandingBanner extends Component {
	render() {
		return (
			<div className="landing-banner-container">
				<div className="landing-banner-top">
					<div className="landing-banner-title">
						Corporate social responsibility, redefined.
					</div>
					<div className="landing-banner-subtitle">
						Sign-up for high impact, well-structured and curated opportunities with the best NGOs
					</div>
					<Row className="landing-banner-stats-container">
						<Col md={3} sm={12} className="landing-banner-count">
							<span className="counter">
								{this.props.companiesCount}
							</span>
							<div className="counter-text">
								responsible companies
							</div>
						</Col>
						<Col md={3} sm={12} className="landing-banner-count">
							<span className="counter">
								{this.props.ngosCount}
							</span>
							<div className="counter-text">
								credible NGO partners
							</div>
						</Col>
						<Col md={3} sm={12} className="landing-banner-count">
							<span className="counter">
								{this.props.projectsCount}
							</span>
							<div className="counter-text">
								high-impact solution-centric projects
							</div>
						</Col>
						<Col md={3} sm={12} className="landing-banner-count">
							<span className="counter">
								{this.props.volunteerOpportunitiesCount}
							</span>
							<div className="counter-text">
								opportunities to make a difference
							</div>
						</Col>
					</Row>
				</div>
				<div className="landing-banner-bottom">
					<div className="landing-banner-link">
						<Link to="/csr/explore-projects">Explore Projects to Invest in</Link>
					</div>
					<div className="landing-banner-link">
						<Link to="/csr/explore-volunteer-opportunities">Explore Volunteering Opportunities</Link>
					</div>
				</div>
			</div>
		);
	}
}