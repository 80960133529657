import React, { Component } from "react";
import { Radio, Checkbox } from "antd";
import { readCookie } from "../../../../../cookie";
import { ToastContainer, toast } from "react-toastify";
import ReactGA from 'react-ga';

export default class PartnerPreferences extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allNgoSpecifications: [],
      noSpecifications: false,
      ngoSpecification: {
        section: [],
        size: "",
        charityOnboardingProcess: "",
        partnerPreference: "",
        older: 0,
        csrHistory: ""
      }
    };
  }

  componentDidMount() {
    fetch(process.env.REACT_APP_API_URL + "/getOtAttributes", {
      method: "get",
      headers: {
        Auth: JSON.parse(readCookie("access_token")).access_token
      }
    }).then(data => data.json())
      .then(data => {
        let allNgoSpecifications = [];
        for (let i = 0; i < data.otAttributes.length; i++) {
          if (data.otAttributes[i].type === "ngoSection") {
            data.otAttributes[i].selected = false;
            allNgoSpecifications.push(data.otAttributes[i]);
          }
        }
        this.setState({ allNgoSpecifications });
      });
    let companyId = JSON.parse(readCookie("userData")).organisationData.id;
    fetch(process.env.REACT_APP_API_URL + "/company/" + companyId + "/mis/preferences", {
      method: "GET",
      headers: {
        Auth: JSON.parse(readCookie("access_token")).access_token
      }
    }).then(data => data.json())
      .then(data => {
        let dataObj = data.data;
        if (dataObj.ngoSpecification === undefined) {
          dataObj.ngoSpecification = {}
        }
        let ngoSpecification = this.state.ngoSpecification;
        let section = [];

        if (dataObj.ngoSpecifications.hasOwnProperty("section")) {
          if (dataObj.ngoSpecifications.section === -1) {
            section = -1;
            this.setState({ noSpecifications: true })
          } else {
            for (var i = 0; i < dataObj.ngoSpecifications.section.length; i++) {
              section.push(dataObj.ngoSpecifications.section[i].id);
            }
          }
        }
        ngoSpecification["section"] = section;

        if (dataObj.ngoSpecifications.charityOnboardingProcess !== "") {
          ngoSpecification["charityOnboardingProcess"] = dataObj.ngoSpecifications.charityOnboardingProcess;
        }

        if (dataObj.ngoSpecifications.partnerPreference !== "") {
          ngoSpecification["partnerPreference"] = dataObj.ngoSpecifications.partnerPreference;
        }

        if (dataObj.ngoSpecifications.csrHistory && dataObj.ngoSpecifications.csrHistory !== "") {
          ngoSpecification["csrHistory"] = dataObj.ngoSpecifications.csrHistory;
        }
        if (dataObj.ngoSpecifications.older && dataObj.ngoSpecifications.older !== "") {
          ngoSpecification["older"] = dataObj.ngoSpecifications.older;
        }
        if (dataObj.ngoSpecifications.size !== "") {
          ngoSpecification["size"] = dataObj.ngoSpecifications.size;
        }

        this.setState({ ngoSpecification });
      });
  }

  updateNgos = (e) => {
    let ngoSpecification = this.state.ngoSpecification;
    let value = e.target.value;
    if (e.target.value !== -1 && ngoSpecification.section === -1) ngoSpecification.section = [];
    if (value === "older" && ngoSpecification.older === 3) {
      delete ngoSpecification.older
    }
    else if (value === "older") {
      ngoSpecification.older = 3;
    }
    if (value === "history" && ngoSpecification.csrHistory === true) {
      ngoSpecification.csrHistory = false
    }
    else if (value === "history") {
      ngoSpecification.csrHistory = true;
    }
    if (ngoSpecification.section.indexOf(value) === -1) {
      ngoSpecification.section.push(value);
    } else {
      ngoSpecification.section.splice(
        ngoSpecification["section"].indexOf(value),
        1
      );
    }
    this.setState({ ngoSpecification });
  };

  updateOrganisationSize = e => {
    let ngoSpecification = this.state.ngoSpecification;
    ngoSpecification.size = e.target.value;
    this.setState({ ngoSpecification });
  };

  updateProcessOfCharity = e => {
    let ngoSpecification = this.state.ngoSpecification;
    ngoSpecification.charityOnboardingProcess = e.target.value;
    this.setState({ ngoSpecification });
  };

  updatePartnerPreference = e => {
    let ngoSpecification = this.state.ngoSpecification;
    ngoSpecification.partnerPreference = e.target.value;
    this.setState({ ngoSpecification });
  };

  noSpecification = (name, e) => {
    let allNgoSpecifications = this.state.allNgoSpecifications;
    let ngoSpecification = this.state.ngoSpecification;
    for (var i = 0; i < allNgoSpecifications.length; i++) {
      allNgoSpecifications[i].selected = false;
    }
    if (this.state.ngoSpecification.section === -1) {
      ngoSpecification.section = [];
      ngoSpecification.older = 0;
      ngoSpecification.csrHistory = false;
      this.setState({
        ngoSpecification
      });
    } else {
      ngoSpecification.section = -1;
      this.setState({
        ngoSpecification
      });
    }
    this.setState({
      allNgoSpecifications,
      noSpecifications: !this.state.noSpecifications
    });
  };

  saveData = () => {
    if ((this.state.ngoSpecification["section"].length || this.state.ngoSpecification["section"] === -1) && this.state.ngoSpecification["partnerPreference"] !== "" && this.state.ngoSpecification["charityOnboardingProcess"] !== "" && this.state.ngoSpecification["size"] !== "") {
      let data = {};
      if (this.state.ngoSpecification["section"].length) {
        data.ngoSpecifications = this.state.ngoSpecification;
      } else if (this.state.ngoSpecification["section"] === -1) {
        let ngoDetail = {};
        ngoDetail['section'] = -1;
        ngoDetail['size'] = this.state.ngoSpecification.size;
        ngoDetail['charityOnboardingProcess'] = this.state.ngoSpecification.charityOnboardingProcess;
        ngoDetail['partnerPreference'] = this.state.ngoSpecification.partnerPreference;
        if (this.state.ngoSpecification.order) { ngoDetail['older'] = this.state.ngoSpecification.order }
        ngoDetail['history'] = this.state.ngoSpecification.history;
        data.ngoSpecifications = ngoDetail;
      }
      let companyId = JSON.parse(readCookie("userData")).organisationData.id;
      fetch(process.env.REACT_APP_API_URL + "/company/" + companyId + "/mis/preferences", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Auth: JSON.parse(readCookie("access_token")).access_token
        },
        body: JSON.stringify(data)
      }).then(function (response) {
        return response.json();
      }).then(function (data) {
        this.notify("Your Preferences has been saved!");
        ReactGA.event({
          category: 'Csr Strategy',
          action: 'edit',
          label: 'Saved',
          value: 4
        });
      }.bind(this));
    } else {
      this.notifyError("Mandatory values are missing!");
    }
  }

  notifyError = text => toast.error(text);

  notify = text =>
    toast.success(text, {
      onClose: this.next(this)
    });

  next = () => {
    var that = this;
    ReactGA.event({
      category: 'Csr Strategy',
      action: 'edit',
      label: 'Next',
      value: 4
    });
    setTimeout(function () {
      that.props.next();
    }, 1000);
  }

  previous = () => {
    ReactGA.event({
      category: 'Csr Strategy',
      action: 'edit',
      label: 'Prev',
      value: 4
    });
    this.props.previous();
  }

  render() {
    if (this.state.allNgoSpecifications !== null) {
      return (
        <div className="mis-content-container-s4">
          <div className="title-section">
            <h1>Define project partner preference</h1>
          </div>
          <div className="body-section-s4">
            <ToastContainer position="bottom-center" type="success" autoClose={4000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={false} />
            <div className="body-s4-section1">
              <div className="s4-heading-container">
                <span className="s4-dot" />
                <div className="sub-s4-heading-1">
                  <h4>Select preferred NGO specifications <span className="astris">*</span></h4>
                </div>
              </div>
              <div className="s4-opt-container">
                <div className="s4-opt-inner">
                  <Checkbox onChange={this.noSpecification} className="s1-goal-prefereces" type="checkbox" value={-1}
                    checked={this.state.ngoSpecification.section === -1 ? true : false} disabled={JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'} />
                  <h3 className="s4-opt-list" style={{ marginLeft: '10px' }}>No Preference</h3>
                </div>
                <div className="s4-opt-inner">
                  <Checkbox value="older" onChange={this.updateNgos.bind(this)} className="s1-goal-prefereces" type="checkbox"
                    disabled={this.state.noSpecifications || JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'} checked={this.state.ngoSpecification.section !== -1 && this.state.ngoSpecification.older >= 3 ? true : false} />
                  <h3 className="s4-opt-list" style={{ marginLeft: '10px' }}>Must be >3 years old</h3>
                </div>
                <div className="s4-opt-inner">
                  <Checkbox value="history" onChange={this.updateNgos.bind(this)} className="s1-goal-prefereces" type="checkbox"
                    disabled={this.state.noSpecifications || JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'} checked={this.state.ngoSpecification.section !== -1 && this.state.ngoSpecification.csrHistory === true ? true : false} />
                  <h3 className="s4-opt-list" style={{ marginLeft: '10px' }}>Must have a history of CSR investments</h3>
                </div>
                {this.state.allNgoSpecifications.map((ngoSpecification, index) => {
                  return (
                    this.state.ngoSpecification.section === -1 ? (
                      <div className="s4-opt-inner" key={index}>
                        <Checkbox value={ngoSpecification._id} onChange={this.updateNgos.bind(this)}
                          disabled={this.state.noSpecifications || JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'} checked={false} className="s4-checkbox" />
                        <h3 className="s4-opt-list">{ngoSpecification.name}</h3>
                      </div>
                    ) : (
                      <div className="s4-opt-inner" key={index}>
                        <Checkbox checked={this.state.ngoSpecification["section"].indexOf(ngoSpecification._id) > -1 ? true : false}
                          className="s4-checkbox" onChange={this.updateNgos.bind(this)} value={ngoSpecification._id} disabled={JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'} />
                        <h3 className="s4-opt-list">{ngoSpecification.name}</h3>
                      </div>
                    )
                  )
                })}
              </div>
            </div>
            <div className="body-s4-section2">
              <div className="s4-heading-container">
                <span className="s4-dot" />
                <div className="sub-s4-heading-2">
                  <h4>Select preferred size of partner organisations <span className="astris">*</span></h4>
                </div>
              </div>
              <div className="s4-options">
                <Radio onChange={this.updateOrganisationSize.bind(this)} disabled={JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'}
                  checked={this.state.ngoSpecification["size"] === "large" ? true : false} value="large" name="ngoSize" />
                <h3 className="s4-opt-list">Large sized NGOs</h3>
              </div>
              <div className="s4-options">
                <Radio onChange={this.updateOrganisationSize.bind(this)} disabled={JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'}
                  checked={this.state.ngoSpecification["size"] === "small" ? true : false} value="small" name="ngoSize" />
                <h3 className="s4-opt-list">Small to mid-sized NGOs</h3>
              </div>
              <div className="s4-options">
                <Radio name="ngoSize" onChange={this.updateOrganisationSize.bind(this)} disabled={JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'} value="noPreferences" checked={this.state.ngoSpecification["size"] === "noPreferences" ? true : false} />
                <h3 className="s4-opt-list">No Preference</h3>
              </div>
            </div>
            <div className="body-s4-section3">
              <div className="s4-heading-container">
                <span className="s4-dot" />
                <div className="sub-s4-heading-3">
                  <h4>Specify the process of on-boarding NGOs<span className="astris">*</span></h4>
                </div>
              </div>
              <div className="s4-options">
                <Radio onChange={this.updateProcessOfCharity.bind(this)} disabled={JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'}
                  checked={this.state.ngoSpecification["charityOnboardingProcess"] === "supporting-charities" ? true : false} value="supporting-charities" name="process-of-charity" />
                <h3 className="s4-opt-list"> Open to supporting charities which fulfil the CSR mandate </h3>
              </div>
              <div className="s4-options">
                <Radio onChange={this.updateProcessOfCharity.bind(this)} disabled={JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'}
                  checked={this.state.ngoSpecification["charityOnboardingProcess"] === "performed-in-house" ? true : false} value="performed-in-house" name="process-of-charity" />
                <h3 className="s4-opt-list"> We have an elaborate on-boarding process, which is performed in-house </h3>
              </div>
              <div className="s4-options">
                <Radio onChange={this.updateProcessOfCharity.bind(this)} disabled={JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'}
                  checked={this.state.ngoSpecification["charityOnboardingProcess"] === "third-party" ? true : false} value="third-party" name="process-of-charity" />
                <h3 className="s4-opt-list"> We leverage third party accreditation agencies to conduct due-diligence. </h3>
              </div>
              <div className="s4-options">
                <Radio name="process-of-charity" onChange={this.updateProcessOfCharity.bind(this)} disabled={JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'}
                  value="noPreferences" checked={this.state.ngoSpecification["charityOnboardingProcess"] === "noPreferences" ? true : false} />
                <h3 className="s4-opt-list">No Preference</h3>
              </div>
            </div>
            <div className="body-s4-section4">
              <div className="s4-heading-container">
                <span className="s4-dot" />
                <div className="sub-s4-heading-4">
                  <h4>Select preferred empanelment mechanism for partners <span className="astris">*</span></h4>
                </div>
              </div>
              <div className="s4-options">
                <Radio onChange={this.updatePartnerPreference.bind(this)} disabled={JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'}
                  checked={this.state.ngoSpecification["partnerPreference"] === "LEDueDiligence" ? true : false} value="LEDueDiligence" name="partner-preference" />
                <h3 className="s4-opt-list"> Only those NGOs which are empanelled through our due-diligence process </h3>
              </div>
              <div className="s4-options">
                <Radio onChange={this.updatePartnerPreference.bind(this)} disabled={JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'}
                  checked={this.state.ngoSpecification["partnerPreference"] === "Credible" ? true : false} value="Credible" name="partner-preference" />
                <h3 className="s4-opt-list"> Open to supporting credible NGOs with necessary accreditation and due-diligence </h3>
              </div>
              <div className="s4-options">
                <Radio name="partner-preference" onChange={this.updatePartnerPreference.bind(this)} disabled={JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'}
                  value="noPreferences" checked={this.state.ngoSpecification["partnerPreference"] === "noPreferences" ? true : false} />
                <h3 className="s4-opt-list">No Preference</h3>
              </div>
            </div>
            <div className="buttons-section">
              <div className="prev-btn-container">
                <button className="btn-prev" onClick={this.props.previous}>
                  Previous
                </button>
                <button className="btn-nxt" onClick={this.props.next}>
                  {JSON.parse(readCookie('userData')).authorizedFor.mis !== 'view' ? ('Continue without saving') : ('Next')}
                </button>
              </div>
              {JSON.parse(readCookie('userData')).authorizedFor.mis !== 'view' ? (
                <div className="nxt-btn-container">
                  <button className="btn-save-next" onClick={this.saveData.bind(this)}>
                    Save & Next
                  </button>
                </div>
              ) : (null)}
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}
