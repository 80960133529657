import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import Icon from 'react-icons-kit';

import BudgetEditorModal from './BudgetEditorModal';

import { ic_add_box } from 'react-icons-kit/md/ic_add_box';
import { ic_delete } from 'react-icons-kit/md/ic_delete';

const readCookie = require('../../../../cookie.js').readCookie;

export default class AddBudget extends Component {
	constructor(props) {
		super(props);
		this.state = {
		}
	}

	openBudgetEditorModal(selectedColumn, selectedColumnName, selectedColumnValue, selectedRowIndex) {
		let budgetEditorModal = {}
		budgetEditorModal['selectedColumn'] = selectedColumn;
		budgetEditorModal['selectedColumnValue'] = selectedColumnValue;
		budgetEditorModal['selectedColumnName'] = selectedColumnName;
		budgetEditorModal['selectedRowIndex'] = selectedRowIndex;
		this.setState({ budgetEditorModal, budgetEditorModalVisibility: true });
	}

	hideBudgetEditorModal() {
		let budgetEditorModal = {
			selectedColumn: '',
			selectedColumnValue: '',
			selectedColumnName: '',
			selectedRowIndex: -1
		}
		this.setState({ budgetEditorModal, budgetEditorModalVisibility: false });
	}

	render() {
		return (
			<div style={{ padding: '0 10px' }}>
				<div className="amount-breakup-table">
					<div className="amount-breakup-table-headers">
						<div className="header-item expense-head">Expense Head</div>
						<div className="header-item expense-item">Expense Item</div>
						{/* <div className="header-item in-units">In Units?</div>
						<div className="header-item unit-cost">Unit Cost</div>
						<div className="header-item total-units">Total Units</div>
						<div className="header-item pretax-amount">Pretax Amount</div>
						<div className="header-item tax-rate">Tax Rate</div> */}
						<div className="header-item proposed-amount">Proposed Amount</div>
						<div className="header-item comments">Comments</div>
					</div>

					<div className="amount-breakup-table-rows">
						{this.props.amountBreakup.map(function (row, index) {
							return (
								<div className="amount-breakup-table-row" key={index}>
									{/* <div className="row-item expense-head" onClick={this.openBudgetEditorModal.bind(this, 'costHead', 'Expense Head', row.costHead.name, index)}> */}
									<div className="row-item expense-head">
										<span title={row.costHead.name}>{row.costHead.name}</span>
									</div>

									{/* <div className="row-item expense-item" onClick={this.openBudgetEditorModal.bind(this, 'expenseItem', 'Expense Item', row.expenseItem, index)}> */}
									<div className="row-item expense-item">
										<span title={row.expenseItem}>{row.expenseItem}</span>
									</div>

									{/* <div className="row-item in-units">
										<div>
											<label className="label-container">{row.unitEconomics === true ? 'Yes' : 'No'}
												<input type="checkbox" name="unit-economics" checked={row.unitEconomics === true ? true : false} disabled />
												<span className="checkmark"></span>
											</label>
										</div>
									</div> */}

									{/* <div className="row-item unit-cost">
										<input className="form-control" disabled type="number" value={row.unitCost} />
									</div> */}

									{/* <div className="row-item total-units">
										<input className="form-control" disabled type="number" value={row.totalUnits} />
									</div> */}

									{/* <div className="row-item pretax-amount">
										<input className="form-control" disabled type="number" value={row.preTaxAmount} />
									</div> */}

									{/* <div className="row-item tax-rate">
										<input className="form-control" disabled type="number" value={row.taxRate} />
									</div> */}

									<div className="row-item proposed-amount">
										<span>{Math.round(row.postTaxAmount * 100) / 100}</span>
									</div>

									{/* <div className="row-item comments" onClick={this.openBudgetEditorModal.bind(this, 'comments', 'Comments', row.comments, index)}> */}
									<div className="row-item comments">
										<div title={row.comments}>{row.comments}</div>
									</div>
								</div>
							)
						}.bind(this))}
					</div>
					<div className="total-budget-container mt10">
						<b>Total Budget:</b>{' INR ' + window.inrFormat(this.props.proposedBudget)}
					</div>
				</div>
				{this.state.budgetEditorModalVisibility ? (
					<BudgetEditorModal selectedColumn={this.state.budgetEditorModal.selectedColumn} hideBudgetEditorModal={this.hideBudgetEditorModal.bind(this)} budgetEditorModalVisibility={this.state.budgetEditorModalVisibility} selectedColumnValue={this.state.budgetEditorModal.selectedColumnValue} selectedColumnName={this.state.budgetEditorModal.selectedColumnName} selectedRowIndex={this.state.budgetEditorModal.selectedRowIndex} />
				) : (null)}
			</div>
		);
	}
}
