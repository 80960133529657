import React,{ Component } from 'react';
import {Col, Row} from 'react-bootstrap';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import $ from 'jquery';
import Icon from 'react-icons-kit';
import { ic_add_location } from 'react-icons-kit/md/ic_add_location';
import { ic_delete } from 'react-icons-kit/md/ic_delete';

export default class InvestmentInformation extends Component {
	constructor(props) {
    super(props);
    this.state = {
    };
  }
  
  onPreferenceLocationChange(index, preferenceLocation) {
  	this.props.changeLocation(index, preferenceLocation);

  	geocodeByAddress(preferenceLocation)
    .then(results => getLatLng(results[0]))
    .then(function(latLng) {
    	this.props.changeLatLng(index, latLng);
    }.bind(this)).catch(error => console.error('Error', error));
  }

  render() {

    const options = {
      types: ['(regions)'],
    };

    if(this.props.preferedGeographiesSetting !== null) {
		  return (
		    <div>
		    	<Row className="investment-information">
		        <Col md={12}>
		          <Row className="mb20 mt20">
		            <Col className="form-group setting-of-investment" md={12}>
		            	<Col md={12}><label>Preferred setting of investment (Select all that apply):</label></Col>
		            	{this.props.allGeographiesSetting.map(function(setting, index) {
            				var index = this.props.preferedGeographiesSetting.indexOf(setting._id);
		            		if(index > -1) {
		            			return <Col className="mb10 mt10" md={3}>
	            						<span><input type="checkbox" value={setting._id} checked onChange={this.props.changePreferedGeographiesSetting.bind(this, false, setting._id)} /> {setting.name}</span>
	            					</Col>
		            		} else {
		            			return <Col className="mb10 mt10" md={3}>
	            						<span><input type="checkbox" value={setting._id} onChange={this.props.changePreferedGeographiesSetting.bind(this, true, setting._id)} /> {setting.name}</span>
	            					</Col>
		            		}
		            	}.bind(this))}
		              <Col className="mb10 mt10" md={3}>
		              	<span><input type="checkbox" value="" onChange={this.props.changePreferedGeographiesSetting.bind(this, true, '')} /> No Preference</span>
		              </Col>
		            </Col>
		          </Row>

		          <Row className="mb20 mt20">
				        <Col md={12}><label>Preferred geographies of investment:</label></Col>
		          	
		          	{this.props.preferedGeographiesLocation.map(function(location, index) {
		          		let inputProps = {
							      value: location.preferenceLocation,
							      onChange: this.onPreferenceLocationChange.bind(this, index),
							    };

							    let cssClasses = {
							      input: 'location form-control ' + location.preferenceLocationError,
							      autocompleteContainer: 'my-autocomplete-container'
							    };

							    return (
							    	<Col key={index} className="form-group geographies-of-investment" md={12}>
				              <Col md={6} className="form-group">
				              	<label className="control-label">Location:</label>
				              	<PlacesAutocomplete inputProps={inputProps} classNames={cssClasses} options={options} />
				              </Col>
				              <Col md={5} className="form-group">
				              	<label className="control-label">Range (in kms around location):</label>
									      <input type="number" className="range" value={location.range} onChange={this.props.changeRange.bind(this, index)} />
				              </Col>
				              {this.props.preferedGeographiesLocation.length > 1 ? (
					              <Col md={1}>
					              	<span className="remove-geography-of-investment pull-right" onClick={this.props.removeGeographiesLocation.bind(this, index)}><Icon icon={ic_delete} size={22} /></span>
					              </Col>
				              ):(null)}
				            </Col>
				          )
		          	}.bind(this))}
		          </Row>

		          <Row className="mb20 mt20">
		          	<span className="add-location" onClick={this.props.addPreferedGeographiesLocation}><Icon icon={ic_add_location} /> Add another location</span>
		          </Row>
		        </Col>
		      </Row>
		    </div>
		  );
		} else return null;
	}
}