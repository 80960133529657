import React, { Component } from "react";
import { Radio, Checkbox } from "antd";
import { ToastContainer, toast } from "react-toastify";
import ReactGA from 'react-ga';

const readCookie = require("../../../../../cookie.js").readCookie;

const performanceMetrics = ["Reputation of the firm", "Market share", "Brand perception", "Sales", "Operating expenses", "Employee satisfaction", "Value-chain relationship", "Recruitment", "Positive PR", "Customer Goodwill", "Improved social conditions", "Improved environmental footprint"];

class AboutYourOrganisation extends Component {
  state = {
    companyStrength: "",
    csrTeamStrength: 0,
    csrWingIndirectTeamStrength: 0,
    metrics: false,
    performanceMetrics: [],
    environmentLitigation: null,
    indirectMembersContribution: null,
    csrWingIndirectTeamStrengthError: false
  };

  componentDidMount = () => {
    let companyId = JSON.parse(readCookie("userData")).organisationData.id;
    fetch(process.env.REACT_APP_API_URL + "/company/" + companyId + "/mis/preferences", {
      method: "GET",
      headers: {
        Auth: JSON.parse(readCookie("access_token")).access_token
      }
    }).then(data => data.json())
      .then(data => {
        let companyStrength = data.data.companyStrength || null;
        let csrTeamStrength = data.data.csrTeamStrength || 0;
        let csrWingIndirectTeamStrength = data.data.csrWingIndirectTeamStrength || 0;
        let performanceMetrics = data.data.performanceMetrics || [];
        let environmentLitigation = data.data.environmentLitigation || null;
        let indirectMembersContribution;
        if (data.data.csrWingIndirectTeamStrength && data.data.csrWingIndirectTeamStrength > 0) {
          indirectMembersContribution = "Yes" || "";
        } else {
          indirectMembersContribution = "No" || "";
        }
        this.setState({
          companyStrength: companyStrength,
          csrTeamStrength: csrTeamStrength,
          csrWingIndirectTeamStrength: csrWingIndirectTeamStrength,
          performanceMetrics: performanceMetrics,
          environmentLitigation: environmentLitigation,
          indirectMembersContribution: indirectMembersContribution
        });
      });
  };

  companyStrength = e => {
    this.setState({
      companyStrength: e.target.value
    });
  };

  csrTeamStrength = e => {
    // if (e.target.value === "") {
    //   this.setState({
    //     csrTeamStrength: e.target.value
    //   });
    // } else {
    this.setState({
      csrTeamStrength: e.target.value
    });
    // }
  };

  csrWingIndirectTeamStrength = e => {
    if(parseInt(e.target.value) < 1 || e.target.value === '') {
      this.setState({ csrWingIndirectTeamStrengthError: true });
    } else this.setState({ csrWingIndirectTeamStrengthError: false });
    this.setState({
      csrWingIndirectTeamStrength: e.target.value
    });
  };

  environmentLitigation = e => {
    this.setState({
      environmentLitigation: e.target.value
    });
  };

  indirectMembersContribution = e => {
    let csrWingIndirectTeamStrength = this.state.csrWingIndirectTeamStrength;
    if (e.target.value === "No") {
      csrWingIndirectTeamStrength = 0;
      this.setState({ csrWingIndirectTeamStrengthError: false });
    } else if(e.target.value === "Yes" && this.state.csrWingIndirectTeamStrength < 1) {
      csrWingIndirectTeamStrength = 1;
    }
    this.setState({ indirectMembersContribution: e.target.value, csrWingIndirectTeamStrength });
  }

  performanceMetrics = e => {
    let performanceMetrics = this.state.performanceMetrics;
    if (e.target.value !== -1 && performanceMetrics === -1) performanceMetrics = [];
    if (performanceMetrics.indexOf(e.target.value) === -1) {
      performanceMetrics.push(e.target.value);
    } else {
      performanceMetrics.splice(performanceMetrics.indexOf(e.target.value), 1);
    }
    this.setState({
      performanceMetrics
    });
  };

  noMetrics = e => {
    let performanceMetrics = this.state.performanceMetrics;

    if (this.state.performanceMetrics === -1) {
      this.setState({ performanceMetrics: [] });
    } else {
      this.setState({ performanceMetrics: -1 });
    }
    this.setState({ metrics: !this.state.metrics });
  }

  saveData = () => {
    if (this.state.companyStrength !== "" && (this.state.csrTeamStrength !== "" || isNaN(this.state.csrTeamStrength)) && !this.state.csrWingIndirectTeamStrengthError &&
      (this.state.csrWingIndirectTeamStrength !== "" || isNaN(this.state.csrWingIndirectTeamStrength)) &&
      (this.state.performanceMetrics.length || this.state.performanceMetrics === -1) && this.state.environmentLitigation !== null && this.state.indirectMembersContribution !== "" && ((this.state.indirectMembersContribution === "No") || (this.state.indirectMembersContribution === "Yes" && this.state.csrWingIndirectTeamStrength > 0))) {
      let data = {};
      data.companyStrength = this.state.companyStrength;
      if (this.state.csrTeamStrength) {
        data.csrTeamStrength = parseInt(this.state.csrTeamStrength);
      } else {
        data.csrTeamStrength = 0;
      }
      if (this.state.csrWingIndirectTeamStrength) {
        data.csrWingIndirectTeamStrength = parseInt(
          this.state.csrWingIndirectTeamStrength
        );
      } else {
        data.csrWingIndirectTeamStrength = 0;
      }
      if (this.state.performanceMetrics.length) {
        data.performanceMetrics = this.state.performanceMetrics;
      } else if (this.state.performanceMetrics === -1) {
        data.performanceMetrics = -1
      }
      data.environmentLitigation = this.state.environmentLitigation;
      data.indirectMembersContribution = this.state.indirectMembersContribution;
      let companyId = JSON.parse(readCookie("userData")).organisationData.id;
      fetch(process.env.REACT_APP_API_URL + "/company/" + companyId + "/mis/preferences", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Auth: JSON.parse(readCookie("access_token")).access_token
        },
        body: JSON.stringify(data)
      }).then(function (response) {
        return response.json();
      }).then(function (data) {
        this.notify("Your Preferences has been saved!");
        ReactGA.event({
          category: 'Csr Strategy',
          action: 'edit',
          label: 'Saved',
          value: 5
        });
      }.bind(this));
    } else {
      this.notifyError("Mandatory values are missing!");
    }
  }

  notifyError = text => toast.error(text);

  notify = text =>
    toast.success(text, {
      onClose: this.next(this)
    });

  next = () => {
    var that = this;
    ReactGA.event({
      category: 'Csr Strategy',
      action: 'edit',
      label: 'Next',
      value: 5
    });
    setTimeout(function () {
      that.props.next();
    }, 1000);
  };

  previous = () => {
    ReactGA.event({
      category: 'Csr Strategy',
      action: 'edit',
      label: 'Prev',
      value: 5
    });
    this.props.previous();
  };

  render() {
    return (
      <div className="mis-content-container-s5">
        <div className="s5-header-container">
          <h1 className="s5-header">Describe your organizational strength and structure</h1>
        </div>
        <div className="s5-info-container">
          <ToastContainer position="bottom-center" type="success" autoClose={4000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={false} />
          <div className="s5-info-1">
            <div className="s5-info-1-header">
              <div className="s5-info-1-point" />
              <h4 className="s5-info-1-heading">{"The overall strength of the company is "}<span className="astris">*</span></h4>
            </div>
            <div className="s5-info-1-container">
              <div className="projectSize-container">
                <select onChange={this.companyStrength} value={this.state.companyStrength} className="numberInput" disabled={JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'}>
                  <option disabled value="">Select company strength</option>
                  <option value="0 - 1">0 - 1</option>
                  <option value="2 - 10">2 - 10</option>
                  <option value="11 - 50">11 - 50</option>
                  <option value="51 - 200">51 - 200</option>
                  <option value="201 - 500">201 - 500</option>
                  <option value="501 - 1000">501 - 1000</option>
                  <option value="1001 - 5000">1001 - 5000</option>
                  <option value="5001 - 10,000">5001 - 10,000</option>
                  <option value="10,001+">10,001+</option>
                </select>
              </div>
            </div>
          </div>
          <div className="s5-info-2">
            <div className="s5-info-2-header">
              <div className="s5-info-2-point" />
              <h4 className="s5-info-2-heading">Strength of the CSR team/committee <span className="astris">*</span></h4>
            </div>
            <div className="s5-info-2-container">
              <div className="projectSize-container">
                <input onChange={this.csrTeamStrength} className="numberInput" type="number" name="quantity" min="0" max="10000000000000" value={this.state.csrTeamStrength} placeholder="0" disabled={JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'} />
              </div>
            </div>
          </div>
          <div className="s5-info-3">
            <div className="s5-info-3-header">
              <div className="s5-info-3-point" />
              <h4 className="s5-info-3-heading">
                Do other members contribute indirectly outside the CSR team? If yes, how many{" "}
                <span className="astris">*</span>
              </h4>
            </div>
            <div className="s5-info-3-container">
              <div style={{ paddingLeft: "15px" }}>
                <div style={{ display: "flex", alignItems: "center", margin: "10px 0" }}>
                  <Radio value="Yes" name="indirectMembersContribution" onChange={this.indirectMembersContribution} checked={this.state.indirectMembersContribution === "Yes" ? true : false} disabled={JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'}></Radio>
                  <h3 style={{ margin: "0", fontSize: "18px", color: "#515356", fontWeight: "normal" }} className="ticketSize-option1">Yes</h3>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Radio value="No" name="indirectMembersContribution" onChange={this.indirectMembersContribution} checked={this.state.indirectMembersContribution === "No" ? true : false} disabled={JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'}></Radio>
                  <h3 style={{ margin: "0", fontSize: "18px", color: "#515356", fontWeight: "normal" }} className="ticketSize-option1">No</h3>
                </div>
              </div>
              {this.state.indirectMembersContribution === "Yes" ? (
                <div className="ticketSize-container">
                  <input onChange={this.csrWingIndirectTeamStrength} className="numberInput" type="number" name="quantity" min="1" max="10000000000000" value={this.state.indirectMembersContribution === "Yes" ? this.state.csrWingIndirectTeamStrength : 1} placeholder="1" disabled={JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'} />
                  {this.state.csrWingIndirectTeamStrengthError ? (
                    <span className="note error">The value must be greater than 0</span>
                  ) : (null)}
                </div>
              ) : (null)}
            </div>
          </div>
          <div className="s5-info-4">
            <div className="s5-info-4-header">
              <div className="s5-info-4-point" />
              <h4 className="s5-info-4-heading">
                Which performance metrics do you wish to impact through CSR?
                <span className="astris">*</span>
              </h4>
            </div>
            <div className="s5-info-4-container">
              <li className="s5-info-4-li" style={{ listStyle: 'none', marginLeft: '15px', display: 'flex' }}>
                <Checkbox onChange={this.noMetrics} className="s5-info-4-li-input" type="checkbox" value={-1}
                  checked={this.state.performanceMetrics === -1 ? true : false} disabled={JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'} />
                <h3 className="s5-info-4-li-lable" style={{ fontSize: '18px', marginLeft: '8px', paddingTop: '2px', color: '#515356', fontWeight: "normal" }}>No Preference</h3>
              </li>
              {this.state.performanceMetrics === -1 ? (
                <ul className="s5-info-4-ul">
                  {performanceMetrics.map((metric, index) => {
                    return (
                      <li className="s5-info-4-li" key={index}>
                        <Checkbox value={metric} onChange={this.performanceMetrics} className="s5-info-4-li-input"
                          type="checkbox" disabled={this.state.metrics || JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'} checked={false} />
                        <h3 className="s5-info-4-li-lable">{metric}</h3>
                      </li>
                    )
                  })}
                </ul>
              ) : (
                <ul className="s5-info-4-ul">
                  {performanceMetrics.map((metric, index) => {
                    return (
                      <li className="s5-info-4-li">
                        <Checkbox value={metric} onChange={this.performanceMetrics} className="s5-info-4-li-input"
                          type="checkbox" disabled={JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'}
                          checked={this.state.performanceMetrics.indexOf(metric) > -1 ? true : false} />
                        <h3 className="s5-info-4-li-lable">{metric}</h3>
                      </li>
                    )
                  })}
                </ul>
              )}
            </div>
          </div>
          <div className="s5-info-5">
            <div className="s5-info-5-header">
              <div className="s5-info-5-point" />
              <h4 className="s5-info-5-heading">
                Does there exist any particular negative environmental impact of the
                <br />
                direct/indirect business of the organization?{" "}
                <span className="astris">*</span>
              </h4>
            </div>
            <div className="s5-info-5-container">
              <div className="ticketSize-container">
                <Radio value="Yes" name="environmentLitigation" onChange={this.environmentLitigation} checked={this.state.environmentLitigation === "Yes" ? true : false} disabled={JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'}>
                  <h3 className="ticketSize-option1">Yes</h3>
                </Radio>
                <Radio value="No" name="environmentLitigation" onChange={this.environmentLitigation} checked={this.state.environmentLitigation === "No" ? true : false} disabled={JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'}>
                  <h3 className="ticketSize-option2">No</h3>
                </Radio>
                <Radio value="Maybe" name="environmentLitigation" onChange={this.environmentLitigation} checked={this.state.environmentLitigation === "Maybe" ? true : false} disabled={JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'}>
                  <h3 className="ticketSize-option3">Maybe</h3>
                </Radio>
              </div>
            </div>
          </div>
          <div className="s6-buttons">
            <div className="prev-btn-container">
              <button onClick={this.previous} className="s6-previous">
                Previous
              </button>
              <button onClick={this.next} className="s6-next">
                {JSON.parse(readCookie('userData')).authorizedFor.mis !== 'view' ? ('Continue without saving') : ('Next')}
              </button>
            </div>
            {JSON.parse(readCookie('userData')).authorizedFor.mis !== 'view' ? (
              <div>
                <button onClick={this.saveData} className="s6-save_next">
                  Save & Next
                </button>
              </div>
            ) : (null)}
          </div>
        </div>
      </div>
    );
  }
}

export default AboutYourOrganisation;
