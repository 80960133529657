import React, { Component } from 'react';
import { Button, Modal, Row, Col } from "react-bootstrap";
import swal from 'sweetalert2';
import moment from 'moment';
import { Menu } from 'antd';
import Scroll from 'react-scroll';
import $ from 'jquery';
import ReactGA from 'react-ga';
import { ToastContainer, toast } from "react-toastify";
import { BootstrapTable, TableHeaderColumn, ButtonGroup, ExportCSVButton } from 'react-bootstrap-table';

const checkValue = require('../../../../checkValue.js').checkValue;
const readCookie = require('../../../../cookie.js').readCookie;

let Link = Scroll.Link;

class DisbursementCriteriaEditorModal extends React.Component {
  constructor(props) {
    super(props);
    this.updateData = this.updateData.bind(this);
    this.state = {
      value: props.defaultValue,
      open: true
    };
  }
  focus() {
    this.refs.inputRef.focus();
  }
  updateData() {
    this.props.checkSaveDisabled();
  	if(this.props.type === 'number' && (parseInt(this.state.value) < this.props.minVal)) this.props.notifyError('The minimum value is ' + this.props.minVal);
    else this.props.onUpdate(this.state.value);
  }
  close = () => {
    this.setState({ open: false });
    this.props.onUpdate(this.props.defaultValue);
  }

  changeNumber(e) {
  	if(parseInt(e.currentTarget.value) < this.props.minVal) this.props.notifyWarning('The minimum value is ' + this.props.minVal);
  	this.setState({ value: e.currentTarget.value });
  }
  render() {
    const fadeIn = this.state.open ? 'in' : '';
    const display = this.state.open ? 'block' : 'none';
    return (
    	<div className={ `modal fade ${fadeIn}` } id='myModal' role='dialog' style={ { display } }>
    		<div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-body'>
              {this.props.type === 'number' ? (
              	<input className={'form-control editor edit-text'} ref='inputRef' type='number' value={ this.state.value} min={this.props.minVal} placeholder="Cumulative Target Beneficiary"
                	onChange={this.changeNumber.bind(this)} />
              ) : (null)}
              {this.props.type === 'text' ? (
              	<textarea className={'form-control editor edit-text'} ref='inputRef' value={ this.state.value} placeholder="Milestone Comment"
                	onChange={ e => { this.setState({ value: e.currentTarget.value }); } }></textarea>
              ) : (null)}
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-primary' onClick={ this.updateData }>Save</button>
              <button type='button' className='btn btn-default' onClick={ this.close }>Close</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default class SingleMISProjectTranches extends Component {
	constructor(props) {
	  super(props);
	  this.state = {
	    tranches: [],
	    current: '',
	    checkSaveDisabled: true
	  }
	}

	componentDidMount() {
		if (this.props.match.params.name) {
			let path = this.props.match.params.name;
			fetch(process.env.REACT_APP_API_URL + '/get-project/' + path, {
				method: "GET",
				headers: {
					'Auth': JSON.parse(readCookie('access_token')).access_token
				}
			}).then((data) => data.json())
			.then(function (data) {
				if (data.status === 'ok') {
					let projectId = data.data.id;
					if(!data.data.corporateInterest.hasOwnProperty('disbursementType') && data.data.corporateInterestStatus === 'Under Review') {
						// window.location.pathname = "/mis-projects/" + projectId;
						let tranches = [];
						for(var i = 0; i < tranches.length; i++) {
							tranches[i].slno = i + 1;
						}
						this.setState({ tranches });
					} else if(data.data.corporateInterestStatus === 'Under Review' || data.data.corporateInterestStatus === 'Awaiting action from Ngo') {
						if(data.data.corporateInterest.disbursementType === 'One Time') {
							window.location.pathname = "/mis-projects/" + projectId;
						} else {
							let tranches = data.data.corporateInterest.tranches || [];
							for(var i = 0; i < tranches.length; i++) {
								tranches[i].slno = i + 1;
							}
							this.setState({ tranches }, function() {
								this.checkSaveDisabled(this);
							}.bind(this));
						}
					} else {
						window.location.pathname = "/mis-projects/" + projectId;
					}
					if (data.data.hasOwnProperty('corporateInvestment')) {
						let corporateInvestment = {};
						if (typeof data.data.corporateInvestment.companyId !== 'undefined' && typeof data.data.corporateInvestment.investmentId !== 'undefined' && typeof data.data.corporateInvestment.portfolioId !== 'undefined') {
							corporateInvestment['companyId'] = data.data.corporateInvestment.companyId;
							corporateInvestment['investmentId'] = data.data.corporateInvestment.investmentId;
							corporateInvestment['portfolioId'] = data.data.corporateInvestment.portfolioId;
							this.setState({ confirmInvestment: true });
						}
						this.setState({ corporateInvestment });
					}

					let corporateInterestStatus = data.data.corporateInterestStatus;
					if (corporateInterestStatus !== false) {
						this.setState({ corporateInterestStatus });
					} else {
						this.setState({ corporateInterestStatus: 'noAction' });
					}

					let releasedAmountBreakup = [];
					if (data.data.hasOwnProperty('releasedAmountBreakup') && data.data.releasedAmountBreakup.length > 0) {
						releasedAmountBreakup = data.data.releasedAmountBreakup;
					}
					this.setState({ releasedAmountBreakup });

					this.setState({ ngoId: data.data.ngoData.id, projectId: data.data.id, selectedProject: data.data });

					let companyId = JSON.parse(readCookie('userData')).organisationData.id;
					fetch(process.env.REACT_APP_API_URL + '/project/' + projectId + '/reported-expenses', {
						method: "GET",
						headers: {
							'Auth': JSON.parse(readCookie('access_token')).access_token
						}
					}).then((data) => data.json())
					.then(function (data) {
						if (data.status === 'ok') {
							let amountBreakup = data.expenseItems;
							this.setState({ amountBreakup });
						}
					}.bind(this));

					fetch(process.env.REACT_APP_API_URL + '/project/interests/company/' + companyId, {
						method: "GET",
						headers: {
							'Auth': JSON.parse(readCookie('access_token')).access_token
						}
					}).then((data) => data.json())
					.then(function (data) {
						if (data.status === 'ok') {
							let canShowInterest = true;
							for (var i = 0; i < data.data.length; i++) {
								if (data.data[i].project.id === projectId) {
									canShowInterest = false;
									this.setState({ canShowInterest: true });
								}
							}
							if (canShowInterest) {
								this.setState({ canShowInterest: true });
							}
						}
					}.bind(this));

					fetch(process.env.REACT_APP_API_URL + '/project/' + projectId + '/milestone', {
						method: "GET",
						headers: {
							"Content-Type": "application/json",
							'Auth': JSON.parse(readCookie('access_token')).access_token
						}
					}).then(res => (res.json()))
					.then(data => {
						this.setState({ milestones: data.data });
					}).catch(er => {
					});

					fetch(process.env.REACT_APP_API_URL + '/project/' + projectId + '/beneficiaries', {
						method: 'GET',
						headers: {
							'Auth': JSON.parse(readCookie('access_token')).access_token
						}
					}).then(res => (res.json()))
					.then(data => {
						if(data.status === 'ok') {
							this.setState({ beneficiaries: data.beneficiaries });
						}
					}).catch(er => {
					});

					fetch(process.env.REACT_APP_API_URL + '/project/key-assesment/' + projectId, {
						method: 'GET',
						headers: {
							'Auth': JSON.parse(readCookie('access_token')).access_token
						}
					}).then(res => (res.json()))
					.then(data => {
						if(data.status === 'ok') {
							this.setState({ kpiData: data.data });
						}
					}).catch(er => {
					});
				}
			}.bind(this));
		} else {
			window.location = "/404";
		}
	}

	handleClick(key) {
		ReactGA.event({
		  category: 'View Project',
		  action: 'view',
		  label: key.charAt(0).toUpperCase() + key.slice(1)
		});
		this.setState({ current: key });
		if(this.state.selectedProject.hasOwnProperty('customUrl') && this.state.selectedProject.customUrl) window.location.pathname = "/mis-projects/" + this.state.selectedProject.customUrl + "#" + key;
		else window.location.pathname = "/mis-projects/" + this.state.projectId + "#" + key;
	}

	exportPdf() {
		ReactGA.event({
		  category: 'View Project',
		  action: 'view',
		  label: 'Export Project'
		});
		$('<div class="modal-backdrop"></div>').appendTo(document.body);
		fetch(process.env.REACT_APP_API_URL + '/project/' + this.state.projectId + '/export-project', {
			method: 'GET',
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then(data => data.json())
		.then(data => {
			$(".modal-backdrop").remove();
			if (data.status === 'ok') {
				swal({
    			html: '<img src="/img/report_image.png" style="height: 120px; margin: 20px 0;" />' +
						'<div style="font-size: 26px; letter-spacing: 0.5px; margin: 0 0 20px;">The exported project is ready to be downloaded!</div>' +
						'<a class="btn" style="background: #3085d6; color: #fff; font-size: 22px !important; letter-spacing: 0.5px; padding: 10px 20px; width: 240px; margin: 0 0 10px;" href="' + data.pdfUrl + '" target="_blank">Download</a>',
    			showCancelButton: false,
    			showConfirmButton: false
    		});
			}
		});
	}

	notifyError = text => toast.error(text);

	notifySuccess = text => toast.success(text);

	notifyWarning = text => toast.warn(text);

	chatWithNgo() {
		if(this.state.corporateInterestStatus !== false && this.state.corporateInterestStatus !== 'noAction') {
			ReactGA.event({
			  category: 'View Project',
			  action: 'view',
			  label: 'Chat'
			});
			window.location.pathname = "/discussion/" + this.state.projectId;
		}
	}

	gotoResources() {
		ReactGA.event({
		  category: 'View Project',
		  action: 'view',
		  label: 'Resources'
		});
		if(this.state.selectedProject.hasOwnProperty('customUrl') && this.state.selectedProject.customUrl) window.location.pathname = "/mis-projects/" + this.state.selectedProject.customUrl + "/resources";
		else window.location.pathname = "/mis-projects/" + this.state.projectId + "/resources";
	}

	dateValidator(value) {
		this.checkSaveDisabled(this);
		if(value && moment(value).isValid()) return true;
		else return false;
	}

	amountFormatter(cell, row) {
		if(!isNaN(cell)) return 'INR ' + window.inrFormat(parseFloat(cell));
		else return 'INR ' + 0;
	}

	addNewTranch() {
		let tranches = this.state.tranches;
		tranches.push({
			slno: tranches.length + 1,
  		amount: 0,
  		disbursementCondition: '',
  		disbursementCriteria: '',
  		date: ''
		});
		this.setState({ tranches }, function() {
			this.checkSaveDisabled(this);
		}.bind(this));
	}

	disbursementConditionFormatter(cell, row) {
		if(cell === '') return 'Select Disbursement Condition';
		else return cell;
	}

	disbursementCriteriaFormatter(cell, row) {
		if(row.disbursementCondition === '') return 'Select Disbursement Condition First';
		else if(row.disbursementCondition === 'Periodic') return 'N/A';
		else if(cell === '') return 'Enter Disbursement Criteria';
		else return cell;
	}

	disbursementCriteriaEditor(onUpdate, props) {
		let row = props.row, value = props.defaultValue;
		if(row.disbursementCondition === '') this.notifyWarning('Please select a Disbursement Condition first.');
		else if(row.disbursementCondition === 'Periodic') onUpdate('N/A');
		else if(row.disbursementCondition === 'Cumulative Target Beneficiary') {
			let minVal = 0, currIndex = (row.slno - 2);
			for(var i = currIndex; i >= 0; i--) {
				if(this.state.tranches[i].disbursementCondition === 'Cumulative Target Beneficiary') {
					minVal = parseInt(this.state.tranches[i].disbursementCriteria) + 1;
					break;
				}
			}
			return (
				<DisbursementCriteriaEditorModal type="number" notifyWarning={this.notifyWarning} notifyError={this.notifyError} checkSaveDisabled={this.checkSaveDisabled.bind(this)} minVal={minVal} onUpdate={onUpdate} {...props} />
			)
		} else if(row.disbursementCondition === 'Custom Milestone') {
			return (
				<DisbursementCriteriaEditorModal type="text" checkSaveDisabled={this.checkSaveDisabled.bind(this)} onUpdate={onUpdate} {...props} />
			)
		}
	}

	selectDateFormatter(cell, row) {
		if(cell === '') return 'Select Tentative Date';
		else if(cell && moment(cell).isValid() && cell.match(/....-..-../)) return moment(cell, 'YYYY-MM-DD').format('DD/MM/YYYY');
		else if(cell && moment(cell, 'DD/MM/YYYY').isValid() && cell.match(/..\/..\/..../)) return moment(cell, 'DD/MM/YYYY').format('DD/MM/YYYY');
		else return '';
	}

	saveAllTranches() {
		let data = {};
		data['companyId'] = JSON.parse(readCookie('userData')).organisationData.id;
		data['message'] = '';
		let tranches = [];
		for(var i = 0; i < this.state.tranches.length; i++) {
			tranches.push(this.state.tranches[i]);
			tranches[i].date = moment(tranches[i].date, 'YYYY-MM-DD').format('DD/MM/YYYY');
			if(tranches[i].disbursementCondition === 'Periodic') tranches[i].disbursementCriteria = 'N/A';
		}
		data['tranches'] = tranches;
		data['disbursementType'] = 'Tranches';
		data['status'] = 'invested';
		fetch(process.env.REACT_APP_API_URL + '/project/' + this.state.selectedProject.id + '/interests', {
			method: "POST",
			headers: {
				'Content-Type': 'application/json',
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
			body: JSON.stringify(data)
		}).then(function (response) {
			return response.json();
		}).then(function (data) {
			if (data.status === 'ok') {
				swal(
					'Your Tranch Disbursements are saved.',
					"",
					'success'
				).then(function() {
					if(this.state.selectedProject.hasOwnProperty('customUrl') && this.state.selectedProject.customUrl) window.location.pathname = "/mis-projects/" + this.state.selectedProject.customUrl;
					else window.location.pathname = "/mis-projects/" + this.state.projectId;
				}.bind(this));
			} else {
				swal(
					'Oops!',
					"Your Tranch Disbursements couldn't be saved.",
					'error'
				)
			}
		}.bind(this));
	}

	removeTranch(index) {
		let tranches = this.state.tranches;
		tranches.splice(index, 1);
		for(var i = index; i < tranches.length; i++) {
			tranches[i].slno = tranches[i].slno - 1;
		}
		this.setState({ tranches }, function() {
			this.checkSaveDisabled(this);
		}.bind(this));
	}

	deleteFormatter(cell, row) {
		if(!row.hasOwnProperty('id')) {
			return (
				<button className="btn btn-default btn-danger delete-disbursement-btn" onClick={this.removeTranch.bind(this, row.slno - 1)}>
					<i className="fa fa-times"></i>
				</button>
			)
		} else {
			return null;
		}
	}

	amountValidator(value) {
		this.checkSaveDisabled(this);
		return true;
	}

	disbursementCondValidator(value) {
		this.checkSaveDisabled(this);
		return true;
	}

	checkSaveDisabled() {
		var that = this;
		setTimeout(function() {
			let tranches = that.state.tranches;
			var disabled = false;
			for(var i = 0; i < tranches.length; i++) {
				if(tranches[i].slno !== i + 1) disabled = true;
				else if(tranches[i].amount <= 0) disabled = true;
				else if(tranches[i].disbursementCondition === '') disabled = true;
				else if(tranches[i].disbursementCondition === 'Cumulative Target Beneficiary' && i > 0) {
					let prevVal = 0, currIndex = (i - 1);
					for(var j = currIndex; j >= 0; j--) {
						if(tranches[j].disbursementCondition === 'Cumulative Target Beneficiary') {
							prevVal = parseInt(tranches[j].disbursementCriteria);
							break;
						}
					}
					if(tranches[i].disbursementCriteria === '') disabled = true;
					else if(prevVal !== 0 && prevVal > parseInt(tranches[i].disbursementCriteria)) disabled = true;
				} else if(tranches[i].disbursementCriteria === '' && tranches[i].disbursementCondition !== 'Periodic') disabled = true;
				// else if(tranches[i].date === '') disabled = true;
				// else if(tranches[i].hasOwnProperty('internalDate')) {
				// 	if(!moment(tranches[i].date, 'DD/MM/YYYY').isValid()) disabled = true;
				// } else if(!moment(tranches[i].date, 'YYYY-MM-DD').isValid()) disabled = true;
				if(disabled) break;
			}
			that.setState({ checkSaveDisabled: disabled });
		}, 700);
	}

	render() {
		const cellEditProp = {
		  mode: 'click',
		  blurToSave: true
		};
		let disbursementConditions = ['Periodic', 'Cumulative Target Beneficiary', 'Custom Milestone'];

		if(this.state.selectedProject && this.state.selectedProject.hasOwnProperty('title')) {
			return (
				<div className="mis-content-container single-csr-project-container">
					<ToastContainer position="bottom-center" autoClose={4000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={false} />
					<div className="mis-fixed-top-container" id="single-csr-project-fixed-top">
						<div>
							<Menu selectedKeys={[this.state.current]} mode="horizontal">
								<Menu.Item key="overview">
									<Link onClick={this.handleClick.bind(this, 'overview')}>Overview</Link>
								</Menu.Item>
								<Menu.Item key="description">
									<Link onClick={this.handleClick.bind(this, 'description')}>Description</Link>
								</Menu.Item>
								{(this.state.selectedProject.status === 'Completed' || this.state.selectedProject.status === 'Funded') || this.state.amountBreakup !== null || this.state.releasedAmountBreakup.length ? (
									<Menu.Item key="finances">
										<Link onClick={this.handleClick.bind(this, 'finances')}>Finances</Link>
									</Menu.Item>
								) : (null)}
								{!checkValue(this.state.kpiData) || !checkValue(this.state.beneficiaries) ? (
									<Menu.Item key="impact-monitoring">
										<Link onClick={this.handleClick.bind(this, 'impact-monitoring')}>Impact Monitoring</Link>
									</Menu.Item>
								) : (null)}
								{!checkValue(this.state.milestones) ? (
									<Menu.Item key="milestones">
										<Link onClick={this.handleClick.bind(this, 'milestones')}>Milestones</Link>
									</Menu.Item>
								) : (null)}
								<Menu.Item key='resources'>
									<Link onClick={this.gotoResources.bind(this)}><span>Resources</span></Link>
								</Menu.Item>
								<Menu.Item key='export'>
									<Link onClick={this.exportPdf.bind(this)}><span>Export Project</span></Link>
								</Menu.Item>
								<Menu.Item key='chat' title={this.state.corporateInterestStatus === false || this.state.corporateInterestStatus === 'noAction' ? "Show Interest on Project to start chatting" : null}>
									<Link onClick={this.chatWithNgo.bind(this)}><span>Chat{this.state.corporateInterestStatus === false || this.state.corporateInterestStatus === 'noAction' ? (<i className="fa fa-lock ml5"></i>) : (null)}</span></Link>
								</Menu.Item>
							</Menu>
						</div>
					</div>

					<Row className="back-container">
						<a href={"/mis-projects/" + this.state.projectId} className="back">
							<i className="fa fa-chevron-left mr5"></i>
							Back To Project
						</a>
					</Row>
					<Row className="tranches-container">
						<h3>Tranche Disbursements</h3>
						<div className="btn-group">
							<button className="btn table-btn" onClick={this.addNewTranch.bind(this)}>
								<i className="fa fa-plus"></i>
								Add New Disbursement
							</button>
							<span className="help-icon" title="Click on each cell to edit them.">
								<i className="fa fa-exclamation"></i>
							</span>
						</div>
						<BootstrapTable data={this.state.tranches} cellEdit={cellEditProp}>
				      <TableHeaderColumn dataField='slno' width='80' editable={false} isKey={true}>Tranches</TableHeaderColumn>
				      <TableHeaderColumn dataField='amount' width='160' editable={{ type: 'number', validator: this.amountValidator.bind(this) }} dataFormat={this.amountFormatter.bind(this)}>Planned Amount<span className="is-imp"></span></TableHeaderColumn>
				      <TableHeaderColumn dataField='disbursementCondition' editable={{ type: 'select', options: { values: disbursementConditions }, validator: this.disbursementCondValidator.bind(this) }} dataFormat={this.disbursementConditionFormatter.bind(this)}>Disbursement Condition<span className="is-imp"></span></TableHeaderColumn>
				      <TableHeaderColumn dataField='disbursementCriteria' customEditor={{ getElement: this.disbursementCriteriaEditor.bind(this) }} dataFormat={this.disbursementCriteriaFormatter.bind(this)}>Disbursement Criteria<span className="is-imp"></span></TableHeaderColumn>
				      <TableHeaderColumn dataField='date' width='150' editable={{ type: 'date', validator: this.dateValidator.bind(this) }} dataFormat={this.selectDateFormatter.bind(this)}><span className="disbursement-date-header">Tentative Disbursement Date</span></TableHeaderColumn>
				      <TableHeaderColumn dataField='date' width='60' editable={false} headerAlign='center' dataAlign='center' dataFormat={this.deleteFormatter.bind(this)}>Delete</TableHeaderColumn>
				    </BootstrapTable>
				    <div className="text-right mt10 mb20">
				    	<button className="btn table-btn" disabled={this.state.checkSaveDisabled} onClick={this.saveAllTranches.bind(this)}>
								<i className="fa fa-check"></i>
								Save Disbursements
							</button>
				    </div>
					</Row>
				</div>
			);
		} else {
			return null;
		}
	}
}
