import React, { Component } from "react";
import $ from "jquery";
import swal from "sweetalert2";

const readCookie = require("../../../cookie.js").readCookie;
const createCookie = require("../../../cookie.js").createCookie;

export default class FirstLogin extends Component {
  componentDidMount() {
    let pwd1 = "";
    let pwd2 = "";
    let pwd3 = "";
    let pwd4 = "";
    let pwd5 = "";
    let pwd6 = "";
    let userDataJSON = JSON.parse(readCookie("userData"));
    if (userDataJSON.fullName === "") {
      swal({
        title: "Kindly fill in the following details.",
        html: `<div class="changePasswordSwalContainer">
								<div>
									<span class="mt5 mb15 changePasswordSwal is-imp">Full Name: </span>
									<input type="text" id="swal-input1" class="mt5 mb15 changePasswordSwal swal2-input" value="">
								</div>
								<div>
									<span class="mt5 mb15 changePasswordSwal">Designation: </span>
									<input type="text" id="swal-input2" class="mt5 mb15 changePasswordSwal swal2-input" value="">
								</div>
								<div>
									<span class="mt5 mb15 changePasswordSwal">Mobile Number: </span>
									<input type="tel" id="swal-input3" class="mt5 mb15 changePasswordSwal swal2-input" value="">
								</div>
								<div>
									<span class="mt5 mb15 changePasswordSwal is-imp">Old Password: </span>
									<input type="password" id="swal-input4" class="mt5 mb15 changePasswordSwal swal2-input" value="">
								</div>
								<div>
									<span class="mt5 mb15 changePasswordSwal is-imp">New Password: </span>
									<input type="password" id="swal-input5" class="mt5 mb15 changePasswordSwal swal2-input" value="">
								</div>
								<div>
									<span class="mt5 mb15 changePasswordSwal is-imp">Confirm New Password: </span>
									<input type="password" id="swal-input6" class="mt5 mb15 changePasswordSwal swal2-input" value="">
								</div>
							</div>`,
        showCancelButton: false,
        closeOnCancel: false,
        allowEscapeKey: false,
        confirmButtonText: "Submit",
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
        preConfirm: function() {
          pwd1 = $("#swal-input1").val();
          pwd2 = $("#swal-input2").val();
          pwd3 = $("#swal-input3").val();
          pwd4 = $("#swal-input4").val();
          pwd5 = $("#swal-input5").val();
          pwd6 = $("#swal-input6").val();
          return new Promise(function(resolve, reject) {
            setTimeout(function() {
              if (pwd1 === "") {
                reject("Please enter a proper full name.");
              } else if (pwd4 === "" && pwd5 === "" && pwd5 !== pwd6) {
                reject("Password did not match.");
              } else {
                resolve();
              }
            }, 2000);
          });
        },
        allowOutsideClick: false
      }).then(
        function(result) {
          pwd1 = $("#swal-input1").val();
          pwd2 = $("#swal-input2").val();
          pwd3 = $("#swal-input3").val();
          pwd4 = $("#swal-input4").val();
          pwd5 = $("#swal-input5").val();
          pwd6 = $("#swal-input6").val();

          if (
            pwd1 !== "" &&
            pwd4 !== "" &&
            pwd5 !== "" &&
            pwd5 === pwd6 &&
            result
          ) {
            let data = {};
            data["userId"] = userDataJSON["id"];
            data["fullName"] = pwd1;
            data["designationAtOrganisation"] = pwd2;
            data["mobileNo"] = pwd3;
            data["oldPassword"] = pwd4;
            data["newPassword"] = pwd6;

            fetch(process.env.REACT_APP_API_URL + "/updatePassword", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Auth: JSON.parse(readCookie("access_token")).access_token
              },
              body: JSON.stringify(data)
            })
              .then(function(response) {
                return response.json();
              })
              .then(
                function(data) {
                  if (data.status === "ok") {
                    var userDataCookie = readCookie("userData");
                    var userDataJSON = JSON.parse(userDataCookie);

                    userDataJSON["fullName"] = pwd1;
                    userDataJSON["designationAtOrganisation"] = pwd2;
                    userDataJSON["mobileNo"] = pwd3;
                    userDataJSON["hasCustomPassword"] = true;

                    var walkthroughJSON = {
             completed: false,
                      data: [
                        {
                          title: "CSR Strategy Set-up",
                          body:
                            "Corporate can create their CSR strategy afresh in this section (LE needs to identify and add the various parameters in addition to the following which will help in effective determination and articulation of CSR Strategy.)",
                          url: "/walkthrough/csr-strategy-setup",
                          done: true
                        },
                        {
                          title: "Fiscal year budgeting",
                          body:
                            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry",
                          url: "/walkthrough/fiscal-year-budgeting",
                          done: true
                        },
                        {
                          title: "Investment planning",
                          body:
                            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry",
                          url: "/walkthrough/investment-planning",
                          done: false
                        },
                        {
                          title: "Investment Portfolio",
                          body:
                            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry",
                          url: "/walkthrough/investment-portfolio",
                          done: false
                        },
                        {
                          title: "Impact Monitoring",
                          body:
                            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry",
                          url: "/walkthrough/impact-monitoring",
                          done: false
                        }
                      ],
                      completedSteps: []
                    };

                    // var strategyJSON = {

                    // };

                    createCookie("userData", JSON.stringify(userDataJSON), 7);
                    localStorage.setItem(
                      "walkthrough",
                      JSON.stringify(walkthroughJSON),
                      7
                    );
                    // localStorage.setItem(
                    //   "strategy",
                    //   JSON.stringify(strategyJSON),
                    //   7
                    // );
                    swal({
                      type: "success",
                      title: "Your password has been changed successfully!",
                      showCancelButton: false,
                      closeOnCancel: false,
                      allowEscapeKey: false,
                      allowOutsideClick: false
                    }).then(function() {
                      window.location.pathname = "/";
                    })
                  } else if (data.status === "error") {
                    swal("Oops...", "Invalid password entered!", "error").then(
                      function() {
                        this.componentDidMount();
                      }.bind(this)
                    );
                  }
                }.bind(this)
              );
          } else if (pwd2 !== pwd3 && result) {
            swal("Oops...", "Passwords did not match.", "error").then(
              function() {
                this.componentDidMount();
              }.bind(this)
            );
          } else {
            swal("Oops...", "Invalid password entered!", "error").then(
              function() {
                this.componentDidMount();
              }.bind(this)
            );
          }
        }.bind(this)
      );
    } else {
      swal({
        title: "Kindly create a new password",
        html: `<div class="changePasswordSwalContainer">
								<div>
									<span class="mt5 mb15 changePasswordSwal is-imp">Old Password: </span>
									<input type="password" id="swal-input1" class="mt5 mb15 changePasswordSwal swal2-input" value="">
								</div>
								<div>
									<span class="mt5 mb15 changePasswordSwal is-imp">New Password: </span>
									<input type="password" id="swal-input2" class="mt5 mb15 changePasswordSwal swal2-input" value="">
								</div>
								<div>
									<span class="mt5 mb15 changePasswordSwal is-imp">Confirm New Password: </span>
									<input type="password" id="swal-input3" class="mt5 mb15 changePasswordSwal swal2-input" value="">
								</div>
							</div>`,
        showCancelButton: false,
        closeOnCancel: false,
        allowEscapeKey: false,
        confirmButtonText: "Submit",
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
        preConfirm: function() {
          pwd1 = $("#swal-input1").val();
          pwd2 = $("#swal-input2").val();
          pwd3 = $("#swal-input3").val();
          return new Promise(function(resolve, reject) {
            setTimeout(function() {
              if (pwd1 === "" && pwd2 === "" && pwd2 !== pwd3) {
                reject("Password did not match.");
              } else {
                resolve();
              }
            }, 2000);
          });
        },
        allowOutsideClick: false
      }).then(
        function(result) {
          pwd1 = $("#swal-input1").val();
          pwd2 = $("#swal-input2").val();
          pwd3 = $("#swal-input3").val();

          if (pwd1 !== "" && pwd2 !== "" && pwd2 === pwd3 && result) {
            let data = {};
            data["userId"] = userDataJSON["id"];
            data["oldPassword"] = pwd1;
            data["newPassword"] = pwd2;

            fetch(process.env.REACT_APP_API_URL + "/updatePassword", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Auth: JSON.parse(readCookie("access_token")).access_token
              },
              body: JSON.stringify(data)
            })
              .then(function(response) {
                return response.json();
              })
              .then(
                function(data) {
                  if (data.status === "ok") {
                    var userDataCookie = readCookie("userData");
                    var userDataJSON = JSON.parse(userDataCookie);

                    userDataJSON["hasCustomPassword"] = true;
                    createCookie("userData", JSON.stringify(userDataJSON), 7);
                    swal({
                      type: "success",
                      title: "Your password has been changed successfully!",
                      showCancelButton: false,
                      closeOnCancel: false,
                      allowEscapeKey: false,
                      allowOutsideClick: false
                    }).then(function() {
                      window.location.pathname = "/";
                    });
                  } else if (data.status === "error") {
                    swal("Oops...", "Invalid password entered!", "error").then(
                      function() {
                        this.componentDidMount();
                      }.bind(this)
                    );
                  }
                }.bind(this)
              );
          } else if (pwd2 !== pwd3 && result) {
            swal("Oops...", "Passwords did not match.", "error").then(
              function() {
                this.componentDidMount();
              }.bind(this)
            );
          } else {
            swal("Oops...", "Invalid password entered!", "error").then(
              function() {
                this.componentDidMount();
              }.bind(this)
            );
          }
        }.bind(this)
      );
    }
  }

  render() {
    return <div />;
  }
}
