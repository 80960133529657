import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import $ from 'jquery';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';

export default class Calendar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			availableDates: []
		}
	}

	componentDidMount() {
		if(this.props.dateType === 'unavailable') {
			let unavailableDates = this.props.unavailableDates;
	    let availableDates = [];

	    for(let i = 0; i < 60; i++) {
	      let day = moment().add(i, 'days').format('DD/MM/YYYY');
	      availableDates.push(day);
	      for(let j = 0; j < unavailableDates.length; j++) {
	      	if(unavailableDates[j] === day) {
	      		availableDates.splice(availableDates.length - 1, 1);
	      		unavailableDates.splice(j, 1);
	      		break;
	      	}
	      }
	    }
    	this.setState({ availableDates });
    }
	}

	change(selectedDates, dateStr, instance) {
		let origDates = [];
		if(this.props.dateType === 'unavailable') origDates = this.state.availableDates;
		else origDates = this.props.availableDates;
		instance.setDate(origDates);
	}

	render() {
		if(this.props.dateType === 'unavailable') {
			if(this.state.availableDates.length !== 0) {
				let availableDates = this.state.availableDates;
				return (
					<Flatpickr data-inline id="datepickr" options={{ onChange: this.change.bind(this), allowInput: false, mode: "multiple", dateFormat: "d-m-Y", minDate: new Date(new Date().setDate(new Date().getDate() - 1)), maxDate: new Date(new Date().setDate(new Date().getDate() + 59)), defaultDate: this.state.availableDates, enable: this.state.availableDates }}/>
				);
			} else {
				return null;
			}
		} else {
			return (
				<Flatpickr data-inline id="datepickr" options={{ onChange: this.change.bind(this), allowInput: false, mode: "multiple", dateFormat: "d-m-Y", minDate: new Date(new Date().setDate(new Date().getDate() - 1)), maxDate: new Date(new Date().setDate(new Date().getDate() + 59)), defaultDate: this.props.availableDates, enable: this.props.availableDates }}/>
			);
		}
	}
}