import React, { Component } from "react";
import { Checkbox } from "antd";
import { Button, Modal, Row, Col } from "react-bootstrap";

export default class InvestedModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      agreed: false
    }
  }

  handleCheckbox() {
    this.setState({ checked: !this.state.checked });
  }

  handleAgree() {
    this.setState({ agreed: true });
  }

  gotoTranchedPage() {
    if(this.props.hasOwnProperty('customUrl') && this.props.customUrl) window.location.pathname = "/mis-projects/" + this.props.customUrl + "/tranch-disbursement";
    else window.location.pathname = "/mis-projects/" + this.props.projectId + "/tranch-disbursement";
  }

  render() {
    return (
      <div>
        <Modal id="show-interest-modal" show={this.props.ShowInterestModal} onHide={this.props.onShowInterestClose} bsSize="large">
          <Modal.Body className="show-interest-modal-body">
            <Row>
              <Col className="show-interest-modal-summary" md={12}>
                <p>This project shall move to <b>“Invested”</b> state. The NGO would be notified and your organisation details would be shared with them. You can start investing funds in this project once the NGO accepts your funds.</p>
              </Col>
            </Row>
            <Row>
              <Col className="show-interest-modal-para" md={12}>
                <p>I confirm that I would commit to investing funds in this project.<br />I would not be able to revert the commitment later unless mutually agreed by all the stakeholders.</p>
                <Col md={12} className="modal-checkbox-conatiner">
                  <Checkbox onChange={this.handleCheckbox.bind(this)}
                    checked={this.state.checked}>
                    <p className="modal-agree">I agree</p>
                  </Checkbox>
                </Col>
              </Col>
            </Row>
            {this.state.agreed ? (
              <Row className="mt30 mb10">
                <Col className="show-interest-modal-para" md={12}>
                  <p className="notice">Please select the type of fund disbursement</p>
                </Col>
                <Col md={12} className="text-center">
                  <div className="btn-group text-center">
                    <Button className="btn le-button-light full-disbursement ml5 mr5" onClick={this.props.handleAgree.bind(this, 'Confirm')}>
                      One time full disbursement
                    </Button>
                    <Button className="btn le-button-light tranch-disbursement ml5 mr5" onClick={this.gotoTranchedPage.bind(this)}>
                      Tranched disbursement
                    </Button>
                  </div>
                </Col>
              </Row>
            ) : (null)}
          </Modal.Body>
          <Modal.Footer>
            {this.state.agreed ? (null) : (
              this.state.checked ? (
                <Button className="btn le-button-light agree" onClick={this.handleAgree.bind(this)}>
                  Confirm
                </Button>
              ) : (
                <Button className="btn le-button-light agree" disabled={true}>
                  Confirm
                </Button>
              )
            )}
            <Button className="btn le-button-light" onClick={this.props.onShowInterestClose}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
