import React,{ Component } from 'react';
import { withGoogleMap, GoogleMap, Marker, InfoWindow } from 'react-google-maps';

const MapWithMarkers = withGoogleMap(props => (
  <GoogleMap defaultZoom={4} defaultCenter={props.center}>
    {props.markers.map(function(marker, index) {
      return (
      	<Marker {...marker} position={marker.latlng} onClick={() => props.changeMarkerShowInfo(index)}>
		      {marker.showInfo && (
		      	<InfoWindow onCloseClick={() => props.changeMarkerShowInfo(index)} options={{ closeBoxURL: ``, enableEventPropagation: true }}>
		        	<div className="info-window" style={{ backgroundColor: `rgba(255, 255, 255, 0.7)`, padding: `4px 4px 4px 0px` }}>
		          	<div style={{ fontSize: `12px`, fontColor: `#000` }}>
		            	<div className="marker-info-project"><span className="title">Project:</span> {marker.project.title}</div>
		            	<div className="marker-info-location"><span className="title">Location:</span> {marker.address}</div>
		            	<div className="marker-info-investment"><a href={"/investments/" + marker.project.id}>See Your Investment</a></div>
			          </div>
			        </div>
			      </InfoWindow>
			    )}
      	</Marker>
      )
    }.bind(this))}
  </GoogleMap>
));

const MapWithoutMarkers = withGoogleMap(props =>
  <GoogleMap defaultZoom={4} defaultCenter={{ lat: 20.5937, lng: 78.9629 }} />
);

export default class Dashboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			center: null
		}
	}

	componentDidMount() {
		var markers = this.props.markers;
		if(markers.length) {
			var latCenter = markers[0].latlng.lat;
			var lngCenter = markers[0].latlng.lng;
			for(var i = 1; i < markers.length; i++) {
				var latlng = markers[i].latlng;
				latCenter += latlng.lat;
				lngCenter += latlng.lng;
			}
			latCenter = latCenter / markers.length;
			lngCenter = lngCenter / markers.length;
			var center = {};
			center['lat'] = latCenter;
			center['lng'] = lngCenter;
			this.setState({ center });
		} else {
			this.setState({ center: [] });
		}
	}

	render() {
		if(this.state.center !== null) {
			if(this.props.markers.length) {
				return (
					<MapWithMarkers center={this.state.center} markers={this.props.markers} changeMarkerShowInfo={this.props.changeMarkerShowInfo} containerElement={<div style={{ height: `400px` }} />} mapElement={<div style={{ height: `100%` }} />} />
				)
			} else {
				return (
					<MapWithoutMarkers containerElement={<div style={{ height: `400px` }} />} mapElement={<div style={{ height: `100%` }} />} />
				)
			}
		} else {
			return <span>Loading Map...</span>
		}
	}
}