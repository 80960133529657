import React, { Component } from "react";
import {BootstrapTable, TableHeaderColumn, ButtonGroup} from 'react-bootstrap-table';
import swal from "sweetalert2";
import { ToastContainer, toast } from 'react-toastify';
import { Col, Row, Tab, Tabs, Modal } from 'react-bootstrap';
import moment from 'moment';
import { DatePicker } from 'antd';
import { Select } from 'antd';
import $ from 'jquery';
import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend} from 'recharts';

const { RangePicker } = DatePicker;
const dateFormat = 'MMM DD YYYY';
const monthFormat = 'MM/YYYY';

const checkValue = require('../../../checkValue.js').checkValue;
const readCookie = require('../../../cookie.js').readCookie;
const Option = Select.Option;

const cellEditProp = {
  mode: 'click',
  blurToSave : true
};
const selectRowProp = {
  mode: 'checkbox'
};

class DirectorsReport extends Component {
	constructor(props){
		super(props);
		this.state = {
			financialYearAmountSpent: [],
      committee: [],
      reportYearSelect: "",
      briefOutlineInput:"",
      AverageNetProfitInput:"",
      PrescribedCSRExpenditureInput:"",
      amountSpentInput:"",
      amountUnspentInput:"",
      financialYearsInput:"",
	  responsibilityStatementInput:"",
      options: {
			  afterInsertRow: this.onAfterInsertRow,  // A hook for after insert rows
			  afterDeleteRow: this.onAfterDeleteRow
			},
			options2: {
			  afterInsertRow: this.onAfterInsertRow2,  // A hook for after insert rows
			  afterDeleteRow: this.onAfterDeleteRow2
			},
			via:[
				'Direct',
				'Implementing Agency'
			],
			errorFields:{},
			saved: false
		}
	}

	notifyError = text => toast.error(text);
	notifySuccess = text => toast.success(text);

	generateDisclosure = () => {
		const startYear = this.state.reportYearSelect.slice(0,4),
			endYear = this.state.reportYearSelect.slice(5,12),
			orgId = JSON.parse(readCookie('userData')).organisationData.id;

		$('<div class="modal-backdrop"></div>').appendTo(document.body);
		fetch(`${process.env.REACT_APP_API_URL}/report/generate`,{
			method: "POST",
			headers: {
				'Content-type': 'application/json',
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
			body: JSON.stringify({
				organizationId: orgId,
				startYear,
				endYear
			})
		}).then(response => response.json())
		.then(data => {
			$(".modal-backdrop").remove();
			if (data.status === "ok") {
				swal({
    			html: '<img src="/img/report_image.png" style="height: 120px; margin: 20px 0;" />' +
						'<div style="font-size: 26px; letter-spacing: 0.5px; margin: 0 0 20px;">The exported report is ready to be downloaded!</div>' +
						'<a class="btn" style="background: #3085d6; color: #fff; font-size: 22px !important; letter-spacing: 0.5px; padding: 10px 20px; width: 240px; margin: 0 0 10px;" href="' + data.pdfUrl + '" target="_blank">Download</a>',
    			showCancelButton: false,
    			showConfirmButton: false
    		});
			} else {
				if(data.message) this.notifyError(data.message);
				else this.notifyError("Oops, something went wrong! Try again later.");
			}
		}).catch(err => this.notifyError("Oops, something went wrong! Try again later."))
	}

	/*refreshData = () => {
		const startYear = this.state.reportYearSelect.slice(0,4),
			endYear = this.state.reportYearSelect.slice(5,12),
			orgId = JSON.parse(readCookie('userData')).organisationData.id;

		fetch(`${process.env.REACT_APP_API_URL}/report/fetch`, {
			method: "POST",
			headers: {
				'Content-type': 'application/json',
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
			body: JSON.stringify({
				startYear,
				endYear,
				organizationId: orgId
			})
		}).then(response => response.json())
		.then(data => {
			if (data.status === "ok") {
				this.setState({
					financialYearAmountSpent: data.data.answer5c,
					committee: data.data.answer2,
					reportYearSelect: `${data.data.startYear}-${data.data.endYear}`,
					briefOutlineInput: data.data.answer1,
					AverageNetProfitInput: data.data.answer3,
					PrescribedCSRExpenditureInput: data.data.answer4,
					amountSpentInput: data.data.answer5a,
					amountUnspentInput: data.data.answer5b,
					financialYearsInput: data.data.answer6,
					responsibilityStatementInput: data.data.answer7
				});
			} else {
				this.notifyError(`No data available for ${startYear} - ${endYear}`);
				this.setState({
					financialYearAmountSpent: [],
      		committee: [],
      		briefOutlineInput:"",
      		AverageNetProfitInput:"",
      		PrescribedCSRExpenditureInput:"",
      		amountSpentInput:"",
      		amountUnspentInput:"",
      		financialYearsInput:"",
      		responsibilityStatementInput:""
				});
			}
		})
		.catch(err => this.notifyError("Oops, your data could not be fetched!"))
	}*/

	reportInputHandler = (e) => {
		this.setState({[e.target.name]:e.target.value})
		if (e.target.name === 'reportYearSelect') {
			const startYear = e.target.value.slice(0,4),
				endYear = e.target.value.slice(5,12),
				orgId = JSON.parse(readCookie('userData')).organisationData.id;

			fetch(`${process.env.REACT_APP_API_URL}/report/fetch`, {
				method: "POST",
				headers: {
					'Content-type': 'application/json',
					'Auth': JSON.parse(readCookie('access_token')).access_token
				},
				body: JSON.stringify({
					startYear,
					endYear,
					organizationId: orgId
				})
			}).then(response => response.json())
			.then(data => {
				if (data.status === "ok") {
					this.setState({
						financialYearAmountSpent: data.data.answer5c,
						committee: data.data.answer2,
						reportYearSelect: `${data.data.startYear}-${data.data.endYear}`,
						briefOutlineInput: data.data.answer1,
						AverageNetProfitInput:data.data.answer3,
						PrescribedCSRExpenditureInput:data.data.answer4,
						amountSpentInput:data.data.answer5a,
						amountUnspentInput:data.data.answer5b,
						financialYearsInput:data.data.answer6,
						responsibilityStatementInput:data.data.answer7
					});
				} else {
					this.notifyError("No data available for this time period. You could update it");
					this.setState({
						financialYearAmountSpent: [],
	      		committee: [],
	      		briefOutlineInput:"",
	      		AverageNetProfitInput:"",
	      		PrescribedCSRExpenditureInput:"",
	      		amountSpentInput:"",
	      		amountUnspentInput:"",
	      		financialYearsInput:"",
	      		responsibilityStatementInput:""
					});
				}
			}).catch(err => this.notifyError("Oops, your data could not be fetched!"))
		}
	}

	onAfterInsertRow = (row) => {
		let committee = [...this.state.committee];
		if(row.id!== committee.length+1)
			row.id=committee.length+1;
		committee.push(row);
	  this.setState({committee})
	}

	onAfterInsertRow2 = (row) => {
		let financialYearAmountSpent = [...this.state.financialYearAmountSpent];
		if(row.partnerName!==financialYearAmountSpent.length+1){
			row.partnerName=financialYearAmountSpent.length+1;
		}
		financialYearAmountSpent.push(row);
	  this.setState({financialYearAmountSpent})
	}

	onAfterDeleteRow = (rowKeys) => {
		let committee = this.state.committee;
		for (let i = 0; i < committee.length; i++) {
			for (let j = 0; j < rowKeys.length; j++) {
				if (committee[i] && rowKeys[j] === committee[i].id) 
					committee.splice(i,1);
			}
		}
		for(let i=0;i<committee.length;i++){
			if(committee[i].id!==i+1)
				committee[i].id=i+1;
		}
		this.setState({committee});
	}

	onAfterDeleteRow2 = (rowKeys) => {
		let financialYearAmountSpent = this.state.financialYearAmountSpent;
		for (let i = 0; i < financialYearAmountSpent.length; i++) {
			for (let j = 0; j < rowKeys.length; j++) {
				if (financialYearAmountSpent[i]!=undefined && financialYearAmountSpent[i].partnerName===(rowKeys[j]) )
					financialYearAmountSpent.splice(i, 1);
				
			}
		}
		for(let i=0;i<financialYearAmountSpent.length;i++){
			if(financialYearAmountSpent[i].partnerName!==i+1)
				financialYearAmountSpent[i].partnerName=i+1;
		}
		this.setState({financialYearAmountSpent});
	}

	saveReport = () => {
		this.setState({ saved: true });
		let data = {}, flag = false, errorFields = {};
		errorFields["amountSpentInput"] = this.state.amountSpentInput !== "" ? "isNotEmpty" : "isEmpty";
		errorFields["reportYearSelect"] = this.state.reportYearSelect !== "" ? "isNotEmpty" : "isEmpty";
    errorFields["briefOutlineInput"] = this.state.briefOutlineInput !== "" ? "isNotEmpty" : "isEmpty";
    errorFields["amountUnspentInput"] = this.state.amountUnspentInput !== ""? "isNotEmpty" : "isEmpty";
    errorFields["financialYearsInput"] = this.state.financialYearsInput !== "" ? "isNotEmpty" : "isEmpty";
    errorFields["AverageNetProfitInput"] = this.state.AverageNetProfitInput !== "" ? "isNotEmpty" : "isEmpty";
    errorFields["responsibilityStatementInput"] = this.state.responsibilityStatementInput !== "" ? "isNotEmpty" : "isEmpty";
    errorFields["PrescribedCSRExpenditureInput"] = this.state.PrescribedCSRExpenditureInput !== "" ? "isNotEmpty" : "isEmpty";
    errorFields["committee"] = this.state.committee.length ? "isNotEmpty" : "isEmpty";
    errorFields["financialYearAmountSpent"] = this.state.financialYearAmountSpent.length ? "isNotEmpty" : "isEmpty";
    this.setState({ errorFields });

		if (this.state.amountSpentInput !== "" && this.state.reportYearSelect !== "" && this.state.briefOutlineInput !== "" && this.state.amountUnspentInput !== ""&& parseInt(this.state.amountUnspentInput)<parseInt(this.state.amountSpentInput) && this.state.financialYearsInput !== "" && this.state.AverageNetProfitInput !== "" && this.state.responsibilityStatementInput !== "" && this.state.PrescribedCSRExpenditureInput !== "" && this.state.committee.length && this.state.financialYearAmountSpent.length) {
			flag = true;
		}

		if (flag === true) {
	    data["committee"] = this.state.committee;
	    data["financialYearAmountSpent"] = this.state.financialYearAmountSpent;
	    data["amountSpentInput"] = this.state.amountSpentInput;
			data["reportYearSelect"] = this.state.reportYearSelect;
	    data["briefOutlineInput"] = this.state.briefOutlineInput;
	    data["amountUnspentInput"] = this.state.amountUnspentInput;
	    data["financialYearsInput"] = this.state.financialYearsInput;
	    data["AverageNetProfitInput"] = this.state.AverageNetProfitInput;
	    data["responsibilityStatementInput"] = this.state.responsibilityStatementInput;
	    data["PrescribedCSRExpenditureInput"] = this.state.PrescribedCSRExpenditureInput;

			fetch(`${process.env.REACT_APP_API_URL}/report/update`,{
				method: "POST",
				headers: {
					'Content-type': 'application/json',
					'Auth': JSON.parse(readCookie('access_token')).access_token
				},
				body: JSON.stringify({
					userId: JSON.parse(readCookie('userData')).id,
					organizationId : JSON.parse(readCookie('userData')).organisationData.id,
					startYear: data["reportYearSelect"].slice(0,4),
					endYear: data["reportYearSelect"].slice(5,12),
					answer1: data["briefOutlineInput"],
					answer2: data["committee"],
					answer3: data["AverageNetProfitInput"],
					answer4: data["PrescribedCSRExpenditureInput"],
					answer5a: data["amountSpentInput"],
					answer5b: data["amountUnspentInput"],
					answer5c : data["financialYearAmountSpent"],
					answer6: data["financialYearsInput"],
					answer7: data["responsibilityStatementInput"],
				})
			})
			.then(response => response.json())
			.then(data => {
				if (data.status === "ok") {
					this.notifySuccess("Successfully Saved");
				} else {
					this.notifyError("Oops, something went wrong! Try again later.")
				}
			}).catch(err => this.notifyError("Oops, something went wrong! Try again later."))
		} else {
			this.notifyError("Please provide mandatory details");
		}
	}

	financialYear1 = (value, row, rowIdx) => {
		let financialYearAmountSpent = this.state.financialYearAmountSpent;
		for (let i = 0; i < financialYearAmountSpent.length; i++) {
			if (row.partnerName === financialYearAmountSpent[i].partnerName) {
				financialYearAmountSpent[i].partnerName1 = value;
			}
		}
		this.setState({financialYearAmountSpent});
		return true;
	}
	financialYear2 = (value, row, rowIdx) => {
		let financialYearAmountSpent = this.state.financialYearAmountSpent;
		for (let i = 0; i < financialYearAmountSpent.length; i++) {
			if (row.partnerName === financialYearAmountSpent[i].partnerName) {
				financialYearAmountSpent[i].partnerName2 = value;
			}
		}
		this.setState({financialYearAmountSpent});
		return true;
	}
	financialYear3 = (value, row, rowIdx) => {
		let financialYearAmountSpent = this.state.financialYearAmountSpent;
		for (let i = 0; i < financialYearAmountSpent.length; i++) {
			if (row.partnerName === financialYearAmountSpent[i].partnerName) {
				financialYearAmountSpent[i].partnerName3 = value;
			}
		}
		this.setState({financialYearAmountSpent});
		return true;
	}
	financialYear4 = (value, row, rowIdx) => {
		let financialYearAmountSpent = this.state.financialYearAmountSpent;
		for (let i = 0; i < financialYearAmountSpent.length; i++) {
			if (row.partnerName === financialYearAmountSpent[i].partnerName) {
				financialYearAmountSpent[i].partnerName4 = value;
			}
		}
		this.setState({financialYearAmountSpent});
		return true;
	}
	financialYear5 = (value, row, rowIdx) => {
		let financialYearAmountSpent = this.state.financialYearAmountSpent;
		for (let i = 0; i < financialYearAmountSpent.length; i++) {
			if (row.partnerName === financialYearAmountSpent[i].partnerName) {
				financialYearAmountSpent[i].partnerName5 = value;
			}
		}
		this.setState({financialYearAmountSpent});
		return true;
	}
	financialYear6 = (value, row, rowIdx) => {
		let financialYearAmountSpent = this.state.financialYearAmountSpent;
		for (let i = 0; i < financialYearAmountSpent.length; i++) {
			if (row.partnerName === financialYearAmountSpent[i].partnerName) {
				financialYearAmountSpent[i].partnerName6 = value;
			}
		}
		this.setState({financialYearAmountSpent});
		return true;
	}
	financialYear7 = (value, row, rowIdx) => {
		let financialYearAmountSpent = this.state.financialYearAmountSpent;
		for (let i = 0; i < financialYearAmountSpent.length; i++) {
			if (row.partnerName === financialYearAmountSpent[i].partnerName) {
				financialYearAmountSpent[i].partnerName7 = value;
			}
		}
		this.setState({financialYearAmountSpent});
		return true;
	}
	

	committeeName = (value, row) => {
		let committee = this.state.committee;
		for (let i = 0; i < committee.length; i++) {
			if (row.id=== committee[i].id) {
				committee[i].name = value;
			}
		}
		this.setState({committee});
		return true;
	}

	committeeDesignation = (value, row) => {
		let committee = this.state.committee;
		for (let i = 0; i < committee.length; i++) {
			if (row.id=== committee[i].id) {
				committee[i].designation = value;
			}
		}
		this.setState({committee});
		return true;
	}

	render () {
		return (
			<div className="mis-content-container">
				<ToastContainer position="bottom-center" autoClose={3000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={true} />
				{/* <div className='featureRestrictionHeader'>
					<p>This feature is not available in your account.</p>
				</div> */}
				<select name="reportYearSelect" style={this.state.saved ? (this.state.errorFields.reportYearSelect === "isNotEmpty" ? {marginBottom:"20px"} : {marginBottom:"20px", border: "solid 1px #ef5a20"}) : {marginBottom:"20px"}} value={this.state.reportYearSelect} id="reportYearSelect" onChange={this.reportInputHandler}>
					<option value="" disabled selected>Select Year</option>
					<option value="2023-2024">2023-2024</option>
					<option value="2022-2023">2022-2023</option>
					<option value="2021-2022">2021-2022</option>
					<option value="2020-2021">2020-2021</option>
					<option value="2019-2020">2019-2020</option>
					<option value="2018-2019">2018-2019</option>
					<option value="2017-2018">2017-2018</option>
					
				</select>
				<h2>THE ANNUAL REPORT ON CORPORATE SOCIAL RESPONSIBILITY (CSR) ACTIVITIES</h2>
				<div style={{marginTop: "15px"}}>
					<h4 style={{textIndent: "-15px", marginLeft: "15px", lineHeight: "1.2"}} className='briefOutlineHeading'>1. Brief Outline of the Company’s CSR Policy, including overview of projects or programs proposed to be undertaken and a reference to the web-link to the CSR policy and projects or Programs <a href="https://www.tridentindia.com/webroot/reports/599adcb9406ac_1503321273_2017_Annual_Report_on_CSR_Activities.pdf" target="_blank">(Here is a sample policy).</a>
					</h4>
					<textarea style={this.state.saved ? (this.state.errorFields.briefOutlineInput === "isNotEmpty" ? {marginLeft: "15px"} : {marginLeft: "15px", border: "solid 1px #ef5a20"}):{marginLeft: "15px"}} value={this.state.briefOutlineInput} name="briefOutlineInput" onChange={this.reportInputHandler} placeholder='Enter your text here...' className="briefOutlineArea textArea" cols="80" rows="8" disabled={JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'}></textarea>
				</div>
				<div style={{marginTop: "20px"}}>
					<div className='reportQues2' style={{display:"flex", alignItems:"center"}}>
						<h4 style={{textIndent: "-15px", marginLeft: "15px", lineHeight: "1.2"}} className='compositionHeading'>2. The Composition of the CSR Committee.</h4>
						{this.state.saved ? (this.state.errorFields.committee !== "isNotEmpty" ? <p style={{color: "#ef5a20", margin:"0px 0px 10px 10px"}}>Please provide required data, this table can not be empty!</p> : null): (null)}
					</div>
					<div className='composition-table-div-scrollable' style={{marginLeft: "15px"}}>
						<BootstrapTable data={this.state.committee} id="composition-table" className='composition-table' striped={false} ref='table' insertRow={JSON.parse(readCookie('userData')).authorizedFor.mis !== 'view'} cellEdit={cellEditProp} options={this.state.options} deleteRow={JSON.parse(readCookie('userData')).authorizedFor.mis !== 'view'} selectRow={selectRowProp}>
							<TableHeaderColumn dataField='id' editable={{ type: 'number'}} isKey={true}>Sr.No.</TableHeaderColumn>
							<TableHeaderColumn dataField="name"  editable={{ type: 'input', validator: this.committeeName }}  headerAlign='center' dataAlign='center' >Name</TableHeaderColumn>
						 	<TableHeaderColumn dataField="designation" editable={{ type: 'input', validator: this.committeeDesignation }} headerAlign='center' dataAlign='center'>Designation</TableHeaderColumn>
						</BootstrapTable>
					</div>
				</div>
				<div style={{marginTop: "20px"}}>
					<h4 style={{textIndent: "-15px", marginLeft: "15px", lineHeight: "1.2"}} className='briefOutlineHeading'>3. Average Net Profit of the Company for last three financial years:</h4>
					<div style={{display: "flex", alignItems: "center", marginLeft: "15px"}}>
						<label style={{margin: "0px 10px 0px 0px"}} htmlFor="">INR</label>
						<input placeholder="Enter your value here..." type="number" style={this.state.saved ? (this.state.errorFields.AverageNetProfitInput === "isNotEmpty" ? {margin: "0px 0px 0px 0px"} : {marginLeft: "0px", border: "solid 1px #ef5a20"}):({margin: "0px 0px 0px 0px"})} value={this.state.AverageNetProfitInput} onChange={this.reportInputHandler} name="AverageNetProfitInput" className="reportInput ques3input" disabled={JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'} />
					</div>
				</div>
				<div style={{marginTop: "20px"}}>
					<h4 style={{textIndent: "-15px", marginLeft: "15px", lineHeight: "1.2"}} className='briefOutlineHeading'>4. Prescribed CSR Expenditure (two percent of the amount as in item 3 above):</h4>
					<div style={{display: "flex", alignItems: "center", marginLeft: "15px"}}>
						<label style={{margin: "0px 10px 0px 0px"}} htmlFor="">INR</label>
						<input placeholder="Enter your value here..." type="number" style={this.state.saved ? (this.state.errorFields.PrescribedCSRExpenditureInput === "isNotEmpty" ? {margin: "0px 0px 0px 0px"} : {marginLeft: "0px 0px 0px 0px", border: "solid 1px #ef5a20"}):({margin: "0px 0px 0px 0px"})} value={this.state.PrescribedCSRExpenditureInput} onChange={this.reportInputHandler} name="PrescribedCSRExpenditureInput" className='reportInput ques4input' disabled={JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'} />
					</div>
				</div>
				<div style={{marginTop: "20px"}}>
					<h4 style={{textIndent: "-15px", marginLeft: "15px"}} className='briefOutlineHeading'>5. Details of CSR Spent during the financial year.</h4>
					<div className="inrDiv" style={{display: "flex", alignItems: "center", marginTop: "10px", marginLeft: "15px"}}>
						<h5 style={{margin: "0px 10px 0px 0px"}} htmlFor="">a. Total amount to be spent for the financial year: INR</h5>
						<input placeholder="Enter your value here..." type="number" style={this.state.saved ? (this.state.errorFields.amountSpentInput === "isNotEmpty" ? {margin: "0px 0px 0px 0px"} : {marginLeft: "0px 0px 0px 0px", border: "solid 1px #ef5a20"}):({margin: "0px 0px 0px 0px"})} value={this.state.amountSpentInput} onChange={this.reportInputHandler} name="amountSpentInput" className='reportInput' disabled={JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'} />
					</div>
					<div className="inrDiv" style={{display: "flex", alignItems: "center", marginTop: "10px", marginLeft: "15px"}}>
						<h5 style={{margin: "0px 10px 0px 0px"}} htmlFor="">b. Amount unspent, if any: INR</h5>
						<input placeholder="Enter your value here..." type="number" style={this.state.saved ? (this.state.errorFields.amountUnspentInput === "isNotEmpty" && this.state.amountUnspentInput>this.state.amountSpentInput? {margin: "0px 0px 0px 0px"} : {marginLeft: "0px 0px 0px 0px", border: "solid 1px #ef5a20"}):({margin: "0px 0px 0px 0px"})} value={this.state.amountUnspentInput} onChange={this.reportInputHandler} name="amountUnspentInput" className='reportInput' disabled={JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'} />
						{this.state.saved ? (parseInt(this.state.amountUnspentInput)>parseInt(this.state.amountSpentInput) ? <p style={{color: "#ef5a20", margin:"0px 0px 10px 0px"}}>Please provide required data, amount should be less</p> : null): (null)}
						
					</div>
					<div className="inrDiv" style={{display: "flex", alignItems: "flex-start", flexDirection:"column", marginTop: "10px", marginLeft: "15px"}}>
						<h5 style={{margin: "0px 0px 10px 0px"}} htmlFor="">c. Manner in which the amount spent for the financial year is detailed below:</h5>
						{this.state.saved ? (this.state.errorFields.financialYearAmountSpent !== "isNotEmpty" ? <p style={{color: "#ef5a20", margin:"0px 0px 10px 0px"}}>Please provide required data, this table can not be empty!</p> : null): (null)}
						<div className='csr-details-table-div-scrollable'>
						{/*<button className="reportRefreshData reportActionButtons mr10" style={{float:"right",marginLeft:"-50% important"}} onClick={this.refreshData}>Refresh Data</button>*/}
							<BootstrapTable	data={this.state.financialYearAmountSpent} id="csr-details-table" className='csr-details-table' striped={true} ref='table' insertRow={JSON.parse(readCookie('userData')).authorizedFor.mis !== 'view'} options={this.state.options2} cellEdit={cellEditProp} deleteRow={JSON.parse(readCookie('userData')).authorizedFor.mis !== 'view'} selectRow={selectRowProp}>
								<TableHeaderColumn dataField='partnerName' editable={{type: 'number'}} isKey={true}>Sr.No.</TableHeaderColumn>
								<TableHeaderColumn dataField="partnerName1" editable={{type: 'textarea', validator: this.financialYear1}} headerAlign='center' dataAlign='center'>CSR Project or activity identified</TableHeaderColumn>
								<TableHeaderColumn dataField="partnerName2" editable={{type: 'textarea', validator: this.financialYear2}} headerAlign='center' dataAlign='center'>Sector in which the project is covered</TableHeaderColumn>
								<TableHeaderColumn dataField="partnerName3" editable={{type: 'input', validator: this.financialYear3}} headerAlign='center' dataAlign='center'>Projects or Programs (1) Local Area or Other (2) Specify the State and district where projects or programs was undertaken
								</TableHeaderColumn>
								<TableHeaderColumn dataField="partnerName4" editable={{type: 'number', validator: this.financialYear4}} headerAlign='center' dataAlign='center'>Amount Outlay (Budget) project or Program wise** (&#8377; In Lakhs)
								</TableHeaderColumn>
								<TableHeaderColumn dataField="partnerName5" editable={{type: 'number', validator: this.financialYear5}} headerAlign='center' dataAlign='center'>Amount spent on the Projects or programs (Sub heads):* (1) Direct Expenditure on projects or programs (2) Overheads (&#8377; In Lakhs)
								</TableHeaderColumn>
								<TableHeaderColumn dataField="partnerName6" editable={{type: 'number', validator: this.financialYea6}} headerAlign='center' dataAlign='center'>Cumulative expenditure upto the reporting period (&#8377; in Lakhs)
								</TableHeaderColumn>
								<TableHeaderColumn dataField="partnerName7" editable={{type: 'select', options :{values: this.state.via},validator: this.financialYear7}} headerAlign='center' dataAlign='center'>Amount Spent: Direct or through implementing agency
								</TableHeaderColumn>
							</BootstrapTable>
						</div>
					</div>
				</div>
				<div style={{display: "flex", alignItems: "flex-start", flexDirection:"column", marginTop: "20px"}}>
					<h4 style={{textIndent: "-15px", marginLeft: "15px", lineHeight: "1.2"}} htmlFor="">6. In case the Company has failed to spend the two percent of the average net profit of the last three financial years or any part thereof, the company shall provide the reasons for not spending the amount in its Board’s Report.</h4>
					<textarea style={this.state.saved ? (this.state.errorFields.financialYearsInput === "isNotEmpty" ? {marginLeft: "15px"} : {marginLeft: "15px", border: "solid 1px #ef5a20"}):({marginLeft: "15px"})} value={this.state.financialYearsInput} name="financialYearsInput" onChange={this.reportInputHandler} placeholder='Enter your text here...' className="financialYearsInput textArea" cols="80" rows="8" disabled={JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'}></textarea>
				</div>
				<div style={{display: "flex", alignItems: "flex-start", flexDirection:"column", marginTop: "20px"}}>
					<h4 style={{textIndent: "-15px", marginLeft: "15px", lineHeight: "1.2"}} htmlFor="">7. A responsibility statement of the CSR committee that the implementation and monitoring of CSR policy, is in compliance with CSR objectives and Policy of the Company. <a href="https://www.tcil-india.com/public/pdf/CSR_annual%20report_2015-16.pdf" target="_blank">(Here is a sample example).</a></h4>
					<textarea style={this.state.saved ? (this.state.errorFields.responsibilityStatementInput === "isNotEmpty" ? {marginLeft: "15px"} : {marginLeft: "15px", border: "solid 1px #ef5a20"} ) : ( {marginLeft: "15px"} )} value={this.state.responsibilityStatementInput} name="responsibilityStatementInput" onChange={this.reportInputHandler} placeholder='Enter your text here...' className="responsibilityStatementInput textArea" cols="80" rows="8" disabled={JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'}></textarea>
				</div>
				<div className='reportButtonsContainer' style={{marginTop: "30px", display:"flex", justifyContent:"center"}}>
					{JSON.parse(readCookie('userData')).authorizedFor.mis !== 'view' ?(
						<button className="reportSaveData reportActionButtons" onClick={this.saveReport}>Save</button>
					):(null) }
					<button className="reportGenerateData reportActionButtons ml10" onClick={this.generateDisclosure}>Generate Disclosure</button>
				</div>
			</div>
		)
	}
}

export default DirectorsReport;