import React, { Component } from "react";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";

import Loader from "../Loader";
import PartnerProjects from "./PartnerProjects";
import Invitations from "./Invitations";

const checkValue = require("../../../checkValue.js").checkValue;
const readCookie = require("../../../cookie.js").readCookie;

export default class MISPartnerProjects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      key: 1,
    };
  }

  handleSelect(key) {
    this.setState({ key });
  }

  render() {
    return (
      <>
        <div className="mis-content-container mis-partner-projects-content-container">
          {this.state.showLoader ? (
            <Loader loaderHiding={this.state.loaderHiding} />
          ) : null}
          <div
            style={{
              height: "100px",
              position: "fixed",
              background: "white",
              marginTop: "-30px",
              paddingTop: "27px",
              width: "71.2%",
              left: "313px",
              zIndex: " 999",
              position: "fixed",
              background: "#fff",
              color: "orange",
              fontSize:"15px",
              fontWeight:700
            }}
          >
           Note: To invite partner organizations to submit project proposals, 
            please select from the list that appears when you start typing. 
            The partner will get an invite to become a licensed user of the platform.
            Once the partner accepts your invitation, you may click on SOLICIT PROJECT 
            to seek their proposal. Proposals submitted by partners against your invitation 
            will show in PROJECT PIPELINE.
          </div>
          {/* height: 100px;
    /* border: 1px solid black; 
    // position: fixed;
    // background: rgb(255, 255, 255);
    // margin-top: -30px;
    // padding-top: 27px;
    // width: 71.2%;
    // left: 312px;
    // z-index: 999;
    // color: orange; */}
          <Col md={12} className="partner-projects-container">
            {/* <Tabs activeKey={this.state.key} onSelect={this.handleSelect.bind(this)} id="partner-projects-tabs"> */}
            <div id="partner-projects-tabs">
              {/* <Tab eventKey={1} title="Projects By Partners">
							<PartnerProjects />
						</Tab> */}
              {/* <Tab eventKey={2} title="Invitations"> */}
              <Invitations />
              {/* </Tab> */}
            </div>
          </Col>
        </div>
      </>
    );
  }
}
