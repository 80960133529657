import React, { Component } from 'react';
import moment from 'moment';
import { Menu } from 'antd';
import ReactGA from 'react-ga';
import Scroll from 'react-scroll';
import TimeAgo from 'react-timeago';
import { Row, Col } from 'react-bootstrap';
import { ToastContainer, toast } from "react-toastify";

const checkValue = require('../../../../checkValue.js').checkValue;
const readCookie = require('../../../../cookie.js').readCookie;

let Link = Scroll.Link;

export default class SingleMISProjectOrganizer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			projectId: null,
			current: 'organizer',
			selectedProject: null,
			selectedProjectEmails: [],
			selectedProjectConversation: null,
			showProjectsViewType: 'single-project'
		}
	}

	componentDidMount() {
		if (this.props.match.params.name) {
			let path = this.props.match.params.name;
			fetch(process.env.REACT_APP_API_URL + '/get-project/' + path, {
				method: "GET",
				headers: {
					'Auth': JSON.parse(readCookie('access_token')).access_token
				}
			}).then((data) => data.json())
			.then(function (data) {
				if (data.status === 'ok') {
					this.setState({ ngoId: data.data.ngoData.id, projectId: data.data.id, selectedProject: data.data });
					let projectId = data.data.id;

					fetch(process.env.REACT_APP_API_URL + '/fetch-project-emails/' + projectId, {
						method: "GET",
						headers: {
							'Auth': JSON.parse(readCookie('access_token')).access_token
						}
					}).then((data) => data.json())
					.then((data) => {
						if(data.status === 'ok') {
							this.setState({ selectedProjectEmails: data.emails, showProjectsViewType: 'single-project' });
						}
					});
				}
			}.bind(this));
		} else {
			window.location = "/404";
		}
	}

	handleClick(key) {
		ReactGA.event({
		  category: 'View Project',
		  action: 'view',
		  label: key.charAt(0).toUpperCase() + key.slice(1)
		});
		if(this.state.selectedProject.hasOwnProperty('customUrl') && this.state.selectedProject.customUrl) window.location.href = window.location.origin + "/mis-projects/" + this.state.selectedProject.customUrl + "#" + key;
		else window.location.href = window.location.origin + "/mis-projects/" + this.state.projectId + "#" + key;
	}

	gotoResources() {
		ReactGA.event({
		  category: 'View Project',
		  action: 'view',
		  label: 'Resources'
		});
		if(this.state.selectedProject.hasOwnProperty('customUrl') && this.state.selectedProject.customUrl) window.location.pathname = "/mis-projects/" + this.state.selectedProject.customUrl + "/resources";
		else window.location.pathname = "/mis-projects/" + this.state.projectId + "/resources";
	}

	toggleConversationProperties(name, index) {
		let conversations = this.state.selectedProjectEmails,
			updateObj = {};

		updateObj[name] = !conversations[index][name];

		if(name === 'starred' || name === 'important') {
			fetch(process.env.REACT_APP_API_URL + '/fetch-email/' + conversations[index].id, {
				method: "POST",
				headers: {
					'Content-Type': 'application/json',
					'Auth': JSON.parse(readCookie('access_token')).access_token
				},
				body: JSON.stringify(updateObj)
			}).then((data) => data.json())
			.then((data) => {
				if(data.status === 'ok') {
					conversations[index][name] = !conversations[index][name];
					this.setState({ selectedProjectEmails: conversations });
				} else {
					this.notifyError("Failed to update conversation!")
				}
			});
		} else {
			conversations[index][name] = !conversations[index][name];
			this.setState({ selectedProjectEmails: conversations });
		}
	}

	openProjectConversation(index) {
		let selectedProjectConversation = this.state.selectedProjectEmails[index];
		fetch(process.env.REACT_APP_API_URL + '/fetch-email/' + selectedProjectConversation.id, {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
		.then((data) => {
			if(data.status === 'ok') {
				this.setState({ selectedProjectConversation: data.email, showProjectsViewType: 'single-project-conversation' });
			}
		});
	}

	backToProjectConversations() {
		this.setState({ selectedProjectConversation: null, showProjectsViewType: 'single-project' });
	}

	render() {
		if(this.state.selectedProject && this.state.selectedProject.hasOwnProperty('title')) {
			return (
				<div className="mis-content-container single-csr-project-container">
					<div className="mis-fixed-top-container" id="single-csr-project-fixed-top">
						<div>
							<Menu selectedKeys={[this.state.current]} mode="horizontal">
								<Menu.Item key="overview">
									<Link onClick={this.handleClick.bind(this, 'overview')}>Overview</Link>
								</Menu.Item>
								<Menu.Item key="description">
									<Link onClick={this.handleClick.bind(this, 'description')}>Description</Link>
								</Menu.Item>
								{(this.state.selectedProject.status === 'Completed' || this.state.selectedProject.status === 'Funded') || this.state.amountBreakup !== null || this.state.releasedAmountBreakup.length ? (
									<Menu.Item key="finances">
										<Link onClick={this.handleClick.bind(this, 'finances')}>Finances</Link>
									</Menu.Item>
								) : (null)}
								{!checkValue(this.state.kpiData) || !checkValue(this.state.beneficiaries) ? (
									<Menu.Item key="impact-monitoring">
										<Link onClick={this.handleClick.bind(this, 'impact-monitoring')}>Impact Monitoring</Link>
									</Menu.Item>
								) : (null)}
								{!checkValue(this.state.milestones) ? (
									<Menu.Item key="milestones">
										<Link onClick={this.handleClick.bind(this, 'milestones')}>Milestones</Link>
									</Menu.Item>
								) : (null)}
								<Menu.Item key='resources'>
									<Link onClick={this.gotoResources.bind(this)}><span>Resources</span></Link>
								</Menu.Item>
								{this.state.selectedProject.projectInterestCode ? (
									<Menu.Item key='organizer'>
										<Link><span>Organizer</span></Link>
									</Menu.Item>
								) : (null)}
							</Menu>
						</div>
					</div>

					<Row className="back-container" id="overview">
						<a href={"/discover/projects"} className="back">
							<i className="fa fa-chevron-left"></i>
						</a>
						<span className="project-title">{' ' + this.state.selectedProject.title}</span>
					</Row>

					<Row className="organizer-container">
						{this.state.showProjectsViewType === 'single-project' ? (
							<div className="all-conversations-container">
								<div className="all-conversations-container-header">
									<span className="all-conversations-title">
										All conversations
									</span>
									<span className="all-conversations-count">
										{this.state.selectedProjectEmails.length === 0 ? (
											'0 of 0'
										) : (
											<span>{'1 - ' + this.state.selectedProjectEmails.length} of {this.state.selectedProjectEmails.length.toLocaleString()}</span>
										)}
									</span>
								</div>
								<div className="all-conversations">
									{this.state.selectedProjectEmails.length ? (
										this.state.selectedProjectEmails.map(function(conversation, index) {
											return (
												<div className="conversation-container" key={index}>
													<div className="conversation-selector-container">
														<input id={"conversation-selector-" + index} className="conversation-selector" type="checkbox"
															checked={conversation.selected} onChange={this.toggleConversationProperties.bind(this, 'selected', index)} />
														<label htmlFor={"conversation-selector-" + index}></label>
													</div>
													<i className={conversation.starred ? "fa fa-star conversation-star" : "fa fa-star-o conversation-star"}
														onClick={this.toggleConversationProperties.bind(this, 'starred', index)}></i>
													<div className={conversation.important ? "conversation-important selected" : "conversation-important"}
														onClick={this.toggleConversationProperties.bind(this, 'important', index)}></div>
													<div className="conversation-title" onClick={this.openProjectConversation.bind(this, index)}>
														<span className="conversation-title-span">
															<span className="conversation-subject">{conversation.subject}</span>
															<span className="conversation-messages-count">{' - (Contains ' + conversation.messagesCount + ' messages)'}</span>
														</span>
													</div>
													{conversation.hasAttachment ? (
														<i className="fa fa-paperclip"></i>
													) : (null)}
													{moment(conversation.updatedAt.split(' ')[0] + '00:00', 'DD/MM/YYYY HH:mm').diff(moment(), 'days') < 0 ? (
														<div className="conversation-last-updated">{moment(conversation.updatedAt, 'DD/MM/YYYY HH:mm').format('MMM DD')}</div>
													) : (
														<div className="conversation-last-updated">{moment(conversation.updatedAt, 'DD/MM/YYYY HH:mm').format('hh:mm A')}</div>
													)}
												</div>
											)
										}.bind(this))
									) : (
										this.state.search === undefined || this.state.search === null ? (
											<div className="no-conversations">
												{this.state.loading ? (
													'Loading...'
												) : (
													'No emails were found.'
												)}
											</div>
										) : (null)
									)}
								</div>
							</div>
						) : (null)}

						{this.state.showProjectsViewType === 'single-project-conversation' && this.state.selectedProjectConversation !== null ? (
							<div className="single-conversation-container">
								<div className="single-conversation-container-header">
									<i className="fa fa-arrow-left back-to-all-conversations-arrow" onClick={this.backToProjectConversations.bind(this)}></i>
									<span className="single-conversation-title">
										<span className="conversation-project-title">{`[${this.state.selectedProjectConversation.project.name}] `}</span>
										<span className="conversation-subject">{this.state.selectedProjectConversation.subject}</span>
									</span>
								</div>
								<div className="single-conversation-messages-container">
									{this.state.selectedProjectConversation.messages.length ? (
										this.state.selectedProjectConversation.messages.map(function(message, index) {
											return (
												<div className="conversation-container" key={index}>
													<div className="sender-container">
														{message.hasOwnProperty('sender') && message.sender ? (
															<i className={message.sender.type + " fa fa-user"}></i>
														) : (
															<i className="fa fa-user"></i>
														)}
													</div>
													<div className="message-container">
														<div className="message-top-container">
															{message.from.address === JSON.parse(readCookie('userData')).email ? (
																<span className="sender-name-email">{'me'}</span>
															) : (
																message.from.name ? (
																	<span className="sender-name-email">{message.from.name}</span>
																) : (
																	<span className="sender-name-email">{message.from.address}</span>
																)
															)}
															<span className="received-at">
																{moment(message.receivedDate.split(' ')[0] + '00:00', 'DD/MM/YYYY HH:mm').diff(moment(), 'days') < 0 ? (
																	moment(message.receivedDate, 'DD/MM/YYYY HH:mm').format('MMM DD') + " ("
																) : (
																	moment(message.receivedDate, 'DD/MM/YYYY HH:mm').format('hh:mm A') + " ("
																)}
																<TimeAgo style={{ fontSize: 12 }} date={moment(message.receivedDate, 'DD/MM/YYYY HH:mm').toDate()} formatter={this.formatter} />
																{")"}
															</span>
														</div>
														{message.to && message.to.length ? (
															<div className="message-to-container">
																{'to'}
																{message.to.map(function(user, index) {
																	if(user.address === JSON.parse(readCookie('userData')).email) {
																		if(index === message.to.length - 1) return ' me';
																		else return ' me,';
																	}	else if(user.name) {
																		if(index === message.to.length - 1) return ` ${user.name}`;
																		else return ` ${user.name},`;
																	} else if(user.address) {
																		if(index === message.to.length - 1) return ` ${user.address}`;
																		else return ` ${user.address},`;
																	} else return null;
																})}
															</div>
														) : (null)}
														{message.html ? (
															<div className="message-bottom-container" dangerouslySetInnerHTML={{__html: message.html}}></div>
														) : (
															<div className="message-bottom-container">
																{message.text}
															</div>
														)}
														{this.state.selectedProjectConversation.hasAttachment && message.attachments && message.attachments.length ? (
															<div className="message-attachments">
																<span className="heading">Attachments :</span>
																{message.attachments.map(function(attachment, index) {
																	return (
																		<a className="message-attachment" href={attachment.url} target="_blank" key={index}>{attachment.name}</a>
																	)
																})}
															</div>
														) : (null)}
													</div>
												</div>
											)
										}.bind(this))
									) : (
										<div className="no-conversations">
											No messages were found.
										</div>
									)}
								</div>
							</div>
						) : (null)}
					</Row>
				</div>
			);
		} else {
			return null;
		}
	}
}