import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import $ from 'jquery';
import Multiselect from 'react-bootstrap-multiselect';
import { createFilter } from 'react-search-input';
import Slider from 'rc-slider';
import moment from 'moment';

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

const KEYS_TO_FILTERS = ['title'];

let filteredProjects = [];

const readCookie = require('../../../cookie.js').readCookie;

let year = moment().year();
const years = [];
for (var i = -3; i < 4; i++) {
	var yearStr = (year - i) + '-' + (year - i + 1);
	years.push(yearStr);
}

export default class DiscoverCSRProjectsFilter extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filters: null,
			goal: null,
			goalValue: [],
			investmentYear: [],
			optionId:[0,1,2,3,4,5,6],
			years: [],
			min: 0,
			max: this.props.max,
			status: 'all',
			projectType: 'both',
			searchTerm: this.props.searchTerm
		};
		this.getCurrentFiscalYear = this.getCurrentFiscalYear.bind(this);
		this.getCurrentYear = this.getCurrentYear.bind(this);
	}

	componentDidMount() {
		let dd=[];
			for(var i=0;i<this.state.optionId.length;i++){
			let child={};
			child['value']=i;
			child['label']=years[i];
			dd.push(child);
		}
		this.setState({ years: dd });
		if(this.props.defaultStatus === 'invested') this.updateStatus('invested');
		fetch(process.env.REACT_APP_API_URL + '/getOtAttributes', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
		.then(function (data) {
			this.setState({ filters: data.otAttributes });
			let filters = data.otAttributes;
			let goalData = [];
			let preferedGoals = { label: 'Prefered Goals' };
			let companyId = JSON.parse(readCookie('userData')).organisationData.id;
			fetch(process.env.REACT_APP_API_URL + '/company/' + companyId + '/mis/preferences', {
				method: "GET",
				headers: {
					'Auth': JSON.parse(readCookie('access_token')).access_token
				}
			}).then((data) => data.json())
			.then(function (data) {
				let children = [];
				let preferedGoalIds = [];
				for (var i = 0; i < data.data.goals.length; i++) {
					let child = {};
					child['value'] = data.data.goals[i].id;
					child['label'] = data.data.goals[i].name;
					children.push(child);
					preferedGoalIds.push(data.data.goals[i].id);
				}
				preferedGoals['children'] = children;
				preferedGoals['label'] = preferedGoals['label'] + ' (' + children.length + ')';

				let otherGoals = { label: 'Other Goals' };
				let otherChildren = [];
				for (let i = 0; i < filters.length; i++) {
					if (filters[i].type === 'goal') {
						if (preferedGoalIds.indexOf(filters[i]._id) === -1) {
							let goalObj = {};
							goalObj['value'] = filters[i]._id;
							goalObj['label'] = filters[i].name;
							otherChildren.push(goalObj);
						}
					}
				}
				otherGoals['children'] = otherChildren;
				otherGoals['label'] = otherGoals['label'] + ' (' + otherChildren.length + ')';
				goalData.push(preferedGoals);
				goalData.push(otherGoals);
				this.setState({ goal: goalData });
				
			}.bind(this));
		}.bind(this));
	}

	handleChange(name, value) {
		this.setState({ [name]: $(value).parents('select').val() });
	}

	handleChangeYear(name,value){
		let year=$(value).parents('select').val();
		for(var i=0;i<year.length;i++)
			year[i]=years[year[i]].split('-')[0];
		this.setState({investmentYear:year});
	}
    getCurrentFiscalYear() {
		var today = new Date();
		var curMonth = today.getMonth();
		var fiscalYr = "";
		if (curMonth > 3) {
		  return today.getFullYear().toString() + '-' + (today.getFullYear() + 1).toString();
		} else {
		  return (today.getFullYear() - 1).toString() + '-' + today.getFullYear().toString();
		}
	  }
	  getCurrentYear(){
		var today = new Date();
		var curMonth = today.getMonth();
		var fiscalYr = "";
		if (curMonth > 3) {
		  return today.getFullYear().toString() 
		} else {
		  return (today.getFullYear() - 1).toString() 		
		}
	  }

   




	resetFilters() {
		let goalValue = [];
		let params = {};
		let investmentYear=[];
		 
		this.setState({ goalValue: null,investmentYear: null }, function () {
			this.setState({ goalValue,investmentYear, min: 0, max: 0, projectType: 'both', searchTerm: '' },
			 function() {
				this.props.updateSearchTerm('');
			}.bind(this));
		});
        params['year'] = this.getCurrentYear();
		params['corporateInterestStatus'] = this.state.status;
		let esc = encodeURIComponent
		let query = Object.keys(params).map(function (k) {
			if (Array.isArray(params[k])) {
				let x = '';
				for (let i = 0; i < params[k].length; i++) {
					if (i === 0) x += esc(k) + '=' + esc(params[k][i]);
					else x += '&' + esc(k) + '=' + esc(params[k][i])
				}
				return x;
			} else return esc(k) + '=' + esc(params[k])
		}).join('&')
		let companyId = JSON.parse(readCookie('userData')).organisationData.id;
		fetch(process.env.REACT_APP_API_URL + '/project/recommendations/' + companyId + '?' + query, {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
		.then(function (data) {
			this.props.updateProjectTypeBreadcrumb('totalProjectsCount', data.projectInterestStatus['all']);
			this.props.updateProjectTypeBreadcrumb('interestedProjectsCount', data.projectInterestStatus['interested']);
			this.props.updateProjectTypeBreadcrumb('underReviewProjectsCount', data.projectInterestStatus['under-review']);
			this.props.updateProjectTypeBreadcrumb('investedProjectsCount', data.projectInterestStatus['invested']);
			this.props.updateProjectTypeBreadcrumb('skippedProjectsCount', data.projectInterestStatus['skipped']);
			this.props.updateFilteredProjects(data.data);
		}.bind(this));
	}

	applyFilters() {
		let params = {};
		if(this.state.goalValue && typeof this.state.goalValue === 'object') {
			if(Array.isArray(this.state.goalValue)) {
				if(this.state.goalValue.length) {
					params['goalId'] = this.state.goalValue;
					// this.state.goalValue.map(function (val) {
					// 	params['goalId'].push(val);
					// })
				}
			}
		}
		if(this.state.investmentYear.length >0){
			if(Array.isArray(this.state.investmentYear)){
				if(this.state.investmentYear.length){
					params['year']=this.state.investmentYear;
				}
			}
		}else {

			params['year'] = this.getCurrentYear();
		
		}
		
             
		params['minBudget'] = this.state.min;
		params['maxBudget'] = this.state.max;
		// params['status'] = this.state.status;
		// params['projectCategory'] = this.state.projectType;
		params['corporateInterestStatus'] = this.state.status;
		params['query'] = this.props.searchTerm;
    

		let esc = encodeURIComponent
		let query = Object.keys(params).map(function (k) {
			if (Array.isArray(params[k])) {
				let x = '';
				for (let i = 0; i < params[k].length; i++) {
					if (i === 0) x += esc(k) + '=' + esc(params[k][i]);
					else x += '&' + esc(k) + '=' + esc(params[k][i])
				}
				return x;
			} else return esc(k) + '=' + esc(params[k])
		}).join('&')
		let companyId = JSON.parse(readCookie('userData')).organisationData.id;
	
		let url = process.env.REACT_APP_API_URL + '/project/recommendations/' + companyId + '?' + query
		fetch(url, {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
		.then(function (data) {
			this.props.updateProjectTypeBreadcrumb('totalProjectsCount', data.projectInterestStatus['all']);
			this.props.updateProjectTypeBreadcrumb('interestedProjectsCount', data.projectInterestStatus['interested']);
			this.props.updateProjectTypeBreadcrumb('underReviewProjectsCount', data.projectInterestStatus['under-review']);
			this.props.updateProjectTypeBreadcrumb('investedProjectsCount', data.projectInterestStatus['invested']);
			this.props.updateProjectTypeBreadcrumb('skippedProjectsCount', data.projectInterestStatus['skipped']);
			this.props.updateFilteredProjects(data.data);
		}.bind(this));
	}

	showButtonText(options, select) {
		if (options.length === 0) {
			return  'Default (All)'
			
		} else if (options.length > 2) {
			return options.length + ' options selected!';
		} else {
			let labels = [];
			options.each(function () {
				if ($(this).attr('label') !== undefined) {
					labels.push($(this).attr('label'));
				} else {
					labels.push($(this).html());
				}
			});
			return labels.join(', ') + '';
		}
	}

	YearButtonText(options,select){
		if (options.length === 0) {
			return  this.getCurrentFiscalYear();
			
		} else if (options.length > 2) {
			return options.length + ' options selected!';
		} else {
			let labels = [];
			options.each(function () {
				if ($(this).attr('label') !== undefined) {
					labels.push($(this).attr('label'));
				} else {
					labels.push($(this).html());
				}
			});
			return labels.join(', ') + '';
		}
	}

	

	updateSearchTerm(e) {
		let value = e.target.value;
		this.props.updateSearchTerm(value);
		this.setState({ searchTerm: value }, function () {
			if (value === '') this.searchUpdated();
		}.bind(this));
	}

	searchUpdated() {
		this.props.updateSearchTerm(this.props.searchTerm);
		this.props.updateFilteredProjects(filteredProjects);
	}

	log(val) {
		this.updateMinMax('minmax', val);
	}

	percentFormatter(v) {
		return `INR ${v}`;
	}

	updateMinMax(name, val) {
		if (name === 'minmax') {
			this.setState({ min: val[0], max: val[1] });
		} else if (name === 'min' || name === 'max') {
			let value;
			if (val.target.value === '') value = 0;
			else value = val.target.value;
			this.setState({ [name]: parseInt(value) });
		}
	}

	toggleShortListed() {
		let companyId = JSON.parse(readCookie('userData')).organisationData.id;
		fetch(process.env.REACT_APP_API_URL + '/shortlist/projects/company/' + companyId, {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
			.then(function (data) {
				this.props.toggleShortListed();
				this.props.updateShortlistedProjects(data.data);
			}.bind(this));
	}

	updateStatus = (status) => {
		this.setState({ status });
		this.changeProjectWithStatus(status);
	}

	changeProjectWithStatus = (value) => {
		let params = {};
		if(this.state.goalValue && typeof this.state.goalValue === 'object') {
			if(Array.isArray(this.state.goalValue)) {
				if(this.state.goalValue.length) {
					params['goalId'] = this.state.goalValue;
					// this.state.goalValue.map(function (val) {
					// 	params['goalId'].push(val);
					// })
				}
			}
		}
		if(this.state.investmentYear.length >0){
			if(Array.isArray(this.state.investmentYear)){
				if(this.state.investmentYear.length){
					params['year']=this.state.investmentYear;
				}
			}
		}else {

			params['year'] = this.getCurrentYear();
		
		}
		params['minBudget'] = this.state.min;
		params['maxBudget'] = this.state.max;
		params['corporateInterestStatus'] = value;
		// params['projectCategory'] = this.state.projectType;
		params['query'] = this.props.searchTerm;
	

		let esc = encodeURIComponent
		let query = Object.keys(params).map(function (k) {
			if (Array.isArray(params[k])) {
				let x = '';
				for (let i = 0; i < params[k].length; i++) {
					if (i === 0) x += esc(k) + '=' + esc(params[k][i]);
					else x += '&' + esc(k) + '=' + esc(params[k][i])
				}
				return x;
			} else return esc(k) + '=' + esc(params[k])
		}).join('&')

		let companyId = JSON.parse(readCookie('userData')).organisationData.id;
		let url = process.env.REACT_APP_API_URL + '/project/recommendations/' + companyId + '?' + query
		fetch(url, {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
		.then(function (data) {
			this.props.updateFilteredProjects(data.data);
		}.bind(this));
		this.setState({ status: value });
	}

	handleProjectTypeChange(value) {
		let params = {};
		if(this.state.goalValue && typeof this.state.goalValue === 'object') {
			if(Array.isArray(this.state.goalValue)) {
				if(this.state.goalValue.length) {
					params['goalId'] = this.state.goalValue;
					// this.state.goalValue.map(function (val) {
					// 	params['goalId'].push(val);
					// })
				}
			}
		}
		params['minBudget'] = this.state.min;
		params['maxBudget'] = this.state.max;
		params['corporateInterestStatus'] = value;

		let esc = encodeURIComponent
		let query = Object.keys(params).map(function (k) {
			if (Array.isArray(params[k])) {
				let x = '';
				for (let i = 0; i < params[k].length; i++) {
					if (i === 0) x += esc(k) + '=' + esc(params[k][i]);
					else x += '&' + esc(k) + '=' + esc(params[k][i])
				}
				return x;
			} else return esc(k) + '=' + esc(params[k])
		}).join('&')

		let companyId = JSON.parse(readCookie('userData')).organisationData.id;
		let url = process.env.REACT_APP_API_URL + '/project/recommendations/' + companyId + '?' + query
		fetch(url, {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
			.then(function (data) {
				this.props.updateFilteredProjects(data.data);
			}.bind(this));
		this.setState({ projectType: value, status: value });
	}

	changeProjectType = (e) => {
		this.setState({
			projectType: e.target.value
		})
	}

	render() {
		filteredProjects = this.props.projects.filter(createFilter(this.props.searchTerm, KEYS_TO_FILTERS))
		return (
			<div className="mis-projects-filter-container">
			<div className="second-filter-row" 
				style={{border :"1px solid transparent"}}
				>
					<Col md={2} style={{ paddingLeft: "0" }}>
						<span>Goals</span>
						{this.state.goalValue !== null ? (
							<Multiselect id="csr-project-goals" 
							defaultValue={this.state.goalValue} 
							onChange={this.handleChange.bind(this, 'goalValue')} 
							buttonWidth={'100%'} 
							buttonText={this.showButtonText.bind(this)} 
							data={this.state.goal} multiple />
						) : (null)}
					</Col>
					<Col md={2} style={{ paddingLeft: "0" }}>
						<span>Investment Year</span>
						{this.state.investmentYear !== null ? (<>
							<Multiselect id="csr-project-goals" 
							data={this.state.years}
							 
							onChange={this.handleChangeYear.bind(this,'years')} 
							buttonWidth={'100%'} 
							buttonText={this.YearButtonText.bind(this)} multiple/>
							</>
							) : (null)}
					</Col>
					<Col md={4} style={{ marginLeft: '-10px' }}>
						<span>Budget Range</span>
						{this.state.min !== null && this.state.max !== null ? (
							<div>
								<div className="csr-projects-minmax-budget-container">
									<b>{'Min : '}</b><input className="csr-projects-minmax-budget" type="number" placeholder="Min" onChange={this.updateMinMax.bind(this, 'min')} value={this.state.min} />
									<b>{'Max : '}</b><input className="csr-projects-minmax-budget" type="number" placeholder="Max" onChange={this.updateMinMax.bind(this, 'max')} value={this.state.max} />
								</div>
							</div>
						) : (null)}
					</Col>
					{/*<Col md={2} style={{ paddingRight: "0", marginLeft: '-60px' }}>
						<span>Project type</span>
						<select name="projectType" id="project-type" onChange={this.changeProjectType} value={this.state.projectType} style={{ borderRadius: "7px", width: "100%" }}>
							<option value="assured">LE-Assured Projects</option>
							<option value="partner">Partner Projects</option>
							<option value="both">All</option>
						</select>
					</Col>*/}
					<Col md={3} style={{ paddingRight: "0", marginLeft: '-45px', position: 'absolute', top: "75px", right: "150px" }}>
						<div className="search-container">
							<div className="csr-projects-search-filter-container">
								<input className="csr-projects-search-filter-input" type="search" placeholder="Search by Name" value={this.state.searchTerm} onChange={this.updateSearchTerm.bind(this)} style={{ width: '48%' }} />
								<button className="btn csr-projects-search-filter-icon" onClick={this.searchUpdated.bind(this)}><i className="fa fa-search"></i></button>
							</div>
						</div>
					</Col>
					<Col md={1} className="btn filter-reset-btn" onClick={this.resetFilters.bind(this)}>Reset</Col>
					<Col md={1} className="btn filter-apply-btn" onClick={this.applyFilters.bind(this)}>Apply</Col>

				</div>

				{this.props.projectTypeBreadcrumb !== false ? (
					<div className="first-filter-row" 
					style={{border:"1px solid transparent"}}
					>
						<Col md={12} className="pipeline-tags" style={{ padding: '0'}}>
							<div className="status-buttons-container" style={{ display: "flex"}}>
								<ol className="project-type-breadcrumb">
									
									{/* <li><a className={this.state.status === "all" ? "selected" : ""} onClick={this.updateStatus.bind(this, 'all')}>{'All (' + this.props.totalProjectsCount + ')'}</a></li */}
									<li><a className={this.state.status === "all" ? "selected" : ""} onClick={this.updateStatus.bind(this, 'all')}><span style={{ height: '18px', fontSize: "15px" }}>{this.props.totalProjectsCount}</span><span style={{ fontSize: '15px' }}>Open</span></a></li>
									{/* <li><a className={this.state.status === "interested" ? "selected" : ""} onClick={this.updateStatus.bind(this, 'interested')}>{'Interested (' + this.props.interestedProjectsCount + ')'}</a></li> */}
									<li><a className={this.state.status === "interested" ? "selected" : ""} onClick={this.updateStatus.bind(this, 'interested')}><span style={{ height: '15px', fontSize: "15px" }}>{this.props.interestedProjectsCount}</span><span style={{ fontSize: '15px' }}>Interested</span></a></li>
									{/* <li><a className={this.state.status === "under-review" ? "selected" : ""} onClick={this.updateStatus.bind(this, 'under-review')}>{'Under review (' + this.props.underReviewProjectsCount + ')'}</a></li> */}
									<li><a className={this.state.status === "under-review" ? "selected" : ""} onClick={this.updateStatus.bind(this, 'under-review')}><span style={{ height: '15px', fontSize: "15px" }}>{this.props.underReviewProjectsCount}</span><span style={{ fontSize: '15px' }}>Under review</span></a></li>
									{/* <li><a className={this.state.status === "invested" ? "selected" : ""} onClick={this.updateStatus.bind(this, 'invested')}>{'Invested (' + this.props.investedProjectsCount + ')'}</a></li> */}
									<li><a className={this.state.status === "invested" ? "selected" : ""} onClick={this.updateStatus.bind(this, 'invested')}><span style={{ height: '15px', fontSize: "15px" }}>{this.props.investedProjectsCount}</span><span style={{ fontSize: '15px' }}>Invested</span></a></li>
								</ol>
								{/* <button className={this.state.status === "skipped" ? "status-btn selected" : "status-btn"} onClick={this.updateStatus.bind(this, 'skipped')}>{'Skipped (' + this.props.skippedProjectsCount + ')'}</button> */}
								<button className={this.state.status === "skipped" ? "status-btn selected" : "status-btn"} onClick={this.updateStatus.bind(this, 'skipped')}><span style={{ height: '15px', fontSize: "15px" }}>{this.props.skippedProjectsCount}</span><span style={{ fontSize: '15px' }}>Declined / Not Available</span></button>
							</div>
						</Col>
					</div>
				) : (null)}

			</div>
		);
	}
}