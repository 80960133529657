import React,{ Component } from 'react';
import {OverlayTrigger, Tooltip, Col, Row} from 'react-bootstrap';
import moment from 'moment';
import $ from 'jquery';
import { LocaleProvider } from 'antd';
import enUS from 'antd/lib/locale-provider/en_US';
import {BootstrapTable, TableHeaderColumn, ButtonGroup} from 'react-bootstrap-table';
import Icon from 'react-icons-kit';
import { ic_add_circle_outline } from 'react-icons-kit/md/ic_add_circle_outline';
import { ic_delete } from 'react-icons-kit/md/ic_delete';
import { Menu } from 'antd';
import Scroll from 'react-scroll';

import AmountBreakupTable from './AmountBreakupTable';
import CostTable from './CostTable';

let Link = Scroll.Link;
const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;
const readCookie = require('../../../cookie.js').readCookie;

export default class MISSingleProjectInvestment extends Component {
	constructor(props) {
    super(props);
    this.state = {
    	portfolioId: null,
    	investmentId: null,
    	investment: null,
    	current: 'overview',
    	amountBreakup: null,
    	investedAmount: null,
    	utilizedAmount: null,
    	allocatedAmount: 0,
    	costAllocation: []
  	}
  }

  componentDidMount() {
  	let investmentId = this.props.match.params.investmentId;
  	this.setState({ investmentId });
  	let companyId = JSON.parse(readCookie('userData')).organisationData.id;
  	fetch(process.env.REACT_APP_API_URL + '/company/' + companyId + '/mis/investment/' + investmentId, {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
		.then(function(data) {
			let investment = data.data;
			let portfolioId = data.data.portfolioId;
			let amountBreakup = [];
			let allocatedAmount = data.data.allocatedAmount;
			let investedAmount = data.data.releasedAmount;

			for(var i = 0; i < investment.amountBreakup.length; i++) {
				let breakup = investment.amountBreakup[i];
				breakup.readOnly = true;
				breakup.costHead = investment.amountBreakup[i].costHead.name;
				amountBreakup.push(breakup);
			}
			let costAllocation = data.data.releasedAmountBreakup;
			if(amountBreakup.length) this.setState({ allocatedAmount, investedAmount, investment, amountBreakup, portfolioId, costAllocation });
			else this.setState({ allocatedAmount, investedAmount, investment, portfolioId, costAllocation });
		}.bind(this));
  }

  handleClick(key) {
  	this.setState({ current: key });
  }

	render() {
		if(this.state.investment !== null) {
			return (
				<Col md={12}>
					<div className="single-investment-nav">
						<Menu selectedKeys={[this.state.current]} mode="horizontal">
							<Menu.Item key="overview">
			          <Link to="overview" spy={true} smooth={true} offset={-100} duration={500} onClick={this.handleClick.bind(this, 'overview')}>Overview</Link>
			        </Menu.Item>
			        <Menu.Item key="description">
			          <Link to="description" spy={true} smooth={true} offset={-50} duration={800} onClick={this.handleClick.bind(this, 'description')}>Description</Link>
			        </Menu.Item>
			        <Menu.Item key="finances">
			          <Link to="finances" spy={true} smooth={true} offset={-50} duration={1500} onClick={this.handleClick.bind(this, 'finances')}>Finances</Link>
			        </Menu.Item>
		        	{(() => {
								if(this.state.investment.status === 0) {
									return (
		        				<Menu.Item key="edit">
						          <a href={"/edit-investment/" + this.state.portfolioId + "/" + this.state.investment.id + '/edit'}>Edit Investment</a>
						        </Menu.Item>
						      )
								} else if (this.state.investment.status === 1) {
									return (
		        				<Menu.Item key="edit">
						          <a href={"/edit-investment/" + this.state.portfolioId + "/" + this.state.investment.id + '/invest'}>Invest Funds</a>
						        </Menu.Item>
						      )
								}
							})()}
						</Menu>
					</div>
					<div className="mis-content-container">
						<Col md={12} className="single-investment-container">
							<Col md={12}>
								<Row className="back-container">
									<span onClick={() => {window.location.pathname = "/investments"}} className="back">
										<i className="fa fa-chevron-left"></i>
										{' GO TO ALL INVESTMENTS'}
									</span>
								</Row>
								<Row className="project-title">
									<Col md={12}>{this.state.investment.project.title}</Col>
								</Row>
							</Col>
							<Col md={12} id="overview" className="section">
								{this.state.investment.project.hasOwnProperty('image') ? (
									<Col md={6} className="image" style={{ backgroundImage: "url('" + this.state.investment.project.image + "')" }}>
									</Col>
								) : (
									<Col md={6} className="image" style={{ backgroundImage: "url('https://assets.letsendorse.com/sahaj/project-placeholder.jpg')" }}>
									</Col>
								)}
								<Col mdOffset={1} md={5}>
									<Col md={12} className="features">
										<div className="heading">Ngo:</div>
										<div className="content">{this.state.investment.ngo.name}</div>
									</Col>
									<Col md={12} className="features">
										<div className="heading">Agenda:</div>
										<div className="content">{this.state.investment.agenda.name}</div>
									</Col>
									<Col md={12} className="features">
										<div className="heading">Location:</div>
										<div className="content">{this.state.investment.project.location[0].address}</div>
									</Col>
									<Col md={12} className="features">
										<div className="heading">Expected Duration:</div>
										<div className="content">{this.state.investment.project.expectedDuration}</div>
									</Col>
									<Col md={12} className="features">
										<div className="heading">Beneficiaries:</div>
										<div className="content">
											{this.state.investment.project.targetBeneficiaries.map(function(targetBeneficiary, i) {
												if(this.state.investment.project.targetBeneficiaries.length - i === 1) return this.state.investment.project.targetBeneficiaries[i].name
												else return this.state.investment.project.targetBeneficiaries[i].name + ', '
											}.bind(this))}
										</div>
									</Col>
								</Col>
							</Col>
							<Col md={12} id="description" className="section">
								<h3 className="project-desc mb10 mt20">Description</h3>
								<div>{this.state.investment.project.description}</div>
								<h3 className="project-desc mb10 mt20">Expected Impact</h3>
								<div>{this.state.investment.project.expectedImpact}</div>
								<h3 className="project-desc mb10 mt20">KPIs</h3>
								{this.state.investment.hasOwnProperty('KPIs') ? (
									<ol>
										{this.state.investment.KPIs.map(function(kpi, index) {
											return <li key={kpi.id}>{kpi.name}</li>
										})}
									</ol>
								) : (null)}
							</Col>
							<Col md={12} id="finances" className="section">
								{this.state.amountBreakup !== null ? (
									<Col md={12} className="mb20">
										<h3 className="mb10 mt20">{'Allocated Amount (INR ' + this.state.allocatedAmount + ')'}</h3>
										<AmountBreakupTable amountBreakup={this.state.amountBreakup} />
									</Col>
								) : (null)}
								{this.state.investedAmount !== null && this.state.investedAmount !== 0 ? (
									<Col md={12} className="mb20">
										<h3 className="mb10 mt20">{'Invested Amount (INR ' + this.state.investedAmount + ')'}</h3>
										<CostTable costAllocation={this.state.costAllocation} />
									</Col>
								) : (null)}
								{this.state.utilizedAmount !== null ? (
									<Col md={12} className="mb20">
										<h3 className="mb10 mt20">Utilized Amount</h3>
									</Col>
								) : (null)}
							</Col>
						</Col>
					</div>
				</Col>
			);
		} else {
			return null;
		}
	}
}