import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn, ButtonGroup } from 'react-bootstrap-table';
import { Icon } from 'react-icons-kit'
import { Col, Row, Tab, Tabs, Modal } from 'react-bootstrap';
import {Input } from 'antd';
import { ToastContainer, toast } from 'react-toastify';
import Multiselect from 'react-bootstrap-multiselect';
import $ from 'jquery';

import InvitationsModal from './InvitationsModal';

const readCookie = require('../../../cookie.js').readCookie;

export default class Invitations extends Component {
	constructor(props) {
		super(props);
		this.state = {
			invitationsData: [],
			show: false,
			expand: false,
			filteredData: [],
			input: "",
			status: false,
			activeTab: 1,
			isStateSelected: false,
			selectlevel:"",
			selectType:"",
			selectCertificate:"",
			showSolicitForm: false,
			solicitSubject:"",
			solicitMessage:"",
			ngoId:"",
			isLoading: false,
			shareKpi: false,
			dontSharekpi: false,
			clusters: [],
			indicatorRows: [],
			selectedcols:[],
			kpiDropDownList:[],
			frequencyReport:"",
			clusterName:"",
			assessment:""
		}
		this.handleShow = this.handleShow.bind(this);
		this.handleClose = this.handleClose.bind(this);
	}

	notifySuccess = (text) => toast.success(text);

  notifyError = (text) => toast.error(text);

	componentWillMount() {
		let companyId = JSON.parse(readCookie('userData')).organisationData.id;
		fetch(process.env.REACT_APP_API_URL + '/company/' + companyId + '/mis/ngo/invite', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then(data => {
			return data.json();
		}).then(data => {
			if (data.status === 'ok') {
				let invitationsData = data.data;
				let filteredData = data.data;
				for (let i = 0; i < invitationsData.length; i++) {
					invitationsData[i]["expand"] = false;
				}
				this.setState({ invitationsData, filteredData, status:  true });
			}
		});

		fetch (process.env.REACT_APP_API_URL + "/kpi-category",{
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
		.then((data) => {
			let clusters = [];
			clusters = (data.data);
			this.setState({clusters});
		});

		let indicatorRows = [], kpiDropDownList = [];
		fetch (process.env.REACT_APP_API_URL + "/kpi-indicator",{
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
		.then((data) => {
			for (let i = 0; i < data.data.length; i++) {
				indicatorRows.push({
					id:data.data[i]["indicatorId"],
					outputOutcome:data.data[i]["type"],
					unit: data.data[i]["unit"],
					indicator:data.data[i]["name"],
					format: data.data[i]["format"],
					kpiId: data.data[i]["id"],
					mode: "view",
					editOutputOutcome: data.data[i]["type"],
					editUnit: data.data[i]["unit"],
					editIndicator:data.data[i]["name"],
					editFormat:data.data[i]["format"],
					formula: data.data[i]["formula"],
					editFormulaInput: data.data[i]["formula"],
					durationalCumulative: data.data[i]["computationType"],
					editDurationalCumulative: data.data[i]["computationType"]
				})
				kpiDropDownList.push({
					label: data.data[i]["name"],
					value: data.data[i]["id"]
				})
			}
			this.setState({indicatorRows, kpiDropDownList});
		});
	}

	onChange = (e) => {
		this.setState({[e.target.name] : e.target.value});
	}

	handleSubmit = () => {
		let data = {};
		if(this.state.solicitSubject !== "" && this.state.solicitMessage !== "" && this.state.ngoId !== ""){
			data["subject"] = this.state.solicitSubject;
			data["message"] = this.state.solicitMessage;;
			data["ngoId"] = this.state.ngoId;
			// if (this.state.sharekpi === true && (this.state.clusterName !== "" || this.state.selectedcols.length > 0)  && this.state.frequencyReport !== "") {
			// 	data["clusterId"] = this.state.clusterName;
			// 	data["indicators"] = this.state.selectedcols;
			// 	data["reportingFrequency"] = this.state.frequencyReport;
			// } else if (this.state.dontSharekpi === true && this.state.frequencyReport !== "" && this.state.assessment !== "") {
			// 	data["reportingFrequency"] = this.state.frequencyReport;
			// 	data["computationType"] = this.state.assessment;
			// } else {
			// 	console.log('it is here')
			// 	this.notifyError('Please provide mandatory Values');
			// 	return
			// }
			let companyId = JSON.parse(readCookie('userData')).organisationData.id;
			this.setState({isLoading: true})
			fetch(process.env.REACT_APP_API_URL + "/company/:" + companyId + "/mis/solicit-project",{
				method: "POST",
				headers:{
					'Content-type': 'application/json',
	        'Auth': JSON.parse(readCookie('access_token')).access_token
				},
				body: JSON.stringify(data)
			}).then((data) => data.json())
			.then((data) => {
				if (data.status === "ok") {
					this.notifySuccess('Success, request has been sent.');
					this.setState({isLoading: false, showSolicitForm: false, solicitSubject:"", solicitMessage:"", clusterName:"", selectedcols:[], frequencyReport:"", assessment:"", sharekpi: "", dontSharekpi: "true"});
				} else {
					this.notifyError(data.message);
					this.setState({isLoading: false,});
				}
			})
		} else {
			console.log('it is here ===')

			this.notifyError('Please provide mandatory Values');
		}
	}

	updateInvitations() {
		let companyId = JSON.parse(readCookie('userData')).organisationData.id;
		fetch(process.env.REACT_APP_API_URL + '/company/' + companyId + '/mis/ngo/invite', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then(data => {
			return data.json();
		}).then(data => {
			if (data.status === 'ok') {
				let invitationsData = data.data;
				this.setState({ invitationsData });
			}
		})
	}

	handleClose() {
		this.setState({ show: false });
	}

	handleShow() {
		this.setState({ show: true });
	}

	createCustomClearButton(onClick) {
		return (
			<span onClick={onClick}>
				<i className="clear-table-search fa fa-times"></i>
			</span>
		);
	}

	statusFormatter(cell, row) {
		if (cell === 'Done') {
			return (
				<span className="bold status-done">Done</span>
			)
		} else {
			return (
				<span className="bold status-not-done">{cell}</span>
			)
		}
	}

	expand = (i) => {
		let invitationsData = this.state.invitationsData;
		invitationsData[i].expand = !invitationsData[i].expand;
		this.setState({ invitationsData });
	}

	resetFilter = (e) => {
		if(e.target.name === "explore-ngo-filter-reset"){
			this.setState({selectlevel:"", selectType:"", selectCertificate:""});
		} else {
			let filteredData = this.state.invitationsData;
			this.setState({ input: "", filteredData });
		}
	}

	solicitProject = (id) => {
		this.setState({showSolicitForm: true, ngoId:id});
	}
	hideSolicitProject = (i) => {
		this.setState({isLoading: false, showSolicitForm: false, solicitSubject:"", solicitMessage:"", ngoId:"", clusterName:"", selectedcols:[], frequencyReport:"", assessment:"", sharekpi: false, dontSharekpi: ""});
	}

	searchBynames = (e) => {
		this.setState({ input: e.target.value.toLowerCase() });
		let input = e.target.value.toLowerCase();
		let filteredData = this.state.invitationsData;
		let filteredInvitationsData = filteredData.filter(function (filteredData) {
			filteredData = filteredData.name.toLowerCase();
			if (filteredData && filteredData.indexOf(input) > -1) {
				return filteredData
			}
		})
		filteredData = filteredInvitationsData;
		this.setState({ filteredData });
	}

	keyChange = (key) => {
		if(key !== this.state.activeTab) {
			this.setState({
				activeTab: key,
			});
		}
	}

	selectState = () => {
		this.setState({isStateSelected: true});
	}

	handleColChange = (e) => {
		this.setState({ selectedcols: $('select#colsTo').val()})
  }

  sharekpi = (e) => {
		if (e.target.name === "sharekpi") {
			this.setState({sharekpi: true, dontSharekpi: false, assessment:"", frequencyReport:""});
		} else {
			this.setState({dontSharekpi: true, sharekpi: false, clusterName:"", selectedcols:[], frequencyReport:""});
		}
	}

	frequencyReport = (e) => {
  	this.setState({[e.target.name]: e.target.value});
  }

	render() {
		let userData = JSON.parse(readCookie("userData"));
		return (
			this.state.status === true ? (
				this.state.invitationsData.length ?
					(
						<Tabs activeKey={this.state.activeTab} onSelect={this.keyChange.bind(this)} id="invitation-tabs-container">
							<ToastContainer position="bottom-center" autoClose={4000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={true} />
						  <Tab eventKey={1} title="Your Partners">
						  <Modal className="mis-content-container invitations-modal" style={{width:"70%", margin: "0 auto"}} show={this.state.showSolicitForm} onHide={this.hideSolicitProject} bsSize="large">
	   		 				<Modal.Header>
		   						<Modal.Title className="modal-header-custom">
		   							Solicit Project from Partner NGO
									</Modal.Title>
								</Modal.Header>
								<Modal.Body className='invitations-modal-main-container'>
									<Row>
		                <Col md={5} className="invitations-modal-select-section form-group m0">
		                  <label className="invitations-modal-ngosug-heading control-label is-imp">Subject</label>
		                  <input type="text" name='solicitSubject' placeholder="Enter Subject" style={{ width: 'calc(100% - 40px)' }} value={this.state.solicitSubject} className="invitations-modal-poc-name form-control" onChange={this.onChange} />
		                </Col>
									</Row>
									<Row>
										<Col md={12} className="invitations-modal-note-section form-group">
		                  <label className="invitations-modal-note-heading control-label is-imp">Message</label>
		                  <textarea type="text" placeholder="Mention the specific project you want to solicit from the partner" value={this.state.solicitMessage} name='solicitMessage' className="invitations-modal-note-text form-control" onChange={this.onChange}></textarea>
		                  {/*this.state.error.indexOf('note') > -1 ? (
		                    <div className="invitations-modal-note-error" style={{color: 'red'}}>This field cannot be empty</div>
		                  ) : (null)*/}
		                </Col>
									</Row>
									<Row>
		                {/* <Col md={12} className="invitations-modal-select-section form-group m0">
			     						<label style={{marginBottom:"0px"}} htmlFor="">Do you want to share KPIs with your partner?</label>
			     						<div style={{display: "flex", alignItems:"center"}}>
			     							<div style={{display:"flex", alignItems:"center"}}>
				     							<label style={{marginBottom:"0px"}} htmlFor="">Yes</label>
			                		<input type="radio" style={{margin: "0px 0px 0px 5px"}} checked={this.state.sharekpi === true ? true : false} name='sharekpi' onChange={this.sharekpi}/>
				     						</div>
				     						<div style={{display:"flex", alignItems:"center", marginLeft:"10px"}}>
				     							<label style={{marginBottom:"0px"}} htmlFor="">No</label>
			                		<input type="radio" style={{margin: "0px 0px 0px 5px"}} checked={this.state.dontSharekpi === true ? true : false} name='dontShareKpi' onChange={this.sharekpi}/>
				     						</div>
			     						</div>
				     						<div style={ this.state.sharekpi === true ? {display:"flex", alignItems:"center", justifyContent: "space-between"} : {display:"flex", alignItems:"center", justifyContent: "flex-start"}}>
				     							{this.state.sharekpi === true && this.state.indicatorRows.length > 0 ? (
						     						<div style={{marginTop:"10px", display: "flex", flexDirection:"column"}}>
						     							<label style={{marginRight:"10px"}} htmlFor="">Select Cluster:</label>
							     						<select style={{borderRadius:"4px"}} name='clusterName' onChange={this.frequencyReport}>
							     							<option selected disabled value="">Select Clusters</option>
							     							{this.state.clusters.map((cluster,i) => {
						     									if (cluster.totalIndicators > 0) {
							     									return (
							     										<option key={i} value={cluster.id}>{cluster.name}</option>
							     									)
							     								}
						     								})}
							     						</select>
						     						</div>
					     						) : (null)}
					     						{this.state.sharekpi === true && this.state.indicatorRows.length > 0 ? (
						     						<div style={{marginTop:"10px", marginLeft:"10px", display: "flex", flexDirection:"column"}}>
						     							<label style={{marginRight:"10px"}} htmlFor="">Select Kpi:</label>
							     						<Multiselect id="colsTo" onChange={this.handleColChange} data={this.state.kpiDropDownList} buttonWidth={'300px'} multiple enableClickableOptGroups enableCollapsibleOptGroups numberDisplayed={3} includeSelectAllOption onSelectAll={this.handleColChange} onDeselectAll={this.handleColChange} />
						     						</div>
					     						) : (null)}
					     						{this.state.sharekpi === true && this.state.indicatorRows.length > 0 ? (
						     						<div style={{marginTop:"10px", marginLeft:"10px", display: "flex", flexDirection: "column"}}>
						     							<label style={{marginRight:"10px"}} htmlFor="">Select Frequency of reporting:</label>
							     						<select style={{borderRadius:"4px"}} name="frequencyReport" onChange={this.frequencyReport}>
							     							<option selected disabled value="">Select Frequency of reporting</option>
							     							<option value="weekly">Weekly</option>
							     							<option value="monthly">Monthly</option>
							     							<option value="yearly">Yearly</option>
							     							<option value="flexible">Flexible</option>
							     						</select>
						     						</div>
					     						) : (null)}
					     						{this.state.dontSharekpi === true ? (
						     						<div style={ this.state.sharekpi === true ? {marginTop:"10px", marginLeft:"10px", display: "flex", flexDirection: "column"} : {marginTop:"10px", display: "flex", flexDirection: "column"}}>
						     							<label style={{marginRight:"10px"}} htmlFor="">Select Frequency of reporting:</label>
							     						<select style={{borderRadius:"4px"}} name="frequencyReport" onChange={this.frequencyReport}>
							     							<option selected disabled value="">Select Frequency of reporting</option>
							     							<option value="weekly">Weekly</option>
							     							<option value="monthly">Monthly</option>
							     							<option value="yearly">Yearly</option>
							     							<option value="flexible">Flexible</option>
							     						</select>
						     						</div>
					     						) : (null)}
					     						{this.state.dontSharekpi === true ? (
						     						<div style={{marginTop:"10px", marginLeft:"40px", display: "flex", flexDirection: "column"}}>
						     							<label style={{marginRight:"10px"}} htmlFor="">Select Assessment:</label>
							     						<select style={{borderRadius:"4px"}} name="assessment" onChange={this.frequencyReport}>
							     							<option selected disabled value="">Select Assessment</option>
							     							<option value="durational">Durational</option>
							     							<option value="cumulative">Cumulative</option>
							     						</select>
						     						</div>
					     						) : (null)}
				     						</div>
			     							{this.state.sharekpi === true && this.state.indicatorRows.length < 0 ? (
				     							<div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent:"center"}}>
				     								<p>You must add KPIs before sending to your partners</p>
				     								<button className='btn solicit-modal-btn' style={{backgroundColor:"#ef5a20", color:"#fff"}} onClick={() => {window.location.pathname='/indicators'}}>Add KPIs</button>
				     							</div>
			     							) : (null)}
		                </Col> */}
									</Row>
								</Modal.Body>
								<Modal.Footer>
									<Row>
										<Col md={12} className="solicit-buttons-container">
											<button className={this.state.isLoading === true ? "btn solicit-modal-btn-loading" : "btn solicit-modal-btn"} onClick={this.handleSubmit}>{this.state.isLoading === true ? <img src="/img/Spinner-white.svg" style={{width: "27px"}} alt="loading"/> : "Submit"}</button>
											<button className="btn solicit-cancel-button" onClick={this.hideSolicitProject}>Cancel</button>
										</Col>
									</Row>
								</Modal.Footer>
							</Modal>
						  	<div className="invitations-tab-container">
									<div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", marginBottom: "30px" ,marginTop:"100px"}}>
										<h4 className="invitations-count" style={{ color: "#515356", fontWeight: "bold", margin: "0" }}>{'Your Invitations (' + this.state.invitationsData.length + ')'}</h4>
										<div style={{ display: "flex", alignItems: "center", marginLeft: "230px", width: "30%" }}>
											<input className='form-control' style={{ width: "100%" }} type="text" name="" placeholder='Search by Partner Name/Status' value={this.state.input} onChange={this.searchBynames} />
											<span style={{ height: "34px", display: "flex", alignItems: "center", background: "#ef5a20", color: "#fff", width: "34px", justifyContent: "center", cursor: "pointer" }} onClick={this.resetFilter}>
												<i className="fa fa-times"></i>
											</span>
										</div>
									</div>
									<button className="invitations-modal-btn" style={{transition: "none"}} onClick={this.handleShow}>Invite Partner NGOs</button>
									{this.state.filteredData.map((data, i) => {
										return (
											<div key={i} className="invitations-content-container">
												<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
													<a className= {data.hasOwnProperty('ngoId') && data.ngoId && data.status === 'Accepted Invitation' ? 'invitations-name note' : "invitations-name-without-solicit"} target="_blank" href={data.ngo && data.ngo.length ? 'https://www.letsendorse.com/ngo/' + data.ngo[0].le_ngo_id : null}>{data.name}</a>
													{data.hasOwnProperty('ngoId') && data.ngoId && data.status === 'Accepted Invitation' ? (
														<button type="buttom" className='solicit-button' onClick={this.solicitProject.bind(this, data.ngoId)}>Solicit Project</button>
													) : (null)}
												</div>
												<h3 className={data.status === 'accepted invitation' ? "invitations-status green" : "invitations-status"}>{data.status}</h3>
												<p className='invited-by'>Invited by : <span className='invite-by-name'>{userData.fullName}</span></p>
												<div style={{ display: "flex", alignItems: "center" }}>
													<hr style={{ border: "solid .5px #ddd", width: "98%", marginRight: "10px" }} />
													<i className={data.expand === true ? "fa fa-angle-up" : "fa fa-angle-down"} style={{ fontSize: "20px", cursor: "pointer" }} onClick={this.expand.bind(this, i)}></i>
												</div>
												{data.expand === true ?
													(<div className='poc-content-container'>
 														<div className='poc-details-container'>
 															<p className='poc-label'>POC Name : <span className='poc-info'>{data.fullNameOfPOC}</span></p>
 															<p className='poc-label'>POC Email Id : <span className='poc-info'>{data.emailOfPOC}</span></p>
 															<p className='poc-label'>POC Contact No. : <span className='poc-info'>{data.contactNoOfPOC}</span></p>
 														</div>
 														<div className='poc-comment-container'>
 															<p className='poc-comment'>Comments : <span className='comment-info'>{data.message ? data.message : "There are no comments"}</span></p>
 														</div>
 													</div>) : (null)
												}
											</div>
										)
									})}
									<InvitationsModal show={this.state.show} handleClose={this.handleClose} updateInvitations={this.updateInvitations.bind(this)} />
								</div>
						  </Tab>
						  {/*<Tab eventKey={2} title="Explore NGOs">
						  	{this.state.isStateSelected === true ? (
						  		<div className='invitations-tab-container'>
							  	<div className='explore-ngo-filters'>
							  		<select className='explore-ngo-filter' value={this.state.selectlevel}>
							  			<option className='explore-ngo-filter-option' selected disabled>Select Level</option>
							  			<option className='explore-ngo-filter-option' value="">All</option>
							  			<option className='explore-ngo-filter-option' value="">L1 Verified</option>
							  			<option className='explore-ngo-filter-option' value="">L2 Verified</option>
							  		</select>
							  		<select className='explore-ngo-filter'>
							  			<option className='explore-ngo-filter-option' selected disabled value="">Select District</option>
							  		</select>
							  		<select className='explore-ngo-filter' style={{width: "20%"}} value={this.state.selectType}>
							  			<option className='explore-ngo-filter-option' value="" selected disabled>Select Type</option>
							  			<option className='explore-ngo-filter-option' value="">Private Sector Companies (Sec25)</option>
							  			<option className='explore-ngo-filter-option' value="">Cooperative Society</option>
							  			<option className='explore-ngo-filter-option' value="">registered Society (Non-Government)</option>
							  			<option className='explore-ngo-filter-option' value="">Trust (Non Government)</option>
							  			<option className='explore-ngo-filter-option' value="">Other Registered Entities (Non- Government)</option>
							  			<option className='explore-ngo-filter-option' value="">Academic Institution (Private)</option>
							  		</select>
							  		<select className='explore-ngo-filter' value={this.state.selectCertificate}>
							  			<option className='explore-ngo-filter-option' value="" selected disabled>Select Certifications</option>
							  			<option className='explore-ngo-filter-option' value="All">All</option>
							  			<option className='explore-ngo-filter-option' value="80G">80G</option>
							  			<option className='explore-ngo-filter-option' value="801">801</option>
							  			<option className='explore-ngo-filter-option' value="FCRA">FCRA</option>
							  			<option className='explore-ngo-filter-option' value="other">other</option>
							  		</select>
							  		<input className='explore-ngo-filter-search' type='text' placeholder='Search NGO'/>
							  		<button type="button" name='explore-ngo-filter-reset' className='explore-ngo-filter-reset' onClick={this.resetFilter}>Reset</button>
							  	</div>
							  	<div className='ngos-container'>
							  		<h3>Andaman Avians Club</h3>
							  		<h4>L1 Verified</h4>
							  		<h4>City, State</h4>
							  		<h4>FCRA</h4>
							  		<button className='ngos-container-button'>View More</button>
							  	</div>
						  	</div>
						  	) : (
					  		<div className='invitations-tab-container'>
					  			<h3>Select State</h3>
							  	<div className='ngos-states-container' onClick={this.selectState}>
							  		<h4>Andaman & Nicobar Islands <span>(100)</span></h4>
							  	</div>
						  	</div>
						  	)}
						  </Tab>*/}
						</Tabs>
					) :
					(<div>
						<InvitationsModal show={this.state.show} handleClose={this.handleClose} updateInvitations={this.updateInvitations.bind(this)} />
						<button className="invitations-modal-btn-new" style={{position: "absolute", top:"300px", left:"350px"}} onClick={this.handleShow}>Invite Partner NGOs</button>
					</div>
					)
			) : (null)
		)
	}
}