import React, { Component } from 'react';
import { OverlayTrigger, Tooltip, Col, Row } from 'react-bootstrap';
import moment from 'moment';
import $ from 'jquery';
import { LocaleProvider } from 'antd';
import enUS from 'antd/lib/locale-provider/en_US';
import { BootstrapTable, TableHeaderColumn, ButtonGroup } from 'react-bootstrap-table';
import Icon from 'react-icons-kit';
import { ic_add_circle_outline } from 'react-icons-kit/md/ic_add_circle_outline';
import { ic_delete } from 'react-icons-kit/md/ic_delete';

import Loader from '../Loader';

const readCookie = require('../../../cookie.js').readCookie;

let year = moment().year();
const years = [];
for (var i = -3; i < 4; i++) {
	var yearStr = (year - i) + '-' + (year - i + 1);
	years.push(yearStr);
}

export default class Investments extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fiscalYear: this.getCurrentFiscalYear(),
			previousInvestments: [],
			showLoader: true,
			loaderHiding: '',
			csrBudget: null,
			investmentsWarning: true,
			portfolioTotalAmount: 0,
			csrBudget: null
		}
		this.selectFiscalYear = this.selectFiscalYear.bind(this);
	}

	getCurrentFiscalYear() {
		var today = new Date();
		var curMonth = today.getMonth();
		if (curMonth > 3) {
			return today.getFullYear().toString() + '-' + (today.getFullYear() + 1).toString();
		} else {
			return (today.getFullYear() - 1).toString() + '-' + today.getFullYear().toString();
		}
	}

	selectFiscalYear(year) {
		if (year === 'default') {
			this.setState({ fiscalYear: this.getCurrentFiscalYear() });
		} else {
			let companyId = JSON.parse(readCookie('userData')).organisationData.id;
			fetch(process.env.REACT_APP_API_URL + '/company/' + companyId + '/mis/investments' + "/" + year.split('-')[0], {
				method: "GET",
				headers: {
					'Auth': JSON.parse(readCookie('access_token')).access_token
				}
			}).then((data) => data.json())
			.then(function (data) {
				let csrBudget = data.csrBudget
				let investments = data.data;
				let previousInvestments = [];
				let portfolioTotalAmount = 0
				if (data.data.length !== -1) {
					for (var i = 0; i < investments.length; i++) {
						let previousInvestment = {};
						previousInvestment.id = investments[i].id;
						previousInvestment.title = investments[i].project.title;
						previousInvestment.location = investments[i].project.location[0].address;
						if (investments[i].project.hasOwnProperty('startDate')) previousInvestment.startDate = investments[i].project.startDate;
						if (investments[i].project.hasOwnProperty('image')) previousInvestment.image = investments[i].project.image;
						else if (investments[i].project.hasOwnProperty('coverImage')) previousInvestment.image = investments[i].project.coverImage;
						else previousInvestment.image = "https://assets.letsendorse.com/sahaj/project-placeholder.jpg";
						previousInvestment.goal = investments[i].goal;
						previousInvestment.status = investments[i].status;
						previousInvestment.projectId = investments[i].project.id;
						previousInvestment.portfolioId = investments[i].portfolioId;
						previousInvestment.invested = investments[i].releasedAmount;
						previousInvestment.allocated = investments[i].allocatedAmount;
						previousInvestment.thematicCategory = investments[i].project.thematicCategory;
						portfolioTotalAmount += investments[i].allocatedAmount;
						previousInvestments.push(previousInvestment);
					}
					this.setState({ previousInvestments, fiscalYear: year, csrBudget: data.csrBudget.amount, portfolioTotalAmount })
				} else {
					this.setState({ previousInvestments, fiscalYear: year, csrBudget: data.csrBudget.amount, portfolioTotalAmount })
				}

				let investmentsWarningObj = localStorage.getItem("investmentsWarning"), investmentsWarning = false;
				if(investmentsWarningObj === "ignored") {
					localStorage.removeItem('investmentsWarning');
					investmentsWarning = true;
				} else {
					if(investmentsWarningObj === null) investmentsWarning = true;
					else if(!JSON.parse(investmentsWarningObj).ignored) investmentsWarning = true;
					else {
						investmentsWarningObj = JSON.parse(investmentsWarningObj);
						if(investmentsWarningObj.csrBudgetAmount !== data.csrBudget.amount || investmentsWarningObj.portfolioTotalAmount !== portfolioTotalAmount) {
							investmentsWarning = true;
							let warning = {
								csrBudgetAmount: data.csrBudget.amount,
								portfolioTotalAmount: portfolioTotalAmount,
								ignored: false
							}
							localStorage.setItem("investmentsWarning", JSON.stringify(warning));
						}
					}
				}
				this.setState({ investmentsWarning });
			}.bind(this));
		}
	}
	componentWillMount(){
		if(this.props.location.state!==undefined){
		this.setState({fiscalYear:this.props.location.state.fiscalYear});
	}
}
	componentDidMount() {
		this.selectFiscalYear(this.state.fiscalYear);
		let previousInvestments = this.state.previousInvestments;
		if (this.props.hasOwnProperty('portfolioId')) {
			let companyId = JSON.parse(readCookie('userData')).organisationData.id;
			fetch(process.env.REACT_APP_API_URL + '/company/' + companyId + '/mis/individual-portfolio/' + this.props.portfolioId + '/investment', {
				method: "GET",
				headers: {
					'Auth': JSON.parse(readCookie('access_token')).access_token
				}
			}).then((data) => data.json())
				.then(function (data) {
					let investments = data.data;
					for (var i = 0; i < investments.length; i++) {
						let previousInvestment = {};
						previousInvestment.id = investments[i].id;
						previousInvestment.title = investments[i].project.title;
						previousInvestment.location = investments[i].project.location[0].address;
						if (investments[i].project.hasOwnProperty('startDate')) previousInvestment.startDate = investments[i].project.startDate;
						if (investments[i].project.hasOwnProperty('image')) previousInvestment.image = investments[i].project.image;
						else if (investments[i].project.hasOwnProperty('coverImage')) previousInvestment.image = investments[i].project.coverImage;
						else previousInvestment.image = "https://assets.letsendorse.com/sahaj/project-placeholder.jpg";
						previousInvestment.goal = investments[i].goal;
						previousInvestment.status = investments[i].status;
						previousInvestment.projectId = investments[i].project.id;
						previousInvestment.portfolioId = investments[i].portfolioId;
						previousInvestment.invested = investments[i].releasedAmount;
						previousInvestment.allocated = investments[i].allocatedAmount;
						previousInvestment.thematicCategory = investments[i].project.thematicCategory;
						previousInvestments.push(previousInvestment);
					}
					this.setState({ previousInvestments, loaderHiding: 'hiding' }, function () {
						setTimeout(function () {
							this.setState({ showLoader: false });
						}.bind(this), 1000);
					}.bind(this));
				}.bind(this));
		} else {
			let companyId = JSON.parse(readCookie('userData')).organisationData.id;
			fetch(process.env.REACT_APP_API_URL + '/company/' + companyId + '/mis/investments', {
				method: "GET",
				headers: {
					'Auth': JSON.parse(readCookie('access_token')).access_token
				}
			}).then((data) => data.json())
				.then(function (data) {
					let investments = data.data;
					let csrBudget = data.csrBudget
					let portfolioTotalAmount = 0;
					for (var i = 0; i < investments.length; i++) {
						let previousInvestment = {};
						previousInvestment.id = investments[i].id;
						previousInvestment.title = investments[i].project.title;
						previousInvestment.location = investments[i].project.location[0].address;
						if (investments[i].project.hasOwnProperty('startDate')) previousInvestment.startDate = investments[i].project.startDate;
						if (investments[i].project.hasOwnProperty('image')) previousInvestment.image = investments[i].project.image;
						else if (investments[i].project.hasOwnProperty('coverImage')) previousInvestment.image = investments[i].project.coverImage;
						else previousInvestment.image = "https://assets.letsendorse.com/sahaj/project-placeholder.jpg";
						previousInvestment.goal = investments[i].goal;
						previousInvestment.status = investments[i].status;
						previousInvestment.projectId = investments[i].project.id;
						previousInvestment.portfolioId = investments[i].portfolioId;
						previousInvestment.invested = investments[i].releasedAmount;
						previousInvestment.allocated = investments[i].allocatedAmount;
						previousInvestment.thematicCategory = investments[i].project.thematicCategory;
						previousInvestments.push(previousInvestment);
						portfolioTotalAmount += investments[i].allocatedAmount
					}

					let investmentsWarningObj = localStorage.getItem("investmentsWarning"), investmentsWarning = false;
					if(investmentsWarningObj === "ignored") {
						localStorage.removeItem('investmentsWarning');
						investmentsWarning = true;
					} else {
						if(investmentsWarningObj === null) investmentsWarning = true;
						else if(!JSON.parse(investmentsWarningObj).ignored) investmentsWarning = true;
						else {
							investmentsWarningObj = JSON.parse(investmentsWarningObj);
							if(investmentsWarningObj.csrBudgetAmount !== data.csrBudget.amount || investmentsWarningObj.portfolioTotalAmount !== portfolioTotalAmount) {
								investmentsWarning = true;
								let warning = {
									csrBudgetAmount: data.csrBudget.amount,
									portfolioTotalAmount: portfolioTotalAmount,
									ignored: false
								}
								localStorage.setItem("investmentsWarning", JSON.stringify(warning));
							}
						}
					}
					this.setState({ previousInvestments, loaderHiding: 'hiding', csrBudget: data.csrBudget.amount, portfolioTotalAmount, investmentsWarning }, function () {
						setTimeout(function () {
							this.setState({ showLoader: false });
						}.bind(this), 1000);
					}.bind(this));
				}.bind(this));
		}
		function reloadPage() {
			var currentDocumentTimestamp =
			new Date(performance.timing.domLoading).getTime();
			var now = Date.now();
			var tenSec = 10 * 1000;
			var plusTenSec = currentDocumentTimestamp + tenSec;
			if (now > plusTenSec) {
			window.location.reload();
			}
		}
			reloadPage();
	}

	ddClick() {
		$('#dd').toggleClass('active');
	}

	selectYear(event) {
		var opt = $(event.target).text();
		$('#dd > span').html(opt);
		this.selectFiscalYear(opt);
	}

	readMoreAboutProject = (index) => {
		let previousInvestment = this.state.previousInvestments[index],
			path = window.location.pathname;
		if (path.split('/')[1] === 'portfolio') {
			return '/mis-projects/' + previousInvestment.projectId;
		} else if (path.split('/')[1] === 'investments') {
			return '/investments/' + previousInvestment.projectId;
		} else return null;
	}

	investFunds(index) {
		let previousInvestment = this.state.previousInvestments[index];
		let projectId = previousInvestment.id;
		let portfolioId = previousInvestment.portfolioId;
		window.location.pathname = '/edit-investment/' + portfolioId + '/' + projectId + '/invest';
	}

	startInvesting() {
		window.location.pathname = '/portfolio';
	}

	makeLive(index) {
		let previousInvestment = this.state.previousInvestments[index];
		let projectId = previousInvestment.id;

		let data = {};
		data['status'] = 1;

		let companyId = JSON.parse(readCookie('userData')).organisationData.id;
		fetch(process.env.REACT_APP_API_URL + '/company/' + companyId + '/mis/investment/' + projectId + '/status', {
			method: "PUT",
			headers: {
				'Content-Type': 'application/json',
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
			body: JSON.stringify(data)
		}).then(function (response) {
			return response.json();
		}).then(function (data) {
			window.location.reload();
		}.bind(this));
	}

	setAnnualBudget = () => {
		window.location.pathname = "/fiscal-year-budgeting";
		// sessionStorage.setItem("selectedFiscalYear", this.state.fiscalYear)
	}

	ignoreWarning = () => {
		let warning = {
			csrBudgetAmount: this.state.csrBudget,
			portfolioTotalAmount: this.state.portfolioTotalAmount,
			ignored: true
		}
		localStorage.setItem("investmentsWarning", JSON.stringify(warning))
		this.setState({
			investmentsWarning: !this.state.investmentsWarning
		})
	}

	gotoHandler = () => {
		window.location.pathname = '/discover/projects';
	}

	render() {
		if (this.props.hasOwnProperty('portfolioId')) {
			return (
				<Row>
					{this.state.showLoader ? (
						<Loader loaderHiding={this.state.loaderHiding} />
					) : ('')}
					<Col className="previous-investments mt20 mb20" md={12}>
						{/*
						{this.props.signlePortfolioPage === true ? (null) : (
							<div className="toggleView" style={{ marginBottom: "50px" }}>
								<button className="toggleView-button-goal" onClick={() => { window.location.pathname = "/portfolio" }} >Switch to Goal-wise Investments <i className="fa fa-angle-double-right"></i></button>
							</div>
						)} */}
						{this.state.previousInvestments.map(function (investment, index) {
							return (
								<Col key={index} className="previous-investment mb10 mt10" md={12}>
									<Col className="previous-investment-image" md={3} style={{ backgroundImage: "url('" + investment.image + "')" }}>
										<div className="index">{index + 1}</div>
										{(() => {
											if (investment.status === 0) {
												return <div className="status draft"><span>Draft</span></div>
											} else if (investment.status === 1) {
												return <div className="status live"><span>Live</span></div>
											} else if (investment.status === 2) {
												return <div className="status completed"><span>Completed</span></div>
											}
										})()}
									</Col>
									{/* <h4 className="col-md-6"><label>Total Amount Allocated:</label> Rs. {this.state.portfolioTotalAmount.toLocaleString()}</h4> */}
									{!this.props.signlePortfolioPage ? (
										<h4 className="col-md-6"><label>Total Amount Allocated:</label> Rs.</h4>
									) : (null)}
									{/* <h4 className="col-md-6 text-right"><label>Planned Budget Amount:</label> Rs. {this.state.csrBudget.toLocaleString()}</h4> */}
									{!this.props.signlePortfolioPage ? (
										<h4 className="col-md-6 text-right"><label>Planned Budget Amount:</label> Rs.</h4>
									) : (null)}
									<Col md={6} className="previous-investment-details">
										<Col md={12} className="project-name-container">
											<label>Project Title:</label>
											{investment.title}
										</Col>
										<Col md={12} className="mt10">
											<label>Project Location:</label>
											{investment.location}
										</Col>
										{investment.thematicCategory && investment.thematicCategory.name ? (
											<Col md={12} className="mt10">
												<label>Schedule 7 Thematic Category:</label>
												{investment.thematicCategory.name}
											</Col>
										) : (null)}
										{investment.goal && investment.goal.name ? (
											<Col md={12} className="mt10">
												<label>Goal:</label>
												{investment.goal.name}
											</Col>
										) : (null)}
										{false && investment.hasOwnProperty('startDate') ? (
											<Col md={12} className="mt10">
												<label>Project Start Date:</label>
												{investment.startDate}
											</Col>
										) : (null)}
										<Col md={12} className="mt10 read-more">
											<a className="btn mr5 ml5" href={this.readMoreAboutProject(index)} target="_blank">Read More</a>
											{investment.status === 0 ? (
												<button className="btn mr5 ml5" onClick={this.makeLive.bind(this, index)}>Make Live</button>
											) : (null)}
										</Col>
									</Col>
									<Col md={3} className="previous-investment-amount">
										<Col md={12} className="mt10">
											<label>Allocated Amount:</label>
											{'Rs. ' + window.inrFormat(investment.allocated)}
										</Col>
										<Col md={12} className="mt10">
											<label>Invested Amount:</label>
											{'Rs. ' + window.inrFormat(investment.invested)}
										</Col>
										{JSON.parse(readCookie('userData')).authorizedFor.mis !== 'view' ? (
											<Col md={12} className="mt10 invest-funds">
												{investment.status === 1 ? (
													<button className="btn" onClick={this.investFunds.bind(this, index)}>Invest Funds</button>
												) : (null)}
											</Col>
										) : (null)}
									</Col>
								</Col>
							)
						}.bind(this))}
					</Col>
					{this.state.previousInvestments.length === 0 ? (
						<div className="create-annual-budget-container">
							{this.state.csrBudget === null ? (
								<div className="create-annual-budget-button" onClick={this.setAnnualBudget}>Create Your Annual Budget for {this.state.fiscalYear}</div>
							) : (
								JSON.parse(readCookie('userData')).authorizedFor.mis !== 'view' ? (
									<div className="create-annual-budget-button mb30" onClick={this.props.openForm}>You have not made any investments. Start Investing now!</div>
								) : (null)
							)}
						</div>
					) : (null)}
				</Row>
			);
		} else {
			return (
				<div className={(this.state.investmentsWarning && this.state.csrBudget !== null && (this.state.csrBudget < this.state.portfolioTotalAmount && (this.state.portfolioTotalAmount / this.state.csrBudget) > 1.1)) ? "mis-content-container show-warning" : "mis-content-container"}>
					{this.state.showLoader ? (
						<Loader loaderHiding={this.state.loaderHiding} />
					) : ('')}
					<Col className="previous-investments mt20 mb20" md={12}>
						{this.props.signlePortfolioPage === true ? (null) : (
							<div className={this.state.investmentsWarning === true ? "toggleView" : "toggleView_1"} style={{ marginBottom: "50px" }}>
								{/*<button className="toggleView-button-goals" onClick={() => { window.location.pathname = "/portfolio" }} >Switch to Goal-wise Investments <i className="fa fa-angle-double-right"></i></button>*/}
								<h2 className="text-center" style={{ textAlign: "center", margin: "0" }}>Project-wise Investments</h2>
								<div className="fiscal-year-selector pull-right">
									<span>
										Select Fiscal Year:
										<div id="dd" className="wrapper-dropdown-3" tabIndex="1" onClick={this.ddClick.bind(this)}>
											<span> { this.state.fiscalYear } </span>
											<ul className="dropdown">
												{years.map(function (year, index) {
													return (
														<li key={index} onClick={this.selectYear.bind(this)}><a href="#">{year}</a></li>
													)
												}.bind(this))}
											</ul>
										</div>
									</span>
								</div>
							</div>
						)}

						{this.state.portfolioTotalAmount !== undefined && this.state.portfolioTotalAmount > 0 ? (
							<h4 className="col-md-6" style={{ padding: "0" }}> <label style={{ color: "#ef5a20" }}>Total Amount Allocated:</label> Rs. {window.inrFormat(this.state.portfolioTotalAmount)}</h4>
						) : (
								<h4 className="col-md-6" style={{ padding: "0" }}> <label style={{ color: "#ef5a20" }}>Total Amount Allocated:</label> Rs.0</h4>
							)}
						{this.state.csrBudget !== null && this.state.csrBudget !== undefined ? (
							<h4 className="col-md-6 text-right" style={{ padding: "0" }}><label style={{ color: "#ef5a20" }}>Planned Budget Amount:</label> Rs. {window.inrFormat(this.state.csrBudget)}</h4>
						) : (
							<h4 className="col-md-6 text-right" style={{ padding: "0" }}><label style={{ color: "#ef5a20" }}>Planned Budget Amount:</label> Rs. 0</h4>
						)}
						{this.state.previousInvestments.map(function (investment, index) {
							return (
								<Col key={index} className="previous-investment mb10 mt30" md={12}>
									{(() => {
										if (this.state.csrBudget !== null) {
											if (this.state.csrBudget < this.state.portfolioTotalAmount && (this.state.portfolioTotalAmount / this.state.csrBudget) > 1.1) {
												if (this.state.investmentsWarning === true) {
													return (
														<div className="balance-banner">
															{'Hey! You have allocated '}
															<span className="amount-span">INR {this.state.portfolioTotalAmount - this.state.csrBudget}</span>
															{' more than your Portfolio planning. You can update your plans from the Fiscal Year Budget section now!'}
															<div style={{ marginTop: "10px" }}>
																<button style={{ background: "#fff", border: "none", color: "#ef5a20", borderRadius: "50px", padding: "0 15px 3px 15px" }} onClick={() => { window.location.pathname = "/fiscal-year-budgeting" }}>Go to Fiscal Year Budget</button>
																<button style={{ background: "#fff", border: "none", color: "#ef5a20", borderRadius: "50px", padding: "0 15px 3px 15px", marginLeft: "20px" }} onClick={this.ignoreWarning}>Ok</button>
															</div>
														</div>
													)
												}
											// } else if (this.state.csrBudget > this.state.portfolioTotalAmount && ((this.state.portfolioTotalAmount / this.state.csrBudget) > 0.75 && (this.state.portfolioTotalAmount / this.state.csrBudget) < 0.95)) {
											// 	if (this.state.investmentsWarning === true) {
											// 		return (
											// 			<div className="balance-banner">
											// 				{'Hey! You have allocated '}
											// 				<span className="amount-span">INR {this.state.csrBudget - this.state.portfolioTotalAmount}</span>
											// 				{' less than your Portfolio planning. Start Allocating now!'}
											// 				<div style={{ marginTop: "10px" }}>
											// 					<button style={{ background: "#fff", border: "none", color: "#ef5a20", borderRadius: "50px", padding: "0 15px 3px 15px" }} onClick={this.ignoreWarnig}>Ok</button>
											// 				</div>
											// 			</div>
											// 		)
											// 	}
											}
										}
									})()}
									<Col className="previous-investment-image" md={3} style={{ backgroundImage: "url('" + investment.image + "')" }}>
										<div className="index">{index + 1}</div>
										{(() => {
											if (investment.status === 0) {
												return <div className="status draft"><span>Draft</span></div>
											} else if (investment.status === 1) {
												return <div className="status live"><span>Live</span></div>
											} else if (investment.status === 2) {
												return <div className="status completed"><span>Completed</span></div>
											}
										})()}
									</Col>
									<Col md={6} className="previous-investment-details">
										<Col md={12} className="project-name-container">
											<label>Project Title:</label>
											{investment.title}
										</Col>
										<Col md={12} className="mt10">
											<label>Project Location:</label>
											{investment.location}
										</Col>
										{investment.thematicCategory && investment.thematicCategory.name ? (
											<Col md={12} className="mt10">
												<label>Schedule 7 Thematic Category:</label>
												{investment.thematicCategory.name}
											</Col>
										) : (null)}
										{investment.goal && investment.goal.name ? (
											<Col md={12} className="mt10">
												<label>Goal:</label>
												{investment.goal.name}
											</Col>
										) : (null)}
										{false && investment.hasOwnProperty('startDate') ? (
											<Col md={12} className="mt10">
												<label>Project Start Date:</label>
												{investment.startDate}
											</Col>
										) : (null)}
										<Col md={12} className="mt10 read-more">
											<a className="btn" href={this.readMoreAboutProject(index)} target="_blank">Read More</a>
											{investment.status === 0 ? (
												<button className="btn mr5 ml5" onClick={this.makeLive.bind(this, index)}>Make Live</button>
											) : (null)}
										</Col>
									</Col>
									<Col md={3} className="previous-investment-amount">
										<Col md={12} className="mt10">
											<label>Allocated Amount:</label>
											{'Rs. ' + window.inrFormat(investment.allocated)}
										</Col>
										<Col md={12} className="mt10">
											<label>Invested Amount:</label>
											{'Rs. ' + window.inrFormat(investment.invested)}
										</Col>
										{JSON.parse(readCookie('userData')).authorizedFor.mis !== 'view' ? (
											<Col md={12} className="mt10 invest-funds">
												{investment.status === 1 ? (
													<button className="btn" onClick={this.investFunds.bind(this, index)}>Invest Funds</button>
												) : (null)}
											</Col>
										) : (null)}
									</Col>
								</Col>
							)
						}.bind(this))}
						<div className="col-md-12 text-center mt20 mb20">
							<button className="btn browse-projects-btn" onClick={() => { window.location.pathname = "/discover/projects" }}>
								Browse Projects
							</button>
						</div>
					</Col>
					{this.state.previousInvestments.length === 0 ? (
						<div className="create-annual-budget-container">
							{this.state.csrBudget === null || this.state.csrBudget === undefined ? (
								<div className="create-annual-budget-button" onClick={this.setAnnualBudget}>Create Your Annual Budget for {this.state.fiscalYear}</div>
							) : (
								JSON.parse(readCookie('userData')).authorizedFor.mis !== 'view' ? (
									<div className="create-annual-budget-button" onClick={() => { window.location.pathname = "/discover/projects" }}>You have not made any investments. Start Investing now!</div>
								) : (null)
							)}
						</div>
					) : (null)}
				</div>
			);
		}

	}
}