import React, { Component } from 'react';
import { OverlayTrigger, Tooltip, Col, Row } from 'react-bootstrap';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import moment from 'moment';
import { LocaleProvider } from 'antd';
import enUS from 'antd/lib/locale-provider/en_US';
import $ from 'jquery';
import { BootstrapTable, TableHeaderColumn, ButtonGroup } from 'react-bootstrap-table';
import Multiselect from 'react-bootstrap-multiselect';
import Icon from 'react-icons-kit';
import { ic_done } from 'react-icons-kit/md/ic_done';
import { DatePicker, Select } from 'antd';
import swal from "sweetalert2";
import { ToastContainer, toast } from 'react-toastify';

import AmountBreakupTable from './AmountBreakupTable';

const Option = Select.Option;

const readCookie = require('../../../cookie.js').readCookie;

export default class EditSinglePortfolioGoal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showEditInvestmentForm: false,
			showAddReleasedAmountForm: false,
			amountBreakupData: null,
			releasedAmount: 0,
			projectId: null,
			customUrl: '',
			investmentId: null,
			singlePortfolio: null,
			singlePortfolioId: null,
			singlePortfolioGoal: null,
			singlePortfolioAgendas: null,
			singlePortfolioAmount: null,
			selectedAgenda: {
				id: "",
				name: ""
			},
			allNGOs: null,
			selectedNgo: {
				id: "",
				name: ""
			},
			allProjects: [],
			selectedProject: [],
			selectedProjectDescription: "",
			multiselectData: [
				{ value: "1", label: "Vishal" },
				{ value: "2", label: "Lionel" },
				{ value: "3", label: "Harkirat" },
				{ value: "4", label: "Subham" }
			],
			allKPIs: [],
			showForm: true,
			otAttributes: null,
			hasProjectLocation: {
				preferenceLocation: '',
				preferenceLocationError: '',
				latLng: {}
			},
			projectExpectedImpact: '',
			projectStartDate: null,
			allocatedAmount: 0,
			amountBreakupTableVisibility: true,
			amountBreakup: [],
			reportingFrequency: '',
			selectedKPIs: [],
			selectedLocations: [],
			selectedBeneficiaryTypes: [],
			selectedProjectDuration: {
				duration: '',
				type: ''
			},
			selectedStartDate: null,
			costHeads: [],
			newRelease: {},
			imageFile: '',
			imagePreviewUrl: '',
			status: null,
			corporateInterest: null
		}
		this.addNewBreakup = this.addNewBreakup.bind(this);
		this.changeSelectedNgo = this.changeSelectedNgo.bind(this);
		this.changeSelectedAgenda = this.changeSelectedAgenda.bind(this);
		this.changeSelectedProject = this.changeSelectedProject.bind(this);
		this.changeSelectedProjectDescription = this.changeSelectedProjectDescription.bind(this);
		this.handleSelectChange = this.handleSelectChange.bind(this);
		this.saveProject = this.saveProject.bind(this);
		this.cancelProject = this.cancelProject.bind(this);
		this.changeProjectDuration = this.changeProjectDuration.bind(this);
		this.changeProjectDurationType = this.changeProjectDurationType.bind(this);
		this.changeSelectedReportingFreq = this.changeSelectedReportingFreq.bind(this);
	}

	componentDidMount() {
		if (window.location.pathname.split('/').pop() === 'invest') this.setState({ showEditInvestmentForm: false, showAddReleasedAmountForm: true });
		else if (window.location.pathname.split('/').pop() === 'edit') this.setState({ showEditInvestmentForm: true, showAddReleasedAmountForm: false });
		let singlePortfolioId = this.props.match.params.portfolioId;
		let investmentId = this.props.match.params.investmentId;
		this.setState({ singlePortfolioId, investmentId });
		let companyId = JSON.parse(readCookie('userData')).organisationData.id;
		fetch(process.env.REACT_APP_API_URL + '/company/' + companyId + '/mis/individual-portfolio/' + singlePortfolioId, {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
		.then(function (data) {
			let singlePortfolio = data.data;
			let singlePortfolioGoal = data.data.goal;
			let singlePortfolioAgendas = data.data.agendas;
			let singlePortfolioAmount = data.data.amount;

			this.setState({ singlePortfolioGoal });

			fetch(process.env.REACT_APP_API_URL + '/cost-attributes', {
				method: "GET",
				headers: {
					'Auth': JSON.parse(readCookie('access_token')).access_token
				}
			}).then((data) => data.json())
				.then(function (data) {
					let costHeads = [];
					for (var i = 0; i < data.data.length; i++) {
						for (var j = 0; j < data.data[i].costHeads.length; j++) {
							let costHead = {};
							costHead.value = data.data[i].costHeads[j].key;
							costHead.label = data.data[i].costHeads[j].name;
							costHeads.push(costHead);
						}
					}
					this.setState({ costHeads });
				}.bind(this));

			fetch(process.env.REACT_APP_API_URL + '/company/' + companyId + '/mis/suggest-projects', {
				method: "GET",
				headers: {
					'Auth': JSON.parse(readCookie('access_token')).access_token
				}
			}).then((data) => data.json())
				.then(function (data) {
					let allProjects = data.data;
					this.setState({ allProjects });
				}.bind(this));

			fetch(process.env.REACT_APP_API_URL + '/company/' + companyId + '/mis/suggest-ngos', {
				method: "GET",
				headers: {
					'Auth': JSON.parse(readCookie('access_token')).access_token
				}
			}).then((data) => data.json())
				.then(function (data) {
					let allNGOs = data.data;
					this.setState({ singlePortfolio }, function () {
						this.setState({ singlePortfolioGoal, singlePortfolioAgendas, singlePortfolioAmount, allNGOs }, function () {
							fetch(process.env.REACT_APP_API_URL + '/company/' + companyId + '/mis/investment/' + investmentId, {
								method: "GET",
								headers: {
									'Auth': JSON.parse(readCookie('access_token')).access_token
								}
							}).then((data) => data.json())
								.then(function (data) {
									let projectId = data.data.project.id;
									let customUrl = data.data.project.id;
									if (data.data.project.customUrl !== undefined) {
										customUrl = data.data.project.customUrl;
									}
									let selectedAgenda = data.data.agenda;
									let selectedNgo = {};
									selectedNgo.id = data.data.ngo.id;
									selectedNgo.name = data.data.ngo.name;
									let allocatedAmount = data.data.allocatedAmount;
									let releasedAmount = data.data.releasedAmount;
									let selectedProject = [];
									selectedProject.push(data.data.project.title);
									let status = data.data.status;
									let corporateInterest = data.data.project.corporateInterest;

									if(corporateInterest.hasOwnProperty('disbursementType')) {
										if(corporateInterest.disbursementType === 'Tranches') {
											for(var i = 0; i < corporateInterest.tranches.length; i++) {
												corporateInterest.tranches[i].slno = i + 1;
											}
										}
									}

									let selectedProjectDescription = data.data.project.description;
									let projectExpectedImpact = data.data.project.expectedImpact;
									if (data.data.project.hasOwnProperty('expectedStartDate')) {
										let projectStartDate = data.data.project.expectedStartDate;
										this.setState({ projectStartDate });
									}

									let hasProjectLocation = {};
									hasProjectLocation.preferenceLocation = data.data.project.location[0].address;
									hasProjectLocation.preferenceLocationError = '';
									let latLng = {};
									latLng.lat = data.data.project.location[0].latitude;
									latLng.lng = data.data.project.location[0].longitude;
									hasProjectLocation.latLng = latLng;

									let amountBreakup = [];
									let amountBreakupData = [];
									for (var i = 0; i < data.data.amountBreakup.length; i++) {
										let breakup = {};
										let breakupData = {};
										breakupData.value = i;
										breakupData.label = data.data.amountBreakup[i].description;

										breakup.name = data.data.amountBreakup[i].description;
										breakup.costHead = data.data.amountBreakup[i].costHead.key;
										breakup.amount = data.data.amountBreakup[i].amount;

										amountBreakupData.push(breakupData);
										amountBreakup.push(breakup);
									}

									let selectedBeneficiaryTypes = [];
									for (var i = 0; i < data.data.project.targetBeneficiaries.length; i++) {
										selectedBeneficiaryTypes.push(data.data.project.targetBeneficiaries[i].id);
									}
									let selectedKPIs = [];
									for (var i = 0; i < data.data.KPIs.length; i++) {
										selectedKPIs.push(data.data.KPIs[i].id);
									}
									let selectedProjectDuration = {};
									selectedProjectDuration.duration = data.data.project.expectedDurationObject.duration;
									selectedProjectDuration.type = data.data.project.expectedDurationObject.type;
									let reportingFrequency = data.data.reportingFrequency.id;
									this.setState({ selectedAgenda, releasedAmount, selectedNgo, status, allocatedAmount, selectedProject, selectedProjectDescription, projectExpectedImpact, hasProjectLocation, amountBreakupData, amountBreakup, selectedBeneficiaryTypes, selectedKPIs, selectedProjectDuration, reportingFrequency, customUrl, corporateInterest }, function () {

										fetch(process.env.REACT_APP_API_URL + '/kpi/' + this.state.singlePortfolioGoal.id, {
											method: "GET",
											headers: {
												'Auth': JSON.parse(readCookie('access_token')).access_token
											}
										}).then((data) => data.json())
											.then(function (data) {
												let allKPIs = [];
												for (var i = 0; i < data.data.length; i++) {
													let kpi = {};
													kpi.value = data.data[i]._id;
													kpi.label = data.data[i].name;
													for (var j = 0; j < this.state.selectedKPIs.length; j++) {
														if (data.data[i]._id == this.state.selectedKPIs[j]) kpi['selected'] = true;
													}
													allKPIs.push(kpi);
												}
												this.setState({ allKPIs });
											}.bind(this));

										fetch(process.env.REACT_APP_API_URL + '/getOtAttributes', {
											method: "GET",
											headers: {
												'Auth': JSON.parse(readCookie('access_token')).access_token
											}
										}).then((data) => data.json())
											.then(function (data) {
												let beneficiaryTypes = [];
												let projectDurations = [];
												let reportingFrequency = [];

												for (let i = 0; i < data.otAttributes.length; i++) {
													if (data.otAttributes[i].type === "beneficiary") {
														let beneficiaryType = {};
														beneficiaryType['label'] = data.otAttributes[i].name;
														beneficiaryType['value'] = data.otAttributes[i]._id;
														for (var j = 0; j < this.state.selectedBeneficiaryTypes.length; j++) {
															if (data.otAttributes[i]._id == this.state.selectedBeneficiaryTypes[j]) beneficiaryType['selected'] = true;
														}
														beneficiaryTypes.push(beneficiaryType);
													} else if (data.otAttributes[i].type === "projectDuration") {
														let projectDuration = {};
														projectDuration['label'] = data.otAttributes[i].name;
														projectDuration['value'] = data.otAttributes[i]._id;
														projectDurations.push(projectDuration);
													} else if (data.otAttributes[i].type === "reportingFrequency") {
														let frequency = {};
														frequency['label'] = data.otAttributes[i].name;
														frequency['value'] = data.otAttributes[i]._id;
														reportingFrequency.push(frequency);
													}
												}
												let otAttributes = {};
												otAttributes['beneficiaryTypes'] = beneficiaryTypes;
												otAttributes['projectDurations'] = projectDurations;
												otAttributes['reportingFrequency'] = reportingFrequency;

												this.setState({ otAttributes });
											}.bind(this));
									}.bind(this));
								}.bind(this));
						}.bind(this));
					}.bind(this))
				}.bind(this));
		}.bind(this));
	}

	showButtonText(options, select) {
		if (options.length === 0) {
			return 'None Selected';
		} else if (options.length > 1) {
			return options.length + ' options selected!';
		} else {
			let labels = [];
			options.each(function () {
				if ($(this).attr('label') !== undefined) {
					labels.push($(this).attr('label'));
				} else {
					labels.push($(this).html());
				}
			});
			return labels.join(', ') + '';
		}
	}

	onPreferenceLocationChange(preferenceLocation) {
		this.props.changeLocation(preferenceLocation);
		geocodeByAddress(preferenceLocation)
			.then(results => getLatLng(results[0]))
			.then(function (latLng) {
				this.props.changeLatLng(latLng);
			}.bind(this)).catch(error => console.error('Error', error));
	}

	changeSelectedAgenda(value) {
		let selectedAgenda = null;
		for (var i = 0; i < this.state.singlePortfolioAgendas.length; i++) {
			if (value === this.state.singlePortfolioAgendas[i].id) selectedAgenda = this.state.singlePortfolioAgendas[i];
		}
		this.setState({ selectedAgenda });
	}

	changeSelectedNgo(value) {
		let selectedNgo = null;
		for (var i = 0; i < this.state.allNGOs.length; i++) {
			if (value === this.state.allNGOs[i].id) selectedNgo = this.state.allNGOs[i];
		}
		this.setState({ selectedNgo });
	}

	changeSelectedProject(value) {
		let newSelectedProject = [];
		if (value.length) newSelectedProject.push(value[0]);
		this.setState({ selectedProject: newSelectedProject });
	}

	changeSelectedProjectDescription(e) {
		this.setState({ selectedProjectDescription: e.target.value });
	}

	handleSelectChange(name, value) {
		this.setState({ [name]: $(value).parents('select').val() });
	}

	saveProject() {
		if (this.state.selectedAgenda.id !== '' && this.state.selectedProject.length && this.state.selectedNgo.id !== '' && this.state.selectedKPIs.length && this.state.reportingFrequency !== '' && this.state.allocatedAmount !== 0) {
			if (this.state.selectedProject[0] !== '') {
				let data = {};
				data.goal = this.state.singlePortfolioGoal.id;
				data.agenda = this.state.selectedAgenda.id;
				if (this.state.selectedProject.length === 1) {
					let foundProject = false;
					let foundProjectIndex = -1;
					for (var i = 0; i < this.state.allProjects.length; i++) {
						if (this.state.allProjects[i].id === this.state.selectedProject[0]) {
							foundProject = true;
							foundProjectIndex = i;
							break;
						}
					}
					if (foundProject) data.projectId = this.state.selectedProject[0];
					else {
						if (this.state.selectedProjectDescription !== '' && this.state.hasProjectLocation.preferenceLocation !== '' && this.state.selectedBeneficiaryTypes.length && this.state.projectExpectedImpact !== '' && this.state.selectedProjectDuration.duration !== '' && this.state.selectedProjectDuration.type !== '') {
							let project = {};
							project.id = this.state.projectId;
							project.title = this.state.selectedProject[0];
							project.description = this.state.selectedProjectDescription;
							let location = [];
							let hasProjectLocation = {};
							hasProjectLocation.latitude = this.state.hasProjectLocation.latLng.lat;
							hasProjectLocation.longitude = this.state.hasProjectLocation.latLng.lng;
							hasProjectLocation.address = this.state.hasProjectLocation.preferenceLocation;
							location.push(hasProjectLocation);
							project.location = location;
							project.targetBeneficiaries = this.state.selectedBeneficiaryTypes;
							project.expectedImpact = this.state.projectExpectedImpact;
							project.expectedDuration = this.state.selectedProjectDuration.duration + ' ' + this.state.selectedProjectDuration.type;
							let expectedDurationObject = {};
							expectedDurationObject.duration = this.state.selectedProjectDuration.duration;
							expectedDurationObject.type = this.state.selectedProjectDuration.type;
							project.expectedDurationObject = expectedDurationObject;
							project.expectedStartDate = this.state.selectedStartDate;
							data.project = project;
						} else return;
					}
				}

				data.ngoId = this.state.selectedNgo.id;
				data.allocatedAmount = this.state.allocatedAmount;
				data.KPIs = this.state.selectedKPIs;
				data.reportingFrequency = this.state.reportingFrequency;
				let amountBreakup = [];
				for (var i = 0; i < this.state.amountBreakup.length; i++) {
					let breakup = {};
					breakup.description = this.state.amountBreakup[i].name;
					breakup.costHead = this.state.amountBreakup[i].costHead;
					breakup.amount = this.state.amountBreakup[i].amount;
					amountBreakup.push(breakup);
				}
				data.amountBreakup = amountBreakup;

				let companyId = JSON.parse(readCookie('userData')).organisationData.id;
				fetch(process.env.REACT_APP_API_URL + '/company/' + companyId + '/mis/investment/' + this.state.investmentId, {
					method: "PUT",
					headers: {
						'Content-Type': 'application/json',
						'Auth': JSON.parse(readCookie('access_token')).access_token
					},
					body: JSON.stringify(data)
				}).then(function (response) {
					return response.json();
				}).then(function (data) {
					if (data.status === 'ok') {
						this.notifySuccess('Successfully updated your project.');
						setTimeout(function() {
							window.location.pathname = "/investments";
						}, 1500);
					} else this.notifyError('The project could not be updated!');
				}.bind(this));
			} else this.notifyError('The project could not be updated!');
		} else this.notifyError('Please fill all the details correctly.');
	}

	notifyError = (text) => toast.error(text);

	notifySuccess = (text) => toast.success(text);

	cancelProject(form) {
		let investmentId = this.state.investmentId;
		this.setState({
			selectedAgenda: {
				id: "",
				name: ""
			},
			selectedNgo: {
				id: "",
				name: ""
			},
			status: null,
			selectedProject: [],
			selectedProjectDescription: ""
		}, function () {
			window.location.pathname = '/mis-projects/' + this.state.customUrl;
		});
	}

	changeProjectDuration(e) {
		let selectedProjectDuration = this.state.selectedProjectDuration;
		selectedProjectDuration.duration = e.target.value;
		this.setState({ selectedProjectDuration });
	}

	changeProjectDurationType(value) {
		let selectedProjectDuration = this.state.selectedProjectDuration;
		selectedProjectDuration.type = value;
		this.setState({ selectedProjectDuration });
	}

	changeLatLng(latLng) {
		let hasProjectLocation = this.state.hasProjectLocation;
		hasProjectLocation.latLng = latLng;
		this.setState({ hasProjectLocation });
	}

	changeLocation(value) {
		let hasProjectLocation = this.state.hasProjectLocation;
		hasProjectLocation.preferenceLocation = value;
		this.setState({ hasProjectLocation });
	}

	changeProjectExpectedImpact(e) {
		this.setState({ projectExpectedImpact: e.target.value });
	}

	onStartDateChange(date, dateString) {
		this.setState({ selectedStartDate: moment(dateString).format('DD/MM/YYYY') });
	}

	updateAllocatedAmount(e) {
		this.setState({ allocatedAmount: e.target.value });
	}

	showAmountBreakupTable() {
		if (this.state.allocatedAmount !== 0) this.setState({ amountBreakupTableVisibility: true });
	}

	changeSelectedReportingFreq(value) {
		this.setState({ reportingFrequency: value });
	}

	addNewBreakup() {
		let amountBreakup = this.state.amountBreakup;
		let newBreakup = {};
		newBreakup.name = '';
		newBreakup.costHead = '';
		newBreakup.amount = 0;
		amountBreakup.push(newBreakup);
		this.setState({ amountBreakup });
	}

	updateAmountBreakupName(index, e) {
		let amountBreakup = this.state.amountBreakup;
		amountBreakup[index].name = e.target.value;
		this.setState({ amountBreakup });
	}

	updateAmountBreakupCosthead(index, value) {
		let amountBreakup = this.state.amountBreakup;
		amountBreakup[index].costHead = value;
		this.setState({ amountBreakup });
	}

	updateAmountBreakupAmount(index, e) {
		let amountBreakup = this.state.amountBreakup;
		amountBreakup[index].amount = e.target.value;
		this.setState({ amountBreakup });
	}

	removeAmountBreakup(index) {
		let amountBreakup = this.state.amountBreakup;
		amountBreakup.splice(index, 1);
		this.setState({ amountBreakup: null }, function () {
			this.setState({ amountBreakup });
		}.bind(this));
	}

	showForm(form) {
		this.setState({ [form]: true });
	}

	updateInvestmentAmount(e) {
		let newRelease = this.state.newRelease;
		newRelease['amount'] = e.target.value;
		this.setState({ newRelease });
	}

	updateInvestmentDate(date, dateString) {
		let newRelease = this.state.newRelease;
		newRelease['date'] = moment(dateString).format('DD/MM/YYYY');
		this.setState({ newRelease });
	}

	updateInvestmentBreakup(value) {
		let newRelease = this.state.newRelease;
		newRelease['amountBreakupIds'] = $(value).parents('select').val();
		this.setState({ newRelease });
	}

	updateInvestmentComment(e) {
		let newRelease = this.state.newRelease;
		newRelease['comment'] = e.target.value;
		this.setState({ newRelease });
	}

	updateInvestmentMode(value) {
		let newRelease = this.state.newRelease;
		newRelease['mode'] = value;
		this.setState({ newRelease });
	}

	updateInvestmentTranch(value) {
		let newRelease = this.state.newRelease;
		newRelease['tranchId'] = value;
		this.setState({ newRelease });
	}

	updateInvestmentUtr(e) {
		let newRelease = this.state.newRelease;
		newRelease['chequeNo'] = e.target.value;
		this.setState({ newRelease });
	}

	updateInvestmentScanCopy(e) {
		e.preventDefault();
		let newRelease = this.state.newRelease;
		let reader = new FileReader();
		let file = e.target.files[0];
		newRelease['imageFile'] = file;

		reader.onloadend = () => {
			this.setState({ newRelease });
		}
		reader.readAsDataURL(file);
	}

	saveRelease() {
		if (this.state.newRelease !== null && this.state.newRelease.hasOwnProperty('amount') && this.state.newRelease.hasOwnProperty('date') && this.state.newRelease.hasOwnProperty('amountBreakupIds') && this.state.newRelease.hasOwnProperty('comment') && this.state.newRelease.hasOwnProperty('mode')) {
			if (this.state.newRelease.amount !== 0 && this.state.newRelease.date !== '' && this.state.newRelease.amountBreakupIds.length && this.state.newRelease.comment !== '' && this.state.newRelease.mode !== '') {
				let amountBreakups = [];
				for (var i = 0; i < this.state.newRelease.amountBreakupIds.length; i++) {
					for (var j = 0; j < this.state.amountBreakupData.length; j++) {
						if (String(this.state.newRelease.amountBreakupIds[i]) === String(this.state.amountBreakupData[j].value)) {
							let amountBreakup = {};
							amountBreakup.id = String(this.state.amountBreakupData[j].value);
							amountBreakup.value = this.state.amountBreakupData[j].label;
							amountBreakups.push(amountBreakup);
						}
					}
				}
				let releasedAmount = {};
				releasedAmount.amount = this.state.newRelease.amount;
				releasedAmount.date = this.state.newRelease.date;
				releasedAmount.amountBreakups = amountBreakups;
				releasedAmount.comment = this.state.newRelease.comment;
				releasedAmount.mode = this.state.newRelease.mode;
				if(this.state.newRelease.hasOwnProperty('tranchId') && this.state.newRelease.tranchId !== '') releasedAmount.tranchId = this.state.newRelease.tranchId;
				releasedAmount.transactionRefNo = this.state.newRelease.chequeNo;

				let formData = new FormData();
				formData.append('releasedAmount', JSON.stringify(releasedAmount));
				formData.append('releasedAmountFile', this.state.newRelease.imageFile);

				if(this.state.newRelease.amount > (this.state.allocatedAmount - this.state.releasedAmount)) {
					swal({
						title: 'Are you sure?',
            text: "You are about to invest Rs." + ((this.state.allocatedAmount - this.state.releasedAmount) - this.state.newRelease.amount) + "more than allocated amount.",
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Change!',
            cancelButtonText: 'No, cancel!',
            confirmButtonClass: 'btn',
            cancelButtonClass: 'btn',
          }).then(function(result) {
            if(result) {
            	let companyId = JSON.parse(readCookie('userData')).organisationData.id;
            	fetch(process.env.REACT_APP_API_URL + '/company/' + companyId + '/mis/investment/' + this.state.investmentId, {
            		method: "PUT",
            		headers: {
            			'Auth': JSON.parse(readCookie('access_token')).access_token
            		},
            		body: formData
            	}).then(function (response) {
            		return response.json();
            	}).then(function (data) {
            		if (data.status === 'ok') {
            			this.notifySuccess('Successfully updated your investment.');
            			var that = this;
            			setTimeout(function() {
            				window.location.pathname = '/mis-projects/' + that.state.customUrl;
            			}, 1500);
            		} else this.notifyError('The investment could not be saved!');
            	}.bind(this));
            }
          }.bind(this));
				} else {
					let companyId = JSON.parse(readCookie('userData')).organisationData.id;
					fetch(process.env.REACT_APP_API_URL + '/company/' + companyId + '/mis/investment/' + this.state.investmentId, {
						method: "PUT",
						headers: {
							'Auth': JSON.parse(readCookie('access_token')).access_token
						},
						body: formData
					}).then(function (response) {
						return response.json();
					}).then(function (data) {
						if (data.status === 'ok') {
							this.notifySuccess('Successfully updated your investment.');
							var that = this;
							setTimeout(function() {
								window.location.pathname = '/mis-projects/' + that.state.customUrl;
							}, 1500);
						} else this.notifyError('The investment could not be saved!');
					}.bind(this));
				}
			} else this.notifyError('Please fill all the details correctly.');
		} else this.notifyError('Please fill all the details correctly.');
	}

	amountFormatter(cell, row) {
		if(!isNaN(cell)) return 'INR ' + window.inrFormat(parseFloat(cell));
		else return 'INR ' + 0;
	}

	disbursementConditionFormatter(cell, row) {
		if(cell === '') return 'Select Disbursement Condition';
		else return cell;
	}

	disbursementCriteriaFormatter(cell, row) {
		if(row.disbursementCondition === '') return 'Select Disbursement Condition First';
		else if(row.disbursementCondition === 'Periodic') return 'N/A';
		else if(cell === '') return 'Enter Disbursement Criteria';
		else return cell;
	}

	selectDateFormatter(cell, row) {
		if(cell === '') return 'Select Tentative Date';
		else if(cell && moment(cell).isValid() && cell.match(/....-..-../)) return moment(cell, 'YYYY-MM-DD').format('DD/MM/YYYY');
		else if(cell && moment(cell, 'DD/MM/YYYY').isValid() && cell.match(/..\/..\/..../)) return moment(cell, 'DD/MM/YYYY').format('DD/MM/YYYY');
		else return '';
	}

	render() {
		if (this.state.status !== null && this.state.amountBreakupData !== null && this.state.singlePortfolio !== null && this.state.singlePortfolioGoal !== null && this.state.singlePortfolioAgendas !== null && this.state.otAttributes !== null && this.state.selectedBeneficiaryTypes.length) {
			if (!this.state.showAddReleasedAmountForm && !this.state.showEditInvestmentForm) {
				return (
					<div className="mis-content-container edit-investment">
						<ToastContainer position="bottom-center" autoClose={4000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={false} />
						<h3 className="mb20 text-center">{this.state.selectedProject[0]}</h3>
						<Col md={6}>
							<div className="edit-investment-button-container">
								<div className="edit-investment-button" onClick={this.showForm.bind(this, 'showEditInvestmentForm')}>Edit Investment</div>
							</div>
						</Col>
						<Col md={6}>
							<div className="edit-investment-button-container">
								<div className="edit-investment-button" onClick={this.showForm.bind(this, 'showAddReleasedAmountForm')}>Add Released Amount</div>
							</div>
						</Col>
					</div>
				)
			} else if (this.state.showAddReleasedAmountForm && this.state.status === 1 && this.state.corporateInterest !== null && this.state.corporateInterest) {
				return (
					<div className="mis-content-container">
						<ToastContainer position="bottom-center" autoClose={4000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={false} />
						<h3 className="mb20 text-center">{this.state.selectedProject[0]}</h3>
						<Col md={6} className="add-single-portfolio-project-buttons mt10 mb20">
							<span className="single-portfolio-allocated-amount">Total Amount Invested: Rs. {this.state.releasedAmount}</span>
						</Col>
						{this.state.allocatedAmount && typeof this.state.allocatedAmount === "number" && typeof this.state.releasedAmount === "number" ? (
							<Col md={6} className="add-single-portfolio-project-buttons text-right mt10 mb20">
								<span className="single-portfolio-allocated-amount">Total Remaining Investment: Rs. {(this.state.allocatedAmount - this.state.releasedAmount)}</span>
							</Col>
						) : (null)}
						<Row className="add-released-amount-form mb10">
							<Col md={this.state.corporateInterest.disbursementType === 'Tranches' ? 3 : 4} className="add-single-portfolio-project-form-item mt10 mb10">
								<label className="col-md-12 is-imp">Amount Invested:</label>
								<Col md={12}>
									<input type="number" className="form-control" onChange={this.updateInvestmentAmount.bind(this)} placeholder="0" />
								</Col>
							</Col>
							<Col md={this.state.corporateInterest.disbursementType === 'Tranches' ? 3 : 4} className="add-single-portfolio-project-form-item mt10 mb10">
								<label className="col-md-12 is-imp">Date of Investment:</label>
								<Col md={12}>
									<LocaleProvider locale={enUS}>
										<DatePicker locale={enUS} size="large" onChange={this.updateInvestmentDate.bind(this)} />
									</LocaleProvider>
								</Col>
							</Col>
							<Col md={this.state.corporateInterest.disbursementType === 'Tranches' ? 3 : 4} className="add-single-portfolio-project-form-item mt10 mb10">
								<label className="col-md-12 is-imp">Choose Amount Breakup:</label>
								<Col md={12}>
									<Multiselect id="singlePortfolioGoal" buttonClass="singlePortfolioGoal btn btn-default"
										onChange={this.updateInvestmentBreakup.bind(this)}
										buttonText={this.showButtonText} multiple
										buttonWidth={'100%'} data={this.state.amountBreakupData} />
								</Col>
							</Col>
							{this.state.corporateInterest.disbursementType === 'Tranches' ? (
								<Col md={this.state.corporateInterest.disbursementType === 'Tranches' ? 3 : 4} className="add-single-portfolio-project-form-item mt10 mb10">
									<label className="col-md-12 is-imp">Mode:</label>
									<Col md={12}>
										<Select id="mode" defaultValue={""} size="large" onChange={this.updateInvestmentMode.bind(this)}>
											<Option disabled value="">None Selected</Option>
											<Option value="Cheque">Cheque</Option>
											<Option value="Cash">Cash</Option>
											<Option value="Bank Transfer">Bank Transfer</Option>
										</Select>
									</Col>
								</Col>
							) : (null)}
						</Row>
						<Row className="add-released-amount-form mb10">
							{this.state.corporateInterest.disbursementType !== 'Tranches' ? (
								<Col md={4} className="add-single-portfolio-project-form-item mt10 mb10">
									<label className="col-md-12 is-imp">Mode:</label>
									<Col md={12}>
										<Select id="mode" defaultValue={""} size="large" onChange={this.updateInvestmentMode.bind(this)}>
											<Option disabled value="">None Selected</Option>
											<Option value="Cheque">Cheque</Option>
											<Option value="Cash">Cash</Option>
											<Option value="Bank Transfer">Bank Transfer</Option>
										</Select>
									</Col>
								</Col>
							) : (null)}
							{this.state.newRelease.mode !== 'Cash' ? (
								<Col md={4} className="add-single-portfolio-project-form-item mt10 mb10">
									<label className="col-md-12">Cheque/UTR No:</label>
									<Col md={12}>
										<input type="number" className="form-control" onChange={this.updateInvestmentUtr.bind(this)} placeholder="0" />
									</Col>
								</Col>
							) : (null)}
							<Col md={4} className="add-single-portfolio-project-form-item mt10 mb10">
								<label className="col-md-12">Upload scanned copy for reference:</label>
								<Col md={12}>
									<input type="file" className="form-control" style={{ fontSize: '12px', padding: '5px' }} onChange={this.updateInvestmentScanCopy.bind(this)} placeholder="0" />
								</Col>
							</Col>
							{this.state.corporateInterest.disbursementType === 'Tranches' ? (
								<Col md={4} className="add-single-portfolio-project-form-item mt10 mb10">
									<label className="col-md-12">Select Tranch:</label>
									<Col md={12}>
										<Select id="mode" defaultValue={""} size="large" onChange={this.updateInvestmentTranch.bind(this)}>
											<Option disabled value="">None Selected</Option>
											{this.state.corporateInterest.tranches.map(function(tranch, index) {
												return (
													<Option value={index + 1}>{'Tranch ' + (index + 1)}</Option>
												)
											})}
										</Select>
									</Col>
								</Col>
							) : (null)}
						</Row>
						<Row md={12} className="add-released-amount-form mb20">
							<Col md={12} className="add-single-portfolio-project-form-item mt10">
								<label className="col-md-12 is-imp">Comment:</label>
								<Col md={12}>
									<textarea className="comment" onChange={this.updateInvestmentComment.bind(this)}></textarea>
								</Col>
							</Col>
						</Row>
						<Col md={12} className="mt10 mb10">
							<button className="btn btn-default pull-right" onClick={this.cancelProject.bind(this, 'releasedAmountForm')}>Cancel</button>
							<button className="btn btn-default pull-right" onClick={this.saveRelease.bind(this)} style={{ background: "#ef5a20", color: "#ffffff" }}>Save</button>
						</Col>
						{this.state.corporateInterest.disbursementType === 'Tranches' ? (
							<Col md={12} className="mt10 mb10 tranch-disbursementCriteria">
								<h3>Tranch Disbursements</h3>
								<BootstrapTable data={this.state.corporateInterest.tranches} striped={false} ref='tranches-table' hover={true}>
									<TableHeaderColumn dataField='slno' width='80' headerAlign='center' dataAlign='center' isKey={true}>Tranches</TableHeaderColumn>
						      <TableHeaderColumn dataField='amount' width='160' headerAlign='center' dataAlign='center' dataFormat={this.amountFormatter.bind(this)}>Planned Amount</TableHeaderColumn>
						      <TableHeaderColumn dataField='disbursementCondition' headerAlign='center' dataAlign='center' dataFormat={this.disbursementConditionFormatter.bind(this)}>Disbursement Condition</TableHeaderColumn>
						      <TableHeaderColumn dataField='disbursementCriteria' headerAlign='center' dataAlign='center' dataFormat={this.disbursementCriteriaFormatter.bind(this)}>Disbursement Criteria</TableHeaderColumn>
						      <TableHeaderColumn dataField='date' width='180' headerAlign='center' dataAlign='center' dataFormat={this.selectDateFormatter.bind(this)}>Tentative Disbursement Date</TableHeaderColumn>
								</BootstrapTable>
							</Col>
						) : (null)}
					</div>
				)
			} else if (this.state.showEditInvestmentForm && this.state.status === 0) {
				return (
					<div className="mis-content-container">
						<ToastContainer position="bottom-center" autoClose={4000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={false} />
						<h3 className="mb20 text-center">{this.state.singlePortfolioGoal.name}</h3>
						<Col md={12} className="add-single-portfolio-project-buttons">
							<span className="single-portfolio-allocated-amount">Total Amount Allocated: Rs. {this.state.singlePortfolioAmount}</span>
						</Col>
						<Col md={12} className={this.state.showForm ? "add-single-portfolio-project-form formShowed mb20" : "add-single-portfolio-project-form mb20"}>
							<Col md={6} className="add-single-portfolio-project-form-item mt10 mb10">
								<label className="col-md-12 is-imp">Select Agenda:</label>
								<Col md={12}>
									<Select id="agenda-selector" defaultValue={this.state.selectedAgenda.id} size="large" onChange={this.changeSelectedAgenda}>
										<Option disabled value="">None Selected</Option>
										{this.state.singlePortfolioAgendas.map(function (agenda, index) {
											return <Option key={index} value={agenda.id}>{agenda.name}</Option>
										})}
									</Select>
								</Col>
							</Col>
							<Col md={6} className="add-single-portfolio-project-form-item mt10 mb10">
								<label className="col-md-12 is-imp">Select the NGO deploying the funds:</label>
								<Col md={12}>
									<Select id="ngo-selector" showSearch defaultValue={this.state.selectedNgo.id} size="large" onChange={this.changeSelectedNgo}
										filterOption={(input, option) => option.state.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} optionFilterProp="children">
										<Option disabled value="">None Selected</Option>
										{this.state.allNGOs.map(function (ngo, index) {
											return <Option key={index} value={ngo.id}>{ngo.name}</Option>
										})}
									</Select>
								</Col>
							</Col>
							<Col md={6} className="add-single-portfolio-project-form-item mt10 mb10">
								<label className="col-md-12 is-imp">Select the Project / Add new Project:</label>
								<Col md={12}>
									<LocaleProvider locale={enUS}>
										<Select id="project-selector" value={this.state.selectedProject} size="large" placeholder="None Selected" mode="tags" onChange={this.changeSelectedProject}>
											{this.state.allProjects.map(function (project, index) {
												return <Option key={index} value={project.id}>{project.name}</Option>
											})}
										</Select>
									</LocaleProvider>
								</Col>
							</Col>
							<div className="break"></div>
							{(() => {
								if (this.state.selectedProject.length === 1) {
									let foundProject = false;
									let foundProjectIndex = -1;
									for (var i = 0; i < this.state.allProjects.length; i++) {
										if (this.state.allProjects[i].id === this.state.selectedProject[0]) {
											foundProject = true;
											foundProjectIndex = i;
											break;
										}
									}
									if (foundProject) {
										return <Col md={12} className="add-single-portfolio-project-form-item mt10 mb10">
											<label className="col-md-12">Project Description:</label>
											<Col md={12}>
												<textarea id="project-description-read" readOnly value="Found Project Description"></textarea>
											</Col>
										</Col>
									} else {
										return <Col md={12} className="add-single-portfolio-project-form-item mt10 mb10">
											<label className="col-md-12 is-imp">Project Description:</label>
											<Col md={12}>
												<textarea id="project-description" defaultValue={this.state.selectedProjectDescription} onChange={this.changeSelectedProjectDescription}></textarea>
											</Col>
										</Col>
									}
								}
							})()}

							{(() => {
								if (this.state.selectedProject.length === 1) {
									let foundProject = false;
									let foundProjectIndex = -1;
									for (var i = 0; i < this.state.allProjects.length; i++) {
										if (this.state.allProjects[i].id === this.state.selectedProject[0]) {
											foundProject = true;
											foundProjectIndex = i;
											break;
										}
									}
									if (foundProject) {
										return <Col md={4} className="add-single-portfolio-project-form-item mt10 mb10">
											<label className="col-md-12 is-imp">Select Location(s) of deployment:</label>
											<Col md={12}>
												<Multiselect id="singlePortfolioGoal" buttonClass="singlePortfolioGoal btn btn-default"
													onChange={this.handleSelectChange.bind(this, 'selectedLocations')}
													buttonText={this.showButtonText} multiple
													buttonWidth={'100%'} data={this.state.multiselectData} />
											</Col>
										</Col>
									} else {
										let inputProps = {
											value: this.state.hasProjectLocation.preferenceLocation,
											onChange: this.onPreferenceLocationChange.bind(this),
										};

										const options = {
											types: ['(regions)'],
										};

										let cssClasses = {
											input: 'location form-control ' + this.state.hasProjectLocation.preferenceLocationError,
											autocompleteContainer: 'my-autocomplete-container'
										};

										return <Col md={4} className="add-single-portfolio-project-form-item mt10 mb10">
											<label className="col-md-12 is-imp">Select Location(s) of deployment:</label>
											<Col md={12}>
												<PlacesAutocomplete inputProps={inputProps} classNames={cssClasses} options={options} />
											</Col>
										</Col>
									}
								}
							})()}

							{(() => {
								if (this.state.selectedProject.length === 1) {
									let foundProject = false;
									let foundProjectIndex = -1;
									for (var i = 0; i < this.state.allProjects.length; i++) {
										if (this.state.allProjects[i].id === this.state.selectedProject[0]) {
											foundProject = true;
											foundProjectIndex = i;
											break;
										}
									}
									if (!foundProject) {
										return <Col md={4} className="add-single-portfolio-project-form-item mt10 mb10">
											<label className="col-md-12 is-imp">Beneficiary Types:</label>
											<Col md={12}>
												<Multiselect id="singlePortfolioGoal" buttonClass="singlePortfolioGoal btn btn-default"
													onChange={this.handleSelectChange.bind(this, 'selectedBeneficiaryTypes')}
													buttonText={this.showButtonText} multiple value={this.state.selectedBeneficiaryTypes}
													buttonWidth={'100%'} data={this.state.otAttributes.beneficiaryTypes} />
											</Col>
										</Col>
									}
								}
							})()}

							{(() => {
								if (this.state.selectedProject.length === 1) {
									let foundProject = false;
									let foundProjectIndex = -1;
									for (var i = 0; i < this.state.allProjects.length; i++) {
										if (this.state.allProjects[i].id === this.state.selectedProject[0]) {
											foundProject = true;
											foundProjectIndex = i;
											break;
										}
									}
									if (!foundProject) {
										return <Col md={4} className="add-single-portfolio-project-form-item mt10 mb10">
											<label className="col-md-12">Duration of Project:</label>
											<Col md={12} className="project-duration-selector">
												<input type="number" className="form-control" defaultValue={this.state.selectedProjectDuration.duration} onChange={this.changeProjectDuration} />
												<Select id="project-duration-type-selector" defaultValue={this.state.selectedProjectDuration.type} size="large" onChange={this.changeProjectDurationType}>
													<Option disabled value="">None Selected</Option>
													<Option value="days">Days</Option>
													<Option value="weeks">Weeks</Option>
													<Option value="months">Months</Option>
													<Option value="years">Years</Option>
												</Select>
											</Col>
										</Col>
									}
								}
							})()}

							{(() => {
								if (this.state.selectedProject.length === 1) {
									let foundProject = false;
									let foundProjectIndex = -1;
									for (var i = 0; i < this.state.allProjects.length; i++) {
										if (this.state.allProjects[i].id === this.state.selectedProject[0]) {
											foundProject = true;
											foundProjectIndex = i;
											break;
										}
									}
									if (!foundProject) {
										return <Col md={12} className="add-single-portfolio-project-form-item mt10 mb10">
											<label className="col-md-12 is-imp">Expected Impact:</label>
											<Col md={12}>
												<textarea id="project-expected-impact" defaultValue={this.state.projectExpectedImpact} onChange={this.changeProjectExpectedImpact}></textarea>
											</Col>
										</Col>
									}
								}
							})()}

							<Col md={12} className="add-single-portfolio-project-form-item mt10 mb10">
								<label className="col-md-12 is-imp">Enter KPIs or Copy:</label>
								<Col md={12}>
									{(() => {
										if (this.state.selectedKPIs.length !== 0) {
											return <Multiselect id="singlePortfolioGoal" buttonClass="singlePortfolioGoal btn btn-default"
												onChange={this.handleSelectChange.bind(this, 'selectedKPIs')}
												buttonText={this.showButtonText} multiple value={this.state.selectedKPIs}
												buttonWidth={'100%'} data={this.state.allKPIs} />
										} else {
											return <Multiselect id="singlePortfolioGoal" buttonClass="singlePortfolioGoal btn btn-default"
												onChange={this.handleSelectChange.bind(this, 'selectedKPIs')}
												buttonText={this.showButtonText} multiple
												buttonWidth={'100%'} data={this.state.allKPIs} />
										}
									})()}
								</Col>
							</Col>

							{(() => {
								if (this.state.selectedProject.length === 1) {
									let foundProject = false;
									let foundProjectIndex = -1;
									for (var i = 0; i < this.state.allProjects.length; i++) {
										if (this.state.allProjects[i].id === this.state.selectedProject[0]) {
											foundProject = true;
											foundProjectIndex = i;
											break;
										}
									}
									if (!foundProject) {
										return <Col md={4} className="add-single-portfolio-project-form-item mt10 mb10">
											<label className="col-md-12">Expected Start Date:</label>
											<Col md={12}>
												<LocaleProvider locale={enUS}>
													<DatePicker locale={enUS} defaultValue={moment(this.state.projectStartDate, 'DD/MM/YYYY')} format={'DD/MM/YYYY'} onChange={this.onStartDateChange.bind(this)} />
												</LocaleProvider>
											</Col>
										</Col>
									}
								}
							})()}

							<Col md={4} className="add-single-portfolio-project-form-item mt10 mb10">
								<label className="col-md-12 is-imp">Define reporting frequency:</label>
								<Col md={12}>
									<Select id="reporting-freq-selector" defaultValue={this.state.reportingFrequency} size="large" onChange={this.changeSelectedReportingFreq}>
										<Option disabled value="">None Selected</Option>
										{this.state.otAttributes.reportingFrequency.map(function (freq, index) {
											return <Option key={index} value={freq.value}>{freq.label}</Option>
										})}
									</Select>
								</Col>
							</Col>
							<Col md={4} className="add-single-portfolio-project-form-item mt10 mb10">
								<label className="col-md-12 is-imp">Enter allocated amount:</label>
								<Col md={12}>
									<span className="allocated-amount-span">
										<input type="number" className="form-control" defaultValue={this.state.allocatedAmount} onChange={this.updateAllocatedAmount} />
										<Icon icon={ic_done} onClick={this.showAmountBreakupTable} title="Click to Show " />
									</span>
								</Col>
							</Col>
							{this.state.amountBreakupTableVisibility && this.state.amountBreakup !== null ? (
								<Col md={12} className="mt10">
									<span className="amount-breakup-table-note mb10"># Note: Please fill the table to assign amounts for different cost heads resulting to a total of the enter allocated amount.</span>
									<AmountBreakupTable addNewBreakup={this.addNewBreakup} updateAmountBreakupName={this.updateAmountBreakupName}
										updateAmountBreakupCosthead={this.updateAmountBreakupCosthead} amountBreakup={this.state.amountBreakup}
										updateAmountBreakupAmount={this.updateAmountBreakupAmount} removeAmountBreakup={this.removeAmountBreakup}
										costHeads={this.state.costHeads} />
									{/*<CostTable allocatedAmount={this.state.allocatedAmount} costAllocation={this.state.costAllocation}
										addNewInvestment={this.state.addNewInvestment} updateInvestmentAmount={this.state.updateInvestmentAmount}
										updateInvestmentDate={this.state.updateInvestmentDate} updateInvestmentComment={this.state.updateInvestmentComment}
										updateInvestmentMode={this.state.updateInvestmentMode} removeInvestment={this.state.removeInvestment} />*/}
								</Col>
							) : (null)}
							<Col md={12} className="mt10 mb10">
								<button className="btn btn-default btn-danger pull-right" onClick={this.cancelProject.bind(this, 'investmentForm')}>Cancel</button>
								<button className="btn btn-default pull-right" onClick={this.saveProject}>Save</button>
							</Col>
						</Col>
					</div>
				)
			}
		} else {
			return null;
		}
	}
}
