import React, {Component} from 'react';
import { Button, Row, Col } from "react-bootstrap";
import Modal from 'react-modal';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import $ from 'jquery';
import moment from 'moment';
import swal from 'sweetalert2';
import DayPicker, { DateUtils } from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';

const readCookie = require('../../../../cookie.js').readCookie;

export default class ExpressInterest extends Component {
	constructor(props) {
		super(props);
		this.state = {
			minVolunteers: 0,
			maxVolunteers: 0,
			selectedDates: []
		};
		this.change = this.change.bind(this);
	}

	updateState(name, value) {
		this.setState({ [name]: value });
	}

	change(selectedDates) {
		this.setState({ selectedDates });
	}

	submitExpressInterest() {
		if(this.state.selectedDates.length && this.state.minVolunteers >= 0 && this.state.maxVolunteers >= 0) {
			let userDataCookie = readCookie('userData');
			let userDataJSON = JSON.parse(userDataCookie);

			let selectedDates = this.state.selectedDates;
			for(let i = 0; i < selectedDates.length; i++) {
				selectedDates[i] = moment(selectedDates[i]).format('DD/MM/YYYY');
			}

			let data = {};
			data['companyId'] = userDataJSON.id;
			data['companySelectedDates'] = selectedDates;
			data['minVolunteers'] = this.state.minVolunteers;
			data['maxVolunteers'] = this.state.maxVolunteers;

			fetch(process.env.REACT_APP_API_URL + '/volunteer-opportunity/' + this.props.volunteerOpportunityId + '/interests', {
				method: "POST",
				headers: {
					'Content-Type': 'application/json',
					'Auth': JSON.parse(readCookie('access_token')).access_token
				},
				body: JSON.stringify(data)
			}).then(function(response) {
				return response.json();
			}).then(function(data) {
				if(data.status === 'ok') {
					swal(
						'Awesome!',
						'Your interest in this project has been taken into account.',
						'success'
					);
					this.props.onExpressInterestModalClose();
				}
			}.bind(this))
		}
	}

	handleDayClick(day, { selected }) {
		const { selectedDates } = this.state;
		if (selected) {
			const selectedIndex = selectedDates.findIndex(selectedDate =>
				DateUtils.isSameDay(selectedDate, day)
			);
			selectedDates.splice(selectedIndex, 1);
		} else {
			selectedDates.push(day);
		}
		this.setState({ selectedDates });
	}

	render() {
		return (
			<div>
				<Modal isOpen={this.props.showExpressInterestModal} contentLabel="Express Interest"
				       onRequestClose={this.props.onExpressInterestModalClose}>
					<h2 ref={subtitle => this.subtitle = subtitle}>Express Interest</h2>

					<Row>
						<form name="expressInterestForm" id="expressInterestForm">
							<Col md={8}>
								<Row>
									<Col className="form-group" md={6}>
										<label className="control-label is-imp">Select Min Voluteers:</label>
										<input type="number"
										       className="form-control"
										       id="min_volunteers"
										       name="min_volunteers"
										       value={this.state.minVolunteers}
										       min="0"
										       placeholder="Min Volunteers"
										       required
										       onChange={(e) => {this.updateState('minVolunteers', e.target.value)}} />
									</Col>
									<Col className="form-group" md={6}>
										<label className="control-label is-imp">Select Max Voluteers:</label>
										<input type="number"
										       className="form-control"
										       id="max_volunteers"
										       name="max_volunteers"
										       value={this.state.maxVolunteers}
										       min="0"
										       placeholder="Max Voluteers"
										       required
										       onChange={(e) => {this.updateState('maxVolunteers', e.target.value)}} />
									</Col>
								</Row>
							</Col>
							<Col md={8}>
								<Row>
									<Col md={12}>
										<label className="control-label is-imp">Select Preferable Dates:</label>
										<DayPicker selectedDays={this.state.selectedDates} onDayClick={this.handleDayClick.bind(this)} disabledDays={this.props.unavailableDates} />
									</Col>
								</Row>
							</Col>
							<Col md={8} className="mt20">
								<Button className="btn btn-danger" onClick={this.props.onExpressInterestModalClose}>Cancel</Button>
								<Button className="btn btn-warning" onClick={this.submitExpressInterest.bind(this)}>Submit</Button>
							</Col>
						</form>
					</Row>
				</Modal>
			</div>
		);
	}
}