import React,{ Component } from 'react';
import {OverlayTrigger, Tooltip, Col, Row} from 'react-bootstrap';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import moment from 'moment';
import $ from 'jquery';
import { LocaleProvider } from 'antd';
import enUS from 'antd/lib/locale-provider/en_US';
import {BootstrapTable, TableHeaderColumn, ButtonGroup} from 'react-bootstrap-table';
import Multiselect from 'react-bootstrap-multiselect';
import Icon from 'react-icons-kit';
import { ic_add_circle_outline } from 'react-icons-kit/md/ic_add_circle_outline';
import { ic_delete } from 'react-icons-kit/md/ic_delete';
import { Select, InputNumber } from 'antd';

const Option = Select.Option;

const readCookie = require('../../../cookie.js').readCookie;

export default class AmountBreakupTable extends Component {
	constructor(props) {
    super(props);
    this.state = {
    	amountBreakup: this.props.amountBreakup
  	}
  }

	render() {
		return (
			<Row className="amount-breakup-table mb20">
				{(() => {
					if(this.props.amountBreakup.length) {
						if(this.props.amountBreakup[0].hasOwnProperty('readOnly')) {
							return (
								<Col md={12}>
									<Col md={4} className="text-center header">Investment Name</Col>
									<Col md={4} className="text-center header">Cost Head</Col>
									<Col md={4} className="text-center header">Amount Breakup</Col>
								</Col>
							)
						} else {
							return (
								<Col md={12}>
									<Col md={4} className="text-center header">Investment Name</Col>
									<Col md={4} className="text-center header">Cost Head</Col>
									<Col md={2} className="text-center header">Amount Breakup</Col>
									<Col md={2} className="text-center header">
										<button className="btn btn-default pull-right" onClick={this.props.addNewBreakup}>
											<Icon icon={ic_add_circle_outline} size={20} />
											Add Breakup
										</button>
									</Col>
								</Col>
							)
						}
					} else {
						return (
							<Col md={12}>
								<Col md={4} className="text-center header">Investment Name</Col>
								<Col md={4} className="text-center header">Cost Head</Col>
								<Col md={2} className="text-center header">Amount Breakup</Col>
								<Col md={2} className="text-center header">
									<button className="btn btn-default pull-right" onClick={this.props.addNewBreakup}>
										<Icon icon={ic_add_circle_outline} size={20} />
										Add Breakup
									</button>
								</Col>
							</Col>
						)
					}
				})()}
				{this.props.amountBreakup.map(function(breakup, index) {
					if(breakup.hasOwnProperty('readOnly')) {
						return (
							<Col className="amount-breakup" key={index} md={12}>
								<Col md={4}>
									<span>{breakup.description}</span>
								</Col>
								<Col md={4}>
									<span>{breakup.costHead}</span>
								</Col>
								<Col md={4}>
									<span>{breakup.amount}</span>
								</Col>
							</Col>
						);
					} else {
						return (
							<Col className="amount-breakup" key={index} md={12}>
								<Col md={4}>
									<input type="text" className="form-control" placeholder="Description" defaultValue={breakup.name} onChange={this.props.updateAmountBreakupName.bind(this, index)} />
								</Col>
								<Col md={4}>
									<Select id="breakup-costhead-selector" defaultValue={breakup.costHead} size="large" onChange={this.props.updateAmountBreakupCosthead.bind(this, index)}>
										<Option disabled value="">None Selected</Option>
										{this.props.costHeads.map(function(costhead, index) {
											return <Option key={index} value={costhead.value}>{costhead.label}</Option>
										})}
									</Select>
								</Col>
								<Col md={2}>
									<input type="number" defaultValue={breakup.amount} onChange={this.props.updateAmountBreakupAmount.bind(this, index)} />
								</Col>
								<Col md={2}>
									<Icon icon={ic_delete} size={24} onClick={this.props.removeAmountBreakup.bind(this, index)} />
								</Col>
							</Col>
						);
					}
				}.bind(this))}
				<Col md={12}>

				</Col>
			</Row>
		);
	}
}