import React, { Component } from "react";
import {BootstrapTable, TableHeaderColumn, ButtonGroup} from 'react-bootstrap-table';
import swal from "sweetalert2";
import { ToastContainer, toast } from 'react-toastify';
import { Col, Row, Tab, Tabs, Modal } from 'react-bootstrap';
import moment from 'moment';
import Trends from './Trends';

const checkValue = require('../../../checkValue.js').checkValue;
const readCookie = require('../../../cookie.js').readCookie;
const math = require('mathjs');
let order = 'asc';

class Indicators extends Component {
	constructor(props){
		super(props);
		this.options = {
      defaultSortName: 'name',
      defaultSortOrder: 'asc'
    };
		this.state = {
			showToolTip: false,
			selectRowProp: {
        mode: 'checkbox',
        onSelect: this.onRowSelect.bind(this),
        onSelectAll: this.onAllRowSelect.bind(this)
      },
      budgetEditorModalVisibility: false,
      selectedRowCount: 0,
      deleteButtonDisabled: true,
			addNewCategory: false,
			isEdit: false,
			sample_size: [
				{
					"unit": "Number",
					"symbol": "None",
					"dataType": "Number"
				},
				{
					"unit": "kilogram",
					"symbol": "kg",
					"dataType": "float"
				},
				{
					"unit": "litre",
					"symbol": "L",
					"dataType": "float"
				},
				{
					"unit": "gram",
					"symbol": "gm",
					"dataType": "float"
				},
				{
					"unit": "Percentage",
					"symbol": "%",
					"dataType": "float"
				},
				{
					"unit": "feet",
					"symbol": "ft",
					"dataType": "float"
				},
				{
					"unit": "inches",
					"symbol": "in",
					"dataType": "float"
				},
				{
					"unit": "centimeter",
					"symbol": "cm",
					"dataType": "float"
				},
				{
					"unit": "meter",
					"symbol": "m",
					"dataType": "float"
				},
				{
					"unit": "Sq meter",
					"symbol": "sq.m.",
					"dataType": "float"
				},
				{
					"unit": "Sq Kilometer",
					"symbol": "sq.km.",
					"dataType": "float"
				},
				{
					"unit": "Hectare",
					"symbol": "ha.",
					"dataType": "float"
				},
				{
					"unit": "Acres",
					"symbol": "ac",
					"dataType": "float"
				}
			],
			categoryName: "",
			categoryDescription: "",
			isLoading: true,
			kpiId:"",
			saveBtnName:"",
			indicatorRows:[],
			activeTab: 1,
			addToExistingCluster: false,
			addToNewCluster: false,
			selectedKpis:[],
			selectedClusterId: "",
			clusters:[],
			modalName: "",
			viewCusterData:"",
			viewCusterDataId:"",
			nonEditedClusterdata:"",
			clusterIndicatorRows:[],
			newClusterIndicatorRows: [],
			clusterIndicatorRowId:"",
			editVIewClusterModal: false,
		}
	}

	notifySuccess = (text) => toast.success(text);
  notifyError = (text) => toast.error(text);

	addNewCategory = () => {
		this.setState({addNewCategory: true});
	}

	componentDidMount () {
		if (this.props.match.params.hasOwnProperty('indicator_type') && this.props.match.params.indicator_type === 'clusters') {
			this.setState({activeTab: 2});
		} else if (this.props.match.params.hasOwnProperty('indicator_type') && this.props.match.params.indicator_type === 'trends') {
			this.setState({activeTab: 3});
		} else {
			this.setState({activeTab: 1});
		}
		let indicatorRows = [];
		fetch (process.env.REACT_APP_API_URL + "/kpi-indicator",{
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
		.then((data) => {
			for (let i = 0; i < data.data.length; i++) {
				indicatorRows.push({
					id:data.data[i]["indicatorId"],
					outputOutcome:data.data[i]["type"],
					unit: data.data[i]["unit"],
					indicator:data.data[i]["name"],
					format: data.data[i]["format"],
					kpiId: data.data[i]["id"],
					mode: "view",
					editOutputOutcome: data.data[i]["type"],
					editUnit: data.data[i]["unit"],
					editIndicator:data.data[i]["name"],
					editFormat:data.data[i]["format"],
					formula: data.data[i]["formula"].replace('{{id}}', '{{value}}'),
					editFormulaInput: data.data[i]["formula"].replace('{{id}}', '{{value}}'),
					durationalCumulative: data.data[i]["computationType"],
					editDurationalCumulative: data.data[i]["computationType"]
				})
			}
			this.setState({indicatorRows});
		});

		fetch (process.env.REACT_APP_API_URL + "/kpi-category",{
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
		.then((data) => {
			let clusters = [];
			clusters = (data.data);
			this.setState({clusters});
		});
	}

	handleBtnClick = () => {
    if (order === 'desc') {
      this.refs.table.handleSort('asc', 'name');
      order = 'asc';
    } else {
      this.refs.table.handleSort('desc', 'name');
      order = 'desc';
    }
  }

  handleBtnClicks = () => {
    if (order === 'desc') {
      this.refs.cluster.handleSort('asc', 'name');
      order = 'asc';
    } else {
      this.refs.cluster.handleSort('desc', 'name');
      order = 'desc';
    }
  }

  handleBtnClickClusterIndicator = () => {
    if (order === 'desc') {
      this.refs.cluster_indicator.handleSort('asc', 'name');
      order = 'asc';
    } else {
      this.refs.cluster_indicator.handleSort('desc', 'name');
      order = 'desc';
    }
  }

	hideBudgetEditorModal = () => {
		this.setState({budgetEditorModalVisibility:false, categoryName:"", categoryDescription:"", showToolTip: false, clusterIndicatorRows: [], editVIewClusterModal: false });
	}

	onRowSelect(row, isSelected) {
		if (row.mode === "view") {
			let selectedKpis = this.state.selectedKpis;
			let index = selectedKpis.indexOf(row.kpiId);
			if(index > -1){
				selectedKpis.splice(index,1);
			} else {
				selectedKpis.push(row.kpiId);
			}
			this.setState({selectedKpis})
	    if (isSelected) {
	      this.setState({ selectedRowCount: this.refs.table.state.selectedRowKeys.length + 1 }, function () {
	        if (this.state.selectedRowCount) {
	          this.setState({ deleteButtonDisabled: false });
	        }
	      });
	    } else {
	      this.setState({ selectedRowCount: this.refs.table.state.selectedRowKeys.length - 1, selectedKpis }, function () {
	        if (this.state.selectedRowCount) {
	          this.setState({ deleteButtonDisabled: false });
	        } else {
	          this.setState({ deleteButtonDisabled: true });
	        }
	      });
	    }
		} else {
			this.notifyError("Can't select your are still in edit mode, Please save the KPI.")
		}
  }

  onAllRowSelect(row, isSelected) {
  	let indicatorRows = this.state.indicatorRows;
  	let flag = false;
		for (let i = 0; i < indicatorRows.length; i++) {
			if (indicatorRows[i]["mode"] === "edit") {
				flag = true;
				break;
			}
		}
		if (flag === false) {
	  	let selectedKpis = this.state.selectedKpis;
	    if (row) {
	    	isSelected.map((kpi, i) => {
	    		selectedKpis.push(kpi.kpiId);
	    	})
	      this.setState({
	        selectedRowCount: this.refs.table.state.data.length,
	        deleteButtonDisabled: false,
	        selectedKpis
	      });
	    } else {
	    	selectedKpis = [];
	      this.setState({
	        selectedRowCount: 0,
	        deleteButtonDisabled: true,
	        selectedKpis
	      });
	    }
		} else {
			this.notifyError("Can't select your are still in edit mode, please save the KPI's")
		}
  }

	addNewIndicatorRowData = (id, e) => {
		let inputName = e.target.name;
		let indicatorRows = this.state.indicatorRows;
		for (let i = 0; i < indicatorRows.length; i++) {
			if (id === indicatorRows[i]["id"]) {
				indicatorRows[i][inputName] = e.target.value;
			}
		}
		this.setState({indicatorRows});
	}

	addNewIndicatorRow = () => {
		let indicatorRows = this.state.indicatorRows;
		fetch(process.env.REACT_APP_API_URL + "/kpi-indicator", {
			method: "POST",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
		.then((data) => {
			if(data.status === "ok"){
				indicatorRows.push({
					id: data.data.indicatorId,
					outputOutcome:"",
					unit:"",
					indicator:"",
					format:"",
					durationalCumulative:"",
					reportingFrequency:"",
					kpiId: data.data.id,
					mode: "edit",
					editOutputOutcome: "",
					editUnit:"",
					editIndicator:"",
					editFormat:"",
					editDurationalCumulative: "",
					formula: "",
					editFormulaInput: ""
				});
				this.setState({indicatorRows});
			}
		})
	}

	editDeleteIndicatorbutton = ( cell, row) => {
		let flag = false;
		return(
			<div style={{display: "flex", justifyContent: "center"}}>
				{this.state.indicatorRows.map((data ,i) => {
					if (data.mode === "edit" && data.id === row.id) {
						return flag = true;
					}
				})}
				{flag === true ? (
					<div>
						{/*<i className="fa fa-check edit-indicator-row" onClick={this.okIndicatorRow.bind(this, row.id)}></i>*/}
						<i className="fa fa-times edit-indicator-row" onClick={this.cancelIndicatorRow.bind(this, row.id)}></i>
					</div>
				) : (<i className="fa fa-pencil edit-indicator-row" onClick={this.editIndicatorRow.bind(this, row.id)}></i>)}
				<i className="fa fa-trash delete-indicator-row" onClick={this.deleteIndicatorRow.bind(this, row.id)}></i>
			</div>
		)
	}

	editDeleteClusterIndicatorbutton = (cell, row) => {
		return(
			<div style={{display: "flex", justifyContent: "center"}}>
				<i className="fa fa-trash delete-indicator-row" onClick={this.deleteClusterIndicatorRow.bind(this, row.id, row)}></i>
			</div>
		)
	}

	deleteIndicatorRow = (id) => {
		swal({
			html: "<h4> Deleting the KPIs here would remove the KPIs from your clusters and the partner's view as well. </h4> <br> <h4> Your NGO partner may have to add the KPI manually </h4> <br> <h4> Are you sure? </h4>",
			type: 'warning',
			showCancelButton: true,
		  confirmButtonText: 'Yes, Delete the KPI',
		  showLoaderOnConfirm: true,
		  cancelButtonText: "No, Keep them"
     })
     .then(function(response) {
     	if (response.value === true) {
				let index = 0;
				let indicatorRows = this.state.indicatorRows;
				let selectedKpis = this.state.selectedKpis;
				for (let i = 0; i < indicatorRows.length; i++) {
					if (id === indicatorRows[i]["id"]) {
						index = i;
						break;
					}
				}
				if (index > -1) {
					fetch (process.env.REACT_APP_API_URL + "/kpi-indicator/" + indicatorRows[index]["kpiId"], {
						method: "DELETE",
						headers: {
							'Auth': JSON.parse(readCookie('access_token')).access_token
						}
					}).then((data) => data.json())
					.then((data) => {
						if (data.status === "ok") {
							indicatorRows.splice(index,1);
							selectedKpis.splice(index,1);
							this.notifySuccess("Successfully Deleted");
						} else {
							this.notifyError('Failed to delete.')
						}
						this.setState({indicatorRows});
					})
				}
     	}
		}.bind(this));
	}

	deleteClusterIndicatorRow = (id, row) => {
		swal({
			html: "<h4>Are you sure you want to delete this KPI from this cluster? Your Partner NGOs would no longer see this KPI.</h4>",
			type: 'warning',
			showCancelButton: true,
		  confirmButtonText: 'Yes, Delete KPI from cluster',
		  showLoaderOnConfirm: true,
		  cancelButtonText: "No, Keep them"
     })
     .then(function(response) {
     	if (response.value === true) {
				let clusterIndicatorRows = this.state.clusterIndicatorRows;
				this.setState({
					clusterIndicatorRows: clusterIndicatorRows.filter(clusterIndicatorRow  => clusterIndicatorRow.kpiId !== row.kpiId),
					clusterIndicatorRowId: row.kpiId
				});
     	}
		}.bind(this));
	}

	saveClusterIndicators = () => {
		let clusterIndicatorRowsIDS = [];
		let clusterIndicatorRows = this.state.clusterIndicatorRows
		clusterIndicatorRows.map((data) => {
			return (
				clusterIndicatorRowsIDS.push(data.kpiId)
			)
		})
		let data = {allIndicators: clusterIndicatorRowsIDS, name: this.state.viewCusterData.name, description: this.state.viewCusterData.description}
		fetch (process.env.REACT_APP_API_URL + "/kpi-category/" + this.state.viewCusterDataId, {
			method: "PUT",
			headers: {
				'Content-type': 'application/json',
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
			body: JSON.stringify(data)
		}).then((data) => data.json())
		.then((data) => {
			if (data.status === "ok") {
				this.notifySuccess("Cluster Successfully Deleted");
				this.setState({editVIewClusterModal: false, newClusterIndicatorRows: clusterIndicatorRows});
			} else {
				this.notifyError('Failed to delete.')
			}
		})
	}

	cencelClusterIndicators = () => {
		this.setState({editVIewClusterModal: false, clusterIndicatorRows: this.state.newClusterIndicatorRows, viewCusterData: this.state.testData});
	}

	editIndicatorRow = (id) => {
		let indicatorRows = this.state.indicatorRows;
		for (let i = 0; i < indicatorRows.length; i++) {
			if (id === indicatorRows[i]["id"]) {
				indicatorRows[i]["mode"] = "edit"
			}
		}
		this.setState({indicatorRows});
	}

	// okIndicatorRow = (id) => {
	// 	let indicatorRows = this.state.indicatorRows;
	// 	for (let i = 0; i < indicatorRows.length; i++) {
	// 		if (id === indicatorRows[i]["id"]) {
	// 			if ((indicatorRows[i]["editFormat"] === "auto") && (id === indicatorRows[i]["id"]) && (indicatorRows[i]["editOutputOutcome"] !== "") && (indicatorRows[i]["editUnit"] !== "") && (indicatorRows[i]["editIndicator"] !== "") && (indicatorRows[i]["editFormat"] !== "") && (indicatorRows[i]["editFormulaInput"] !== "") && this.checkFormulaValid(indicatorRows[i]["editFormulaInput"]) && (indicatorRows[i]["editDurationalCumulative"] !== "")) {
	// 					indicatorRows[i]["outputOutcome"]= indicatorRows[i]["editOutputOutcome"];
	// 					indicatorRows[i]["unit"]= indicatorRows[i]["editUnit"];
	// 					indicatorRows[i]["indicator"]= indicatorRows[i]["editIndicator"];
	// 					indicatorRows[i]["format"]= indicatorRows[i]["editFormat"];
	// 					indicatorRows[i]["durationalCumulative"]= indicatorRows[i]["editDurationalCumulative"];
	// 					indicatorRows[i]["mode"]= "view";
	// 					indicatorRows[i]["formula"]= indicatorRows[i]["editFormulaInput"];
	// 					break;
	// 			}
	// 			 else if ((indicatorRows[i]["editFormat"] === "input") && (id === indicatorRows[i]["id"]) && (indicatorRows[i]["editOutputOutcome"] !== "") && (indicatorRows[i]["editUnit"] !== "") && (indicatorRows[i]["editIndicator"] !== "") && (indicatorRows[i]["editFormat"] !== "") && (indicatorRows[i]["editDurationalCumulative"] !== "")) {
	// 				indicatorRows[i]["outputOutcome"]= indicatorRows[i]["editOutputOutcome"];
	// 				indicatorRows[i]["unit"]= indicatorRows[i]["editUnit"];
	// 				indicatorRows[i]["indicator"]= indicatorRows[i]["editIndicator"];
	// 				indicatorRows[i]["format"]= indicatorRows[i]["editFormat"];
	// 				indicatorRows[i]["durationalCumulative"]= indicatorRows[i]["editDurationalCumulative"];
	// 				indicatorRows[i]["mode"]= "view";
	// 				indicatorRows[i]["formula"]= indicatorRows[i]["editFormulaInput"];
	// 				break;
	// 			} else {
	// 				this.notifyError("Please Provide Mandatory Values");
	// 			}
	// 		}
	// 	}
	// 	this.setState({indicatorRows});
	// }

	checkFormulaValid(formula) {
		if(formula) {
			if(formula.match('{{value}}') !== null) {
				let mathFormula = formula.replace('{{value}}', 50);
				mathFormula = math.eval(mathFormula);
				if(typeof mathFormula === 'number' && !isNaN(mathFormula)) return true;
			}
		}
		return false;
	}

	cancelIndicatorRow = (id) => {
		let indicatorRows = this.state.indicatorRows;
		for (let i = 0; i < indicatorRows.length; i++) {
			if (id === indicatorRows[i]["id"]) {
				if ((id === indicatorRows[i]["id"]) && indicatorRows[i]["outputOutcome"] === "" && (indicatorRows[i]["unit"] === "") && (indicatorRows[i]["indicator"] === "") && (indicatorRows[i]["format"] === "") && indicatorRows[i]["formula"] === "") {
					indicatorRows[i]["editOutputOutcome"] = "";
					indicatorRows[i]["editUnit"] = "";
					indicatorRows[i]["editIndicator"] = "";
					indicatorRows[i]["editFormat"] = "";
					indicatorRows[i]["editFormulaInput"] = "";
					indicatorRows[i]["editDurationalCumulative"] = "";
					indicatorRows[i]["mode"] = "edit";
					break;
				} else {
					indicatorRows[i]["mode"] = "view";
					indicatorRows[i]["editFormat"] = indicatorRows[i]["format"];
					indicatorRows[i]["editOutputOutcome"] = indicatorRows[i]["outputOutcome"];
					indicatorRows[i]["editUnit"] = indicatorRows[i]["unit"];
					indicatorRows[i]["editDurationalCumulative"] = indicatorRows[i]["durationalCumulative"];
					indicatorRows[i]["editIndicator"] = indicatorRows[i]["indicator"];
					break;
				}
			}
		}
		this.setState({indicatorRows});
	}

	outputOutcome = (cell, row) => {
		let flag = false;
		return(
			<div>
				{this.state.indicatorRows.map((data ,i) => {
					if (data.mode === "edit" && data.id === row.id) {
						return flag = true
					}
				})}
				{flag === true ? (
					<select style={{width: "100%", border: "solid 1px #ddd"}} value={row.editOutputOutcome} name='editOutputOutcome' onChange={this.addNewIndicatorRowData.bind(this, row.id)}>
						<option selected disabled value="">Select Output/Outcome</option>
						<option value="output">Output</option>
						<option value="outcome">Outcome</option>
					</select>
				):(row.outputOutcome.charAt(0).toUpperCase() + row.outputOutcome.slice(1))}
			</div>
		)
	}

	UnitColumn = (cell, row) => {
		let flag = false;
		let unit = "";
		return(
			<div>
				{this.state.indicatorRows.map((data ,i) => {
					if (data.mode === "edit" && data.id === row.id) {
						return flag = true;
					}
				})}
				{this.state.sample_size.map((unit, i) => {
					if (row.unit === unit.symbol && !flag) {
						return unit = unit.unit
					}
				})}
				{flag === true ? (
					<select style={{width: "100%", border: "solid 1px #ddd"}} value={row.editUnit} name='editUnit' onChange={this.addNewIndicatorRowData.bind(this, row.id)}>
						<option selected disabled value="">Select Unit</option>
						{this.state.sample_size.map((unit, i) => {
							return (
								<option key={i} value={unit.symbol} data-type={unit.dataType} data-name={unit.unit}>{unit.unit}</option>
							)
						})}
					</select>
				):(null)}
			</div>
		)
	}

	formatColumn = (cell, row) => {
		let flag = false;
		return(
			<div>
				{this.state.indicatorRows.map((data ,i) => {
					if (data.mode === "edit" && data.id === row.id) {
						return flag = true
					}
				})}
				{flag === true ? (
					<div className='format-input-container'>
						<select style={{width: "100%", border: "solid 1px #ddd"}} name="editFormat" value={ row.editFormat} onChange={this.addNewIndicatorRowData.bind(this, row.id)}>
							<option selected disabled value="">Select Format</option>
							<option value="input">Input</option>
							<option value="auto">Auto Calculate</option>
						</select>
						{row.editFormat === "auto" ?
							<div style={{display:"flex", alignItems: "center", justifyContent: "space-between"}}>
								<input className='format-input' style={{width: "90%"}} name="editFormulaInput" value={row.editFormulaInput} type="text" placeholder="Enter your formula" onChange={this.addNewIndicatorRowData.bind(this, row.id)}/>
								<i className="fa fa-info-circle" style={{cursor:"pointer", marginTop: "10px"}} onClick={() => {this.setState({showToolTip: true})}}></i>
							</div>
						: null}
					</div>
					):( row.format === "auto" ? (row.formula) : (row.format.charAt(0).toUpperCase() + row.format.slice(1)))
				}
			</div>
		)
	}

	saveindicators = (e) => {
		this.setState({isLoading: true, saveBtnName: e.target.name});
		let uri;
		if (this.state.selectedClusterId === "") {
			uri = process.env.REACT_APP_API_URL + "/kpi-category" //post(create)
		} else {
			uri = process.env.REACT_APP_API_URL + `/kpi-category/${this.state.selectedClusterId}` //update(select)
		}
		swal({
			html: "<h3>Are you sure?</h3>",
			type: 'warning',
			showCancelButton: true,
		  confirmButtonText: 'Save',
		  showLoaderOnConfirm: true,
		  cancelButtonText: "Cancel"
     })
     .then(function(response) {
     	if (response.value === true) {
				this.setState({isLoading: true});
				let data = {};
				if (this.state.selectedClusterId !== "") {
					data["indicators"] = this.state.selectedKpis;
				} else {
					data["name"] = this.state.categoryName;
					data["indicators"] = this.state.selectedKpis;
					data["description"] = this.state.categoryDescription;
				}
				fetch(uri,{
					method: this.state.selectedClusterId === "" ? "POST" : "PUT",
					headers:{
						'Content-type': 'application/json',
		        'Auth': JSON.parse(readCookie('access_token')).access_token
					},
					body: JSON.stringify(data)
				}).then((data) => data.json())
				.then((data) => {
					if(data.status === "ok"){
						let selectedKpis = this.state.selectedKpis;
						selectedKpis = [];
						this.notifySuccess('Cluster sucessfully created.');
						this.refs.table.cleanSelected();
						this.setState ({
							isLoading: false,
							saveBtnName: "",
							categoryName:"",
							categoryDescription:"",
							selectedClusterId:"",
							budgetEditorModalVisibility: false,
							selectedClusterId: "",
							selectedKpis,
							selectedRowCount: 0,
							addToExistingCluster: false,
							addToNewCluster: false
						});
					} else {
						this.notifyError('Failed to create your cluster, ' + data.message);
						this.setState({isLoading: false, saveBtnName: ""});
					}
				});
			}
		}.bind(this));
	}

	categoryInputHandler = (e) => {
		this.setState({[e.target.name] : e.target.value});
	}

	indicator = (cell, row) => {
		let flag = false;
		return (
			<div>
				{this.state.indicatorRows.map((data ,i) => {
					if (data.mode === "edit" && data.id === row.id) {
						return flag = true
					}
				})}
				{flag === true ? (
					<input
						style={{width: "100%", border: "solid 1px #ddd", height:"33px", padding:"5px"}}
						type="text"
						value={row.editIndicator}
						name="editIndicator"
						id=""
						onChange={this.addNewIndicatorRowData.bind(this, row.id)}/>
					):(row.indicator)
				}
			</div>
		)
	}

	keyChange = (key) => {
		if (key !== this.state.activeTab) {
			if (key === 1) {
				window.location.pathname = '/indicators';
			}
			if (key === 2) {
				window.location.pathname = '/indicators/clusters';
				fetch (process.env.REACT_APP_API_URL + "/kpi-category",{
					method: "GET",
					headers: {
						'Auth': JSON.parse(readCookie('access_token')).access_token
					}
				}).then((data) => data.json())
				.then((data) => {
					let clusters = [];
					clusters = (data.data);
					this.setState({clusters});
				});
			}
			if (key === 3) {
				window.location.pathname = '/indicators/trends';
			}
		}
	}

	saveKpi = () => {
		let indicatorRows = this.state.indicatorRows;
		let flag = false;
		for (let i = 0; i < indicatorRows.length; i++) {
			if ((indicatorRows[i]["editFormat"] === "auto") && (indicatorRows[i]["editOutputOutcome"] !== "") && (indicatorRows[i]["editUnit"] !== "") && (indicatorRows[i]["editIndicator"] !== "") && (indicatorRows[i]["editFormat"] !== "") && (indicatorRows[i]["editFormulaInput"] !== "") && this.checkFormulaValid(indicatorRows[i]["editFormulaInput"]) && (indicatorRows[i]["editDurationalCumulative"] !== "")) {
					indicatorRows[i]["outputOutcome"]= indicatorRows[i]["editOutputOutcome"];
					indicatorRows[i]["unit"]= indicatorRows[i]["editUnit"];
					indicatorRows[i]["indicator"]= indicatorRows[i]["editIndicator"];
					indicatorRows[i]["format"]= indicatorRows[i]["editFormat"];
					indicatorRows[i]["durationalCumulative"]= indicatorRows[i]["editDurationalCumulative"];
					indicatorRows[i]["mode"]= "view";
					indicatorRows[i]["formula"]= indicatorRows[i]["editFormulaInput"];
					indicatorRows[i]['formula'] = indicatorRows[i]['formula'].replace('{{value}}', '{{id}}');
			}
			 else if ((indicatorRows[i]["editFormat"] === "input") && (indicatorRows[i]["editOutputOutcome"] !== "") && (indicatorRows[i]["editUnit"] !== "") && (indicatorRows[i]["editIndicator"] !== "") && (indicatorRows[i]["editFormat"] !== "") && (indicatorRows[i]["editDurationalCumulative"] !== "")) {
				indicatorRows[i]["outputOutcome"]= indicatorRows[i]["editOutputOutcome"];
				indicatorRows[i]["unit"]= indicatorRows[i]["editUnit"];
				indicatorRows[i]["indicator"]= indicatorRows[i]["editIndicator"];
				indicatorRows[i]["format"]= indicatorRows[i]["editFormat"];
				indicatorRows[i]["durationalCumulative"]= indicatorRows[i]["editDurationalCumulative"];
				indicatorRows[i]["mode"]= "view";
				indicatorRows[i]["formula"]= indicatorRows[i]["editFormulaInput"];
			} else {
				this.notifyError("Please Provide Mandatory Values");
			}
		}
		this.setState({indicatorRows});
		for (let i = 0; i < indicatorRows.length; i++) {
			if (indicatorRows[i]["mode"] === "edit") {
				flag = true;
				break;
			}
		}
		if (flag === false) {
			let data = {indicators: indicatorRows};
			fetch(process.env.REACT_APP_API_URL + "/kpi-indicator", {
				method: "PUT",
				headers: {
					'Content-type': 'application/json',
					'Auth': JSON.parse(readCookie('access_token')).access_token
				},
				body: JSON.stringify(data)
			}).then((data) => data.json())
			.then((data) => {
				if(data.status === "ok"){
					this.notifySuccess("Successfully Saved.")
				} else {
					this.notifyError('Failed to Save.')
				}
			})
		} else {
				this.notifyError("Can't save your are still in edit mode.")
			}
	}

	addToCluster = () => {
		let indicatorRows = this.state.indicatorRows;
		let flag = false;
		for (let i = 0; i < indicatorRows.length; i++) {
			if (indicatorRows[i]["mode"] === "edit") {
				flag = true;
				break;
			}
		}
		if (flag === false) {
			this.setState({budgetEditorModalVisibility: true, modalName: ""})
		} else {
			this.notifyError("Can't add KPI's to cluster, Please save the KPI's first");
		}
	}

	addKpiToCluster = (name) => {
		if (name === "addToNewCluster") {
			this.setState({addToNewCluster: true, addToExistingCluster: false, selectedClusterId:""});
		} else {
			this.setState({addToExistingCluster: true, addToNewCluster: false, categoryName:"", categoryDescription:""});
		}
	}

	clusterDate = (cell, row) => {
		return (
			moment(row.updateOn).format('DD/MM/YYYY')
		)
	}

	clusterSelect = (e) => {
		this.setState({selectedClusterId: e.target.value});
	}

	clusterViewDelete = (cell, row) => {
		return(
			<div>
				<button className='indicators-save-btn' onClick={this.viewCluster.bind(this, row, "clusterModal")}>View</button>
				<i className="fa fa-trash delete-indicator-row" onClick={this.deleteClusterRow.bind(this, row.id)}></i>
			</div>
		)
	}

	viewCluster = (row, name) => {
		this.setState({modalName: name, viewCusterDataId: row.id, testData: row,  viewCusterData: row, budgetEditorModalVisibility: true});
		fetch (process.env.REACT_APP_API_URL + "/kpi-category/" + row.id,{
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
		.then((data) => {
			if(data.status === "ok") {
				let clusterIndicatorRows = [];
				let newClusterIndicatorRows = [];
				for (let i = 0; i < data.data.indicators.length; i++) {
					clusterIndicatorRows.push({
						id:data.data.indicators[i]["indicatorId"],
						outputOutcome:data.data.indicators[i]["type"],
						unit: data.data.indicators[i]["unit"],
						indicator:data.data.indicators[i]["name"],
						format: data.data.indicators[i]["format"],
						kpiId: data.data.indicators[i]["id"],
						mode: "view",
						editOutputOutcome: data.data.indicators[i]["type"],
						editUnit: data.data.indicators[i]["unit"],
						editIndicator:data.data.indicators[i]["name"],
						editFormat:data.data.indicators[i]["format"],
						formula: data.data.indicators[i]["formula"],
						editFormulaInput: data.data.indicators[i]["formula"],
						durationalCumulative: data.data.indicators[i]["computationType"],
						editDurationalCumulative: data.data.indicators[i]["computationType"],
					})
				}
				newClusterIndicatorRows = clusterIndicatorRows;
			this.setState({clusterIndicatorRows, newClusterIndicatorRows});
			}
		});
	}

	deleteClusterRow = (clusterId) => {
		swal({
			html: "<h3>Are you sure you want to delete this Cluster?</h3>",
			type: 'warning',
			showCancelButton: true,
		  confirmButtonText: 'Yes, Delete',
		  showLoaderOnConfirm: true,
		  cancelButtonText: "No, Cancel"
     })
     .then(function(response) {
     	if (response.value === true) {
				let flag = false
				let index ;
				let clusters = this.state.clusters;
				clusters.map((cluster,i) => {
					if (clusterId === cluster.id) {
						flag = true;
						index = i;
						return;
					}
				})
				if (flag = true) {
					fetch (process.env.REACT_APP_API_URL + "/kpi-category/" + clusterId,{
						method: "DELETE",
						headers: {
							'Auth': JSON.parse(readCookie('access_token')).access_token
						}
					}).then((data) => data.json())
					.then((data) => {
						if(data.status === "ok") {
							clusters.splice(index,1)
							this.notifySuccess("Successfully Deleted.");
						} else {
							this.notifyError('Failed to Delete.');
						}
						this.setState({clusters});
					})
				}
     	}
		}.bind(this));
	}

	clusterNameDesc = (e) => {
		let viewCusterData = this.state.viewCusterData;
		if (e.target.name === "edit-cluster-desc") {
			viewCusterData["description"] = e.target.value
		} else {
			viewCusterData["name"] = e.target.value;
		}
		this.setState({viewCusterData})
	}

	durationalCumulative = (cell, row) => {
		let flag = false;
		return (
			<div>
				{this.state.indicatorRows.map((data ,i) => {
					if (data.mode === "edit" && data.id === row.id) {
						return flag = true;
					}
				})}
				{flag === true ? (
					<select style={{width: "100%", border: "solid 1px #ddd"}} value={row.editDurationalCumulative} name='editDurationalCumulative' onChange={this.addNewIndicatorRowData.bind(this, row.id)}>
						<option selected disabled value="">Select Durational/Cumulative</option>
						<option value="durational">Durational</option>
						<option value="cumulative">Cumulative</option>
					</select>
				):(row.durationalCumulative.charAt(0).toUpperCase() + row.durationalCumulative.slice(1))}
			</div>
		)
	}

	handlerClickCleanFiltered() {
    this.refs.kpi_name_column.cleanFiltered();
  }
  handlerClickCleanClusterFiltered() {
    this.refs.cluster_name_column.cleanFiltered();
  }

  handlerClickCleanClusterIndicatorFiltered() {
    this.refs.cluster_indicator_column.cleanFiltered();
  }

  editClusterData() {
  	let testData = {};
  	testData['name'] = this.state.viewCusterData.name;
  	testData['description'] = this.state.viewCusterData.description;
  	testData['id'] = this.state.viewCusterData.id;
  	testData['sharedWithProjects'] = this.state.viewCusterData.sharedWithProjects;
  	testData['totalIndicators'] = this.state.viewCusterData.totalIndicators;
  	testData['updateOn'] = this.state.viewCusterData.updateOn;
  	this.setState({ editVIewClusterModal: true, testData: testData });
  }

	render(){
		return (
			<div className="mis-content-container">
				<ToastContainer position="bottom-center" autoClose={3000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={true} />
					<Tabs activeKey={this.state.activeTab} onSelect={this.keyChange.bind(this)} id="partnerPage-tabs-container">
					  <Tab eventKey={1} title="Indicators">
					  	<div className='add-new-kpi-category-container'>
					  		<Modal className="formula-modal" show={this.state.showToolTip}
			    				bsSize="large">
			    				<Modal.Header>
			    					<Modal.Title className="modal-header-custom">
			     						<h3 style={{textAlign:"center"}}>Calculation Formula</h3>
			    					</Modal.Title>
			    				</Modal.Header>
			    				<Modal.Body>
			    					<span>
											<h4 style={{lineHeight: "2" }}>You can set the calculation formula for this indicator. <br/> Please use the previous Indicator IDs or Numbers only.
											<br/> Operations Index-</h4>
											<h5>Add values (+)</h5>
											<h5>Subtract values(-)</h5>
											<h5>Multiply Values (*)</h5>
											<h5>Divide Values (/)</h5>
											<h5>{'Brackets ({})'}</h5>
										</span>
			    				</Modal.Body>
			    			</Modal>
			    			{this.state.budgetEditorModalVisibility ? (
						  		<Modal className="kpi-cluster-modal" show={this.state.budgetEditorModalVisibility}
				    				onHide={this.hideBudgetEditorModal.bind(this)} bsSize="large">
			    					{this.state.modalName !== "clusterModal" ? (
					    				<Modal.Header>
					    					<Modal.Title className="modal-header-custom">
					     						<h3 style={{textAlign:"center"}}>Cluster KPI</h3>
					    					</Modal.Title>
					    				</Modal.Header>
				    				):(null)}
				    				<Modal.Body>
				     					{this.state.modalName === "" ? (
				     						<div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
					     						<div style={{display:"flex", alignItems: "center", cursor: "pointer"}} onClick={this.addKpiToCluster.bind(this, "addToExistingCluster")}>
					     							<input type="radio" style={{margin: "0px 10px 0px 0px"}} checked={this.state.addToExistingCluster === true ? true : false}/>
					     							<label style={{marginBottom:"0px"}} htmlFor="">Do you want to add KPI's to an existing Cluster</label>
					     						</div>
					     						{this.state.addToExistingCluster === true ? (
					     							<select style={{width: "50%", margin: "10px 0 0 0", borderRadius: "4px"}} value={this.state.selectedClusterId} onChange={this.clusterSelect}>
					     								<option value='' selected disabled>Select Cluster</option>
					     								{this.state.clusters.map((cluster,i) => {
					     									return (
					     										<option key={i} value={cluster.id}>{cluster.name}</option>
					     									)
					     								})}
					     							</select>
					     						):(null)}
					     						<div style={{display:"flex", alignItems: "center", marginTop:"10px", cursor: "pointer"}} onClick={this.addKpiToCluster.bind(this, "addToNewCluster")}>
						     						<input type="radio" style={{margin: "0px 10px 0px 0px"}} checked={this.state.addToNewCluster === true ? true : false}/>
						     						<label style={{marginBottom:"0px"}} htmlFor="">Do you want to add KPI's to a new Cluster</label>
					     						</div>
					     						{this.state.addToNewCluster === true ? (
					     							<div style={{width: "100%", marginTop: "15px"}}>
					     								<div className='add-category-input-container'>
																<h5 className='is-req add-category-input-heading'>Enter Cluster Name</h5>
																<input className='add-category-input' type="text" placeholder='Enter Category Name' name="categoryName" onChange={this.categoryInputHandler} value={this.state.categoryName} />
															</div>
															<div className='add-category-input-container'>
																<h5 className='is-req add-category-input-heading'>Enter Cluster Description</h5>
																<textarea className='add-category-input' type="text" placeholder='Enter Category Description' name="categoryDescription" onChange={this.categoryInputHandler} value={this.state.categoryDescription}/>
															</div>
					     							</div>
					     						):(null)}
					     					</div>
				     					):(
				     						<div>
				     							<div className={this.state.editVIewClusterModal === false ? "cluster-input-container-view" : "cluster-input-container-edit"}>
				     								<label htmlFor="" style={{marginRight: "8px", marginBottom:"0px"}}>Cluster Name:</label>
				     								{this.state.editVIewClusterModal === false ? (<h5 style={{margin: "0px"}}>{this.state.viewCusterData.name}</h5>) : (<input style={{marginTop: "5px"}} value={this.state.viewCusterData.name} name="edit-cluster-name" onChange={this.clusterNameDesc} className='cluster-input' type="text"/>)}
				     							</div>
				     							<div className={this.state.editVIewClusterModal === false ? "cluster-input-container-view" : "cluster-input-container-edit"}>
				     								<label htmlFor="" style={{marginRight: "8px",marginBottom:"0px"}}>Cluster Description:</label>
				     								{this.state.editVIewClusterModal === false ? (<h5 style={{margin: "0px"}}>{this.state.viewCusterData.description}</h5>) : (<textarea rows="4" cols="30" style={{marginTop: "5px", minHeight : "30px"}} value={this.state.viewCusterData.description} name="edit-cluster-desc" onChange={this.clusterNameDesc} className='cluster-input' type="text"/>)}
				     							</div>
				     							<div style={{marginTop:"20px"}}>
				     								<div className="cluster-view-kpi-table">
													<BootstrapTable data={this.state.clusterIndicatorRows} className='cluster-indicators-table' id="indicators-table" ref="cluster_indicator" striped={false}>
																<TableHeaderColumn dataField='id' width="6%" headerAlign='center' dataSort={true} dataAlign='center' isKey={true}>Id</TableHeaderColumn>
													      <TableHeaderColumn dataField='outputOutcome' width="20%" headerAlign='center' dataSort={true} dataAlign='center' dataFormat={this.outputOutcome}>Output/Outcome</TableHeaderColumn>
													      <TableHeaderColumn dataField='unit' headerAlign='center' dataSort={true} dataAlign='center' dataFormat={this.UnitColumn}>Unit</TableHeaderColumn>
													      <TableHeaderColumn ref="cluster_indicator_column" dataField='indicator' width="30%" headerAlign='center' dataSort={true} dataAlign='center' filter={ this.state.clusterIndicatorRows.length ? { type: 'TextFilter', delay: 1000,  placeholder: 'Enter Indicator Name' } : null } dataFormat={this.indicator}>Indicator <br/>{this.state.clusterIndicatorRows.length ? <a onClick={ this.handlerClickCleanClusterIndicatorFiltered.bind(this) } style={ { cursor: 'pointer', display:"block" } }>clear filters</a> : null}
													     	</TableHeaderColumn>
													      <TableHeaderColumn dataField='format' headerAlign='center' dataAlign='center' dataSort={true} dataFormat={this.formatColumn}>Format</TableHeaderColumn>
													      <TableHeaderColumn dataField='durationalCumulative' headerAlign='center' dataSort={true} dataAlign='center' dataFormat={this.durationalCumulative}>Durational/Cumulative</TableHeaderColumn>
												      	<TableHeaderColumn headerAlign='center' dataAlign='center' hidden={this.state.editVIewClusterModal ? false : true} dataFormat={this.editDeleteClusterIndicatorbutton}>Edit/Delete
												      	</TableHeaderColumn>
															</BootstrapTable>
				     								</div>
														<div className='add-cluster-button-container'>
															{this.state.editVIewClusterModal === false ? <button className='new-cluster-button' onClick={this.editClusterData.bind(this)}>Edit</button> : null}
															{this.state.editVIewClusterModal === false ? <button className='new-cluster-button' style={{marginLeft: "10px"}} onClick={this.hideBudgetEditorModal.bind(this)}>Close</button> : null}
															<div>
																{this.state.editVIewClusterModal ? (
																	<button className='add-cluster-button-save' name="add-cluster-button-save" onClick={this.saveClusterIndicators}>{this.state.saveBtnName === "add-cluster-button-save"? <img src="/img/Spinner-white.svg" style={{width: "27px"}} alt="loading"/> : "Save"}</button>
																):(null)}
																{this.state.editVIewClusterModal === true ? <button className='indicators-cancel-btn' onClick={this.cencelClusterIndicators} style={{marginLeft:"10px"}}>Cancel</button> : null}
															</div>
														</div>
				     							</div>
				     						</div>
				     					)}
				    				</Modal.Body>
				    				{this.state.modalName !== "clusterModal" ? (
					    				<Modal.Footer>
					    					<div className='category-desc-button-container'>
													<button className='category-desc-buttons indicators-save-btn' name="category-desc-save" onClick={this.saveindicators}>{this.state.saveBtnName === "category-desc-save"? <img src="/img/Spinner-white.svg" style={{width: "27px"}} alt="loading"/> : "Save"}</button>
													{/*<button className='category-desc-buttons indicators-cancel-btn' name="category-desc-cancel" disabled={this.state.isLoading ? true : false} onClick={this.hideBudgetEditorModal.bind(this)}>Cancel</button>*/}
												</div>
					    				</Modal.Footer>
					    			):(null)}
				    			</Modal>
				    		) : (null)}
								<div className='new-indicators-table-container'>
									<div style={{display: "flex", alignItems:"center", justifyContent: "space-between", marginBottom: "15px"}}>
										<h3 style={{marginBottom: "0px" }}>Indicators</h3>
										<div style={{display: "flex"}}>
											{this.state.selectedRowCount > 0 ? (
												<button className='indicators-cluster-btn' onClick={this.addToCluster}>Add To Cluster</button>
											):(null)}
											{this.state.indicatorRows.length ? (
												<button className='add-indicator-button-save' name="add-indicator-button-save" onClick={this.saveKpi}>{this.state.saveBtnName === "add-indicator-button-save"? <img src="/img/Spinner-white.svg" style={{width: "27px"}} alt="loading"/> : "Save"}</button>
											):(null)}
										</div>
									</div>
									<div className='indicators-table-div-scrollable'>
										<BootstrapTable data={this.state.indicatorRows} ref='table' className='indicators-table' selectRow={this.state.selectRowProp} id="indicators-table" striped={false}>
											<TableHeaderColumn dataField='id' width="6%" headerAlign='center' dataSort={ true } dataAlign='center' isKey={true}>Id</TableHeaderColumn>
								      <TableHeaderColumn dataField='outputOutcome' headerAlign='center' dataSort={ true } dataAlign='center' dataFormat={this.outputOutcome}>Output/Outcome</TableHeaderColumn>
								      <TableHeaderColumn dataField='unit' headerAlign='center' dataSort={ true } dataAlign='center' dataFormat={this.UnitColumn}>Unit</TableHeaderColumn>
								      <TableHeaderColumn ref="kpi_name_column" dataField='indicator' width="30%" headerAlign='center' dataSort={ true } filter={ { type: 'TextFilter', delay: 1000,  placeholder: 'Enter Indicator Name' } } dataAlign='center' dataFormat={this.indicator}>Indicator <br/><a onClick={ this.handlerClickCleanFiltered.bind(this) } style={ { cursor: 'pointer', display:"block" } }>clear filters</a></TableHeaderColumn>
								      <TableHeaderColumn dataField='format' width="20%" headerAlign='center' dataSort={ true } dataAlign='center' dataFormat={this.formatColumn}>Format</TableHeaderColumn>
								      <TableHeaderColumn dataField='durationalCumulative' headerAlign='center' dataSort={ true } dataAlign='center' dataFormat={this.durationalCumulative}>Durational/Cumulative</TableHeaderColumn>
								      {JSON.parse(readCookie('userData')).authorizedFor.mis !== 'view' ? (
							      		<TableHeaderColumn headerAlign='center' dataAlign='center' dataFormat={this.editDeleteIndicatorbutton}>Edit/Delete</TableHeaderColumn>
							      	) : (null)}
										</BootstrapTable>
									</div>
									{JSON.parse(readCookie('userData')).authorizedFor.mis !== 'view' ? (
										<div className='add-indicator-button-container'>
											<button className='new-indicators-button' onClick={this.addNewIndicatorRow}><i className='fa fa-plus'></i> Add New Indicator</button>
										</div>
									) : (null)}
								</div>
							</div>
					  </Tab>
					  <Tab eventKey={2} title="Indicator Clusters">
					  	{/*<div style={{display:"flex", justifyContent:"flex-end"}}>
					  		<button style={{marginBottom:"15px"}} className='indicators-save-btn' onClick={this.addNewIndicatorClusterRow}><i className='fa fa-plus'></i> Add Indicator Cluster</button>
					  	</div>*/}
					  	<div className='clusters-table-div-scrollable'>
						  	<BootstrapTable data={this.state.clusters} id="indicators-table" className='clusters-table' striped={false} ref='cluster' options={ this.options }>
									<TableHeaderColumn ref="cluster_name_column" dataField='name' headerAlign='center' dataSort filter={ { type: 'TextFilter', delay: 1000, placeholder: 'Enter Cluster Name' } } dataAlign='center' isKey={true}>Cluster Name <br/><a onClick={ this.handlerClickCleanClusterFiltered.bind(this) } style={ { cursor: 'pointer', display:"block" } }>clear filters</a></TableHeaderColumn>
						      <TableHeaderColumn dataField='totalIndicators' headerAlign='center' dataSort dataAlign='center'>Number of Indicators</TableHeaderColumn>
						      <TableHeaderColumn dataField='sharedWithProjects' headerAlign='center' dataSort dataAlign='center'>Projects Tagged</TableHeaderColumn>
						      <TableHeaderColumn headerAlign='center' dataAlign='center' dataSort dataFormat={this.clusterDate}>Last Updated</TableHeaderColumn>
						      {JSON.parse(readCookie('userData')).authorizedFor.mis !== 'view' ? (
						      	<TableHeaderColumn headerAlign='center' dataAlign='center' dataFormat={this.clusterViewDelete}>Edit/Delete</TableHeaderColumn>
						      ) : (null)}
								</BootstrapTable>
					  	</div>
					  </Tab>
					  {/*<Tab eventKey={3} title="Trends">
					  	<Trends />
					  </Tab>*/}
				  </Tabs>
			</div>
		)
	}
}

export default Indicators;