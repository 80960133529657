import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import moment from 'moment';

export default class SingleInterest extends Component {
	render () {
		return (
			<div className="interest-wrapper">
				<Row>
					<Col md={4}>
						<div className="interest-container">
							<div className="interest-project-name">
								<span className="interest-container-title">Applied For: </span>
								<Link className="interest-project-name"
								      to={"/csr/project/" + this.props.interest.volunteerOpportunity.customUrl} >
									{this.props.interest.volunteerOpportunity.title}
								</Link>
							</div>

							<div className="interest-volunteer-count">
								<span className="interest-container-title">Volunteers: </span>{this.props.interest.minVolunteers} - {this.props.interest.maxVolunteers}
							</div>

							<div className="interest-selected-dates">
								<span className="interest-container-title">Dates: </span>
								{
									this.props.interest.companySelectedDates.map(function (date, i) {
										if(this.props.interest.companySelectedDates.length - 1 === i) return date;
										else return date + ', '
									}.bind(this))
								}
							</div>

							<div className="interest-bottom-section">
								<span className="interest-container-title">Status: </span>
								{(() => {
									if(this.props.interest.status === 0) {
										return <span>Applied</span>
									} else if (this.props.interest.status === 1) {
										return <span>Reviewing</span>
									} else if (this.props.interest.status === 2) {
										return <span>Approved</span>
									} else if (this.props.interest.status === 3) {
										return <span>Completed</span>
									} else {
										return <span>Cancelled</span>
									}
								})()}
							</div>
						</div>
					</Col>
				</Row>
			</div>
		);
	}
}