import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Row, Col} from 'react-bootstrap';
import $ from 'jquery';
import swal from 'sweetalert2';

import * as action from '../../actions/AdminAction';
var CryptoJS = require("crypto-js");

const createCookie = require('../../cookie.js').createCookie;

class AdminLogin extends Component {
	constructor(props) {
		super(props);
		this.state = {
			signInEmail: '',
			signInPassword: '',
		};
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(name, value) {
		this.setState({ [name]: value });
	}

	login() {
		if(this.state.signInEmail !== '' && this.state.signInPassword !== '') {
			let data = {};
			data['email'] = CryptoJS.AES.encrypt(this.state.signInEmail,process.env.REACT_APP_ENCRYPTION_KEYPHRASE).toString();
			data['password'] = CryptoJS.AES.encrypt(this.state.signInPassword,process.env.REACT_APP_ENCRYPTION_KEYPHRASE).toString();
			data['type'] = 'admin_panel';
			data['enc'] = true;

			fetch(process.env.REACT_APP_API_URL + '/login', {
				method: "POST",
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(data)
			}).then(function(response) {
				return response.json();
			}).then(function(data) {
				if(data['status'] === 'ok') {
					createCookie('access_token', JSON.stringify(data.token), 7);
					createCookie('userData', JSON.stringify(data.data), 7);
					this.props.actions.changeLoginStatus(true);
					window.location.pathname = '/admin/dashboard';
				} else if(data['status'] === 'error') {
					$('#invalid-details').removeClass('hidden');
					if(data['code'] === 1) {
						swal(
							'Email Verification Pending!',
							'Kindly check the verification link sent to your official email ID.',
							'warning'
						)
					} else if (data['code'] === 2) {
						swal(
							'Verification Pending!',
							'We are in the process of verifying your email. You will receive a password shortly.',
							'warning'
						)
					} else if (data['code'] === 3) {
						$('#invalid-details').removeClass('hidden');
					} else {
						swal(
							'Error!',
							'We are facing some issue. Kindly try after some time.',
							'warning'
						)
					}
				}
			}.bind(this));
		}
	}

	render() {
		return (
			<div className="login-container">
				<Col md={4} mdOffset={4} className="container">
					<h1>Login to Your Account</h1><br />
					<div className="form-group">
						<label className="control-label is-imp">Email:</label>
						<input type="email" className="form-control" autocomplete="off" placeholder="example@domain.com" required onChange={(e) => {this.handleChange('signInEmail', e.target.value)}}/>
					</div>

					<div className="form-group">
						<label className="control-label is-imp">Password:</label>
						<input type="password" className="form-control" autocomplete="off" placeholder="password" required onChange={(e) => {this.handleChange('signInPassword', e.target.value)}}/>
					</div>

					<div className="form-group hidden" id="invalid-details">
						<span>Invalid user details</span>
					</div>

					<div className="form-group signinAdminBtn-container">
						<button id="signinAdmin" className="btn submit-btn" onClick={this.login.bind(this, 'ngo')}>
							Sign In
						</button>
					</div>
				</Col>
			</div>
		);
	}
}

AdminLogin.propTypes = {

};

function mapStateToProps(state) {
	return {
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(action, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminLogin);