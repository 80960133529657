import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import ProjectsContainer from '../../4-DiscoverProjects/ProjectsContainer';
import DiscoverProjectsMap from '../../4-DiscoverProjects/DiscoverProjectsMap';

import * as action from '../../../../actions/DiscoverCausesAction';

const readCookie = require('../../../../cookie.js').readCookie;

class SingleCause extends Component {
	componentWillMount() {
		let path = window.location.pathname;
		path = path.split('/').pop();
		fetch(process.env.REACT_APP_API_URL + '/opportunityType/' + path, {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
		.then(function(data) {
			if(data.hasOwnProperty('data')) {
				this.props.actions.updateSelectedCause(data['data']);
				fetch(process.env.REACT_APP_API_URL + '/volunteer-opportunity/discover?opportunityId=' + this.props.selectedCause.id, {
					method: "GET",
					headers: {
						'Auth': JSON.parse(readCookie('access_token')).access_token
					}
				}).then((data) => data.json())
				.then(function(data) {
					this.props.actions.updateProjects(data.data);
				}.bind(this));
			} else {
				window.location = "/404";
			}
		}.bind(this));
	}

	clearSelectedCause() {
		let causeJSON = {};
		this.props.actions.updateSelectedCause(causeJSON);
	}

	componentWillUnmount() {
		this.clearSelectedCause();
	}

	render() {
		if(this.props.selectedCause.hasOwnProperty('id') && this.props.projects !== null && this.props.selectedCause.opportunityTypes) {
			return (
				<div className="single-cause-container">
					<Row className="back-container">
						<Link to={"/csr/explore-causes"} className="back" onClick={this.clearSelectedCause.bind(this)}>
							<i className="fa fa-chevron-left">

							</i>
							{' GO TO ALL CAUSES'}
						</Link>
					</Row>
					<Row>
						<Col md={3}>
							<div className="cause-image" style={{backgroundImage: `url(${this.props.selectedCause.representativeMedia})`}}>

							</div>
						</Col>
						<Col md={6} className="cause-details">
							<span className="cause-name">{this.props.selectedCause.title}</span>
							<Row>
								<Col md={6} className="features">
									<div className="heading">Intended beneficiary</div>
									<div className="content">
										{this.props.selectedCause.beneficiaryTypes.map(function(beneficiaryType, i) {
											if(this.props.selectedCause.beneficiaryTypes.length - i === 1) return beneficiaryType.name;
											else return beneficiaryType.name + ', '
										}.bind(this))}
									</div>
								</Col>
								<Col md={6} className="features">
									<div className="heading">Intended cause areas</div>
									<div className="content">
										{this.props.selectedCause.causes.map(function(cause, i) {
											if(this.props.selectedCause.causes.length - i === 1) return cause.name;
											else return cause.name + ', '
										}.bind(this))}
									</div>
								</Col>
							</Row>
						</Col>
						<Col md={3} className="other-details">
							<span>
								<b>{'Type of opportunity: '}</b>
								{this.props.selectedCause.opportunityTypes.map(function(opportunity, i) {
									if(this.props.selectedCause.opportunityTypes.length - i === 1) return this.props.selectedCause.opportunityTypes[i].name;
									else return this.props.selectedCause.opportunityTypes[i].name + ', '
								}.bind(this))}
							</span>
							<span>
								<b>{'Intensity of activity: '}</b>
								{this.props.selectedCause.activityIntensity.map(function(opportunity, i) {
									if(this.props.selectedCause.activityIntensity.length - i === 1) return this.props.selectedCause.activityIntensity[i].name
									else return this.props.selectedCause.activityIntensity[i].name + ', '
								}.bind(this))}
							</span>
						</Col>
					</Row>
					<br/>
					<Row>
						<p className="cause-desc col-md-12">{this.props.selectedCause.description}</p>
					</Row>
					<Row>
						<p className="cause-desc col-md-12">{this.props.selectedCause.pitch}</p>
					</Row>
					<Row>
						<Col md={7} className="projects-list">
							{this.props.projects !== null ? (<span id="projects-count">{this.props.projects.length + ' PROJECTS FOUND'}</span>) : ('')}
							<ProjectsContainer projects={this.props.projects}
								priceRangeMin={this.props.priceRangeMin}
								priceRangeMax={this.props.priceRangeMax}

								updateProjects={this.props.actions.updateProjects}/>
						</Col>
						<Col md={5} className="projects-map">
							<DiscoverProjectsMap projects={this.props.projects}
								updateProjects={this.props.actions.updateProjects}
								initialPosition={this.props.initialPosition} />
						</Col>
					</Row>
				</div>
			);
		} else {
			return null;
		}
	}
}

SingleCause.propTypes = {
	selectedCause: PropTypes.object,
	projects: PropTypes.array,
	priceRangeMin: PropTypes.number,
	priceRangeMax: PropTypes.number,
	initialPosition: PropTypes.object
};

function mapStateToProps(state) {
	return {
		selectedCause: state.DiscoverCauses.selectedCause,
		projects: state.DiscoverCauses.projects,
		priceRangeMin: state.DiscoverProjects.priceRangeMin,
		priceRangeMax: state.DiscoverProjects.priceRangeMax,
		initialPosition: state.DiscoverProjects.initialPosition
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(action, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SingleCause);