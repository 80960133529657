import { combineReducers } from 'redux';

import Landing from './LandingReducer';
import Signup from './SignupReducer';
import DiscoverCauses from './DiscoverCausesReducer';
import DiscoverProjects from './DiscoverProjectsReducer';
import DiscoverCSRProjects from './DiscoverCSRProjectsReducer';
import Corporate from './CorporateReducer';
import Admin from './AdminReducer';
import Contact from './ContactReducer';
import Profile from './ProfileReducer';

const indexReducer = combineReducers({
	Landing,
	Signup,
	DiscoverCauses,
	DiscoverProjects,
	DiscoverCSRProjects,
	Corporate,
	Admin,
	Contact,
	Profile
});

export default indexReducer;