import React, { Component } from 'react';
import { OverlayTrigger, Tooltip, Col, Row } from 'react-bootstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { BootstrapTable, TableHeaderColumn, ButtonGroup } from 'react-bootstrap-table';
import Multiselect from 'react-bootstrap-multiselect';
import { ToastContainer, toast } from 'react-toastify';
import Icon from 'react-icons-kit';
import { ic_edit } from 'react-icons-kit/md/ic_edit';
import { ic_cancel } from 'react-icons-kit/md/ic_cancel';
import { ic_done } from 'react-icons-kit/md/ic_done';
import { ic_delete } from 'react-icons-kit/md/ic_delete';
import { ic_open_in_new } from 'react-icons-kit/md/ic_open_in_new';
import { chevronDown } from 'react-icons-kit/fa/chevronDown'
import ReactGA from 'react-ga';

import { Menu, Dropdown } from 'antd';

import Loader from '../Loader';

const readCookie = require('../../../cookie.js').readCookie;

let year = moment().year();
const years = [];
for (var i = -3; i < 4; i++) {
	var yearStr = (year - i) + '-' + (year - i + 1);
	years.push(yearStr);
}

export default class CSRPortfolio extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fiscalYear: this.getCurrentFiscalYear(),
			data: [],
			portfolioTotalAmount: 0,
			currency: 'INR',
			allGoals: [],
			allAgendas: [],
			csrBudget: null,
			annualBudgetNotFound: false,
			annualBudgetNotFoundYear: null,
			showLoader: true,
			loaderHiding: '',
			allAgendas2: [],
			showAddPortfolioGoalForm: false,
			investmentType: 'allInvestment',
			showWarning: true,
			cancelWarning: false,
			preferedAgendas: [],
			realAllAgendas: []
		}
		this.selectFiscalYear = this.selectFiscalYear.bind(this);
		this.handleAmountChange = this.handleAmountChange.bind(this);
		this.addPortfolioGoalRow = this.addPortfolioGoalRow.bind(this);
		this.updatePortfolioGoal = this.updatePortfolioGoal.bind(this);
		this.refreshPortfolioGoalsData = this.refreshPortfolioGoalsData.bind(this);
	}

	getCurrentFiscalYear() {
		var today = new Date();
		var curMonth = today.getMonth();
		if (curMonth > 3) {
			return today.getFullYear().toString() + '-' + (today.getFullYear() + 1).toString();
		} else {
			return (today.getFullYear() - 1).toString() + '-' + today.getFullYear().toString();
		}
	}

	selectFiscalYear(year) {
		if (year === 'default') {
			this.setState({ fiscalYear: this.getCurrentFiscalYear() });
		} else {
			let companyId = JSON.parse(readCookie('userData')).organisationData.id;
			fetch(process.env.REACT_APP_API_URL + '/company/' + companyId + '/mis/portfolio/' + year.split('-')[0], {
				method: "GET",
				headers: {
					'Auth': JSON.parse(readCookie('access_token')).access_token
				}
			}).then((data) => data.json())
			.then(function (data) {
				let portfolioTotalAmount = 0;
				for (var i = 0; i < data.data.csrPortfolio.length; i++) {
					data.data.csrPortfolio[i]['mode'] = 'read';
					portfolioTotalAmount += data.data.csrPortfolio[i]['amount'];
				}
				if (!data.data.csrBudget.hasOwnProperty('id')) this.setState({ annualBudgetNotFound: true, annualBudgetNotFoundYear: year });
				else this.setState({ annualBudgetNotFound: false, annualBudgetNotFoundYear: null });
				let goalPortfolioWarning = localStorage.getItem("goalPortfolioWarning"), showWarning = false;
				if(goalPortfolioWarning === "ignored") {
					localStorage.removeItem('goalPortfolioWarning');
					showWarning = true;
				} else {
					if(goalPortfolioWarning === null) showWarning = true;
					else if(!JSON.parse(goalPortfolioWarning).ignored) showWarning = true;
					else {
						goalPortfolioWarning = JSON.parse(goalPortfolioWarning);
						if(goalPortfolioWarning.csrBudgetAmount !== data.data.csrBudget.amount || goalPortfolioWarning.portfolioTotalAmount !== portfolioTotalAmount) {
							showWarning = true;
							let warning = {
								csrBudgetAmount: data.data.csrBudget.amount,
								portfolioTotalAmount: portfolioTotalAmount,
								ignored: false
							}
							localStorage.setItem("goalPortfolioWarning", JSON.stringify(warning));
						}
					}
				}
				this.setState({ data: data.data.csrPortfolio, csrBudget: data.data.csrBudget, fiscalYear: year });
			}.bind(this));
		}
	}

	handleAmountChange(name, event) {
		this.setState({ [name]: event.target.value });
	}

	refreshPortfolioGoalsData() {
		let companyId = JSON.parse(readCookie('userData')).organisationData.id;
		fetch(process.env.REACT_APP_API_URL + '/company/' + companyId + '/mis/portfolio/' + this.state.fiscalYear.split('-')[0], {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
		.then(function (data) {
			let portfolioTotalAmount = 0;
			for (var i = 0; i < data.data.csrPortfolio.length; i++) {
				data.data.csrPortfolio[i]['mode'] = 'read';
				portfolioTotalAmount += data.data.csrPortfolio[i]['amount'];
			}
			let goalPortfolioWarning = localStorage.getItem("goalPortfolioWarning"), showWarning = false;
			if(goalPortfolioWarning === "ignored") {
				localStorage.removeItem('goalPortfolioWarning');
				showWarning = true;
			} else {
				if(goalPortfolioWarning === null) showWarning = true;
				else if(!JSON.parse(goalPortfolioWarning).ignored) showWarning = true;
				else {
					goalPortfolioWarning = JSON.parse(goalPortfolioWarning);
					if(goalPortfolioWarning.csrBudgetAmount !== data.data.csrBudget.amount || goalPortfolioWarning.portfolioTotalAmount !== portfolioTotalAmount) {
						showWarning = true;
						let warning = {
							csrBudgetAmount: data.data.csrBudget.amount,
							portfolioTotalAmount: portfolioTotalAmount,
							ignored: false
						}
						localStorage.setItem("goalPortfolioWarning", JSON.stringify(warning));
					}
				}
			}
			this.setState({ data: data.data.csrPortfolio, portfolioTotalAmount, csrBudget: data.data.csrBudget });
		}.bind(this));
	}

	componentDidMount() {
		let companyId = JSON.parse(readCookie('userData')).organisationData.id;
		fetch(process.env.REACT_APP_API_URL + '/company/' + companyId + '/mis/portfolio/' + this.state.fiscalYear.split('-')[0], {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
			.then(function (data) {
				let portfolioTotalAmount = 0;
				for (var i = 0; i < data.data.csrPortfolio.length; i++) {
					data.data.csrPortfolio[i]['mode'] = 'read';
					portfolioTotalAmount += data.data.csrPortfolio[i]['amount'];
				}
				if (!data.data.csrBudget.hasOwnProperty('id')) this.setState({ annualBudgetNotFound: true, annualBudgetNotFoundYear: this.state.fiscalYear });
				else this.setState({ annualBudgetNotFound: false, annualBudgetNotFoundYear: null });
				let goalPortfolioWarning = localStorage.getItem("goalPortfolioWarning"), showWarning = false;
				if(goalPortfolioWarning === "ignored") {
					localStorage.removeItem('goalPortfolioWarning');
					showWarning = true;
				} else {
					if(goalPortfolioWarning === null) showWarning = true;
					else if(!JSON.parse(goalPortfolioWarning).ignored) showWarning = true;
					else {
						goalPortfolioWarning = JSON.parse(goalPortfolioWarning);
						if(goalPortfolioWarning.csrBudgetAmount !== data.data.csrBudget.amount || goalPortfolioWarning.portfolioTotalAmount !== portfolioTotalAmount) {
							showWarning = true;
							let warning = {
								csrBudgetAmount: data.data.csrBudget.amount,
								portfolioTotalAmount: portfolioTotalAmount,
								ignored: false
							}
							localStorage.setItem("goalPortfolioWarning", JSON.stringify(warning));
						}
					}
				}
				this.setState({ data: data.data.csrPortfolio, portfolioTotalAmount, csrBudget: data.data.csrBudget, showWarning, loaderHiding: 'hiding' }, function () {
					setTimeout(function () {
						this.setState({ showLoader: false });
					}.bind(this), 1000);
				}.bind(this));
			}.bind(this));

		$(document).mouseup(function (e) {
			var container = $("#dd");
			if (!container.is(e.target) && container.has(e.target).length === 0) container.removeClass('active');
		});

		fetch(process.env.REACT_APP_API_URL + '/company/' + companyId + '/mis/preferences', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
			.then(function (data) {
				let allGoals = [];
				let preferedAgendas = [];
				let preferenceGoals = {};
				preferenceGoals.label = 'Preferences';
				preferenceGoals.children = [];
				let preferenceAgendas = {};
				preferenceAgendas.label = 'Preferences';
				preferenceAgendas.children = [];
				let otherGoals = {};
				otherGoals.label = 'Others';
				otherGoals.children = [];

				if (data.data.hasOwnProperty('goals')) {
					for (let i = 0; i < data.data.goals.length; i++) {
						let goalObj = {};
						goalObj['value'] = data.data.goals[i].id;
						goalObj['label'] = data.data.goals[i].name;
						preferenceGoals.children.push(goalObj);
					}
				}
				if (data.data.hasOwnProperty('agendas')) {
					for (let i = 0; i < data.data.agendas.length; i++) {
						let agendaObj = {};
						agendaObj['value'] = data.data.agendas[i].id;
						agendaObj['label'] = data.data.agendas[i].name;
						preferenceAgendas.children.push(agendaObj);
					}
				}
				fetch(process.env.REACT_APP_API_URL + '/getOtAttributes', {
					method: "GET",
					headers: {
						'Auth': JSON.parse(readCookie('access_token')).access_token
					}
				}).then((data) => data.json())
					.then(function (data) {
						let realAllAgendas = [];
						for (let i = 0; i < data.otAttributes.length; i++) {
							if (data.otAttributes[i].type === 'goal') {
								var checkIfExists = false;
								for (var j = 0; j < preferenceGoals.children.length; j++) {
									if (data.otAttributes[i]._id === preferenceGoals.children[j].value) checkIfExists = true;
								}
								if (!checkIfExists) {
									let goalObj = {};
									goalObj['value'] = data.otAttributes[i]._id;
									goalObj['label'] = data.otAttributes[i].name;
									otherGoals.children.push(goalObj);
								}
							} else if (data.otAttributes[i].type === 'agenda') {
								let agendaObj = {};
								agendaObj['value'] = data.otAttributes[i]._id;
								agendaObj['label'] = data.otAttributes[i].name;
								realAllAgendas.push(agendaObj);
							}
						}

						allGoals.push(preferenceGoals);
						preferedAgendas.push(preferenceAgendas);
						allGoals.push(otherGoals);

						this.setState({ allGoals, preferedAgendas, realAllAgendas });

						$('#goalSelector').parent().attr('style', 'position: relative; overflow: visible;');
						$('#goalSelector').parents('.react-bs-container-body').css('overflow', 'visible');

					}.bind(this));
			}.bind(this));
	}

	showButtonText(options, select) {
		if (options.length === 0) {
			return 'None selected ...';
		} else if (options.length > 2) {
			return options.length + ' options selected!';
		} else {
			let labels = [];
			options.each(function () {
				if ($(this).attr('label') !== undefined) {
					labels.push($(this).attr('label'));
				} else {
					labels.push($(this).html());
				}
			});
			return labels.join(', ') + '';
		}
	}

	ddClick() {
		$('#dd').toggleClass('active');
	}

	selectYear(event) {
		var opt = $(event.target).text();
		$('#dd > span').html(opt);
		this.selectFiscalYear(opt);
	}

	setAnnualBudget() {
		window.location.pathname = '/preferences/' + this.state.fiscalYear;
	}

	updatePortfolioGoal(index, portfolioId) {
		let body = {};
		body.agendas = $('select#edit-goal-agendas-select').val();
		for (let i = 0; i < this.state.investedAgendas.length; i++) {
			body.agendas.push(this.state.investedAgendas[i]);
		}
		if (body.agendas.length > 0) {
			body.amount = parseInt($('input#edit-allocated-amount-input').val());
			body.year = parseInt(this.state.fiscalYear.split('-')[0]);

			let companyId = JSON.parse(readCookie('userData')).organisationData.id;

			fetch(process.env.REACT_APP_API_URL + '/company/' + companyId + '/mis/individual-portfolio/' + portfolioId, {
				method: "PUT",
				headers: {
					'Content-Type': 'application/json',
					'Auth': JSON.parse(readCookie('access_token')).access_token
				},
				body: JSON.stringify(body)
			}).then(function (response) {
				return response.json();
			}).then(function (data) {
				if (data.status === 'ok') {
					this.notifySuccess('Successfully updated portfolio goal.');
					this.refreshPortfolioGoalsData();
				} else {
					this.notifyError(data.message);
				}
			}.bind(this));
		} else {
			this.notifyError('Please select atleast one Agenda.');
		}
	}

	cancelEditPortfolioGoal(index) {
		let data = this.state.data;
		data[index]['mode'] = 'read';
		this.setState({ data });
	}

	deletePortfolioGoal(index) {
		let allPortfolio = this.state.data;
		if (allPortfolio[index].liveProjects > 0) {
			this.notifyError('Cannot delete this portfolio goal.');
		} else {
			let companyId = JSON.parse(readCookie('userData')).organisationData.id;
			fetch(process.env.REACT_APP_API_URL + '/company/' + companyId + '/mis/individual-portfolio/' + allPortfolio[index].id, {
				method: "DELETE",
				headers: {
					'Auth': JSON.parse(readCookie('access_token')).access_token
				}
			}).then(function (response) {
				return response.json();
			}).then(function (data) {
				if (data.status === 'ok') {
					this.notifySuccess('Successfully deleted portfolio goal.');
					this.refreshPortfolioGoalsData();
				}
			}.bind(this));
		}

	}

	editPortfolioGoal(index, id) {
		let goalId = id;
		let data = this.state.data;
		let preferedAgendas = this.state.preferedAgendas;
		for (var i = 0; i < data.length; i++) {
			data[i]['mode'] = 'read';
		}
		let portfolio = data[index];
		let goal = portfolio.goal;

		this.setState({ data, showAddPortfolioGoalForm: false }, function () {
			fetch(process.env.REACT_APP_API_URL + '/getOtAttributes', {
				method: "GET",
				headers: {
					'Auth': JSON.parse(readCookie('access_token')).access_token
				}
			}).then((data) => data.json())
				.then(function (data) {
					let plannedGroup = [], investedGroup = [], preferencesGroup = [], investedAgendas = [];
					for (let i = 0; i < data.otAttributes.length; i++) {
						if (data.otAttributes[i].type === 'agenda') {
							if (data.otAttributes[i]._goalId === goal.id) {
								let agendaObj = {};
								for (var j = 0; j < portfolio.agendas.length; j++) {
									if (portfolio.agendas[j].id === data.otAttributes[i]._id) agendaObj['selected'] = true;
								}
								agendaObj['value'] = data.otAttributes[i]._id;
								if (portfolio.investedAgendas.indexOf(data.otAttributes[i]._id) > -1) { investedAgendas.push(data.otAttributes[i]._id); agendaObj['disabled'] = true };
								agendaObj['label'] = data.otAttributes[i].name;
								let flag = false;
								preferedAgendas[0].children.map((el) => {
									if (el.value === data.otAttributes[i]._id) {
										return flag = true;
									}
								})
								if (flag === true) {
									preferencesGroup.push(agendaObj)
								} else {
									plannedGroup.push(agendaObj)
								}
							}
						}
					}
					let allAgendas = [
						// {
						// 	label: "Invested",
						// 	children: investedGroup
						// },
						{
							label: "Preferences",
							children: preferencesGroup
						},
						{
							label: "Others",
							children: plannedGroup
						}

					];
					this.setState({ allAgendas, investedAgendas }, function () {
						let allPortfolio = this.state.data;
						allPortfolio[index]['mode'] = 'edit';
						this.setState({ data: allPortfolio });
					}.bind(this));

				}.bind(this));
		}.bind(this));
	}

	handleGoalChange(event) {
		let goalId = event.target.value;

		fetch(process.env.REACT_APP_API_URL + '/getOtAttributes', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
			.then(function (data) {
				let allAgendas = [];

				for (let i = 0; i < data.otAttributes.length; i++) {
					if (data.otAttributes[i].type === 'agenda') {
						if (data.otAttributes[i]._goalId === goalId) {
							let agendaObj = {};
							agendaObj['value'] = data.otAttributes[i]._id;
							agendaObj['label'] = data.otAttributes[i].name;
							allAgendas.push(agendaObj);
						}
					}
				}
				this.setState({ allAgendas });
			}.bind(this));
	}

	addPortfolioGoalRow() {
		let data = this.state.data;
		for (var i = 0; i < data.length; i++) {
			data[i]['mode'] = 'read';
		}
		this.setState({ data, showAddPortfolioGoalForm: !this.state.showAddPortfolioGoalForm, allAgendas2: [] });
	}

	handleGoalChange2(event) {
		let goalId = event.target.value;
		let preferedAgendas = this.state.preferedAgendas;
		fetch(process.env.REACT_APP_API_URL + '/getOtAttributes', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
			.then(function (data) {
				let plannedGroup = [], investedGroup = [], preferencesGroup = [];
				for (let i = 0; i < data.otAttributes.length; i++) {
					if (data.otAttributes[i].type === 'agenda') {
						if (data.otAttributes[i]._goalId === goalId) {
							let agendaObj = {};
							agendaObj['value'] = data.otAttributes[i]._id;
							agendaObj['label'] = data.otAttributes[i].name;
							let flag = false;
							preferedAgendas[0].children.map((el) => {
								if (el.value === data.otAttributes[i]._id) {
									return flag = true;
								}
							})
							if (flag === true) {
								preferencesGroup.push(agendaObj)
							} else {
								plannedGroup.push(agendaObj)
							}
						}
					}
				}
				let allAgendas2 = [
					{
						label: "Preferences",
						children: preferencesGroup
					},
					{
						label: "Others",
						children: plannedGroup
					}

				];

				this.setState({ allAgendas2 });

			}.bind(this));
	}

	addPortfolioGoal() {
		if ($('#add-goal-goal-select').val() !== '' && $('input#add-allocated-amount-input').val() > 0 && $('select#add-goal-agendas-select').val().length > 0) {
			let body = {};
			body.goal = $('#add-goal-goal-select').val();
			body.agendas = $('select#add-goal-agendas-select').val();
			body.amount = parseInt($('input#add-allocated-amount-input').val());
			body.year = parseInt(this.state.fiscalYear.split('-')[0]);

			ReactGA.event({
				category: 'CSR Portfolio',
				action: 'edit',
				label: 'Add Portfolio'
			});

			let companyId = JSON.parse(readCookie('userData')).organisationData.id;
			fetch(process.env.REACT_APP_API_URL + '/company/' + companyId + '/mis/portfolio', {
				method: "POST",
				headers: {
					'Content-Type': 'application/json',
					'Auth': JSON.parse(readCookie('access_token')).access_token
				},
				body: JSON.stringify(body)
			}).then(function (response) {
				return response.json();
			}).then(function (data) {
				if (data.status === 'ok') {
					this.setState({ allAgendas2: [] })
					this.notifySuccess('Successfully added portfolio goal.');
					this.refreshPortfolioGoalsData();
					this.addPortfolioGoalRow();
				} else {
					this.notifyError(data.message);
				}
			}.bind(this));
		} else {
			this.notifyError('Mendatory fields are missing');
		}
	}

	handleInvestmentTypeChange(value) {
		this.setState({ investmentType: value });
	}

	notifySuccess = (text) => toast.success(text);
	notifyError = (text) => toast.error(text);

	gotoHandler = () => {
		ReactGA.event({
			category: 'CSR Portfolio',
			action: 'edit',
			label: 'Browse Projects'
		});
		window.location.pathname = '/discover/projects';
	}

	setAnnualBudget = () => {
		window.location.pathname = "/fiscal-year-budgeting"
		sessionStorage.setItem("selectedFiscalYear", this.state.fiscalYear)
	}

	ignoreWarnig = () => {
		let warning = {
			csrBudgetAmount: this.state.csrBudget.amount,
			portfolioTotalAmount: this.state.portfolioTotalAmount,
			ignored: true
		}
		localStorage.setItem("goalPortfolioWarning", JSON.stringify(warning))
		this.setState({
			showWarning: !this.state.showWarning
		})
	}

	// cancelWarning = () => {
	// 	localStorage.setItem("individualGoalPortfolioWarning", "ignored")
	// 	this.setState({
	// 		cancelWarning: !this.state.cancelWarning
	// 	})
	// }

	render() {
		return (
			<div className={(this.state.showWarning && this.state.csrBudget !== null && (this.state.csrBudget.amount < this.state.portfolioTotalAmount && (this.state.portfolioTotalAmount / this.state.csrBudget.amount) > 1.1)) ? "mis-content-container show-warning" : "mis-content-container"}>
				<ToastContainer position="bottom-center" autoClose={4000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={true} />
				{this.state.showLoader ? (
					<Loader loaderHiding={this.state.loaderHiding} />
				) : ('')}
				{this.state.fiscalYear !== null ? (
					<div>
						<div className={this.state.showWarning === true ? "toggleView" : "toggleView_1"}>
							<button className="toggleView-button-investments" onClick={() => { window.location.pathname = "/investments" }}> Switch to All Investments <i className="fa fa-angle-double-right"></i></button>
							<h3 style={{ textAlign: "center", marginTop: "30px" }}>Goal-wise Investments</h3>
							<div className="fiscal-year-selector pull-right mt20">
								<span>
									Select Fiscal Year:
									<div id="dd" className="wrapper-dropdown-3" tabIndex="1" onClick={this.ddClick.bind(this)}>
										<span>{this.getCurrentFiscalYear()}</span>
										<ul className="dropdown">
											{years.map(function (year, index) {
												return (
													<li key={index} onClick={this.selectYear.bind(this)}><a href="#">{year}</a></li>
												)
											}.bind(this))}
										</ul>
									</div>
								</span>
							</div>
						</div>
						{this.state.annualBudgetNotFound ? (
							<div className="create-annual-budget-container">
								{/* <div className="create-annual-budget-button" onClick={this.setAnnualBudget.bind(this)}>Create Your Annual Budget for {this.state.fiscalYear}</div> */}
								{this.state.csrBudget !== null ? (
									<div className="create-annual-budget-button" onClick={this.setAnnualBudget}>Create Your Annual Budget for {this.state.fiscalYear}</div>
								) : (
									JSON.parse(readCookie('userData')).authorizedFor.mis !== 'view' ? (
										<div className="create-annual-budget-button" onClick={() => window.location.pathname = "/investments"}>You have not made any investments. Start Investing now!</div>
									) : (null)
								)}
							</div>
						) : (
								<Col md={12} className="portfolio-goals-table">
									{(() => {
										if (this.state.csrBudget !== null) {
											if (this.state.csrBudget.amount < this.state.portfolioTotalAmount && (this.state.portfolioTotalAmount / this.state.csrBudget.amount) > 1.1) {
												if (this.state.showWarning === true) {
													return (
														<div className="balance-banner">
															{'Hey! You have allocated '}
															<span className="amount-span">INR {this.state.portfolioTotalAmount - this.state.csrBudget.amount}</span>
															{' more than your Portfolio planning. You can update your plans from the Fiscal Year Budget section now!'}
															<div style={{ marginTop: "10px" }}>
																<button onClick={() => { window.location.pathname = "/fiscal-year-budgeting" }}>Go to Fiscal Year Budget</button>
																<button onClick={this.ignoreWarnig}>Ok</button>
															</div>
														</div>
													)
												}
											// } else if (this.state.csrBudget.amount > this.state.portfolioTotalAmount && ((this.state.portfolioTotalAmount / this.state.csrBudget.amount) > 0.75 && (this.state.portfolioTotalAmount / this.state.csrBudget.amount) < 0.95)) {
											// 	if (this.state.showWarning === true) {
											// 		return (
											// 			<div className="balance-banner">
											// 				{'Hey! You have allocated '}
											// 				<span className="amount-span">INR {this.state.csrBudget.amount - this.state.portfolioTotalAmount}</span>
											// 				{' less than your Portfolio planning. Start Allocating now!'}
											// 				<div style={{ marginTop: "10px" }}>
											// 					<button onClick={this.ignoreWarnig}>Ok</button>
											// 				</div>
											// 			</div>
											// 		)
											// 	}
											}
										}
									})()}
									{/*this.state.data.length !== 0 && this.state.portfolioTotalAmount !== undefined && this.state.portfolioTotalAmount > 0 ? (
										<h4 className="col-md-6"><label>Total Amount Allocated:</label> Rs. {window.inrFormat(this.state.portfolioTotalAmount)}</h4>
									) : (
											<h4 className="col-md-6"><label>Total Amount Allocated:</label> Rs. 0</h4>
										)*/}
									{this.state.csrBudget !== null && this.state.csrBudget.amount !== undefined ? (
										<h4 className="col-md-6 text-right"><label>Planned Budget Amount:</label> Rs. {window.inrFormat(this.state.csrBudget.amount)}</h4>
									) : (
											<h4 className="col-md-6 text-right"><label>Planned Budget Amount:</label> Rs. 0</h4>
										)}
									{!this.state.data.length ? (
										<div className="text-center" style={{ fontSize: '18px', margin: '50px 0' }}>Create some Portfolio Goals to invest in them.</div>
									) : (null)}
									{this.state.data.map(function (portfolioGoal, index) {
										const SubMenu = Menu.SubMenu;
										let planned = [], invested = [];
										for (var i = 0; i < this.state.realAllAgendas.length; i++) {
											if (portfolioGoal.investedAgendas.indexOf(this.state.realAllAgendas[i].value) > -1) invested.push(this.state.realAllAgendas[i]);
										}
										for (var i = 0; i < portfolioGoal.agendas.length; i++) {
											if (portfolioGoal.investedAgendas.indexOf(portfolioGoal.agendas[i].value) === -1) planned.push(portfolioGoal.agendas[i]);
										}
										let menu;
										if (portfolioGoal.agendas.length) {
											menu = (
												<Menu>
													{planned.length ? (
														<SubMenu title="Planned">
															{planned.map((agenda, i) => {
																return (
																	<Menu.Item key={i}>{agenda.name}</Menu.Item>
																)
															})}
														</SubMenu>
													) : (
															<SubMenu title="Planned">
																<Menu.Item>No Agendas to display</Menu.Item>
															</SubMenu>
														)}
													{invested.length ? (
														<SubMenu title="Invested">
															{invested.map((agenda, i) => {
																return (
																	<Menu.Item key={i}>{agenda.label}</Menu.Item>
																)
															})}
														</SubMenu>
													) : (
															<SubMenu title="Invested">
																<Menu.Item>No Investments yet</Menu.Item>
															</SubMenu>
														)}
												</Menu>
											);
										} else {
											menu = (
												<Menu>
													<Menu.Item>No Agendas to display</Menu.Item>
												</Menu>
											);
										}
										return (
											<Col key={index} className="portfolio-goal mb10 mt10" md={12}>
												<Col md={8} className="portfolio-goal-details">
													{portfolioGoal.mode === 'read' ? (null) : (
														<h4 className="text-center mb10" style={{ fontWeight: '600' }}>Edit Portfolio Goal</h4>
													)}
													<Col md={12} className={portfolioGoal.mode === 'read' ? "portfolio-goal-name-container" : ""}>
														<label>Portfolio Goal: </label>
														{portfolioGoal.goal.name}
													</Col>
													{portfolioGoal.mode === "read" ? (
														<Col md={12} className={portfolioGoal.mode === 'read' ? "portfolio-goal-name-container" : ""}>
															<Dropdown overlay={menu}>
																<a className="ant-dropdown-link" href="#">Portfolio Agendas: <Icon style={{ marginTop: "5px" }} icon={chevronDown} /></a>
															</Dropdown>
														</Col>
													) : (
															<Col md={12} className="mt10">
																<label>Portfolio Agendas: </label>
																<Multiselect onChange={this.disabledValue} id="edit-goal-agendas-select" buttonClass="btn btn-default"
																	buttonText={this.showButtonText.bind(this)} multiple
																	buttonWidth={'100%'} data={this.state.allAgendas} />
															</Col>
														)}
													{portfolioGoal.mode === 'read' && JSON.parse(readCookie('userData')).authorizedFor.mis !== 'view' ? (
														<div className="goal-buttons">
															<div className="edit-goal-button btn btn-success" onClick={this.editPortfolioGoal.bind(this, index, portfolioGoal.goal.id)}>
																<Icon icon={ic_edit} />
																Edit
															</div>
															{portfolioGoal.projectCount > 0 ? (null) : (
																<div className="delete-goal-button btn btn-danger" onClick={this.deletePortfolioGoal.bind(this, index)}>
																	<Icon icon={ic_delete} />
																</div>
															)}
														</div>
													) : (null)}
													{portfolioGoal.projectCount ? (
														<Col md={12} className="mt10 read-more">
															<button className="btn" onClick={() => { window.location.pathname = "/portfolio/" + portfolioGoal.id }}>View Projects</button>
														</Col>
													) : (null)}
													{/*portfolioGoal.amount > 0 ? (
														portfolioGoal.investedAmount > portfolioGoal.amount ? (
															this.state.cancelWarning ? (
																<span style={{ margin: "15px 0 0 15px", color: "#fff", background: "red", padding: "0 10px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>Your Invested Amount is greater than Allocated Amount ! <i className="fa fa-times" style={{ cursor: "pointer" }} onClick={this.cancelWarning}></i></span>
															) : (null)
														) : (null)
													) : (null)*/}
												</Col>
												<Col md={4} className="portfolio-goal-amount">
													<Col md={12} className={portfolioGoal.mode === 'read' ? "mt10" : "mt10 text-center"}>
														<label>Allocated Amount:</label>
														{portfolioGoal.mode === 'read' ? (
															<b>{window.inrFormat(portfolioGoal.amount)}</b>
														) : (
																<input id="edit-allocated-amount-input" type="number" placeholder={0} defaultValue={window.inrFormat(portfolioGoal.amount)} />
															)}
													</Col>
													{portfolioGoal.mode === 'read' ? (
														<Col md={12} className="mt10">
															<label>Invested Amount:</label>
															<b>{window.inrFormat(portfolioGoal.investedAmount)}</b>
														</Col>
													) : (null)}
													{portfolioGoal.mode === 'read' ? (
														<hr className="portfolio-goal-amount-line-break" />
													) : (null)}
													{portfolioGoal.mode === 'read' ? (
														<Col md={12} className="mt10">
															<label>Total Projects:</label>
															<b>{portfolioGoal.projectCount}</b>
														</Col>
													) : (null)}
													{portfolioGoal.mode === 'read' ? (
														<Col md={12} className="mt10 invest-funds">
															{/* <button onClick={() => { window.location.pathname = "/portfolio/" + portfolioGoal.id }} className="btn btn-default le-button">Invest Now</button> */}
														</Col>
													) : (
															<Col md={12} className="mt10 invest-funds edit-mode">
																<button onClick={this.updatePortfolioGoal.bind(this, index, portfolioGoal.id)} className="btn btn-default le-button">
																	<Icon icon={ic_done} />
																	Save
													</button>
																<button onClick={this.cancelEditPortfolioGoal.bind(this, index)} className="btn btn-default btn-danger">
																	<Icon icon={ic_cancel} />
																	Cancel
													</button>
															</Col>
														)}
												</Col>
											</Col>
										)
									}.bind(this))}
									{this.state.showAddPortfolioGoalForm ? (
										<Col className="portfolio-goal mb10 mt10" md={12}>
											<Col md={9} className="portfolio-goal-details">
												<h4 className="text-center mb10" style={{ fontWeight: '600' }}>Add Portfolio Goal</h4>
												<Col md={12}>
													<label className='portfolio-label'>Portfolio Goal: </label>
													<select id="add-goal-goal-select" className="btn btn-default" defaultValue="" onChange={this.handleGoalChange2.bind(this)}>
														<option disabled value="">None Selected</option>
														<optgroup label="Preferences">
															{this.state.allGoals[0].children.map(function (goal, index) {
																return <option key={index} value={goal.value}>{goal.label}</option>
															}.bind(this))}
														</optgroup>
														<optgroup label="Others">
															{this.state.allGoals[1].children.map(function (goal, index) {
																return <option key={index} value={goal.value}>{goal.label}</option>
															}.bind(this))}
														</optgroup>
													</select>
												</Col>
												<Col md={12} className="mt10">
													<label className='portfolio-label'>Portfolio Agendas: </label>
													<Multiselect id="add-goal-agendas-select" buttonClass="btn btn-default"
														buttonText={this.showButtonText.bind(this)} multiple
														buttonWidth={'100%'} data={this.state.allAgendas2} />
												</Col>
											</Col>
											<Col md={3} className="portfolio-goal-amount">
												<Col md={12} className="mt10 text-center">
													<label className='portfolio-label'>Allocated Amount:</label>
													<input id="add-allocated-amount-input" type="number" placeholder={0} />
												</Col>
												<Col md={12} className="mt10 invest-funds edit-mode">
													<button onClick={this.addPortfolioGoal.bind(this)} className="btn btn-default le-button">
														<Icon icon={ic_done} />
														Save
												</button>
													<button onClick={this.addPortfolioGoalRow.bind(this)} className="btn btn-default btn-danger">
														<Icon icon={ic_cancel} />
														Cancel
												</button>
												</Col>
											</Col>
										</Col>
									) : (null)}
									<div className="text-center">
										<ButtonGroup className='add-portfolio-button-container' sizeClass='btn-group-md'>
											{JSON.parse(readCookie('userData')).authorizedFor.mis !== 'view' ? (
												<button type='button' onClick={this.addPortfolioGoalRow} className='btn btn-default react-bs-table-add-btn hidden-print add-portfolio-button'>
													<span>
														<i className="glyphicon glyphicon-plus"></i>
														Add Portfolio Goal
													</span>
												</button>
											) : (null)}
											{JSON.parse(readCookie('userData')).authorizedFor.mis !== 'view' ? (
												<p className="or">OR</p>
											) : (null)}
											<button className="btn-go-to-csr-portfolio" onClick={this.gotoHandler}> Browse Projects </button>
										</ButtonGroup>
									</div>
								</Col>
							)}
					</div>
				) : (
						<div className="create-portfolio-container">
							<div className="create-portfolio-button" onClick={this.selectFiscalYear.bind(this, 'default')}>Create Your Portfolio</div>
						</div>
					)}
			</div>
		)
	}
}
