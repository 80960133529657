import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import * as action from '../../../actions/DiscoverCausesAction';

import CausesBanner from './CausesBanner';
import CausesContainer from './CausesContainer';

const readCookie = require('../../../cookie.js').readCookie;

class DiscoverCauses extends Component {
	componentDidMount() {
		fetch(process.env.REACT_APP_API_URL + '/opportunityType/discover', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
		.then(function(data) {
			this.props.actions.updateCauses(data.data);
			this.props.actions.updateFilteredCauses(data.data);
		}.bind(this));
	}

	render() {
		return (
			<div>
				<CausesBanner />
				<CausesContainer causes={this.props.causes}
					filteredCauses={this.props.filteredCauses}
					searchTerm={this.props.searchTerm}

					updateCauses={this.props.actions.updateCauses}
					updateFilteredCauses={this.props.actions.updateFilteredCauses}
					updateSearchTerm={this.props.actions.updateSearchTerm}
					updateSelectedCause={this.props.actions.updateSelectedCause} />
			</div>
		);
	}
}

DiscoverCauses.propTypes = {
	causes: PropTypes.array,
	filteredCauses: PropTypes.array,
	searchTerm: PropTypes.string,
	selectedCauseId: PropTypes.string
};

function mapStateToProps(state) {
	return {
		causes: state.DiscoverCauses.causes,
		filteredCauses: state.DiscoverCauses.filteredCauses,
		searchTerm: state.DiscoverCauses.searchTerm,
		selectedCauseId: state.DiscoverCauses.selectedCauseId
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(action, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DiscoverCauses);