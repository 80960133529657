import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import Select from 'react-select';
import $ from 'jquery';
import Multiselect from 'react-bootstrap-multiselect';
import {createFilter} from 'react-search-input';
import Slider from 'rc-slider';

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

const KEYS_TO_FILTERS = ['title'];

let filteredProjects = [];

const readCookie = require('../../../cookie.js').readCookie;

export default class DiscoverCSRProjectsFilter extends Component {
	constructor(props) {
    super(props);
    this.state = {
    	filters: null,
    	goal: null,
    	goalValue: "",
    	min: 0,
    	max: this.props.max
    };
  }

  componentDidMount() {
  	fetch(process.env.REACT_APP_API_URL + '/getOtAttributes', {
  		method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
  	}).then((data) => data.json())
		.then(function(data) {
			this.setState({ filters: data.otAttributes });
			let goal = [];

			for(let i = 0; i < data.otAttributes.length; i++) {
				if(data.otAttributes[i].type === 'goal') {
					let goalObj = {};
					goalObj['value'] = data.otAttributes[i]._id;
					goalObj['label'] = data.otAttributes[i].name;
					goal.push(goalObj);
				}
			}
			this.setState({ goal });
		}.bind(this));
  }

  handleChange(name, value) {
		this.setState({ [name]: $(value).parent().val() });
	}

	resetFilters() {
		let goalValue = [];

		this.setState({ goalValue: null }, function() {
			this.setState({ goalValue, min: 0, max: this.props.max });
		});

		fetch(process.env.REACT_APP_API_URL + '/projects/discover', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
		.then(function(data) {
			this.props.updateFilteredProjects(data.data);
		}.bind(this));
	}

	applyFilters() {
		let params = {};
		if(this.state.goalValue !== '') {
			if(Array.isArray(this.state.goalValue)) {
				if(this.state.goalValue.length) {
					params['goalId'] = [];
					this.state.goalValue.map(function(val) {
						params['goalId'].push(val);
					})
				}
			}
		}
		params['minBudget'] = this.state.min;
		params['maxBudget'] = this.state.max;

		let esc = encodeURIComponent
		let query = Object.keys(params).map(function(k) {
			if(Array.isArray(params[k])) {
				let x = '';
				for(let i = 0; i < params[k].length; i++) {
					if(i === 0) x += esc(k) + '=' + esc(params[k][i]);
					else x += '&' + esc(k) + '=' + esc(params[k][i])
				}
				return x;
			} else return esc(k) + '=' + esc(params[k])
		}).join('&')

		let url = process.env.REACT_APP_API_URL + '/projects/discover?' + query
		fetch(url, {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
		.then(function(data) {
			this.props.updateFilteredProjects(data.data);
		}.bind(this));
	}

	applyAdvancedFilters() {
		// console.log(this.state.engagementValue, this.state.timeTakenValue, this.state.labourDependentValue, this.state.frequencyValue, this.state.locationValue)
	}

	showButtonText(options, select) {
	  if (options.length === 0) {
      return 'No option selected ...';
	  } else if (options.length > 2) {
      return options.length + ' options selected!';
	  } else {
    	let labels = [];
      options.each(function() {
	     	if ($(this).attr('label') !== undefined) {
	      	labels.push($(this).attr('label'));
	     	} else {
	      	labels.push($(this).html());
	    	}
 			});
			return labels.join(', ') + '';
    }
  }

  updateSearchTerm(e) {
		let value = e.target.value;
		this.props.updateSearchTerm(value);
		this.setState({ searchTerm: value }, function() {
			if(value === '') this.searchUpdated();
		}.bind(this));
	}

	searchUpdated() {
		this.props.updateSearchTerm(this.props.searchTerm);
		this.props.updateFilteredProjects(filteredProjects);
	}

	log(val) {
		this.updateMinMax('minmax', val);
	}

	percentFormatter(v) {
	  return `INR ${v}`;
	}

	updateMinMax(name, val) {
		if(name === 'minmax') {
			this.setState({ min: val[0], max: val[1] });
		} else if(name === 'min' || name === 'max') {
			let value;
			if(val.target.value === '') value = 0;
			else value = val.target.value;
			this.setState({ [name]: parseInt(value) });
		}
	}

	toggleInterested() {
		if(this.props.showInterested) {
			fetch(process.env.REACT_APP_API_URL + '/projects/discover', {
				method: "GET",
				headers: {
					'Auth': JSON.parse(readCookie('access_token')).access_token
				}
			}).then((data) => data.json())
			.then(function(data) {
				this.props.toggleInterested();
				this.props.updateFilteredProjects(data.data);
			}.bind(this));
		} else {
			let companyId = JSON.parse(readCookie('userData')).organisationData.id;
			fetch(process.env.REACT_APP_API_URL + '/project/interests/company/' + companyId, {
				method: "GET",
				headers: {
					'Auth': JSON.parse(readCookie('access_token')).access_token
				}
			}).then((data) => data.json())
			.then(function(data) {
				this.props.toggleInterested();
				this.props.updateShortlistedProjects(data.data);
			}.bind(this));
		}
	}

	render() {
		filteredProjects = this.props.projects.filter(createFilter(this.props.searchTerm, KEYS_TO_FILTERS))

		return (
			<div>
				<Row className="csr-projects-filters">
					<Col md={12} className="mb10">
						<Col md={3} className="heading">SEARCH</Col>
						<Col md={9}>
							<div className="csr-projects-search-filter-container">
								<input className="csr-projects-search-filter-input" type="search" placeholder="Search by Name" onChange={this.updateSearchTerm.bind(this)} />
								<button className="btn csr-projects-search-filter-icon" onClick={this.searchUpdated.bind(this)}>SEARCH</button>
							</div>
						</Col>
					</Col>

					<Col md={3}>
						<span>Goals</span>
						{this.state.goalValue !== null ? (
								<Multiselect id="csr-project-goals" defaultValue={this.state.goalValue} onChange={this.handleChange.bind(this, 'goalValue')} buttonWidth={'100%'} buttonText={this.showButtonText.bind(this)} data={this.state.goal} multiple />
							) : ('')
						}
					</Col>
					<Col md={3}>
						<span>Budget Range</span>
				    {this.state.min !== null && this.state.max !== null ? (
				    	<div>
								<Range step={5000} min={0} max={this.props.max} value={[this.state.min, this.state.max]} marks={this.props.marks} onChange={this.log.bind(this)} tipFormatter={this.percentFormatter} />
								<div className="csr-projects-minmax-budget-container">
									<b>{'Min : '}</b><input className="csr-projects-minmax-budget" type="number" placeholder="Min" onChange={this.updateMinMax.bind(this, 'min')} value={this.state.min} />
									<b>{'Max : '}</b><input className="csr-projects-minmax-budget" type="number" placeholder="Max" onChange={this.updateMinMax.bind(this, 'max')} value={this.state.max} />
								</div>
							</div>
						) : (null)}
					</Col>
					<Col md={3} className="shortlisted">
						<input type="checkbox" value={this.props.showInterested} onChange={this.toggleInterested.bind(this)} />
						<span>Show Only Interested</span>
					</Col>
					<Col md={1} className="btn filter-reset-btn" onClick={this.resetFilters.bind(this)}>Reset</Col>
					<Col md={1} className="btn filter-apply-btn" onClick={this.applyFilters.bind(this)}>Apply</Col>
				</Row>
				<Row className="advanced-filters" hidden={true}>
					<Col md={3}>
						<span>Frequency</span>
				    <Select multi={true} name="project-frequency"
						  value={this.state.frequencyValue} options={this.state.frequency}
						  onChange={this.handleChange.bind(this, 'frequencyValue')} />
					</Col>
					<Col md={3}>
						<span>Time Taken</span>
				    <Select multi={true} name="project-timetaken"
						  value={this.state.timeTakenValue} options={this.state.timeTaken}
						  onChange={this.handleChange.bind(this, 'timeTakenValue')} />
					</Col>
					<Col md={3}>
						<span>Labour Dependent</span>
				    <Select multi={true} name="project-labour-dependent"
						  value={this.state.labourDependentValue} options={this.state.labourDependent}
						  onChange={this.handleChange.bind(this, 'labourDependentValue')} />
					</Col>
					<Col className="btn filter-apply-btn" onClick={this.applyAdvancedFilters.bind(this)}>Apply</Col>
				</Row>
			</div>
		);
	}
}