import React, {Component} from 'react';

export default class InvestmentGoalPlan extends Component {
	render(){
		return(
			<div className="mis-content-container">
				<div>This section is yet not prepared</div>
			</div>
		);
	}
}