import React, {Component} from 'react';
import Map from './Map';

export default class DiscoverProjectsMap extends Component {
	render () {
		return (
			<Map
				initialPosition={this.props.initialPosition}
				projects={this.props.projects}
				topProject={this.props.topProject}
				updateTopProject={this.props.updateTopProject}
			/>
		);
	}
}