import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';

export default class ProfileSettings extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}

	render() {
		return (
			<Row>
				ProfileSettings
			</Row>
		);
	}
}