import React, { Component } from "react";
import {BootstrapTable, TableHeaderColumn, ButtonGroup} from 'react-bootstrap-table';
import swal from "sweetalert2";
import { ToastContainer, toast } from 'react-toastify';
import { Col, Row, Tab, Tabs, Modal } from 'react-bootstrap';
import moment from 'moment';
import { DatePicker } from 'antd';
import { Select } from 'antd';
import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend} from 'recharts';

const { RangePicker } = DatePicker;
const dateFormat = 'MMM DD YYYY';
const monthFormat = 'MM/YYYY';

const checkValue = require('../../../checkValue.js').checkValue;
const readCookie = require('../../../cookie.js').readCookie;
const Option = Select.Option;

const data = [
  {
    name: 'Partner 1', uv: 4000, pv: 2400, amt: 2400,
  },
  {
    name: 'Partner 2', uv: 3000, pv: 1398, amt: 2210,
  },
  {
    name: 'Partner 3', uv: 2000, pv: 9800, amt: 2290,
  },
  {
    name: 'Partner 4', uv: 2780, pv: 3908, amt: 2000,
  },
  {
    name: 'Partner 5', uv: 1890, pv: 4800, amt: 2181,
  },
  {
    name: 'Partner 6', uv: 2390, pv: 3800, amt: 2500,
  },
  {
    name: 'Partner 7', uv: 3490, pv: 4300, amt: 2100,
  },
];

class Trends extends Component {
	constructor(props){
		super(props);
		this.state = {
			selectRowProp: {
        mode: 'checkbox',
        onSelect: this.onRowSelect.bind(this),
        onSelectAll: this.onAllRowSelect.bind(this)
      },
      selectedRowCount: 0,
      dummyData: [
      	{partnerName: "partner1"},
      	{partnerName: "partner2"},
      	{partnerName: "partner3"},
      	{partnerName: "partner4"},
      	{partnerName: "partner5"},
      	{partnerName: "partner6"},
      ],
      tags:[],
      selectView:"",
      selectGoals:"",
      selectState:"",
      selectCity:"",
      mainDate: [moment().format('MMM DD YYYY'), moment().format('MMM DD YYYY')],
      mainDateRange: "",
      compareToDate: [moment().subtract(1, 'days').format('MMM DD YYYY'), moment().subtract(1, 'days').format('MMM DD YYYY')],
      compareToDateRange: "",
      showDatePicker: false,
      isCompareTo: false,
      isDisabled: false,
      compareNow: false,
      selectedKpis: [],
      showFilters: false,
      screenWidth: ''
		}
	}

	componentDidMount () {
		this.setState({screenWidth: window.innerWidth})
	}

	onRowSelect(row, isSelected) {
		let selectedKpis = this.state.selectedKpis;
		let index = selectedKpis.indexOf(row.kpiId);
		if(index > -1){
			selectedKpis.splice(index,1);
		} else {
			selectedKpis.push(row.kpiId);
		}
		this.setState({selectedKpis})
    if (isSelected) {
      this.setState({ selectedRowCount: this.refs.table.state.selectedRowKeys.length + 1 }, function () {
        if (this.state.selectedRowCount) {
          this.setState({ deleteButtonDisabled: false });
        }
      });
    } else {
      this.setState({ selectedRowCount: this.refs.table.state.selectedRowKeys.length - 1, selectedKpis }, function () {
        if (this.state.selectedRowCount) {
          this.setState({ deleteButtonDisabled: false });
        } else {
          this.setState({ deleteButtonDisabled: true });
        }
      });
    }
  }

  onAllRowSelect(row, isSelected) {
  	let selectedKpis = this.state.selectedKpis;
    if (row) {
    	isSelected.map((kpi, i) => {
    		selectedKpis.push(kpi.kpiId);
    	})
      this.setState({
        selectedRowCount: this.refs.table.state.data.length,
        deleteButtonDisabled: false,
        selectedKpis
      });
    } else {
    	selectedKpis = [];
      this.setState({
        selectedRowCount: 0,
        deleteButtonDisabled: true,
        selectedKpis
      });
    }
  }

  handleChange = (value) => {
  	this.setState({tags: value});
	}

	filters = (e) => {
		this.setState({[e.target.name]: e.target.value});
	}

	resetFilters = () => {
		this.setState({selectGoals:"", selectState:"", selectView:"", selectCity:"", tags:[]})
	}

	showDatePicker = () => {
		this.setState({showDatePicker: !this.state.showDatePicker})
	}

	resetDatePicker = () => {
		this.setState({
			mainDateRange: "custom",
			compareToDateRange: "custom",
			isDisabled: false,
			mainDate: [moment().format('MMM DD YYYY'), moment().format('MMM DD YYYY')],
			compareToDate: [
				moment().subtract(1, 'days').format('MMM DD YYYY'),
				moment().subtract(1, 'days').format('MMM DD YYYY')
			]
		})
	}

	dateRange = (e) => {
		this.setState({[e.target.name]: e.target.value });
		if (e.target.value === "today") {
			this.setState({
				isDisabled: false,
				compareToDateRange: "previousPeriod",
				mainDate: [moment().format('MMM DD YYYY'), moment().format('MMM DD YYYY')],
				compareToDate: [
					moment().subtract(1, 'days').format('MMM DD YYYY'),
					moment().subtract(1, 'days').format('MMM DD YYYY')
				]
			});
		} else if (e.target.value === "yesterday") {
			this.setState({
				isDisabled: false,
				compareToDateRange: "previousPeriod",
				mainDate: [moment().subtract(1, 'days').format('MMM DD YYYY'), moment().subtract(1, 'days').format('MMM DD YYYY')],
				compareToDate: [
					moment().subtract(2, 'days').format('MMM DD YYYY'),
					moment().subtract(2, 'days').format('MMM DD YYYY')
				]
			});
		} else if (e.target.value === "last7Days") {
			this.setState({
				isDisabled: true,
				compareToDateRange: "previousPeriod",
				mainDate: [moment().subtract(7, 'days').format('MMM DD YYYY'), moment().subtract(1, 'days').format('MMM DD YYYY')],
				compareToDate: [
					moment().subtract(14, 'days').format('MMM DD YYYY'),
					moment().subtract(8, 'days').format('MMM DD YYYY')
				]
			});
		} else if (e.target.value === "last30Days") {
			this.setState({
				isDisabled: true,
				compareToDateRange: "previousPeriod",
				mainDate: [moment().subtract(30, 'days').format('MMM DD YYYY'), moment().subtract(1, 'days').format('MMM DD YYYY')],
				compareToDate: [
					moment().subtract(60, 'days').format('MMM DD YYYY'),
					moment().subtract(31, 'days').format('MMM DD YYYY')
				]
			});
		} else if (e.target.value === "lastMonth") {
			this.setState({
				isDisabled: false,
				compareToDateRange: "previousPeriod",
				mainDate: [moment().subtract(1, 'months').startOf('month').format('MMM DD YYYY'), moment().subtract(1, 'months').endOf('month').format('MMM DD YYYY')],
				compareToDate: [
					moment().subtract(2, 'months').startOf('month').format('MMM DD YYYY'),
					moment().subtract(2, 'months').endOf('month').format('MMM DD YYYY')
				]
			});
		} else if (e.target.value === "lastWeek") {
			this.setState({
				isDisabled: false,
				compareToDateRange: "previousPeriod",
				mainDate: [moment().subtract(1, 'weeks').startOf('week').format('MMM DD YYYY'), moment().subtract(1, 'weeks').endOf('week').format('MMM DD YYYY')],
				compareToDate: [
					moment().subtract(2, 'weeks').startOf('week').format('MMM DD YYYY'),
					moment().subtract(2, 'weeks').endOf('week').format('MMM DD YYYY')
				]
			});
		} else if (this.state.mainDateRange === "lastWeek" && e.target.value === "previousYear") {
			this.setState({
				compareToDateRange: "previousYear",
				mainDate: [moment().subtract(1, 'weeks').startOf('week').format('MMM DD YYYY'), moment().subtract(1, 'weeks').endOf('week').format('MMM DD YYYY')],
				compareToDate: [
					moment().subtract(2, 'weeks').startOf('week').format('MMM DD YYYY').replace(moment().subtract(2, 'weeks').startOf('week').year(), ((moment().subtract(2, 'weeks').startOf('week').year()) - 1)),
					moment().subtract(2, 'weeks').endOf('week').format('MMM DD YYYY').replace(moment().subtract(2, 'weeks').endOf('week').year(), ((moment().subtract(2, 'weeks').endOf('week').year()) - 1))
				]
			});
		} else if (this.state.mainDateRange === "lastMonth" && e.target.value === "previousYear") {
			this.setState({
				compareToDateRange: "previousYear",
				mainDate: [moment().subtract(1, 'months').startOf('month').format('MMM DD YYYY'), moment().subtract(1, 'months').endOf('month').format('MMM DD YYYY')],
				compareToDate: [
					moment().subtract(2, 'months').startOf('month').format('MMM DD YYYY').replace(moment().subtract(2, 'months').startOf('month').year(), ((moment().subtract(2, 'months').startOf('month').year()) - 1)),
					moment().subtract(2, 'months').endOf('month').format('MMM DD YYYY').replace(moment().subtract(2, 'months').endOf('month').year(), ((moment().subtract(2, 'months').endOf('month').year()) - 1))
				]
			});
		} else if (this.state.mainDateRange === "yesterday" && e.target.value === "previousYear") {
			this.setState({
				compareToDateRange: "previousYear",
				mainDate: [moment().subtract(1, 'days').format('MMM DD YYYY'), moment().subtract(1, 'days').format('MMM DD YYYY')],
				compareToDate: [
					moment().subtract(1, 'days').format('MMM DD YYYY').replace(moment().subtract(1, 'days').year(), ((moment().subtract(1, 'days').year()) - 1)),
					moment().subtract(1, 'days').format('MMM DD YYYY').replace(moment().subtract(1, 'days').year(), ((moment().subtract(1, 'days').year()) - 1))
				]
			});
		} else if (this.state.mainDateRange === "today" && e.target.value === "previousYear") {
			this.setState({
				compareToDateRange: "previousYear",
				mainDate: [moment().format('MMM DD YYYY'), moment().format('MMM DD YYYY')],
				compareToDate: [
					moment().format('MMM DD YYYY').replace(moment().year(), ((moment().year()) - 1)),
					moment().format('MMM DD YYYY').replace(moment().year(), ((moment().year()) - 1))
				]
			});
		} else if (this.state.mainDateRange === "today" && e.target.value === "previousPeriod") {
			this.setState({
				compareToDateRange: "previousPeriod",
				mainDate: [moment().format('MMM DD YYYY'), moment().format('MMM DD YYYY')],
				compareToDate: [
					moment().subtract(1, 'days').format('MMM DD YYYY'),
					moment().subtract(1, 'days').format('MMM DD YYYY')
				]
			});
		} else if (this.state.mainDateRange === "yesterday" && e.target.value === "previousPeriod") {
			this.setState({
				compareToDateRange: "previousPeriod",
				mainDate: [moment().subtract(1, 'days').format('MMM DD YYYY'), moment().subtract(1, 'days').format('MMM DD YYYY')],
				compareToDate: [
					moment().subtract(2, 'days').format('MMM DD YYYY'),
					moment().subtract(2, 'days').format('MMM DD YYYY')
				]
			});
		} else if (this.state.mainDateRange === "last7Days" && e.target.value === "previousPeriod") {
			this.setState({
				compareToDateRange: "previousPeriod",
				mainDate: [moment().subtract(7, 'days').format('MMM DD YYYY'), moment().subtract(1, 'days').format('MMM DD YYYY')],
				compareToDate: [
					moment().subtract(14, 'days').format('MMM DD YYYY'),
					moment().subtract(8, 'days').format('MMM DD YYYY')
				]
			});
		} else if (this.state.mainDateRange === "last30Days" && e.target.value === "previousPeriod") {
			this.setState({
				compareToDateRange: "previousPeriod",
				mainDate: [moment().subtract(30, 'days').format('MMM DD YYYY'), moment().subtract(1, 'days').format('MMM DD YYYY')],
				compareToDate: [
					moment().subtract(60, 'days').format('MMM DD YYYY'),
					moment().subtract(31, 'days').format('MMM DD YYYY')
				]
			});
		} else if (this.state.mainDateRange === "lastMonth" && e.target.value === "previousPeriod") {
			this.setState({
				compareToDateRange: "previousPeriod",
				mainDate: [moment().subtract(1, 'months').startOf('month').format('MMM DD YYYY'), moment().subtract(1, 'months').endOf('month').format('MMM DD YYYY')],
				compareToDate: [
					moment().subtract(2, 'months').startOf('month').format('MMM DD YYYY'),
					moment().subtract(2, 'months').endOf('month').format('MMM DD YYYY')
				]
			});
		} else if (this.state.mainDateRange === "lastWeek" && e.target.value === "previousPeriod") {
			this.setState({
				compareToDateRange: "previousPeriod",
				mainDate: [moment().subtract(1, 'weeks').startOf('week').format('MMM DD YYYY'), moment().subtract(1, 'weeks').endOf('week').format('MMM DD YYYY')],
				compareToDate: [
					moment().subtract(2, 'weeks').startOf('week').format('MMM DD YYYY'),
					moment().subtract(2, 'weeks').endOf('week').format('MMM DD YYYY')
				]
			});
		}
	}

	dataOnChange = (name, value, dateString) => {
		if( name === "mainDate") {
			this.setState({mainDate: dateString})
		} else {
			this.setState({compareToDate: dateString})
		}
	}

	onDatePickerClick = (name) => {
		if( name === "mainDate") {
			this.setState({mainDateRange: "custom"})
		} else {
			this.setState({compareToDateRange: "custom"})
		}
	}

	onOk = (value) => {
  	console.log('onOk: ', value);
	}

	kpiDataCompare = (cell, row) => {

	}

	kpiCompareDates = (cell, row) => {
		if (this.state.compareNow) {
			return (
				<div style={{display:"flex",alignItems:"flex-start", flexDirection:"column"}}>
					<p style={{marginBottom: "5px"}}>{this.state.mainDate[0] + " - " + this.state.mainDate[1]}</p>
					{this.state.isCompareTo ? (
						<p style={{marginBottom: "5px"}}>{this.state.compareToDate[0] + " - " + this.state.compareToDate[1]}</p>
					):(null)}
					{this.state.isCompareTo ? (
						<p style={{fontWeight:"bold", margin:"0px"}}>% Change</p>
					):(null)}
				</div>
			)
		}
	}

	compareNow = () => {
		this.setState({compareNow: true})
	}

	render () {
		return (
			<div className="mis-content-container">
				<div className='featureRestrictionHeader'>
					<p>This feature is not available in your account.</p>
				</div>
				<div className='kpi-datePicker-dropDown-container' style={{display: "flex", justifyContent: "space-between", marginBottom: "20px", marginTop: "40px"}}>
					<select style={{height: "33px", borderRadius: "4px", border: "solid 1px #ddd", width: "150px"}} name="selectState" value={this.state.selectState} onChange={this.filters}> {/*KPI Names*/}
						<option value="" selected disabled>Select KPIs</option>
						{/*<option value="project">Project</option>
						<option value="partner">Partner</option>*/}
					</select>
					<div className='datepikerDropDown' style={{display:"flex", alignItems:"center", border: "solid 1px #ddd", padding: "5px 10px", borderRadius: "4px", position: "relative", cursor: "pointer"}} onClick={this.showDatePicker}>
						<div style={{display: "flex", flexDirection:"column"}}>
							<h3 style={{fontSize: "15px", marginBottom: "5px"}}>{this.state.mainDate && this.state.mainDate.length ? (this.state.mainDate[0] + " - " + this.state.mainDate[1]) : (null)}</h3>
							{this.state.isCompareTo === true ? (
								<div style={{display:"flex"}}>
									<p style={{margin: "-2px 0 0 0", color: "#9c9c9c", fontWeight: "bold", fontSize: "12px"}}>Compare to:</p>
									<h5 style={{margin: "0px 0px 0px 8px"}}>{this.state.compareToDate.length ? (this.state.compareToDate[0] + " - " + this.state.compareToDate[1]) : (null)}</h5>
								</div>
							):(null)}
						</div>
						<div style={{alignItems: "center", display: "flex", marginLeft: "7px"}}>
							{this.state.showDatePicker ? (<i style={{fontSize:"20px", marginTop: "10px"}} className="fa fa-sort-up"></i>) : (<i style={{fontSize:"20px", marginTop: "-10px"}} className="fa fa-sort-down"></i>)}
						</div>
					</div>
					{this.state.showDatePicker ? (
						<div className='datePicker-Content'>
							<div style={{display: "flex", alignItems:"center", justifyContent:"space-between"}}>
								<label style={{marginBottom: "0px"}} htmlFor="">Date Range:</label>
								<select style={{borderRadius: "4px", border: "solid 1px #ddd", width: "70%", backgroundColor: "#fff"}} name="mainDateRange" value={this.state.mainDateRange} onChange={this.dateRange}>
									<option value="custom" selected>Custom</option>
									<option value="today">Today</option>
									<option value="yesterday">Yesterday</option>
									<option value="lastWeek">Last Week</option>
									<option value="lastMonth">Last Month</option>
									<option value="last7Days">Last 7 Days</option>
									<option value="last30Days">Last 30 Days</option>
								</select>
							</div>
							<div onClick={this.onDatePickerClick.bind(this, "mainDate")}>
								<RangePicker format={dateFormat} style={{width:"100%", margin: "10px 0"}} value={[moment(this.state.mainDate[0]), moment(this.state.mainDate[1])]} onChange={this.dataOnChange.bind(this, "mainDate")}/>
							</div>
							<div style={{display: "flex", alignItems:"center", justifyContent:"space-between"}}>
								<div style={{display:"flex", alignItems:"center"}}>
									<input type="checkbox" style={{marginTop: "0px"}} onChange={() => {this.setState({isCompareTo: !this.state.isCompareTo, compareToDateRange: "previousPeriod"})}} checked={this.state.isCompareTo === true ? true : false}/>
									<label style={{marginBottom: "0px", marginLeft:"10px"}} htmlFor="">Compare to:</label>
								</div>
								<select
									style={ this.state.isCompareTo === true && this.state.isDisabled === false ? {borderRadius: "4px", border: "solid 1px #ddd", width: "60%", backgroundColor: "#fff"} : {borderRadius: "4px", border: "solid 1px #ddd", width: "60%", cursor: "not-allowed"}}
									disabled={this.state.isCompareTo && this.state.isDisabled === false  ? false : true}
									name="compareToDateRange" value={this.state.compareToDateRange} onChange={this.dateRange}>
									<option value="custom" selected>Custom</option>
									<option value="previousPeriod">Previous Period</option>
									<option value="previousYear">Previous Year</option>
								</select>
							</div>
							{this.state.isCompareTo === true ? (
								<div onClick={this.onDatePickerClick.bind(this, "compareToDate")}>
									<RangePicker
										format={dateFormat} style={{width:"100%", margin: "10px 0"}}
										disabled={this.state.isDisabled ? true : false}
										value={[moment(this.state.compareToDate[0]), moment(this.state.compareToDate[1])]}
										onChange={this.dataOnChange.bind(this, "compareToDate")}/>
								</div>
							):(null)}
							<div style={this.state.isCompareTo === true ? {display: "flex", justifyContent:"space-between"} : {display: "flex", justifyContent:"space-between", marginTop: "10px"}}>
								<button className='date-compare-button' onClick={this.compareNow}>Compare</button>
								<button className='date-compare-cancel-button' onClick={this.resetDatePicker}>Cancel</button>
							</div>
						</div>
					):(null)}
				</div>
				<div className='trends-graph-container'>
					<LineChart width={1000} height={300} data={data} margin={{top: 5, right: 60, left: -30, bottom: 20}}>
		        <CartesianGrid strokeDasharray="3 3" />
		        <Tooltip />
		        <XAxis dataKey="name" />
		        <YAxis />
		        <Legend />
		        <Line type="monotone" dataKey={this.state.mainDate[0] + " - " + this.state.mainDate[1]} style={{fontWeight: "bold"}} stroke="#8884d8" activeDot={{ r: 8 }} />
		        <Line type="monotone" dataKey={this.state.compareToDate[0] + " - " + this.state.compareToDate[1]} stroke="#82ca9d" />
		      </LineChart>
				</div>
				<div className='trends-filters' style={{display:"flex", alignItems: "center", justifyContent: "space-between", marginBottom: "10px"}}>
					<div style={{width: this.state.screenWidth < 500 ? "100%" : "10%", display: "flex", alignItems: "center" }}>
						<h3 style={{margin:"0px"}}>Filters:</h3>
						{this.state.screenWidth < 500 ? (
							<div className='filters-drop-down' style={{width:"100%",display:"flex", alignItems:"center"}} onClick={() => {this.setState({showFilters: !this.state.showFilters})}}>
								<hr style={{height:"3px", backgroundColor:"#c1c1c1", border:"none", width:"inherit", margin: "0 10px"}}/>
								<i className= {this.state.showFilters === false ? "fa fa-chevron-down" : "fa fa-chevron-up"}></i>
							</div>
						) : (null)}
					</div>
					{(this.state.showFilters) || (this.state.screenWidth > 500) ? (
						<div className='filters-container' style={{width:"90%", display:"flex", alignItems:"center", justifyContent:"space-between", marginBottom: this.state.screenWidth < 500 ? "15px" : "0px"}}>
							<select className="filter-menu" style={{height: "33px", borderRadius: "4px", border: "solid 1px #ddd", width: "120px"}} name="selectView" value={this.state.selectView} onChange={this.filters}>
								<option value="" selected disabled>Select KPIs</option>
								<option value="project">Project</option>
							</select>
							<select className="filter-menu" style={{height: "33px", borderRadius: "4px", border: "solid 1px #ddd", width: "120px"}} name="selectView" value={this.state.selectView} onChange={this.filters}>
								<option value="" selected disabled>Select View</option>
								<option value="project">Project</option>
								<option value="partner">Partner</option>
							</select>
							<select className="filter-menu" style={{height: "33px", borderRadius: "4px", border: "solid 1px #ddd", width: "120px"}} name="selectGoals" value={this.state.selectGoals} onChange={this.filters}>
								<option value="" selected disabled>Select Goals</option>
								{/*<option value="project">Project</option>
								<option value="partner">Partner</option>*/}
							</select>
							<select className="filter-menu" style={{height: "33px", borderRadius: "4px", border: "solid 1px #ddd", width: "120px"}} name="selectState" value={this.state.selectState} onChange={this.filters}> {/*states of the all projects*/}
								<option value="" selected disabled>Select State</option>
								{/*<option value="project">Project</option>
								<option value="partner">Partner</option>*/}
							</select>
							<select className="filter-menu" style={{height: "33px", borderRadius: "4px", border: "solid 1px #ddd", width: "120px"}} name="selectCity" value={this.state.selectCity} onChange={this.filters}> {/*cities of the selected state*/}
								<option value="" selected disabled>Select City</option>
								{/*<option value="project">Project</option>
								<option value="partner">Partner</option>*/}
							</select>
							<Select className="filter-menu" style={{width: "120px"}} mode="tags" placeholder="Enter Tags" value={this.state.tags} onChange={this.handleChange}></Select>
							<button className="resetTrendsFilterButton" onClick={this.resetFilters}>Reset</button>
						</div>
					): (null)}
				</div>
				<div className='trends-table-div-scrollable'>
					<BootstrapTable data={this.state.dummyData} id="trends-table" className='trends-table' striped={false} selectRow={this.state.selectRowProp} ref='table'>
						<TableHeaderColumn dataField='partnerName' width="20%" headerAlign='center' dataAlign='center' isKey={true} dataFormat={this.kpiCompareDates}>KPIs</TableHeaderColumn>
						{this.state.dummyData.map((data,i) => {
							return (
			      		<TableHeaderColumn key={i} headerAlign='center' dataAlign='center' dataFormat={this.kpiDataCompare}>{data.partnerName}</TableHeaderColumn>
							)
						})}
					</BootstrapTable>
				</div>
			</div>
		)
	}
}

export default Trends;