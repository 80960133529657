import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import * as action from '../../actions/SignupAction';

import SignupSwitch from './SignupSwitch';

class Signup extends Component {
	render() {
		return (
			<div className="signup-container">
				<SignupSwitch
					loginType={this.props.loginType}
					loginEmail={this.props.loginEmail}
					loginPassword={this.props.loginPassword}

					changeLoginType={this.props.actions.changeLoginType}
					changeLoginEmail={this.props.actions.changeLoginEmail}
					changeLoginPassword={this.props.actions.changeLoginPassword}
					cancelCorporateSignin={this.props.actions.cancelCorporateSignin}
					cancelNgoSignin={this.props.actions.cancelNgoSignin}
					flipCorporateSignin={this.props.actions.flipCorporateSignin}
					flipNgoSignin={this.props.actions.flipNgoSignin}
				/>
			</div>
		);
	}

}

Signup.propTypes = {
	loginType: PropTypes.string,
	loginEmail: PropTypes.string,
	loginPassword: PropTypes.string,
};

function mapStateToProps(state, props) {
	return {
		loginType: state.Signup.loginType,
		loginEmail: state.Signup.loginEmail,
		loginPassword: state.Signup.loginPassword
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(action, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Signup);