import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { Radio, Checkbox } from "antd";
import { readCookie } from "../../../../../cookie";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import Icon from "react-icons-kit";
import { ic_add_location } from "react-icons-kit/md/ic_add_location";
import { ic_delete } from "react-icons-kit/md/ic_delete";
import { ToastContainer, toast } from "react-toastify";
import $ from "jquery";
import ReactGA from 'react-ga';

export default class YourInitiatives extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allSetting: null,
      allBeneficiary: null,
      preferedGeographiesSetting: null,
      preferedGeographiesLocation: [],
      beneficiary: [],
      noBeneficiaries: false,
      locationError: "",
      selectedInvestmentSetting: [],
      noSettings: false,
      engageEmployee: "",
      vicinity: null,
      geographiesRadiusError: [],
      headquarterObj: {
        location: "",
        city: "",
        state: "",
        country: ""
      }
    }
  }

  componentDidMount() {
    fetch(process.env.REACT_APP_API_URL + "/getOtAttributes", {
      method: "get",
      headers: {
        Auth: JSON.parse(readCookie("access_token")).access_token
      }
    }).then(data => data.json())
      .then(data => {
        let allSetting = [];
        let allBeneficiary = [];
        for (let i = 0; i < data.otAttributes.length; i++) {
          if (data.otAttributes[i].type === "setting") {
            data.otAttributes[i].selected = false;
            allSetting.push(data.otAttributes[i]);
          } else if (data.otAttributes[i].type === "beneficiary") {
            data.otAttributes[i].selected = false;
            allBeneficiary.push(data.otAttributes[i]);
          }
        }
        this.setState({ allSetting, allBeneficiary });
      });

    let companyId = JSON.parse(readCookie("userData")).organisationData.id;
    fetch(process.env.REACT_APP_API_URL + "/company/" + companyId + "/mis/preferences", {
      method: "GET",
      headers: {
        Auth: JSON.parse(readCookie("access_token")).access_token
      }
    }).then(data => data.json())
    .then(data => {
      let dataObj = data.data;

      let headquarterObj = this.state.headquarterObj;
      let preferedGeographiesLocation = this.state.preferedGeographiesLocation;
      let selectedInvestmentSetting = this.state.selectedInvestmentSetting;
      let beneficiary = this.state.beneficiary;
      let engageEmployee = this.state.engageEmployee;
      let vicinity = this.state.vicinity;

      let headquarterLocation = "";
      if (dataObj.city !== "") {
        headquarterLocation = dataObj.city;
      }
      if (dataObj.state !== "") {
        if (headquarterLocation === "") headquarterLocation = dataObj.state;
        else headquarterLocation += ", " + dataObj.state;
      }
      if (dataObj.country !== "") {
        if (headquarterLocation === "") headquarterLocation = dataObj.country;
        else headquarterLocation += ", " + dataObj.country;
      }

      headquarterObj["location"] = headquarterLocation;
      headquarterObj["city"] = dataObj.city;
      headquarterObj["state"] = dataObj.state;
      headquarterObj["country"] = dataObj.country;

      if (dataObj.hasOwnProperty("geographies")) {
        for (var i = 0; i < dataObj.geographies.length; i++) {
          let preferedGeographiesLocationJson = {};
          preferedGeographiesLocationJson["location"] = dataObj.geographies[i].location;
          preferedGeographiesLocationJson["radius"] = dataObj.geographies[i].radius;
          preferedGeographiesLocationJson["city"] = dataObj.geographies[i].city;
          preferedGeographiesLocationJson["state"] = dataObj.geographies[i].state;
          preferedGeographiesLocationJson["country"] = dataObj.geographies[i].country;
          preferedGeographiesLocationJson["latitude"] = dataObj.geographies[i].latitude;
          preferedGeographiesLocationJson["longitude"] = dataObj.geographies[i].longitude;
          preferedGeographiesLocation.push(preferedGeographiesLocationJson);
        }
        if (dataObj.geographies.length === 0) {
          this.addPreferedGeographiesLocation();
        }
      } else {
        this.addPreferedGeographiesLocation();
      }

      if (dataObj.hasOwnProperty("investmentSetting")) {
        if (dataObj.investmentSetting === -1) selectedInvestmentSetting = -1;
        else {
          for (var i = 0; i < dataObj.investmentSetting.length; i++) {
            selectedInvestmentSetting.push(dataObj.investmentSetting[i].id);
          }
        }
      }

      if (dataObj.hasOwnProperty("beneficiary")) {
        if (dataObj.beneficiary === -1) beneficiary = -1;
        else {
          for (var i = 0; i < dataObj.beneficiary.length; i++) {
            beneficiary.push(dataObj.beneficiary[i].id);
          }
        }
      }

      if (dataObj.employeeEngagement !== "") {
        engageEmployee = dataObj.employeeEngagement;
      }

      if (dataObj.vicinity !== "") {
        vicinity = dataObj.vicinity;
      }

      this.setState({
        vicinity,
        engageEmployee,
        beneficiary,
        headquarterObj,
        selectedInvestmentSetting
      });
    });
  }

  updateSettingDetails = e => {
    let value = e.target.value;
    let selectedInvestmentSetting = this.state.selectedInvestmentSetting;
    if (e.target.value !== -1 && selectedInvestmentSetting === -1) selectedInvestmentSetting = [];
    if (selectedInvestmentSetting.indexOf(value) === -1) {
      selectedInvestmentSetting.push(value);
    } else {
      selectedInvestmentSetting.splice(selectedInvestmentSetting.indexOf(value), 1);
    }
    this.setState({ selectedInvestmentSetting });
  };

  updateBeneficiary = e => {
    let value = e.target.value;
    let beneficiary = this.state.beneficiary;
    if (e.target.value !== -1 && beneficiary === -1) beneficiary = [];
    if (beneficiary.indexOf(value) === -1) {
      beneficiary.push(value);
    } else {
      beneficiary.splice(beneficiary.indexOf(value), 1);
    }
    this.setState({ beneficiary });
  };

  updateEmployeeEngage = e => {
    let engageEmployee = e.target.value;
    this.setState({ engageEmployee });
  };

  updateVicinity = e => {
    this.setState({ vicinity: e.target.value });
  };

  onHeadquarterLocationChange = preferenceLocation => {
    let headquarterObj = this.state.headquarterObj;
    headquarterObj.location = preferenceLocation;
    this.setState({ headquarterObj });
    geocodeByAddress(preferenceLocation)
      .then(results => {
        let headquarterLocationObj = this.getlocation(results[0]);
        headquarterObj["city"] = headquarterLocationObj.city;
        headquarterObj["state"] = headquarterLocationObj.state;
        headquarterObj["country"] = headquarterLocationObj.country;
        this.setState({ headquarterObj });
        return getLatLng(results[0]);
      }).then(function (latLng) {
        headquarterObj["longitude"] = latLng.lng;
        headquarterObj["latitude"] = latLng.lat;
        this.setState({ headquarterObj });
      }.bind(this)).catch(error => console.error("Error", error));
  };

  noSetting = e => {
    let allSetting = this.state.allSetting;
    let selectedInvestmentSetting = this.state.selectedInvestmentSetting;
    for (var i = 0; i < allSetting.length; i++) {
      allSetting[i].selected = false;
    }
    if (this.state.selectedInvestmentSetting === -1) {
      selectedInvestmentSetting = [];
      this.setState({
        selectedInvestmentSetting
      });
    } else {
      selectedInvestmentSetting = -1;
      this.setState({
        selectedInvestmentSetting
      });
    }
    this.setState({
      allSetting,
      noSettings: !this.state.noSettings
    })
  }

  noBeneficiary = e => {
    let allBeneficiary = this.state.allBeneficiary;
    let beneficiary = this.state.beneficiary;
    for (var i = 0; i < allBeneficiary.length; i++) {
      allBeneficiary[i].selected = false;
    }
    if (this.state.beneficiary === -1) {
      beneficiary = [];
      this.setState({ beneficiary });
    } else {
      beneficiary = -1;
      this.setState({ beneficiary });
    }
    this.setState({
      allBeneficiary,
      noBeneficiaries: !this.state.noBeneficiaries
    })
  }

  changeRange(index, e) {
    let preferedGeographiesLocation = this.state.preferedGeographiesLocation;
    let geographiesRadiusError = this.state.geographiesRadiusError;
    if(e.target.value === 0 || e.target.value === '' || e.target.value === '0') {
      if(geographiesRadiusError.indexOf(index) === -1) geographiesRadiusError.push(index);
    } else {
      if(geographiesRadiusError.indexOf(index) > -1) geographiesRadiusError.splice(geographiesRadiusError.indexOf(index), 1);
    }
    preferedGeographiesLocation[index].radius = e.target.value;
    this.setState({ preferedGeographiesLocation, geographiesRadiusError });
  }

  changeLocation(index, value) {
    let preferedGeographiesLocation = this.state.preferedGeographiesLocation;
    preferedGeographiesLocation[index].location = value;
    this.setState({ preferedGeographiesLocation });
  }

  changeLatLng(index, value) {
    let preferedGeographiesLocation = this.state.preferedGeographiesLocation;
    preferedGeographiesLocation[index].latitude = value.lat;
    preferedGeographiesLocation[index].longitude = value.lng;
    this.setState({ preferedGeographiesLocation });
  }

  addPreferedGeographiesLocation() {
    let preferedGeographiesLocation = this.state.preferedGeographiesLocation;
    let geographiesRadiusError = this.state.geographiesRadiusError;
    let preferedGeographiesLocationJSON = {};
    preferedGeographiesLocationJSON["location"] = "";
    preferedGeographiesLocationJSON["radius"] = 0;
    geographiesRadiusError.push(preferedGeographiesLocation.length);
    preferedGeographiesLocation.push(preferedGeographiesLocationJSON);
    this.setState({ preferedGeographiesLocation, geographiesRadiusError });
  }

  removeGeographiesLocation(index) {
    let preferedGeographiesLocation = this.state.preferedGeographiesLocation;
    preferedGeographiesLocation.splice(index, 1);
    this.setState({ preferedGeographiesLocation });
  }

  getlocation = result => {
    let city = {}, country = {}, region = {};
    let headquarterLocation = this.state.headquarterLocation;
    for (var i = 0; i < result.address_components.length; i++) {
      if (result.address_components[i].types[0] == "locality") {
        city = result.address_components[i];
      }
      if (result.address_components[i].types[0] == "administrative_area_level_1") {
        region = result.address_components[i];
      }
      if (result.address_components[i].types[0] == "country") {
        country = result.address_components[i];
      }
    }
    return {
      city: city.long_name,
      state: region.long_name,
      country: country.long_name
    };
  };

  onPreferenceLocationChange(index, preferenceLocation) {
    this.changeLocation(index, preferenceLocation);
    let preferedGeographiesLocation = this.state.preferedGeographiesLocation;
    geocodeByAddress(preferenceLocation)
    .then(results => {
      let preferenceLocationObj = this.getlocation(results[0]);
      preferedGeographiesLocation[index]["city"] = preferenceLocationObj.city;
      preferedGeographiesLocation[index]["state"] = preferenceLocationObj.state;
      preferedGeographiesLocation[index]["country"] = preferenceLocationObj.country;
      this.setState({ preferedGeographiesLocation });
      return getLatLng(results[0]);
    }).then(function (latLng) {
      preferedGeographiesLocation[index]["latitude"] = latLng.lat;
      preferedGeographiesLocation[index]["longitude"] = latLng.lng;
      this.setState({ preferedGeographiesLocation });
    }.bind(this))
    .catch(error => console.error("Error", error));
  }

  saveData = () => {
    if (((this.state.selectedInvestmentSetting.length && this.state.selectedInvestmentSetting[0] !== "") || this.state.selectedInvestmentSetting === -1) && this.state.vicinity !== "" &&
      ((this.state.beneficiary.length && this.state.beneficiary[0] !== "") || this.state.beneficiary === -1) && this.state.engageEmployee !== "" &&
      this.state.headquarterObj["location"] !== "" && this.state.headquarterObj["city"] !== "" && this.state.headquarterObj["state"] !== "" &&
      this.state.headquarterObj["country"] !== "" && this.state.headquarterObj["latitude"] !== "" && this.state.headquarterObj["longitude"] !== "" && this.state.preferedGeographiesLocation.length && !this.state.geographiesRadiusError.length) {
      let data = {};
      if (this.state.selectedInvestmentSetting.length && this.state.selectedInvestmentSetting[0] !== "") {
        data.investmentSetting = this.state.selectedInvestmentSetting;
      } else if (this.state.selectedInvestmentSetting === -1) {
        data.investmentSetting = -1
      }
      if (this.state.beneficiary.length && this.state.beneficiary[0] !== "") {
        data.beneficiary = this.state.beneficiary;
      } else if (this.state.beneficiary === -1) {
        data.beneficiary = -1
      }
      data.vicinity = this.state.vicinity;
      data.employeeEngagement = this.state.engageEmployee;
      data.headquarterLocation = this.state.headquarterObj;
      data.geographies = this.state.preferedGeographiesLocation;

      let companyId = JSON.parse(readCookie("userData")).organisationData.id;
      fetch(process.env.REACT_APP_API_URL + "/company/" + companyId + "/mis/preferences", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Auth: JSON.parse(readCookie("access_token")).access_token
        },
        body: JSON.stringify(data)
      }).then(function (response) {
        return response.json();
      }).then(function (data) {
        this.notify("Your Preferences has been saved!");
        ReactGA.event({
          category: 'Csr Strategy',
          action: 'edit',
          label: 'Saved',
          value: 2
        });
      }.bind(this));
    } else {
      this.notifyError("Mandatory values are missing!");
    }
  };

  notifyError = text => toast.error(text);

  notify = text =>
    toast.success(text, {
      onClose: this.next(this)
    });

  next = () => {
    var that = this;
    ReactGA.event({
      category: 'Csr Strategy',
      action: 'edit',
      label: 'Next',
      value: 2
    });
    setTimeout(function () {
      that.props.next();
    }, 1000);
  };

  previous = () => {
    ReactGA.event({
      category: 'Csr Strategy',
      action: 'edit',
      label: 'Prev',
      value: 2
    });
    this.props.previous();
  };

  render() {
    const options = {
      types: ["(regions)"]
    };

    let headquarterInputProps = {
      value: this.state.headquarterObj.location,
      onChange: this.onHeadquarterLocationChange.bind(this)
    };
    if(JSON.parse(readCookie('userData')).authorizedFor.mis === 'view') headquarterInputProps['disabled'] = true;

    let headquarterCssClasses = {
      input: "location form-control " + this.state.locationError,
      autocompleteContainer: "my-autocomplete-container"
    };

    if (this.state.allSetting !== null && this.state.allBeneficiary !== null && this.state.preferedGeographiesLocation !== null) {
      return (
        <div className="mis-content-container-s2">
          <div className="title-section">
            <h1>Define whom your initiative could potentially impact</h1>
          </div>
          <div className="body-section">
            <ToastContainer position="bottom-center" type="success" autoClose={4000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={false} />
            <div className="body-section1">
              <div className="section-header-container">
                <span className="dot" />
                <div className="sub-heading-1">
                  <h3>Select the preferred setting of investment <span className="astris">*</span></h3>
                </div>
              </div>
              <div className="investment-setting">
                <div className="inner-div">
                  <Checkbox onChange={this.noSetting} className="s1-goal-prefereces" type="checkbox" value={-1} checked={this.state.selectedInvestmentSetting === -1 ? true : false} disabled={JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'} />
                  <h3 className="inner-div-name">No Preference</h3>
                </div>
                {this.state.allSetting.map((setting, index) => {
                  return (
                    this.state.selectedInvestmentSetting === -1 ? (
                      <div className="inner-div">
                        <Checkbox value={setting._id} onChange={this.updateSettingDetails.bind(this)} disabled={this.state.noSettings || JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'} checked={false} />
                        <h3 className="inner-div-name">{setting.name}</h3>
                      </div>
                    ) : (
                      <div className="inner-div">
                        <Checkbox onChange={this.updateSettingDetails.bind(this)} checked={this.state.selectedInvestmentSetting.indexOf(setting._id) > -1 ? true : false} key={setting._id} value={setting._id} disabled={JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'} />
                        <h3 className="inner-div-name">{setting.name}</h3>
                      </div>
                    )
                  )
                })}
              </div>
            </div>
            <div className="body-section2">
              <div className="section-header-container">
                <span className="dot" />
                <div className="sub-heading-2">
                  <h3>Location of the Company Headquarter <span className="astris">*</span></h3>
                </div>
              </div>
              <div className="headquarter-location" />
              <div className="form-group company-headquarter">
                <label className="control-label">Location:</label>
                <PlacesAutocomplete inputProps={headquarterInputProps} classNames={headquarterCssClasses} />
              </div>
            </div>
            <div className="body-section3">
              <div className="section-header-container">
                <span className="dot" />
                <div className="sub-heading-3">
                  <h3>Select preferred vicinity of projects from the Headquarter</h3>
                </div>
              </div>
              <div className="s2-options">
                <div className="s2-options-sub">
                  <Radio onChange={this.updateVicinity.bind(this)} checked={this.state.vicinity === "anywhere" ? true : false}
                    name="vicinity" value="anywhere" style={{ margin: '0' }} disabled={JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'} />
                  <h3 className="rad-opt-vin">Anywhere</h3>
                </div>
                <div className="s2-options-sub">
                  <Radio onChange={this.updateVicinity.bind(this)} checked={this.state.vicinity === "nearby" ? true : false}
                    name="vicinity" value="nearby" style={{ margin: '0' }} disabled={JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'} />
                  <h3 className="rad-opt-vin">Nearby</h3>
                </div>
                <div className="s2-options-sub">
                  <Radio name="vicinity" onChange={this.updateVicinity.bind(this)} value="noPreferences" checked={this.state.vicinity === "noPreferences" ? true : false}
                    style={{ margin: '0' }} disabled={JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'} />
                  <h3 className="rad-opt-vin">No Preference</h3>
                </div>
              </div>
            </div>
            <div className="body-section4">
              <div className="section-header-container">
                <span className="dot" />
                <div className="sub-heading-4">
                  <h3>Select preferred geographies of investment <span className="astris">*</span></h3>
                </div>
              </div>
              <div className="section-content-container">
                {this.state.preferedGeographiesLocation.map((location, index) => {
                  let inputProps = {
                    value: location.location,
                    onChange: this.onPreferenceLocationChange.bind(this, index)
                  };
                  if(JSON.parse(readCookie('userData')).authorizedFor.mis === 'view') inputProps['disabled'] = true;
                  let cssClasses = {
                    input: "location form-control " + this.state.locationError,
                    autocompleteContainer: "my-autocomplete-container"
                  };
                  return (
                    <div key={index} className="form-group geographies-of-investment">
                      <Col md={12}>
                        <Col md={6} className="form-group">
                          <label className="control-label">Location:</label>
                          <PlacesAutocomplete inputProps={inputProps} classNames={cssClasses} />
                        </Col>
                        <Col md={5} className="form-group">
                          <label className="control-label">
                            Radius of coverage (in Km around location):
                            {this.state.geographiesRadiusError.indexOf(index) > -1 ? (
                              <span className="error">(Must be greater than 0)</span>
                            ) : (null)}
                          </label>
                          <input type="number" className="range form-control" min="0" max="10000000" value={location.radius}
                            onChange={this.changeRange.bind(this, index)} disabled={JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'} />
                        </Col>
                        {this.state.preferedGeographiesLocation.length > 1 && JSON.parse(readCookie('userData')).authorizedFor.mis !== 'view' ? (
                          <Col md={1} className="mb15">
                            <span className="remove-geography-of-investment pull-right" onClick={this.removeGeographiesLocation.bind(this, index)}>
                              <Icon icon={ic_delete} size={22} />
                            </span>
                          </Col>
                        ) : null}
                      </Col>
                    </div>
                  );
                })}
              </div>
              {JSON.parse(readCookie('userData')).authorizedFor.mis !== 'view' ? (
                <Row className="mb20 mt20 add-loc-btn">
                  <span className="ml15 btn btn-default add-location" onClick={this.addPreferedGeographiesLocation.bind(this)}>
                    <Icon icon={ic_add_location} /> Add another location
                  </span>
                </Row>
              ) : (null)}
            </div>
            <div className="body-section5">
              <div className="section-header-container">
                <span className="dot" />
                <div className="sub-heading-5">
                  <h3>Select the targeted recipients of your support <span className="astris">*</span></h3>
                </div>
              </div>
              <div className="ben-items-container">
                <div className="ben-inner-div">
                  <Checkbox onChange={this.noBeneficiary} className="s1-goal-prefereces" type="checkbox" value={-1} checked={this.state.beneficiary === -1 ? true : false} disabled={JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'} />
                  <h3 className="ben-items-title" style={{ marginLeft: '10px' }}>No Preference</h3>
                </div>
                {this.state.allBeneficiary.map((beneficiary, index) => {
                  return (
                    this.state.beneficiary === -1 ? (
                      <div className="ben-inner-div">
                        <Checkbox value={beneficiary._id} onChange={this.updateBeneficiary.bind(this)} className="ben-items" disabled={this.state.noBeneficiaries} checked={false} disabled={JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'} />
                        <h3 className="ben-items-title">{beneficiary.name}</h3>
                      </div>
                    ) : (
                      <div className="ben-inner-div">
                        <Checkbox checked={this.state.beneficiary.indexOf(beneficiary._id) > -1 ? true : false} className="ben-items" key={beneficiary._id} onChange={this.updateBeneficiary.bind(this)} value={beneficiary._id} disabled={JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'} />
                        <h3 className='ben-items-title'>{beneficiary.name}</h3>
                      </div>
                    )
                  )
                })}
              </div>
            </div>
            <div className="body-section6">
              <div className="section-header-container">
                <span className="dot" />
                <div className="sub-heading-6">
                  <h3>Do you wish to engage your employees in the projects your support through meaningful volunteering opportunities? <span className="astris">*</span></h3>
                </div>
              </div>
              <div className="sub-body-section6">
                <div className="s2-section6-opt">
                  <Radio checked={this.state.engageEmployee === "yes" ? true : false} className="s2-section6-rad" onChange={this.updateEmployeeEngage.bind(this)}
                    name="engageEmployee" value="yes" disabled={JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'} />
                  <h3 className='s2-section6-opt-title'>Yes</h3>
                </div>
                <div className="s2-section6-opt">
                  <Radio checked={this.state.engageEmployee === "no" ? true : false} className="s2-section6-rad" onChange={this.updateEmployeeEngage.bind(this)}
                    name="engageEmployee" value="no" disabled={JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'} />
                  <h3 className='s2-section6-opt-title'>No</h3>
                </div>
                <div className="s2-section6-opt">
                  <Radio checked={this.state.engageEmployee === "maybe" ? true : false} className="s2-section6-rad" onChange={this.updateEmployeeEngage.bind(this)}
                    name="engageEmployee" value="maybe" disabled={JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'} />
                  <h3 className='s2-section6-opt-title'>Maybe</h3>
                </div>
              </div>
            </div>
            <div className="buttons-section">
              <div className="prev-btn-container">
                <button className="btn-prev" onClick={this.props.previous}>
                  Previous
                </button>
                <button className="btn-nxt" onClick={this.props.next}>
                  {JSON.parse(readCookie('userData')).authorizedFor.mis !== 'view' ? ('Continue without saving') : ('Next')}
                </button>
              </div>
              {JSON.parse(readCookie('userData')).authorizedFor.mis !== 'view' ? (
                <div className="nxt-btn-container">
                  <button className="btn-save-next" onClick={this.saveData.bind(this)}>
                    Save & Next
                  </button>
                </div>
              ) : (null)}
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}
