import React, { Component } from "react";
import { Radio } from "antd";
import { ToastContainer, toast } from "react-toastify";
import ReactGA from 'react-ga';

const readCookie = require("../../../../../cookie.js").readCookie;

class Investmentpreferences extends Component {
  state = {
    projectSize: null,
    ticketSize: 0,
    projectDuration: null,
    selectedProjectsDuration: ""
  };

  componentDidMount = () => {
    fetch(process.env.REACT_APP_API_URL + "/getOtAttributes", {
      method: "get",
      headers: {
        Auth: JSON.parse(readCookie("access_token")).access_token
      }
    }).then(data => data.json())
      .then(data => {
        let projectDuration = [];
        for (let i = 0; i < data.otAttributes.length; i++) {
          if (data.otAttributes[i].type === "projectDuration") {
            projectDuration.push(data.otAttributes[i]);
          }
        }
        this.setState({ projectDuration: projectDuration });
      });

    let companyId = JSON.parse(readCookie("userData")).organisationData.id;
    fetch(process.env.REACT_APP_API_URL + "/company/" + companyId + "/mis/preferences", {
      method: "GET",
      headers: {
        Auth: JSON.parse(readCookie("access_token")).access_token
      }
    }).then(data => data.json())
      .then(data => {
        let projectSize = null;
        let ticketSize = 0;
        if (data.data.projectSpecifications === undefined || data.data.projectSpecifications.size === undefined) {
          projectSize = null;
        } else {
          projectSize = data.data.projectSpecifications.size.type;
        }
        if (data.data.projectSpecifications === undefined || data.data.projectSpecifications.size === undefined) {
          ticketSize = 0;
        } else {
          ticketSize = data.data.projectSpecifications.size.amount;
        }
        let Duration = [];
        if (data.data.projectSpecifications !== undefined && data.data.projectSpecifications.duration !== undefined) {
          Duration = data.data.projectSpecifications.duration
        }
        let selectedProjectsDuration = "" || [];
        if (Duration === -1) {
          selectedProjectsDuration = Duration;
        } else {
          for (var i = 0; i < Duration.length; i++) {
            if (Array.isArray(Duration)) {
              selectedProjectsDuration = Duration[i].id;
            }
          }
        }

        this.setState({
          projectSize: projectSize,
          ticketSize: ticketSize,
          selectedProjectsDuration: selectedProjectsDuration
        });
      });
  }

  projectSize = e => {
    this.setState({
      projectSize: e.target.value
    });
  }

  ticketSize = e => {
    this.setState({
      ticketSize: e.target.value
    });
  }

  projectDuration = e => {
    let selectedProjectsDuration = this.state.selectedProjectsDuration;
    this.setState({
      selectedProjectsDuration: e.target.value
    });
  }

  saveData = () => {
    if (this.state.projectSize !== null && this.state.ticketSize !== "" && this.state.projectDuration !== null) {
      let data = {};
      let projectSpecifications = {};
      projectSpecifications.duration = this.state.selectedProjectsDuration;
      let size = {};
      size.type = this.state.projectSize;
      size.amount = this.state.ticketSize;

      projectSpecifications.size = size;

      data.projectSpecifications = projectSpecifications;
      let companyId = JSON.parse(readCookie("userData")).organisationData.id;
      fetch(process.env.REACT_APP_API_URL + "/company/" + companyId + "/mis/preferences", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Auth: JSON.parse(readCookie("access_token")).access_token
        },
        body: JSON.stringify(data)
      }).then(function (response) {
        return response.json();
      }).then(function (data) {
        this.notify("Your Preferences has been saved!");
        ReactGA.event({
          category: 'Csr Strategy',
          action: 'edit',
          label: 'Saved',
          value: 3
        });
      }.bind(this));
    } else {
      this.notifyError("Mandatory values are missing!");
    }
  }

  notifyError = text => toast.error(text);

  notify = text => toast.success(text, { onClose: this.next(this) });

  next = () => {
    var that = this;
    ReactGA.event({
      category: 'Csr Strategy',
      action: 'edit',
      label: 'Next',
      value: 3
    });
    setTimeout(function () {
      that.props.next();
    }, 1000);
  };

  previous = () => {
    ReactGA.event({
      category: 'Csr Strategy',
      action: 'edit',
      label: 'Prev',
      value: 3
    });
    this.props.previous();
  };

  render() {
    if (this.state.projectDuration !== null) {
      return (
        <div className="mis-content-container-s3">
          <div className="s3-header-container">
            <h1 className="s3-header">Define project and investment preference</h1>
          </div>
          <div className="s3-info-container">
            <ToastContainer position="bottom-center" type="success" autoClose={4000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={false} />
            <div className="s3-info-1">
              <div className="s3-info-1-header">
                <div className="s3-info-1-point" />
                <h4 className="s3-info-1-heading">{"Select preferred portfolio of projects "}<span className="astris">*</span></h4>
              </div>
              <div className="s3-info-1-container">
                <div className="projectSize-container">
                  <Radio name="projects-size" onChange={this.projectSize} value="Multiple smaller projects"
                    checked={this.state.projectSize === "Multiple smaller projects" ? true : false} disabled={JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'}>
                    <h3 className="projectSize-option1">Multiple smaller projects</h3>
                  </Radio>
                  <Radio name="projects-size" onChange={this.projectSize} value="Few large projects"
                    checked={this.state.projectSize === "Few large projects" ? true : false} disabled={JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'}>
                    <h3 className="projectSize-option2">Few large projects</h3>
                  </Radio>
                  <Radio name="projects-size" onChange={this.projectSize} value="noPreferences"
                    checked={this.state.projectSize === "noPreferences" ? true : false} disabled={JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'}>
                    <h3 className="projectSize-option2">No Preference</h3>
                  </Radio>
                </div>
              </div>
            </div>
            <div className="s3-info-2">
              <div className="s3-info-2-header">
                <div className="s3-info-2-point" />
                <h4 className="s3-info-2-heading">{"Select preferred average investment in each project "}<span className="astris">*</span></h4>
              </div>
              <div className="s3-info-2-container">
                <div className="projectSize-container">
                  {'INR '}
                  <input onChange={this.ticketSize} className="numberInput ml5" type="number" name="quantity" min="1" max="10000000000000" value={this.state.ticketSize} placeholder="0" disabled={JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'} />
                </div>
              </div>
            </div>
            <div className="s3-info-3">
              <div className="s3-info-3-header">
                <div className="s3-info-3-point" />
                <h4 className="s3-info-3-heading">{"Select preferred project duration "}<span className="astris">*</span></h4>
              </div>
              <div className="s3-info-3-container">
                <div className="ticketSize-container">
                  {this.state.projectDuration.map(project => {
                    return (
                      <Radio value={project._id} name="project-duration" onChange={this.projectDuration} checked={this.state.selectedProjectsDuration === project._id ? true : false} disabled={JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'}>
                        <h3 className="ticketSize-option1">{project.name}</h3>
                      </Radio>
                    );
                  })}
                  <Radio name="project-duration" onChange={this.projectDuration} value={-1} checked={this.state.selectedProjectsDuration === -1 ? true : false} disabled={JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'}>
                    <h3 className="ticketSize-option1">No Preferences</h3>
                  </Radio>
                </div>
              </div>
            </div>
            <div className="s3-buttons">
              <div className="prev-btn-container">
                <button onClick={this.previous} className="s3-previous">
                  Previous
                </button>
                <button onClick={this.next} className="s3-next">
                  {JSON.parse(readCookie('userData')).authorizedFor.mis !== 'view' ? ('Continue without saving') : ('Next')}
                </button>
              </div>
              {JSON.parse(readCookie('userData')).authorizedFor.mis !== 'view' ? (
                <div>
                  <button onClick={this.saveData} className="s3-save_next">
                    Save & Next
                  </button>
                </div>
              ) : (null)}
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default Investmentpreferences;
