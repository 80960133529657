import React, { Component } from 'react';
import { Button, Row, Col, Tab, Tabs, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import swal from 'sweetalert2';
import Swal from 'sweetalert2';
import $ from 'jquery';
import _ from 'lodash';
import moment from 'moment';
import { Select } from 'antd';
import { Menu } from 'antd';
import Scroll from 'react-scroll';
import Icon from 'react-icons-kit';
import { ToastContainer, toast } from "react-toastify";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, BarChart, Bar, ReferenceLine } from "recharts";
import { BootstrapTable, TableHeaderColumn, ButtonGroup, ExportCSVButton } from 'react-bootstrap-table';
import io from "socket.io-client";
import ReactGA from 'react-ga';
import { Checkbox } from "antd";
import createClass from 'create-react-class';
import * as action from '../../../../actions/DiscoverCSRProjectsAction';

import Loader from '../../Loader';
import SingleCSRProjectMap from "../../../CSR/6-DiscoverCSRProjects/SingleCSRProject/SingleCSRProjectMap";
import ShowInterestModal from '../../../CSR/6-DiscoverCSRProjects/SingleCSRProject/ShowInterestModal';
import ReleaseAmountTable from './ReleaseAmountTable';
import NotificationModal from './NotificationModal';
import ReportExpenseModal from './ReportExpenseModal';
import ImageModal from './ImageModal';
import GraphModal from './GraphModal';

import { ic_add_box } from 'react-icons-kit/md/ic_add_box';
import { ic_edit } from 'react-icons-kit/md/ic_edit';
import { ic_delete } from 'react-icons-kit/md/ic_delete';

const checkValue = require('../../../../checkValue.js').checkValue;
const readCookie = require('../../../../cookie.js').readCookie;
const Option = Select.Option;

let Link = Scroll.Link;
let plottedGraph = null;


const CustomTooltip  = createClass({
  render() {
    const { active } = this.props;

    if (active) {
      const { payload, label } = this.props;
      return (
        <div className="kpi-graph-container-tooltip">
        	<p className="date">{`Date : `}<span>{`${payload[0].payload.date}`}</span></p>
        	{payload[0].payload.unit && payload[0].payload.unit !== '' && payload[0].payload.unit !== 'None' ? (
          	<p className="label">{`${payload[0].payload.name.charAt(0).toUpperCase() + payload[0].payload.name.slice(1)} : `}<span>{`${payload[0].payload.value} ${payload[0].payload.unit}`}</span></p>
          ) : (
          	<p className="label">{`${payload[0].payload.name.charAt(0).toUpperCase() + payload[0].payload.name.slice(1)} : `}<span>{`${payload[0].payload.value}`}</span></p>
          )}
        </div>
      );
    } return null;
  }
});

let year = moment().year();
const years = [];
for (var i = -3; i < 10; i++) {
	var yearStr = (year - i) + '-' + (year - i + 1);
	years.push(yearStr);
}

const projectSettingsDisbursementPlanHandler = (e) => {
	this.setState({projectSettingsDisbursementPlan: e.target.value });
}

class SingleMISProject extends Component {
	constructor(props) {
		super(props);
		this.state = {
			graphPlot: null,
			fiscalYear:"",
			showGraph: false,
			openGraphModal: false,
			projectUpdates: [],
			projectUpdatesPerPage: 5,
			projectUpdatesPage: 0,
			totalProjectUpdates: 0,
			milestones: [],
			keyIndicator: false,
			dateArray: null,
			kpiData: null,
			beneficiaries: null,
			sortByDate: null,
			keyIndicator: null,
			readMore: false,
			readMoreSocialImpact: false,
			readMoreEconomicImpact: false,
			canShowInterest: true,
			amountBreakup: null,
			ShowInterestModal: false,
			ShownotificationModal: false,
			notificationContent: {},
			showInterestMessage: '',
			chatContainerOpen: false,
			chats: null,
			chatReason: null,
			projectStatus: 'noAction',
			ngoId: null,
			companyId: this.props.userData.organisationData.id,
			projectId: null,
			discussion: '',
			conversationId: null,
			corporateInterestStatus: 'noAction',
			corporateInvestment: {},
			confirmInvestment: false,
			current: 'overview',
			releasedAmountBreakup: [],
			reportExpenseModalVisibility: false,
			reportType: '',
			expenseData: null,
			editExpense: false,
			imgArray: [],
			openModal: false,
			showLoader: false,
			loaderHiding: '',
			showProjectBudgetDetails: false,
			showForcefulPaymentModal: false,
			showProjectTranches: false,
			tranches: null,
			projectUpdatesLoading: false,
			tags:[],
			customCategory: '',
			customCategories: [],
			projectInterestId:"",
			csrBudgets: [],
			csrBudget: null,
			selectedCSRBudgetIndex: null,
			QueryYear: null,
			showChangeInvestmentYearModal:false,
			showSettingsModal: false,
			DisallowUnbudgetedReporting: false,
			ChangeDisbursementPlan: false,
			DisallowDeletionOfKpi: false,
			projectSettingsDisbursementPlan: "",
			investmentYearSelected:'',
			reasonForChangingYear:"",
			optionId:[0,1,2,3,4,5,6],
			options:years.slice(0,7),
			selectedId:"",
			showEditsModal:false,
			yearEdits:[],
			hasEdits:false
		}
		this.chatReasonRef = null;

		this.setChatReasonRef = element => {
			this.chatReasonRef = element;
		};

		this.focusChatReason = () => {
			if (this.chatReasonRef) this.chatReasonRef.focus();
		};

		this.handleClick = this.handleClick.bind(this);
	}

	getFiscalYear=()=>{
	if (!this.props.selectedProject.hasOwnProperty('customUrl')) {
		let path = window.location.pathname;
		path = path.split('/').pop();

		fetch(process.env.REACT_APP_API_URL + '/get-project/' + path, {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
		.then(function (data) {
			let projectId = data.data.id;
			if (data.status === 'ok') {
				fetch(process.env.REACT_APP_API_URL+'/get-project/'+projectId,{
					method: "GET",
					headers: {
						'Auth' :JSON.parse(readCookie('access_token')).access_token
					}
				}).then((data)=> data.json())
				.then((data)=>{
					if(data.status==='ok'){
						this.setState({fiscalYear:data.data.fiscalYear});
						this.setState({selectedId: this.state.options[2023-data.data.fiscalYear]})
					}
				})
			}
				fetch(process.env.REACT_APP_API_URL+'/investmentYearChange/get/'+projectId,{
					method: "GET",
					headers: {
						'Auth': JSON.parse(readCookie('access_token')).access_token
					}
					}).then((data) => data.json())
					.then(function(data){
						if(data.status=='ok'){
							this.setState({yearEdits:data.data});
							this.setState({hasEdits:true});
						}
					}.bind(this));
			}.bind(this));
		}
	}
	showEditModal = () =>{
		this.setState({showEditsModal:true})
	}
	hideEditModal= () =>{
		this.setState({showEditsModal:false})
	}

	componentWillMount() {
		this.initSocket();

		let companyId = JSON.parse(readCookie('userData')).organisationData.id;
		fetch(process.env.REACT_APP_API_URL + '/company/' + companyId + '/mis/preferences', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
		.then((data) => {
			let csrBudgets = [];
			let selectedCSRBudgetIndex = 0;
			let preferences = data.data;

			if (preferences.hasOwnProperty('csrBudget')) {
				for (var i = 0; i < preferences.csrBudget.length; i++) {
					let currentYearBudget = {};
					if (parseInt(preferences.csrBudget[i].year) === parseInt(this.getCurrentFiscalYear().split('-')[0])) selectedCSRBudgetIndex = i;
					currentYearBudget['year'] = preferences.csrBudget[i].year + '-' + (preferences.csrBudget[i].year + 1);
					currentYearBudget['amount'] = preferences.csrBudget[i].amount;
					currentYearBudget['communityInvestmentPercentage'] = preferences.csrBudget[i].communityInvestment;
					currentYearBudget['volunteerEngagementPercentage'] = preferences.csrBudget[i].volunteerEngagement;
					currentYearBudget['trainingAndDevelopmentPercentage'] = preferences.csrBudget[i].trainingAndDevelopment;
					currentYearBudget['othersPercentage'] = preferences.csrBudget[i].others;
					csrBudgets.push(currentYearBudget);
				}
				if (preferences.csrBudget.length === 0) {
					let currentYearBudget = {};
					currentYearBudget['year'] = this.getCurrentFiscalYear();
					currentYearBudget['amount'] = 0;
					currentYearBudget['communityInvestmentPercentage'] = 0;
					currentYearBudget['volunteerEngagementPercentage'] = 0;
					currentYearBudget['trainingAndDevelopmentPercentage'] = 0;
					currentYearBudget['othersPercentage'] = 100;
					csrBudgets.push(currentYearBudget);
				}
			} else {
				let currentYearBudget = {};
				currentYearBudget['year'] = this.getCurrentFiscalYear();
				currentYearBudget['amount'] = 0;
				currentYearBudget['communityInvestmentPercentage'] = 0;
				currentYearBudget['volunteerEngagementPercentage'] = 0;
				currentYearBudget['trainingAndDevelopmentPercentage'] = 0;
				currentYearBudget['othersPercentage'] = 100;
				csrBudgets.push(currentYearBudget);
			}
			let QueryYear = sessionStorage.getItem("selectedFiscalYear")
			sessionStorage.removeItem("selectedFiscalYear");
			if (QueryYear === null) {
				this.setState({ QueryYear: this.getCurrentFiscalYear() });
			} else {
				this.setState({ QueryYear	});
				let found = false;
				let foundIndex = -1;
				for (var i = 0; i < csrBudgets.length; i++) {
					if(csrBudgets[i].year === QueryYear) {
						found = true;
						foundIndex = i;
						break;
					}
				}
				if(!found) {
					let csrBudget = {};
					csrBudget['year'] = QueryYear;
					csrBudget['amount'] = 0;
					csrBudget['communityInvestmentPercentage'] = 0;
					csrBudget['volunteerEngagementPercentage'] = 0;
					csrBudget['trainingAndDevelopmentPercentage'] = 0;
					csrBudget['othersPercentage'] = 100;
					csrBudgets.push(csrBudget);
					foundIndex = csrBudgets.length - 1;
					selectedCSRBudgetIndex = csrBudgets.length - 1
				}
			}
			let customCategories=[];
			if(preferences.hasOwnProperty('customCategory')){
				customCategories = preferences.customCategory;
			}

			this.setState({ csrBudgets, csrBudget: csrBudgets[selectedCSRBudgetIndex], selectedCSRBudgetIndex, customCategories });
		});
	}

	getCurrentFiscalYear() {
    var today = new Date();
    var curMonth = today.getMonth();
    var fiscalYr = "";
    if (curMonth > 3) {
      return today.getFullYear().toString() + '-' + (today.getFullYear() + 1).toString();
    } else {
      return (today.getFullYear() - 1).toString() + '-' + today.getFullYear().toString();
    }
  }

	initSocket = () => {
		const socket = io(process.env.REACT_APP_API_URL)
		socket.on('connect', () => {
			socket.emit('authentication', { token: JSON.parse(readCookie('access_token')).access_token });
      socket.on('authenticated', function () {
				this.setState({ socket });

				let csr_namespace = "CSR_" + this.state.companyId;

				socket.on(csr_namespace, (data) => {
					if (data.length === undefined) {
						let new_chat = this.state.chats;
						new_chat.push(data);
						let uniq = _.uniq(new_chat, '_id');
						this.setState({ chats: uniq, discussion: '' });
					} else {
						let new_chat = [];
						let uniq_msg = [];
						new_chat.push(this.state.chats[0]);
						uniq_msg = new_chat.concat(data);
						this.setState({ chats: uniq_msg });
					}
				});
			}.bind(this));
		});
	}

	componentDidMount() {
		this.focusChatReason();
		this.getFiscalYear();

		if (!this.props.selectedProject.hasOwnProperty('customUrl')) {
			let path = window.location.pathname;
			path = path.split('/').pop();
			fetch(process.env.REACT_APP_API_URL + '/get-project/' + path, {
				method: "GET",
				headers: {
					'Auth': JSON.parse(readCookie('access_token')).access_token
				}
			}).then((data) => data.json())
			.then(function (data) {
				if (data.status === 'ok') {
					let projectId = data.data.id;
					if(data.data.projectInterestId){
						this.setState({projectInterestId: data.data.projectInterestId})
					}

					if(data.data.corporateInterest.hasOwnProperty('disbursementType')) {
						if(data.data.corporateInterest.disbursementType === 'Tranches') {
							let tranches = data.data.corporateInterest.tranches;
							for(var i = 0; i < tranches.length; i++) {
								tranches[i].slno = i + 1;
							}
							this.setState({ tranches });
						}
					}
					if (data.data.hasOwnProperty('corporateInvestment')) {
						let corporateInvestment = {};
						if (typeof data.data.corporateInvestment.companyId !== 'undefined' && typeof data.data.corporateInvestment.investmentId !== 'undefined' && typeof data.data.corporateInvestment.portfolioId !== 'undefined') {
							corporateInvestment['companyId'] = data.data.corporateInvestment.companyId;
							corporateInvestment['investmentId'] = data.data.corporateInvestment.investmentId;
							corporateInvestment['portfolioId'] = data.data.corporateInvestment.portfolioId;

							let showForcefulPaymentModal = false;
							if(data.data.corporateInvestment.companyId === JSON.parse(readCookie('userData'))._organisationId && data.data.status === 'Funded' && data.data.releasedAmount === 0) showForcefulPaymentModal = true;
							this.setState({ confirmInvestment: true, showForcefulPaymentModal });
						}
						this.setState({ corporateInvestment });
					}
					if(data.data.hasOwnProperty('customProjectCategoryId')){
						this.setState({customCategory: data.data.customProjectCategoryId});
					}
				}

				this.setState({ ngoId: data.data.ngoData.id, projectId: data.data.id, tags: data.data.tags });

				fetch(process.env.REACT_APP_API_URL + '/project/allUpdates/' + this.state.projectId + '?perPage=' + this.state.projectUpdatesPerPage + '&page=' + this.state.projectUpdatesPage, {
					method: 'GET',
					headers: {
						'Auth': JSON.parse(readCookie('access_token')).access_token
					}
				}).then(res => (res.json()))
				.then(res => {
					if(res.status === 'ok') {
						let projectUpdates = res.posts;
						this.setState({ projectUpdates, totalProjectUpdates: res.totalPosts });
					} else {
						// Not authorized to view updateds
					}
				}).catch(function(err) {
					// Not authorized to view updateds
				});

				fetch(process.env.REACT_APP_API_URL + '/project/' + this.state.projectId + '/milestone', {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						'Auth': JSON.parse(readCookie('access_token')).access_token
					}
				}).then(res => (res.json()))
				.then(data => {
					this.setState({ milestones: data.data });
				}).catch(er => {
				});

				fetch(process.env.REACT_APP_API_URL + '/project/' + this.state.projectId + '/beneficiaries', {
					method: 'GET',
					headers: {
						'Auth': JSON.parse(readCookie('access_token')).access_token
					}
				}).then(res => (res.json()))
				.then(data => {
					let sortByDate = [];
					if(data.status === 'ok') {
						for (let i = 0; i < data.beneficiaries.length; i++) {
							for (let j = 0; j < data.beneficiaries[i].reported.length; j++) {
									sortByDate.push(data.beneficiaries[i].reported[j])
							}
						}
						sortByDate = _.sortBy(sortByDate, function (node) {
							return (new Date(node.internalDate).getTime());
						})
						this.setState({ beneficiaries: data.beneficiaries, sortByDate });
					}
				}).catch(er => {
				});

				fetch(process.env.REACT_APP_API_URL + '/project/key-assesment/' + this.state.projectId, {
					method: 'GET',
					headers: {
						'Auth': JSON.parse(readCookie('access_token')).access_token
					}
				}).then(res => (res.json()))
				.then(data => {
					if(data.status === 'ok') {
						let assessmentdata = []
            for (let i = 0; i < data.data.length; i++) {
              for (let j = 0; j < data.data[i].assessments.length; j++) {
                if (!assessmentdata.push[data.data[i].assessments[j].date]) {
                  assessmentdata.push(data.data[i].assessments[j])
                  assessmentdata.push[data.data[i].assessments[j].date] = true
                }
              }
            }
            assessmentdata = _.sortBy(assessmentdata, function (node) {
              return (new Date(node.internalDate).getTime());
            });
						this.setState({ kpiData: data.data, assessmentdata }, function() {
							// $('.export-kpi-graph-btn').each(function() {
							// 	$(this).hide();
							// 	$(this).siblings('.kpi-graph-type-selector').hide();
							// 	var $this = $(this);
							// 	console.log($(this).parent())
							// 	html2canvas($(this).parent()).then(canvas => {
							//     let imgData = canvas.toDataURL('image/png');
							// 		console.log(imgData, $($this))
							//     $($this).attr('href', imgData);
							//     $($this).show();
							// 		$($this).siblings('.kpi-graph-type-selector').show();
							// 	});
							// });
						});
					}
				}).catch(er => {
				});

				this.props.actions.updateSelectedProject(data.data);
				let companyId = JSON.parse(readCookie('userData')).organisationData.id;
				let projectId = data.data.id;

				let corporateInterestStatus = data.data.corporateInterestStatus;
				if (corporateInterestStatus !== false) {
					this.setState({ corporateInterestStatus });
				} else {
					this.setState({ corporateInterestStatus: 'noAction' });
				}

				let releasedAmountBreakup = [];
				if (data.data.hasOwnProperty('releasedAmountBreakup') && data.data.releasedAmountBreakup.length > 0) {
					releasedAmountBreakup = data.data.releasedAmountBreakup;
				}
				this.setState({ releasedAmountBreakup }, function() {
					$('.to-be-collapsed').hide();
				});

				fetch(process.env.REACT_APP_API_URL + '/project/' + projectId + '/reported-expenses', {
					method: "GET",
					headers: {
						'Auth': JSON.parse(readCookie('access_token')).access_token
					}
				}).then((data) => data.json())
				.then(function (data) {
					if (data.status === 'ok') {
						let amountBreakup = data.expenseItems;
						this.setState({ amountBreakup }, function() {
							$('.to-be-collapsed').hide();
						});
					}
				}.bind(this));

				fetch(process.env.REACT_APP_API_URL + '/project/interests/company/' + companyId, {
					method: "GET",
					headers: {
						'Auth': JSON.parse(readCookie('access_token')).access_token
					}
				}).then((data) => data.json())
				.then(function (data) {
					if (data.status === 'ok') {
						let canShowInterest = true;
						for (var i = 0; i < data.data.length; i++) {
							if (data.data[i].project.id === projectId) {
								canShowInterest = false;
								this.setState({ canShowInterest: true });
							}
						}
						if (canShowInterest) {
							this.setState({ canShowInterest: true });
						}
					}
				}.bind(this));

				if(window.location.hash && window.location.hash !== "") {
					var that = this;
					$(document).ready(function() {
						setTimeout(function() {
							that.handleClick(window.location.hash.split('#')[1]);
						}, 500);
					});
				}
			}.bind(this));
		} else {
			window.location = "/404";
		}
	}

	updateReleaseAmountBreakup() {
		let path = window.location.pathname;
		path = path.split('/').pop();
		fetch(process.env.REACT_APP_API_URL + '/get-project/' + path, {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
		.then(function (data) {
			if (data.status === 'ok') {
				let releasedAmountBreakup = [];
				if (data.data.hasOwnProperty('releasedAmountBreakup') && data.data.releasedAmountBreakup.length > 0) {
					releasedAmountBreakup = data.data.releasedAmountBreakup;
				}
				this.setState({ releasedAmountBreakup });
			}
		}.bind(this));
	}

	showInterest(value) {
		if (this.state.showInterestMessage !== '') {
			let data = {};
			data['companyId'] = JSON.parse(readCookie('userData')).organisationData.id;
			data['message'] = this.state.showInterestMessage;
			data["ngoId"] = this.state.ngoId;
			fetch(process.env.REACT_APP_API_URL + '/project/' + this.props.selectedProject.id + '/interests', {
				method: "POST",
				headers: {
					'Content-Type': 'application/json',
					'Auth': JSON.parse(readCookie('access_token')).access_token
				},
				body: JSON.stringify(data)
			}).then(function (response) {
				return response.json();
			}).then(function (data) {
				if (data.status === 'ok') {
					swal(
						'Awesome!',
						'Your interest in this project has been taken into account.',
						'success'
					)
					this.setState({ InvestedModal: false, showInterestMessage: '', canShowInterest: true });
				}
			}.bind(this));
		}
		this.projectStatusChange(value);
	}

	onShowInterestClose(data) {
		this.setState({ ShowInterestModal: false, showInterestMessage: '' });
	}

	onShowInterestOpen(data) {
		this.setState({ ShowInterestModal: true, showInterestMessage: '' });
	}

	onShownotification(data) {
		this.setState({ ShownotificationModal: true });
	}

	onClosenotification(data) {
		this.setState({ ShownotificationModal: false });
	}

	updateMessage(e) {
		this.setState({ showInterestMessage: e.target.value });
	}

	clearSelectedProject() {
		let projectJSON = {};
		this.props.actions.updateSelectedProject(projectJSON);
	}

	toggleChatContainerOpen() {
		this.setState({ chatContainerOpen: !this.state.chatContainerOpen });
		let uri = process.env.REACT_APP_API_URL + "/chat/csr-ngo/" + this.state.companyId;
		let chat_info = {
			projectId: this.state.projectId
		}
		fetch(uri, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				// 'Auth': JSON.parse(readCookie('mis_data')).access_token
			},
			body: JSON.stringify(chat_info)
		}).then(res => (res.json()))
		.then(res => {
			//let arr = recent_chat.concat(res.data);
			this.setState({ chats: res.data, conversationId: res.data[0]._conversationId });
		}).catch(err => {
			console.log("err", err);
		});
	}

	startChat() {
		if (this.state.chatReason !== null && this.state.chatReason !== '') {
			let data = {};
			data['companyId'] = JSON.parse(readCookie('userData')).organisationData.id;
			data['message'] = this.state.chatReason;
			data["ngoId"] = this.state.ngoId;
			fetch(process.env.REACT_APP_API_URL + '/project/' + this.props.selectedProject.id + '/interests', {
				method: "POST",
				headers: {
					'Content-Type': 'application/json',
					'Auth': JSON.parse(readCookie('access_token')).access_token
				},
				body: JSON.stringify(data)
			}).then(function (response) {
				return response.json();
			}).then(function (data) {
				if (data.status === 'ok') {
					swal(
						'Awesome!',
						'Your interest in this project has been taken into account.',
						'success'
					)
					this.setState({ chats: [] });
				}
			}.bind(this));
		}
		else {
			this.focusChatReason();
		}
	}

	changeChatReason(e) {
		this.setState({ chatReason: e.target.value });
	}

	projectStatusChange(value) {
		var reactGAObj = {
		  category: 'Project',
		  action: 'click',
		  label: value
		};
		if (value === 'Invested') {
			reactGAObj['value'] = 1;
		}
		ReactGA.event(reactGAObj);

		let data = {};
		let notificationContent = {};
		if (value === 'Interested') {
			notificationContent.text = 'This project shall move to “Interested” state. You can start a chat with the NGO for further discussions now.';
			notificationContent.status = 'interested';
			this.setState({ notificationContent }, () => {
				this.onShownotification();
			});
		} else if (value === 'Under Review') {
			notificationContent.text = 'This project shall move to “Under review” state. You could initiate the due diligence process and sign the MoU in this stage.';
			notificationContent.status = 'under-review';
			this.setState({ notificationContent }, () => {
				this.onShownotification();
			});
		} else if (value === 'Skipped') {
			notificationContent.text = 'This action would move the project out of your projects funnel. You can add this project back later by showing interest, if needed.';
			notificationContent.status = 'skipped';
			this.setState({ notificationContent }, () => {
				this.onShownotification();
			});
		} else if (value === 'Invested') {
			this.onShowInterestOpen();
		}
	}

	handleConfirm(value, status) {
		if (value === 'Confirm') {
			let data = {
				status: status,
				user_id: this.props.userData.id,
				fullName: this.props.userData.fullName
			}
			fetch(process.env.REACT_APP_API_URL + '/project/' + this.state.projectId + '/interests', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Auth': JSON.parse(readCookie('access_token')).access_token
				},
				body: JSON.stringify(data)
			}).then(data => data.json())
			.then(data => {
				if (data.status === 'ok') {
					let corporateInvestment = {};
					let corporateInterestStatus = data.data.status
					corporateInvestment['portfolioId'] = data.data.portfolioId;
					corporateInvestment['investmentId'] = data.data.investmentId;
					this.setState({ corporateInterestStatus, confirmInvestment: true, corporateInvestment });
				} else {
					this.setState({ confirmInvestment: false });
				}
			});
		} else {
			this.setState({ confirmInvestment: false });
		}
		this.onClosenotification();
	}

	sendMessage(event) {
		if (event.key === 'Enter') {
			const { socket } = this.state;
			let data = {
				"case": "saveLastChat",
				"_ngoId": this.state.ngoId,
				"_conversationId": this.state.conversationId,
				"_companyId": this.state.companyId,
				"discussion": this.state.discussion,
				"sender": "CSR"
			}
			socket.emit("CHAT_HANDLERS", data);
			this.setState({ discussion: '' })
		}
	}

	getSocketDone(event) {
		const { socket } = this.state;
		if (this.state.companyId === null || this.state.ngoId === null || this.state.conversationId === null) {
			return;
		}
		let data = {
			"case": "getLastChat",
			"_ngoId": this.state.ngoId,
			"_conversationId": this.state.conversationId,
			"_companyId": this.state.companyId,
			"discussion": this.state.discussion,
			"sender": "CSR"
		}
		socket.emit("CHAT_HANDLERS", data);
	}

	handleAgree(value) {
		if (value === 'Confirm') {
			let data = {
				status: 'invested',
				disbursementType: 'One Time'
			}
			fetch(process.env.REACT_APP_API_URL + '/project/' + this.state.projectId + '/interests', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Auth': JSON.parse(readCookie('access_token')).access_token
				},
				body: JSON.stringify(data)
			}).then(data => data.json())
			.then(data => {
				if (data.status === 'ok') {
					let corporateInvestment = {};
					let corporateInterestStatus = data.data.status
					corporateInvestment['portfolioId'] = data.data.portfolioId;
					corporateInvestment['investmentId'] = data.data.investmentId;
					this.setState({ corporateInterestStatus, confirmInvestment: true, corporateInvestment });
				} else {
					this.setState({ confirmInvestment: false });
				}
			})
		} else {
			this.setState({ confirmInvestment: false });
		}
		this.onShowInterestClose();
	}

	showAssessmentData(date, assessments) {
		for (var i = 0; i < assessments.length; i++) {
      if (assessments[i].hasOwnProperty('date') && assessments[i].date === date) return assessments[i].value;
    }
    return "-";
	}

	showGraphTable(row) {
		this.setState({ openGraphModal: true })
		let graph_data = _.filter(this.state.keyIndicator, (el, i) => (row === el.value[0].kpi));
		if (graph_data.length > 0) {
			let data = {
				"graph_data": graph_data
			}
			plottedGraph = data;
		} else {
			return 0;
		}
	}

	addPlotGraphButton = (row) => {
		return (
			<button className="btn btn-info" onClick={this.showGraphTable.bind(this, row)}>Plot Graph</button>
		)
	}

	handlePathChange = () => {
		if (this.state.corporateInvestment.portfolioId !== undefined && this.state.corporateInvestment.investmentId !== undefined) {
			ReactGA.event({
			  category: 'Project',
			  action: 'click',
			  label: 'Invest Funds'
			});
			window.location.pathname = "/edit-investment/" + this.state.corporateInvestment.portfolioId + "/" + this.state.corporateInvestment.investmentId + "/invest";
		} else {
			this.notifyError("Please try again!");
		}
	}

	exportPdf() {
		ReactGA.event({
		  category: 'View Project',
		  action: 'view',
		  label: 'Export Project'
		});
		$('<div class="modal-backdrop"></div>').appendTo(document.body);
		fetch(process.env.REACT_APP_API_URL + '/project/' + this.state.projectId + '/export-project', {
			method: 'GET',
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then(data => data.json())
		.then(data => {
			// console.log("data",data)
			$(".modal-backdrop").remove();
			if (data.status === 'ok') {
				swal({
    			html: '<img src="/img/report_image.png" style="height: 120px; margin: 20px 0;" />' +
						'<div style="font-size: 26px; letter-spacing: 0.5px; margin: 0 0 20px;">The exported project is ready to be downloaded!</div>' +
						'<a class="btn" style="background: #3085d6; color: #fff; font-size: 22px !important; letter-spacing: 0.5px; padding: 10px 20px; width: 240px; margin: 0 0 10px;" href="' + data.pdfUrl + '" target="_blank">Download</a>',
    			showCancelButton: false,
    			showConfirmButton: false
    		});
			}
		})
		.catch((err)=>{
			console.log("err",err)
		})
	}

	notifyError = text => toast.error(text);
	notifySuccess = text => toast.success(text);

	notify = text => toast.success(text);

	costHeadFormatter(cell, row) {
		return cell.name;
	}

	typeFormatter(cell, row) {
		if (cell) return cell.type;
		else return null;
	}

	budgetedFormatter(cell, row) {
		if (cell) return 'Yes';
		else if (row.isTotal) return '';
		else return 'No';
	}

	proposedAmountFormatter(cell, row) {
		if (cell) return window.inrFormat(cell);
		else return null;
	}

	reportedAmountFormatter(cell, row) {
		if (cell) return window.inrFormat(cell);
		else return null;
	}

	expenseStatusFormatter(cell, row) {
		if (cell !== '' && cell !== 'N/A') return cell + ' Payment';
		else if (cell === 'N/A') return cell;
		else return '';
	}

	percentageUtilizedFormatter(cell, row) {
		if (row.budgeted || row.isTotal) {
			if(cell) return cell + '%';
			else return '0%';
		} else return 'N/A';
	}

	unitEconomicsFormatter(cell, row) {
		if (row.unitEconomics && cell) return cell;
		else return '';
	}

	billsFormatter(cell, row) {
		if (row.isTotal) return null;
		else if (cell === undefined) return '';
		else if (cell.length) return (
			<button className="btn table-btn" onClick={this.editExpense.bind(this, 'view', row)}>
				<i className="fa fa-file-text-o"></i>
				Show Bills
			</button>
		);
		else return row.billStatus;
	}

	editExpense(reportType, expenseData) {
		this.setState({ expenseData, editExpense: true }, function () {
			this.showReportExpenseModal(reportType);
		}.bind(this));
	}

	showReportExpenseModal(reportType) {
		this.setState({ reportExpenseModalVisibility: true, reportType });
	}

	hideReportExpenseModal(type) {
		this.setState({ reportExpenseModalVisibility: false, reportType: '', editExpense: false });
	}

	onCloseModal() {
		this.setState({ openModal: false })
	}
	onOpenModal() {
		this.setState({ openModal: true });
	}
	viewImages(event, data) {
		this.setState({ imgArray: event, openModal: true })
	}
	onCloseGraphModal() {
		this.setState({ openGraphModal: false })
	}
	chatWithNgo() {
		if(this.state.corporateInterestStatus !== false && this.state.corporateInterestStatus !== 'noAction') {
			ReactGA.event({
			  category: 'View Project',
			  action: 'view',
			  label: 'Chat'
			});
			window.location.pathname = "/discussion/" + this.state.projectId;
		}
	}

	handleKPIGraphTypeChange(index, value) {
		let kpiData = this.state.kpiData;
		kpiData[index]['graphType'] = value;
		this.setState({ kpiData });
	}

	renderCusomizedLegend = (name, unit, props) => {
		const { payload } = props;
    return (
      <div className="customized-legend">
      	<div className="legend-box" style={{backgroundColor: payload[0].color}}></div>
      	{unit && unit !== '' && unit !== 'None' ? (
      		<div className="legend-text">{name.charAt(0).toUpperCase() + name.slice(1) + ' (' + unit + ')'}</div>
      	) : (
      		<div className="legend-text">{name.charAt(0).toUpperCase() + name.slice(1)}</div>
      	)}
      </div>
    )
	}

	toggleDetailsView(name) {
		if(this.state[name]) $('.' + name).hide('slow');
		else $('.' + name).show('slow');
		this.setState({ [name]: !this.state[name] });
	}

	handleClick(key) {
		ReactGA.event({
		  category: 'View Project',
		  action: 'view',
		  label: key.charAt(0).toUpperCase() + key.slice(1)
		});
		this.setState({ current: key });
		$(document).ready(function() {
			var $container = $('.mis-content-container.single-csr-project-container'), $scrollTo = $('#' + key);
			$container.animate({
		    scrollTop: $container.scrollTop() + $scrollTo.offset().top - 60
			}, 1500);
		});
	}

	gotoResources() {
		ReactGA.event({
		  category: 'View Project',
		  action: 'view',
		  label: 'Resources'
		});
		if(this.props.selectedProject.hasOwnProperty('customUrl') && this.props.selectedProject.customUrl) window.location.pathname = "/mis-projects/" + this.props.selectedProject.customUrl + "/resources";
		else window.location.pathname = "/mis-projects/" + this.state.projectId + "/resources";
	}

	gotoOrganizer() {
		ReactGA.event({
		  category: 'View Project',
		  action: 'view',
		  label: 'Organizer'
		});
		if(this.props.selectedProject.hasOwnProperty('customUrl') && this.props.selectedProject.customUrl) window.location.pathname = "/mis-projects/" + this.props.selectedProject.customUrl + "/organizer";
		else window.location.pathname = "/mis-projects/" + this.state.projectId + "/organizer";
	}

	onShowForcefulPaymentModalInvest() {
		this.setState({ showForcefulPaymentModal: false });
		this.handlePathChange(this);
	}

	onShowForcefulPaymentModalClose() {
		this.setState({ showForcefulPaymentModal: false });
	}

	amountFormatter(cell, row) {
		if(!isNaN(cell)) return 'INR ' + window.inrFormat(parseFloat(cell));
		else return 'INR ' + 0;
	}

	disbursementConditionFormatter(cell, row) {
		if(cell === '') return 'Select Disbursement Condition';
		else return cell;
	}

	disbursementCriteriaFormatter(cell, row) {
		if(row.disbursementCondition === '') return 'Select Disbursement Condition First';
		else if(row.disbursementCondition === 'Periodic') return 'N/A';
		else if(cell === '') return 'Enter Disbursement Criteria';
		else return cell;
	}

	selectDateFormatter(cell, row) {
		if(cell === '') return 'Select Tentative Date';
		else if(cell && moment(cell).isValid() && cell.match(/....-..-../)) return moment(cell, 'YYYY-MM-DD').format('DD/MM/YYYY');
		else if(cell && moment(cell, 'DD/MM/YYYY').isValid() && cell.match(/..\/..\/..../)) return moment(cell, 'DD/MM/YYYY').format('DD/MM/YYYY');
		else return '';
	}

	editTranches() {
		if(this.props.selectedProject.hasOwnProperty('customUrl') && this.props.selectedProject.customUrl) window.location.pathname = "/mis-projects/" + this.props.selectedProject.customUrl + "/tranch-disbursement";
		else window.location.pathname = "/mis-projects/" + this.state.projectId + "/tranch-disbursement";
	}

	loadMoreProjectUpdates() {
		this.setState({ projectUpdatesLoading: true });
		let page = this.state.projectUpdatesPage + 1;
		fetch(process.env.REACT_APP_API_URL + '/project/allUpdates/' + this.state.projectId + '?perPage=' + this.state.projectUpdatesPerPage + '&page=' + page, {
			method: 'GET',
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then(res => (res.json()))
		.then(res => {
			if(res.status === 'ok') {
				let projectUpdates = [...this.state.projectUpdates, ...res.posts];
				this.setState({ projectUpdates, projectUpdatesPage: page, projectUpdatesLoading: false });
			} else {
				this.setState({ projectUpdatesLoading: false });
			}
		}).catch(function(err) {
			// Not authorized to view updateds
		});
	}

	handleChange = (value) => {
  	let data = {}
  	data['tags'] = value;
  	fetch(process.env.REACT_APP_API_URL + '/project/interests/' + this.state.projectInterestId , {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
			body: JSON.stringify(data)
		}).then(data => data.json())
		.then(data => {
			if (data.status === 'ok') {
				this.notifySuccess("Tags are successfully saved")
				this.setState({tags: value})
			} else {
				this.notifyError("Failed to save tags")
			}
		})
	}

	handleCategoryChange = (value) =>{
		let data ={};
		data['customCategoryId'] = value;
		fetch(process.env.REACT_APP_API_URL+'/updateProjectCategory/'+this.state.projectId,{
			method:"PUT",
			headers: {
				'Content-Type': 'application/json',
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
			body: JSON.stringify(data)
		}).then(data => data.json())
		.then(data => {
			if (data.status === 'ok') {
				this.notifySuccess("Project Category is successfully saved")
				this.setState({customCategory: value})
			} else {
				this.notifyError("Failed to save project category")
			}
		})
	}

	showProjectSettingsModal = () => {
		this.setState({showSettingsModal: !this.state.showSettingsModal});
		this.cancelProjectSettings()
	}
	showChangeInvestmentYear = () =>{
		this.setState({showChangeInvestmentYearModal:true})
	}
	hideChangeInvestmentYear =()=>{
		this.setState({showChangeInvestmentYearModal:false})
	}
	saveChangedInvestmentYear = () =>{
		let currYear=this.state.fiscalYear+'-'+ (parseInt(this.state.fiscalYear)+1).toString();
		if(this.state.investmentYearSelected===currYear)
			alert("Investment Year cannot be the same");
		else if(this.state.reasonForChangingYear==="")
			alert("Reason for changing Investment Year cannot be empty!");
		else{
			let data={}
			data['newInvestmentYear']=this.state.investmentYearSelected;
			data['Reason']=this.state.reasonForChangingYear;
			fetch(process.env.REACT_APP_API_URL+'/investmentYearChange/update',{
				method: "POST",
				headers: {
					'Content-type': 'application/json',
					'Auth': JSON.parse(readCookie('access_token')).access_token
				},
				body: JSON.stringify({
					updatedBy: JSON.parse(readCookie('userData')).fullName,
					companyId : JSON.parse(readCookie('userData')).organisationData.id,
					projectId:this.state.projectId,
					portfolioId:this.state.corporateInvestment.portfolioId,
					oldInvestmentYear:this.state.fiscalYear,
					newInvestmentYear:data['newInvestmentYear'],
					reasonForChange:data['Reason'],
					updatedOn: moment(new Date()).format('DD-MM-YYYY hh:mm:ss')
				})
			})
			.then(response => response.json())
			.then(data => {
				if (data.status === "ok") {
				toast.success("Investment year has been successfully changed! ");
				this.setState({fiscalYear: this.state.investmentYearSelected.split('-')[0]});
				this.hideChangeInvestmentYear();
				}
			}).catch(err => this.notifyError("Oops, something went wrong! Try again later."))
		}
	}
	cancelChangedInvestmentYear =() =>{
		this.setState({
			investmentYearSelected:'',
			reasonForChangingYear:""
		})
		this.hideChangeInvestmentYear();
	}
	porjectSettingsOptions = (e) => {
		this.setState({[e.target.name] : !this.state[e.target.name]})
		{
			if (e.target.name === "ChangeDisbursementPlan")
				Swal.fire({
		  title: 'Are you sure you want to change the disbursement plan?',
		  type: 'warning',
		  showCancelButton: true,
		  confirmButtonColor: '#3085d6',
		  cancelButtonColor: '#d33',
		  confirmButtonText: 'Yes',
		  cancelButtonText: 'No'
		}).then((result) => {
		  if (result.value) {
		    Swal.fire({
		      title: 'Select Disbursement Plan!',
      		input: 'select',
				  inputOptions: {
				    'oneTime': 'One Time',
				    'tranch': 'Tranch',
				  },
				  inputPlaceholder: 'Select Disbursement Plan',
				  inputValidator: (value) => {
				    return new Promise((resolve) => {
				    	resolve(this.setState({projectSettingsDisbursementPlan: value }));
				    })
				  },
      		confirmButtonText: 'ok'
		    })
		  }
		})}
	}

	handleYearChange = (e) =>{
		this.setState({investmentYearSelected: e.target.value});
		this.setState({selectedId:e.target.value});
	}

	cancelProjectSettings = () => {
		this.setState({ChangeDisbursementPlan: false, DisallowDeletionOfKpi : false, DisallowUnbudgetedReporting : false, projectSettingsDisbursementPlan: ""});
	}

	saveProjectSettings = () => {
		let data = {};
		data["DisallowUnbudgetedReporting"] = this.state.DisallowUnbudgetedReporting;
		data["ChangeDisbursementPlan"] = {};
		data["ChangeDisbursementPlan"]["plan"] = this.state.projectSettingsDisbursementPlan;
		data["DisallowDeletionOfKpi"] = this.state.DisallowDeletionOfKpi;
	}

	render() {
		if (this.props.selectedProject.title !== undefined) {
			if (this.props.selectedProject.hasOwnProperty('title')) {
				return (
					<div className="mis-content-container single-csr-project-container">
						<div className="mis-fixed-top-container" id="single-csr-project-fixed-top">
							<div>
								<Modal show={this.state.openModal} onHide={this.onCloseModal.bind(this)}>
									<ImageModal imgArray={this.state.imgArray} hasDate={false} openModal={this.state.openModal} imageIndex={0} />
								</Modal>
								<Modal show={this.state.openGraphModal} style={{ height: '500px' }} onHide={this.onCloseGraphModal.bind(this)} center>
									<GraphModal dataArray={plottedGraph} />
								</Modal>
								<Modal id="show-interest-modal" show={this.state.showForcefulPaymentModal} onHide={this.onShowForcefulPaymentModalClose.bind(this)} bsSize="large">
								  <Modal.Body className="show-interest-modal-body">
								    <Row>
								      <Col className="show-interest-modal-para" md={12}>
								      	<p style={{fontSize: 20, fontWeight: 500, margin: 50, fontFamily: 'Proxima Nova'}}>{"Please invest funds in this project to start the project. Please note that "}<b>{this.props.selectedProject.ngoData.name}</b>{" cannot report budget utilization unless funds are invested."}</p>
								      </Col>
								    </Row>
								  </Modal.Body>
								  <Modal.Footer className="text-center">
								    <Button style={{fontFamily: 'Proxima Nova'}} className="btn le-button-light agree" onClick={this.onShowForcefulPaymentModalClose.bind(this)}>
								      Okay, I understand!
								    </Button>
								    <Button style={{fontFamily: 'Proxima Nova'}} className="btn le-button-light" onClick={this.onShowForcefulPaymentModalInvest.bind(this)}>
								      Invest Funds Now
								    </Button>
								  </Modal.Footer>
								</Modal>

								<Menu selectedKeys={[this.state.current]} mode="horizontal">
									<Menu.Item key="overview">
										<Link to="overview" spy={true} smooth={true} offset={-100} duration={500} onClick={this.handleClick.bind(this, 'overview')}>Overview</Link>
									</Menu.Item>
									<Menu.Item key="description">
										<Link to="description" spy={true} smooth={true} offset={-50} duration={800} onClick={this.handleClick.bind(this, 'description')}>Description</Link>
									</Menu.Item>
									{(this.props.selectedProject.status === 'Completed' || this.props.selectedProject.status === 'Funded') || this.state.amountBreakup !== null || this.state.releasedAmountBreakup.length ? (
										<Menu.Item key="finances">
											<Link to="finances" spy={true} smooth={true} offset={-50} duration={1500} onClick={this.handleClick.bind(this, 'finances')}>Finances</Link>
										</Menu.Item>
									) : (null)}
									{!checkValue(this.state.kpiData) || !checkValue(this.state.beneficiaries) ? (
										<Menu.Item key="impact-monitoring">
											<Link to="impact-monitoring" spy={true} smooth={true} offset={-50} duration={1500} onClick={this.handleClick.bind(this, 'impact-monitoring')}>Impact Monitoring</Link>
										</Menu.Item>
									) : (null)}
									{!checkValue(this.state.milestones) ? (
										<Menu.Item key="milestones">
											<Link to="milestones" spy={true} smooth={true} offset={-50} duration={1500} onClick={this.handleClick.bind(this, 'milestones')}>Milestones</Link>
										</Menu.Item>
									) : (null)}
									<Menu.Item key='resources'>
										<Link onClick={this.gotoResources.bind(this)}><span>Resources</span></Link>
									</Menu.Item>
									<Menu.Item key='export'>
										<Link onClick={this.exportPdf.bind(this)}><span>Export Project</span></Link>
									</Menu.Item>
									<Menu.Item key='chat' title={this.state.corporateInterestStatus === false || this.state.corporateInterestStatus === 'noAction' ? "Show Interest on Project to start chatting" : null}>
										<Link onClick={this.chatWithNgo.bind(this)}>
											<span>Chat{this.state.corporateInterestStatus === false || this.state.corporateInterestStatus === 'noAction' ? (<i className="fa fa-lock ml5"></i>) : (null)}</span>
										</Link>
									</Menu.Item>
									{this.props.selectedProject.projectInterestCode ? (
										<Menu.Item key='organizer'>
											<Link onClick={this.gotoOrganizer.bind(this)}>
												<span>Organizer</span>
											</Link>
										</Menu.Item>
									) : (null)}
									{JSON.parse(readCookie('userData')).authorizedFor.mis !== 'view' ? (
										<Menu.Item key='settings'>
											<Link onClick={this.showProjectSettingsModal}>
												<span className="projectSettingsOption">Settings <i className="fa fa-cog"></i></span>
											</Link>
											{this.state.showSettingsModal ? (
												<div className="projectSettingsModal">
													<div style={{display: "flex", alignItems:"center"}}>
														<input style={{marginTop: "0px"}} type="checkbox" name="DisallowUnbudgetedReporting" onChange={this.porjectSettingsOptions} checked={this.state.DisallowUnbudgetedReporting ? true : false }/>
														<label style={{margin: "0px 0px 0px 10px"}} htmlFor="">Disallow Unbudgeted Reporting</label>
													</div>
													<div style={{display: "flex", alignItems:"center"}}>
														<input style={{marginTop: "0px"}} type="checkbox" name="ChangeDisbursementPlan" onChange={this.porjectSettingsOptions} checked={this.state.ChangeDisbursementPlan ? true : false }/>
														<label style={{margin: "0px 0px 0px 10px"}} htmlFor="">Change Disbursement Plan</label>
													</div>
													<div style={{display: "flex", alignItems:"center"}}>
														<input style={{marginTop: "0px"}} type="checkbox" name="DisallowDeletionOfKpi" onChange={this.porjectSettingsOptions} checked={this.state.DisallowDeletionOfKpi ? true : false }/>
														<label style={{margin: "0px 0px 0px 10px"}} htmlFor="">Disallow Deletion of KPI after Project is Funded</label>
													</div>
													<div>
														<button className="saveProjectSettingsButton" onClick={this.saveProjectSettings}>Save</button>
														<button className="cancelProjectSettingsButton" onClick={this.cancelProjectSettings}>Cancel</button>
													</div>
												</div>
											):(null)}
										</Menu.Item>
									) : (null)}
								</Menu>
								{this.state.confirmInvestment === true && this.state.corporateInterestStatus === 'Invested' && JSON.parse(readCookie('userData')).authorizedFor.mis !== 'view' ? (
									<div>
										<button className="header-express-btn" onClick={this.handlePathChange}>Invest Funds</button>
									</div>
								) : (
									JSON.parse(readCookie('userData')).authorizedFor.mis !== 'view' ? (
										<div>
											{(() => {
												if (this.state.corporateInterestStatus === 'noAction') {
													return (
														<div className="status-holder">
															<button className="header-express-btn" onClick={this.projectStatusChange.bind(this, 'Interested')}>
																Show Interest
															</button>
															<button className="btn skip-btn" onClick={this.projectStatusChange.bind(this, 'Skipped')}>
																Decline
															</button>
														</div>
													)
												} else if (this.state.corporateInterestStatus === 'Interested') {
													return (
														<div className="status-holder">
															<button className="header-express-btn" onClick={this.projectStatusChange.bind(this, 'Under Review')}>
																Put Under Review
															</button>
															<button className="btn skip-btn" onClick={this.projectStatusChange.bind(this, 'Skipped')}>
																Decline
															</button>
														</div>
													)
												} else if (this.state.corporateInterestStatus === 'Under Review') {
													return (
														<div className="status-holder">
															<button className="header-express-btn" onClick={this.projectStatusChange.bind(this, 'Invested')}>
																Start Investing
															</button>
															<button className="btn skip-btn" onClick={this.projectStatusChange.bind(this, 'Skipped')}>
																Decline
															</button>
														</div>
													)
												} else if (this.state.corporateInterestStatus === 'Skipped') {
													return (
														<div className="status-holder">
															<button className="header-express-btn" onClick={this.projectStatusChange.bind(this, 'Interested')}>
																Show Interest
															</button>
														</div>
													)
												}
											})()}
										</div>
									) : (null)
								)}
							</div>
						</div>

						<Row className="back-container" id="overview">
							<a href={"/discover/projects"} className="back" onClick={this.clearSelectedProject.bind(this)}>
								<i className="fa fa-chevron-left"></i>
							</a>
							<span className="project-title">
								{' ' + this.props.selectedProject.title + ' '}
								{this.props.selectedProject.projectInterestCode ? (
									<span title="Email Code" style={{color: "#ef5a20"}}>{' [' + this.props.selectedProject.projectInterestCode + ']'}</span>
								) : (null)}
								</span>
						</Row>

						<Row>
							<Col md={6} className="project-details">
								{this.props.selectedProject.coverImage ? (
									<div className="project-image" style={{ backgroundImage: "url('" + this.props.selectedProject.coverImage + "')" }}>
										<div className="project-cause-icon" title={this.props.selectedProject.goalData.name}
											style={{ backgroundImage: "url('https://le-uploaded-image-bucket.s3.amazonaws.com/" + this.props.selectedProject.goalData.iconUrl + "')" }}>
										</div>
										<span className="project-cause-name">{this.props.selectedProject.goalData.name}</span>
										<div className="project-type">
											<span className="current-state-outer">
												<span className="outer-arrow"></span>
												{this.props.selectedProject.LEAssured ? (
													<span className="current-state-text">LE Assured</span>
												) : (
													<span className="current-state-text">Partner Project</span>
												)}
											</span>
										</div>
									</div>
								) : (
										<div className="project-image" style={{ backgroundImage: "url('https://assets.letsendorse.com/sahaj/project-placeholder.jpg')" }}>
											<div className="project-cause-icon" title={this.props.selectedProject.goalData.name}
												style={{ backgroundImage: "url('https://le-uploaded-image-bucket.s3.amazonaws.com/" + this.props.selectedProject.goalData.iconUrl + "')" }}>
											</div>
											<span className="project-cause-name">{this.props.selectedProject.goalData.name}</span>
											<div className="project-type">
												<span className="current-state-outer">
													<span className="outer-arrow"></span>
													{this.props.selectedProject.LEAssured ? (
														<span className="current-state-text">LE Assured</span>
													) : (
														<span className="current-state-text">Partner Project</span>
													)}
												</span>
											</div>
										</div>
									)}
								{this.state.projectInterestId !== "" ? (
									<div>
										<div className='tags-input'style={{marginTop: "20px"}}>
											<h4>CUSTOM PROJECT CATEGORY (Only one)</h4>
											<Select style={{ width: '100%' }} placeholder="Enter Custom Project Category" value={this.state.customCategory} onChange={this.handleCategoryChange} allowClear>
												{this.state.customCategories.map(option =>{
													return(
													<Option value={option.id}>{option.name}</Option>
													);
												})}
											</Select>
										</div>
										<div className='tags-input'style={{marginTop: "20px"}}>
											<h4>Enter Tags</h4>
											<Select mode="tags" style={{ width: '100%' }} placeholder="Enter Tags" value={this.state.tags} onChange={this.handleChange}></Select>
										</div>
										<div className='tags-input'style={{marginTop: "20px"}}>
											<h4>Enter Indicators</h4>
											<Select mode="tags" style={{ width: '100%' }} placeholder="Select Cluster" value={this.state.tags} onChange={this.handleChange}></Select>
										</div>
									</div>
								) : (null)}
							</Col>
							<Col md={6} className="project-details">
							{this.state.showChangeInvestmentYearModal ? (
								<Modal className="change-investment-year-modal" show={this.state.showChangeInvestmentYearModal}>
									<Modal.Body>
									<div className="container-fluid">
									<div className="change-investment-year-header">
										Change Project Investment Year
									</div>
									<div className="change-investment-year-body">
										<div>Investment Year</div>
										<select name="investment-year" value={this.state.selectedId} onChange={this.handleYearChange}>
										{this.state.optionId.map(id =>
											<option key={id} value={this.state.options[id]}> {this.state.options[id]}</option>
											)}
					 					</select>
									<div>Reason behind changing the Investment Year</div>
										<textarea className="textarea" placeholder='Enter your reason here...' cols="60" rows="8" onChange={(e)=> this.setState({reasonForChangingYear:e.target.value})}></textarea>
									</div>
									</div>
									</Modal.Body>
									<Modal.Footer >
										<div>
										<button type="button" className="CancelButton" onClick={this.cancelChangedInvestmentYear}>Cancel</button>
        								<button type="button" className="SubmitButton ml10" onClick={this.saveChangedInvestmentYear}>Submit</button>
										</div>
										</Modal.Footer>
								</Modal>
								) : (null)}

								<div className="project-key-details">
								<div className="project-key-detail">
									<div className="heading"> Investment Year: </div>
									{this.state.fiscalYear && JSON.parse(readCookie('userData')).authorizedFor.mis !== 'view'  ? (
									<button className="header-express-btn" onClick={this.showChangeInvestmentYear.bind(this)}> {this.state.fiscalYear+'-'+ (parseInt(this.state.fiscalYear)+1-2000).toString()} (Edit this)</button>)
									:(<button className="header-express-btn"> {this.state.fiscalYear+'-'+ (parseInt(this.state.fiscalYear)+1-2000).toString()}</button>)}
									{this.state.hasEdits && JSON.parse(readCookie('userData')).authorizedFor.mis !== 'view' ?(
										<a onClick={this.showEditModal}>Show Edits</a>
									):(null)}
									{this.state.showEditsModal ? (
										<Modal className="editsModal" show={this.state.showEditsModal} bsSize='lg'>
											<Modal.Header className="change-investment-year-header">
											<div>Investment Year Edits
											<button type="button" className="CloseButton mr0" onClick={this.hideEditModal}> &times; </button>
											</div>
											</Modal.Header>
											<Modal.Body>
											<div className="container-fluid">
												<BootstrapTable className="editsTable" data={this.state.yearEdits} striped={false} ref='table' hover={true} pagination>
												<TableHeaderColumn thStyle={{whiteSpace: 'normal'}} dataField='oldInvestmentYear' width="15%" headerAlign='center' dataAlign='center'>Original Investment Year</TableHeaderColumn>
												<TableHeaderColumn thStyle={{whiteSpace: 'normal'}} dataField='newInvestmentYear' width="15%" headerAlign='center' dataAlign='center' >New Investment Year</TableHeaderColumn>
												<TableHeaderColumn tdStyle={{whiteSpace: 'normal'}} dataField='updatedOn' headerAlign='center' dataAlign='center' width="20%" isKey={true}>Updated On</TableHeaderColumn>
												<TableHeaderColumn tdStyle={{whiteSpace: 'normal'}} dataField='updatedBy' headerAlign='center' dataAlign='center' width="20%">Updated By</TableHeaderColumn>
												<TableHeaderColumn tdStyle={{whiteSpace: 'normal'}} dataField='reasonForChange'  width="auto" headerAlign='center' dataAlign='justify'>Reason for Updating</TableHeaderColumn>
												</BootstrapTable>
											</div>
											</Modal.Body>
										</Modal>
									):(null)}
								</div>
									<div className="project-key-detail">
										<div className="heading">Status: </div>
										<div className="content status-content">
											<div className="status-content-container">
												{this.state.corporateInterestStatus === 'noAction' ? (
													<div className="status-content-disabled">Open</div>
												) : (
													<div className="status-content-disabled">{this.state.corporateInterestStatus}</div>
												)}
											</div>
										</div>
									</div>
									{!checkValue(this.props.selectedProject.ngoData) && this.props.selectedProject.ngoData.hasOwnProperty('name') ? (
										<div className="project-key-detail">
											<div className="heading">NGO: </div>
											<div className="content ngo-name">{' ' + this.props.selectedProject.ngoData.name}</div>
										</div>
									): (null)}
									{!checkValue(this.props.selectedProject.location) ? (
										<div className="project-key-detail">
											<div className="heading">Location/Region: </div>
											<div className="content">
												{' ' + this.props.selectedProject.location[0].address}
												{this.props.selectedProject.location.map(function (location, index) {
													if (index !== 0) {
														return (
															<span key={index}>{'; ' + location.address}</span>
														)
													}
												}.bind(this))}
											</div>
										</div>
									) : (null)}
									{this.props.selectedProject.targetBeneficiaries.length ? (
										<div className="project-key-detail">
											<div className="heading">Target beneficiaries ({this.props.selectedProject.targetBeneficiaries.length}): </div>
											<div className="content">
												{this.props.selectedProject.targetBeneficiaries.map(function (targetBeneficiary, i) {
													if (this.props.selectedProject.targetBeneficiaries.length - i === 1) return targetBeneficiary.name
													else return targetBeneficiary.name + ', '
												}.bind(this))}
											</div>
										</div>
									) : (null)}
									{(() => {
										if (this.props.selectedProject.hasOwnProperty('agenda')) {
											if (this.props.selectedProject.agenda.hasOwnProperty('id')) {
												return (
													<div className="project-key-detail">
														<div className="heading">Agenda: </div>
														<div className="content">{this.props.selectedProject.agenda.name}</div>
													</div>
												)
											} else return null;
										} else return null;
									})()}
									{this.props.selectedProject.hasOwnProperty('thematicCategory') && this.props.selectedProject.thematicCategory.name ? (
										<div className="project-key-detail">
											<div className="heading">Schedule 7 Category: </div>
											<div className="content">
												{this.props.selectedProject.thematicCategory.name}
											</div>
										</div>
									) : (null)}
									<div className="project-key-detail" hidden={checkValue(this.props.selectedProject.expectedDurationObject)}>
										<div className="heading">Expected duration: </div>
										<div className="content">{this.props.selectedProject.expectedDurationObject.duration + ' ' + this.props.selectedProject.expectedDurationObject.type}</div>
									</div>
									{!checkValue(this.props.selectedProject.proposedBudget) ? (
										<div className="project-key-detail">
											<div className="heading">Proposed Budget: </div>
											<div className="content">
												{this.props.selectedProject.hasOwnProperty('currency_symbol') ? (<div className="content">{this.props.selectedProject.currency_symbol + window.inrFormat(this.props.selectedProject.proposedBudget)}</div>) : (<div className="content">{'INR ' + window.inrFormat(this.props.selectedProject.proposedBudget)}</div>)}
											</div>
										</div>) : (null)}
									{this.props.selectedProject.unitEconomics ? (
										<div className="project-key-detail">
											<div className="heading">Proposed Requirement: </div>
											<div className="content">
												{this.props.selectedProject.hasOwnProperty('maxUnits') ? (<span className="mr5">{this.props.selectedProject.maxUnits + ' '}</span>) : (null)}
												{this.props.selectedProject.unitName + ' at '}
												{this.props.selectedProject.hasOwnProperty('currency_symbol') ? (
													<span className="ml5">{this.props.selectedProject.currency_symbol + ' ' + window.inrFormat(this.props.selectedProject.unitCost) + ' / Unit'}</span>
												) : (
														<span className="ml5">{'INR ' + window.inrFormat(this.props.selectedProject.unitCost) + ' / Unit'}</span>
													)}
											</div>
										</div>
									) : (null)}
									{/*<div className="project-key-detail">
										<div className="heading">Year:</div>
										<div className="content">
										</div>
									</div>*/}
									<div className="project-key-detail">
										<div className="heading">About the NGO</div>
										<div className="content">
											{this.props.selectedProject.ngoData.csrHistory ? (
												<div className="about-ngo-tag">Has done CSR</div>
											) : (null)}
											{this.props.selectedProject.ngoData.hasOwnProperty('section') ? (
												this.props.selectedProject.ngoData.section.map(function (sec, index) {
													return <div className="about-ngo-tag" key={index + "cc"}>{sec.name}</div>
												})
											) : (null)}
											{/* <div className="about-ngo-tag">{this.props.selectedProject.ngoData.older} years old</div> */}
											{this.state.projectId === '5c04e6ecb2c5f1676389906f' ? (
												<div className="about-ngo-tag l3-due-diligence-done">L3 due diligence done</div>
											) : (
												<div className="about-ngo-tag l2-due-diligence-done">L2 due diligence done</div>
											)}
										</div>
									</div>
								</div>
								{this.state.corporateInterestStatus === 'Skipped' && JSON.parse(readCookie('userData')).authorizedFor.mis !== 'view' ? (
									<div className="project-status-btn-container">
										<button className="express-btn" onClick={this.projectStatusChange.bind(this, 'Interested')}>
											Show Interest
										</button>
									</div>
								) : (
									JSON.parse(readCookie('userData')).authorizedFor.mis !== 'view' ? (
										<div className="project-status-btn-container">
											{this.state.canShowInterest !== null ? (
												this.state.canShowInterest === false ? (
													<button className="btn project-buy" disabled={true}>
														{' Already Interest Shown'}
													</button>
												) : (
													this.state.confirmInvestment === true && this.state.corporateInterestStatus === 'Invested' ? (
														<button className="express-btn" onClick={this.handlePathChange}>
															Invest Funds
														</button>
													) : (
														<div className="btn-group">
															{this.state.corporateInterestStatus === 'noAction' ? (
																<div className="btn-container">
																	<button className="express-btn" onClick={this.projectStatusChange.bind(this, 'Interested')}>
																		Show Interest
																	</button>
																	<button className="btn skip-btn" onClick={this.projectStatusChange.bind(this, 'Skipped')}>
																		Decline
																	</button>
																</div>
															) : (null)}
															{this.state.corporateInterestStatus === 'Interested' ? (
																<div className="btn-container">
																	<button className="express-btn" onClick={this.projectStatusChange.bind(this, 'Under Review')}>
																		Put Under Review
																	</button>
																	<button className="btn skip-btn" onClick={this.projectStatusChange.bind(this, 'Skipped')}>
																		Decline
																	</button>
																</div>
															) : (null)}
															{this.state.corporateInterestStatus === 'Under Review' ? (
																<div className="btn-container">
																	<button className="express-btn" onClick={this.projectStatusChange.bind(this, 'Invested')}>
																		Start Investing
																	</button>
																	<button className="btn skip-btn" onClick={this.projectStatusChange.bind(this, 'Skipped')}>
																		Decline
																	</button>
																</div>
															) : (null)}
														</div>
													)
												)
											) : (null)}
										</div>
									) : (null)
								)}
							</Col>
							{this.props.selectedProject.hasOwnProperty('innovationData') && Object.keys(this.props.selectedProject.innovationData).length ? (
								<Col md={12} className='project-details-mobile'>
									<Row className="innovation-card">
										<Col md={8} className="innovation-card-text-container">
											<span className="is-inspired-by">This Project is Inspired by -</span>
											<a href={this.props.selectedProject.innovationData.url} target="_blank">
												<span className="innovation-card-title">{this.props.selectedProject.innovationData.title}</span>
											</a>
											<div className="innovation-card-description">
												<span className={this.state.readMore ? ('desc') : ('desc ellipsis')}>
													{this.props.selectedProject.innovationData.description}
												</span>
												<div className="read-more" onClick={() => { this.setState({ readMore: !this.state.readMore }) }}>
													{this.state.readMore ? ('Read Less') : ('Read More')}
												</div>
											</div>
											<div className="innovation-card-impact-container">
												<Col md={6} className="social-impact-container">
													<span className="title"><div className="dot"></div>{'Social Impact :'}</span>
													<span className={this.state.readMoreSocialImpact ? ('desc') : ('desc ellipsis')}>
														{this.props.selectedProject.innovationData.socialImpact}
													</span>
													<div className="read-more" onClick={() => { this.setState({ readMoreSocialImpact: !this.state.readMoreSocialImpact }) }}>
														{this.state.readMoreSocialImpact ? ('Read Less') : ('Read More')}
													</div>
												</Col>
												<Col md={6} className="economic-impact-container">
													<span className="title"><div className="dot"></div>{'Economic Impact :'}</span>
													<span className={this.state.readMoreEconomicImpact ? ('desc') : ('desc ellipsis')}>
														{this.props.selectedProject.innovationData.economicImpact}
													</span>
													<div className="read-more" onClick={() => { this.setState({ readMoreEconomicImpact: !this.state.readMoreEconomicImpact }) }}>
														{this.state.readMoreEconomicImpact ? ('Read Less') : ('Read More')}
													</div>
												</Col>
											</div>
										</Col>
										<Col md={4} className="innovation-card-image-container">
											<a href={this.props.selectedProject.innovationData.url} target="_blank" className="innovation-card-image-link">
												<div className="innovation-card-cause-icon" style={{ backgroundColor: this.props.selectedProject.innovationData.goalBackgroundColor, backgroundImage: "url('" + this.props.selectedProject.innovationData.goalIcon + "')" }}></div>
												<img className="innovation-card-image" src={this.props.selectedProject.innovationData.image} />
											</a>
											<span className="interested-in-innovation">Interested in the Innovation?</span>
											<button className="btn view-more-btn" onClick={() => window.location.pathname = "/innovation/" + this.props.selectedProject.innovationData.customUrl}>View More</button>
										</Col>
									</Row>
								</Col>
							) : (null)}
							<Col id="description" md={12} className="project-details more-details padt10">
								<Row>
									<Col md={12} className="features mt10 mb10">
										<div className="heading"><div className="dot"></div>{'Background and Rationale of the Project :'}</div>
										<div className="content">{this.props.selectedProject.description}</div>
									</Col>
									<Col md={12} className="features mt10 mb10" hidden={checkValue(this.props.selectedProject.expectedImpact)}>
										<div className="heading"><div className="dot"></div>{'Expected Impact :'}</div>
										<div className="content">{this.props.selectedProject.expectedImpact}</div>
									</Col>

									<Col md={12} className="features mt10 mb10" style={{ padding: '0' }} hidden={checkValue(this.props.selectedProject.milestones)}>
										{this.props.selectedProject.milestones ? (
											<Col md={12} className="even-more-details-milestones-container">
												<div className="heading"><div className="dot"></div>{'Key activities/milestones planned to achieve through this project :'}</div>
												<div className="milestones" style={{ padding: '0 10px' }}>
													{this.props.selectedProject.milestones.map(function (milestone, index) {
														return (
															<div className="milestone" key={index}>
																<div className="dot"></div>
																{milestone}
															</div>
														)
													})}
												</div>
											</Col>
										) : (null)}
									</Col>

									<Col md={12} className="features mb10">
										<div id="even-more-details-container">
											<Row className="even-more-details-about-container">
												<Col md={12} className="features mb10" hidden={checkValue(this.props.selectedProject.need)}>
													<div className="heading"><div className="dot"></div>{'Overall Objective of the Project :'}</div>
													<div className="content">{this.props.selectedProject.need}</div>
												</Col>
												<Col md={12} className="features mt10 mb10" hidden={checkValue(this.props.selectedProject.impactMonitoring)}>
													<div className="heading"><div className="dot"></div>{'Systems in place to monitor the impact of the project :'}</div>
													<div className="content">{this.props.selectedProject.impactMonitoring}</div>
												</Col>
												<Col md={12} className="features mt10 mb10" hidden={checkValue(this.props.selectedProject.pastProjectHistory)}>
													<div className="heading"><div className="dot"></div>{'History of executing similar projects in the past :'}</div>
													<div className="content">{this.props.selectedProject.pastProjectHistory}</div>
												</Col>
												<Col md={12} className="features mt10 mb10" hidden={checkValue(this.props.selectedProject.sustainabilityPlan)}>
													<div className="heading"><div className="dot"></div>{'The plans to sustain this project in a long-term and the funding source :'}</div>
													<div className="content">{this.props.selectedProject.sustainabilityPlan}</div>
												</Col>
												{this.props.selectedProject.eeOpportunities ? (
													<Col md={12} className="features mt10 mb10" hidden={checkValue(this.props.selectedProject.eeOpportunities)}>
														<div className="heading"><div className="dot"></div>{'Various Opportunities for Employee Engagement, as part of this project :'}</div>
														<div className="content">
															<div className="ee-opportunities">
																{this.props.selectedProject.eeOpportunities.map(function (eeOpportunity, eeOpportunityIndex) {
																	return (
																		<div className="ee-opportunity" key={eeOpportunityIndex}>
																			<div className="dot"></div>
																			{eeOpportunity}
																		</div>
																	)
																})}
															</div>
														</div>
													</Col>
												) : (null)}
											</Row>

											{(this.props.selectedProject.status === 'Completed' || this.props.selectedProject.status === 'Funded') || this.state.amountBreakup !== null || this.state.releasedAmountBreakup.length ? (
												<div id="finances" className="finances-container">
													<div className="heading">
														<div className="dot"></div>Finances
													</div>
													{this.props.selectedProject.status === 'Completed' || this.props.selectedProject.status === 'Funded' ? (
														<div className="finances-small">
															<div className="square-box">
															  <div className="content">
															    <div className="heading">Allocated Amount</div>
															    <div className="sub-heading"></div>
															    <div className="amount totalAllocated"><span>(INR)</span>{window.inrFormat(this.props.selectedProject.proposedBudget)}</div>
															  </div>
															</div>
															<div className="square-box">
															  <div className="content">
															    <div className="heading">Invested Amount</div>
															    <div className="sub-heading"></div>
															    <div className="amount totalInvested"><span>(INR)</span>{window.inrFormat(this.props.selectedProject.investedAmount)}</div>
															  </div>
															</div>
															<div className="square-box">
															  <div className="content">
															    <div className="heading">Utilized Amount</div>
															    <div className="sub-heading"></div>
																	<div className="amount totalUtilized"><span>(INR)</span>{window.inrFormat(this.props.selectedProject.utilizedAmount ? this.props.selectedProject.utilizedAmount.toFixed(2):0)}</div>
															  </div>
															</div>
														</div>
													) : (null)}

													{this.state.amountBreakup !== null ? (
														<div className={this.state.showProjectBudgetDetails ? "even-more-details-budget-container mt10" : "even-more-details-budget-container mt10 small"}>
															<div className="heading">
																<div className="dot"></div>
																{this.state.showProjectBudgetDetails ? 'Project Budget & Utilization:' : 'Project Budget & Utilization'}
															</div>
															{this.state.showProjectBudgetDetails ? (
																<div className="btn show-more less" onClick={this.toggleDetailsView.bind(this, 'showProjectBudgetDetails')}>Show Less<i className="fa fa-chevron-up"></i></div>
															) : (
																<div className="btn show-more" onClick={this.toggleDetailsView.bind(this, 'showProjectBudgetDetails')}>Show More<i className="fa fa-chevron-down"></i></div>
															)}

															<div className={this.state.showProjectBudgetDetails ? "tab-content budget-tab-content showProjectBudgetDetails" : "tab-content budget-tab-content to-be-collapsed showProjectBudgetDetails"}>
																<BootstrapTable data={this.state.amountBreakup} striped={false} ref='table' hover={true}>
																	<TableHeaderColumn dataField="id" isKey={true} hidden={true} export={false} searchable={false}>Expense Id</TableHeaderColumn>
																	<TableHeaderColumn dataField="costHead" width='140px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} dataFormat={this.costHeadFormatter}>Expense Head</TableHeaderColumn>
																	<TableHeaderColumn dataField="expenseItem" width='180px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true}>Expense Item</TableHeaderColumn>
																	<TableHeaderColumn dataField="costHead" width='70px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} dataFormat={this.typeFormatter}>Type</TableHeaderColumn>
																	<TableHeaderColumn dataField="totalUnits" width='60px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} dataFormat={this.unitEconomicsFormatter}>Units</TableHeaderColumn>
																	<TableHeaderColumn dataField="unitCost" width='70px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} dataFormat={this.unitEconomicsFormatter}>Cost/Unit</TableHeaderColumn>
																	<TableHeaderColumn dataField="budgeted" width='70px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} dataFormat={this.budgetedFormatter}>Budgeted</TableHeaderColumn>
																	<TableHeaderColumn dataField="proposedAmount" width='100px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} dataFormat={this.proposedAmountFormatter}>Proposed Amount (with TAX)</TableHeaderColumn>
																	<TableHeaderColumn dataField="reportedAmount" width='100px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} dataFormat={this.reportedAmountFormatter}>Reported Amount (with TAX)</TableHeaderColumn>
																	<TableHeaderColumn dataField="expenseStatus" width='100px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} dataFormat={this.expenseStatusFormatter.bind(this)}>Expense Status</TableHeaderColumn>
																	<TableHeaderColumn dataField="percentageUtilized" width='100px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} dataFormat={this.percentageUtilizedFormatter}>Percentage Utilized</TableHeaderColumn>
																	<TableHeaderColumn dataField="bills" width='140px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={false} searchable={false} dataFormat={this.billsFormatter.bind(this)}>Bills</TableHeaderColumn>
																</BootstrapTable>

																{this.state.allowReporting ? (
																	<div className="budget-tab-buttons-container">
																		<button className="btn" onClick={this.showReportExpenseModal.bind(this, 'reportUnbudgeted')}>
																			Add an unbudgeted expense
																		</button>
																	</div>
																) : (null)}
															</div>
														</div>
													) : (null)}

													{this.state.tranches !== null && this.state.tranches.length ? (
														<div className={this.state.showProjectTranches ? "even-more-details-budget-container mt10" : "even-more-details-budget-container mt10 small"}>
															<div className="heading">
																<div className="dot"></div>
																{this.state.showProjectTranches ? 'Tranche Disbursements:' : 'Tranche Disbursements'}
															</div>
															{this.state.showProjectTranches && this.props.selectedProject.corporateInterestStatus === 'Awaiting action from Ngo' ? (
																<button className="btn btn-default mb5" onClick={this.editTranches.bind(this)}>
																	<i className="fa fa-pencil"></i>
																</button>
															) : (null)}
															{this.state.showProjectTranches ? (
																<div className="btn show-more less" onClick={this.toggleDetailsView.bind(this, 'showProjectTranches')}>Show Less<i className="fa fa-chevron-up"></i></div>
															) : (
																<div className="btn show-more" onClick={this.toggleDetailsView.bind(this, 'showProjectTranches')}>Show More<i className="fa fa-chevron-down"></i></div>
															)}

															<div className={this.state.showProjectTranches ? "tab-content budget-tab-content showProjectTranches" : "tab-content budget-tab-content to-be-collapsed showProjectTranches"}>
																<BootstrapTable data={this.state.tranches} striped={false} ref='tranches-table' hover={true}>
																	<TableHeaderColumn dataField='slno' width='80' headerAlign='center' dataAlign='center' isKey={true}>Tranches</TableHeaderColumn>
														      <TableHeaderColumn dataField='amount' width='160' headerAlign='center' dataAlign='center' dataFormat={this.amountFormatter.bind(this)}>Planned Amount</TableHeaderColumn>
														      <TableHeaderColumn dataField='disbursementCondition' headerAlign='center' dataAlign='center' dataFormat={this.disbursementConditionFormatter.bind(this)}>Disbursement Condition</TableHeaderColumn>
														      <TableHeaderColumn dataField='disbursementCriteria' headerAlign='center' dataAlign='center' dataFormat={this.disbursementCriteriaFormatter.bind(this)}>Disbursement Criteria</TableHeaderColumn>
														      <TableHeaderColumn dataField='date' width='180' headerAlign='center' dataAlign='center' dataFormat={this.selectDateFormatter.bind(this)}>Tentative Disbursement Date</TableHeaderColumn>
																</BootstrapTable>
															</div>
														</div>
													) : (null)}

													{this.state.releasedAmountBreakup.length ? (
														<div className={this.state.showProjectInvestmentDetails ? "even-more-details-budget-container mt20" : "even-more-details-budget-container mt20 small"}>
															<div className="heading">
																<div className="dot"></div>
																{this.state.showProjectInvestmentDetails ? 'My Investments :' : 'My Investments'}
															</div>
															{this.state.showProjectInvestmentDetails ? (
																<div className="btn show-more less" onClick={this.toggleDetailsView.bind(this, 'showProjectInvestmentDetails')}>Show Less<i className="fa fa-chevron-up"></i></div>
															) : (
																<div className="btn show-more" onClick={this.toggleDetailsView.bind(this, 'showProjectInvestmentDetails')}>Show More<i className="fa fa-chevron-down"></i></div>
															)}

															<div className={this.state.showProjectInvestmentDetails ? "showProjectInvestmentDetails" : "to-be-collapsed showProjectInvestmentDetails"}>
																<ReleaseAmountTable  tranches={this.state.tranches} releasedAmountBreakup={this.state.releasedAmountBreakup} updateReleaseAmountBreakup={this.updateReleaseAmountBreakup.bind(this)} investmentId={this.state.corporateInvestment.investmentId} companyId={this.state.companyId} disbursementType={this.props.selectedProject.corporateInterest.disbursementType} />
															</div>
														</div>
													) : (null)}
												</div>
											) : (null)}
										</div>
									</Col>

									<Col md={12} id="impact-monitoring" className="features mt10 mb10" hidden={checkValue(this.state.kpiData) && checkValue(this.state.beneficiaries)}>
										<div className="heading"><div className="dot"></div>{'Impact :'}</div>
										<div className="content">
											{this.state.beneficiaries !== null ? (
												<div className="beneficiaries-container">
													{this.state.beneficiaries.map(function(beneficiary, index) {
														return (
															<div key={index} className="beneficiary-container">
																<h4 className="beneficiary-name">{"Total " + beneficiary.name + " Reached"}</h4>
																<div className="beneficiary-actual">{beneficiary.actual}<span>{' ' + beneficiary.type + 'ly'}</span></div>
																{beneficiary.potential ? (
																  <div className="beneficiary-potential-container">of potential<span>{' ' + window.inrFormat(beneficiary.potential)}</span></div>
																) : (null)}
																{beneficiary.potential ? (
																	<div className="percent-container">
																	  <div className="percent-bar" style={Math.round(beneficiary.actual / beneficiary.potential * 100) > 100 ? {width: '100%'} : { width: Math.round(beneficiary.actual / beneficiary.potential * 100) + '%' }}></div>
																	</div>
															  ) : (null)}
															  {beneficiary.potential ? (
																	<div className="percent-count count">{Math.round(beneficiary.actual / beneficiary.potential * 100) + '% of target achieved'}</div>
																) : (null)}
															</div>
														)
													}.bind(this))}
													<BootstrapTable data={this.state.kpiData} striped={false} ref='table' hover={true} id="csr-kpi-table" className="csr-kpi-table">
														<TableHeaderColumn dataField="id" isKey hidden={true} export={false} searchable={false}>Id</TableHeaderColumn>
                            <TableHeaderColumn dataField="name" width='140px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true}>KEY INDICATORS</TableHeaderColumn>
                            <TableHeaderColumn dataField="type" width='140px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true}>TYPE</TableHeaderColumn>
                            <TableHeaderColumn dataField="baselineValue" width='140px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true}>BASELINE VALUES</TableHeaderColumn>
                            <TableHeaderColumn dataField="targetValue" width='140px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true}>TARGET VALUES</TableHeaderColumn>
                           { _.map(this.state.assessmentdata, (el, i) => (
                            	<TableHeaderColumn key={i} dataField='assessments' width='140px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} dataFormat={this.showAssessmentData.bind(this, el.date)}>{el.date}</TableHeaderColumn>
                            ))}
													</BootstrapTable>
												</div>
											) : (null)}

											{this.state.kpiData !== null ? (
												<div className="kpis-graph-container">
													{this.state.kpiData.map(function(kpi, index) {
														return (
															<div className={kpi.assessments.length ? "kpi-container" : "kpi-container no-assessments"} key={index}>
																{kpi.assessments.length ? (
																	<div className="kpi-name">{kpi.name.charAt(0).toUpperCase() + kpi.name.slice(1)}</div>
																) : (null)}
																{kpi.assessments.length ? (
																	<Select defaultValue="changeGraphType" className="kpi-graph-type-selector" style={{ width: 36 }} onChange={this.handleKPIGraphTypeChange.bind(this, index)}>
																		<Option value="changeGraphType" disabled></Option>
																		<Option value="barChart">Bar Chart</Option>
																		<Option value="lineChart">Line Chart</Option>
																	</Select>
																) : (null)}

																{/*<a className="export-kpi-graph-btn" target="_blank"><i className="fa fa-download"></i></a>*/}

																{kpi.assessments.length ? (
																	kpi.hasOwnProperty('graphType') && kpi.graphType === 'lineChart' ? (
																		<ResponsiveContainer height={250}>
																		  <LineChart height={250} data={kpi.assessments}
																		    margin={{ top: 30, right: 30, left: 10, bottom: 35 }} maxBarSize={10}>
																		    <XAxis dataKey="date" />
																		    <YAxis />
																		    <CartesianGrid strokeDasharray="3 3" />
																		    <Tooltip cursor={{ fill: '#f3f3f3' }} content={<CustomTooltip />} />
																		    <Legend content={this.renderCusomizedLegend.bind(this, kpi.name, kpi.unit)} />
																		    <Line type="monotone" dataKey="value" stackId="a" stroke="#52a9f5" />
																		    {kpi.targetValue !== 'N/A' ? (
																		    	<ReferenceLine y={kpi.targetValue} stroke="green" alwaysShow={true} />
																		    ) : (null)}
																		    {kpi.baselineValue !== 'N/A' ? (
																		    	<ReferenceLine y={kpi.baselineValue} stroke="#ef5a20" alwaysShow={true} />
																		    ) : (null)}
																		  </LineChart>
																		</ResponsiveContainer>
																	) : (
																		<ResponsiveContainer height={250}>
																		  <BarChart height={250} data={kpi.assessments}
																		    margin={{ top: 30, right: 30, left: 10, bottom: 35 }} maxBarSize={10}>
																		    <XAxis dataKey="date" />
																		    <YAxis />
																		    <CartesianGrid strokeDasharray="3 3" />
																		    <Tooltip cursor={{ fill: '#f3f3f3' }} content={<CustomTooltip />} />
																		    <Legend content={this.renderCusomizedLegend.bind(this, kpi.name, kpi.unit)} />
																		    <Bar dataKey="value" stackId="a" fill="#52a9f5" />
																		    {kpi.targetValue !== 'N/A' ? (
																		    	<ReferenceLine y={kpi.targetValue} stroke="green" alwaysShow={true} />
																		    ) : (null)}
																		    {kpi.baselineValue !== 'N/A' ? (
																		    	<ReferenceLine y={kpi.baselineValue} stroke="#ef5a20" alwaysShow={true} />
																		    ) : (null)}
																		  </BarChart>
																		</ResponsiveContainer>
																	)
																) : (
																	<div className="no-assessments-container">
																		<span className="no-assessments-span">{kpi.type + ' Indicator'}</span>
																		<h4 className="no-assessments">{kpi.name.charAt(0).toUpperCase() + kpi.name.slice(1)}</h4>
																		{kpi.baselineValue !== 'N/A' ? (
																			<span className="no-assessments-span"><label>{'Baseline Value : '}</label>{kpi.baselineValue}</span>
																		) : (null)}
																		{kpi.targetValue !== 'N/A' ? (
																			<span className="no-assessments-span"><label>{'Target Value : '}</label>{kpi.targetValue}</span>
																		) : (null)}
																	</div>
																)}
																{kpi.assessments.length ? (
																	<div className="assessments-details-container">
																		<span className="assessments-details-span">{kpi.type + ' Indicator'}</span>
																		{kpi.baselineValue !== 'N/A' ? (
																			<span className="assessments-details-span"><label>{'Baseline Value : '}</label>{kpi.baselineValue}</span>
																		) : (null)}
																		{kpi.targetValue !== 'N/A' ? (
																			<span className="assessments-details-span"><label>{'Target Value : '}</label>{kpi.targetValue}</span>
																		) : (null)}
																	</div>
																) : (null)}
															</div>
														)
													}.bind(this))}
												</div>
											) : (null)}
										</div>
									</Col>

									<Col md={12} className="features mt10 mb10" hidden={checkValue(this.state.projectUpdates)}>
										<div className="heading">
											<div className="dot"></div>{'Project Updates :'}
										</div>
										<div className="updates-container">
										  {this.state.projectUpdates.map(function(update, index) {
										    return (
										      <div className="update-container" key={index}>
										        <div className="update-container-right">
										          <div className="update-container-bottom">
										          	<div className="description">{update.text}</div>
										            {update.images.length ? (
										            	<button className="update-images" onClick={this.viewImages.bind(this, update.images)}>
										            		View Images
										            	</button>
										            ) : (null)}
										          </div>
											        <div className="update-container-top">
											          <div className="user">{update.postedBy.fullName}</div>
											          <div className="date">{moment(update.createdAt).format('DD MMM YYYY')}</div>
											        </div>
										        </div>
										      </div>
										    )
										  }.bind(this))}
										</div>
										{this.state.projectUpdates.length < this.state.totalProjectUpdates && this.state.totalProjectUpdates > 5 ? (
	                    <div className="load-more-updates-container">
	                    	<button className="btn load-more-btn" onClick={this.loadMoreProjectUpdates.bind(this)}>
	                    		Load More
	                    		{this.state.projectUpdatesLoading ? (
	                    			<i className="fa fa-spin fa-spinner"></i>
	                    		) : (null)}
	                    	</button>
	                    </div>
										) : (null)}
									</Col>

									<Col md={12} className="features mt10 mb10" hidden={checkValue(this.state.milestones)}>
										<div className="heading"><div className="dot"></div>{'Milestones :'}</div>
										<div className="content">
											<div id="milestones" className="section pt30">
												<div className={this.state.milestones.length ? "milestones-container" : "milestones-container empty"}>
													{this.state.milestones.length ? (<div className="milestones-start"></div>) : (null)}
													{this.state.milestones.map(function (milestone, index) {
														milestone.hover = true; milestone.isDeletable = true;
														return (
															<div className="milestone-container" key={index}>
																{index % 2 === 1 ? (
																	<div className="milestones-line-container right">
																		<span className="dot">
																			<span className="inner-dot"></span>
																		</span>
																	</div>
																) : (null)}

																<div className={index % 2 === 0 ? "milestones-left-container" : "milestones-right-container"}>
																	<div className={index % 2 === 0 ? "left-milestone" : "right-milestone"}>
																		<span>
																			{milestone.hasOwnProperty('url') ? (
																				<a href={milestone.url} target="_blank"><span className="title">{milestone.title}</span></a>
																			) : (
																				<span className="title">{milestone.title}</span>
																			)}
																			<span className="date">{moment(milestone.date, 'DD/MM/YYYY').format('DD/MM/YYYY')}</span>
																		</span>
																		<span className="description">{milestone.description}</span>
																	</div>
																</div>

																{index % 2 === 0 ? (
																	<div className="milestones-line-container">
																		<span className="dot">
																			<span className="inner-dot"></span>
																		</span>
																	</div>
																) : (null)}
															</div>
														)
													}.bind(this))}

													{this.state.milestones.length ? (
														<div className="milestones-end"></div>
													) : (
														<h2 className="text-center">No Milestones Yet</h2>
													)}
												</div>
											</div>
										</div>
									</Col>

								</Row>
							</Col>
						</Row>

						{this.state.canShowInterest ? (
							<ShowInterestModal showInterestMessage={this.state.showInterestMessage}
								ShowInterestModal={this.state.ShowInterestModal}
								updateMessage={this.updateMessage.bind(this)}
								showInterest={this.showInterest.bind(this)}
								projectId={this.state.projectId} customUrl={this.props.selectedProject.customUrl}
								onShowInterestClose={this.onShowInterestClose.bind(this)}
								onShowInterestOpen={this.onShowInterestOpen.bind(this)}
								handleAgree={this.handleAgree.bind(this)} />
						) : (null)}
						{this.state.csrBudget !== null ? (
							<NotificationModal ShownotificationModal={this.state.ShownotificationModal}
								notificationContent={this.state.notificationContent}
								onClosenotification={this.onClosenotification.bind(this)}
								onShownotification={this.onShownotification.bind(this)}
								QueryYear={this.state.QueryYear} csrBudgetYear={this.state.csrBudget.year}
								years={years} handleConfirm={this.handleConfirm.bind(this)} />
						) : (null)}
						<ToastContainer	position="bottom-center" autoClose={4000}	hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={false} />
						{this.state.reportExpenseModalVisibility && this.state.reportType !== '' ? (
							<ReportExpenseModal hideReportExpenseModal={this.hideReportExpenseModal.bind(this)} type={this.state.reportType}
								project_id={this.props.selectedProject.id} editExpense={this.state.editExpense} expenseData={this.state.expenseData} />
						) : (null)}
					</div>
				);
			} else {
				return null;
			}
		} else {
			return null;
		}
	}
}

SingleMISProject.propTypes = {
	selectedProject: PropTypes.object,
};

function mapStateToProps(state) {
	return {
		selectedProject: state.DiscoverCSRProjects.selectedProject,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(action, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SingleMISProject);
