import React, { Component } from "react";
import DocumentTitle from "react-document-title";

import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

import Signup from "./components/2-Signup/Signup";
import ClassificationModule from "./components/ClassificationModule/ClassificationModule";
import FirstLogin from "./components/CSR/1-Landing/FirstLogin";
import NotFound from "./components/NotFound/NotFound";
import Verify from "./components/Verify/Verify";
import ChangePasswordLayout from './components/MIS/ChangePasswordLayout';

// Admin
import AdminLogin from './components/Admin/AdminLogin';
import AdminDashboard from './components/Admin/AdminDashboard';

// CSR
import CSRHeader from './components/CSR/0-Navigation/Header';
import Landing from './components/CSR/1-Landing/Landing';
import DiscoverCauses from './components/CSR/3-DiscoverCauses/DiscoverCauses';
import SingleCause from './components/CSR/3-DiscoverCauses/SingleCause/SingleCause';
import DiscoverProjects from './components/CSR/4-DiscoverProjects/DiscoverProjects';
import SingleProject from './components/CSR/4-DiscoverProjects/SingleProject/SingleProject';
import Corporate from './components/CSR/5-Corporate/Corporate';
import Profile from './components/CSR/Profile/Profile';
import DiscoverCSRProjects from './components/CSR/6-DiscoverCSRProjects/DiscoverCSRProjects';
import SingleCSRProject from './components/CSR/6-DiscoverCSRProjects/SingleCSRProject/SingleCSRProject';
import Contact from './components/CSR/Contact/Contact'

// MIS
import MISDashboardCSR from './components/MIS/MISDashboard/MISDashboardCSR';
import MISDashboardImpactFootprint from './components/MIS/MISDashboard/MISDashboardImpactFootprint';
import MISHeader from './components/MIS/0-Navigation/Header';
import Preferences from './components/MIS/Preferences/Preferences';
import MISProjects from './components/MIS/Discover/MISProjects';
import MISProjects2 from './components/MIS/Discover/MISProjects2';
import MISPartnerProjects from './components/MIS/Discover/MISPartnerProjects';
import Indicators from './components/MIS/Discover/Indicators';
import Trends from './components/MIS/Discover/Trends';
import DirectorsReport from './components/MIS/Discover/DirectorsReport';
import partnerPage from "./components/MIS/Discover/partnerPage";
import MISInnovativeModels from './components/MIS/Discover/MISInnovativeModels';
import CSRPortfolio from './components/MIS/CSRPortfolio/CSRPortfolio';
import SinglePortfolioGoal from './components/MIS/CSRPortfolio/SinglePortfolioGoal';
import EditSinglePortfolioGoal from './components/MIS/CSRPortfolio/EditSinglePortfolioGoal';
import MISSingleProjectInvestment from './components/MIS/CSRPortfolio/MISSingleProjectInvestment';
import Investments from './components/MIS/CSRPortfolio/Investments';
import Settings from './components/MIS/Settings/Settings';
import ChatMessage from './components/MIS/Discussion/ChatMessage';
import EmailsRepo from './components/MIS/EmailRepository/EmailsRepo';
import Walkthrough from './components/MIS/Walkthrough/Walkthrough';
import Strategy from './components/MIS/Walkthrough/Strategy/Strategy';
import FiscalYearBudgeting from './components/MIS/Walkthrough/FiscalYearBudgeting/FiscalYearBudgeting';
import Innovation from './components/MIS/Discover/Innovation/Innovation';
import SingleMISProject from './components/MIS/Discover/SingleMISProject/SingleMISProject';
import SingleMISProjectResources from './components/MIS/Discover/SingleMISProject/SingleMISProjectResources';
import SingleMISProjectTranches from './components/MIS/Discover/SingleMISProject/SingleMISProjectTranches';
import SingleMISProjectOrganizer from './components/MIS/Discover/SingleMISProject/SingleMISProjectOrganizer';
import InvestmentGoalPlan from './components/MIS/Walkthrough/InvestmentPlanning/InvestmentGoalPlan';
import CSRCorner from "./components/MIS/Walkthrough/CSR-Corner/CSRCorner";
import UserProfile from "./components/MIS/Settings/UserProfile";
import Notifications from "./components/MIS/0-Navigation/Notifications";

const createCookie = require('./cookie.js').createCookie;
const readCookie = require('./cookie.js').readCookie;
const eraseCookie = require('./cookie.js').eraseCookie;

const DefaultCSRLayout = ({ component: Component, ...rest }) => {
	return (
		<Route {...rest} render={matchProps => (
			<div>
				<DocumentTitle title='CSR' />
				<CSRHeader />
				<Component {...matchProps} userData={rest.userData} />
			</div>
		)} />
	)
};

const DefaultMISLayout = ({ component: Component, ...rest }) => {
	return (
		<Route {...rest} render={matchProps => (
			<div className="mis-container">
				<DocumentTitle title='CSREdge' />
				<MISHeader selected={rest.selected} />
				<Component {...matchProps} {...rest} />
			</div>
		)} />
	)
};

export default class Routes extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userData: null,
			walkthrough: null
		};
	}

	checkUser() {
		if (readCookie("userData") === null) {
			fetch(process.env.REACT_APP_API_URL + "/check_user").then(function (response) {
				if (response.ok) return response.json();
			}).then(function (data) {
				createCookie("userData", data["userData"], 7);
			});
		}
	}

	componentWillMount() {
		let userDataCookie = readCookie("userData");
		let userDataJSON = JSON.parse(userDataCookie);
		let walkthrough;
		if (localStorage.getItem('walkthrough') !== null) {
			walkthrough = JSON.parse(localStorage.getItem("walkthrough"));
		} else {
			walkthrough = {
				completed: false,
				data: [
					{
						id: "1",
						title: "CSR Strategy Set-up",
						btnTitle: "Set Strategy",
						body:
							"Configure your platform with you CSR Strategy or build one with this guided setup to align your social and business goals.",
						url: "/csr-strategy-setup",
						done: false
					}, {
						id: "2",
						title: "Fiscal Year Budgeting",
						btnTitle: "Allot Budget",
						body:
							"Allot CSR budget for the current fiscal year and plan the allocation proportion to suit your company’s social goals.",
						url: "/fiscal-year-budgeting",
						done: false
					}, {
						id: "3",
						title: "Goal-wise Investment Planning",
						btnTitle: "Set Goals",
						body:
							"Distribute your annual CSR budget across different social goals and agendas and track your investments & impact in each of the selected goals",
						url: "/portfolio",
						done: false
					}, {
						id: "4",
						title: "Project Discovery And Pipeline",
						btnTitle: "Browse Projects",
						body:
							"Discover LE assured projects,partner projects & global social innovations and manage the project status with the streamlined project funnel.",
						url: "/discover/projects",
						done: false
					}, {
						id: "5",
						title: "Impact Monitoring",
						btnTitle: "View Impact",
						body:
							"Gauge the impact your investments have made and plan further actions with the visual charts, real time project updates and milestones",
						url: "/investments",
						done: false
					}, {
						id: "6",
						title: "CSR-Corner",
						btnTitle: "Read More",
						body:
							"Catch up with the latest sector trends and insights from across the country every hour,day and week.",
						url: "/csr-corner",
						done: false
					}
				],
				completedSteps: []
			};
		}
		this.setState({
			userData: userDataJSON,
			walkthrough
		});
	}

	redirectFromMISUrl() {
		var redirectUrl = '/';
		if (window.location.pathname.startsWith('/mis')) {
			redirectUrl = window.location.pathname.split('/mis')[1];
		}
		return redirectUrl;
	}

	render() {
		return (
			<Router>
				<div>
					{(() => {
						if (this.state.userData !== null) {
							if (this.state.userData.type === "corporate") {
								if (this.state.userData.hasCustomPassword && this.state.walkthrough !== null) {
									return (
										<Switch>

											{/* CSR */}

											{/*
											<DefaultCSRLayout exact path="/csr" component={Landing} />
											<DefaultCSRLayout exact path="/csr/explore-causes" component={DiscoverCauses} />
											<DefaultCSRLayout exact path="/csr/explore-projects" component={DiscoverCSRProjects} />
											<DefaultCSRLayout exact path="/csr/explore-volunteer-opportunities" component={DiscoverProjects} />
											<DefaultCSRLayout path="/csr/cause/:name" component={SingleCause} />
											<DefaultCSRLayout path="/csr/csr-projects/:name" component={SingleCSRProject} userData={this.state.userData} />
											<DefaultCSRLayout path="/csr/volunteer-opportunity/:name" component={SingleProject} />
											<DefaultCSRLayout path="/csr/contact" component={Contact} />
											<DefaultCSRLayout path="/csr/corporate" component={Corporate} />
											<DefaultCSRLayout path="/csr/profile" component={Profile} />
											*/}

											{/* MIS */}
											{this.state.walkthrough.completed ? (
												<DefaultMISLayout exact path="/" selected="dashboard" component={MISDashboardCSR} />
											) : (
												<DefaultMISLayout exact path="/" selected="walkthrough" component={Walkthrough} />
											)}
											<DefaultMISLayout exact path="/dashboard" selected="dashboard" component={MISDashboardCSR} />
											<DefaultMISLayout exact path="/dashboard/impact-footprint" selected="dashboard" component={MISDashboardImpactFootprint} />
											<DefaultMISLayout exact path="/discover/projects" selected="projects" component={MISProjects} />
											{this.state.userData.authorizedFor.mis !== 'view' ? (
												<DefaultMISLayout exact path="/discover/partner-projects" selected="invite-ngo" component={MISPartnerProjects} />
											) : (null)}
											{this.state.userData.authorizedFor.mis !== 'view' ? (
												<DefaultMISLayout exact path="/discover/partner-projects/:partnerId" selected="invite-ngo" component={partnerPage} />
											) : (null)}
											{this.state.userData.authorizedFor.mis !== 'view' ? (
												<DefaultMISLayout exact path="/discover/innovative-models" selected="innovative-models" component={MISInnovativeModels} />
											) : (null)}
											<DefaultMISLayout exact path="/investments" selected="investments" component={MISProjects2} />
											{/*<DefaultMISLayout exact path="/investments" selected="investments" component={Investments} />*/}
											<DefaultMISLayout exact path="/investments/:name" selected="investments" component={SingleMISProject} userData={this.state.userData} />
											{this.state.userData.authorizedFor.mis !== 'view' ? (
												<DefaultMISLayout exact path="/edit-investment/:portfolioId/:investmentId/invest" selected="investments" component={EditSinglePortfolioGoal} />
											) : (null)}
											{this.state.userData.authorizedFor.mis !== 'view' ? (
												<DefaultMISLayout exact path="/edit-investment/:portfolioId/:investmentId/edit" selected="investments" component={EditSinglePortfolioGoal} />
											) : (null)}
											<DefaultMISLayout exact path="/portfolio" selected="investments" component={CSRPortfolio} />
											<DefaultMISLayout exact path="/portfolio/:id" selected="investments" component={SinglePortfolioGoal} />
											<DefaultMISLayout exact path="/preferences" selected="preferences" component={Preferences} />
											<DefaultMISLayout exact path="/preferences/:fiscalYear" selected="preferences" component={Preferences} />
											<DefaultMISLayout exact path="/settings" selected="settings" component={Settings} />
											{this.state.userData.authorizedFor.mis !== 'view' ? (
												<DefaultMISLayout exact path="/settings/:settings_type" selected="settings" component={Settings} />
											) : (
												<Redirect exact path="/settings/:settings_type" to="/settings" />
											)}
											<DefaultMISLayout exact path="/notifications" selected="notifications" component={Notifications} />
											<DefaultMISLayout exact path="/settings/user-profile" selected="user-profile" component={UserProfile} />
											<DefaultMISLayout exact path="/discussion" selected="discussion" component={ChatMessage} userData={this.state.userData} />
											<DefaultMISLayout exact path="/discussion/:chatId" selected="discussion" component={ChatMessage} userData={this.state.userData} />
											<DefaultMISLayout exact path="/organizer" selected="emails-repo" component={EmailsRepo} userData={this.state.userData} />
											{this.state.userData.authorizedFor.mis !== 'view' ?(<DefaultMISLayout exact path="/indicators" selected="indicators" component={Indicators} userData={this.state.userData} />):null}
											{this.state.userData.authorizedFor.mis !== 'view' ?(<DefaultMISLayout exact path="/indicators/:indicator_type" selected="indicators" component={Indicators} userData={this.state.userData} />):null }
											
											<DefaultMISLayout exact path="/trends" selected="trends" component={Trends} userData={this.state.userData} />
											<DefaultMISLayout exact path="/directors-report" selected="directors-report" component={DirectorsReport} userData={this.state.userData} />
											<DefaultMISLayout exact path="/walkthrough" selected="walkthrough" component={Walkthrough} />
											<DefaultMISLayout exact path="/mis-projects/:name" selected="projects" component={SingleMISProject} userData={this.state.userData} />
											<DefaultMISLayout exact path="/mis-projects/:name/tranch-disbursement" selected="projects" component={SingleMISProjectTranches} userData={this.state.userData} />
											<DefaultMISLayout exact path="/mis-projects/:name/resources" selected="projects" component={SingleMISProjectResources} userData={this.state.userData} />
											<DefaultMISLayout exact path="/mis-projects/:name/resources/album/:albumId" selected="projects" component={SingleMISProjectResources} userData={this.state.userData} />
											<DefaultMISLayout exact path="/mis-projects/:name/organizer" selected="projects" component={SingleMISProjectOrganizer} userData={this.state.userData} />
											<DefaultMISLayout exact path="/csr-strategy-setup" selected="strategy" component={Strategy} />
											<DefaultMISLayout exact path="/fiscal-year-budgeting" selected="fiscal-year-budgeting" component={FiscalYearBudgeting} />
											<DefaultMISLayout exact path="/innovation/:innovationId" selected="innovative-models" component={Innovation} />
											<DefaultMISLayout exact path="/investment-goal-plan" selected="investment-goal-plan" component={InvestmentGoalPlan} />
											<DefaultMISLayout exact path="/csr-corner" selected="csr-corner" component={CSRCorner} />
											<Redirect from="/mis/:something" to={this.redirectFromMISUrl()} />

											<Route component={NotFound} />
										</Switch>
									);
								} else {
									return (
										<Switch>
											<Route exact path="/change-password" component={FirstLogin} />
										</Switch>
									);
								}
							} else if (this.state.userData.type === "super_admin") {
								return (
									<div>
										<DocumentTitle title="Admin Dashboard" />
										<Switch>
											<Route exact path="/admin/dashboard" component={AdminDashboard} />
										</Switch>
									</div>
								);
							} else {
								eraseCookie("userData");
								return (
									<Switch>
										<Route path="/" component={Signup} />
									</Switch>
								);
							}
						} else {
							return (
								<div>
									<DocumentTitle title="CSREdge- Streamlined CSR Portfolio Management" />
									<Switch>
										<Route path="/verify-email/:name" component={Verify} />
										<Route path="/admin" component={AdminLogin} />
										<Route exact path="/reset-password/:token" component={ChangePasswordLayout} />
										<Route path="/" component={Signup} />
									</Switch>
								</div>
							);
						}
					})()}
				</div>
			</Router>
		);
	}
}