import React, {Component} from 'react';

import CausesFilter from './CausesFilter';
import Causes from './Causes';

export default class CausesContainer extends Component {
	render() {
		return (
			<div className="causes-container">
				<div className="causes-search">
					{this.props.causes !== null ? (
						<CausesFilter causes={this.props.causes}
							filteredCauses={this.props.filteredCauses}
							searchTerm={this.props.searchTerm}

							updateCauses={this.props.updateCauses}
							updateFilteredCauses={this.props.updateFilteredCauses}
							updateSearchTerm={this.props.updateSearchTerm} />
						) : ('')
					}
				</div>
				<Causes causes={this.props.filteredCauses}
					updateSelectedCause={this.props.updateSelectedCause} />
			</div>
		);
	}
}