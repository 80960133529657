import React, { Component } from "react";
import moment from "moment";
import ReactPaginate from "react-paginate";

const readCookie = require("../../../../cookie.js").readCookie;

class CSRCorner extends Component {
  state = {
    articles: [],
    perPage: 5,
    offset: 0,
    pageCount: 1
  };

  componentDidMount() {
    this.getPagiatedData(0);
  }

  handlePageClick = data => {
    let selected = data.selected;
    let offset = Math.ceil(selected * this.state.perPage);

    this.getPagiatedData(offset);
    this.setState({ offset });
  };

  getPagiatedData = skip => {
    let getQuery = "?skip=" + skip + "&limit=" + this.state.perPage;
    fetch(process.env.REACT_APP_API_URL + "/articles" + getQuery, {
      method: "GET",
      headers: {
        Auth: JSON.parse(readCookie("access_token")).access_token
      }
    })
      .then(data => data.json())
      .then(data => {
        if (skip === 0) {
          let pageCount = Math.ceil(data.totalCount / this.state.perPage);
          this.setState({ pageCount });
        }
        this.setState({
          articles: data.data
        });
      });
  };

  render() {
    return (
      <div className="mis-content-container mis-content-container-csrCorner ">
        <div className="notifications-header">
          <h1 className="notification-header-heading">
            CSR updates from across India.
          </h1>
          <hr className="notificaction-hr" />
        </div>
        {this.state.articles.map((article, i) => {
          return (
            <div key={i} className="notification-content-container">
              <div className="notification-heading-date-container">
                <div className="notification-title-container">
                  <div className="notificaction-bullets" />
                  <a
                    href={article.link}
                    target="_blank"
                    className="notification-title"
                  >
                    {article.title}
                  </a>
                </div>
                <p className="notification-date">
                  {" "}
                  {moment(article.publishedDate)
                    .startOf("hour")
                    .fromNow("DD-MM-YYYY")}{" "}
                  ago
                </p>
              </div>
              <p className="notification-src">
                <em>{article.source}</em>
              </p>
              <p className="notification-content">{article.content}</p>
              <div className="notification-link-share-container">
                <a
                  className="notification-link"
                  href={article.link}
                  target="_blank"
                >
                  <p className="notification-readMore">Read More</p>
                  <i className="fa fa-share notification-share-button" />
                </a>
              </div>
              <hr className="notification-div-hr" />
            </div>
          );
        })}
        <ReactPaginate
          previousLabel={"Prev"}
          nextLabel={"Next"}
          breakLabel={<a href="">...</a>}
          breakClassName={"break-me"}
          pageCount={this.state.pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={this.handlePageClick.bind(this)}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      </div>
    );
  }
}

export default CSRCorner;
