import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import Select from 'react-select';
import $ from 'jquery';
import Multiselect from 'react-bootstrap-multiselect';

const readCookie = require('../../../cookie.js').readCookie;

export default class DiscoverProjectsFilter extends Component {
	constructor(props) {
    super(props);
    this.state = {
    	filters: null,
    	setting: null,
    	beneficiary: null,
    	beneficiaryValue: "",
    };
  }

  componentDidMount() {
  	fetch(process.env.REACT_APP_API_URL + '/getOtAttributes', {
  		method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
  	}).then((data) => data.json())
		.then(function(data) {
			this.setState({ filters: data.otAttributes });
			let setting = [], beneficiary = [];

			for(let i = 0; i < data.otAttributes.length; i++) {
				if(data.otAttributes[i].type === 'setting') {
					let settingObj = {};
					settingObj['value'] = data.otAttributes[i]._id;
					settingObj['label'] = data.otAttributes[i].name;
					setting.push(settingObj);
				} else if(data.otAttributes[i].type === 'beneficiary') {
					let beneficiaryObj = {};
					beneficiaryObj['value'] = data.otAttributes[i]._id;
					beneficiaryObj['label'] = data.otAttributes[i].name;
					beneficiary.push(beneficiaryObj);
				}
			}
			this.setState({ setting, beneficiary });
		}.bind(this));
  }

  handleChange(name, value) {
		this.setState({ [name]: $(value).parent().val() });
	}

	resetFilters() {
		let settingValue = [];
		let beneficiaryValue = [];
		this.setState({ settingValue: null, beneficiaryValue: null }, function() {
			this.setState({ settingValue, beneficiaryValue });
		});

		fetch(process.env.REACT_APP_API_URL + '/volunteer-opportunity/discover', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
		.then(function(data) {
			this.props.updateProjects(data.data);
		}.bind(this));
	}

	applyFilters() {
		let params = {};
		if(this.state.settingValue !== '') {
			if(Array.isArray(this.state.settingValue)) {
				if(this.state.settingValue.length) {
					params['projectSetting'] = [];
					this.state.settingValue.map(function(val) {
						params['projectSetting'].push(val);
					})
				}
			}
		}
		if(this.state.beneficiaryValue !== '') {
			if(Array.isArray(this.state.beneficiaryValue)) {
				if(this.state.beneficiaryValue.length) {
					params['beneficiaryTypes'] = [];
					this.state.beneficiaryValue.map(function(val) {
						params['beneficiaryTypes'].push(val);
					})
				}
			}
		}

		let esc = encodeURIComponent
		let query = Object.keys(params).map(function(k) {
			if(Array.isArray(params[k])) {
				let x = '';
				for(let i = 0; i < params[k].length; i++) {
					if(i === 0) x += esc(k) + '=' + esc(params[k][i]);
					else x += '&' + esc(k) + '=' + esc(params[k][i])
				}
				return x;
			} else return esc(k) + '=' + esc(params[k])
		}).join('&')

		let url = process.env.REACT_APP_API_URL + '/volunteer-opportunity/discover?' + query
		fetch(url, {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
		.then(function(data) {
			this.props.updateProjects(data.data);
		}.bind(this));
		// minCost
		// maxCost
	}

	applyAdvancedFilters() {
		// console.log(this.state.engagementValue, this.state.timeTakenValue, this.state.labourDependentValue, this.state.frequencyValue, this.state.locationValue)
	}

	showButtonText(options, select) {
	  if (options.length === 0) {
      return 'No option selected ...';
	  } else if (options.length > 2) {
      return options.length + ' options selected!';
	  } else {
    	let labels = [];
      options.each(function() {
	     	if ($(this).attr('label') !== undefined) {
	      	labels.push($(this).attr('label'));
	     	} else {
	      	labels.push($(this).html());
	    	}
 			});
			return labels.join(', ') + '';
    }
  }

	render() {
		return (
			<div>
				<Row className="filters">
					<Col md={4}>
						<span>Location Coverage</span>
						{this.state.settingValue !== null ? (
								<Multiselect id="projectSetting" defaultValue={this.state.settingValue} onChange={this.handleChange.bind(this, 'settingValue')} buttonWidth={'100%'} buttonText={this.showButtonText.bind(this)} data={this.state.setting} multiple />
							) : ('')
						}
					</Col>
					<Col md={3}>
						<span>Beneficiary</span>
				    {this.state.beneficiaryValue !== null ? (
								<Multiselect id="projectBeneficiary" defaultValue={this.state.beneficiaryValue} onChange={this.handleChange.bind(this, 'beneficiaryValue')} buttonWidth={'100%'} buttonText={this.showButtonText.bind(this)} data={this.state.beneficiary} multiple />
							) : ('')
				  	}
					</Col>
					<Col md={1} className="btn filter-reset-btn" onClick={this.resetFilters.bind(this)}>Reset</Col>
					<Col md={1} className="btn filter-apply-btn" onClick={this.applyFilters.bind(this)}>Apply</Col>
				</Row>
				<Row className="advanced-filters" hidden={true}>
					<Col md={3}>
						<span>Frequency</span>
				    <Select multi={true} name="project-frequency"
						  value={this.state.frequencyValue} options={this.state.frequency}
						  onChange={this.handleChange.bind(this, 'frequencyValue')} />
					</Col>
					<Col md={3}>
						<span>Time Taken</span>
				    <Select multi={true} name="project-timetaken"
						  value={this.state.timeTakenValue} options={this.state.timeTaken}
						  onChange={this.handleChange.bind(this, 'timeTakenValue')} />
					</Col>
					<Col md={3}>
						<span>Labour Dependent</span>
				    <Select multi={true} name="project-labour-dependent"
						  value={this.state.labourDependentValue} options={this.state.labourDependent}
						  onChange={this.handleChange.bind(this, 'labourDependentValue')} />
					</Col>
					<Col className="btn filter-apply-btn" onClick={this.applyAdvancedFilters.bind(this)}>Apply</Col>
				</Row>
			</div>
		);
	}
}