import React, {Component} from 'react';

const google = window.google;
let map, marker, infowindow;
let theme = [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}]
let markersArray = [];

export default class Map extends Component {
	constructor(props) {
		super(props);
		this.updateTopProject = this.updateTopProject.bind(this)
	}

	componentDidMount() {
		const {lat, lng} = this.props.initialPosition;

		map = new google.maps.Map(this.refs.map, {
			center: {lat, lng},
			zoom: 9,
			styles: theme
		});

		this.componentDidUpdate();
	}

	updateTopProject(customId) {
		this.props.updateTopProject.bind(this, customId)
	};


	componentDidUpdate() {
		let markerArr = [];
		let customId = [];
		let data = this.props.projects;

		for (let i = 0; i < markersArray.length; i++ ) {
	        markersArray[i].setMap(null);
	    }
	    markersArray.length = 0;

		if(data !== null) {
			data.map(function(project, i) {
				let lat = parseFloat(project.latitude);
				let lng = parseFloat(project.longitude);
				let projectId = project.id;

				let icon= {
					url: `${process.env.PUBLIC_URL + '/img/red-marker.png'}`, // url
					scaledSize: new google.maps.Size(32, 32), // scaled size
					origin: new google.maps.Point(0,0), // origin
					anchor: new google.maps.Point(12, 32) // anchor
				};

				markerArr.push({lat, lng});
				customId.push(projectId);

				for(let i = 0; i < markerArr.length; i++) {
					let that = this;
					marker = new google.maps.Marker({
						position: markerArr[i],
						icon: icon,
						map: map,
						animation: google.maps.Animation.DROP,
						customId: customId[i],
						that: this
					});
					markersArray.push(marker)
					marker.setMap(map);

					marker.addListener('click', function() {
						map.setCenter(this.position);
						this.that.props.updateTopProject(this.customId);
					});
				}
			}.bind(this));
		}
	}

	x(x, that) {
		// console.log(this.customId);
		// let customId = this.customId;
		// this.updateTopProject(customId);
	}

	render() {
		return (
			<div>
				<div className="project-discover-map" style={{width: 500, height: 500, border: '1px solid black'}} ref="map">

				</div>
			</div>
		);
	}
}