import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import { Select, InputNumber } from "antd";
import { readCookie } from "../../../../../cookie";
import { ToastContainer, toast } from "react-toastify";
import ReactGA from 'react-ga';

var today = new Date();
var curMonth = today.getMonth();

let year = "";
if(curMonth > 3) year = moment().year() - 1;
else year = moment().year() - 2;
const years = [];
for (var i = 0; i < 10; i++) {
  var yearStr = year - i + "-" + (year - i + 1);
  var yearObj = {
    "selected": false,
    "year": yearStr
  }
  years.push(yearObj);
}

const Option = Select.Option;

let substring = "/walkthrough"

export default class CsrHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      history: [],
      allAgendas: [],
      allGoals: [],
      selectedGoalAgendas: [],
      tempGoalObj: null,
      newGoal: {
        goal: [],
        agenda: [],
        percentageBudget: 0,
        isHistorical: true
      },
      selectedGoalForEdit: null
    };
  }

  componentDidMount() {
    fetch(process.env.REACT_APP_API_URL + "/getOtAttributes", {
      method: "GET",
      headers: {
        Auth: JSON.parse(readCookie("access_token")).access_token
      }
    }).then(data => data.json())
      .then(data => {
        let allGoals = [];
        let allAgendas = [];
        for (let i = 0; i < data.otAttributes.length; i++) {
          if(data.otAttributes[i].type === "goal") {
            allGoals.push(data.otAttributes[i]);
          } else if(data.otAttributes[i].type === "agenda") {
            data.otAttributes[i].selected = false;
            allAgendas.push(data.otAttributes[i]);
          }
        }
        this.setState({ allGoals, allAgendas });

        let companyId = JSON.parse(readCookie("userData")).organisationData.id;
        fetch(process.env.REACT_APP_API_URL + "/company/" + companyId + "/mis/portfolio-history", {
          method: "GET",
          headers: {
            Auth: JSON.parse(readCookie("access_token")).access_token
          }
        }).then(data => data.json())
          .then(data => {
            let dataObj = data.data;
            if(dataObj.length === 0) this.addHistory(this);
            let history = [];
            for (var i = 0; i < dataObj.length; i++) {
              let historyObj = {};
              if(dataObj[i].amount !== "") {
                historyObj['csrBudget'] = dataObj[i].amount;
              }

              if(dataObj[i].year !== "") {
                historyObj['year'] = dataObj[i].year.toString() + '-' + (dataObj[i].year + 1).toString();
              }

              historyObj['isHistorical'] = dataObj[i].isHistorical;
              historyObj['max'] = 100;
              if(i === dataObj.length - 1 && dataObj[i].isHistorical) historyObj['selected'] = true;
              else historyObj['selected'] = false;

              let totalPercentage = 0;
              if(dataObj[i].hasOwnProperty('goals')) {
                let goals = dataObj[i].goals;
                if(historyObj['goals'] === undefined) historyObj['goals'] = [];
                for (var j = 0; j < goals.length; j++) {
                  let newGoalJson = {};
                  if(goals[j].amount !== 0) {
                    newGoalJson['percentageBudget'] = (goals[j].amount / dataObj[i].amount) * 100;
                    totalPercentage += (goals[j].amount / dataObj[i].amount) * 100;
                  } else {
                    newGoalJson['percentageBudget'] = 0;
                  }
                  newGoalJson['isHistorical'] = dataObj[i].goals[j].isHistorical;
                  if(goals[j].goal !== "") {
                    for (var k = 0; k < allGoals.length; k++) {
                      if(allGoals[k]._id === goals[j].goal) {
                        newGoalJson['goal'] = allGoals[k];
                      }
                    }
                  } else {
                    newGoalJson['goal'] = "";
                  }

                  if(goals[j].agendas.length) {
                    let agendas = [];
                    for (var k = 0; k < allAgendas.length; k++) {
                      for (var l = 0; l < goals[j].agendas.length; l++) {
                        if(allAgendas[k]._id === goals[j].agendas[l]) {
                          agendas.push(allAgendas[k]);
                        }
                      }
                    }
                    newGoalJson['agenda'] = agendas;
                  } else {
                    newGoalJson['agenda'] = [];
                  }
                  historyObj['goals'].push(newGoalJson);
                }
              }

              if(totalPercentage > 100) historyObj['max'] = 0;
              else historyObj['max'] = 100 - totalPercentage;

              for (var j = 0; j < years.length; j++) {
                if(years[j].year === historyObj.year) years[j].selected = true;
              }
              history.push(historyObj);
            }
            this.setState({ history });
          });
      });
  }

  handleYearChange = (historyIndex, value) => {
    let history = this.state.history;
    for (var j = 0; j < years.length; j++) {
      years[j].selected = false;
    }

    for (var i = 0; i < history.length; i++) {
      for (var j = 0; j < years.length; j++) {
        if(years[j].year === value || years[j].year === history[i].year) years[j].selected = true;
      }
    }
    history[historyIndex].year = value;
    this.setState({ history });
  };

  updateCSRBudget = (historyIndex, value) => {
    let csrBudget = 0;
    if(!isNaN(value)){
      csrBudget = value;
    }
    let history = this.state.history;
    history[historyIndex].csrBudget = csrBudget;
    this.setState({ history });
  };

  handleGoalChange = value => {
    let newGoal = this.state.newGoal;
    newGoal['goal'] = value;
    newGoal['agenda'] = [];
    let allAgendas = this.state.allAgendas;
    let selectedGoalAgendas = [];

    for (var i = 0; i < allAgendas.length; i++) {
      if(allAgendas[i]._goalId === value) {
        selectedGoalAgendas.push(allAgendas[i]);
      }
    }
    this.setState({ newGoal, selectedGoalAgendas });
  };

  updateAgenda = value => {
    let newGoal = this.state.newGoal;
    newGoal['agenda'] = value;
    this.setState({ newGoal });
  };

  updatePercentageBudget = (max, value) => {
    let newGoal = this.state.newGoal;
    let percentageValue = 0;
    if(max > 100) max = 100;
    if(value > max) {
      this.notifyWarn('The budget percentage cannot be greater than ' + max);
      percentageValue = max;
    } else percentageValue = value;
    newGoal['percentageBudget'] = percentageValue;
    this.setState({ newGoal });
  };

  addGoalObj(historyIndex) {
    let newGoal = this.state.newGoal;
    if(newGoal.percentageBudget >= 0 && typeof newGoal.goal === 'string' && newGoal.goal !== '' && newGoal.agenda.length !== undefined && newGoal.agenda.length > 0) {
      for (var i = 0; i < this.state.allGoals.length; i++) {
        if(this.state.allGoals[i]._id === newGoal.goal) newGoal.goal = this.state.allGoals[i];
      }
      let agendaObjects = [];
      for (i = 0; i < this.state.selectedGoalAgendas.length; i++) {
        for (var j = 0; j < newGoal.agenda.length; j++) {
          if(this.state.selectedGoalAgendas[i]._id === newGoal.agenda[j]) agendaObjects.push(this.state.selectedGoalAgendas[i]);
        }
      }
      newGoal.agenda = agendaObjects;
      let history = this.state.history;
      history[historyIndex].goals.push(newGoal);
      if(this.state.selectedGoalForEdit !== null) {
        var totalPercentage = 0;
        for(var i = 0; i < history[historyIndex].goals.length; i++) {
          totalPercentage += history[historyIndex].goals[i].percentageBudget;
        }
        history[historyIndex]['max'] = 100 - totalPercentage;
      } else {
        history[historyIndex]['max'] -= parseInt(newGoal.percentageBudget);
      }
      this.setState({
        history,
        newGoal: null,
        selectedGoalAgendas: [],
        selectedGoalForEdit: null
      }, function () {
        this.setState({
          newGoal: {
            goal: [],
            agenda: [],
            percentageBudget: 0,
            isHistorical: true
          }
        });
      }.bind(this));
    } else {
      if(newGoal.percentageBudget < 0) this.notifyError("Percentage Budget cannot be negative!");
      else this.notifyError("Mandatory values are missing!");
    }
  }

  getPrevFiscalYear() {
    var today = new Date();
    var curMonth = today.getMonth();
    let year = '';
    if(curMonth > 3) year = moment().year() - 1;
    else year = moment().year() - 2;
    return year + "-" + (year + 1);
  }

  addHistory = () => {
    let history = this.state.history;
    if(!history.length || (history[history.length - 1].year !== '' && history[history.length - 1].goals.length && history[history.length - 1].csrBudget !== '')) {
      for (var i = 0; i < history.length; i++) {
        history[i]['selected'] = false;
      }
      let historyJson = {};
      historyJson['year'] = '2015-2016';
      historyJson['csrBudget'] = '';
      historyJson['max'] = 100;
      historyJson['goals'] = [];
      historyJson['selected'] = true;
      historyJson['isHistorical'] = true;
      history.push(historyJson);
      this.setState({ newGoal: null }, function () {
        let newGoal = {
          goal: [],
          agenda: [],
          percentageBudget: 0,
          isHistorical: true
        };
        this.setState({ newGoal, history });
      }.bind(this));
    } else {
      this.notifyError("The last section is incomplete!");
    }
  }

  changeSelectedHistory(index) {
    let history = this.state.history;
    for (var i = 0; i < history.length; i++) {
      history[i]['selected'] = false;
    }
    history[index]['selected'] = true;
    this.setState({ history });
  }

  saveData = () => {
    if(this.state.history.length && this.state.history[0]['year'] !== "" && this.state.history[0]["csrBudget"] !== "") {
      let history = this.state.history.slice();
      for (var i = 0; i < history.length; i++) {
        history[i]['year'] = history[i]['year'].split('-')[0];
        for (var j = 0; j < history[i].goals.length; j++) {
          let agendas = history[i].goals[j].agenda;
          let agendaIds = [];
          for (var k = 0; k < agendas.length; k++) {
            agendaIds.push(agendas[k]._id);
          }
          history[i].goals[j].agenda = agendaIds;
        }
      }

      let data = {};
      data.history = this.state.history;
      let companyId = JSON.parse(readCookie("userData")).organisationData.id;
      fetch(process.env.REACT_APP_API_URL + "/company/" + companyId + "/mis/portfolio-history", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Auth: JSON.parse(readCookie("access_token")).access_token
        },
        body: JSON.stringify(data)
      }).then(function (response) {
        return response.json();
      }).then(function (data) {
        this.notify("Your Preferences has been saved!");
        ReactGA.event({
          category: 'Csr Strategy',
          action: 'edit',
          label: 'Saved',
          value: 6
        });
      }.bind(this));
    } else {
      this.notifyError("You've entered wrong details, Please try again!");
    }
  }

  notifyError = text => toast.error(text);

  notify = text => toast.success(text);

  notifyWarn = text => toast.warn(text);

  previous = () => {
    ReactGA.event({
      category: 'Csr Strategy',
      action: 'edit',
      label: 'Prev',
      value: 6
    });
    this.props.previous();
  };

  editGoal(historyIndex, goalIndex, id) {
    let history = this.state.history;
    let goalObj = history[historyIndex].goals[goalIndex];
    history[historyIndex].goals.splice(goalIndex, 1);

    let newGoal = {};
    newGoal['goal'] = goalObj.goal._id;
    newGoal['agenda'] = [];
    newGoal['percentageBudget'] = goalObj.percentageBudget;
    newGoal['isHistorical'] = goalObj.isHistorical;
    let allAgendas = this.state.allAgendas;
    let selectedGoalAgendas = [];

    for (var i = 0; i < allAgendas.length; i++) {
      if(allAgendas[i]._goalId === goalObj.goal._id) {
        selectedGoalAgendas.push(allAgendas[i]);
      }
    }
    this.setState({ newGoal, selectedGoalAgendas }, function () {
      let newGoal = this.state.newGoal;
      let agendas = [];
      for (var i = 0; i < goalObj.agenda.length; i++) {
        agendas.push(goalObj.agenda[i]._id);
      }
      newGoal.agenda = agendas;
      this.setState({ newGoal, tempGoalObj: goalObj, selectedGoalForEdit: id });
    }.bind(this));
  }

  gotoHandler = () => {
    window.location.pathname = '/fiscal-year-budgeting';
  }

  cancelGoalEdit(historyIndex) {
    let tempGoalObj = this.state.tempGoalObj;
    if(tempGoalObj !== null) {
      let history = this.state.history;
      history[historyIndex].goals.push(tempGoalObj);
      this.setState({
        history,
        newGoal: null,
        selectedGoalAgendas: [],
        selectedGoalForEdit: null
      }, function () {
        this.setState({
          newGoal: {
            goal: [],
            agenda: [],
            percentageBudget: 0,
            isHistorical: true
          },
          tempGoalObj: null
        });
      }.bind(this));
    } else {
      this.notifyError('The update was not successful');
      this.setState({
        newGoal: null,
        selectedGoalAgendas: [],
        selectedGoalForEdit: null
      }, function () {
        this.setState({
          newGoal: {
            goal: [],
            agenda: [],
            percentageBudget: 0,
            isHistorical: true
          },
          tempGoalObj: null
        });
      }.bind(this));
    }
  }

  render() {
    if(this.state.allAgendas !== null && this.state.allGoals !== null && this.state.history !== null) {
      return (
        <div className="mis-content-container-s6">
          <div className="title-section">
            <h1>Record the historical investment made in CSR</h1>
          </div>
          <div className="s6-body-section">
            <ToastContainer position="bottom-center" type="success" autoClose={4000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={false} />
            <div className="s6-sub-heading">
              <h3>Enlist year-wise project outlays</h3>
            </div>

            <div className="budget-table-container">
              {this.state.history.map((data, historyIndex) => {
                return (
                  <div className="history-container" key={historyIndex}>
                    {!data.selected && this.state.selectedGoalForEdit === null && data.isHistorical && JSON.parse(readCookie('userData')).authorizedFor.mis !== 'view' ? (
                      <button className="btn edit-btn" onClick={this.changeSelectedHistory.bind(this, historyIndex)}>
                        <i className="fa fa-pencil"></i>
                      </button>
                    ) : (null)}
                    <div className="s6-year-budget-container">
                      {data.isHistorical && data.goals.length === 0 ? (
                        <Col md={6} className="year-conatiner form-group">
                          <label className="select-year control-label">
                            Select Year:
                          </label>
                          <Select mode="single" style={{ marginLeft: "15px" }} placeholder="Select Year" value={data.year}
                            onChange={this.handleYearChange.bind(this, historyIndex)} disabled={JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'}>
                            <Option style={{ textAlign: "center" }} value="">Select Fiscal Year</Option>
                            {years.map((yearObj, index) => {
                              if(!yearObj.selected) {
                                return (
                                  <Option key={index} style={{ textAlign: "center" }} value={yearObj.year}>
                                    {yearObj.year}
                                  </Option>
                                );
                              } else return null;
                            })}
                          </Select>
                          </Col>
                      ) : (
                        <Col md={6} className="year-conatiner form-group">
                          <label className="select-year control-label">
                            Year:
                          </label>
                          <span>{' ' + data.year}</span>
                        </Col>
                      )}
                      {data.isHistorical ? (
                        <Col md={6} className="csr-budget-container form-group">
                          <label className="addCsr control-label">
                            Add CSR budget:
                          </label>
                          <InputNumber className="csr-budget-input form-control" placeholder="5000" min={0} value={data.csrBudget}
                            onChange={this.updateCSRBudget.bind(this, historyIndex)} disabled={JSON.parse(readCookie('userData')).authorizedFor.mis === 'view'} />
                        </Col>
                      ) : (
                        <Col md={6} className="csr-budget-container form-group">
                          <label className="addCsr control-label">
                            CSR budget:
                          </label>
                          <span>{'INR ' + data.csrBudget}</span>
                        </Col>
                      )}
                    </div>
                    <div className="budget-table-items">
                      <div className="goals-container">
                        {data.goals.map(function (goalObj, index) {
                          if(this.state.selectedGoalForEdit !== goalObj.goal._id) {
                            return (
                              <div className="goal-container">
                                <span className="goal-name">
                                  <strong>Goal:</strong> {goalObj.goal.name}
                                </span>
                                <br />
                                <div>
                                  <span className="agenda-name">
                                    <strong>Agenda: </strong>
                                    <span>
                                      {goalObj.agenda.map(function (agenda, index) {
                                        if(index > 0) {
                                          return (<span>{', ' + agenda.name}</span>)
                                        } else {
                                          return (<span>{agenda.name}</span>)
                                        }
                                      })}
                                    </span>
                                  </span>
                                  <br />
                                </div>
                                <span className="percentage-budget">
                                  <strong>Budget: </strong>
                                  {'INR ' + parseInt((goalObj.percentageBudget / 100) * data.csrBudget)}
                                </span>
                                <br />
                                {data.selected && data.isHistorical && goalObj.isHistorical && ((this.state.selectedGoalForEdit !== null && this.state.selectedGoalForEdit === goalObj.goal._id) || this.state.selectedGoalForEdit === null) && JSON.parse(readCookie('userData')).authorizedFor.mis !== 'view' ? (
                                  <button className="btn edit-btn" onClick={this.editGoal.bind(this, historyIndex, index, goalObj.goal._id)}>
                                    <i className="fa fa-pencil"></i>
                                  </button>
                                ) : (null)}
                              </div>
                            );
                          } else return null;
                        }.bind(this))}
                      </div>
                      {this.state.newGoal !== null && data.selected && JSON.parse(readCookie('userData')).authorizedFor.mis !== 'view' ? (
                        <div className="add-goal-container">
                          <div className="goal-agenda">
                            <div className="add-goal form-group">
                              <label className="control-label is-imp">
                                {"Select Goal: "}
                              </label>
                              <Select mode="single" style={{ width: "100%" }} value={this.state.newGoal.goal} placeholder="Select Goal" onChange={this.handleGoalChange}>
                                {this.state.allGoals.map((goal, index) => {
                                  return (
                                    <Option key={index} value={goal._id}>
                                      {goal.name}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </div>
                            <div className="add-agenda form-group">
                              <label className="control-label is-imp">
                                {"Select Agenda: "}
                              </label>
                              <Select mode="multiple" style={{ width: "100%" }} value={this.state.newGoal.agenda} placeholder="Select Agenda" onChange={this.updateAgenda}>
                                {this.state.selectedGoalAgendas.map((agenda, index) => {
                                  return (
                                    <Option key={index} style={{ textAlign: "center" }} value={agenda._id}>
                                      {agenda.name}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </div>
                          </div>
                          <div className="percentage-budget-container">
                            <div className="percentage-budget form-group">
                              <label className="control-label is-imp">
                                {"Add % of budget: "}
                              </label>
                              {this.state.selectedGoalForEdit !== null ? (
                                <InputNumber className="form-control" style={{ width: "100%" }}
                                  min={0} max={this.state.newGoal.percentageBudget + data.max} placeholder="50%" value={this.state.newGoal.percentageBudget}
                                  onChange={this.updatePercentageBudget.bind(this, this.state.newGoal.percentageBudget + data.max)} />
                              ) : (
                                <InputNumber className="form-control" style={{ width: "100%" }}
                                  min={0} max={data.max} placeholder="50%" value={this.state.newGoal.percentageBudget}
                                  onChange={this.updatePercentageBudget.bind(this, data.max)} />
                              )}
                            </div>
                            <span className="btn add-goal-btn"
                              onClick={this.addGoalObj.bind(this, historyIndex)}>
                              {this.state.selectedGoalForEdit !== null ? 'Update Goal' : 'Add Another Goal'}
                            </span>
                            {this.state.selectedGoalForEdit !== null ? (
                              <span className="btn cancel-goal-btn" onClick={this.cancelGoalEdit.bind(this, historyIndex)}>
                                <i className="fa fa-times"></i>Cancel
                              </span>
                            ) : (null)}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                );
              })}
              {JSON.parse(readCookie('userData')).authorizedFor.mis !== 'view' ? (
                <span className="btn add-year-btn"
                  onClick={this.addHistory}>
                  Add another year
                </span>
              ) : (null)}
            </div>
            <div className="buttons-section">
              <div className="prev-btn-container">
                <button className="btn-prev" onClick={this.previous}>
                  Previous
                </button>
              </div>
              <div className="nxt-btn-container">
                {JSON.parse(readCookie('userData')).authorizedFor.mis !== 'view' ? (
                  <button className="btn-save-next" onClick={this.saveData.bind(this)}> Save </button>
                ) : (null)}
                <button className="btn-go-to" onClick={this.gotoHandler}> Go to Fiscal Year Budget </button>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}